@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/mixins_backgrounds';
@import '../../../styles/animations';
@import '../../../styles/animations-intro';
.PopupChooseQuestions {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
	background-color: rgba(#092335, 0.6);
	width: 100%;
	height: 100%;
	@include flex('center', 'center');
	.PopupChooseQuestions-content {
		padding: 1em 1em 0em 1em;
		width: 17.22em;
		height: 18.88em;
		background-color: #F5F5F5;
		border-radius: 0.5em;
		@include flex('flex-start', 'center');
		flex-wrap: wrap;
		flex-direction: column;
		gap: 1em;
		.PopupChooseQuestions-title {
			width: 100%;
			height: 1em;
			position: relative;
			@include flex('center', 'center');
			margin-top: 0.5em;

			padding-bottom: 0.74em;
			span {
				border-radius: 0.55em;
				font-weight: bold;
				text-align: center;
				text-decoration: none;
				font-family: "Roboto", sans-serif;
				color: $blue-dark2;
			}
		}
		.PopupChooseQuestions-itemWrapper {
			width: 15.194em;
			height: 11.59em;
			@include flex('flex-start', center);
			flex-direction: column;
			background-color: white;
			border-radius: 0.556em;
			padding-top: 0.69em;
			padding-left: 0.69em;
			margin-bottom: 1.22em;
			position: relative;
			.PopupChooseQuestions-item {
				width: 100%;
				height: 2.318em;

				padding-bottom: 1em;
				@include flex('flex-start', 'center');
				span {
					border-radius: 0.55em;
					font-family: 'Source Sans Pro', sans-serif;
					font-weight: bold;
					text-align: center;
					text-decoration: none;
					color: $blue-dark2;
					font-size: 0.88em;
				}
				&.selected {
					::after {
						content: '';
						position: absolute;
						left: calc(13.47em + 1.38em);
						width: 1.38em;
						height: 1.38em;
						background-image: url('../../../assets/images/modules/icon-check2.svg');
					}
				}
				&:not(.selected) {
					::after {
						content: '';
						position: absolute;
						left: calc(13.47em + 1.38em);
						width: 1.38em;
						height: 1.38em;
						background-color: $blue-dark2;
						border-radius: 50%;
						@include opacity(0.3);
					}
				}
				&:nth-last-child(-n+2) { 
					border-bottom: none;
				}
			}
			.PopupChooseQuestions-feedbackWrapper {
				.PopupChooseQuestions-feedback {
					width: 100%;
					height: 1em;
					overflow: hidden;
					// background-color: red;
					span {
						border-radius: 0.55em;
						font-family: 'Source Sans Pro', sans-serif;
						font-weight: bold;
						text-align: center;
						text-decoration: none;
						font-size: 0.8em;
						color: rgba($color: red, $alpha: 0.5);
						display: none;
					}
				
					&.show {
						span {
							display: block;
						}
					}
				}
			}
		}
		.PopupChooseQuestions-buttonsWrapper {
			@include flex('flex-end', 'space-between');
			height: 1.8em;
			width: 100%;
			padding: 0 2em;
			margin-top: -1em;
			.Button {
				height: 1.8em;
				width: 5.6em;
			}
		}
		.PopupChooseQuestions-buttonsWrapper .Button:last-child {
			margin-left: 0.555em;						
		}
	}
}