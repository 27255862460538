@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';
@import '../../../../styles/mixins_backgrounds';
@import '../../../../styles/animations';
@import '../../../../styles/animations-intro';
.FacilitatorDeleteMessagePopup {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(#7ABCEA, 0.81);
	@include flex('center', 'center');

	.FacilitatorDeleteMessagePopup-closeBtn {
		padding: 0em;
		width: 2em;
		height: 2em;
		background-image: url('../../../../assets/images/facilitator/icon-close.svg');
		background-size: 1.75em auto;
		cursor: pointer;
	}

	.FacilitatorDeleteMessagePopup-content {
		width: 40em;
		min-height: 14em;
		color: #11466B;
		background-color: white;
		border-radius: 0.83em;

		.FacilitatorDeleteMessagePopup-header {
			position: relative;
			width: 100%;
			height: 2.8em;
			background-color: #11466B;
			border-top-left-radius: 0.83em;
			border-top-right-radius: 0.83em;
			border: 0.25em solid white;
			padding-left: 1em;
			@include flex('space-between', 'center');
			.FacilitatorDeleteMessagePopup-title {
				color: #ffffff;
				font-size: 1.25em;
				font-weight: 600;
			}
			.FacilitatorDeleteMessagePopup-closeBtn {
				width: 2.33em;
				height: 2.33em;
				background-image: url('../../../../assets/images/facilitator/icon-close.svg');
				background-size: 1.56em auto;
				cursor: pointer;
			}
		}

		.FacilitatorDeleteMessagePopup-body {
			position: relative;
			padding: 1em 1.5em;
			.FacilitatorDeleteMessagePopup-text {
				width: 100%;
				
			}
			.FacilitatorDeleteMessagePopup-message {
				.FacilitatorDeleteMessagePopup-messageInfo {
					span {
						display: inline-block;
						vertical-align: middle;
						&:first-of-type {
							width: 6em;
						}
					}
				}
			}
			.FacilitatorDeleteMessagePopup-feedback {
				width: 100%;
				height: 2em;
				span {
					font-size: 0.8em;
					color: black;
				}
				&.error span {color: #C13B3B;}
			}
			.FacilitatorDeleteMessagePopup-buttons {
				width: 100%;
				@include flex('space-between', 'center');
			}
		}
	}
}