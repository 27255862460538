@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';
@import '../../../../styles/mixins_backgrounds';
@import '../../../../styles/animations';
@import '../../../../styles/animations-intro';
.OrganizeDndDragLayer {
	.OrganizeDndDragLayer-item { 
		position: relative;
		display: inline-block;
		.OrganizeDndDragLayer-img {
			width: 100%;
			height: 100%;
			position: relative;
		}
	}

	&.floral-2-bouquets {
		.OrganizeDndDragLayer-item {
			width: 4.5em;
			height: 5em;
			.OrganizeDndDragLayer-img {
				height: 6.308em;
				width: 5em;
				&.bouquet1 {
					background-size: 2.9em;
					background-image: url('../../../../assets/images/modules/tasks/organize/floral-2-bouquets/bouquet1.svg');
				}
				&.bouquet2 {
					background-size: 4.1em;
					background-image: url('../../../../assets/images/modules/tasks/organize/floral-2-bouquets/bouquet2.svg');
				}
				&.bouquet3 {
					background-size: 2.9em;
					background-image: url('../../../../assets/images/modules/tasks/organize/floral-2-bouquets/bouquet3.svg');
				}
				&.bouquet4 {
					background-size: 2.9em;
					background-image: url('../../../../assets/images/modules/tasks/organize/floral-2-bouquets/bouquet4.svg');
				}
				&.bouquet5 {
					background-size: 4.1em;
					background-image: url('../../../../assets/images/modules/tasks/organize/floral-2-bouquets/bouquet5.svg');
				}
				&.bouquet6 {
					background-size: 2.9em;
					background-image: url('../../../../assets/images/modules/tasks/organize/floral-2-bouquets/bouquet6.svg');
				}
				&.bouquet7 {
					background-size: 2.2em;
					background-image: url('../../../../assets/images/modules/tasks/organize/floral-2-bouquets/bouquet7.svg');
				}
				&.bouquet8 {
					background-size: 3.25em;
					background-image: url('../../../../assets/images/modules/tasks/organize/floral-2-bouquets/bouquet8.svg');
				}
				&.bouquet9 {
					background-size: 2.2em;
					background-image: url('../../../../assets/images/modules/tasks/organize/floral-2-bouquets/bouquet9.svg');
				}
			}
		}
	}

	&.bakery-1-donuts {
		.OrganizeDndDragLayer-item {
			width: 4.5em;
			height: 5em;
			.OrganizeDndDragLayer-img {
				&.initial {
					height: 5em;
					width: 4.5em;
				}
				&.final {
					height: 3em;
					width: 5.5em;
				}
				&.remove {
					height: 3.5em;
					width: 3.5em;
				}
				&.donut-1 {
					background-image: url('../../../../assets/images/modules/tasks/organize/bakery-1-donuts/donut-1.svg');
				}
				&.donut-2 {
					background-image: url('../../../../assets/images/modules/tasks/organize/bakery-1-donuts/donut-2.svg');
				}
				&.donut-3 {
					background-image: url('../../../../assets/images/modules/tasks/organize/bakery-1-donuts/donut-3.svg');
				}
				&.donut-4 {
					background-image: url('../../../../assets/images/modules/tasks/organize/bakery-1-donuts/donut-4.svg');
				}
				&.donut-5 {
					background-image: url('../../../../assets/images/modules/tasks/organize/bakery-1-donuts/donut-5.svg');
				}
				&.donut-6 {
					background-image: url('../../../../assets/images/modules/tasks/organize/bakery-1-donuts/donut-6.svg');
				}
				&.donut-7 {
					background-image: url('../../../../assets/images/modules/tasks/organize/bakery-1-donuts/donut-7.svg');
				}
				&.donut-8 {
					background-image: url('../../../../assets/images/modules/tasks/organize/bakery-1-donuts/donut-8.svg');
				}
				&.donut-9 {
					background-image: url('../../../../assets/images/modules/tasks/organize/bakery-1-donuts/donut-9.svg');
				}
				&.donut-10 {
					background-image: url('../../../../assets/images/modules/tasks/organize/bakery-1-donuts/donut-10.svg');
				}
				&.donut-11 {
					background-image: url('../../../../assets/images/modules/tasks/organize/bakery-1-donuts/donut-11.svg');
				}
				&.donut-12 {
					background-image: url('../../../../assets/images/modules/tasks/organize/bakery-1-donuts/donut-12.svg');
				}
			}
		}
	}

	&.bakery-1-cupcakes {
		.OrganizeDndDragLayer-item {
			width: 4.25em;
			height: 4.25em;
			.OrganizeDndDragLayer-img {
				background-size: auto 3.5em;
				background-position: center center;
				&.cupcake-1 {background-image: url('../../../../assets/images/modules/tasks/organize/bakery-1-cupcakes/cupcake-1.svg');}
				&.cupcake-2 {background-image: url('../../../../assets/images/modules/tasks/organize/bakery-1-cupcakes/cupcake-2.svg');}
				&.cupcake-3 {background-image: url('../../../../assets/images/modules/tasks/organize/bakery-1-cupcakes/cupcake-3.svg');}
				&.cupcake-4 {background-image: url('../../../../assets/images/modules/tasks/organize/bakery-1-cupcakes/cupcake-4.svg');}
			}
		}
	}

	&.center-store-2-shelf-tags {
		.OrganizeDndDragLayer-item {
			height: 1em;
			width: 6em;
			.OrganizeDndDragLayer-img {
				background-size: auto 100%;
				background-position: left center;
				&.onion {background-image: url('../../../../assets/images/modules/tasks/organize/center-store-2-shelf-tags/onion.svg');}
				&.potatoes {background-image: url('../../../../assets/images/modules/tasks/organize/center-store-2-shelf-tags/potatoes.svg');}
				&.corn {background-image: url('../../../../assets/images/modules/tasks/organize/center-store-2-shelf-tags/corn.svg');}
				&.beets {background-image: url('../../../../assets/images/modules/tasks/organize/center-store-2-shelf-tags/beets.svg');}
				&.carrots {background-image: url('../../../../assets/images/modules/tasks/organize/center-store-2-shelf-tags/carrots.svg');}
				&.asparagus {background-image: url('../../../../assets/images/modules/tasks/organize/center-store-2-shelf-tags/asparagus.svg');}
			}
		}
	}

	&.meat-1-match-with-animal {
		.OrganizeDndDragLayer-item {
			.OrganizeDndDragLayer-img {
				height: 4em;
				width: 5em;
				background-size: 4em;
				
				&.meat-1 {
					background-image: url('../../../../assets/images/modules/tasks/organize/meat-1-match-with-animal/meat-1.svg');
				}
				&.meat-2 {
					background-image: url('../../../../assets/images/modules/tasks/organize/meat-1-match-with-animal/meat-2.svg');
				}
				&.meat-3 {
					background-image: url('../../../../assets/images/modules/tasks/organize/meat-1-match-with-animal/meat-3.svg');
				}
				&.meat-4 {
					background-image: url('../../../../assets/images/modules/tasks/organize/meat-1-match-with-animal/meat-4.svg');
				}
				&.meat-5 {
					background-image: url('../../../../assets/images/modules/tasks/organize/meat-1-match-with-animal/meat-5.svg');
				}
				&.meat-6 {
					background-image: url('../../../../assets/images/modules/tasks/organize/meat-1-match-with-animal/meat-6.svg');
				}
				&.meat-7 {
					background-image: url('../../../../assets/images/modules/tasks/organize/meat-1-match-with-animal/meat-7.svg');
				}
			}
		}
	}

	&.meat-2-meat-case {
		.OrganizeDndDragLayer-item {
			.OrganizeDndDragLayer-img {
				width: 5.22em;
				height: 3.43em;
				background-size: 5em auto;
				background-position: center center;
				&.meat-1 {background-image: url('../../../../assets/images/modules/tasks/organize/meat-2-meat-case/meat-1.svg');}
				&.meat-2 {background-image: url('../../../../assets/images/modules/tasks/organize/meat-2-meat-case/meat-2.svg');}
				&.meat-3 {background-image: url('../../../../assets/images/modules/tasks/organize/meat-2-meat-case/meat-3.svg');}
				&.meat-4 {
					background-image: url('../../../../assets/images/modules/tasks/organize/meat-2-meat-case/meat-4.svg');
					background-size: 100% auto;
				}
				&.meat-5 {
					background-image: url('../../../../assets/images/modules/tasks/organize/meat-2-meat-case/meat-5.svg');
					background-size: 100% auto;
				}
				&.meat-6 {
					background-image: url('../../../../assets/images/modules/tasks/organize/meat-2-meat-case/meat-6.svg');
					background-size: 100% auto;
				}
				&.meat-7 {background-image: url('../../../../assets/images/modules/tasks/organize/meat-2-meat-case/meat-7.svg');}
				&.meat-8 {background-image: url('../../../../assets/images/modules/tasks/organize/meat-2-meat-case/meat-8.svg');}
				&.meat-9 {background-image: url('../../../../assets/images/modules/tasks/organize/meat-2-meat-case/meat-9.svg');}
			}
		}
	}

	&.produce-1-cool-racks {
		.OrganizeDndDragLayer-item {
			width: 3em;
			height: 5em;

			.OrganizeDndDragLayer-img {
				height: 3em;
				width: 3em;

				&.dry-1 {
					width: 4.5em;
					height: 3.4em;
					background-size: 4.5em;
					background-position: bottom right;
					background-image: url('../../../../assets/images/modules/tasks/organize/produce-1-produce-aisle/dry-1.svg');
				}
				&.dry-2 {
					width: 4em;
					height: 3.4em;
					background-size: 4em;
					background-position: bottom calc(50%) right calc(50%);
					background-image: url('../../../../assets/images/modules/tasks/organize/produce-1-produce-aisle/dry-2.svg');
				}
				&.dry-3 {
					width: 4.1em;
					height: 3.4em;
					background-size: 4em;
					background-position: bottom calc(50%) right calc(50%);
					background-image: url('../../../../assets/images/modules/tasks/organize/produce-1-produce-aisle/dry-3.svg');
				}
				&.dry-4 {
					width: 4.1em;
					height: 3.4em;
					background-size: 4em;
					background-position: bottom calc(50%) right calc(50%);
					background-image: url('../../../../assets/images/modules/tasks/organize/produce-1-produce-aisle/dry-4.svg');
				}
				&.dry-5 {
					width: 4.1em;
					height: 4.25em;
					background-size: 4.4em;
					background-position: bottom calc(50%) right calc(50%);
					background-image: url('../../../../assets/images/modules/tasks/organize/produce-1-produce-aisle/dry-5.svg');
				}
				&.dry-6 {
					width: 4.4em;
					height: 3.4em;
					background-size: 4.4em;
					background-position: bottom calc(50%) right calc(50%);
					background-image: url('../../../../assets/images/modules/tasks/organize/produce-1-produce-aisle/dry-6.svg');
				}
				&.dry-7 {
					width: 4.5em;
					height: 3.4em;
					background-size: 4.4em;
					background-position: bottom calc(50%) right calc(50%);
					background-image: url('../../../../assets/images/modules/tasks/organize/produce-1-produce-aisle/dry-7.svg');
				}
				&.dry-8 {
					width: 5.1em;
					height: 3.4em;
					background-size: 5em;
					background-position: bottom calc(50%) right calc(50%);
					background-image: url('../../../../assets/images/modules/tasks/organize/produce-1-produce-aisle/dry-8.svg');
				}
				&.cooled-1 {
					width: 5.1em;
					height: 3.4em;
					background-size: 5em;
					background-position: bottom calc(50%) right calc(50%);
					background-image: url('../../../../assets/images/modules/tasks/organize/produce-1-produce-aisle/cooled-1.svg');
				}
				&.cooled-2 {
					width: 5.1em;
					height: 3.4em;
					background-size: 5em;
					background-position: bottom calc(50%) right calc(50%);
					background-image: url('../../../../assets/images/modules/tasks/organize/produce-1-produce-aisle/cooled-2.svg');
				}
				&.cooled-3 {
					width: 4.5em;
					height: 3.4em;
					background-size: 4.4em;
					background-position: bottom calc(50%) right calc(50%);
					background-image: url('../../../../assets/images/modules/tasks/organize/produce-1-produce-aisle/cooled-3.svg');
				}
				&.cooled-4 {
					width: 4.5em;
					height: 3.4em;
					background-size: 5em;
					background-position: bottom calc(50%) right calc(50%);
					background-image: url('../../../../assets/images/modules/tasks/organize/produce-1-produce-aisle/cooled-4.svg');
				}
				&.cooled-5 {
					width: 4.2em;
					height: 4em;
					background-size: 3.5em;
					background-position: bottom calc(50%) right calc(50%);
					background-image: url('../../../../assets/images/modules/tasks/organize/produce-1-produce-aisle/cooled-5.svg');
				}
				&.cooled-6 {
					width: 4.5em;
					height: 3.4em;
					background-size: 5em;
					background-position: bottom -0.5em right calc(50%);
					background-image: url('../../../../assets/images/modules/tasks/organize/produce-1-produce-aisle/cooled-6.svg');
				}
			}
		}
	}

	&.produce-1-dry-table {
		.OrganizeDndDragLayer-item {
			width: 3em;
			height: 5em;

			.OrganizeDndDragLayer-img {
				height: 3em;
				width: 3em;

				&.dry-1 {
					width: 4.5em;
					height: 3.4em;
					background-size: 4.5em;
					background-position: bottom right;
					background-image: url('../../../../assets/images/modules/tasks/organize/produce-1-produce-aisle/dry-1.svg');
				}
				&.dry-2 {
					width: 4em;
					height: 3.4em;
					background-size: 4em;
					background-position: bottom calc(50%) right calc(50%);
					background-image: url('../../../../assets/images/modules/tasks/organize/produce-1-produce-aisle/dry-2.svg');
				}
				&.dry-3 {
					width: 4.1em;
					height: 3.4em;
					background-size: 4em;
					background-position: bottom calc(50%) right calc(50%);
					background-image: url('../../../../assets/images/modules/tasks/organize/produce-1-produce-aisle/dry-3.svg');
				}
				&.dry-4 {
					width: 4.1em;
					height: 3.4em;
					background-size: 4em;
					background-position: bottom calc(50%) right calc(50%);
					background-image: url('../../../../assets/images/modules/tasks/organize/produce-1-produce-aisle/dry-4.svg');
				}
				&.dry-5 {
					width: 4.1em;
					height: 4.25em;
					background-size: 4.4em;
					background-position: bottom calc(50%) right calc(50%);
					background-image: url('../../../../assets/images/modules/tasks/organize/produce-1-produce-aisle/dry-5.svg');
				}
				&.dry-6 {
					width: 4.4em;
					height: 3.4em;
					background-size: 4.4em;
					background-position: bottom calc(50%) right calc(50%);
					background-image: url('../../../../assets/images/modules/tasks/organize/produce-1-produce-aisle/dry-6.svg');
				}
				&.dry-7 {
					width: 4.5em;
					height: 3.4em;
					background-size: 4.4em;
					background-position: bottom calc(50%) right calc(50%);
					background-image: url('../../../../assets/images/modules/tasks/organize/produce-1-produce-aisle/dry-7.svg');
				}
				&.dry-8 {
					width: 5.1em;
					height: 3.4em;
					background-size: 5em;
					background-position: bottom calc(50%) right calc(50%);
					background-image: url('../../../../assets/images/modules/tasks/organize/produce-1-produce-aisle/dry-8.svg');
				}
				&.cooled-1 {
					width: 5.1em;
					height: 3.4em;
					background-size: 5em;
					background-position: bottom calc(50%) right calc(50%);
					background-image: url('../../../../assets/images/modules/tasks/organize/produce-1-produce-aisle/cooled-1.svg');
				}
				&.cooled-2 {
					width: 5.1em;
					height: 3.4em;
					background-size: 5em;
					background-position: bottom calc(50%) right calc(50%);
					background-image: url('../../../../assets/images/modules/tasks/organize/produce-1-produce-aisle/cooled-2.svg');
				}
				&.cooled-3 {
					width: 4.5em;
					height: 3.4em;
					background-size: 4.4em;
					background-position: bottom calc(50%) right calc(50%);
					background-image: url('../../../../assets/images/modules/tasks/organize/produce-1-produce-aisle/cooled-3.svg');
				}
				&.cooled-4 {
					width: 4.5em;
					height: 3.4em;
					background-size: 5em;
					background-position: bottom calc(50%) right calc(50%);
					background-image: url('../../../../assets/images/modules/tasks/organize/produce-1-produce-aisle/cooled-4.svg');
				}
				&.cooled-5 {
					width: 4.2em;
					height: 4em;
					background-size: 3.5em;
					background-position: bottom calc(50%) right calc(50%);
					background-image: url('../../../../assets/images/modules/tasks/organize/produce-1-produce-aisle/cooled-5.svg');
				}
				&.cooled-6 {
					width: 4.5em;
					height: 3.4em;
					background-size: 5em;
					background-position: bottom -0.5em right calc(50%);
					background-image: url('../../../../assets/images/modules/tasks/organize/produce-1-produce-aisle/cooled-6.svg');
				}
			}
		}
	}

	&.produce-2-cut-fruit {
		width: 5.8em;
		height: 3.4em;
		
		.OrganizeDndDragLayer-item {
			height: 100%;
			width: 100%;
			background-image: url('../../../../assets/images/modules/tasks/organize/produce-2-cut-fruit/cut-fruit.svg');
		}
	}

	&.backroom-5-s-organize-shelves {
		.OrganizeDndDragLayer-item {
			width: 4.75em;
			height: 4.75em;
			.OrganizeDndDragLayer-img {
				background-position: center center;
				&.spray-cleaner {
					background-size: auto 4.6em;
					background-image: url('../../../../assets/images/modules/tasks/organize/backroom-5-s-organize-shelves/spray-cleaner.svg');
				}
				&.gloves {
					background-size: auto 3.6em;
					background-image: url('../../../../assets/images/modules/tasks/organize/backroom-5-s-organize-shelves/gloves.svg');
				}
				&.buckets {
					background-size: auto 4.4em;
					background-image: url('../../../../assets/images/modules/tasks/organize/backroom-5-s-organize-shelves/bucket.svg');
				}
				&.paper-towels {
					background-size: auto 3.4em;
					background-image: url('../../../../assets/images/modules/tasks/organize/backroom-5-s-organize-shelves/paper-towels.svg');
				}
				&.cleaner-jugs {
					background-size: auto 4.6em;
					background-image: url('../../../../assets/images/modules/tasks/organize/backroom-5-s-organize-shelves/cleaner-jug.svg');
				}
			}
		}
	}

	&.backroom-5-s-organize-files {
		.OrganizeDndDragLayer-item {
			width: 4.5em;
			height: 5.5em;
			.OrganizeDndDragLayer-img {
				&.order-history {
					height: 3.7em;
					width: 5em;
					background-size: 2.9em 2.4em;
					background-position: bottom calc(50%) right calc(50%);
					background-image: url('../../../../assets/images/modules/tasks/organize/backroom-5-s-organize-files/order-history.svg');
				}
				&.sanitation-schedules {
					height: 3.7em;
					width: 5em;
					background-size: 5em 2.4em;
					background-position: bottom calc(50%) right calc(50%);
					background-image: url('../../../../assets/images/modules/tasks/organize/backroom-5-s-organize-files/sanitation-schedules.svg');
				}
				&.invoice {
					height: 3.7em;
					width: 5em;
					background-size: 2.9em 2.4em;
					background-position: bottom calc(50%) right calc(50%);
					background-image: url('../../../../assets/images/modules/tasks/organize/backroom-5-s-organize-files/invoice.svg');
				}
				&.holiday-ads {
					height: 3.7em;
					width: 5em;
					background-size: 4.8em 2.4em;
					background-position: bottom calc(50%) right calc(50%);
					background-image: url('../../../../assets/images/modules/tasks/organize/backroom-5-s-organize-files/holiday-ads.svg');
				}
			}
		}
	}

	&.deli-1-salad-bar {
		.OrganizeDndDragLayer-item {
			width: 4.5em;
			height: 5.5em;
			&::before {
				content: '';
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				background-image: url('../../../../assets/images/modules/tasks/organize/deli-1-salad-bar/item-depth.svg');
				background-size: 3.85em auto;
				background-position: 0.65em 0.725em;
				background-repeat: no-repeat;
			}
			.OrganizeDndDragLayer-img {
				background-image: url('../../../../assets/images/modules/tasks/organize/deli-1-salad-bar/carrot.svg');
				background-size: 92% auto;
				background-position: top left;
				&.tomato {background-image: url('../../../../assets/images/modules/tasks/organize/deli-1-salad-bar/tomato.svg');}
				&.cucumber {background-image: url('../../../../assets/images/modules/tasks/organize/deli-1-salad-bar/cucumber.svg');}
				&.green-salad {background-image: url('../../../../assets/images/modules/tasks/organize/deli-1-salad-bar/green-salad.svg');}
				&.carrot {background-image: url('../../../../assets/images/modules/tasks/organize/deli-1-salad-bar/carrot.svg');}
				&.onion {background-image: url('../../../../assets/images/modules/tasks/organize/deli-1-salad-bar/onion.svg');}
				&.champignon-mushroom {background-image: url('../../../../assets/images/modules/tasks/organize/deli-1-salad-bar/champignon-mushroom.svg');}
				&.red-bell-pepper {background-image: url('../../../../assets/images/modules/tasks/organize/deli-1-salad-bar/red-bell-pepper.svg');}
				&.corn {background-image: url('../../../../assets/images/modules/tasks/organize/deli-1-salad-bar/corn.svg');}
			}
		}
	}

	&.safety-1-gloves {
		.OrganizeDndDragLayer-item {
			width: 4.75em;
			height: 4.75em;
			.OrganizeDndDragLayer-img {
				background-position: center center;
				&.gloves-1 {
					background-size: auto 3.58em;
					background-image: url('../../../../assets/images/modules/tasks/organize/safety-1-gloves/gloves-heat-resistant.svg');
				}
				&.gloves-2 {
					background-size: auto 3.58em;
					background-image: url('../../../../assets/images/modules/tasks/organize/safety-1-gloves/gloves-cut-proof.svg');
				}
				&.gloves-3 {
					background-size: auto 3.68em;
					background-image: url('../../../../assets/images/modules/tasks/organize/safety-1-gloves/gloves-washing.svg');
				}
				&.gloves-4 {
					background-size: auto 3.63em;
					background-image: url('../../../../assets/images/modules/tasks/organize/safety-1-gloves/gloves-food-prep.svg');
				}
				&.gloves-5 {
					background-size: auto 3.66em;
					background-image: url('../../../../assets/images/modules/tasks/organize/safety-1-gloves/baseball-mit.svg');
				}
				&.gloves-6 {
					background-size: auto 4.52em;
					background-image: url('../../../../assets/images/modules/tasks/organize/safety-1-gloves/finger-foam.svg');
				}
				&.gloves-7 {
					background-size: auto 2.38em;
					background-image: url('../../../../assets/images/modules/tasks/organize/safety-1-gloves/biker-glove.svg');
				}
			}
		}
	}

	&.seafood-1-fish-labels {
		.OrganizeDndDragLayer-item {
			.OrganizeDndDragLayer-img {
				&.label-1 {
					background-image: url('../../../../assets/images/modules/tasks/organize/seafood-1-fish-labels/label-1.svg');
					width: 6em;
					height: 3.6em;
				}
				&.label-2 {
					width: 3.5em;
					height: 3.5em;
					background-image: url('../../../../assets/images/modules/tasks/organize/seafood-1-fish-labels/label-2.svg');
				}
				&.label-3 {
					width: 3em;
					height: 3em;
					background-image: url('../../../../assets/images/modules/tasks/organize/seafood-1-fish-labels/label-3.svg');
				}
			}
		}
	}

	&.seafood-1-seafood-pallet {
		z-index: 5;
		.OrganizeDndDragLayer-item {
			.OrganizeDndDragLayer-img {
				&.pallet-1 {
					height: 2.75em;
					width: 5em;
					background-size: 5em auto;
					background-image: url('../../../../assets/images/modules/tasks/organize/seafood-1-seafood-pallet/seafood-1.svg');
				}
				&.pallet-2 {
					height: 2.75em;
					width: 5em;
					background-size: 5em;
					background-image: url('../../../../assets/images/modules/tasks/organize/seafood-1-seafood-pallet/seafood-2.svg');
				}
				&.pallet-3 {
					width: 3.2em;
					height: 3em;
					background-size: 3em;
					background-position: bottom calc(50%) right calc(50%);
					background-image: url('../../../../assets/images/modules/tasks/organize/seafood-1-seafood-pallet/seafood-3.svg');
				}
				&.pallet-4 {
					height: 3em;
					width: 5em;
					background-size: 5em;
					background-position: bottom calc(50%) right calc(50%);
					background-image: url('../../../../assets/images/modules/tasks/organize/seafood-1-seafood-pallet/seafood-4.svg');
				}
				&.pallet-5 {
					height: 3em;
					width: 5em;
					background-size: 5em;
					background-position: bottom calc(50%) right calc(50%);
					background-image: url('../../../../assets/images/modules/tasks/organize/seafood-1-seafood-pallet/seafood-5.svg');
				}
				&.pallet-6 {
					height: 3em;
					width: 5em;
					background-size: 5em;
					background-position: bottom calc(50%) right calc(50%);
					background-image: url('../../../../assets/images/modules/tasks/organize/seafood-1-seafood-pallet/seafood-6.svg');
				}
			}
		}
	}

	&.deli-2-shelves {
		.OrganizeDndDragLayer-item {
			width: 2.9em;
			height: 6em;
			.OrganizeDndDragLayer-img { 
				background-position: bottom center;
				&.can-beans {
					background-size: 3.11em auto;
					background-image: url('../../../../assets/images/modules/tasks/organize/deli-2-shelves/can-beans.svg');
				}
				&.can-corn {
					background-size: 2.5em auto;
					background-image: url('../../../../assets/images/modules/tasks/organize/deli-2-shelves/can-corn.svg');
				}
				&.can-corn-2 {
					background-size: 2.5em auto;
					background-image: url('../../../../assets/images/modules/tasks/organize/deli-2-shelves/can-corn-2.svg');
				}
				&.can-mushrooms {
					background-size: 2.8em auto;
					background-image: url('../../../../assets/images/modules/tasks/organize/deli-2-shelves/can-mushrooms.svg');
				}
				&.can-onions {
					background-size: 2.5em auto;
					background-image: url('../../../../assets/images/modules/tasks/organize/deli-2-shelves/can-onions.svg');
				}
				&.can-tomatos {
					background-size: 2.1em auto;
					background-image: url('../../../../assets/images/modules/tasks/organize/deli-2-shelves/can-tomatos.svg');
				}
				&.can-peas {
					background-size: 2.62em auto;
					background-image: url('../../../../assets/images/modules/tasks/organize/deli-2-shelves/can-peas.svg');
				}
			}
		}
	}

	&.deli-2-sandwiches {
		.OrganizeDndDragLayer-item {
			width: 6.9em;
			height: 3em;
			.OrganizeDndDragLayer-img {
				background-position: bottom center;
				background-size: 100% auto;
				&.sandwich-1 {background-image: url('../../../../assets/images/modules/tasks/organize/deli-2-sandwiches/sandwich-1.svg');}
				&.sandwich-2 {background-image: url('../../../../assets/images/modules/tasks/organize/deli-2-sandwiches/sandwich-2.svg');}
				&.sandwich-3 {background-image: url('../../../../assets/images/modules/tasks/organize/deli-2-sandwiches/sandwich-3.svg');}
				&.sandwich-4 {background-image: url('../../../../assets/images/modules/tasks/organize/deli-2-sandwiches/sandwich-4.svg');}
				&.sandwich-5 {background-image: url('../../../../assets/images/modules/tasks/organize/deli-2-sandwiches/sandwich-5.svg');}
				&.sandwich-6 {background-image: url('../../../../assets/images/modules/tasks/organize/deli-2-sandwiches/sandwich-6.svg');}
			}
		}
	}

	&.orientation-1-restock-bread {
		.OrganizeDndDragLayer-item { 
			width: 3.6em;
			height: 6.4em;
			.OrganizeDndDragLayer-img {
				background-position: bottom center;
				background-size: 100% auto;
				&.bread-blue-1 {background-image: url('../../../../assets/images/modules/tasks/organize/orientation-1-restock-bread/bread-blue-1.svg');}
				&.bread-blue-2 {background-image: url('../../../../assets/images/modules/tasks/organize/orientation-1-restock-bread/bread-blue-2.svg');}
				&.bread-blue-3 {background-image: url('../../../../assets/images/modules/tasks/organize/orientation-1-restock-bread/bread-blue-3.svg');}
				&.bread-blue-1,
				&.bread-blue-2,
				&.bread-blue-3 {
					background-size: 3.55em auto;
				}
				&.bread-green-1 {background-image: url('../../../../assets/images/modules/tasks/organize/orientation-1-restock-bread/bread-green-1.svg');}
				&.bread-green-2 {background-image: url('../../../../assets/images/modules/tasks/organize/orientation-1-restock-bread/bread-green-2.svg');}
				&.bread-green-3 {background-image: url('../../../../assets/images/modules/tasks/organize/orientation-1-restock-bread/bread-green-3.svg');}
				&.bread-green-1,
				&.bread-green-2,
				&.bread-green-3 {
					background-size: 3.5em auto;
				}
				&.bread-red-1 {background-image: url('../../../../assets/images/modules/tasks/organize/orientation-1-restock-bread/bread-red-1.svg');}
				&.bread-red-2 {background-image: url('../../../../assets/images/modules/tasks/organize/orientation-1-restock-bread/bread-red-2.svg');}
				&.bread-red-1,
				&.bread-red-2 {
					background-size: 3.44em auto;
				}
			}
		}
	}

	&.seafood-1-bad-shellfish {
		.OrganizeDndDragLayer-item {
			width: 7.7em;
			height: 4.7em;
			.OrganizeDndDragLayer-img {
				background-position: bottom center;
				background-size: 100% auto;
				&.lobster {
					background-image: url('../../../../assets/images/modules/tasks/organize/seafood-1-bad-shellfish/lobster.svg');
				}
				&.clam {
					background-image: url('../../../../assets/images/modules/tasks/organize/seafood-1-bad-shellfish/clam.svg');
					background-size: 4.2em 4.6em;
				}
				&.shrimp {
					background-image: url('../../../../assets/images/modules/tasks/organize/seafood-1-bad-shellfish/shrimp.svg');
				}
				&.oyster {
					background-image: url('../../../../assets/images/modules/tasks/organize/seafood-1-bad-shellfish/oyster.svg');
					background-size: 4.8em 4em;
				}
				&.crab {
					background-image: url('../../../../assets/images/modules/tasks/organize/seafood-1-bad-shellfish/crab.svg');
				}
			}
		}
	}
}