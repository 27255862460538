@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/mixins_backgrounds';
@import '../../../styles/animations';
@import '../../../styles/animations-intro';
.Checklist {
	position: relative;
	display: inline-block;
	padding: 0.5em;
	background-color: white;
	border-radius: 0.5em;
	color: #11466B;

	.Checklist-title {
		text-align: center;
		margin-bottom: 0.5em;
		span {
			font-size: 1.1em;
			font-weight: bold;
		}
	}
	.Checklist-items {
		.Checklist-item {
			height: 1.5em;
			padding-left: 1.5em;
			background-image: url('../../../assets/images/modules/icon-error.svg');
			background-size: 0.8em auto;
			background-position: left center;
			@include flex('flex-start', 'center');
			span {
				font-size: 0.8em;
			}
			&.checked {
				background-image: url('../../../assets/images/modules/icon-check2.svg');
			}
		}
	}


	&.deli-1 {
		position: absolute;
		bottom: 7em;
		left: calc(50% - 1em);
	}
	&.bakery-2 {
		position: absolute;
		width: 15em;
		height: 20.4em;
		top: 10em;
		left: calc(50% - 7.5em);
		background-color: transparent;
		background-image: url('../../../assets/images/modules/tasks/dialogue/checkinglist.svg');
		padding: 5.11em 3em 0 3em;
		.Checklist-title {
			text-align: center;
			margin-bottom: 0.5em;
			span {
				font-weight: bold;
				text-transform: uppercase;
			}
		}
	}
	&.branching-story {
		width: 15em;
		height: 20.4em;
		color: #11466B;
		background-color: transparent;
		background-image: url('../../../assets/images/modules/tasks/dialogue/checkinglist.svg');
		padding: 4.8em 2.5em 0 2.5em;
		.Checklist-title span {
			text-transform: uppercase;	
		}
		.Checklist-item {
			height: auto;
			margin-bottom: 0.5em;
			line-height: 1.1;
			span {
				font-size: 1em;
				text-transform: uppercase;
			}
			&.checked {
				background-image: url('../../../assets/images/modules/icon-check2.svg');
			}
		}
		&.food-safety {
			.Checklist-item {
				span {
					font-size: 0.95em;
				}
			}
		}
	} 
}