@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/mixins_backgrounds';
@import '../../../styles/animations';
@import '../../../styles/animations-intro';
.AvatarMaker {
	position: relative;
	height: 100%;
	@include avatar-maker-background();
	&.landscape {
		@include avatar-maker-landscape-background();
		.AvatarMaker-backBtn {left: calc(50% - 13.5em);}
		.AvatarMaker-profileBtn {right: calc(50% - 13.5em);}
		.AvatarMaker-panel {
			text-align: center;
			.AvatarMaker-categories {
				@include flex('center', 'flex-start');	
			}
			.AvatarMaker-options {
				display: inline-block;
				width: auto;
				max-width: 100%;
			}
		}
	}

	.AvatarMaker-backBtn {
		position: absolute;
		top: 0.25em;
		left: 0.25em;
	}

	.AvatarMaker-profileBtn {
		position: absolute;
		top: 0.25em;
		right: 0.25em;
		width: 3em;
		height: 3em;
		@include flex('center', 'center');
	}

	.AvatarMaker-avatar {
		position: absolute;
		left: -0.5em;
		bottom: 13.3em;
		width: 100%;

		.AvatarMaker-generatingAvatar {
			margin: auto;
			width: 15em;
			height: 15em;
			text-align: center;
			background-image: url('../../../assets/images/avatar-maker/generating-avatar.svg');
			background-size: 3em auto;
			padding-top: 9.5em;
			color: #10779e;
		}
	}

	.AvatarMaker-saveBtn {
		position: absolute;
		top: 1em;
		left: calc(50% - 2.25em);
		span {font-size: 0.89em;}
	}

	.AvatarMaker-panel {
		position: absolute;
		width: 100%;
		height: 14.25em;
		left: 0;
		bottom: 0;
		background-color: #F5F5F5;

		.AvatarMaker-indicatorRight {
			position: absolute;
			pointer-events: none;
			width: 1em;
			height: calc(2.25em + 0.11em + 1px);
			top: 0;
			right: 0;

			&.active {
				float: right;
				background-image: url('../../../assets/images/modules/icon-arrow-right.svg');
				background-size: 1em 1em;
				@include pulse(2s);
			}
		}

		.AvatarMaker-indicatorLeft {
			position: absolute;
			pointer-events: none;
			width: 1em;
			height: calc(2.25em + 0.11em + 1px);
			top: 0;
			left: 0;

			&.active {
				float: left;
				@include rotate(180deg);
				background-image: url('../../../assets/images/modules/icon-arrow-right.svg');
				background-size: 1em 1em;
				@include pulse(2s);
			}
		}

		.AvatarMaker-categories {
			position: relative;
			width: 100%;
			height: 2.25em;
			overflow: auto;
			background-color: #10779E;
			background-image: linear-gradient(#10779E, #15476A);
			background-size: 100% 100%;
			border-top: calc(0.11em + 1px) solid #7DBDE8;
			box-sizing: content-box;
			@include flex('flex-start', 'flex-start');
			@include hide-scrollbar();


			.AvatarMaker-category {
				position: relative;
				width: 2.25em;
				min-width: 2.25em;
				max-width: 2.25em;
				height: 2.25em;
				cursor: pointer;
				.AvatarMaker-categoryImage {
					width: 100%;
					height: 100%;
					background-size: 1.5em auto;
					background-position: center center;
					background-repeat: no-repeat;
					@include opacity(0.5);
					&.selected {@include opacity(1);}
				
					&.body-type {
						background-size: 1.11em auto;
						background-image: url('../../../assets/images/avatar-maker/category-icons/body-type.svg');
					}
					&.skin-color {
						background-size: 1.5em auto;
						background-image: url('../../../assets/images/avatar-maker/category-icons/skin-color.svg');
					}
					&.eyes {
						background-size: 1.48em auto;
						background-image: url('../../../assets/images/avatar-maker/category-icons/eyes.svg');
					}
					&.nose {
						background-size: 1.04em auto;
						background-image: url('../../../assets/images/avatar-maker/category-icons/nose.svg');
					}
					&.mouth {
						background-size: 1.75em auto;
						background-image: url('../../../assets/images/avatar-maker/category-icons/mouth.svg');
					}
					&.hair {
						background-size: 1.4em auto;
						background-image: url('../../../assets/images/avatar-maker/category-icons/hair.svg');
					}
					&.beard {
						background-size: 1.4em auto;
						background-image: url('../../../assets/images/avatar-maker/category-icons/beard.svg');
					}
					&.glasses {
						background-size: 1.75em auto;
						background-image: url('../../../assets/images/avatar-maker/category-icons/glasses.svg');
					}
					&.clothes {
						background-size: 1.69em auto;
						background-image: url('../../../assets/images/avatar-maker/category-icons/clothes.svg');
					}
					&.hats {
						background-size: 1.75em auto;
						background-image: url('../../../assets/images/avatar-maker/category-icons/hats.svg');
					}
					&.work-clothes {
						background-size: 0.98em auto;
						background-image: url('../../../assets/images/avatar-maker/category-icons/apron.svg');
					}
					&.accessories {
						background-size: 1.5em auto;
						background-image: url('../../../assets/images/avatar-maker/category-icons/accessories.svg');
					}
					&.items {
						background-size: 0.9em auto;
						background-image: url('../../../assets/images/avatar-maker/category-icons/items.svg');
					}
					&.animals {
						background-size: 1.5em auto;
						background-image: url('../../../assets/images/avatar-maker/category-icons/animals.svg');
					}
					&.decorations {
						background-size: 1.5em auto;
						background-image: url('../../../assets/images/avatar-maker/category-icons/decorations.svg');
					}
				}
				.AvatarMaker-categoryTag {
					position: absolute;
					top: 0.2em;
					left: 0;
					width: 0.75em;
					height: 0.75em;
					border-radius: 100%;
					padding-top: 0.05em;
					background-color: #F26D6F;
					text-align: center;
					span {
						display: block;
						color: white;
						font-size: 0.6em;
					}
				}
			}
		}

		.AvatarMaker-options {
			width: 100%;
			height: calc(100% - 2.25em);
			min-height: 11.11em;
			padding: 0 0.35em;
			overflow: auto;
			@include flex('center', 'flex-start', 'column');
			
			.AvatarMaker-noOptions {
				padding: 0 1em;
				height: 100%;
				@include flex('center', 'center');
				p {
					margin: 0;
					font-size: 0.85em;
					color: #11466B;
				}	
			}

			.AvatarMaker-optionRow {
				@include flex('flex-start', 'center');

				.AvatarMaker-option {
					position: relative;
					cursor: pointer;
					text-align: center;
					margin: 0.75em 0.35em;
					img {
						width: 100%;
						height: auto;
					}
					&.selected {
						background-color: #FFFFFF;
						border-radius: 1em;
						@include box-shadow(0, 0, 0.33em, 0, rgba(black, 0.16));
					}

					&.not-seen {
						&::after {
							content: '';
							position: absolute;
						top: 0.2em;
						left: 0;
						width: 0.75em;
						height: 0.75em;
						border-radius: 100%;
						padding-top: 0.05em;
						background-color: #F26D6F;
						text-align: center;
						}
					}
					&.option-none {
						background-image: url('../../../assets/images/avatar-maker/none.svg');
						background-size: 66.67% auto;
						background-position: center center;
						background-repeat: no-repeat;
						&.body-f,
						&.body-f2 {
							svg #Skin_F {display: block;}
						}
						&.body-m,
						&.body-m2 {
							svg #Skin_M {display: block;}
						}	
					}
					&.body-type {
						height: 9.75em;
						padding: 0.5em;
						&.body-f,
						&.body-f2 {
							width: 4.75em;
							svg {
								width: 3.75em;
								#Path_8861, #Rectangle_2209, #Path_8857, #Path_8858, #Path_8863 {fill:$skin1a;}
								#Path_8862, #Path_8859, #Path_8860 {fill: $skin1b;}
							}
							&.color-2 svg {
								#Path_8861, #Rectangle_2209, #Path_8857, #Path_8858, #Path_8863 {fill:$skin2a;}
								#Path_8862, #Path_8859, #Path_8860 {fill: $skin2b;}	
							}
							&.color-3 svg {
								#Path_8861, #Rectangle_2209, #Path_8857, #Path_8858, #Path_8863 {fill:$skin3a;}
								#Path_8862, #Path_8859, #Path_8860 {fill: $skin3b;}	
							}
							&.color-4 svg {
								#Path_8861, #Rectangle_2209, #Path_8857, #Path_8858, #Path_8863 {fill:$skin4a;}
								#Path_8862, #Path_8859, #Path_8860 {fill: $skin4b;}	
							}
							&.color-5 svg {
								#Path_8861, #Rectangle_2209, #Path_8857, #Path_8858, #Path_8863 {fill:$skin5a;}
								#Path_8862, #Path_8859, #Path_8860 {fill: $skin5b;}	
							}
							&.color-6 svg {
								#Path_8861, #Rectangle_2209, #Path_8857, #Path_8858, #Path_8863 {fill:$skin6a;}
								#Path_8862, #Path_8859, #Path_8860 {fill: $skin6b;}	
							}
							&.color-7 svg {
								#Path_8861, #Rectangle_2209, #Path_8857, #Path_8858, #Path_8863 {fill:$skin7a;}
								#Path_8862, #Path_8859, #Path_8860 {fill: $skin7b;}	
							}
							&.color-8 svg {
								#Path_8861, #Rectangle_2209, #Path_8857, #Path_8858, #Path_8863 {fill:$skin8a;}
								#Path_8862, #Path_8859, #Path_8860 {fill: $skin8b;}	
							}
							&.color-9 svg {
								#Path_8861, #Rectangle_2209, #Path_8857, #Path_8858, #Path_8863 {fill:$skin9a;}
								#Path_8862, #Path_8859, #Path_8860 {fill: $skin9b;}	
							}
							&.color-10 svg {
								#Path_8861, #Rectangle_2209, #Path_8857, #Path_8858, #Path_8863 {fill:$skin10a;}
								#Path_8862, #Path_8859, #Path_8860 {fill: $skin10b;}	
							}
						}
						&.body-m,
						&.body-m2 {
							width: 5.75em;
							svg {
								width: 4.75em;
								#Rectangle_2208, #Path_8824, #Path_52448, #Path_52449, #Ellipse_166, #Ellipse_167, #Ellipse_168 {fill:$skin1a;}
								#Path_8825, #Path_8829, #Path_8830 {fill: $skin1b}
							}
							&.color-2 svg {
								#Rectangle_2208, #Path_8824, #Path_52448, #Path_52449, #Ellipse_166, #Ellipse_167, #Ellipse_168 {fill:$skin2a;}
								#Path_8825, #Path_8829, #Path_8830 {fill: $skin2b}
							}
							&.color-3 svg {
								#Rectangle_2208, #Path_8824, #Path_52448, #Path_52449, #Ellipse_166, #Ellipse_167, #Ellipse_168 {fill:$skin3a;}
								#Path_8825, #Path_8829, #Path_8830 {fill: $skin3b}
							}
							&.color-4 svg {
								#Rectangle_2208, #Path_8824, #Path_52448, #Path_52449, #Ellipse_166, #Ellipse_167, #Ellipse_168 {fill:$skin4a;}
								#Path_8825, #Path_8829, #Path_8830 {fill: $skin4b}
							}
							&.color-5 svg {
								#Rectangle_2208, #Path_8824, #Path_52448, #Path_52449, #Ellipse_166, #Ellipse_167, #Ellipse_168 {fill:$skin5a;}
								#Path_8825, #Path_8829, #Path_8830 {fill: $skin5b}
							}
							&.color-6 svg {
								#Rectangle_2208, #Path_8824, #Path_52448, #Path_52449, #Ellipse_166, #Ellipse_167, #Ellipse_168 {fill:$skin6a;}
								#Path_8825, #Path_8829, #Path_8830 {fill: $skin6b}
							}
							&.color-7 svg {
								#Rectangle_2208, #Path_8824, #Path_52448, #Path_52449, #Ellipse_166, #Ellipse_167, #Ellipse_168 {fill:$skin7a;}
								#Path_8825, #Path_8829, #Path_8830 {fill: $skin7b}
							}
							&.color-8 svg {
								#Rectangle_2208, #Path_8824, #Path_52448, #Path_52449, #Ellipse_166, #Ellipse_167, #Ellipse_168 {fill:$skin8a;}
								#Path_8825, #Path_8829, #Path_8830 {fill: $skin8b}
							}
							&.color-9 svg {
								#Rectangle_2208, #Path_8824, #Path_52448, #Path_52449, #Ellipse_166, #Ellipse_167, #Ellipse_168 {fill:$skin9a;}
								#Path_8825, #Path_8829, #Path_8830 {fill: $skin9b}
							}
							&.color-10 svg {
								#Rectangle_2208, #Path_8824, #Path_52448, #Path_52449, #Ellipse_166, #Ellipse_167, #Ellipse_168 {fill:$skin10a;}
								#Path_8825, #Path_8829, #Path_8830 {fill: $skin10b}
							}
						}
						&.body-f2 {
							width: calc(1.2 * 3.75em + 1em);
							svg {width: calc(1.2 * 3.75em);}	
						}
						&.body-m2 {
							width: calc(1.2 * 4.75em + 1em);
							svg {width: calc(1.2 * 4.75em);}
						} 
					}

					&.skin-color {
						width: 3em;
						height: 3em;
						@include flex('center', 'center');
						span {
							display: inline-block;
							width: 2em;
							height: 2em;
							border-radius: 2em;
						}
						&.color-1 span {background-color: $skin1a;}
						&.color-2 span {background-color: $skin2a;}
						&.color-3 span {background-color: $skin3a;}
						&.color-4 span {background-color: $skin4a;}
						&.color-5 span {background-color: $skin5a;}
						&.color-6 span {background-color: $skin6a;}
						&.color-7 span {background-color: $skin7a;}
						&.color-8 span {background-color: $skin8a;}
						&.color-9 span {background-color: $skin9a;}
						&.color-10 span {background-color: $skin10a;}
					}

					&.hair {
						width: 3.5em;
						height: 4em;
						@include flex('center', 'center');
						svg {
							width: 3em;
							#Skin_M,
							#Skin_F,
							#Hair_M > g,
							#Hair_M > path,
							#Hair_F > g, 
							#Hair_F > path, 
							#Hair_Back_F > g,
							#Hair_Back_F > path {
								display: none;
							}
						}
						&.body-f,
						&.body-f2 {
							svg #Skin_F {display: block;}
						}
						&.body-m,
						&.body-m2 {
							svg #Skin_M {display: block;}
						}					
						&.Hair_01 svg #Hair_01,
						&.Hair_02 svg #Hair_02,
						&.Hair_03 svg #Hair_03,
						&.Hair_04 svg #Hair_04,
						&.Hair_05 svg #Hair_05,
						&.Hair_06 svg #Hair_06,
						&.Hair_07 svg #Hair_07,
						&.Hair_08 svg #Hair_08,
						&.Hair_09 svg #Hair_09,
						&.Hair_01-2 svg #Hair_01-2,
						&.Hair_Back_01 svg #Hair_Back_01,
						&.Hair_02-2 svg #Hair_02-2,
						&.Hair_back_02 svg #Hair_back_02,
						&.Hair_03-2 svg #Hair_03-2,
						&.Hair_Back_03 svg #Hair_Back_03,
						&.Hair_04-2 svg #Hair_04-2,
						&.Hair_Back_04 svg #Hair_Back_04,
						&.Hair_05-2 svg #Hair_05-2,
						&.Hair_06-2 svg #Hair_06-2,
						&.Hair_Back_06 svg #Hair_Back_06, 
						&.Hair_07-2 svg #Hair_07-2,
						&.Hair_Back_07 svg #Hair_Back_07,
						&.Hair_08-2 svg #Hair_08-2,
						&.Hair_Back_08 svg #Hair_Back_08,
						&.Hair_09-2 svg #Hair_08-9,
						&.Hair_Back_09 svg #Hair_Back_09 {
							display: block;
						} 
					}

					&.nose {
						width: 3.25em;
						height: 3em;
						@include flex('center', 'center');
						svg {
							width: 1.5em;
							#Nose_F > g,
							#Nose_M > g {
								display: none;
							}
						}

						&.color-1 svg #background-01 path,
						&.color-1 svg #background-02 path,
						&.color-1 svg #background-03 path,
						&.color-1 svg #background-04 path,
						&.color-1 svg #background-05 path,
						&.color-1 svg #background-06 path,
						&.color-1 svg #background-01-2 path,
						&.color-1 svg #background-02-2 path,
						&.color-1 svg #background-03-2 path,
						&.color-1 svg #background-04-2 path,
						&.color-1 svg #background-05-2 path,
						&.color-1 svg #background-06-2 path {
							fill: $skin1a;
						}
						&.color-2 svg #background-01 path,
						&.color-2 svg #background-02 path,
						&.color-2 svg #background-03 path,
						&.color-2 svg #background-04 path,
						&.color-2 svg #background-05 path,
						&.color-2 svg #background-06 path,
						&.color-2 svg #background-01-2 path,
						&.color-2 svg #background-02-2 path,
						&.color-2 svg #background-03-2 path,
						&.color-2 svg #background-04-2 path,
						&.color-2 svg #background-05-2 path,
						&.color-2 svg #background-06-2 path {
							fill: $skin2a;
						}
						&.color-3 svg #background-01 path,
						&.color-3 svg #background-02 path,
						&.color-3 svg #background-03 path,
						&.color-3 svg #background-04 path,
						&.color-3 svg #background-05 path,
						&.color-3 svg #background-06 path,
						&.color-3 svg #background-01-2 path,
						&.color-3 svg #background-02-2 path,
						&.color-3 svg #background-03-2 path,
						&.color-3 svg #background-04-2 path,
						&.color-3 svg #background-05-2 path,
						&.color-3 svg #background-06-2 path {
							fill: $skin3a;
						}
						&.color-4 svg #background-01 path,
						&.color-4 svg #background-02 path,
						&.color-4 svg #background-03 path,
						&.color-4 svg #background-04 path,
						&.color-4 svg #background-05 path,
						&.color-4 svg #background-06 path,
						&.color-4 svg #background-01-2 path,
						&.color-4 svg #background-02-2 path,
						&.color-4 svg #background-03-2 path,
						&.color-4 svg #background-04-2 path,
						&.color-4 svg #background-05-2 path,
						&.color-4 svg #background-06-2 path {
							fill: $skin4a;
						}
						&.color-5 svg #background-01 path,
						&.color-5 svg #background-02 path,
						&.color-5 svg #background-03 path,
						&.color-5 svg #background-04 path,
						&.color-5 svg #background-05 path,
						&.color-5 svg #background-06 path,
						&.color-5 svg #background-01-2 path,
						&.color-5 svg #background-02-2 path,
						&.color-5 svg #background-03-2 path,
						&.color-5 svg #background-04-2 path,
						&.color-5 svg #background-05-2 path,
						&.color-5 svg #background-06-2 path {
							fill: $skin5a;
						}
						&.color-6 svg #background-01 path,
						&.color-6 svg #background-02 path,
						&.color-6 svg #background-03 path,
						&.color-6 svg #background-04 path,
						&.color-6 svg #background-05 path,
						&.color-6 svg #background-06 path,
						&.color-6 svg #background-01-2 path,
						&.color-6 svg #background-02-2 path,
						&.color-6 svg #background-03-2 path,
						&.color-6 svg #background-04-2 path,
						&.color-6 svg #background-05-2 path,
						&.color-6 svg #background-06-2 path {
							fill: $skin6a;
						}
						&.color-7 svg #background-01 path,
						&.color-7 svg #background-02 path,
						&.color-7 svg #background-03 path,
						&.color-7 svg #background-04 path,
						&.color-7 svg #background-05 path,
						&.color-7 svg #background-06 path,
						&.color-7 svg #background-01-2 path,
						&.color-7 svg #background-02-2 path,
						&.color-7 svg #background-03-2 path,
						&.color-7 svg #background-04-2 path,
						&.color-7 svg #background-05-2 path,
						&.color-7 svg #background-06-2 path {
							fill: $skin7a;
						}
						&.color-8 svg #background-01 path,
						&.color-8 svg #background-02 path,
						&.color-8 svg #background-03 path,
						&.color-8 svg #background-04 path,
						&.color-8 svg #background-05 path,
						&.color-8 svg #background-06 path,
						&.color-8 svg #background-01-2 path,
						&.color-8 svg #background-02-2 path,
						&.color-8 svg #background-03-2 path,
						&.color-8 svg #background-04-2 path,
						&.color-8 svg #background-05-2 path,
						&.color-8 svg #background-06-2 path {
							fill: $skin8a;
						}
						&.color-9 svg #background-01 path,
						&.color-9 svg #background-02 path,
						&.color-9 svg #background-03 path,
						&.color-9 svg #background-04 path,
						&.color-9 svg #background-05 path,
						&.color-9 svg #background-06 path,
						&.color-9 svg #background-01-2 path,
						&.color-9 svg #background-02-2 path,
						&.color-9 svg #background-03-2 path,
						&.color-9 svg #background-04-2 path,
						&.color-9 svg #background-05-2 path,
						&.color-9 svg #background-06-2 path {
							fill: $skin9a;
						}
						&.color-10 svg #background-01 path,
						&.color-10 svg #background-02 path,
						&.color-10 svg #background-03 path,
						&.color-10 svg #background-04 path,
						&.color-10 svg #background-05 path,
						&.color-10 svg #background-06 path,
						&.color-10 svg #background-01-2 path,
						&.color-10 svg #background-02-2 path,
						&.color-10 svg #background-03-2 path,
						&.color-10 svg #background-04-2 path,
						&.color-10 svg #background-05-2 path,
						&.color-10 svg #background-06-2 path {
							fill: $skin10a;
						}
						&.Nose_01 svg #Nose_01,
						&.Nose_02 svg #Nose_02,
						&.Nose_03 svg #Nose_03,
						&.Nose_04 svg #Nose_04,
						&.Nose_05 svg #Nose_05,
						&.Nose_06 svg #Nose_06,
						&.Nose_01-2 svg #Nose_01-2,
						&.Nose_02-2 svg #Nose_02-2,
						&.Nose_03-2 svg #Nose_03-2,
						&.Nose_04-2 svg #Nose_04-2,
						&.Nose_05-2 svg #Nose_05-2,
						&.Nose_06-2 svg #Nose_06-2 {
							display: block;
						}
					}
					 

					&.eyes,
					&.mouth,
					&.beard,
					&.glasses,
					&.clothes,
					&.hats,
					&.work-clothes,
					&.accessories,
					&.items,
					&.animals,
					&.decorations {
						height: 3em;
						width: 3em;
						@include flex('center', 'center');
						img {
							height: auto;
						}

						&.option-jarzard {
							img {
								width: 1.5em;
							}
						}
					}
					&.eyes {
						width: 3.5em;
						img {width: 3em;}
					}
					&.mouth {
						width: 3em;
						img {width: 2em;}
					}
					&.beard {
						width: 4em;
						img {width: 3em;}
					}
					&.glasses {
						width: 3.5em;
						img {width: 3em;}
					}
					&.clothes {
						width: 5em;
						height: 5em;
						margin: 0.25em 0.35em;
						img {
							width: 4em;
						}
					}
					&.hats {
						width: 4em; 
						height: 4.25em;
						img {width: 3em;}
					}
					&.work-clothes {
						width: 3em; 
						height: 4em;
						img {width: 3em; max-height: 3em;}
					}
					&.accessories {
						height: 4em;
						img {width: 2.5em;}
					}
					&.items {
						width: 4em;
						height: 4em;
						img {max-width: 3em; max-height: 3em;}
					}
					&.animals {
						width: 4em;
						height: 4.25em;
						img {width: 3em; max-height: 5em}
					}
					&.decorations {
						width: 4.5em;
						height: 5em;
						img {width: 4em; max-height: 5em}
					}
				}
			}
			
		}
	}
}