@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/mixins_backgrounds';
@import '../../../styles/animations';
@import '../../../styles/animations-intro';
.PopupLootBox {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 13;
	@include flex('center', 'center');

	.PopupLootBox-background {
		position: absolute;
		width: 150vh;
		height: 150vh;
		background-image: url('../../../assets/images/map/loot-box/loot-box-background.svg'),radial-gradient(#10779E, #15476A);
		background-position: center, center;
		background-size: cover;
		@include origin(50%, 50%);
	}
	
	.PopupLootBox-content {
		position: relative;
		width: 25.8em;
		height: 25.8em;
		color: white;
		@include flex('center', 'center', 'column');
		.PopupLootBox-title {
			
			margin-bottom: 0.5em;
			@include fadeIn(0.5s, ease, 0s);
			span {
				font-size: 1.56em;
				font-weight: 900;
			}
		}
		.PopupLootBox-text {
			padding: 0 4em;
			text-align: center;
			@include fadeIn(0.5s, ease, 0.5s);
			p {
				margin: 0;
				font-size: 1em;
				font-weight: 500;
			}
		}

		.PopupLootBox-image {
			position: relative;
			width: 100%;
			height: 20em;
			background-size: 17em auto;
			background-image: url('../../../assets/images/map/loot-box/loot-box-closed.svg');
			@include bounceIn();
			.PopupLootBox-options {
				.PopupLootBox-option {
					position: absolute;
					width: 6em;
					height: 7.5em;
					bottom: 9.5em;
					left: calc(50% - 3em);
					&.animation-1-2 {
						@include animateLootBox1Of2();
					}
					&.animation-2-2 {
						@include animateLootBox2Of2();
					}
				}
			}
			.PopupLootBox-prize {
				width: 100%;
				height: 100%;
			}
			.PopupLootBox-options .PopupLootBox-option,
			.PopupLootBox-prize {
				&.bunny {
					background-size: 5em auto;
					background-image: url('../../../assets/images/avatar-maker/animals/bunny.svg');
				}
				&.canary {
					background-size: 5em auto;
					background-image: url('../../../assets/images/avatar-maker/animals/canary.svg');
				}
				&.cat {
					background-size: 4.5em auto;
					background-image: url('../../../assets/images/avatar-maker/animals/cat.svg');
				}
				&.dog {
					background-size: 6em auto;
					background-image: url('../../../assets/images/avatar-maker/animals/dog.svg');
				}
				&.fish {
					background-size: 5.5em auto;
					background-image: url('../../../assets/images/avatar-maker/animals/fish.svg');
				}
				&.hamster {
					background-size: 3.7em auto;
					background-image: url('../../../assets/images/avatar-maker/animals/hamster.svg');
				}
				&.hedgehog {
					background-size: 5em auto;
					background-image: url('../../../assets/images/avatar-maker/animals/hedgehog.svg');
				}
				&.lizard {
					background-size: 4em auto;
					background-image: url('../../../assets/images/avatar-maker/animals/lizard.svg');
				}
				&.snake {
					background-size: 5em auto;
					background-image: url('../../../assets/images/avatar-maker/animals/snake.svg');
				}
				&.turtle {
					background-size: 6em auto;
					background-image: url('../../../assets/images/avatar-maker/animals/turtle.svg');
				}
			}
		}
	}

	&.landscape {
		.PopupLootBox-background {
			width: 150vw;
			height: 150vw;
		}
	}
	
	&.choose {
		.PopupLootBox-background {
			@include animateRotate(3s);
		}
		.PopupLootBox-content {
			.PopupLootBox-image {
				background-size: 19.46em auto;
				background-position: top calc(50% - 0.46em) left calc(50% - 1.23em);
				background-image: url('../../../assets/images/map/loot-box/loot-box-open.svg');
			}
		}
	}
	&.empty .PopupLootBox-content {
		margin-top: -6em;
		.PopupLootBox-image {
			height: 15em;
			background-image: radial-gradient(circle, rgba(#FFFFFF, 0.8) 0%, rgba(#FFFFFF, 0) 50%);

		}
	}
}
