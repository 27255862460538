.MultipleChoice {
	&.cleaning-1-common-schedules {
		.MultipleChoice-options {
			width: 18em;
			left: calc(50% - 9em);
			.MultipleChoice-optionsWrap {
				width: 100%;
				height: 17em;
				padding: 0.72em 1em;
				background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/cleaning-1-common-schedules/background.svg');
				background-position: top center;
				.MultipleChoice-option {
					display: inline-block;
					width: 5.3em;
					height: 6.8em;
					background-size: 5em auto;
					background-position: top center;
					span:nth-child(2) {
						left: calc(50% - 0.75em);
						top: calc(50% - 0.75em);
					}
					&.option-1 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/cleaning-1-common-schedules/option-1.svg');
					}
					&.option-2 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/cleaning-1-common-schedules/option-2.svg');
					}
					&.option-3 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/cleaning-1-common-schedules/option-3.svg');
					}
					&.option-4 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/cleaning-1-common-schedules/option-4.svg');
					}
					&.option-5 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/cleaning-1-common-schedules/option-5.svg');
					}
				}
			}
		}
	}

	&.cleaning-1-daily-cleaning-tools {
		.MultipleChoice-options {
			width: 18em;
			left: calc(50% - 9em);
			.MultipleChoice-optionsWrap {
				width: 100%;
				height: 22.5em;
				margin-top: 1em;
				.MultipleChoice-option {
					position: absolute;
					width: 6em;
					height: 5em;
					span:nth-child(2) {
						left: auto;
						right: 1em;
						top: calc(50% - 0.75em);
					}

					&.option-1 {
						height: 15.7em;
						bottom: 0;
						right: 0;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/cleaning-1-daily-cleaning-tools/option-1.svg');
					}
					&.option-2 {
						height: 15.7em;
						bottom: 0;
						left: 0;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/cleaning-1-daily-cleaning-tools/option-2.svg');
					}
					&.option-3 {
						top: 0;
						left: 6em;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/cleaning-1-daily-cleaning-tools/option-3.svg');
					}
					&.option-4 {
						height: 15.7em;
						bottom: 0;
						left: 6em;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/cleaning-1-daily-cleaning-tools/option-4.svg');
					}
					&.option-5 {
						top: 0;
						left: 0;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/cleaning-1-daily-cleaning-tools/option-5.svg');
					}
					&.option-6 {
						top: 0;
						right: 0;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/cleaning-1-daily-cleaning-tools/option-6.svg');
					}
				}
			}
		}
	}

	&.cleaning-1-day-most-cleaning {
		.MultipleChoice-options {
			width: 16.5em;
			left: calc(50% - 8.25em);
			.MultipleChoice-optionsWrap {
				width: 100%;
				height: 22.7em;
				background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/cleaning-1-day-most-cleaning/background.svg');
				background-position: top center;
				.MultipleChoice-option {
					position: absolute;
					width: 10.87em;
					height: 1.85em;
					left: 2.5em;
    			top: 6.7em;
					span:nth-child(2) {
						left: auto;
						right: 0;
						top: calc(50% - 0.75em);	
					}
					&.option-2 {
						top: calc(6.7em + 1.85em);
					}
					&.option-3 {
						top: calc(6.7em + 2*1.85em);
					}
					&.option-4 {
						top: calc(6.7em + 3*1.85em);
					}
					&.option-5 {
						top: calc(6.7em + 4*1.85em);
					}
					&.option-6 {
						top: calc(6.7em + 5*1.85em);
					}
					&.option-7 {
						top: calc(6.7em + 6*1.85em);
					}
				}
			}
		}
	}
	&.cleaning-1-dirty-signs {
		.MultipleChoice-options {
			width: 13.5em;
			left: calc(50% - 6.75em);
			.MultipleChoice-optionsWrap {
				width: 100%;
				height: 17.5em;
				margin-top: 1em;
				.MultipleChoice-option {
					position: absolute;
					width: 5.33em;
					height: 8.48em;
					span:nth-child(2) {
						left: auto;
						right: 1em;
						top: calc(50% - 0.75em);
					}

					&.option-1 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/cleaning-1-dirty-signs/option-1.svg');
					}
					&.option-2 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/cleaning-1-dirty-signs/option-2.svg');
					}
					&.option-3 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/cleaning-1-dirty-signs/option-3.svg');
					}
					&.option-4 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/cleaning-1-dirty-signs/option-4.svg');
					}
					&.position-1 {
						top: 0;
						left: 0
					}
					&.position-2 {
						top: 0;
						right: 0;
					}
					&.position-3 {
						bottom: 0;
						left: 0
					}
					&.position-4 {
						bottom: 0;
						right: 0;
					}
				}
			}
		}
	}
	&.cleaning-1-overlooked-tool {
		.MultipleChoice-options {
			width: 16em;
			left: calc(50% - 8em);
			.MultipleChoice-optionsWrap {
				width: 100%;
				height: 18.7em;
				.MultipleChoice-option {
					display: inline-block;
					width: 8em;
					height: 9.35em;
					span:nth-child(2) {
						left: auto;
						right: 2em;
						top: calc(50% - 0.75em);
					}
					&.option-1 {
						background-size: 7em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/cleaning-1-overlooked-tool/option-1.svg');
					}
					&.option-2 {
						background-size: 2.92em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/cleaning-1-overlooked-tool/option-2.svg');
					}
					&.option-3 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/cleaning-1-overlooked-tool/option-3.svg');
					}
					&.option-4 {
						background-size: 4.4em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/cleaning-1-overlooked-tool/option-4.svg');
					}
				}
			}
		}
	}
	&.cleaning-1-weekly-checklist {
		.MultipleChoice-options {
			width: 16.5em;
			left: calc(50% - 8.25em);
			.MultipleChoice-optionsWrap {
				width: 100%;
				height: 22.7em;
				background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/cleaning-1-weekly-checklist/background.svg');
				background-position: top center;
				.MultipleChoice-option {
					position: absolute;
					width: 11.5em;
					height: 2em;
					left: 2.3em;
    			top: 6.9em;
					span:nth-child(2) {
						left: auto;
						right: 0;
						top: calc(50% - 0.75em);	
					}
					&.option-2 {
						top: calc(6.8em + 2em);
					}
					&.option-3 {
						top: calc(6.8em + 2*2em);
					}
					&.option-4 {
						top: calc(6.8em + 3*2em);
					}
					&.option-5 {
						top: calc(6.8em + 4*2em);
					}
				}
			}
		}
	}
}