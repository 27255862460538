@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';
@import '../../../../styles/mixins_backgrounds';
@import '../../../../styles/animations';
@import '../../../../styles/animations-intro';
.AdminGamePopup {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(#7ABCEA, 0.81);
	@include flex('center', 'center');

	.AdminGamePopup-content {
		width: 95em;
		height: 40em;
		max-height: 100vh;
		background-color: white;
		border-radius: 0.83em;

		.AdminGamePopup-header {
			position: relative;
			width: 100%;
			height: 4.25em;
			background-color: #11466B;
			border-top-left-radius: 0.83em;
			border-top-right-radius: 0.83em;
			border-top: 0.25em solid white;
			border-right: 0.25em solid white;
			border-left: 0.25em solid white;
			.AdminGamePopup-storeName {
				position: absolute;
				left: 2.56em;
				top: 0;
				bottom: 0;
				@include flex('flex-start', 'center');
				span {
					color: #ffffff;
					font-size: 1.4em;
					font-weight: 600;
				}
			}
			.AdminGamePopup-closeBtn {
				position: absolute;
				top: 0;
				right: 0;
				width: 4.25em;
				height: 3.75em;
				background-image: url('../../../../assets/images/facilitator/icon-close.svg');
				background-size: 1.56em auto;
				cursor: pointer;
			}
			.AdminGamePopup-sectionMenu {
				position: absolute;
				bottom: 0em;
				left: 0;
				width: 100%;
				height: 2.35em;
				overflow: hidden;
				@include flex('center', 'flex-end');
				.AdminGamePopup-sectionMenuItem {
					width: 9.11em;
					height: 100%;
					color: #525252;
					background-color: #BFBFBF;
					border-top-left-radius: 0.83em;
					border-top-right-radius: 0.83em;
					padding-bottom: 0.1em;
					margin: 0 0.67em;
					cursor: pointer;
					@include flex('center', 'center');
					span {
						font-size: 1.11em;
						font-weight: 700;
						line-height: 1;
					}
					&.selected {
						background-color: white;
						@include box-shadow(0.56em, 0, 0.26em, 0, rgba(#000000, 0.16));
					}
				}
			}
		}

		.AdminGamePopup-body {
			height: calc(100% - 4.25em);
		}
	}
}