@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/mixins_backgrounds';
@import '../../../styles/animations';
@import '../../../styles/animations-intro';
.AdminStatistics {
	position: relative;
	min-height: 100%;
	&.admin-page {
		padding-top: 6em;
	}
	&.specificGame {
		.AdminStatistics-cell {
			&.title {
				width: 12%;
			}
			&.playersStarted {
				width: 15%;
			}
			&.stars {
				width: 15%;
			}
			&.iconEye {
				width: 5%
			}
		}
	}

	.AdminStatistics-cell {
		width: 20%;
		height: 100%;
		padding: 0 1.5em;
		@include flex('center', 'center');
		&.title {
			@include flex('flex-start', 'center');
		}
	}
	.AdminStatistics-tags {
		position: absolute;
		top: 1em;
		left: 0;
		width: 100%;
		.AdminStatistics-tagsSelect {
			width: 10em;
		}
		.AdminStatistics-selectedTags {
			margin-top: 0.5em;
			.AdminStatistics-tag {
				position: relative;
				display: inline-block;
				border: 0.1em solid #10779E;
				border-radius: 0.4em;
				padding: 0.25em 2em 0.25em 0.5em;
				margin-right: 0.5em;
				cursor: pointer;
				&:hover {
					&::after {
						content: '';
						position: absolute;
						right: 0.5em;
						top: calc(50% - 0.5em);
						width: 1em;
						height: 1em;
						background-image: url('../../../assets/images/facilitator/icon-close.svg');
					}
				}
			}
		}
	}
	.AdminStatistics-header {
		position: relative;
		width: 100%;
		height: 2.33em;
		color: white;
		background-color: #11466B;
		@include flex('space-between', 'center');
		.AdminStatistics-cell {
			@include no-select();
			span {
				display: inline-block;
				vertical-align: middle;
				position: relative;
				font-size: 1.39em;
				font-weight: 600;
			}
			&.title,
			&.playersStarted,
			&.playersCompleted,
			&.time,
			&.mistakes,
			&.stars {
				cursor: pointer;
			}
			&.sortedBy {
				position: relative;
				span::after {
					content: '';
					position: absolute;
					top: 0.2em;
					display: inline-block;
					vertical-align: middle;
					width: 1em;
					height: 1em;
					background-size: 0.6em auto;
					background-image: url('../../../assets/images/facilitator/icon-sort-desc.svg');
				}
				&.ASC span::after {background-image: url('../../../assets/images/facilitator/icon-sort-asc.svg');}
			}
		}
	}
	.AdminStatistics-body {
		position: relative;
		width: 100%;
		.AdminStatistics-row {
			width: 100%;
			height: 2.5em;
			color: #525252;
			&:nth-child(odd) {background-color: #EDEDED;}
			@include flex('space-between', 'center');
			.AdminStatistics-cell {
				&.title {
					span {
						font-weight: 600;
						text-decoration: underline;
						text-align: left;						
					}
				}
				&.iconEye span {
					display: inline-block;		
					background-image: url('../../../assets/images/facilitator/icon-eye.svg');
					background-size: 1.25em auto;
					width: 2.5em;
					height: 2.5em;
					cursor: pointer;
					&:hover {
						background-size: 1.5em auto;
					}
				}
			}
		}
	}

	.AdminStatistics-popupOverlay {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(#7ABCEA, 0.81);
		@include flex('center', 'center');
	}
}