@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/mixins_backgrounds';
@import '../../../styles/animations';
@import '../../../styles/animations-intro';
.ToggleSwitch {
	position: relative;

	width: 2.63em;
	height: 1.22em;
	@include no-select();

	&-checkbox {
		display: none;
	}

	&-label {
		display: block;
		overflow: hidden;
		cursor: pointer;
		border-radius: 1.111em;
		height: 100%;
		width: 100%;
	}
	
	&-inner {
		width: 200%;
		height: 100%;
		margin-left: -100%;
		transition: margin 0.3s ease-in 0s;
		@include flex('center', 'center');
		&:before,
		&:after {
			width: 50%;
			height: 100%;
			color: white;
			font-weight: bold;
			line-height: 1.8em;
		}
		&:before {
			content: "ON";
			text-transform: uppercase;
			padding-left: 0.494em;
			padding-top: 0.05em;
			background-color: #10779E;
			font-size: 0.66em;
		}
	}
	&-inner:after {
		content: "OFF";
		text-transform: uppercase;
		background-color: #BFBFBF;
		padding-left: 1.5em;
		padding-top: 0.05em;
		font-size: 0.66em;
		text-align: center;
	}

	&-switch {
		display: block;
		position: absolute;
		width: 1em;
		height: 1em;

		margin-top: 0.1em;
		margin-bottom: 0.11em;

		border-radius: 50%;
		background: #fff;
	
		right: 1.5em;
		top: 0;
		transition: all 0.3s ease-in 0s;
	}
	&-checkbox:checked + &-label {
		.ToggleSwitch-inner {
			margin-left: 0;
		}
		.ToggleSwitch-switch {
			right: 0.1em;
		}
	}

	&.disabled {
		.ToggleSwitch-label {
			cursor: default;
		}		
	}
}