@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';
@import '../../../../styles/mixins_backgrounds';
@import '../../../../styles/animations';
@import '../../../../styles/animations-intro';
.SortDndDragLayer {
	.SortDndDragLayer-item {
		border-radius: 1em;
		color: white;
		background-color: #10779E;
		padding: 0.5em 1.75em 0.5em 0.5em;
		margin-bottom: 0.25em;
		margin-right: 0.25em;
		line-height: 1em;
		white-space: nowrap;
		font-family: 'Source Sans Pro', sans-serif;
		background-image: url('../../../../assets/images/modules/icon-move.svg');
		background-position: center right 0.5em;
		background-size: auto 1em;		
		transform-origin: right 0.5em center;
		@include rotate(-7deg);
		span {
			font-size: 0.75em;
		}

		&.shrink-1-math {
			width: 9em;
		}
	}
}