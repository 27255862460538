@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/mixins_backgrounds';
@import '../../styles/animations';
@import '../../styles/animations-intro';
.AuthMDI {
	position: relative;
	width: 100%;
	height: 100%;
	padding: 5em;
}
