@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';
@import '../../../../styles/mixins_backgrounds';
@import '../../../../styles/animations';
@import '../../../../styles/animations-intro';
.Dialogue {
	position: relative;
	height: 100%;
	overflow: hidden;
	@include grey-background();
	&.intro,
	&.result {
		padding-top: 4em;
		background: none;
		.Dialogue-introText,
		.Dialogue-resultText {
			position: relative;
			left: calc(50% - 8.75em);
			width: 17.5em;
			color: #11466B;
			background-color: #F5F5F5;
			border-radius: 1.1em;
			padding: 1em 1.25em 1.25em 1.25em;

			p {	
				margin-bottom: 0;
				&:first-of-type {margin-top: 0;}
			}
			&.speech,
			&.dialogue {
				position: absolute;
				bottom: 19em;
				&::after {
					content: '';
					position: absolute;
					top: calc(100% - 0.5em);
					left: 6.25em;
					border-left: 2em solid #F5F5F5;
					border-bottom: 2.4em solid transparent;
				}
			}
		}			
		.Dialogue-character {
			position: absolute;
			left: calc(50% - 11.75em);
			bottom: 0;
			z-index: 3;
			&.bearded-man-confused,
			&.old-lady,
			&.old-lady-happy,
			&.old-lady-angry {
				left: calc(50% - 8em);
			}
			&.bearded-man-confused {
				bottom: -0.2em;
			}
			&.elderly-lady-with-cart {
				bottom: -13.67em;
				left: calc(50% - 12.25em);
			}
			&.wizard-child,
			&.wizard-side,
			&.middle-aged-man-angry,
			&.middle-aged-man-happy {
				left: calc(50% - 10em);
			}
			&.middle-aged-man-with-bag {
				left: calc(50% - 10em);
			}
			&.middle-aged-man-with-watch,
			&.middle-aged-man-with-watch-happy {
				left: calc(50% - 10.5em);
			}
			&.wizard-child {
				bottom: -7em;
			}

			&.old-man-with-cat-food {
				left: calc(50% - 13em);
			}

			&.old-man {
				left: calc(50% - 12em);
				bottom: -1em;
			}

			&.manager-2 {
				left: calc(50% - 12em);
				&.pos2 {
					left: calc(50% - 14em);
				}
			}
			&.man-with-box-annoyed.pos-2 {
				left: calc(50% - 23em);
			}
			&.woman-with-baby {
				left: calc(50% - 10.75em);
			}
			&.woman-with-handbag-angry,
			&.woman-with-handbag-very-angry {
				left: calc(50% - 10em);
			}
			&.shopping-cart-lady-neutral.pos-3 {
				left: calc(50% - 38em);
			}
			&.shopping-cart-lady-angry:not(flipped), 
			&.shopping-cart-lady-happy:not(flipped) {
				left: calc(50% - 17em);
			}
			&.old-man-with-cat-food.flipped,
			&.old-man-with-cat-food-annoyed.flipped,
			&.old-man-with-cat-food-angry.flipped {
				left: calc(50% - 16em);
			}
			&.wizard-skates {
				bottom: 2em;
				left: calc(50% - 9.5em);
			}
		}

		.Dialogue-item {
			position: absolute;
			bottom: 0;
			&.trashcan {
				left: calc(50% - 10em);
				width: 8.83em;
				height: 4.55em;
				background-image: url('../../../../assets/images/modules/tasks/dialogue/trashcan.svg');
				background-size: 100% auto;
				background-position: top center;
			}
			&.carts {
				left: calc(50% - 18.5em);
				width: 18em;
				height: 4.55em;
				background-image: url('../../../../assets/images/modules/tasks/dialogue/carts.svg');
				background-size: 100% auto;
				background-position: top center;
			}
		}
		.Dialogue-avatar {
			position: absolute;
			left: calc(50% - 5.8em);
			bottom: -0.8em;
		}
		.Dialogue-startBtn {
			position: absolute;
			left: calc(50% + 1em);
			bottom: 2em;
			width: 8em;
			z-index: 5;
		}

		&.freezer-cooler-on-rafts {
			background-image: url('../../../../assets/images/modules/tasks/dialogue/cooler.svg'), url('../../../../assets/images/modules/tasks/dialogue/freezer.svg');
			background-size: 6.8em auto, 6.65em auto;
			background-repeat: no-repeat;
			background-position: bottom 11.5em center, bottom 6em left calc(50% + 8em);
		}
		&.ocean-ice-rink {
			@include ocean-ice-rink();
			.Dialogue-resultText {
				bottom: 21em;
			}
		}
	}


	.Dialogue-wrap {
		position: absolute;
		bottom: 0;
		left: calc(50% - 10.4167em);
		width: 20.833em;
		height: 100%;

		.Dialogue-dialogue {
			position: relative;
			width: 100%;
			height: calc(100% - 10.5em);
			.Dialogue-lines {
				position: absolute;
				left: 0;
				bottom: 0.25em;
				width: 100%;
				max-height: calc(100% - 1em);
				padding: 0 1.55em;
				overflow: auto;
				display: table-cell; 
				vertical-align: bottom;
				scroll-behavior: smooth;
				-webkit-overflow-scrolling: touch;
				.Dialogue-line {
					position: relative;
					width: 100%;
					text-align: center;
					margin: 0 0 1em 0;
					.Dialogue-bubble {
						position: relative;
						display: inline-block;
						width: 14.25em;
						color: #11466B;
						background-color: #A2D0F0;
						padding: 1em 1.25em 1.25em 3em;
						border-radius: 1.1em;
						text-align: left;

					}
					.Dialogue-personAvatar {
						position: absolute;
						bottom: -0.35em;
						left: -0.75em;
						width: 3.25em;
						height: 3.25em;
						background-color: white;
						border-radius: 100%;
						border: 0.15em solid #10779E;
						&.flipped {
							@include scaleX(-1);
						}
					}
					&.dialogue {
						margin: 0 0 1.75em 0;
						&.customer {
							text-align: left;
							.Dialogue-bubble {
								background-color: #10779E;
								color: white;
							}
							.Dialogue-personAvatar {
								background-color: #10779E;
								border-color: white;
								background-size: 2.5em auto;
								background-position: 0.05em 0.1em;
								&.bearded-man {
									@include scaleX(-1);
									background-position: 0.1em 0.2em;
									background-image: url('../../../../assets/images/characters/bearded-man-confused.svg');
									&.happy {
										background-position: 0.3em 0.3em;
										background-image: url('../../../../assets/images/characters/bearded-man-happy.svg');
									}
								}
								&.elderly-lady-with-cart {
									background-position: top 0.5em left 0.15em;
									background-size: 9em auto;
									background-image: url('../../../../assets/images/characters/elderly-lady-with-cart.svg');
								}
								&.man-with-box {
									background-size: 2.75em auto;
									background-position: top 0.3em center;
									background-image: url('../../../../assets/images/characters/man-with-box.svg');
									&.angry {background-image: url('../../../../assets/images/characters/man-with-box-angry.svg');}
									&.annoyed {background-image: url('../../../../assets/images/characters/man-with-box-annoyed.svg');}
								}
								&.man-with-boxes {
									background-size: 2.75em auto;
									background-position: top 0.3em center;
									background-image: url('../../../../assets/images/characters/man-with-boxes.svg');
									&.angry {background-image: url('../../../../assets/images/characters/man-with-boxes-angry.svg');}
									&.annoyed {background-image: url('../../../../assets/images/characters/man-with-boxes-annoyed.svg');}
								}
								&.middle-aged-man {
									@include scaleX(-1);
									background-position: top 0.4em center;
									background-size: 1.75em auto;
									background-image: url('../../../../assets/images/characters/middle-aged-man-happy.svg');
									&.angry {
										background-image: url('../../../../assets/images/characters/middle-aged-man-angry.svg');
									}
								}
								&.middle-aged-man-with-watch {
									@include scaleX(-1);
									background-position: top 0.4em center;
									background-size: 1.75em auto;
									background-image: url('../../../../assets/images/characters/middle-aged-man-with-watch.svg');
									&.happy {
										@include scaleX(1);
										background-image: url('../../../../assets/images/characters/middle-aged-man-with-watch-happy.svg');
									}
								}
								&.old-lady {
									background-image: url('../../../../assets/images/characters/old-lady.svg');
									&.angry {background-image: url('../../../../assets/images/characters/old-lady-angry.svg');}
									&.happy {background-image: url('../../../../assets/images/characters/old-lady-happy.svg');}
								}
								&.old-man-with-cat-food {
									background-position: top 0.2em left 0em;
									background-size: 4em auto;
									background-image: url('../../../../assets/images/characters/old-man-with-cat-food.svg');
									&.annoyed {background-image: url('../../../../assets/images/characters/old-man-with-cat-food-annoyed.svg');}
									&.angry {background-image: url('../../../../assets/images/characters/old-man-with-cat-food-angry.svg');}
								}
								&.old-man {
									background-position: top 0.2em left -0.75em;
									background-size: 4em auto;
									background-image: url('../../../../assets/images/characters/old-man.svg');
									&.annoyed {background-image: url('../../../../assets/images/characters/old-man-with-cat-food-annoyed.svg');}
									&.angry {background-image: url('../../../../assets/images/characters/old-man-with-cat-food-angry.svg');}
								}
								
								&.wizard-customer {
									background-position: top 0.3em left -0.05em;
									background-size: 3.25em auto;
									background-image: url('../../../../assets/images/characters/wizard-customer.svg');
								}
								&.shopping-cart-lady {
									background-position: top 0.4em right 0.4em;
									background-size: 4em auto;
									background-image: url('../../../../assets/images/characters/shopping-cart-lady-neutral.svg');
									@include scaleX(-1);
									&.angry {
										background-image: url('../../../../assets/images/characters/shopping-cart-lady-angry.svg');
									}
									&.happy {
										background-image: url('../../../../assets/images/characters/shopping-cart-lady-happy.svg');
									}
									&.tired {
										background-image: url('../../../../assets/images/characters/shopping-cart-lady-tired.svg');
									}
								}
								&.woman-with-baby {
									background-position: top 0.4em right -1.5em;
									background-size: 4em auto;
									background-image: url('../../../../assets/images/characters/woman-with-baby.svg');
									&.angry {
										background-image: url('../../../../assets/images/characters/woman-with-baby-angry.svg');
									}
									&.happy {
										background-image: url('../../../../assets/images/characters/woman-with-baby-happy.svg');
									}
									&.tired {
										background-image: url('../../../../assets/images/characters/woman-with-baby-tired.svg');
									}
								}
								&.woman-with-handbag {
									background-position: top 0.4em right 0.1em;
									background-image: url('../../../../assets/images/characters/woman-with-handbag.svg');
									&.angry {
										background-image: url('../../../../assets/images/characters/woman-with-handbag-angry.svg');
									}
									&.very-angry {
										background-image: url('../../../../assets/images/characters/woman-with-handbag-very-angry.svg');
									}
								}
								&.shopping-cart-lady-angry {
									background-position: top 0.4em right 0.4em;
									background-size: 4em auto;
									background-image: url('../../../../assets/images/characters/shopping-cart-lady-angry.svg');
								}
								&.wizard {
									background-position: top 0.2em center;
									background-size: 3.5em auto;
									background-image: url('../../../../assets/images/characters/wizard-1.svg');
									
									&.angry {
										background-image: url('../../../../assets/images/characters/wizard-3.svg');
									}
								}
								&.wizard-child {
									background-position: top 0.2em center;
									background-size: 2.25em auto;
									background-image: url('../../../../assets/images/characters/wizard-child.svg');
								}
							}
						}
						&.player {
							text-align: right;
							.Dialogue-bubble {
								text-align: left;
								background-color: #F5F5F5;
								color: #11466B;
								padding: 1em 3.5em 1.25em 1.25em;
							}
							.Dialogue-personAvatar {
								left: unset;
								right: -1.25em;
								@include flex('center', 'flex-start');
								overflow: hidden;
							}
						}
					}
					&.action {
						.Dialogue-bubble {
							padding: 1em 1.25em 1.25em 1.25em;
						}
						.Dialogue-personAvatar {
							display: none;
						}
					}
				}
			}
		}
		.Dialogue-panel {
			position: absolute;
			width: 100%;
			height: 10.5em;
			background-color: #10779E;
			border-top: 0.167em solid #7DBDE8;
			overflow: hidden;
			bottom: 0;
			.Dialogue-options {
				padding: 0.5em 0;
				.Dialogue-optionsText {
					color: white;
					text-align: center;
					font-weight: 700;
				}
				.Dialogue-optionsCarousel {
					width: 100%;
					height: 7em;
					margin-top: 0.67em;
				}
			}

			.Dialogue-points {
				width: 100%;
				height: 100%;
				padding-top: 3.5em;
				text-align: center;
				span {
					color: white;
					font-size: 1.75em;
					font-weight: 700;
				}
			}
		}
	}
	.Dialogue-nextBtn {
		position: absolute;
		right: 0;
		bottom: 0;
	}


	&.landscape {
		@include grey-landscape-background();
		.Dialogue-nextBtn {right: calc(50% - 13.5em);}
	}
}