.MultipleChoice {
	&.bagger.landscape {@include bagger-grey-landscape-background();}
	
	&.bagger-1-bag-items-shape {
		.MultipleChoice-options {
			margin-top: 1em;
			padding-bottom: 1em;
			.MultipleChoice-optionsWrap {
				height: 24.4em;	
				padding: 0 1.5em;			
				.MultipleChoice-option { 
					display: inline-block;
					width: 8em;
					height: 8em;
					span:nth-child(2) {
						bottom: 0.5em;
						right: 0.75em;
						left: auto;
						width: 1.39em;
						height: 1.39em;
					}
					&.position-1,
					&.position-3 {margin-right: calc(100% - 16em);}
					&.position-1,
					&.position-2 {margin-bottom: 1em;}

					&.option-1 {background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/bagger-1-bag-items-shape/option-1.svg');}
					&.option-2 {background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/bagger-1-bag-items-shape/option-2.svg');}
					&.option-3 {background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/bagger-1-bag-items-shape/option-3.svg');}
					&.option-4 {background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/bagger-1-bag-items-shape/option-4.svg');}
				}
			}
		}
	}

	&.bagger-1-correct-packing {
		.MultipleChoice-options {
			padding-top: 0.5em;
			.MultipleChoice-optionsWrap {
				margin: 0 auto;
				width: 10.56em;
				.MultipleChoice-option { 
					width: 10.56em;
					height: 8.5em;
					margin-bottom: 0.25em;
					span {
						&:nth-child(2) {
							bottom: 0;
							left: auto;
							right: 0;
						}
					}
					&.option-1 {background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/bagger-1-correct-packing/option-1.svg');}
					&.option-2 {background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/bagger-1-correct-packing/option-2.svg');}
					&.option-3 {background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/bagger-1-correct-packing/option-3.svg');}
				}
			}
		}
	}

	&.bagger-1-correct-uniform {
		.MultipleChoice-options {
			padding: 1em;
			.MultipleChoice-optionsWrap {
				height: 25em;				
				.MultipleChoice-option { 
					display: inline-block;
					width: 50%;
					height: 12.5em;
					background-size: auto 12em;
					span {
						&:nth-child(2) {
							bottom: 3.25em;
							left: auto;
							right: 1em;
						}
					}
					&.option-1 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/bagger-1-correct-uniform/option-1.svg');
					}
					&.option-2 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/bagger-1-correct-uniform/option-2.svg');
					}
					&.option-3 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/bagger-1-correct-uniform/option-3.svg');
					}
					&.option-4 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/bagger-1-correct-uniform/option-4.svg');
					}
				}
			}
		}
	}

	&.bagger-1-no-bag-needed {
		.MultipleChoice-options {
			padding-top: 0.5em;
			.MultipleChoice-optionsWrap {
				margin: 0 auto;
				width: 15.56em;
				height: 22.37em;
				.MultipleChoice-option { 
					position: absolute;
					span {
						&:nth-child(2) {
							bottom: 0;
							left: auto;
							right: 0;
						}
					}
					&.option-1 {
						top: 0;
						left: 0;
						width: 5em;
						height: 8.8em;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/bagger-1-no-bag-needed/option-1.svg');
						background-size: 4.5em auto;
						background-position: top left;
					}

					&.option-2 {
						bottom: 0;
						right: 0;
						width: 5.75em;
						height: 9em;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/bagger-1-no-bag-needed/option-2.svg');
						background-size: 5.4em auto;
						background-position: top left;
					}
					&.option-3 {
						top: 0;
						right: 0;
						width: 5.8em;
						height: 8em;						
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/bagger-1-no-bag-needed/option-3.svg');
						background-size: 5.2em auto;
						background-position: top 1em right 0.5em;
					}

					&.option-4 {
						bottom: 0;
						left: 0;
						width: 3.75em;
						height: 9.5em;						
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/bagger-1-no-bag-needed/option-4.svg');
						background-size: 3.25em auto;
						background-position: bottom 0.5em left;
					}
					&.option-5 {
						top: calc(50% - 3em);
						left: calc(50% - 2em);
						width: 4em;
						height: 7em;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/bagger-1-no-bag-needed/option-5.svg');
						background-size: 3.14em auto;
						background-position: center center;
					}
				}
			}
		}
	}

	&.bagger-1-uniform-parts {
		.MultipleChoice-options {
			padding: 1em;
			.MultipleChoice-optionsWrap {
				height: 22em;				
				.MultipleChoice-option { 
					display: inline-block;
					width: 50%;
					height: 11em;
					span {
						&:nth-child(2) {
							bottom: calc(50% - 2em);
							left: calc(50% - 0.75em);
						}
					}
					&.option-1 {
						background-size: 6.4em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/bagger-1-uniform-parts/option-1.svg');
					}
					&.option-2 {
						background-size: 5.5em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/bagger-1-uniform-parts/option-2.svg');
					}
					&.option-3 {
						background-size: 4.2em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/bagger-1-uniform-parts/option-3.svg');
					}
				}
			}
		}
	}
}