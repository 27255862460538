@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';
@import '../../../../styles/mixins_backgrounds';
@import '../../../../styles/animations';
@import '../../../../styles/animations-intro';
.SpotErrors {
	position: relative;
	height: 100%;
	overflow: auto;
	@include grey-background();

	&.landscape {
		@include grey-landscape-background();
	}
	
	.SpotErrors-intro {
		position: absolute;
		top: 4em;
		left: calc(50% - 9em);
		width: 17.5em;
	}

	.SpotErrors-errors {
		position: relative;
		width: 100%;
		height: 100%;
		overflow: hidden;

		.SpotErrors-error {
			position: absolute;
			width: 5em;
			height: 5em;
			border-radius: 100%;
			border: 0 solid #11466B;
			&.spotted {
				background-image: url('../../../../assets/images/modules/icon-check2.svg');
				background-size: 1.4em auto;
				background-position: center center;
			}
		}


		&.bakery-1-bread-wrapped {
			background-image: url('../../../../assets/images/modules/tasks/spot-errors/bakery-1-bread-wrapped.svg');
			background-size: auto 4.5em;
			background-position: center top 13em;
			min-height: calc(4.5em + 13em);
				
			.SpotErrors-error {
				&.excess-plastic-1 {
					top: 13.2em;
					left: calc(50% - 10em);
				}
				&.excess-plastic-2 {
					top: 13.1em;
					left: calc(50% + 4.5em);
					width: 5.5em;
				}
				&.label {
					top: 13.4em;
					left: calc(50% - 3.75em);
					width: 6.75em;
					height: 3.5em;
					@include rotate(-38deg);
					&.spotted {
						@include rotate(0deg);
					}
				}
			}
		}

		&.bakery-1-department {
			background-image: url('../../../../assets/images/modules/tasks/spot-errors/bakery-1-department.svg'), linear-gradient(rgba(#808285, 0), #808285);
			background-size: auto 31.48em, 100% 14.5em;
			background-position: top 3.5em left calc(50% + 1.5em), top center;
			min-height: calc(31.48em + 3.5em);
			.SpotErrors-error {
				&.notes {
					width: 5em;
					height: 5em;
					top: 5.7em;
					left: calc(50% - 10.3em);
				}
				&.trash {
					width: 4.5em;
					height: 4em;
					top: 13em;
					left: calc(50% - 1.8em);
				}
				&.flour {
					width: 4em;
					height: 2em;
					top: 21.3em;
					left: calc(50% + 2em);
				}
				&.sign {
					width: 5em;
					height: 5.3em;
					top: 19.2em;
					left: calc(50% - 11em);
				}
			}
		}

		&.bakery-1-muffin-display {
			background-image: url('../../../../assets/images/modules/tasks/spot-errors/bakery-1-muffin-display.svg');
			background-size: auto 23.81em;
			background-position: top 8em center;
			min-height: calc(23.81em + 8em);
			.SpotErrors-error {
				&.high-stack-1 {
					top: 7.9em;
					left: calc(50% - 9.1em);
					width: 6em;
					height: 7em;
				}
				&.high-stack-2 {
					top: 9.7em;
					left: calc(50% + 2.8em);
					width: 6em;
				}
				&.donuts {
					top: 19.3em;
					left: calc(50% - 3em);
					width: 6em;
					height: 4em;
				}
				&.empty-space {
					top: 17.6em;
					left: calc(50% + 2.9em);
					width: 6em;
					height: 2.1em;
				}
			}
		}

		&.bakery-1-table {
			background-image: url('../../../../assets/images/modules/tasks/spot-errors/bakery-1-table.svg');
			background-size: auto 20.32em;
			background-position: top 9.36em center;
			min-height: calc(20.32em + 9.36em);
			.SpotErrors-error {
				width: 6.4em;
				height: 3.8em;
				&.date-1 {
					top: 10.6em;
					left: calc(50% - 3.1em);
				}
				&.date-2 {
					top: 16.4em;
					left: calc(50% - 9.5em);
				}
				&.date-3 {
					top: 16.4em;
					left: calc(50% + 3em);
				}
			}
		}

		&.bakery-1-stacked-cakes {
			background-image: url('../../../../assets/images/modules/tasks/spot-errors/bakery-1-stacked-cakes.svg');
			background-size: auto 17em;
			background-position: top 10em center;
			min-height: calc(17em + 10em);
			.SpotErrors-error {
				&.cake-1 {
					width: 6em;
					height: 3.7em;
					top: 9.8em;
					right: calc(50% - 3em);
				}
			}
		}

		&.bakery-1-cookies {
			background-image: url('../../../../assets/images/modules/tasks/spot-errors/bakery-1-cookies.svg');
			background-size: auto 22.92em;
			background-position: top 10em center;
			min-height: calc(22.92em + 10em);
			.SpotErrors-error {
				&.low-stack {
					width: 5em;
					height: 4em;
					top: 14.8em;
					left: calc(50% - 2.4em);
					&.spotted {
						background-position: top center;
					}
				}
				&.high-stack {
					width: 5em;
					height: 6.8em;
					top: 11.8em;
					left: calc(50% + 3.7em);
					&.spotted {
						background-position: top center
					}
				}
				&.wrong-placement {
					width: 5em;
					height: 2em;
					top: 21.9em;
					left: calc(50% - 8.4em);
				}
				&.wrong-order {
					width: 5em;
					height: 5em;
					top: 19.8em;
					left: calc(50% + 3.7em);
				}
				&.old {
					width: 5em;
					height: 3em;
					top: 13.8em;
					left: calc(50% - 8.4em);
				}
			}
		}

		&.bakery-2-decorated-cake {
			min-height: calc(14.7em + 10em);
			background-image: url('../../../../assets/images/modules/tasks/spot-errors/bakery-2-decorated-cake.svg');
			background-size: auto 14.7em;
			background-position: top 10em center, top center;	
			.SpotErrors-error {
				&.spelling {
					height: 2em;
					width: 2em;
					top: 11.4em;
					left: calc(50% - 0.75em);
				}
				&.name {
					border-radius: 0.5em;
					width: 8em;
					height: 2.3em;
					top: 13.4em;
					left: calc(50% - 0.5em);
				}
				&.date {
					border-radius: 0.5em;
					width: 5.57em;
					height: 1.8em;
					top: 15.6em;
					left: calc(50% - 2.5em);
				}
			}
		}
		&.bakery-2-prevent-icing-shrink {
			min-height: calc(17.5em + 9em);
			background-image: url('../../../../assets/images/modules/tasks/spot-errors/bakery-2-prevent-icing-shrink.svg');
			background-size: auto 17.5em;
			background-position: top 9em center, top center;	
			.SpotErrors-error {
				&.clump1 {
					height: 4.5em;
    			width: 5em;
    			top: 11em;
    			left: calc(50% - 5.4em);
				}
				&.clump2 {
					width: 5em;
					height: 4.5em;
					top: 17.4em;
					left: calc(50% - 7em);
				}
				&.clump3 {
					width: 9em;
					height: 9em;
					top: 15.5em;
					left: calc(50% - 1.25em);
				}
			}
		}
		&.bakery-2-stacked-cookies {
			min-height: calc(20em + 10em);
			background-image: url('../../../../assets/images/modules/tasks/spot-errors/bakery-2-stacked-cookies.svg');
			background-size: auto 20em;
			background-position: top 10em center, top center;	
			.SpotErrors-error {
				width: 6.4em;
				height: 5.3em;
				border-radius: 1em;
				&.stack1 {
    			top: 11em;
    			left: calc(50% - 9.4em);
				}
				&.stack2 {
					top: 16.8em;
					left: calc(50% - 9.4em);
				}
				&.stack3 {
					top: 16.8em;
					left: calc(50% + 3em);
				}
			}
		}


		&.center-store-1-backroom {
			min-height: 30em;
			background-image: url('../../../../assets/images/modules/tasks/spot-errors/center-store-1-backroom.svg'), linear-gradient(rgba(#808285, 0), #808285);
			background-size: 17.5em auto, 100% 20.83em;
			background-position: top 8.67em center, top 9.2em right calc(50%);
			.SpotErrors-error {
				border-radius: 0;
				&.boxes-floor {
					width: 4.25em;
					height: 5.65em;
					top: 21.3em;
					right: calc(50% - 4.85em);
				}
				&.paperwork {
					width: 3.75em;
					height: 4.2em;
					top: 11.5em;
					right: calc(50% - 4.9em);
				}
				&.wrong-side-up {
					width: 2em;
					height: 3em;
					top: 17em;
					right: calc(50% - 0.1em);
				}
			}
		}

		&.center-store-1-restock {
			min-height: 30em;
			background-image: url('../../../../assets/images/modules/tasks/spot-errors/center-store-1-restock.svg');
			background-size: 17.5em auto;
			background-position: top 8.67em center;
			.SpotErrors-error {
				&.broken-tag {
					width: 3em;
					height: 1.75em;
					top: 14.6em;
					right: calc(50% - 6em);
					@include rotate(35deg);
				}
				&.dirty-shelf {
					width: 6em;
					height: 3em;
					top: 18.2em;
					right: calc(50% - 3em);
				}
			}
		}

		&.center-store-1-endcap {
			min-height: calc(21.28em + 10em);
			background-image: url('../../../../assets/images/modules/tasks/spot-errors/center-store-1-endcap.svg');
			background-size: auto 21.28em;
			background-position: top 10em center;
			.SpotErrors-error {
				border-radius: 0;
				width: 2.75em;
				height: 3.75em;
				&.not-pulled {
					top: 14.9em;
					right: calc(50% - 6.8em);
				}
				&.uneven {					
					top: 20.3em;
					right: calc(50% + 6.5em);
				}
				&.wrong-spot {
					top: 10.5em;
					right: calc(50% + 5.55em);
				}
				&.backwards {
					width: 3.5em;
					height: 2.25em;
					top: 26.7em;
					right: calc(50% - 7em);
				}
			}
		}

		&.center-store-1-rice-shelves {
			min-height: 30em;
			background-image: url('../../../../assets/images/modules/tasks/spot-errors/center-store-1-rice-shelves.svg');
			background-size: 17.5em auto;
			background-position: top 8.67em center;
			.SpotErrors-error { 
				&.hanging-tag {
					top: 16em;
					left: calc(50% - 3em);
				}
			}
		}

		

		&.cleaning-1-coffin-case,
		&.cleaning-1-coffin-case-seafood {
			min-height: calc(12.14em + 14.5em);
			background-image: url('../../../../assets/images/modules/tasks/spot-errors/cleaning-1-coffin-case.svg'), linear-gradient(rgba(#808285, 0), #808285);;
			background-size: auto 12.14em, 100% 24.1em;
			background-position: top 14.5em center, top center;	
			.SpotErrors-error {
				&.spots {
					height: 3em;
					width: 5em;
					top: 19.75em;
					left: calc(50% - 6.25em);
				}
				&.glass {
					width: 4.5em;
					height: 6em;
					top: 15.5em;
					left: calc(50% + 5.3em);
				}
				&.smudge {
					width: 3.5em;
					height: 5em;
					top: 14.8em;
					left: calc(50% - 10em);
				}
			}
		}

		&.cleaning-1-coffin-case-seafood {
			background-image: url('../../../../assets/images/modules/tasks/spot-errors/cleaning-1-coffin-case-seafood.svg'), linear-gradient(rgba(#808285, 0), #808285);;
			.SpotErrors-error {
				&.tag {
					width: 3.5em;
					height: 2em;
					top: 21.2em;
					left: calc(50% + 1.1em);
				}
			}
		}

		&.cleaning-1-deli-production-area {
			min-height: calc(20.85em + 10em);
			background-image: url('../../../../assets/images/modules/tasks/spot-errors/cleaning-1-deli-production-area.svg'), linear-gradient(rgba(#808285, 0), #808285);;
			background-size: auto 20.85em, 100% 17.6em;
			background-position: top 10em left calc(50% - 0.5em), top center;
			.SpotErrors-error {
				&.slicer {
					width: 5.5em;
					height: 4.77em;
					top: 15em;
					left: calc(50% + 0.7em);
				}
				&.knives {
					width: 3.5em;
					height: 6em;
					top: 12.5em;
					left: calc(50% + 5.8em);
				}
				&.countertop {
					height: 2.5em;
					width: 6em;
					top: 17.2em;
					left: calc(50% - 5em);
				}
				&.dirt {
					height: 3.5em;
					width: 8em;
					top: 28em;
					left: calc(50% - 7em);
				}
				&.trash {
					width: 5.2em;
					height: 7.6em;
					top: 22.8em;
					left: calc(50% + 5.5em);
				}
				&.fryer {
					height: 4.5em;
					width: 6em;
					top: 15em;
					left: calc(50% - 10em);
				}
			}
		}

		&.cleaning-1-handwash-sink {
			min-height: calc(19.3em + 10em);
			background-image: url('../../../../assets/images/modules/tasks/spot-errors/cleaning-1-handwash-sink.svg'), linear-gradient(rgba(#808285, 0), #808285);;
			background-size: auto 19.3em, 100% 26.4em;
			background-position: top 10em center, top center;
			.SpotErrors-error {
				&.soap {
					width: 3.5em;
					height: 5.5em;
					top: 15em;
					left: calc(50% - 10.1em);
				}
				&.towels {
					width: 7em;
					height: 8em;
					top: 9.3em;
					left: calc(50% + 3.5em);
				}
				&.trash {
					width: 4.8em;
					height: 8em;
					top: 22em;
					left: calc(50% + 4.5em);
				}
				&.dirt {
					height: 4em;
					width: 4em;
					top: 16em;
					left: calc(50% - 5.5em);
				}
			}
		}

		&.cleaning-1-meat-production-area {
			min-height: calc(18.2em + 10em);
			background-image: url('../../../../assets/images/modules/tasks/spot-errors/cleaning-1-meat-production-area.svg'), linear-gradient(rgba(#808285, 0), #808285);
			background-size: auto 18.2em, 100% 17.6em;
			background-position: top 10em left calc(50% + 0.25em), top center;
			.SpotErrors-error {
				&.grinder {
					height: 6.1em;
					width: 7em;
					top: 10.7em;
					left: calc(50% - 10.4em);
				}
				&.saw {
					width: 5.5em;
					height: 4.2em;
					top: 12.6em;
					left: calc(50% + 1em);
				}

				&.knives {
					width: 3.5em;
					height: 6em;
					top: 10em;
					left: calc(50% + 5.9em);
				}
				&.meat {
					width: 9.6em;
					height: 5.2em;
					top: 17.5em;
					left: calc(50% - 4em);
					border-radius: 1em;
				}
				&.floor {
					height: 3.5em;
					width: 8em;
					top: 25.4em;
					left: calc(50% - 7em);
				}
				&.countertop {
					height: 2.5em;
					width: 6em;
					top: 14.4em;
					left: calc(50% - 4.5em);
				}
			}
		}

		&.cooler-freezer-1-freezer-issues {
			min-height: calc(26.66em + 8em);
			background-image: url('../../../../assets/images/modules/tasks/spot-errors/cooler-freezer-1-freezer-issues.svg'), linear-gradient(rgba(#808285, 0), #808285);;
			background-size: auto 26.66em, 100% 26.54em;
			background-position: top 8em left calc(50% + 0.5em), top center;
			.SpotErrors-error {
				&.fan {
					width: 4.5em;
					height: 4em;
					top: 7.5em;
					left: calc(50% + 5.2em);
				}
				&.ice-on-floor {
					width: 6.2em;
					height: 3.8em;
					top: 30.6em;
					left: calc(50% - 8.75em);
				}
				&.cases-on-floor {
					width: 7.3em;
					height: 8.5em;
					top: 26.6em;
					left: calc(50% + 3em);
					border-radius: 1em;
				}
				&.lunch-box {
					height: 3.2em;
					width: 3.8em;
					top: 16.9em;
					left: calc(50% + 5.4em);
				}
				&.refrigerated {
					height: 4.6em;
					width: 6.3em;
					top: 15.4em;
					left: calc(50% - 7.1em);
					border-radius: 1em;
				}
			}	
		}

		&.customer-service-1-service-desk {
			min-height: calc(19.17em + 9em);
			background-image: url('../../../../assets/images/modules/tasks/spot-errors/customer-service-1-service-desk.svg'), linear-gradient(rgba(#808285, 0), #808285);;
			background-size: auto 18.8em, 100% 27em;
			background-position: top 9em left calc(50% + 3.75em), bottom 18.05em center;
			.SpotErrors-error {
				&.trash {
					width: 4em;
					height: 7em;
					top: 19.7em;
					left: calc(50% - 10em);
				}
				&.cup {
					width: 2.75em;
					height: 3em;
					top: 14.8em;
					left: calc(50% + 6.7em);
				}
				&.shoe {
					width: 4em;
					height: 2.5em;
					top: 25.6em;
					left: calc(50% - 2em);
				}
				&.post-its {
					width: 3em;
					height: 3em;
					top: 16.8em;
					left: calc(50% - 8.5em);
				}
			}
		}
		
		&.customer-service-2-generic-employee {
			min-height: calc(28em + 8em);
			background-image: url('../../../../assets/images/modules/tasks/spot-errors/customer-service-2-generic-employee.svg');
			background-size: auto 28em;
			background-position: top 8em center;
			.SpotErrors-error {
				&.apron-torn {
					width: 2em;
					height: 2.5em;
					top: 26.5em;
					left: calc(50% + 0.75em);
				}
				&.pet-hair {
					width: 2em;
					height: 2em;
					top: 16.8em;
					left: calc(50% + 2.25em);
				}
				&.dirt {
					width: 2.5em;
					height: 4.5em;
					top: 28.7em;
					left: calc(50% - 3.15em);
				}
			}			
		}
		
		
		&.customer-service-2-generic-employee-2 {
			min-height: calc(28em + 8em);
			background-image: url('../../../../assets/images/modules/tasks/spot-errors/customer-service-2-generic-employee-2.svg');
			background-size: auto 28em;
			background-position: top 8em center;
			.SpotErrors-error {
				&.name-tag {
					width: 3em;
					height: 1.5em;
					top: 18.4em;
					left: calc(50% - 2.5em);
				}
				&.baggy-pants {
					width: 8em;
					height: 4.75em;
					top: 29.5em;
					left: calc(50% - 4em);
				}
				&.flip-flops {
					width: 6em;
					height: 2em;
					top: 34.4em;
					left: calc(50% - 2.9em);
				}
			}
		}

		&.customer-service-2-outside-collecting {
			min-height: 35em;
			background-image: url('../../../../assets/images/modules/tasks/spot-errors/customer-service-2-outside-collecting.svg'), linear-gradient(#6B6D73, #6B6D73);
			background-size: auto 37em, 100% 24em;
			background-position: bottom -4em left calc(50% + 1.25em), bottom left;
			.SpotErrors-error {
				&.customers {
					width: 4em;
					height: 10em;
					bottom: 3.5em;
					left: calc(50% - 6em);
				}
				&.cars {
					bottom: 7em;
					width: 14em;
					height: 7em;
					left: calc(50% + 3em);
					&.spotted {
						background-size: 1.4em auto;
						background-position: left calc(50% - 2em) center !important;
					}
					&::after {
						content: '';
						position: fixed;
						border-radius: 100%;
						width: 14em;
						height: 5.5em;
						bottom: 12.75em;
						left: calc(50% - 18em);
					}
				}
				&.trash {
					width: 4.5em;
					height: 4em;
					bottom: 12.25em;
					left: calc(50% + 1em);
				}
				&.ogres {
					width: 3.25em;
					height: 3em;
					bottom: 18.25em;
					left: calc(50% - 10.5em);
				}
			}
		}

		&.daily-guidelines-1-service-case-signs {
			min-height: calc(12.75em + 12em);
			background-image: url('../../../../assets/images/modules/tasks/spot-errors/daily-guidelines-1-service-case-signs.svg');
			background-size: auto 12.75em;
			background-position: top 12em center;
			.SpotErrors-error {
				&.sign-1 {
					width: 4em;
					height: 4em;
					top: 11.75em;
					left: calc(50% - 6.5em);
				}
				&.sign-2 {
					width: 4em;
					height: 4em;
					top: 11.75em;
					left: calc(50% + 1.25em);
				}
			}
		}
		&.daily-guidelines-1-sign-blocks {
			min-height: calc(24em + 8em);
			background-image: url('../../../../assets/images/modules/tasks/spot-errors/daily-guidelines-1-sign-blocks.svg');
			background-size: auto 24em;
			background-position: top 8em center;

			.SpotErrors-error {
				&.sign {
					width: 3.5em;
					height: 4.5em;
					top: 16em;
					left: calc(50% - 2em);
				}
			}
			
		}

		&.deli-1-deli-case {
			margin: auto;
			width: 100%;
			background-image: url('../../../../assets/images/modules/tasks/spot-errors/deli-1-deli-case.svg'), url('../../../../assets/images/backgrounds/deli-grey2.svg'), linear-gradient(rgba(#808285, 0), #808285);
			background-size: auto 33.58em, auto 36.5em, 100% 29em;
			background-position: top 0 right calc(50% + 1.9em), bottom -2em center, top center;
			.SpotErrors-error.bottle {
				top: 13.6em;
				right: calc(50% + 4em);
				width: 3.5em;
				height: 4.5em;
			}
			.SpotErrors-error.glass {
				top: 20.5em;
				right: calc(50% + 3.9em);
				width: 6.5em;
				height: 5.5em;
			}
			.SpotErrors-error.ham {
				top: 18.5em;
				right: calc(50% - 3.8em);
				width: 4.5em;
				height: 4em;
			}
			.SpotErrors-error.vent {
				top: 25.4em;
				right: calc(50% - 3.25em);
			}
			.SpotErrors-error.plug {
				top: 25.2em;
				right: calc(50% - 11.5em);
				width: 5.5em;
				height: 5.5em;
			}
			.SpotErrors-error.water {
				top: 29.4em;
				right: calc(50%);
				width: 9.5em;
				height: 5.5em;
			}
		}


		&.deli-1-deli-counter {
			margin: auto;
			width: 100%;
			background-image: url('../../../../assets/images/modules/tasks/spot-errors/deli-1-deli-counter.svg'), linear-gradient(rgba(#808285, 0), #808285);
			background-size: 20.44em auto, auto 27.39em;
			background-position: top 9em right calc(50%), top center;
			.SpotErrors-error.scale {
				top: 11em;
				left: calc(50% + 2em);
				width: 7.75em;
				height: 8em;
			}
		}

		&.deli-1-sandwiches {
			min-height: calc(16em + 9em);
			background-image: url('../../../../assets/images/modules/tasks/spot-errors/deli-1-sandwiches.svg');
			background-size: auto 16em;
			background-position: top 9em center;
			.SpotErrors-error {
				width: 6.2em;
				height: 4em;
				&.sandwich-1 {
					left: calc(50% - 9.2em);
					top: 16.1em;
				}
				&.sandwich-2 {
					left: calc(50% + 3em);
					top: 16.1em;
				}
				&.sandwich-3 {
					left: calc(50% - 3.2em);
					top: 20.7em;
				}
			}
		}

		&.deli-1-store {
			background-color: #777777;
			background-image: url('../../../../assets/images/modules/tasks/spot-errors/deli-1-store.svg'), radial-gradient(ellipse, rgba(#D7D7D7, 0.4), rgba(#B5B5B5, 0.4));
			background-size: 27.5em auto, 100% 100%;
			background-position: bottom -8.11em center, center center;
			.SpotErrors-error.water {
				width: 7em;
				height: 5em;
				bottom: 24.25em;
				left: calc(50% - 4.25em);
			}
			.SpotErrors-error.shelf1 {
				width: 4em;
				height: 4em;
				left: calc(50% - 9.8em);
				bottom: 25.5em;
			}
			.SpotErrors-error.shelf2 {
				width: 4.5em;
				height: 8em;
				left: calc(50% + 5.5em);
				bottom: 16.8em;
			}
			.SpotErrors-error.sign {
				width: 5.5em;
				height: 5.5em;
				bottom: 14em;
				left: calc(50% - .5em);
			}
			.SpotErrors-error.glass {
				width: 5.5em;
				height: 5.5em;
				bottom: 8.6em;
				left: calc(50% - 1.75em);
			}
			.SpotErrors-error.flies {
				bottom: 6.75em;
				left: calc(50% - 10.25em);
			}
		}
	

		&.deli-1-chicken-case {
			background-image: url('../../../../assets/images/modules/tasks/spot-errors/deli-1-chicken-case.svg');
			background-size: 18em auto;
			background-position: top 9em center;
			.SpotErrors-error {
				&.temperature {
					width: 4em;
					height: 4em;
					top: 15.5em;
					right: calc(50% + 3.5em);
				}
			}
		}

		&.deli-2-prep-table {
			min-height: calc(22.96em + 9.5em);
			background-image: url('../../../../assets/images/modules/tasks/spot-errors/deli-2-prep-table.svg');
			background-size: auto 22.96em;
			background-position: top 9.5em right calc(50% + 0.5em);
			.SpotErrors-error {
				&.pot {
					height: 4.5em;
					width: 4.5em;
					top: 9.4em;
					right: calc(50% + 4.6em);
				}
				&.meat1 {
					width: 3em;
					height: 2.5em;
					top: 13.3em;
					right: calc(50% + 7.4em);
				}
				&.spoon {
					width: 5em;
					height: 1.5em;
					@include rotate(-30deg);
					top: 15em;
					right: calc(50% + 5.2em);
					&::after {
						@include rotate(30deg);
					}
				}
				&.meat2 {
					width: 3em;
					height: 3em;
					top: 14.5em;
					right: calc(50% + 2.6em);
				}
				&.knife {
					width: 6em;
					height: 2em;
					top: 18em;
					right: calc(50% - 4em);
				}
				&.meat3 {
					width: 4em;
					height: 3em;
					top: 19.6em;
					right: calc(50% - 5em);
				}
			}
		}

		&.deli-2-salad-bar-not-ready {
			margin: auto;
			min-height: calc(18.5em + 9em);
			background-image: url('../../../../assets/images/modules/tasks/spot-errors/deli-2-salad-bar-not-ready.svg'), linear-gradient(rgba(#808285, 0), #808285);
			background-size: auto 18.5em, 100% 23em;
			background-position: top 9em left calc(50% - 0.5em), top 0 center;
			.SpotErrors-error {
				&.missing-tongs {
					top: 13em;
					left: calc(50% + 4.78em);
					width: 3em;
					height: 3em;
				}
				&.glass {
					top: 15.5em;
					left: calc(50% + 1em);
					height: 2em;
				}
				&.smears {
					top: 20.8em;
					left: calc(50% - 7em);
					height: 5.2em;
					width: 6em;
				}
				&.light {
					top: 9.1em;
					left: calc(50% - 7.6em);
					width: 3em;
					height: 3em;
				}
			}
		}

		&.deli-2-salad-bar-issues {
			margin: auto;
			min-height: calc(23.32em + 9em);
			background-image: url('../../../../assets/images/modules/tasks/spot-errors/deli-2-salad-bar-issues.svg'), linear-gradient(rgba(#808285, 0), #808285);
			background-size: auto 23.32em, 100% 23em;
			background-position: top 9em left calc(50% - 1em), top 0 center;
			.SpotErrors-error {
				&.missing-supplies1 {
					top: 17.3em;
					left: calc(50% + 2.2em);
					width: 3.5em;
					height: 2em;
				}
				&.missing-supplies2 {
					top: 18.8em;
					left: calc(50% - 3.9em);
					width: 3.5em;
					height: 2em;
				}
				&.cord {
					top: 20.2em;
					left: calc(50% - 10.4em);
					height: 5em;
					width: 3.2em;
				}
				&.water {
					top: 28.4em;
					left: calc(50% - 14em);
					height: 5.2em;
					width: 12em;
				}
			}
		}
		&.deli-2-hot-bar-issues {
			margin: auto;
			min-height: calc(20.76em + 9em);
			background-image: url('../../../../assets/images/modules/tasks/spot-errors/deli-2-hot-bar-issues.svg'), linear-gradient(rgba(#808285, 0), #808285);
			background-size: auto 20.76em, 100% 23em;
			background-position: top 9em left calc(50%), top 0 center;
			.SpotErrors-error {
				&.light {
					top: 9em;
					left: calc(50% - 2.2em);
					width: 3em;
					height: 3em;
				}
				&.floor {
					top: 27.7em;
					left: calc(50% + 6.3em);
					height: 3em;
					width: 3em;
				}
				&.vent {
					top: 23.7em;
					left: calc(50% - 7.8em);
					height: 3em;
					width: 3em;
				}
			}
		}
		&.deli-2-service-case-issues {
			margin: auto;
			min-height: calc(17em + 11em);
			background-image: url('../../../../assets/images/modules/tasks/spot-errors/deli-2-service-case-issues.svg'), linear-gradient(rgba(#808285, 0), #808285);
			background-size: auto 17em, 100% 23em;
			background-position: top 11em left calc(50%), top 0 center;
			.SpotErrors-error {
				&.tag {
					top: 22em;
					left: calc(50% - 5.9em);
					width: 3em;
					height: 2em;
				}
				&.glass {
					top: 16.6em;
					left: calc(50% - 2.2em);
					height: 3em;
					width: 4em
				}
			}
		}
		&.deli-2-oven-issues {
			margin: auto;
			min-height: calc(15em + 13em);
			background-image: url('../../../../assets/images/modules/tasks/spot-errors/deli-2-oven-issues.svg'), linear-gradient(rgba(#808285, 0), #808285);
			background-size: auto 15em, 100% 23em;
			background-position: top 13em left calc(50% - 0.5em), top 0 center;
			.SpotErrors-error {
				&.chicken {
					top: 18.7em;
					left: calc(50% - 2.5em);
					width: 6em;
					height: 4.5em;
				}
				&.on {
					top: 15.5em;
					left: calc(50% - 1em);
					height: 2em;
					width: 3em;
				}
			}
		}

		&.orientation-1-pallet-with-water {
			min-height: calc(17em + 10em);
			background-image: url('../../../../assets/images/modules/tasks/spot-errors/orientation-1-pallet-with-water.svg'), linear-gradient(rgba(#808285, 0), #808285);
			background-size: auto 17em, 100% 20em;
			background-position: top 10em center, top center;
			.SpotErrors-error {
				&.puddle1 {
					width: 6em;
					height: 3em;
					top: 21.2em;
					left: calc(50% - 11.7em);
				}
				&.puddle2 {
					width: 3em;
					height: 3em;
					top: 20.4em;
					left: calc(50% + 7.5em);
				}
				&.puddle3 {
					width: 8em;
					height: 4em;
					top: 24em;
					left: calc(50% - 7em);
				}
				&.puddle4 {
					width: 7em;
					height: 4em;
					top: 24em;
					left: calc(50% + 5.2em);
				}
				&.jug1 {
					width: 4em;
					top: 12.2em;
					left: calc(50% - 7.75em);
				}
				&.jug2 {
					width: 3em;
					height: 3.5em;
					top: 17em;
					left: calc(50% - 6.8em);
				}
			}
		}

		&.orientation-1-step-ladder {
			min-height: calc(26em + 6em);
			background-image: url('../../../../assets/images/modules/tasks/spot-errors/orientation-1-step-ladder.svg'), linear-gradient(rgba(#808285, 0), #808285);;
			background-size: auto 26em, 100% 19.5em;
			background-position: top 6em center, top center;
			.SpotErrors-error {
				&.full-hands {
					top: 10em;
					left: calc(50% - 0.75em);
				}
				&.can {
					width: 3.5em;
					height: 3.5em;
					top: 23em;
					left: calc(50% - 2.2em);
				}
				&.box {
					border-radius: 1em;
					width: 8em;
					height: 7em;
					top: 25.25em;
					left: calc(50% - 10.25em);
				}
				&.puddle {
					height: 5em;
					width: 10em;
					top: 25em;
					left: calc(50% + 1em);
				}
				&.child {
					height: 7em;
					width: 3em;
					top: 13.2em;
					left: calc(50% + 8em);
				}
			}
		}

		

		&.floral-1-needed-corrections {
			margin: auto;
			min-height: calc(32.52em + 3.5em);
			background-image: url('../../../../assets/images/modules/tasks/spot-errors/floral-1-needed-corrections.svg'), linear-gradient(rgba(#808285, 0), #808285);
			background-size: auto 31.2em, 100% 15.5em;
			background-position: top 5em left calc(50% - 0.5em), top 0 center;
			.SpotErrors-error {
				&.paperwork {
					top: 15em;
					left: calc(50% + 3.3em);
					width: 5em;
					height: 3em;
				}
				&.cut-stems {
					top: 21.1em;
					left: calc(50% - 10em);
					height: 2em;
				}
				&.scissors {
					top: 15.8em;
					left: calc(50% - 3.8em);
					height: 2.2em;
				}
				&.ribbon {
					top: 26.7em;
					left: calc(50% + 6.5em);
					width: 3em;
					height: 3em;
				}
				&.loose-cardboard {
					top: 13.9em;
					left: calc(50% - 9.8em);
					width: 4.5em;
					height: 4.75em;
				}
			}
		}

		&.floral-1-ready-department {
			min-height: calc(19.6em + 11em);
			background-image: url('../../../../assets/images/modules/tasks/spot-errors/floral-1-ready-department.svg'), linear-gradient(rgba(#808285, 0), #808285);
			background-size: auto 19.6em, 100% 28.5em;
			background-position: top 11em left calc(50% + 1.5em), top center;
			.SpotErrors-error {
				&.glass {
					top: 17.5em;
					left: calc(50% - 10em);
					width: 5.5em;
					height: 5.5em;
				}
				
				&.flower-debris {
					top: 26.9em;
					left: calc(50% - 6.3em);
					height: 2.5em;
				}
			}
		}
		
		&.floral-1-floral-production-area {
			min-height: calc(24.76em + 9em);
			background-image: url('../../../../assets/images/modules/tasks/spot-errors/floral-1-floral-production-area.svg'), linear-gradient(rgba(#808285, 0), #808285);
			background-size: auto 24.76em, 100% 25.8em;
			background-position: top 9em left calc(50% - 3em), top center;
			.SpotErrors-error {
				&.tool-blade {
					top: 9.3em;
					width: 2.1em;
					height: 4em;
					right: calc(50% - 4.7em);
				}
				&.flower-debris {
					top: 30.2em;
					height: 2.2em;
					right: calc(50% - 9.5em);
				}
				&.counter-mess {
					top: 15em;
					left: calc(50% - 10.5em);
					width: 8em;
					height: 6.7em;
				}
				&.dirty-floor {
					top: 31.1em;
					right: calc(50% + 1.6em);
					width: 7.5em;
					height: 3.5em;
				}
				&.dirty-tool {
					top: 9.2em;
					right: calc(50% + 2.75em);
					width: 2em;
				}
			}
		}

		&.floral-2-dangers {
			min-height: calc(18.2em + 10em);
			background-image: url('../../../../assets/images/modules/tasks/spot-errors/floral-2-dangers.svg'), linear-gradient(rgba(#808285, 0), #808285);
			background-size: 30.9em auto, 100% 17.6em;
			background-position: top 13em left calc(50% + 4em), top 3em center;
			.SpotErrors-error {
				&.puddle {
					height: 3.5em;
					width: 9em;
					top: 25em;
					left: calc(50% - 12.4em);
				}
				&.puddle2 {
					top: 35em;
					left: calc(50% - 3.2em);
					width: 9.2em;
					height: 4em;
					&.spotted {
						background-position: top 0.5em center;
					}
				}
				&.hoses {
					height: 6em;
					width: 4em;
					top: 15em;
					left: calc(50% - 9.5em);
				}
			}
		}

		&.food-safety-1-sink {
			min-height: calc(22.11em + 10em);
			background-image: url('../../../../assets/images/modules/tasks/spot-errors/food-safety-1-sink.svg'), linear-gradient(rgba(#808285, 0), #808285);
			background-size: auto 22.11em, 100% 26em;
			background-position: top 10em left calc(50%), top center;
			.SpotErrors-error {
				&.out-of-soap {
					top: 15em;
					width: 3.5em;
					height: 5.5em;
					left: calc(50% - 8.3em);
				}
				&.paper-towels-out {
					top: 9.5em;
					border-radius: 1em;
					width: 5.75em;
					height: 7.5em;
					left: calc(50% + 4em);
				}
				&.cloth-draped-over {
					top: 20.25em;
					width: 3em;
					height: 3.75em;
					left: calc(50% - 4.8em);
				}
				&.floor-mat-turned-up {
					top: 29em;
					left: calc(50% - 8em);
					width: 3em;
					height: 3.5em;
				}
			}
		}

		&.food-safety-1-wizard-ready {
			background-image: url('../../../../assets/images/modules/tasks/spot-errors/food-safety-1-wizard-ready.svg');
			background-size: auto 25em;
			background-position: top 8.5em center;
			min-height: 33.5em;
			.SpotErrors-error {
				&.no-beardnet {
					top: 13em;
					width: 4.75em;
					height: 7em;
					left: calc(50% - 2.3em);
				}
				&.wearing-shorts {
					top: 26.5em;
					width: 7em;
					height: 6em;
					left: calc(50% - 3.4em);
				}
				&.oven-mitts-on {
					top: 23.5em;
					width: 3em;
					height: 4em;
					left: calc(50% - 5.6em);
					&::after {
						content: '';
						position: absolute;
						border-radius: 100%;
						top: 0em;
						width: 3em;
						height: 4em;
						left: 8.1em;
					}
					&.spotted::after {
						background-image: url('../../../../assets/images/modules/icon-check2.svg');
						background-size: 1.4em auto;
						background-position: center center;
					}
				}
				&.shoulder-covered-in-cat-hair {
					top: 16em;
					left: calc(50% + 2.35em);
					width: 2em;
					height: 2.5em;
				}
			}
		}

		&.food-safety-1-should-move-items {
			min-height: calc(25em + 12em);
			background-image: url('../../../../assets/images/modules/tasks/spot-errors/food-safety-1-should-move-items.svg');
			background-size: auto 14.96em;
			background-position: top 12em left calc(50%);
			min-height: 25em;
			.SpotErrors-error {
				&.above-line {
					top: 12.2em;
					width: 4.5em;
					height: 3.5em;
					left: calc(50% + 4.6em);
				}
			}
		}

		&.intro-parking-lot {
			background-image: url('../../../../assets/images/modules/tasks/spot-errors/intro-parking-lot.svg'), linear-gradient(#797c82, #48484d);
			background-size: 40.71em auto, 100% 20.83em;
			background-position: bottom -4.11em left calc(50% + 6.6em), bottom center;
			.SpotErrors-error {
				&.flower {
					width: 3.25em;
					height: 2.75em;
					bottom: 17.85em;
					left: calc(50% - 10.325em);
				}
				&.box {
					bottom: 12em;
					left: calc(50% - 10em);
				}
				&.bin {
					bottom: 17em;
					left: calc(50% + 2em);
				}
				&.cart {
					width: 8em;
					height: 8em;
					bottom: 8em;
					left: calc(50% + 1.25em);
				}
				&.orc {
					width: 5.2em;
					height: 6em;
					left: calc(50% + 8em);
					bottom: 16.25em;
					&.spotted {
						background-position: center left 0.5em;
					}
				}
			}	
		}

		&.meat-1-meat-cooler {
			min-height: calc(15.5em + 12em);
			background-image: url('../../../../assets/images/modules/tasks/spot-errors/meat-1-meat-cooler.svg'), linear-gradient(rgba(#808285, 0), #808285);
			background-size: auto 15.5em, 100% 24em;
			background-position: top 12em right calc(50% + 1.75em), top center;
			.SpotErrors-error {
				&.empty-roll {
					border-radius: 1em;
					width: 5em;
					height: 4.25em;
					top: 18.25em;
					right: calc(50% + 3.5em);
				}
				&.gauge {
					width: 5em;
					height: 3em;
					top: 23.5em;
					right: calc(50% - 10em);
				}
				&.over-fill {
					width: 5em;
					height: 3.25em;
					top: 12.5em;
					right: calc(50% - 7.2em);
				}
				&.various-heights {
					width: 5.5em;
					height: 3.5em;
					top: 14.5em;
					right: calc(50% + 5em);
				}
			}
		}

		&.meat-1-meat-cooler2 {
			min-height: calc(17.9em + 9.5em);
			background-image: url('../../../../assets/images/modules/tasks/spot-errors/meat-1-meat-cooler2.svg'), linear-gradient(rgba(#808285, 0), #808285);
			background-size: auto 17.9em, 100% 20em;
			background-position: top 9.5em right calc(50%), top center;
			.SpotErrors-error {
				&.updside-down {
					width: 6em;
					height: 3em;
					top: 12.7em;
					right: calc(50% + 1.5em);
				}
				&.frosted-package {
					width: 6em;
					height: 3.5em;
					top: 15.8em;
					right: calc(50% + 1.4em);
				}
				&.brown-spots {
					width: 3em;
					height: 3em;
					top: 12.7em;
					right: calc(50% - 6em);
				}
				&.leaking-package {
					width: 3em;
					height: 2em;
					top: 21.3em;
					right: calc(50% - 2.9em);
				}
				&.dirty-shelf {
					width: 5em;
					height: 3em;
					top: 21em;
					right: calc(50% - 8em);
				}
			}
		}

		&.meat-1-prep-area {
			min-height: calc(32em + 9em);
			background-image: url('../../../../assets/images/modules/tasks/spot-errors/meat-1-prep-area.svg'), linear-gradient(rgba(#808285, 0), #808285);
			background-size: auto 32em, 100% 19em;
			background-position: top 9em right calc(50% + 10em), top center;
			.SpotErrors-error {
				&.spills {
					width: 7em;
					height: 3.5em;
					top: 21.5em;
					left: calc(50% + 4.6em);
				}
				&.raw-meat {
					width: 3em;
					height: 2em;
					top: 12.7em;
					left: calc(50% + 1.5em);
				}
				&.paper-work {
					border-radius: 1em;
					width: 5em;
					height: 3em;
					top: 11.6em;
					left: calc(50% - 8.8em);
				}
				&.cow {
					border-radius: 1em;
					width: 29em;
					height: 20em;
					top: 21.5em;
					right: calc(50% + 2.5em);
					
					&.spotted {
						background-image: url('../../../../assets/images/modules/icon-check2.svg');
						background-size: 1.4em auto;
						background-position: right 2em top 6em;
					}
				}
			}
		}

		&.meat-1-stickers {
			background-image: url('../../../../assets/images/modules/tasks/spot-errors/meat-1-stickers.svg');
			background-size: 18.4em auto;
			background-position: top 10em right calc(50%);
			.SpotErrors-error {

				&.hot-deal-1 {
					width: 6.5em;
					height: 3.25em;
					top: 14em;
					right: calc(50% + 1em);

					&:not(.spotted) {
						background-image: url('../../../../assets/images/modules/tasks/spot-errors/meat-1-stickers-sticker.svg');
						background-size: 4em auto;
						@include rotate(-14deg);
					}

					&.spotted {
						background-image: none;
					}
				}
				&.hot-deal-2 {
					width: 6.5em;
					height: 3.25em;
					top: 15.5em;
					right: calc(50% - 3.15em);

					&:not(.spotted) {
						background-image: url('../../../../assets/images/modules/tasks/spot-errors/meat-1-stickers-sticker.svg');
						background-size: 4em auto;
						@include rotate(-24deg);
					}

					&.spotted {
						background-image: none;
					}
				}
				&.hot-deal-3 {
					width: 6.5em;
					height: 3.25em;
					top: 14.5em;
					right: calc(50% - 8em);

					&:not(.spotted) {
						background-image: url('../../../../assets/images/modules/tasks/spot-errors/meat-1-stickers-sticker.svg');
						background-size: 4em auto;
						@include rotate(-1deg);
					}

					&.spotted {
						background-image: none;
					}
				}
			}
		}

		&.meat-2-freezer {
			min-height: calc(18.5em + 9em);
			background-image: url('../../../../assets/images/modules/tasks/spot-errors/meat-2-freezer.svg'), linear-gradient(rgba(#808285, 0), #808285);
			background-size: auto 18.5em, 100% 17.6em;
			background-position: top 9em center, top 3em center;
			.SpotErrors-error { 
				&.sign {
					top: 12.1em;
					left: calc(50% + 1.5em);
					width: 5em;
					height: 5.25em;
				}
			}
		}

		&.meat-2-meat-case {
			min-height: calc(18.11em + 9.5em);
			background-image: url('../../../../assets/images/modules/tasks/spot-errors/meat-2-meat-case.svg');
			background-size: auto 18.11em;
			background-position: top 9.5em center;
			.SpotErrors-error { 
				// &.edges {
				// 	top: 12.1em;
				// 	left: calc(50% + 1.5em);
				// 	width: 5em;
				// 	height: 5.25em;
				// }
				&.temperature {
					width: 3em;
					height: 3em;
					top: 21.5em;
					left: calc(50% + 4.8em);
				}
				&.price {
					width: 12.1em;
					height: 1.5em;
					top: 19.8em;
					left: calc(50% - 7.5em);
					border-radius: 0;
				}
				&.vent {
					width: 3.5em;
					height: 3em;
					top: 24.25em;
					left: calc(50% - 7.5em);
				}
			}

		}

		&.orientation-1-store-aisle {
			min-height: calc(23em + 9.3em);
			background-image: url('../../../../assets/images/modules/tasks/spot-errors/orientation-1-store-aisle.svg'), linear-gradient(rgba(#808285, 0), #808285);
			background-size: auto 23em, 100% 21.22em;
			background-position: left calc(50% + 2em) top 9.3em, top center;
			.SpotErrors-error {
				&.disorder-on-shelf {
					height: 4em;
					width: 4em;
					top: 16em;
					left: calc(50% - 6em);
				}
				&.utensil {
					height: 2em;
					top: 16.5em;
					left: calc(50% + 6em);
				}
				&.open-cooler-door {
					height: 12em;
					width: 4em;
					top: 14em;
					left: calc(50% - 10em);
				}
				&.fire-exit-blocked {
					top: 17.25em;
					left: calc(50% - 1.75em);
				}
				&.no-fire-extinguisher {
					width: 3em;
					height: 4em;
					top: 12.25em;
					left: calc(50% - 2.1em);
				}
				&.box {
					width: 7em;
					height: 6.3em;
					top: 23.3em;
					left: calc(50% - 2.1em);
					border-radius: 1em;
				}
			}
		}

		&.produce-1-prep-area {
			background-image: url('../../../../assets/images/modules/tasks/spot-errors/produce-1-prep-area.svg'), linear-gradient(rgba(#808285, 0), #808285);;
			background-size: auto 20.46em, 100% 24.11em;
			background-position: top 9.67em left calc(50% + 4.25em),top center;
			.SpotErrors-error {
				&.trash {
					top: 19.8em;
					right: calc(50% + 5.5em);
					width: 5em;
					height: 7.5em;
				}
				&.knife {
					top: 17.2em;
					right: calc(50% - 5.1em);
					width: 4.5em;
					height: 1.5em;
				}
				&.grapes {
					top: 28.3em;
					right: calc(50% - 8.65em);
					width: 4em;
					height: 2.5em;
				}
				&.notes {
					top: 17.7em;
					right: calc(50% + 4.8em);
					width: 3.5em;
					height: 2.5em;
					@include rotate(50deg)
				}
				&.boxes {
					top: 9.5em;
					right: calc(50% - 12em);
					width: 9em;
					height: 9em;
				}
			}
		}

		&.produce-1-scales {
			background-image: url('../../../../assets/images/modules/tasks/spot-errors/produce-1-scales.svg'), linear-gradient(rgba(#808285, 0), #808285);
			background-size: 45em auto, 100% 30em;
			background-position: top 8.83em left calc(50%), top center;
			.SpotErrors-error {
				&.screen {
					top: 8.3em;
					right: calc(50% - 5.8em);
					width: 6em;
					height: 4em;
				}
			}
		}

		&.produce-1-orange {
			margin: auto;
			width: 20.833em;
			background-image: url('../../../../assets/images/modules/tasks/spot-errors/produce-1-orange.svg');
			background-size: 20em auto;
			background-position: top calc(50%) left calc(50%);
			.SpotErrors-error.COOL-label
			{
				top: calc(50% + 2.2em);
				right: calc(50% - 3.7em);
				width: 6.5em;
				height: 5.75em;
			}
		}

		&.produce-1-bad-tomatoes {
			background-image: url('../../../../assets/images/modules/tasks/spot-errors/produce-1-bad-tomatoes.svg');
			background-size: 15em auto;
			background-position: top 9em center;
			.SpotErrors-error {
				&.tomato-1 {
					top: 13.3em;
					right: calc(50% - 4.2em);
					width: 2em;
					height: 2em;
				}
				&.tomato-2 {
					top: 9.7em;
					right: calc(50% - 7em);
					width: 2.5em;
					height: 2.25em;
				}
				&.tomato-3 {
					top: 9.7em;
					right: calc(50% + 4.7em);
					width: 2.25em;
					height: 2.25em;
				}
			}
		}

		&.produce-1-pineapple-sniff {
			background-image: url('../../../../assets/images/modules/tasks/spot-errors/produce-1-pineapple-sniff.svg');
			background-size: 13em auto;
			background-position: top 7em center;
			.SpotErrors-error {
				&.bottom {
					top: 26.5em;
					right: calc(50% - 4.2em);
					width: 8em;
					height: 2em;
				}
			}
		}

		&.produce-2-fruit-stands {
			min-height: calc(21.5em + 10.5em);
			background-image: url('../../../../assets/images/modules/tasks/spot-errors/produce-2-fruit-stands.svg'), linear-gradient(rgba(#808285, 0), #808285);
			background-size: auto 21.5em, 100% 17em;
			background-position: top 10.5em center, top center;	
			.SpotErrors-error {
				&.label {
					top: 14em;
					left: calc(50% + 4.2em);
					width: 3.8em;
					height: 2em;
				}
				&.price {
					top: 18.8em;
					left: calc(50% + 1.44em);
					width: 3em;
					height: 3em;
				}
				&.stacking {
					top: 20.5em;
					left: calc(50% - 8em);
					height: 3.75em;
					width: 5em;
				}
			}
		}
		&.produce-2-refridgerated-produce-1 {
			min-height: calc(16.78em + 11.5em);
			background-image: url('../../../../assets/images/modules/tasks/spot-errors/produce-2-refridgerated-produce-1.svg'), linear-gradient(rgba(#808285, 0), #808285);
			background-size: auto 16.78em, 100% 27em;
			background-position: top 11.5em center, top center;
			.SpotErrors-error {

				&.salads {
					top: 20.9em;
					right: calc(50% - 8.5em);
					width: 3em;
					height: 3em;
				}
				&.finger-prints {
					top: 24em;
					right: calc(50% - 4em);
					height: 3.5em;
					width: 6em;
				}
				&.wrong-placement {
					top: 21.2em;
					right: calc(50% + 3.9em);
					width: 3em;
					height: 3em;
				}
				&.price-tag {
					top: 16.3em;
					right: calc(50% + 4.5em);
					width: 3em;
					height: 3em;
				}
			}
		}

		&.produce-2-refridgerated-produce-2 {
			min-height: calc(16.78em + 11.5em);
			background-image: url('../../../../assets/images/modules/tasks/spot-errors/produce-2-refridgerated-produce-2.svg'), linear-gradient(rgba(#808285, 0), #808285);
			background-size: auto 16.78em, 100% 27em;
			background-position: top 11.5em center, top center;
			.SpotErrors-error {
				&.temperature {
					top: 24.2em;
					right: calc(50% + 1.8em);
					width: 4em;
					height: 3em;
				}

				&.plastic-clam {
					right: calc(50% - 7.9em);
					width: 3em;
					height: 2em;
					top: 15.5em;
				}

				&.uneven-case {
					top: 14.2em;
					right: calc(50% - 0.35em);
					width: 3em;
					height: 2em;
				}
			}
		}

		

		&.safety-1-boat {
			background-image: url('../../../../assets/images/modules/tasks/spot-errors/safety-1-boat.svg'), linear-gradient(#113955, transparent), linear-gradient(#10597F, #10597F), radial-gradient(ellipse, #7ABCEA 0%, #6BB2DF 10%, #10779E 90%);
			background-size: auto 33.9em, 100% 18.5em, 100% 14.5em, 100% 100%;
			background-position: left calc(50% + 1em) bottom, center top, center bottom, center;
			.SpotErrors-error {
				&.trash-paper {
					width: 2.5em;
					height: 2.5em;
					bottom: 12em;
					right: calc(50% - 1em);
				}
				&.dead-fish {
					width: 2.5em;
					height: 1.25em;
					bottom: 11.7em;
					right: calc(50% - 4.6em);
				}
				&.water {
					width: 2.5em;
					height: 1.5em;
					bottom: 11.75em;
					right: calc(50% - 9em);
				}
				&.open-cans {
					width: 1.25em;
					height: 1.75em;
					bottom: 16.25em;
					right: calc(50% - 2.25em);
				}
				&.broken-oars {
					width: 4.5em;
					height: 1em;
					bottom: 13.15em;
					right: calc(50% + 2.75em);
				}
			}
		}

		&.safety-1-bodyparts {
			background-image: url('../../../../assets/images/modules/tasks/spot-errors/safety-1-bodyparts.svg');
			background-size: auto 21.6em;
			background-position: top 9em left calc(50% - 1em);
			
			.SpotErrors-error {
				&.upper-legs {
					width: 2em;
					height: 2em;
					top: 22.4em;
					right: calc(50% - 0.82em);
				}
				&.lower-legs {
					width: 2em;
					height: 2em;
					top: 26.3em;
					right: calc(50% - 0.6em);
				}
			}
		}

		&.safety-1-desk {
			background-image: url('../../../../assets/images/modules/tasks/spot-errors/safety-1-desk.svg'), linear-gradient(rgba(#808285, 0), #808285);
			background-size: auto 21.6em, 100% 24.11em;
			background-position: top 10.5em left calc(50% + 3.25em), top center;
			.SpotErrors-error {
				&.puddle {
					width: 10em;
					height: 5em;
					top: 28.4em;
					right: calc(50% + 3em);
				}
				&.scissors-in-cup {
					width: 2.5em;
					height: 5em;
					top: 14.7em;
					right: calc(50% - 9.85em);
				}
				&.computer-on-fire {
					width: 4.5em;
					height: 8em;
					top: 19.6em;
					right: calc(50% + 6.4em);
				}
				&.broken-glass {
					width: 2.25em;
					height: 3em;
					top: 16.3em;
					right: calc(50% + 4.25em);
				}
			}
		}

		&.seafood-1-freezer-issues {
			background-image: url('../../../../assets/images/modules/tasks/spot-errors/seafood-1-freezer-issues.svg'), linear-gradient(rgba(#808285, 0), #808285);
			background-size: 19.77em auto, 100% 21em;
			background-position: top 9.83em center, top center;
			.SpotErrors-error {
				&.fillets {
					width: 6em;
					height: 4em;
					top: 13.8em; 
					left: calc(50% - 7.7em);
					border-radius: 6em;
				}
				&.temperature {
					width: 3.5em;
					height: 2.2em;
					top: 22.5em;
					right: calc(50% - 8.7em);
				}
				&.lobster {
					width: 3em;;
					height: 3em;
					top: 15.5em;
					right: calc(50% - 10.1em);
				}
				&.sign {
					width: 4em;
					height: 3em;
					top: 9.4em;
					right: calc(50% - 5.5em);
				}
			}
		}

		&.seafood-1-bad-fish {
			background-image: url('../../../../assets/images/modules/tasks/spot-errors/seafood-1-bad-fish.svg');
			background-size: 33em auto;
			background-position: bottom -4.11em right calc(50% + 3.25em);
			.SpotErrors-error {
				&.eyes {
					width: 2.5em;
					height: 2.5em;
					bottom: 17em;
					right: calc(50% + 6.9em);
				}
				&.gills {
					width:2em;
					height: 3em;
					bottom: 16.3em;
					right: calc(50% + 4.85em);
				}
				&.scales {
					width: 4.5em;
					height: 3.5em;
					bottom: 18em;
					right: calc(50% + 1em);
				}
				&.belly-cavity {
					width: 7em;
					height: 2.25em;
					bottom: 16em;
					right: calc(50% - 3.2em);
				}
			}
		}
		&.seafood-1-calendar-day-4 {
			min-height: calc(16.38em + 10em);
			background-image: url('../../../../assets/images/modules/tasks/spot-errors/seafood-1-calendar-first.svg');
			background-size: auto 16.38em;
			background-position: top 10em left 50%;
			.SpotErrors-error {
				&.day-4 {
					width: 2.5em;
					height: 2.5em;
					top: 15.1em;
					right: calc(50% - 5.05em);
				}
			}
		}
		&.seafood-1-calendar-day-5 {
			min-height: calc(16.38em + 10em);
			background-image: url('../../../../assets/images/modules/tasks/spot-errors/seafood-1-calendar-first.svg');
			background-size: auto 16.38em;
			background-position: top 10em left 50%;
			.SpotErrors-error {
				&.day-5 {
					width: 2.5em;
					height: 2.5em;
					top: 15.1em;
					right: calc(50% - 7em);
				}
			}
		}
		&.seafood-1-calendar-day-10 {
			min-height: calc(16.38em + 10em);
			background-image: url('../../../../assets/images/modules/tasks/spot-errors/seafood-1-calendar-first.svg');
			background-size: auto 16.38em;
			background-position: top 10em left 50%;
			.SpotErrors-error {
				&.day-10 {
					width: 2.5em;
					height: 2.5em;
					top: 17.15em;
					right: calc(50% - 3.05em);
				}
			}
		}
		&.seafood-1-product-case {
			min-height: calc(21em + 10em);
			background-image: url('../../../../assets/images/modules/tasks/spot-errors/seafood-1-product-case.svg');
			background-size: auto 21em;
			background-position: top 10em left 50%;
			.SpotErrors-error {
				&.back-bottom {
					top: 19.9em;
					left: calc(50% - 6.5em);
					width: 15em;
					height: 3.5em;
				}
				&.top-front {
					top: 13.75em;
					left: calc(50% - 7.25em);
					width: 16em;
					height: 2.5em;
				}
				&.trash {
					top: 24em;
					left: calc(50% - 10.25em);
					width: 7.5em;
					height: 8em;
				}
			}
		}
		&.seafood-1-shrimp-tag {
			min-height: calc(10.5em + 8em);
			background-image: url('../../../../assets/images/modules/tasks/spot-errors/seafood-1-shrimp-tag.svg');
			background-size: auto 10.5em;
			background-position: top 8em left 50%;
			.SpotErrors-error {
				&.each {
					top: 13em;
					left: calc(50% + 3.5em);
					width: 3em;
					height: 2em;
				}
				&.yesterday {
					top: 14.9em;
					left: calc(50% - 8em);
					width: 5em;
					height: 3em;
				}
				&.barcode {
					top: 15.05em;
					left: calc(50% + 3.2em);
					width: 4.8em;;
					height: 3em;
				}
				&.crab {
					top: 9.8em;
					left: calc(50% + 4.4em);
					width: 3em;
					height: 3em;
				}
			}
		}

		&.seafood-2-self-service-case1 {
			min-height: calc(19.5em + 9em);
			background-image: url('../../../../assets/images/modules/tasks/spot-errors/seafood-2-self-service-case1.svg');
			background-size: auto 19.5em;
			background-position: top 9em center;
			.SpotErrors-error {
				&.melted-ice {
					top: 20em;
					left: calc(50% - 3.1em);
					width: 6em;
					height: 3.3em;
				}
				&.smelly-one {
					top: 10.8em;
					left: calc(50% - 3.1em);
					width: 6em;
					height: 3.25em;
				}
				&.label-left {
					top: 13.9em;
					left: calc(50% - 9.1em);
					width: 6em;
					height: 3em;
				}
				&.discolored-fillet {
					top: 17em;
					left: calc(50% + 2.9em);
					width: 6em;
					height: 3em;
				}
				&.leaking-package {
					top: 14.8em;
					left: calc(50% + 1em);
					width: 3em;
					height: 3em;
				}
				&.salmon-as-crab {
					top: 10.9em;
					left: calc(50% + 2.8em);
					width: 6em;
					height: 3em;
				}
				&.tiny-fish-big-tray {
					top: 20em;
					left: calc(50% - 9.2em);
					width: 6em;
					height: 3em;
				}
			}
		}

		&.shrink-1-where-shrink {
			background-image: url('../../../../assets/images/modules/tasks/spot-errors/shrink-1-where-shrink.svg'), linear-gradient(rgba(#808285, 0), #808285);
			background-size: auto 24.8em, 100% 30em;
			background-position: bottom 5.1em left calc(50% + 1em), bottom 19em center;

			.SpotErrors-error {
				&.bar {
					width: 13em;
					height: 4em;
					bottom: 13.5em;
					right: calc(50% - 13em);
				}
				&.fridge {
					border-radius: 0;
					width: 9.75em;
					height: 11.75em;
					bottom: 18.25em;
					right: calc(50% - 12.5em);
				}
				&.coffin {
					border-radius: 0;
					width: 9.75em;
					height: 3em;
					bottom: 21.25em;
					right: calc(50% - 2em);
				}
				&.shelves {
					width: 9.5em;
					height: 4.5em;
					bottom: 12em;
					right: calc(50% + 2em);
				}
			}
		}

		&.shrink-1-potential-shrink {
			background-image: url('../../../../assets/images/modules/tasks/spot-errors/shrink-1-potential-shrink.svg'), linear-gradient(rgba(#808285, 0), #808285);
			background-size: auto 23em, 100% 30em;
			background-position: bottom 5.6em left calc(50%), bottom 10em center;

			.SpotErrors-error {
				&.boxes {
					border-radius: 0;
					width: 9.65em;
					height: 2.75em;
					bottom: 15.75em;
					right: calc(50% - 1.25em);
				}
				&.floor-case {
					border-radius: 0;
					width: 9.5em;
					height: 3.85em;
					bottom: 5.5em;
					right: calc(50% - 5.3em);
				}
				&.meat {
					border-radius: 0;
					width: 6.15em;
					height: 3.8em;
					bottom: 19.6em;
					right: calc(50% - 7.65em);
				}
				&.blank-date {
					border-radius: 0;
					width: 2.95em;
					height: 1.5em;
					bottom: 20.85em;
					right: calc(50% + 1.25em);
				}
			}
		}

		&.orientation-1-pick-stuff {
			min-height: calc(28em + 7.5em);
			background-image: 
				url('../../../../assets/images/modules/tasks/spot-errors/orientation-1-pick-stuff.svg'),
				radial-gradient(closest-side, #7ABCEA, #10779E, #10779E);
			background-size: auto 28em, 100% 200%;
			background-position: top 7.5em left calc(50% - 0.4em), center center;
			
			.SpotErrors-error {
				&.timeclock {
					top: 31.5em;
					left: calc(50% + 1.9em);
					width: 4em;
					height: 3.3em;
				}
				&.fire-extinguisher {
					top: 25em;
					left: calc(50% - 7.3em);
					width: 3.5em;
					height: 4em;
				}
				&.first-aid-kit {
					top: 15.7em;
					left: calc(50% - 7.75em);
					width: 4em;
					height: 3.3em;
				}
				&.home-phone {
					top: 9.2em;
					left: calc(50%);
					width: 3.75em;
					height: 3.3em;
				}
				&.employee-handbook {
					top: 12.7em;
					left: calc(50% + 5em);
					width: 3.5em;
					height: 4em;
				}
				&.bathroom-sign {
					top: 23.3em;
					left: calc(50% - 0.7em);
					width: 4em;
					height: 2.75em;
				}
				&.exit-sign {
					top: 7.4em;
					left: calc(50% - 8em);
					width: 3.5em;
					height: 3em;
				}
			}
		}
	}
	

	.SpotErrors-confirmBtn {
		position: fixed;
		right: calc(50% - 8.5em);
		bottom: 0.5em;
		height: 2.25em;
		width: 8.85em;
		background-color: #10779E;
		color: white;
		border-radius: 1em;
		cursor: pointer;
		@include flex('center','center');
		span {
			font-family: 'Source Sans Pro', sans-serif;
			font-size: 0.85em;
			font-weight: bold;
		}
		&.orientation-1-spot-errors-orientation-1-pick-stuff,
		&.floral-2-spot-errors-dangers {
			width: 5em;
			right: calc(50% - 10em);
			background-color: white;
			color: #10779E;
		}
		&.customer-service-2-spot-errors-generic-employee,
		&.customer-service-2-spot-errors-generic-employee-2 {
			right: calc(50% - 10em);
			width: 5em;
			
		}
	}



	&.completed {
		.SpotErrors-error:not(.spotted) {
			border-width: 0.25em;
		}
	}
}