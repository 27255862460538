@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/mixins_backgrounds';
@import '../../../styles/animations';
@import '../../../styles/animations-intro';
.FacilitatorFacilitators {
	position: relative;
	min-height: 100%;

	.FacilitatorFacilitators-wrap {
		@include flex('space-between', 'flex-start');
		.FacilitatorFacilitators-list {
			position: relative;
			width: 50em;
			margin-top: 1em;
			.FacilitatorFacilitators-title {
				font-size: 1.5em;
				
				font-weight: bold;
				color: #10779E;
			}
			.FacilitatorFacilitators-coFacilitatorTooltip {
				position: absolute;
					left: 10em;
					top: 0.1em;
					width: 17em;
					z-index: 5;
					cursor: pointer;
					@include flex('space-between', 'flex-start');
					.FacilitatorFacilitators-coFacilitatorTooltipIcon {
						width: 1.5em;
						height: 1.5em;
						background-image: url('../../../assets/images/facilitator/icon-help-2.svg');
						background-size: contain;
					}
					.FacilitatorFacilitators-coFacilitatorTooltipText {
						display: none;
						width: 15em;
						background-color: #BFBFBF;
						color: #525252;
						border-radius: 0.55em;
						padding: 0.5em;
						p {
							margin-top: 0;
							&:last-of-type {
								margin-bottom: 0;
							}
						}
					}
					&:hover {
						.FacilitatorFacilitators-coFacilitatorTooltipText {
							display: block;
						}
					}
			}
			.FacilitatorFacilitators-addCoFacilitatorBtn {
				position: absolute;
				width: 8em;
				right: 0;
				top: -0.5em;
			}
			.FacilitatorFacilitators-cell {
				width: 40%;
				height: 100%;
				padding: 0 1.5em;
				@include flex('flex-start', 'center');
				&.login {
					width: 20%;
				}
			}
			.FacilitatorFacilitators-header {
				width: 100%;
				height: 2.33em;
				margin-top: .25em;
				color: white;
				background-color: #11466B;
				@include flex('space-between', 'center');
				.FacilitatorFacilitators-cell {
					span {
						display: inline-block;
						vertical-align: middle;
						position: relative;
						font-size: 1.39em;
						font-weight: 600;
					}
					&.name,
					&.email {
						cursor: pointer;
		
					}
					&.sortedBy {
						span::after {
							content: '';
							display: inline-block;
							vertical-align: middle;
							width: 1em;
							height: 1em;
							background-size: 0.6em auto;
							background-image: url('../../../assets/images/facilitator/icon-sort-desc.svg');
						}
						&.ASC span::after {background-image: url('../../../assets/images/facilitator/icon-sort-asc.svg');}
					}
				}
			}
			.FacilitatorFacilitators-body {
				width: 100%;
				.FacilitatorFacilitators-row {
					width: 100%;
					height: 2.5em;
					color: #525252;
					&:nth-child(odd) {
						background-color: #EDEDED;
					}
					@include flex('space-between', 'center');
					.FacilitatorFacilitators-cell {
						&.name {
							span {
								font-weight: 600;
								text-decoration: underline;
							}
						} 
					}
				}
			}

			&.coFacilitators {
				.FacilitatorFacilitators-body {
					.FacilitatorFacilitators-row {
						cursor: pointer;
						&:hover {background-color: darken(#ffffff, 5%);}
						&:nth-child(odd) {
							&:hover {background-color: darken(#EDEDED, 3%);}
						}
					}
				}
			}
		}
	}
}