.MultipleChoice {
	&.seafood.landscape {@include seafood-grey-landscape-background();}

	&.seafood-1-issues-priority {
		background-image: linear-gradient(rgba(#808285, 0), rgba(#808285, 1));
		background-position: top 2em center;
		background-size: 100% 21em;
		.MultipleChoice-options {
			padding-bottom: 1em;
			.MultipleChoice-optionsWrap {
				position: relative;
				margin-top: 2em;
				background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/seafood-1-issues-priority/background.svg');
				background-position: top center;
				background-size: 20em auto;
				height: 18em;				
				.MultipleChoice-option { 
					position: absolute;
					span:nth-child(2) {
						bottom: calc(50% - 0.5em);
						left: calc(50% - 0.5em);
						width: 1em;
						height: 1em;
						transform: skewX(-32deg);
					}
					&.option-1 {
						width: 5.1em;
						height: 3.8em;
						top: 2.7em; 
						left: calc(50% - 7.1em);
						transform: skewX(32deg);
					}
					&.option-2 {
						width: 4.6em;
						height: 3.8em;
						top: 2.7em; 
						left: calc(50% - 1.6em);
						transform: skewX(32deg);
					}
					&.option-3 {
						width: 5.1em;
						height: 3.8em;
						top: 2.7em; 
						left: calc(50% + 3.2em);
						transform: skewX(32deg);
					}
					&.option-1,
					&.option-2,
					&.option-3,
					&.selected,
					&.correct,
					&.animateCorrect,
					&.wrong,
					&.animateWrong {@include noAnimation();}
				}
			}
		}
	}

	&.seafood-1-fillets {
		.MultipleChoice-options {
			margin-top: 1em;
			padding-bottom: 1em;
			.MultipleChoice-optionsWrap {
				height: 24.4em;				
				.MultipleChoice-option { 
					display: inline-block;
					width: 15em;
					height: 6em;
					span:nth-child(2) {
						bottom: 0.5em;
						right: 0.75em;
						left: auto;
						width: 1.39em;
						height: 1.39em;
					}
					&.position-1,
					&.position-2,
					&.position-3,
					&.position-4 {margin-left: calc(100% - 17.5em);}
					&.option-1 {
						background-size: 15em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/seafood-1-fillets/option-1.svg');
					}
					&.option-2 {
						background-size: 15em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/seafood-1-fillets/option-2.svg');
					}
					&.option-3 {
						background-size: 15em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/seafood-1-fillets/option-3.svg');
					}
					&.option-4 {
						background-size: 15em auto;		
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/seafood-1-fillets/option-4.svg');
					}
				}
			}
		}
	}

	&.seafood-1-knife-angle {
		.MultipleChoice-options {
			margin-top: 1em;
			padding-bottom: 1em;
			.MultipleChoice-optionsWrap {
				height: 24.4em;				
				.MultipleChoice-option { 
					width: 14em;
					height: 8.2em;
					margin: 0 auto;
					span:nth-child(2) {
						bottom: 0.5em;
						right: 0.75em;
						left: auto;
						width: 1.39em;
						height: 1.39em;
					}

					&.option-1 {
						background-size: 14em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/seafood-1-knife-angle/option-1.svg');
					}
					&.option-2 {
						background-size: 14em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/seafood-1-knife-angle/option-2.svg');
					}
					&.option-3 {
						background-size: 14em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/seafood-1-knife-angle/option-3.svg');
					}
				}
			}
		}
	}
	
	&.seafood-1-poor-quality-fish {
		.MultipleChoice-options {
			margin-top: 1em;
			padding-bottom: 1em;
			.MultipleChoice-optionsWrap {
				height: 5em;
				width: 15em;				
				.MultipleChoice-option { 
					display: inline-block;
					margin-right: 1em;
					width: 18em;
					height: 6.25em;
					span:nth-child(2) {
						position: relative;
						bottom: 0em;
						right: 0.75em;
						left: auto;
						width: 1.39em;
						height: 1.39em;
					}
					&.position-1,
					&.position-2,
					&.position-3,
					&.position-4 {margin-left: 1em;}
					&.option-1 {
						background-size: 18em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/seafood-1-poor-quality-fish/option-1.svg');
					}
					&.option-2 {
						background-size: 18em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/seafood-1-poor-quality-fish/option-2.svg');
					}
					&.option-3 {
						background-size: 18em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/seafood-1-poor-quality-fish/option-3.svg');
					}
					&.option-4 {
						background-size: 18em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/seafood-1-poor-quality-fish/option-4.svg');
					}
				}
			}
		}
	}	

	&.seafood-2-service-case-shingle {
		.MultipleChoice-options {
			margin-top: 1em;
			padding-bottom: 1em;
			.MultipleChoice-optionsWrap {
				background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/seafood-2-service-case-shingle/background.svg');
				background-position: top center;
				background-size: auto 100%;
				height: 18.55em;				
				.MultipleChoice-option { 
					position: absolute; 
					width: 5.3em;
					height: 11em;
					top: 2.1em;
					span:nth-child(2) {
						top: 0.5em;
						right: 0.5em;
						left: auto;
						bottom: auto;
						
					}
					&.option-1 {left: calc(50% - 8.4em);}
					&.option-2 {left: calc(50% - 2.8em);}
					&.option-3 {left: calc(50% + 2.9em);}
					&.selected,
					&.correct,
					&.animateCorrect,
					&.wrong,
					&.animateWrong {@include noAnimation();}
				}
			}
		}	
	}

	&.seafood-1-shellfish {
		.MultipleChoice-options {
			margin-top: 1em;
			padding-bottom: 1em;
			.MultipleChoice-optionsWrap {
				padding: 0 1em;
				height: 24.4em;				
				.MultipleChoice-option { 
					display: inline-block;
					width: 6em;
					height: 6em;
					span:nth-child(2) {
						bottom: 0.5em;
						right: 0.75em;
						left: auto;
						width: 1.39em;
						height: 1.39em;
					}
					&.option-1 {
						background-size: 6em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/seafood-1-shellfish/option-1.svg');
					}
					&.option-2 {
						background-size: 5em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/seafood-1-shellfish/option-2.svg');
					}
					&.option-3 {
						background-size: 6em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/seafood-1-shellfish/option-3.svg');
					}
					&.option-4 {
						background-size: 6em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/seafood-1-shellfish/option-4.svg');
					}
					&.option-5 {
						background-size: 4em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/seafood-1-shellfish/option-5.svg');
					}
					&.option-6 {
						background-size: 6em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/seafood-1-shellfish/option-6.svg');
					}
					&.option-7 {
						background-size: 3em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/seafood-1-shellfish/option-7.svg');
					}
					&.option-8 {
						background-size: 4em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/seafood-1-shellfish/option-8.svg');
					}
					&.option-9 {
						background-size: 4em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/seafood-1-shellfish/option-9.svg');
					}
				}
			}
		}
	}

	&.seafood-1-where-to-start {
		.MultipleChoice-options {
			margin-top: 1em;
			padding-bottom: 1em;
			.MultipleChoice-optionsWrap {
				width: 17.5em;	
				margin: 0 auto;
				.MultipleChoice-option { 
					width: 100%;
					background-size: auto 100%;
					margin-bottom: 1em;
					&.option-1 {
						height: 6.16em;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/seafood-1-where-to-start/option-1.svg');
					}
					&.option-2 {
						height: 9em;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/seafood-1-where-to-start/option-2.svg');
					}
					&.option-3 {
						height: 6.14em;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/seafood-1-where-to-start/option-3.svg');
					}
				}
			}
		}
	}

	&.seafood-2-plan-how-far {
		.MultipleChoice-options {
			margin-top: 1em;
			padding-bottom: 1em;
			.MultipleChoice-optionsWrap {
				width: 18em;	
				margin: 0 auto;
				text-align: center;
				.MultipleChoice-option { 
					display: inline-block;
					width: 5em;
					height: 6em;
					background-size: contain;
					margin: 0.4em;
					span:nth-child(2) {
						bottom: 1.5em;
						right: 0em;
						left: auto;
						
					}
					&.option-1 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/seafood-2-plan-how-far/option-1.svg');
					}
					&.option-2 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/seafood-2-plan-how-far/option-2.svg');
					}
					&.option-3 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/seafood-2-plan-how-far/option-3.svg');
					}
					&.option-4 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/seafood-2-plan-how-far/option-4.svg');
					}
					&.option-5 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/seafood-2-plan-how-far/option-5.svg');
					}
					&.option-6 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/seafood-2-plan-how-far/option-6.svg');
					}
				}
			}
		}
	}
}