@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';
@import '../../../../styles/mixins_backgrounds';
@import '../../../../styles/animations';
@import '../../../../styles/animations-intro';
.Welcome {
	position: relative;
	height: 100%;

	.Welcome-content {
		width: 100%;
		height: 50%;
		background-image: linear-gradient(#11466B, rgba(#11466B, 0));
		background-size: 100% 100%;
		text-align: center;
		padding-top: 2.67em;
		margin: auto;
		.Welcome-title {
			margin: auto;
			width: 21em;
			p {
				font-size: 2em;
				font-weight: 900;
				color: #E8B341;
				margin: 0;
			}
		}
		.Welcome-text {
			width: 17em;
			margin: 1em auto;
			p {
				font-size: 1.33em;
				font-weight: 500;
				color: white;
				margin: 0;
			}
		}
	}
}