/* General */
@mixin dark-grey-background() {
	background-color: #777777;
	background-image: radial-gradient(ellipse, #BFBFBF, #777777);
	background-size: 100% 100%;
}
@mixin grey-background() {
	background-color: #BFBFBF;
	background-image: radial-gradient(ellipse, #DCDCDC, #BFBFBF);
	background-size: 100% 100%;
}
@mixin dark-grey-landscape-background() {
	background-color: #777777;
	background-image: radial-gradient(ellipse, #BFBFBF, #777777);
	background-size: 100% 100%;
}
@mixin grey-landscape-background() {
	background-color: #BFBFBF;
	background-image: radial-gradient(ellipse, #D7D7D7, #B5B5B5);
	background-size: 100% 100%;
}

@mixin blue-background() {
	background-color: #10779E;
	background-image: radial-gradient(ellipse, #7ABCEA, #10779E);
	background-size: 100% 100%;
}
@mixin blue-landscape-background() {
	background-color: #10779E;
	background-image: radial-gradient(ellipse, #7ABCEA 0%, #6BB2DF 10%, #10779E 90%);
	background-size: 100% 100%;;
}

/* Login */
@mixin login-background() {
	background-color: #18698D;
	background-image: 
		linear-gradient(#113955 0%, #11466B 40%, rgba(#11466B, 0) 100%), 
		url('../assets/images/backgrounds/login.svg'), 
		linear-gradient(#18698D, #11466B), 
		radial-gradient(ellipse, #7ABCEA 0%, #6BB2DF 15%, #10779E 100%);
	background-size: 100% calc(100% - 22.67em), auto 33.89em, 100% 11em, 100% 100%;
	background-position: bottom 22.67em center, bottom -0.1em center, center bottom, center;
}

/* Map */
@mixin map-background() {
	background-color: #10597F;
	background-image: linear-gradient(to right, #10597F, #67A4D1, #10597F);
	background-size: 100% 100%;
}
@mixin map-background-landscape() {
	background-color: #10597F;
	background-image: linear-gradient(to right, #10597F, #10597F, #67A4D1, #10597F, #10597F);
	background-size: 50em 100%;
	background-position: center;
}

/* Avatar maker */
@mixin avatar-maker-background() {
	background-color: #C6C6C6;
	background-image: radial-gradient(ellipse, #D7D7D7, #B5B5B5);
}
@mixin avatar-maker-landscape-background() {
	background-image: url('../assets/images/backgrounds/avatar-maker.svg'), radial-gradient(ellipse, #D7D7D7, #B5B5B5);
	background-size: auto 25.6em, 100% 100%;
	background-position: bottom 8.56em center, center center;
}

/* Bagger */
@mixin bagger-blue-background() {
	background-image: url('../assets/images/backgrounds/bagger.svg'), radial-gradient(ellipse, #7ABCEA, #10779E);
	background-size: auto 36.48em, 100% 100%;
	background-position: left calc(50% - 8em) bottom -2.5em, center center;
}
@mixin bagger-blue-landscape-background() {
	background-image: url('../assets/images/backgrounds/bagger.svg'), radial-gradient(ellipse, #7ABCEA 0%, #6BB2DF 10%, #10779E 90%);
	background-size: auto 36.48em, 100% 100%;
	background-position: center bottom -2em, center center;
}
@mixin bagger-grey-landscape-background() {
	background-image: url('../assets/images/backgrounds/bagger-grey.svg'), radial-gradient(ellipse, #D7D7D7, #B5B5B5);
	background-size: auto 36.48em, 100% 100%;
	background-position: center bottom -2em, center center;
}

/* Bakery */
@mixin bakery-blue-background() {
	background-image: url('../assets/images/backgrounds/bakery.svg'), radial-gradient(ellipse, #7ABCEA, #10779E);
	background-size: auto 36.48em, 100% 100%;
	background-position: left calc(50% - 8em) bottom -2.5em, center center;
}
@mixin bakery-blue-landscape-background() {
	background-image: url('../assets/images/backgrounds/bakery.svg'), radial-gradient(ellipse, #7ABCEA 0%, #6BB2DF 10%, #10779E 90%);
	background-size: auto 36.48em, 100% 100%;
	background-position: center bottom -2em, center center;
}
@mixin bakery-grey-landscape-background() {
	background-image: url('../assets/images/backgrounds/bakery-grey.svg'), radial-gradient(ellipse, #D7D7D7, #B5B5B5);
	background-size: auto 36.48em, 100% 100%;
	background-position: center bottom -2em, center center;
}


/* Cashier */
@mixin cashier-blue-background() {
	background-image: url('../assets/images/backgrounds/cashier.svg'), radial-gradient(ellipse, #7ABCEA, #10779E);
	background-size: auto 36.48em, 100% 100%;
	background-position: left calc(50% - 8em) bottom -2.5em, center center;
}
@mixin cashier-blue-landscape-background() {
	background-image: url('../assets/images/backgrounds/cashier.svg'), radial-gradient(ellipse, #7ABCEA 0%, #6BB2DF 10%, #10779E 90%);
	background-size: auto 36.48em, 100% 100%;
	background-position: center bottom -2em, center center;
}
@mixin cashier-grey-landscape-background() {
	background-image: url('../assets/images/backgrounds/cashier-grey.svg'), radial-gradient(ellipse, #D7D7D7, #B5B5B5);
	background-size: auto 36.48em, 100% 100%;
	background-position: center bottom -2em, center center;
}

/* Center store */
@mixin center-store-blue-background() {
	background-color: #18698D;
	background-image: url('../assets/images/backgrounds/center-store.svg'), radial-gradient(ellipse, #7ABCEA, #10779E);
	background-size: auto 37.5em, 100% 100%;
	background-position: left calc(50% - 7.25em) bottom -2.5em, center;	
}
@mixin center-store-blue-landscape-background() {
	background-color: #18698D;
	background-image: url('../assets/images/backgrounds/center-store.svg'), radial-gradient(ellipse, #7ABCEA 0%, #6BB2DF 10%, #10779E 90%);
	background-size: auto 32.97em, 100% 100%;
	background-position: bottom -4em center, center;
}
@mixin center-store-grey-landscape-background() {
	background-image: url('../assets/images/backgrounds/center-store-grey.svg'), radial-gradient(ellipse, #D7D7D7, #B5B5B5);
	background-size: auto 32.97em, 100% 100%;
	background-position: bottom -4em center, center;
}

/* Customer service */
@mixin customer-service-blue-background() {
	background-color: #18698D;
	background-image: url('../assets/images/backgrounds/customer-service.svg'), radial-gradient(ellipse, #7ABCEA, #10779E);
	background-size: auto 38.52em, 100% 100%;
	background-position: left calc(50% - 5em) bottom -4em, center;	
}
@mixin customer-service-blue-landscape-background() {
	background-color: #18698D;
	background-image: url('../assets/images/backgrounds/customer-service.svg'), radial-gradient(ellipse, #7ABCEA 0%, #6BB2DF 10%, #10779E 90%);
	background-size: auto 38.52em, 100% 100%;
	background-position: bottom -4em center, center;
}
@mixin customer-service-grey-landscape-background() {
	background-image: url('../assets/images/backgrounds/customer-service-grey.svg'), radial-gradient(ellipse, #D7D7D7, #B5B5B5);
	background-size: auto 38.52em, 100% 100%;
	background-position: bottom -4em center, center;
}
@mixin customer-line() {
	background-image: url('../assets/images/backgrounds/customer-line.svg'), url('../assets/images/backgrounds/customer-service.svg'), radial-gradient(ellipse, #7ABCEA, #10779E);
	background-size: 24.9em auto, auto 25em, 100% 100%;
	background-position: bottom -5.7em right -4.4em, bottom -4em center, center;
}
@mixin customer-line-landscape() {
	background-image: url('../assets/images/backgrounds/customer-line.svg'), url('../assets/images/backgrounds/customer-service.svg'), radial-gradient(ellipse, #7ABCEA 0%, #6BB2DF 10%, #10779E 90%);
	background-size: 30em auto, auto 30em, 100% 100%;
	background-position: bottom -7em center, bottom -4em center, center;
}

/* Deli */
@mixin deli-blue-background() {
	background-image: url('../assets/images/backgrounds/deli.svg'), radial-gradient(ellipse, #7ABCEA, #10779E);
	background-size: auto 36.48em, 100% 100%;
	background-position: left calc(50% - 8em) bottom -2.5em, center center;
}
@mixin deli-blue-landscape-background() {
	background-image: url('../assets/images/backgrounds/deli.svg'), radial-gradient(ellipse, #7ABCEA 0%, #6BB2DF 10%, #10779E 90%);
	background-size: auto 36.48em, 100% 100%;
	background-position: center bottom -2em, center center;
}
@mixin deli-grey-landscape-background() {
	background-image: url('../assets/images/backgrounds/deli-grey.svg'), radial-gradient(ellipse, #D7D7D7, #B5B5B5);
	background-size: auto 36.48em, 100% 100%;
	background-position: center bottom -2em, center center;
}

/* Orientation */
@mixin orientation-blue-background() {
	background-color: #18698D;
	background-image: url('../assets/images/backgrounds/orientation.svg'), linear-gradient(#18698D, #11466B), radial-gradient(ellipse, #7ABCEA, #10779E);
	background-size: auto 24.1em, 100% 11em, 100% 100%;
	background-position: center bottom, center bottom, center;
}
@mixin orientation-blue-landscape-background() {
	background-color: #18698D;
	background-image: url('../assets/images/backgrounds/orientation.svg'), linear-gradient(#18698D, #11466B), radial-gradient(ellipse, #7ABCEA 0%, #6BB2DF 10%, #10779E 90%);
	background-size: auto 24.1em, 100% 11em, 100% 100%;
	background-position: center bottom, center bottom, center;
}
@mixin orientation-grey-landscape-background() {
	background-color: #18698D;
	background-image: url('../assets/images/backgrounds/orientation-grey.svg'), radial-gradient(ellipse, #D7D7D7, #B5B5B5);
	background-size: auto 24.1em, 100% 100%;
	background-position: center bottom, center bottom, center;
}

/* Floral */
@mixin floral-blue-background() {
	background-image: url('../assets/images/backgrounds/floral.svg'), radial-gradient(ellipse, #7ABCEA, #10779E);
	background-size: auto 35.96em, 100% 100%;
	background-position: bottom -1em left calc(50% - 8em), center;
}
@mixin floral-blue-landscape-background() {
	background-image: url('../assets/images/backgrounds/floral.svg'), radial-gradient(ellipse, #7ABCEA 0%, #6BB2DF 10%, #10779E 90%);
	background-size: auto 35.96em, 100% 100%;
	background-position: bottom -1em center, center;
}
@mixin floral-grey-landscape-background() {
	background-image: url('../assets/images/backgrounds/floral-grey.svg'), radial-gradient(ellipse, #D7D7D7, #B5B5B5);
	background-size: auto 35.96em, 100% 100%;
	background-position: bottom -1em center, center;
}

/* Meat */
@mixin meat-blue-background() {
	background-image: url('../assets/images/backgrounds/meat.svg'), radial-gradient(ellipse, #7ABCEA, #10779E);
	background-size: auto 36.48em, 100% 100%;
	background-position: left calc(50% - 8em) bottom -2.5em, center center;
}
@mixin meat-blue-landscape-background() {
	background-image: url('../assets/images/backgrounds/meat.svg'), radial-gradient(ellipse, #7ABCEA 0%, #6BB2DF 10%, #10779E 90%);
	background-size: auto 36.48em, 100% 100%;
	background-position: center bottom -2em, center center;
}
@mixin meat-grey-landscape-background() {
	background-image: url('../assets/images/backgrounds/meat-grey.svg'), radial-gradient(ellipse, #D7D7D7, #B5B5B5);
	background-size: auto 36.48em, 100% 100%;
	background-position: center bottom -2em, center center;
}

/* Produce */
@mixin produce-blue-background() {
	background-image: url('../assets/images/backgrounds/produce.svg'), radial-gradient(ellipse, #7ABCEA, #10779E);
	background-size: auto 37.5em, 100% 100%;
	background-position: right calc(50% + 8em) bottom -2.75em, center;
}
@mixin produce-blue-landscape-background() {
	background-image: url('../assets/images/backgrounds/produce.svg'), radial-gradient(ellipse, #7ABCEA 0%, #6BB2DF 10%, #10779E 90%);
	background-size: auto 37.5em, 100% 100%;
	background-position: bottom -2.75em center, center;
}
@mixin produce-grey-landscape-background() {
	background-image: url('../assets/images/backgrounds/produce-grey.svg'), radial-gradient(ellipse, #D7D7D7, #B5B5B5);
	background-size: auto 37.5em, 100% 100%;
	background-position: bottom -2.75em center, center;
}


/* Salad bar */
@mixin salad-bar-blue-background() {
	background-image: url('../assets/images/backgrounds/salad-bar.svg'), radial-gradient(ellipse, #7ABCEA, #10779E);
	background-size: 34.5em auto, 100% 100%;
	background-position: right calc(50% + 5em) bottom -10em, center;
}

/* Seafood */
@mixin seafood-blue-background() {
	background-image: url('../assets/images/backgrounds/seafood.svg'), radial-gradient(circle at left center, #7ABCEA 10%, #10779E 90%);
	background-size: auto 100%, auto;
	background-position: left calc(50% - 8.5em) bottom -2.5em, left calc(50% - 8.5em) center;
}
@mixin seafood-blue-landscape-background() {
	background-image: url('../assets/images/backgrounds/seafood.svg'), radial-gradient(circle, #7ABCEA 20%, #6BB2DF, #10779E);
	background-size: auto 36.44em, auto;
	background-position: center bottom -2.5em, center center;
}
@mixin seafood-grey-landscape-background() {
	background-image: url('../assets/images/backgrounds/seafood-grey.svg'), radial-gradient(ellipse, #D7D7D7, #B5B5B5);
	background-size: auto 36.44em, auto;
	background-position: center bottom -2.5em, center center;
}

/* Ocean */
@mixin ocean() {
	background-image: url('../assets/images/backgrounds/ocean.svg'), linear-gradient(#113955, transparent), linear-gradient(#10597F, #10597F), radial-gradient(ellipse, #7ABCEA 0%, #6BB2DF 10%, #10779E 90%);
	background-size: auto 36.44em, 100% 18.5em, 100% 14.5em, 100% 100%;
	background-position: center bottom, center top, center bottom, center;
}
@mixin ocean-island() {
	background-image: url('../assets/images/backgrounds/ocean-island.svg'), linear-gradient(#113955, transparent), linear-gradient(#10597F, #10597F), radial-gradient(ellipse, #7ABCEA 0%, #6BB2DF 10%, #10779E 90%);
	background-size: auto 36.44em, 100% 18.5em, 100% 14.5em, 100% 100%;
	background-position: center bottom, center top, center bottom, center;	
}
@mixin ocean-raft() {
	background-image: url('../assets/images/backgrounds/ocean-raft.svg'), linear-gradient(#113955, transparent), linear-gradient(#10597F, #10597F), radial-gradient(ellipse, #7ABCEA 0%, #6BB2DF 10%, #10779E 90%);
	background-size: auto 36.44em, 100% 18.5em, 100% 14.5em, 100% 100%;
	background-position: center bottom, center top, center bottom, center;	
}
@mixin ocean-raft-ice() {
	background-image: url('../assets/images/backgrounds/ocean-raft-ice.svg'), linear-gradient(#113955, transparent), linear-gradient(#10597F, #10597F), radial-gradient(ellipse, #7ABCEA 0%, #6BB2DF 10%, #10779E 90%);
	background-size: auto 37em, 100% 18.5em, 100% 13.5em, 100% 100%;
	background-position: center bottom, center top, center bottom, center;	
}
@mixin ocean-ice-rink() {
	background-image: url('../assets/images/backgrounds/clouds-raft-rocks.svg'), linear-gradient(to left, #93CFEF, #DEF0FA, #93CFEF), linear-gradient(#113955, rgba(#11466B, 1), rgba(#11466B, 0)), radial-gradient(ellipse, #7ABCEA 0%, #6BB2DF 10%, #10779E 90%);
	background-size: auto 29.65em, 100% 13.5em, 100% 18.5em, 100% 100%;
	background-position: center bottom 4.26em, center bottom, center top, center;	
	background-repeat: no-repeat;
}