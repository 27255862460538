@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';
@import '../../../../styles/mixins_backgrounds';
@import '../../../../styles/animations';
@import '../../../../styles/animations-intro';
.AdminDeleteGamePopup {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(#7ABCEA, 0.81);
	@include flex('center', 'center');

	.AdminDeleteGamePopup-content {
		width: 42em;
		background-color: white;
		border-radius: 0.83em;

		.AdminDeleteGamePopup-header {
			position: relative;
			width: 100%;
			height: 4.25em;
			background-color: #11466B;
			border-top-left-radius: 0.83em;
			border-top-right-radius: 0.83em;
			border-top: 0.25em solid white;
			border-right: 0.25em solid white;
			border-left: 0.25em solid white;
			padding-left: 2em;
			@include flex('flex-start', 'center');
			.AdminDeleteGamePopup-title {
				color: #ffffff;
				font-size: 1.25em;
				font-weight: 600;
			}
			.AdminDeleteGamePopup-closeBtn {
				position: absolute;
				top: 0;
				right: 0;
				width: 4.25em;
				height: 3.75em;
				background-image: url('../../../../assets/images/facilitator/icon-close.svg');
				background-size: 1.56em auto;
				cursor: pointer;
			}
		}

		.AdminDeleteGamePopup-body {
			padding: 1.5em 5em;
			.AdminDeleteGamePopup-text {
				width: 100%;
				color: #11466B;			
			}
			.AdminDeleteGamePopup-feedback {
				width: 100%;
				height: 2em;
				span {
					font-size: 0.8em;
					color: black;
				}
				&.error span {color: #C13B3B;}
			}
			.AdminDeleteGamePopup-buttons {
				width: 100%;
				@include flex('space-between', 'center');
			}
			
		}
	}
}