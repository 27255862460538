@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/mixins_backgrounds';
@import '../../../styles/animations';
@import '../../../styles/animations-intro';
.AdminHeader {
	position: relative;
	width: 100%;
	height: 12.11em;
	background-image: url('../../../assets/images/facilitator/background-header.jpg');
	background-size: cover;

	.AdminHeader-logo {
		position: absolute;
		top: 0em;
		left: 2em;
		width: 8em;
		text-align: center;
		cursor: pointer;
		img {
			display: block;
			&:nth-of-type(1) {width: 100%;}
			&:nth-of-type(2) {
				width: 4em;
				margin-top: -0.4em;
				margin-left: calc(50% - 2em);
			}
		}
	}

	.AdminHeader-buttons {
		position: absolute;
		top: 0.75em;
		right: 1.5em;
		width: 2.5em;
		text-align: right;
		> div {
			margin-bottom: 1em;
		}
	}

	.AdminHeader-user {
		position: absolute;
		top: 0em;
		right: 6em;
		width: 12.5em;
		height: 5.25em;
		background-color: #10779E;
		border-bottom-left-radius: 0.44em;
		border-bottom-right-radius: 0.44em;
		@include flex('flex-start', 'center');
		@include box-shadow(0, 0.167em, 0.33em, 0, rgba(black, 0.16));
		.AdminHeader-userInfo {
			padding-left: 1.33em;
			> div {
				width: 100%;
				white-space: nowrap;
				overflow: hidden;
				span {
					font-size: 0.75em;
					color: white;
				}
				&:first-of-type span,
				&:last-of-type span {
					font-weight: 600;
				}
			}
		}
	}

	.AdminHeader-menu {
		position: absolute;
		bottom: 0;
		left: 2em;
		width: 100em;
		height: 2.44em;
		@include flex('space-between', 'flex-end');
		.AdminHeader-menuItem {
			width: 18em;
			height: 2.44em;
			color: #10779E;
			background-color: #A2D0F0;
			border-top-left-radius: 0.83em;
			border-top-right-radius: 0.83em;
			padding-bottom: 0.15em;
			cursor: pointer;
			@include flex('center', 'flex-end');
			span {
				font-size: 1.39em;
				font-weight: bold;
			}
			&.selected {
				color: #10779E;
				background-color: white;
			}
		}
	}
}

// @media (orientation: portrait) and (min-aspect-ratio: 375/667) {
//   .FacilitatorOverview {
//     min-height: calc(177.5vw);
// 		&.map,
// 		&.splash {
// 			min-height: 100%;
// 		}
//   }
// }