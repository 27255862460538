@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';
@import '../../../../styles/mixins_backgrounds';
@import '../../../../styles/animations';
@import '../../../../styles/animations-intro';
.Carousel {
	position: relative;
	width: 100%;
	height: 100%;
	.Carousel-options {
		position: relative;
		width: 100%;
		height: 6.2em;
		overflow: hidden;
		.Carousel-option {
			position: absolute;
			width: 14.25em;
			min-height: 4.25em;
			max-height: 5.8em;
			color: #11466B;
			border-radius: 1em;
			background-color: #A2D0F0;
			will-change: transform;
			padding: 0.75em 1.75em 0.75em 0.75em;
			touch-action: none;
			@include no-select();
			// span {
			// 	color: #11466B;
			// }
			&.dialogue {
				
				color: #11466B;
				background-color: #F5F5F5;
			}
			.Carousel-selectBtn {
				position: absolute;
				top: 0.25em;
				right: 0.35em;
				width: 1.45em;
				height: 1.45em;
				background-image: url('../../../../assets/images/modules/tasks/dialogue/icon-select-answer.svg');
				background-size: contain;
				cursor: pointer;
				@include no-select();
			}
		}
	}
	.Carousel-navigation {
		width: 100%;
		height: 1em;
		text-align: center;
		margin-top: -0.6em;
		.Carousel-navigationDot {
			display: inline-block;
			width: calc(0.75em + 1px);
			height: calc(0.75em + 1px);
			border-radius: 1em;
			margin: 0 0.350em;
			svg {
				width: 100%;
				fill:rgba(#FFFFFF, 0.35)
			}
			&.selected svg {
				fill: #ffffff;
			}
		}
	}
}