@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/mixins_backgrounds';
@import '../../../styles/animations';
@import '../../../styles/animations-intro';
.Button {
	position: relative;
	width: 100%;
	height: 2.25em;
	border-radius: 0.55em;
	font-family: 'Source Sans Pro', sans-serif;
	font-weight: bold;
	text-align: center;
	text-decoration: none;
	cursor: pointer;
	@include no-select();
	@include opacity(1);
	@include flex('center', 'center');
	&.disabled {
		opacity: 0.4;
		cursor: not-allowed;
		pointer-events: none;
		&:hover,
		&:focus,
		&:active {
			opacity: 0.4;
		}
	}
	&.loading {
		background-image: url('../../../assets/images/icon-loading.svg');
		background-size: auto 80%;
		background-position: center right;
	}

	&.login {
		height: 2.22em;
		color: white;
		background-color: #E8B341;
		border-radius: 1.11em;
		@include box-shadow(0, 0, 0.33em, 0, rgba(black, 0.5));
		span {
			font-size: 0.88em;
			font-weight: 700;
		}
		&:hover {background-color: darken(#E8B341, 3%);}
		&.loading {background-position: right 0.5em center;}
	}

	&.game-logout,
	&.game-logout2,
	&.game-logout3,
	&.reset {
		height: 1.75em;
		border-radius: 0.89em;
		border: 1px solid #10779E;
		span {
			color: #10779E;
			font-size: 0.75em;
			font-family: 'Source Sans Pro', sans-serif;
			font-weight: 600;
		}
	}
	&.game-logout2 {
		border-color: white;
		span {color: white;}
	}
	&.game-logout3,
	&.reset {
		border-color: #53A3CE;
		span {
			font-weight: 700;
			color: #53A3CE;
		}
	}

	&.help {
		display: inline-block;
		width: 2em;
		height: 2em;
		border-radius: 0;
		background-image: url('../../../assets/images/facilitator/icon-help.svg');
	}

	&.next {
		width: 5em;
		height: 5em;
		border-radius: 5em;
		background-image: url('../../../assets/images/modules/icon-next.svg');
		background-size: 3em auto;
		&.delay {
			@include delayButton();
		}
		&.animate {
			@include opacity(0);
			animation: fadeIn 0.25s ease 0.5s forwards, bounce 0.75s cubic-bezier(0.280, 0.840, 0.420, 1) 0.75s 1 forwards;
			-webkit-animation: fadeIn 0.25s ease 0.5s forwards, bounce 0.75s cubic-bezier(0.280, 0.840, 0.420, 1) 0.75s 1 forwards;
		}
	}
	&.inactive {
		opacity: 0.4;
		&:hover,
		&:focus,
		&:active {
			opacity: 0.4;
		}
	}
	&.back {
		width: 3em;
		height: 3.4em;
		background-image: url('../../../assets/images/modules/icon-back.svg');
		background-size: 2.25em auto;
		background-repeat: no-repeat;
		background-position: top 0.2em center;
		@include flex('center', 'flex-end');
		span {
			color: #11466B;
			font-size: 0.67em;
			text-transform: uppercase;
		}
	}

	&.island {
		width: 3em;
		height: 3em;
		background-image: url('../../../assets/images/modules/icon-island.svg');
		background-size: 2.55em auto;
		background-repeat: no-repeat;
		background-position: center center;
	}

	&.intro {
		width: 8.85em;
		border-radius: 1em;
		color: white;
		background-color: #E8B341;
		span {font-size: 0.89em;}
		&:hover,
		&:focus,
		&:active {
			background-color: darken(#E8B341, 5%);
		}
		&.dark {
			background-color: #D29241;
			&:hover,
			&:focus,
			&:active {
				background-color: darken(#D29241, 5%);
			}
		}
	}

	&.download {
		width: 8.85em;
		color: white;
		background-color: #E8B341;
		span {
			font-size: 1.11em;
			font-weight: 700;
		}
		&:hover,
		&:focus,
		&:active {
			background-color: darken(#E8B341, 5%);
		}
		&.dark {
			background-color: #D29241;
			&:hover,
			&:focus,
			&:active {
				background-color: darken(#D29241, 5%);
			}
		}
	}
	&.launch {
		width: 100%;
		background-image: url('../../../assets/images/facilitator/icon-rocket.svg');
		background-position: left 0.4em center;
		background-size: auto 80%;
		padding-left: 0.8em;
		&.loading {
			&::after {
				content: '';
				position: absolute;
				right: 0;
				top: 0;
				height: 100%;
				width: 2.25em;
				background-image: url('../../../assets/images/icon-loading.svg');
				background-size: auto 80%;
				background-position: center right;
			}

		}
	}

	&.yellow {
		color: white;
		background-color: #E8B341;
		border-radius: 1.11em;
		@include box-shadow(0, 0, 0.33em, 0, rgba(black, 0.5));
		span {
			font-size: 0.88em;
			font-weight: 700;
		}
		&:hover,
		&:focus,
		&:active {background-color: darken(#E8B341, 3%);}
	}

	&.blue {
		color: white;
		background-color: #10779E;	
		border-radius: 1em;
		&:hover,
		&:focus,
		&:active {
			background-color: darken(#10779E, 5%);
		}
	}

	&.blueDark {
		color: white;
		height: 100%;
		width: 100%;
		background-color: $blue-dark2;
		border-radius: 2em;
		&:hover,
		&:focus,
		&:active {
			background-color: darken($blue-dark2, 5%);
		}
		span {
			font-size: 0.8em;
			font-weight: bold;
		}
	}

	&.finish {
		width: 6em;
	}

	&.done {
		width: 8em;
		&.small {
			width: 4.5em;
		}
	}

	&.save-avatar,
	&.ok {
		width: 4.5em;
		height: 2em;
	}

	&.popup {
		margin-top: 1em;
	}

	&.try-again {
		margin-top: 1em;
	}

	/* Facilitator / admin only */
	&.logout {
		display: inline-block;
		width: 2.5em;
		height: auto;
		padding-top: 1.5em;
		color: white;
		background-image: url('../../../assets/images/facilitator/icon-logout.svg');
		background-size: 2em auto;
		background-position: top right;
		border-radius: 0;
		text-align: right;
		span {font-size: 0.6em;}
	}

	&.selectSection {
		width: 100%;
		height: 100%;
		border-radius: 0.56em;
		span {
			font-size: 1.4em;
			font-weight: 600;
		}
	}

	&.facilitatorBack {
		width: 8em;
		color: #7C7C7C;
		background-color: #EDEDED;
		background-image: url('../../../assets/images/facilitator/icon-arrow-left.svg');
		background-size: 0.6em auto;
		background-position: left 1.5em center;
		margin-right: 1.2em;
		span {
			font-size: 1.11em;
			font-weight: 700;
		}
		&:hover,
		&:focus,
		&:active {
			background-color: darken(#EDEDED, 5%);
		}
	}

	&.addFacilitator,
	&.addGame {
		width: 100%;
		height: 100%;
		color: white;
		background-color: #E6B155;
		padding-right: 2.11em;
		@include flex('flex-end', 'center');
		@include box-shadow(0, 0, 0, 0, transparent);
		span {
			font-family: 'Roboto', sans-serif;
			font-size: 1.11em;
			font-weight: bold;
		}
		&:hover,
		&:focus,
		&:active {
			background-color: darken(#E6B155, 5%);
			@include box-shadow(0, 0, 0, 0, transparent);
		}
	}

	&.addGame,
	&.addFacilitator {
		padding: 0;
		@include flex('center', 'center');
	}
	&.addGame {
		background-color: #10779E;
		padding: 0.5em;
		&:hover,
		&:focus,
		&:active {
			background-color: darken(#10779E, 5%);
			@include box-shadow(0, 0, 0, 0, transparent);
		}
	}
	&.removeUser,
	&.removeFacilitator {
		width: 1.89em;
		height: 1.39em;
		color: #525252;
		background-color: #F8937B;
		margin-top: -0.45em;
		@include box-shadow(0, 0.45em, 0, 0, #C13B3B);
		&:hover,
		&:focus,
		&:active {
			background-color: darken(#F8937B, 5%);
			@include box-shadow(0, 0.45em, 0, 0, darken(#C13B3B, 5%));
		}
	}
	&.editUser {
		width: 1.5em;
		height: 1.5em;
		color: #525252;
		background-color: #E6B155;
		margin-top: -0.45em;
		@include box-shadow(0, 0.25em, 0, 0, #D19141);
		border-radius: 50%;
		span {
			margin-top: -0.225em;
			font-size: 2em;
		}
		&:hover,
		&:focus,
		&:active {
			background-color: darken(#E6B155, 5%);
			@include box-shadow(0, 0.25em, 0, 0, darken(#D19141, 5%));
		}
	}
	&.removeFacilitator {
		width: 100%;
		height: 100%;
		margin: 0;
		@include box-shadow(0, 0.15em, 0, 0, #C13B3B);
		&:hover,
		&:focus,
		&:active {
			@include box-shadow(0, 0.15em, 0, 0, darken(#C13B3B, 5%));
		}
		span {
			font-size: 0.89em;
		}
	}
	&.deleteGame {
		width: 100%;
		height: 100%;
		background-color: #F8937B;
		span {
			color: white;
			font-size: 1.39em;
			font-weight: 600;
		}
		&:hover,
		&:focus,
		&:active {
			background-color: darken(#F8937B, 5%);
		}
	}
	&.adminPopup {
		display: inline-block;
		vertical-align: middle;
		min-width: 9em;
		width: auto;
		height: 2.75em;
		margin: 0 2em;
		line-height: 2.75em;
		border-radius: 0.56em;
		span {
			font-size: 1.4em;
			font-weight: 600;
		}
		&.wide {
			min-width: 11em;
		}
	}

	&.reportUser {
		width: 1.11em;
		height: 1.11em;
		border: 0.11em solid #B0292B;
		border-radius: 50%;
		span {
			font-size: 1em;
			color:#B0292B;
		}
		&:hover,
		&:focus,
		&:active {
			border: 0.11em solid darken(#B0292B, 10%);
			span {
				color:darken(#B0292B, 10%);
			}
		}
	}
}