@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/mixins_backgrounds';
@import '../../../styles/animations';
@import '../../../styles/animations-intro';
.FacilitatorPlayers {
	position: relative;
	min-height: 100%;

	.FacilitatorPlayers-cell {
		width: 20%;
		height: 100%;
		padding: 0 1.5em;
		@include flex('flex-start', 'center');
	}
	.FacilitatorPlayers-header {
		width: 100%;
		height: 2.33em;
		margin-top: 1.5em;
		color: white;
		background-color: #11466B;
		@include flex('space-between', 'center');
		.FacilitatorPlayers-cell {
			span {
				display: inline-block;
				vertical-align: middle;
				position: relative;
				font-size: 1.39em;
				font-weight: 600;

			}
			&.name,
			&.email,
			&.completion,
			&.lastlogin {
				cursor: pointer;

			}
			&.sortedBy {
				span::after {
					content: '';
					display: inline-block;
					vertical-align: middle;
					width: 1em;
					height: 1em;
					background-size: 0.6em auto;
					background-image: url('../../../assets/images/facilitator/icon-sort-desc.svg');
				}
				&.ASC span::after {background-image: url('../../../assets/images/facilitator/icon-sort-asc.svg');}
			}
		}
	}
	.FacilitatorPlayers-body {
		width: 100%;
		.FacilitatorPlayers-row {
			width: 100%;
			height: 2.5em;
			color: #525252;
			cursor: pointer;
			&:hover {background-color: darken(#ffffff, 5%);}
			&:nth-child(odd) {
				background-color: #EDEDED;
				&:hover {background-color: darken(#EDEDED, 3%);}
			}
			@include flex('space-between', 'center');
			.FacilitatorPlayers-cell {
				&.name {
					span {
						font-weight: 600;
						text-decoration: underline;
					}
				} 
				&.seen {
					@include opacity(0.5);
				}
			}
		}
	}
}