@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';
@import '../../../../styles/mixins_backgrounds';
@import '../../../../styles/animations';
@import '../../../../styles/animations-intro';
.OrderDndPreview {	
	.OrderDndPreview-item {
		position: relative;
		width: 15.5em;
		height: 2.25em;
		line-height: 1.1em;
		border-radius: 0.55em;
		font-family: 'Source Sans Pro', sans-serif;
		font-weight: bold;
		text-align: center;
		padding: 0.75em 2em 0.5em 2em;
		cursor: pointer;
		transform-origin: left 0.5em center;
		@include blue-button();
		@include flex('center', 'center');
		@include no-select();
		@include rotate(-7deg);
		span {
			font-size: 0.85em;
		}		

		&::after {
			content: '';
			position: absolute;
			right: 0;
			top: 0;
			width: 6em;
			height: 100%;
			background-image: url('../../../../assets/images/modules/icon-move2.svg');
			background-position: left 4.5em center;
			background-size: auto 1.25em;
		}
	}
	&.orientation-1-order-of-important,
	&.deli-2-order-deep-clean-display-case,
	&.floral-2-multiple-choice-flower-care-water-buckets,
	&.meat-1-order-fix-hot-case,
	&.produce-2-order-receiving-bananas,
	&.produce-2-order-vegetables-crisping,
	&.produce-2-order-lettuce-crisping,
	&.seafood-2-order-reset-service-case,
	&.seafood-2-order-purging-lobsters,
	&.daily-guidelines-1-order-culling {
		.OrderDndPreview-item {
			height: 3em;
		}
	}
}