@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/mixins_backgrounds';
@import '../../../styles/animations';
@import '../../../styles/animations-intro';
.AreaMap {
	position: relative;
	height: 100%;



	.AreaMap-mapWrap {
		position: relative;
		width: 100%;
		height: 100%;
		overflow-x: hidden;
		overflow-y: auto;
		z-index: 1;
		@include map-background();
		&.smoothScroll {
			scroll-behavior: smooth;
		}
		.AreaMap-mapContent {
			position: relative;
			width: 100%;
			height: 38em;
			min-height: 100%;
			@include map-background();
			&.modules-2 {
				height: 45em;
			}
			&.modules-3 {
				height: 52em;
			}
			&.modules-4 {
				height: 60em;
			}
			&.modules-5 {
				height: 70em;
			}
			&.modules-6 {
				height: 90em;
			}
			&.modules-8 {
				height: 83em;
			}
			.AreaMap-waves {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				z-index: 1;
				&.core {
					background-image: 
					url('../../../assets/images/areas/wave.svg'),
					url('../../../assets/images/areas/wave.svg'),
					url('../../../assets/images/areas/wave.svg'),
					url('../../../assets/images/areas/wave.svg'),
					url('../../../assets/images/areas/wave.svg'),
					url('../../../assets/images/map/puddle-1.svg'),
					url('../../../assets/images/map/puddle-2.svg'),
					url('../../../assets/images/map/puddle-2.svg');
					background-size: 
						5.03em auto,
						5.03em auto,
						5.03em auto,
						5.03em auto,
						5.03em auto,
						7.15em auto,
						7.15em auto,
						7.15em auto;
					background-position: 
						bottom 13.19em left calc(50% + 8em),
						bottom 18.3em left calc(50% - 7.5em),
						bottom 21em left calc(50% + 4em),
						bottom 28em left calc(50% + 4.5em),
						bottom 31.25em left calc(50% - 3em),
						bottom 12em left calc(50% - 11.2em),
						bottom 17em left calc(50% + 10.75em),
						bottom 42em left calc(50% + 10.75em);
					background-repeat:
						no-repeat, no-repeat, no-repeat, no-repeat, no-repeat, 
						no-repeat, no-repeat, no-repeat;
				}

				&.sideQuest {
					background-image: 
						url('../../../assets/images/map/puddle-1.svg'), url('../../../assets/images/map/puddle-2.svg'),
						url('../../../assets/images/map/puddle-1.svg'), url('../../../assets/images/map/puddle-2.svg'),
						url('../../../assets/images/map/puddle-1.svg'), url('../../../assets/images/map/puddle-2.svg'),
						url('../../../assets/images/map/puddle-1.svg'), url('../../../assets/images/map/puddle-2.svg'),
						url('../../../assets/images/areas/wave.svg'), url('../../../assets/images/areas/wave.svg'),
						url('../../../assets/images/areas/wave.svg'), url('../../../assets/images/areas/wave.svg'),
						url('../../../assets/images/areas/wave.svg'), url('../../../assets/images/areas/wave.svg'),
						url('../../../assets/images/areas/wave.svg'), url('../../../assets/images/areas/wave.svg'),
						url('../../../assets/images/areas/wave.svg')
					;
					background-size: 
						7.15em auto, 7.15em auto, 7.15em auto, 7.15em auto, 7.15em auto, 7.15em auto, 7.15em auto, 7.15em auto,
						5.03em auto, 5.03em auto, 5.03em auto, 5.03em auto, 5.03em auto, 5.03em auto, 5.03em auto, 5.03em auto, 5.03em auto
					;
					background-position: 
						bottom 9.57em left calc(50% - 11em), bottom 13.56em left calc(50% + 11em),
						bottom 28.37em left calc(50% - 11em), bottom 33.29em left calc(50% + 11em),
						bottom 43em left calc(50% - 11em), bottom 47.59em left calc(50% + 11em),
						bottom 59.48em left calc(50% - 11em), bottom 62.57em left calc(50% + 11em),	
						bottom 9.06em left calc(50% + 7em), bottom 18.96em left calc(50% + 4.5em),
						bottom 24.62em left calc(50% - 7em), bottom 35.32em left calc(50% + 4em), 
						bottom 41.74em left calc(50% - 5em), bottom 49.68em left calc(50% + 4em), 
						bottom 57.19em left calc(50% - 5em), bottom 69em left calc(50% + 8em),
						bottom 72.5em left calc(50% - 4em)
					;
					background-repeat: no-repeat;
				}
			}
			.AreaMap-module {
				position: absolute;

				.AreaMap-constructionSign {
					position: absolute;
					background-image: url('../../../assets/images/map/under-construction.svg');
					width: 9em;
					height: 6em;
					background-size: contain;
					@include opacity(1);
					z-index: 1;
					bottom: 2em;
					left: calc(50% - 9em / 2);
				}

				.AreaMap-items {
					position: absolute;
					width: 100%;
					height: 100%;
					top: 0;
					left: 0;
					&.bagger-1 {
						background-size: 6.68em auto;
						background-position: left 2.25em bottom 5em;
						background-image: url('../../../assets/images/map/module-bagger-1.svg');
					}
					&.bakery-1 {
						background-size: 9.12em auto;
						background-position: left 2em bottom 1.5em;
						background-image: url('../../../assets/images/map/module-bakery-1.svg');
					}
					&.bakery-2 {
						background-size: 4.2em auto;
						background-position: left 7.5em bottom 1.75em;
						background-image: url('../../../assets/images/map/module-bakery-2.svg');
					}
					&.cashier-1 {
						background-size: 2.28em auto;
						background-position: left 2.5em bottom 5em;
						background-image: url('../../../assets/images/map/module-cashier-1.svg');
					}
					&.center-store-1 {
						background-size: 1.12em auto;
						background-position: left 3.2em bottom 5em;
						background-image: url('../../../assets/images/map/module-center-store-1.svg');
					}
					&.customer-service-1 {
						background-size: 4.48em auto;
						background-position: left 1.5em bottom 2em;
						background-image: url('../../../assets/images/map/module-customer-service-1.svg');
					}
					&.customer-service-2 {
						background-size: 5.36em auto;
						background-position: left 7em bottom 1.8em;
						background-image: url('../../../assets/images/map/module-customer-service-2.svg');
					}
					&.deli-1 {
						background-size: 8.64em auto;
						background-position: left 3.2em bottom 3.5em;
						background-image: url('../../../assets/images/map/module-deli-1.svg');
					}
					&.deli-2 {
						background-size: 9.14em auto;
						background-position: left 1.75em bottom 3.5em;
						background-image: url('../../../assets/images/map/module-deli-2.svg');
					}
					&.orientation-1 {
						background-size: 6.61em auto;
						background-position: left 3em bottom 3.2em;
						background-image: url('../../../assets/images/map/module-orientation-1.svg');
					}
					&.floral-1 {
						background-size: 5.14em auto;
						background-position: left 3em bottom 5.5em;
						background-image: url('../../../assets/images/map/module-floral-1.svg');
					}
					&.floral-2 {
						background-size: 8.87em auto;
						background-position: left 2.5em bottom 2.8em;
						background-image: url('../../../assets/images/map/module-floral-2.svg');
					}
					&.meat-1 {
						background-size: 1.7em auto;
						background-position: left 9.25em bottom 4em;
						background-image: url('../../../assets/images/map/module-meat-1.svg');
					}
					&.meat-2 {
						background-size: 3.54em auto;
						background-position: left 8.1em bottom 3em;
						background-image: url('../../../assets/images/map/module-meat-2.svg');
					}
					&.produce-1 {
						background-size: 2.83em auto;
						background-position: left 2.9em bottom 1.69em;
						background-image: url('../../../assets/images/map/module-produce-1.svg');
					}
					&.produce-2 {
						background-size: 8.47em auto;
						background-position: left 2.89em bottom 0.96em;
						background-image: url('../../../assets/images/map/module-produce-2.svg');
					}
					&.seafood-1 {
						background-size: 11.1em auto;
						background-position: left 2.5em bottom 0.3em;
						background-image: url('../../../assets/images/map/module-seafood-1.svg');
					}
					&.seafood-2 {
						background-size: 8.53em auto;
						background-position: left 2.5em bottom 0em;
						background-image: url('../../../assets/images/map/module-seafood-2.svg');
					}
				}

				.AreaMap-moduleShadow {
					position: absolute;
					width: calc(3.77em + 1.3em);
					left: calc(50% - 1.75em - (0.5 * 1.3em));
					background-position: left bottom calc(3.2em - 0.9em);
					height: 100%;
					background-image: url('../../../assets/images/map/module-status-shadow.svg');
					@include scale(1);
					@include transition(0.25s, 'all', 'ease-out');
					@include transition-delay(0.25s);
					&.current {
						@include scale(1.05);
					}
					&.module-2 {
						@include translate(-0.4em, -0.5em);
					}
				}
	
				.AreaMap-moduleStatus {
					position: absolute;
					width: 3.77em;
					bottom: 3.2em;
					left: calc(50% - 1.75em);
					@include flex('center', 'center', 'column');
					cursor: pointer;
					.AreaMap-moduleLabel {
						position: absolute;
						width: 100%;
						height: 100%;
						top: 1.1em;
						left: 0;
						text-align: center;
						span {
							font-size: 1.33em;
							font-weight: 900;
							color: white;
						}
					}
					
					.AreaMap-moduleTitleWrapper {
						position: absolute;
						z-index: 2;
						width: 11em;
						left: calc(50% - 11em / 2);
						bottom: -0.75em;
						@include flex('center');
						.AreaMap-moduleTitle {
							position: relative;
							padding-left: 0.6em;
							padding-right: 0.6em;
							padding-bottom: 0.2em;
							min-width: 4.44em;
							white-space: nowrap;
							border-radius: 1em;
							background-color: #E8B341;
							span {
								font-size: 0.78em;
							}
						}
					}
					svg {
						display: block;
						width: 100%;
						height: auto;
						
					}
					&.locked {
						cursor: not-allowed;
					}

					&.module-2 {
						@include translate(-0.4em, -0.5em);
					}
				}

				&::after {
					content: '';
					position: absolute;		
				}
				&.bridge-none::after {display:none;}

				&.underConstruction {
					pointer-events: none;
				}

				&:not(.isSideQuest) {
					&.position-1 {
						bottom: 21em;
						left: calc(50% - 12.9em);
						width: 14em;
						height: 14em;
						background-image: url('../../../assets/images/map/module-1.svg');
						z-index: 8;
						&::after {
							left: 6.5em;
							top: 11.3em;
							width: 8.19em;
							height: 11.5em;
							background-image: url('../../../assets/images/map/bridge-1.svg');
						}
					}
					&.position-2 {
						bottom: 30.66em;
						left: calc(50% - 1em);
						width: 14em;
						height: 13.19em;
						background-image: url('../../../assets/images/map/module-2.svg');
						z-index: 6;
						&::after {
							left: -3.4em;
							bottom: -4.6em;
							width: 9.09em;
							height: 8.86em;
							background-image: url('../../../assets/images/map/bridge-2.svg');
						}
						&.bridge-half::after {
							width: 4.51em;
							height: 4.26em;
							background-image: url('../../../assets/images/map/bridge-2-half.svg');
						}
					}
					&.position-3 {
						bottom: 40.56em;
						left: calc(50% - 12.7em);
						width: 15.53em;
						height: 14.28em;
						background-image: url('../../../assets/images/map/module-3.svg');
						z-index: 4;
						&::after {
							left: -3.4em;
							bottom: -4.6em;
							width: 9.09em;
							height: 8.86em;
						}
						&.bridge-half::after {
							width: 4.51em;
							height: 4.26em;
							background-image: url('../../../assets/images/map/bridge-3-half.svg');
						}
					}
				}

				&.isSideQuest {
					&.position-1 {
						bottom: 4.5em;
						left: calc(50% - 6.25em);
						width: 11.78em;
						height: 11.08em;
						background-image: url('../../../assets/images/map/module-sq-safety.svg');
						z-index: 18;
						&::after {
							left: -3.25em;
							bottom: 0.82em;
							width: 5.35em;
							height: 1.47em;
							background-image: url('../../../assets/images/map/rock-bridge-1.svg');
						}
					}
					&.position-2 {
						bottom: 16.2em;
						left: calc(50% - 10em);
						width: 11.78em;
						height: 10.85em;
						background-image: url('../../../assets/images/map/module-sq-food-safety.svg');
						z-index: 14;
						&::after {
							left: 7em;
							bottom: -5.46em;
							width: 2.17em;
							height: 5.46em;
							background-image: url('../../../assets/images/map/rock-bridge-2.svg');
						}
					}
					&.position-3 {
						bottom: 23.62em;
						left: calc(50% - 0.9em);
						width: 11.78em;
						height: 10.85em;
						background-image: url('../../../assets/images/map/module-sq-daily-guidelines.svg');
						z-index: 16;
						&::after {
							left: 0.4em;
							bottom: -3.7em;
							width: 2.5em;
							height: 5.42em;
							background-image: url('../../../assets/images/map/rock-bridge-3.svg');
						}
					}
					&.position-4 {
						bottom: 32.57em;
						left: calc(50% - 11.3em);
						width: 11.78em;
						height: 10.52em;
						background-image: url('../../../assets/images/map/module-sq-inventory-management.svg');
						z-index: 10;
						&::after {
							left: 8em;
							bottom: -2.9em;
							width: 6.67em;
							height: 3.68em;
							background-image: url('../../../assets/images/map/rock-bridge-4.svg');
						}
					}
					&.position-5 {
						bottom: 38.5em;
						left: calc(50% - 0.9em);
						width: 11.78em;
						height: 11em;
						background-image: url('../../../assets/images/map/module-sq-cooler-freezer.svg');
						z-index: 12;
						&::after {
							left: -1em;
							bottom: -2.1em;
							width: 2.5em;
							height: 5.42em;
							background-image: url('../../../assets/images/map/rock-bridge-3.svg');
						}
					}
					&.position-6 {
						bottom: 47.44em;
						left: calc(50% - 11.3em);
						width: 11.78em;
						height: 11em;
						background-image: url('../../../assets/images/map/module-sq-cleaning.svg');
						z-index: 6;
						&::after {
							left: 8em;
							bottom: -2.9em;
							width: 6.67em;
							height: 3.68em;
							background-image: url('../../../assets/images/map/rock-bridge-4.svg');
						}
					}
					&.position-7 {
						bottom: 53.11em;
						left: calc(50% - 2em);
						width: 12.88em;
						height: 11.25em;
						background-image: url('../../../assets/images/map/module-sq-5s.svg');
						z-index: 7;
						&::after {
							left: 0.7em;
							bottom: -2.5em;
							width: 2.5em;
							height: 5.42em;
							background-image: url('../../../assets/images/map/rock-bridge-3.svg');
						}
					}
					&.position-8 {
						bottom: 63.1em;
						left: calc(50% - 6.8em);
						width: 11.78em;
						height: 11.08em;
						background-image: url('../../../assets/images/map/module-sq-shrink.svg');
						z-index: 4;
						&::after {
							left: 5.8em;
							bottom: -3.9em;
							width: 4.1em;
							height: 4.57em;
							background-image: url('../../../assets/images/map/rock-bridge-7.svg');
						}
						&::before {
							content: '';
							position: absolute;
							left: -3em;
							top: 3.9em;
							width: 5.35em;
							height: 1.69em;
							background-image: url('../../../assets/images/map/rock-bridge-6.svg');
						}
					}	
				}

				
			}	
			.AreaMap-sideQuest {
				position: absolute;
				&::after {
					content: '';
					position: absolute;		
				}
				&.area,
				&.module {
					width: 5em;
					height: 5em;
					background-image: url('../../../assets/images/map/raft.svg');
					background-size: 100% auto;
					background-position: bottom center;
				}
				&.loot-box {
					width: 4.58em;
					height: 3.1em;
					background-image: url('../../../assets/images/map/loot-box/loot-box-in-water-closed.svg');
					background-size: 4.12em auto;
					background-position: bottom right;
					&.unlocked {
						background-image: url('../../../assets/images/map/loot-box/loot-box-in-water-closed-glow.svg');
					}
					&.empty {
						background-size: 100% auto;
						background-image: url('../../../assets/images/map/loot-box/loot-box-in-water-empty.svg');
					}
				}
				&.position-1-1 { // First island, to the right
					bottom: 24em;
					left: calc(50% + 5em);
					z-index: 9;
					.AreaMap-sideQuestStones {
						left: -5em;
						bottom: 0em;
					}
					&:not(.locked) {
						.AreaMap-sideQuestStones {
							&.type-1 svg {
								#Group_71564,
								#Group_71565,
								#Group_71566 {
									@include opacity(1);
								}	
							}
						}
					}
				}
				&.position-2-1,  // Second island, to the left
				&.position-8-1 {
					bottom: 37em;
					left: calc(50% - 10em);
					z-index: 7;
					.AreaMap-sideQuestStones {
						left: 0.5em;
						bottom: -2em;
					}
					&:not(.locked) {
						.AreaMap-sideQuestStones {
							&.type-2 svg {
								#Group_71563,
								#Group_71562,
								#Group_71701,
								#Group_71702,
								#Group_71703 {
									@include opacity(1);
								}
							}
						}
					}
				}
				&.position-8-1 { // Last sidequest
					bottom: 74.5em;
					left: calc(50% + 0.3em);
					z-index: 5;
					.AreaMap-sideQuestStones {
						&.type-3 {
							bottom: -5.25em;
							left: 2em;
							width: 2.5em;
							height: 5.42em;
						}
					}
				}
				.AreaMap-sideQuestStones {
					position: absolute;
					svg {
						width: 100%;
						height: auto;
					}
					&.type-1 { // 3 stones, usually position-1-1
						width: 5.35em;
						height: 1.5em;
						svg {
							#Group_71564,
							#Group_71565,
							#Group_71566 {
								@include opacity(0);
							}
						}
						&.stones-1 svg #Group_71564 {@include opacity(1);}
						&.stones-2 svg {
							#Group_71564,
							#Group_71565 {
								@include opacity(1);
							}
						}
						&.stones-3 svg {
							#Group_71564,
							#Group_71565,
							#Group_71566 {
								@include opacity(1);
							}
						}
					}
					&.type-2 { // 5 stones, usually position-2-1
						width: 8.94em;
						height: 1.88em;
						svg {
							#Group_71563,
							#Group_71562,
							#Group_71701,
							#Group_71702,
							#Group_71703 {
								@include opacity(0);
							}
						}
						&.stones-1 svg #Group_71563 {@include opacity(1);}
						&.stones-2 svg {
							#Group_71563,
							#Group_71562 {
								@include opacity(1);
							}
						}
						&.stones-3 svg {
							#Group_71563,
							#Group_71562,
							#Group_71701 {
								@include opacity(1);
							}
						}
						&.stones-4 svg {
							#Group_71563,
							#Group_71562,
							#Group_71701,
							#Group_71702 {
								@include opacity(1);
							}
						}
						&.stones-5 svg {
							#Group_71563,
							#Group_71562,
							#Group_71701,
							#Group_71702,
							#Group_71703 {
								@include opacity(1);
							}
						}
					}	
				}
				.AreaMap-sideQuestStatus {
					position: absolute;
					width: 2.69em;
					bottom: 0.75em;
					left: calc(50% - (0.5 * 2.69em));
					&.area {
						height: 2.5em;
					}

					cursor: pointer;
					.AreaMap-sideQuestLabel {
						position: absolute;
						width: 100%;
						height: 100%;
						top: 1.9em;
						left: -0.6em;
						text-align: center;
						white-space: nowrap;
						span {
							font-size: 0.7em;
							font-weight: 700;
							color: white;
						}
					}
					svg {
						width: 100%;
						height: auto;
						#Path_113042-2_-_Outline-4,
						#Path_113042-2_-_Outline-4,
						#Path_113042-2_-_Outline-6 {
							@include opacity(0.5);
						}
					}
				}
				.AreaMap-lootBoxBtn {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					cursor: pointer;

					&.locked {cursor: not-allowed;}
				}
			}
			.AreaMap-mainIsland {
				position: absolute;
				bottom: 4.5em;
				left: calc(50% - (0.5 * 14.67em));
				width: 14.67em;
				height: 16.5em;
				z-index: 10;
				&.bagger {
					height: 18.5em;
					bottom: 4.1em;
				}
				&.bakery {
					height: 19em;
					bottom: 4em;
				}
				&.cashier {
					height: 18.25em;
					bottom: 4em;
				}
				&.center-store {
					height: 18.5em;
				}
				&.customer-service {
					height: 18.75em;
					bottom: 4em;
				}
				&.deli {
					height: 18.25em;
				}
				&.orientation {
					height: 19.25em;
				}
				&.floral {
					height: 19em;
				}
				&.meat {
					height: 18em;
				}
				&.seafood {
					height: 19.5em;
					bottom: 4.2em;
				}
			}
			.AreaMap-avatar {
				position: absolute;
				bottom: 11.75em;
				left: calc(50% - 1.75em);
				width: 2.5em;
				height: auto;
				z-index: 20;

				&:not(.isSidequest) {
					&.position-1 {
						@include translate(-2.75em, -11.75em);
					}
					&.position-2 {
						@include translate(3.5em, -21em);
					}

					&.move-0-1 {
						@include move-avatar-from-to(0.5s, 0s, 0, 0, -3.5em, -11em);
					}
					&.move-0-2 {
						@include move-avatar-from-to(0.5s, 0s, 0, 0, 3.5em, -21em);
					}
					&.move-1-2 {
						@include move-avatar-from-to(0.5s, 0s, -3.5em, -11em, 3.5em, -21em);
					}
					&.move-2-1 {
						@include move-avatar-from-to(0.5s, 0s, 3.5em, -21em, -3.5em, -11em);
					}
				}

				&.isSidequest {
					&.position-0,
					&.position-1 {
						@include translate(3.1em, 5em);
					}
					&.position-2 {
						@include translate(-0.1em, -6.8em);
					}
					&.position-3 {
						@include translate(8.5em, -14.9em);
					}
					&.position-4 {
						@include translate(-1.75em, -23.5em);
					}
					&.position-5 {
						@include translate(2.8em, -29.6em);
					}
					&.position-6 {
						@include translate(-7.5em, -38em);
					}
					&.position-7 {
						@include translate(8em, -43.8em);
					}
					&.position-8 {
						@include translate(-3em, -54.4em);
					}
					&.move-1-2,
					&.move-0-2 {
						@include move-avatar-from-to(0.5s, 0s, 3.1em, 5em, -0.1em, -6.8em);
					}
					&.move-1-3,
					&.move-0-3 {
						@include move-avatar-from-to(0.5s, 0s, 3.1em, 5em, 8.5em, -14.9em);
					}
					&.move-1-4,
					&.move-0-4 {
						@include move-avatar-from-to(0.5s, 0s, 3.1em, 5em, -1.75em, -23.5em);
					}
					&.move-1-5,
					&.move-0-5 {
						@include move-avatar-from-to(0.5s, 0s, 3.1em, 5em, 2.8em, -29.6em);
					}
					&.move-1-6,
					&.move-0-6 {
						@include move-avatar-from-to(0.5s, 0s, 3.1em, 5em, -7.5em, -38em);
					}
					&.move-1-7,
					&.move-0-7 {
						@include move-avatar-from-to(0.5s, 0s, 3.1em, 5em, 8em, -43.8em);
					}
					&.move-1-8,
					&.move-0-8 {
						@include move-avatar-from-to(0.5s, 0s, 3.1em, 5em, -3em, -54.4em);
					}
					&.move-2-1 {
						@include move-avatar-from-to(0.5s, 0s, -0.1em, -6.8em, 3.1em, 5em);
					}
					&.move-2-3 {
						@include move-avatar-from-to(0.5s, 0s, -0.1em, -6.8em, 8.5em, -14.9em);
					}
					&.move-2-4 {
						@include move-avatar-from-to(0.5s, 0s, -0.1em, -6.8em, -1.75em, -23.5em);
					}
					&.move-2-5 {
						@include move-avatar-from-to(0.5s, 0s, -0.1em, -6.8em, 2.8em, -29.6em);
					}
					&.move-2-6 {
						@include move-avatar-from-to(0.5s, 0s, -0.1em, -6.8em, -7.5em, -38em);
					}
					&.move-2-7 {
						@include move-avatar-from-to(0.5s, 0s, -0.1em, -6.8em, 8em, -43.8em);
					}
					&.move-2-8 {
						@include move-avatar-from-to(0.5s, 0s, -0.1em, -6.8em, -3em, -54.4em);
					}
					&.move-3-1 {
						@include move-avatar-from-to(0.5s, 0s, 8.5em, -14.9em, 3.1em, 5em);
					}
					&.move-3-2 {
						@include move-avatar-from-to(0.5s, 0s, 8.5em, -14.9em, -0.1em, -6.8em);
					}
					&.move-3-4 {
						@include move-avatar-from-to(0.5s, 0s, 8.5em, -14.9em, -1.75em, -23.5em);
					}
					&.move-3-5 {
						@include move-avatar-from-to(0.5s, 0s, 8.5em, -14.9em, 2.8em, -29.6em);
					}
					&.move-3-6 {
						@include move-avatar-from-to(0.5s, 0s, 8.5em, -14.9em, -2.5em, -60em);
					}
					&.move-3-7 {
						@include move-avatar-from-to(0.5s, 0s, 8.5em, -14.9em, 8em, -43.8em);
					}
					&.move-3-8 {
						@include move-avatar-from-to(0.5s, 0s, 8.5em, -14.9em, -3em, -54.4em);
					}
					&.move-4-1 {
						@include move-avatar-from-to(0.5s, 0s, -1.75em, -23.5em, 3.1em, 5em);
					}
					&.move-4-2 {
						@include move-avatar-from-to(0.5s, 0s, -1.75em, -23.5em, -0.1em, -6.8em);
					}
					&.move-4-3 {
						@include move-avatar-from-to(0.5s, 0s, -1.75em, -23.5em, 8.5em, -14.9em);
					}
					&.move-4-5 {
						@include move-avatar-from-to(0.5s, 0s, -1.75em, -23.5em, 2.8em, -29.6em);
					}
					&.move-4-6 {
						@include move-avatar-from-to(0.5s, 0s, -1.75em, -23.5em, -7.5em, -38em);
					}
					&.move-4-7 {
						@include move-avatar-from-to(0.5s, 0s, -1.75em, -23.5em, 8em, -43.8em);
					}
					&.move-4-8 {
						@include move-avatar-from-to(0.5s, 0s, -1.75em, -23.5em, -3em, -54.4em);
					}
					&.move-5-1 {
						@include move-avatar-from-to(0.5s, 0s, 2.8em, -29.6em, 3.1em, 5em);
					}
					&.move-5-2 {
						@include move-avatar-from-to(0.5s, 0s, 2.8em, -29.6em, -0.1em, -6.8em);
					}
					&.move-5-3 {
						@include move-avatar-from-to(0.5s, 0s, 2.8em, -29.6em, 8.5em, -14.9em);
					}
					&.move-5-4 {
						@include move-avatar-from-to(0.5s, 0s, 2.8em, -29.6em, -1.75em, -23.5em);
					}
					&.move-5-6 {
						@include move-avatar-from-to(0.5s, 0s, 2.8em, -29.6em, -7.5em, -38em);
					}
					&.move-5-7 {
						@include move-avatar-from-to(0.5s, 0s, 2.8em, -29.6em, 8em, -43.8em);
					}
					&.move-5-8 {
						@include move-avatar-from-to(0.5s, 0s, 2.8em, -29.6em, -3em, -54.4em);
					}
					&.move-6-1 {
						@include move-avatar-from-to(0.5s, 0s, -7.5em, -38em, 3.1em, 5em);
					}
					&.move-6-2 {
						@include move-avatar-from-to(0.5s, 0s, -7.5em, -38em, -0.1em, -6.8em);
					}
					&.move-6-3 {
						@include move-avatar-from-to(0.5s, 0s, -7.5em, -38em, 8.5em, -14.9em);
					}
					&.move-6-4 {
						@include move-avatar-from-to(0.5s, 0s, -7.5em, -38em, -1.75em, -23.5em);
					}
					&.move-6-5 {
						@include move-avatar-from-to(0.5s, 0s, -7.5em, -38em, 2.8em, -29.6em);
					}
					&.move-6-7 {
						@include move-avatar-from-to(0.5s, 0s, -7.5em, -38em, 8em, -43.8em);
					}
					&.move-6-8 {
						@include move-avatar-from-to(0.5s, 0s, -7.5em, -38em, -3em, -54.4em);
					}
					&.move-7-1 {
						@include move-avatar-from-to(0.5s, 0s, 8em, -43.8em, 3.1em, 5em);
					}
					&.move-7-2 {
						@include move-avatar-from-to(0.5s, 0s, 8em, -43.8em, -0.1em, -6.8em);
					}
					&.move-7-3 {
						@include move-avatar-from-to(0.5s, 0s, 8em, -43.8em, 8.5em, -14.9em);
					}
					&.move-7-4 {
						@include move-avatar-from-to(0.5s, 0s, 8em, -43.8em, -1.75em, -23.5em);
					}
					&.move-7-5 {
						@include move-avatar-from-to(0.5s, 0s, 8em, -43.8em, 2.8em, -29.6em);
					}
					&.move-7-6 {
						@include move-avatar-from-to(0.5s, 0s, 8em, -43.8em, -7.5em, -38em);
					}
					&.move-7-8 {
						@include move-avatar-from-to(0.5s, 0s, 8em, -43.8em, -3em, -54.4em);
					}
					&.move-8-1 {
						@include move-avatar-from-to(0.5s, 0s, -3em, -54.4em, 3.1em, 5em);
					}
					&.move-8-2 {
						@include move-avatar-from-to(0.5s, 0s, -3em, -54.4em, -0.1em, -6.8em);
					}
					&.move-8-3 {
						@include move-avatar-from-to(0.5s, 0s, -3em, -54.4em, 8.5em, -14.9em);
					}
					&.move-8-4 {
						@include move-avatar-from-to(0.5s, 0s, -3em, -54.4em, -1.75em, -23.5em);
					}
					&.move-8-5 {
						@include move-avatar-from-to(0.5s, 0s, -3em, -54.4em, 2.8em, -29.6em);
					}
					&.move-8-6 {
						@include move-avatar-from-to(0.5s, 0s, -3em, -54.4em, -7.5em, -38em);
					}
					&.move-8-7 {
						@include move-avatar-from-to(0.5s, 0s, -3em, -54.4em, 8em, -43.8em);
					}
				}
			}
		}
	}

	.AreaMap-unavailableFeedback {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 12;
		@include flex('center', 'center');
		.AreaMap-unavailableFeedbackContent {
			width: 18em;
			background-color: #F5F5F5;
			border-radius: 1em;
			padding: 1em;
			.AreaMap-unavailableFeedbackText {
				span {
					font-size: 0.89em;
					font-weight: 500;
				}
			}
			.AreaMap-unavailableFeedbackBtn {
				margin: 0.75em auto 0 auto;
				width: 4.44em;
				height: 1.67em;
				background-color: #11466B;
				border-radius: 0.83em;
				cursor: pointer;
				@include flex('center', 'center');
				span {
					font-size: 0.89em;
					font-weight: 700;
					color: white;
				}
			}

		}
	}

	&.landscape {
		overflow: visible;
		.AreaMap-mapWrap {
			@include map-background-landscape();
			.AreaMap-mapContent {
				@include map-background-landscape();
			}
		}
	}
}