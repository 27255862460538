@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';
@import '../../../../styles/mixins_backgrounds';
@import '../../../../styles/animations';
@import '../../../../styles/animations-intro';
.FacilitatorEditCoFacilitatorPopup {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(#7ABCEA, 0.81);
	@include flex('center', 'center');

	.FacilitatorEditCoFacilitatorPopup-content {
		width: 40.16em;
		background-color: white;
		border-radius: 0.83em;

		.FacilitatorEditCoFacilitatorPopup-header {
			position: relative;
			width: 100%;
			min-height: 3.61em;
			background-color: #11466B;
			border-top-left-radius: 0.83em;
			border-top-right-radius: 0.83em;
			border: 0.25em solid white;
			padding-left: 1em;
			@include flex('center', 'center');
			.FacilitatorEditCoFacilitatorPopup-closeBtn {
				position: absolute;
				top: 0;
				right: 0;
				width: 2.33em;
				height: 2.33em;
				background-image: url('../../../../assets/images/facilitator/icon-close.svg');
				background-size: 1.56em auto;
				cursor: pointer;
			}
			.FacilitatorEditCoFacilitatorPopup-title {
				color: #ffffff;
				font-size: 1.25em;
				font-weight: 600;
			}
		}

		.FacilitatorEditCoFacilitatorPopup-body {
			padding: 1em 2em 1.5em 2em;
			.FacilitatorEditCoFacilitatorPopup-info {
				margin-bottom: 0.5em;
				span {
					display: inline-block;
					vertical-align: top;
					color: #11466B;
					
					&:first-of-type {
						font-weight: 600;
						width: 6em;
					}
				}
			}

			.FacilitatorEditCoFacilitatorPopup-removeBtn {
				cursor: pointer;
				span {
					color: #C13B3B;
					text-decoration: underline;
				}
			}

			.FacilitatorEditCoFacilitatorPopup-permissions {
				margin: 2em 0;
				.FacilitatorEditCoFacilitatorPopup-title {
					margin-bottom: 0.5em;
					span {
						font-size: 1.2em;
						color: #10779E;
						font-weight: 600;
					}
				}
				.FacilitatorEditCoFacilitatorPopup-setting {
					@include flex('flex-start', 'center');
					margin-bottom: 0.5em;
					.FacilitatorEditPlayerPopup-settingToggle {
						width: 3.5em;
					}
					.FacilitatorEditPlayerPopup-settingLabel {
						span {
							color: #11466B;
						}
					}
				}
			}
			
			.FacilitatorEditCoFacilitatorPopup-buttons {
				margin: 3em auto 0 auto;
				width: 25em;
				text-align: center;
				@include flex('space-between', 'center');
				> div {
					width: 12em;
				}
			}
		}
	}
}