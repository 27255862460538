@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';
@import '../../../../styles/mixins_backgrounds';
@import '../../../../styles/animations';
@import '../../../../styles/animations-intro';
.ClockTasks {
	position: relative;
	height: 100%;
	overflow: hidden;
	// @include grey-background();
	.ClockTasks-nextBtn {
		position: absolute;
		right: 0;
		bottom: 0;
		z-index: 2;
	}

	&.landscape {
		// @include grey-landscape-background();
		.ClockTasks-nextBtn {right: calc(50% - 13.5em);}
	}
}