@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';
@import '../../../../styles/mixins_backgrounds';
@import '../../../../styles/animations';
@import '../../../../styles/animations-intro';
.FacilitatorEditPlayerPopup {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(#7ABCEA, 0.81);
	@include flex('center', 'center');

	.FacilitatorEditPlayerPopup-content {
		width: 40.16em;
		height: 34.83em;
		background-color: white;
		border-radius: 0.83em;

		.FacilitatorEditPlayerPopup-header {
			position: relative;
			width: 100%;
			min-height: 3.61em;
			background-color: #11466B;
			border-top-left-radius: 0.83em;
			border-top-right-radius: 0.83em;
			border: 0.25em solid white;
			padding-left: 1em;
			@include flex('center', 'center');
			.FacilitatorEditPlayerPopup-closeBtn {
				position: absolute;
				top: 0;
				right: 0;
				width: 2.33em;
				height: 2.33em;
				background-image: url('../../../../assets/images/facilitator/icon-close.svg');
				background-size: 1.56em auto;
				cursor: pointer;
			}
			.FacilitatorEditPlayerPopup-title {
				color: #ffffff;
				font-size: 1.25em;
				font-weight: 600;
			}
		}

		.FacilitatorEditPlayerPopup-body {
			@include flex('center', 'center');
			flex-direction: column;
			.FacilitatorEditPlayerPopup-text {
				height: 2.166em;
				margin-top: 0.944em;
				span {
					color: #11466B;
					font-size: 1.11em;
					font-weight: 600;
					p {
						margin: 0;
					}

				}
			}
			
			.FacilitatorEditPlayerPopup-table {
				width: 100%;

				.FacilitatorEditPlayerPopup-cell {
					width: 50%;
					height: 100%;
					padding: 0 1.5em;
					@include flex('flex-start', 'center');
				}
				.FacilitatorEditPlayerPopup-tableheader {
					width: 100%;
					height: 1.55em;
					color: white;
					background-color: #11466B;
					@include flex('space-between', 'center');

					.FacilitatorEditPlayerPopup-cell {
						span {
							display: inline-block;
							vertical-align: middle;
							position: relative;
							font-weight: 600;
						}
						&.module {
							width: 80%;
						}
						&.access {
							width: 20%;
						}
					}
					

				}
				.FacilitatorEditPlayerPopup-row {
					width: 100%;
					height: 2.11em;
					color: #707070;
					&:nth-child(odd) {
						background-color: #EDEDED;
					}
					@include flex('space-between', 'center');
					.FacilitatorEditPlayerPopup-cell {
						&.module {
							width: 80%;
							span {
								font-weight: 600;
							}
						}
						&.toggle {
							width: 20%;
						}
					}
				}
			}
			.FacilitatorEditPlayerPopup-buttons {
				margin-top: 2em;
				text-align: center;
			}
		}
	}
}