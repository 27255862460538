@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';
@import '../../../../styles/mixins_backgrounds';
@import '../../../../styles/animations';
@import '../../../../styles/animations-intro';
.BranchingStoryInfo {
	position: relative;

	.BranchingStoryInfo-textBox {
		position: relative;
		left: calc(50% - 8.75em);
		width: 17.5em;
		color: #11466B;
		background-color: #F5F5F5;
		border-radius: 1.1em;
		padding: 1em 1.25em 1.25em 1.25em;
		margin-bottom: 2em;
		&.bubble {
			top: auto;
			bottom: 20em;
			&::after {
				content: '';
				position: absolute;
				top: calc(100% - 0.5em);
				left: 6.25em;
				border-left: 2em solid #F5F5F5;
				border-bottom: 2.4em solid transparent;
			}
		}
		.BranchingStoryInfo-title {
			font-weight: bold;
			font-size: 1.5em;
			margin-bottom: 0.75em;
		}
		.BranchingStoryInfo-text {
			p {
				margin-bottom: 0;
				font-size: 0.85em;
				&:first-of-type {margin-top: 0;}
			}
			&.manager {
				padding-right: 2em;
			}
		}
		.BranchingStoryInfo-manager {
			position: absolute;
			right: 1em;
			top: 0em;
			width: 3em;
			height: 3em;
			overflow: hidden;
			border-radius: 100%;
			
			
		}
		&.wizard-1 {
			padding-bottom: 3.5em;
			.BranchingStoryInfo-text {
				padding-right: 4em;
			}
			.BranchingStoryInfo-image {
				position: absolute;
				height: 6em;
				width: 4.25em;
				background-image: url('../../../../assets/images/characters/wizard-1.svg');
				background-size: 6.5em auto;
				background-position: right calc(50%) bottom -10em;
				right: 0.5em;
				bottom: 0em;
			}
		}
		&.wizard-black-eye {
			padding-bottom: 3.5em;
			.BranchingStoryInfo-text {
				padding-right: 4em;
			}
			.BranchingStoryInfo-image {
				position: absolute;
				height: 4em;
				width: 4.25em;
				background-image: url('../../../../assets/images/characters/wizard-black-eye.svg');
				background-size: 100% auto;
				background-position: top center;
				right: 1.5em;
				top: 0.5em;
			}
		}
		&.customer2-branching {
			padding-bottom: 3.5em;
			.BranchingStoryInfo-text {
				padding-right: 6em;
			}
			.BranchingStoryInfo-image {
				position: absolute;
				height: 6em;
				width: 6.25em;
				background-image: url('../../../../assets/images/modules/tasks/task-intro/customer2.svg');
				background-size: 5.5em auto;
				background-position: right calc(50%) bottom 0em;
				right: 0.5em;
				bottom: -1.5em;
			}
		}
	}

	.BranchingStoryInfo-character {
		margin: 2em auto 0 auto;
		&.wizard-karate {
			&::after {
				content: '';
				display: block;
				position: absolute;
				bottom: 0em;
				left: calc(50% - 6.3em);
				width: 12.35em;
				height: 11em;
				z-index: 9;
				background-image: url('../../../../assets/images/characters/items/chop.svg');
				@include opacity(0);
				@include animateChop(1s, 1s);
			}
		}
	}


	&.bakery-1-branching-story-cookies {
		.BranchingStoryInfo-textBox {
			&.ba-01 {
				.BranchingStoryInfo-image {
					margin-top: 0.5em;
					width: 14.75em;
					height: 12.58em;
					background-image: url('../../../../assets/images/modules/tasks/branching-story/bakery-1-branching-story-cookies/ba-01.svg');

				}
			}	
			&.ba-02 {
				.BranchingStoryInfo-image {
					margin-top: 0.5em;
					width: 12.11em;
					height: 9.19em;
					background-image: url('../../../../assets/images/modules/tasks/branching-story/bakery-1-branching-story-cookies/ba-02.svg');

				}
			}	
			&.ba-05 {
				@include flex('space-between', 'flex-start');
				.BranchingStoryInfo-text {
					width: calc(100% - 3em);
					padding-right: 0.5em;
				}
				.BranchingStoryInfo-image {
					width: 3em;
					height: 6.5em;
					background-position: top center;
					background-size: 3.5em auto;
					background-image: url('../../../../assets/images/modules/tasks/branching-story/bakery-1-branching-story-cookies/ba-05.svg');

				}
			}
			&.ba-07 {
				@include flex('space-between', 'flex-start');
				.BranchingStoryInfo-text {
					width: calc(100% - 4em);
					padding-right: 0.5em;
				}
				.BranchingStoryInfo-image {
					width: 4em;
					height: 4em;
					background-position: top center;
					background-size: 5em auto;
					background-image: url('../../../../assets/images/modules/tasks/branching-story/bakery-1-branching-story-cookies/ba-07.svg');

				}
			}
			&.ba-08 {
				@include flex('space-between', 'flex-start');
				.BranchingStoryInfo-text {
					width: calc(100% - 4em);
					padding-right: 0.5em;
				}
				.BranchingStoryInfo-image {
					width: 4em;
					height: 4em;
					background-position: top center;
					background-size: 5em auto;
					background-image: url('../../../../assets/images/modules/tasks/branching-story/bakery-1-branching-story-cookies/ba-08.svg');

				}
			}
			&.ba-10 {
				@include flex('space-between', 'flex-start');
				.BranchingStoryInfo-text {
					width: calc(100% - 3em);
					padding-right: 0.5em;
				}
				.BranchingStoryInfo-image {
					width: 3em;
					height: 6.5em;
					background-position: top center;
					background-size: 3.5em auto;
					background-image: url('../../../../assets/images/modules/tasks/branching-story/bakery-1-branching-story-cookies/ba-10.svg');

				}
			}
		}
	}

	&.deli-2-branching-story-tailgate-trays {
		.BranchingStoryInfo-textBox {
			&.db-31 {
				.BranchingStoryInfo-image {
					margin-top: 0.5em;
					width: 14.75em;
					height: 12.58em;
					background-image: url('../../../../assets/images/modules/tasks/branching-story/deli-2-tailgate-trays/db-31.svg');

				}
			}	
		}
	}
	
	&.center-store-2-branching-story-new-tags {
		.BranchingStoryInfo-textBox {
			&.cb-24,
			&.cb-28,
			&.cb-32,
			&.cb-35 {
				.BranchingStoryInfo-image {
					position: absolute;
					top: 0;
					right: 1em;
					width: 2.8em;
					height: 2.8em;
					border-radius: 100%;
					background-position: top center;
					background-size: 100% auto;
					background-image: url('../../../../assets/images/characters/wizard-1.svg');

				}
			}	
		}
	}
}