@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/mixins_backgrounds';
@import '../../../styles/animations';
@import '../../../styles/animations-intro';
.Areas {
	position: relative;
	height: 100%;

	.Areas-wrap {
		padding-top: 1.72em;
		padding-bottom: 2.5em;
		height: 100%;
		overflow: auto;
		@include map-background();

		.Areas-areas {
			position: relative;
			width: 100%;
			padding: 0 calc(50% - 9.83em);
			padding-bottom: 2em;
			text-align: center;
			.Areas-area {
				position: relative;
				display: inline-block;
				vertical-align: top;
				width: 9.83em;
				height: 11.5em;
				margin-top: 1em;
				&:nth-child(even)::after {
					content: '';
					position: absolute;
					top: 1em;
					left: -1.5em;
					width: 5em;
					height: 1.425em;
					background-image: url('../../../assets/images/areas/wave.svg');
				}
				&.disabled {
					.Area {
						@include opacity(0.5);
					}
				}

				&.under-construction {
					pointer-events: none;
					.Area {
						@include opacity(0.5);
					}
				}

				.Areas-constructionSign {
					position: absolute;
					background-image: url('../../../assets/images/map/under-construction.svg');
					width: 7em;
					height: 4em;
					background-size: auto;
					@include opacity(1);
					z-index: 1;
					bottom: 2.5em;
					left: calc(50% - 7em / 2);
				}
			}
			&.uneven .Areas-area {
				&:last-child::after,
				&:last-child::before {
					content: '';
					position: absolute;
					top: 1em;
					left: -3em;
					width: 5em;
					height: 1.425em;
					background-image: url('../../../assets/images/areas/wave.svg');
			 	}
				 &:last-child::before { 
					left: 8em;
				 }
			}
		}
	}

	.Areas-areaUnavailableFeedback {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		@include flex('center', 'center');
		.Areas-areaUnavailableFeedbackContent {
			width: 18em;
			background-color: #F5F5F5;
			border-radius: 1em;
			padding: 1em;
			.Areas-areaUnavailableFeedbackText {
				span {
					font-size: 0.89em;
					font-weight: 500;
				}
			}
			.Areas-areaUnavailableFeedbackBtn {
				margin: 0.75em auto 0 auto;
				width: 4.44em;
				height: 1.67em;
				background-color: #11466B;
				border-radius: 0.83em;
				cursor: pointer;
				@include flex('center', 'center');
				span {
					font-size: 0.89em;
					font-weight: 700;
					color: white;
				}
			}

		}
	}

	&.landscape {
		.Areas-wrap {
			@include map-background-landscape();
		}
	}
}