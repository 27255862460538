@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';
@import '../../../../styles/mixins_backgrounds';
@import '../../../../styles/animations';
@import '../../../../styles/animations-intro';
.ClockTasksOverview {
	position: relative;
	height: 100%;
	overflow: hidden;
	.ClockTasksOverview-clock {
		position: absolute;
		width: 7.8em;
		height: 1.85em;
		top: 3em;
		left: calc(50% - 3.9em);
		background-color: rgba(#082940, 0.6);
		border-radius: 1em;
		text-align: center;
		@include flex('center','center');
		span {
			color: white;
		}
	}
	.ClockTasksOverview-tasks {
		height: 100%;
		background-size: 20.833em auto;
		background-position: top center;
		.ClockTasksOverview-task {
			position: absolute;
			svg {
				width: auto;
				height: auto;
			}
			.ClockTasksOverview-taskBtn {
				position: absolute;
				top: 0;
				left: 0;
				width: 5em;
				height: 5em;
				border-radius: 100%;
				border: 0.22em solid white;
				cursor: pointer;
				z-index: 5;
			}
		}
		
	}

	&.deli-2 {
		@include dark-grey-background();
		&.landscape {@include dark-grey-landscape-background();}
		.ClockTasksOverview-tasks {
			min-height: 27em;
			background-size: 26.47em;
			background-image: url('../../../../assets/images/modules/tasks/clock-tasks/deli-2/background.svg');
			background-position: bottom -5.11em left calc(50% - 2.8em);
			.ClockTasksOverview-task {
				&.cl-01 {
					bottom: 9.5em;
					left: calc(50% + 3.4em);
					svg {width: 5.53em;}
					&.available svg {
						#Group_15952,
						#Group_65555 {
							display: none;
						}
					}
					.ClockTasksOverview-taskBtn {
						top: 0.5em;
						left: 2em;
					}
				}
				&.cl-02 {
					bottom: 22.15em;
					left: calc(50% + 5.75em);
					svg {width: 7.5em;}
					.ClockTasksOverview-taskBtn {
						top: 2em;
						left: -.8em;
					}
				}
				&.cl-03 {
					bottom: 13.2em;
					left: calc(50% + 0.3em);
					svg {width: 10.75em;}
					&.solved svg #Group_17292{display: none;}
					.ClockTasksOverview-taskBtn {
						top: 0.7em;
						left: 2em;
					}
				}
				&.cl-04 {
					bottom: 12.7em;
					left: calc(50% - 6.25em);
					svg {width: 2.65em}
					&.solved svg {display: none;}
					.ClockTasksOverview-taskBtn {
						top: -2em;
						left: -1.25em;
					}
				}
				&.cl-05 {
					bottom: 16.8em;
					left: calc(50% - 11.9em);
					svg {width: 9.75em;}
					&.solved svg #Group_17233 {display: none;}
					.ClockTasksOverview-taskBtn {
						top: 2em;
						left: 3em;
					}
				}
				&.cl-06 {
					bottom: 10.1em;
					left: calc(50% - 10.5em);
					.ClockTasksOverview-taskBtn {
						width: 4em;
						height: 4em;
					}
				}
				&.cl-07 {
					bottom: 11.2em;
					left: calc(50% + 2.5em);
				}
				&.cl-08 {
					bottom: 15.2em;
					left: 7.75em;
					left: calc(50% - 2.65em);
				}
				&.cl-09 {
					bottom: 8em;
					left: calc(50% - 7.5em);
					svg {width: 6.11em;}
				}
			}
		}
	}

	&.center-store-1 {
		@include grey-background();
		&.landscape {@include grey-landscape-background();}
		.ClockTasksOverview-tasks {
			min-height: 32em;	
			background-image: linear-gradient(rgba(#808285, 0), rgba(#808285, 1));
			background-size: 100% 18.53em;
			background-position: bottom 18.53em center;
			.ClockTasksOverview-task {
				&.ec-01 {
					bottom: 17.11em;
					left: calc(50% - 11em);
					width: 11.23em;
					height: 13.62em;
					svg {
						width: 11.23em;
						height: 13.62em;
						#price-tag-corrected {display: none;}
					}
					&.solved {
						svg {
							#price-tag-loose {display: none;}
							#price-tag-corrected {display: block;}
						}
					}
					.ClockTasksOverview-taskBtn {
						width: 8em;
						height: 8em;
						top: 1em;
						left: 1.75em;
					}
				}
				&.ec-02 {
					bottom: 5em;
					left: calc(50% - 9.5em);
					&.solved, &.failed {display: none;}
					svg {width: 6.44em;}
					.ClockTasksOverview-taskBtn {
						width: 7em;
						height: 7em;
						top: -0.75em;
						left: -0.25em;
					}
				}
				&.ec-03 {
					bottom: -0.61em;
					left: calc(50% - 0.5em);
					svg {width: 8.75em;}
					&.hidden, &.failed, &.solved {display: none;}
					.ClockTasksOverview-taskBtn {
						width: 7em;
						height: 7em;
						top: -0.5em;
						left: 1.25em;
					}
				}
				&.ec-04 {
					bottom: 14.7em;
					left: calc(50% + 1em);
					svg {width: 9.8em}
					.ClockTasksOverview-taskBtn {
						width: 8em;
						height: 8em;
						top: 2.5em;
						left: 0.5em;
					}
				}
				&.ec-05 {
					bottom: 12.02em;
					left: calc(50% - 3.25em);
					svg {width: 3.99em;}
					&.hidden, &.solved {display: none;}

					.ClockTasksOverview-taskBtn {
						height: 7em;
						top: 1em;
						left: -0.5em;
					}
				}
			}		
		}
	}

	&.customer-service-1 {
		@include customer-service-blue-background();
		background-size: auto 25em, 100% 100%;
		background-position: bottom -4em center, center;
		.ClockTasksOverview-tasks {
			.ClockTasksOverview-task {
				svg {
					width: 100%; 
				}
				.ClockTasksOverview-taskBtn {
					border: none;
					width: 100%;
					height: 100%;
				}
				&.csc-01 {
					bottom: 0em;
					left: calc(50%);
					width: 16.7em;
					height: 10.11em;
					padding-left: 0.5em;
					&.solved, &.failed {display: none;}
				}
				&.csc-02 {
					bottom: 0em;
					left: calc(50% - 5.5em);
					width: 5.65em;
					height: 11em;
					&.solved, &.failed {display: none;}
				}
				&.csc-03 {
					bottom: 0em;
					left: calc(50% - 10em);
					width: 4.4em;
					height: 10.4em;
					&.solved, &.failed {display: none;}
				}
			}		
		}
		&.landscape {
			@include customer-service-blue-landscape-background();
			background-size: auto 30em, 100% 100%;
			background-position: bottom -4em center, center;
			.ClockTasksOverview-tasks {
				.ClockTasksOverview-task {
					@include scale(1.2);
					bottom: 1.8em;
					&.csc-01 {
						left: calc(50% + 1.75em);	
					}
					&.csc-02 {
						left: calc(50% - 6em);
					}
					&.csc-03 {
						bottom: 1.3em;
						left: calc(50% - 12em);
					}
				}
			}
		}
	}

	&.produce-2 {
		@include grey-background();
		&.landscape {@include grey-landscape-background();}
		.ClockTasksOverview-tasks {
			min-height: 35em;
			background-size: 22.81em auto, 100% calc(100% - 21.11em);
			background-image: url('../../../../assets/images/modules/tasks/clock-tasks/produce-2/background.svg'), linear-gradient(rgba(#808285, 0), rgba(#808285, 1));
			background-position: bottom 10.67em right calc(50% + 2em), bottom 21.11em center;
			.ClockTasksOverview-task {
				&.pc-01 { // Bananas
					&.available, &.failed {
						bottom: 15.59em;
						left: calc(50% - 7.5em);
						width: 5.9em;
						height: 2.91em;
						background-image: url('../../../../assets/images/modules/tasks/clock-tasks/produce-2/pc-01.svg');
					}
					&.solved {
						bottom: 15.45em;
						left: calc(50% - 7.95em);
						width: 6.7em;
						height: 3.53em;
						background-image: url('../../../../assets/images/modules/tasks/clock-tasks/produce-2/pc-01-solved.svg');
					}
					.ClockTasksOverview-taskBtn {
						top: -0.75em;
						left: 0.75em;
						z-index: 4;
					}					
				}
				&.pc-02 { // Brown Bananas
					&.available, &.failed {
						bottom: 15.48em;
						left: calc(50% - 12.75em);
						width: 6.485em;
						height: 3.26em;
						background-image: url('../../../../assets/images/modules/tasks/clock-tasks/produce-2/pc-02.svg');
					}
					&.solved {
						bottom: 15.45em;
						left: calc(50% - 13em);
						width: 6.7em;
						height: 3.53em;
						background-image: url('../../../../assets/images/modules/tasks/clock-tasks/produce-2/pc-02-solved.svg');
					}
					.ClockTasksOverview-taskBtn {
						top: -0.75em;
						left: 1em;
						z-index: 4;
					}					
				}
				&.pc-wall { // Banana wall
					bottom: 14.52em;
					left: calc(50% - 13.44em);
					svg {
						width: 12.122em;
						height: auto;
					}
					z-index: 3;
					.ClockTasksOverview-taskBtn {
						display: none;
						pointer-events: none;
					}
				}
				&.pc-03 { // Bad tomatoes
					bottom: 10.43em;
					left: calc(50% + 2.45em);
					svg {
						width: 12.12em;
						height: auto;
					}
					&.available svg #pc-03-solved {display: none;}
					&.solved svg #pc-03-available {display: none;}
					.ClockTasksOverview-taskBtn {
						top: -0.5em;
					}
				}
				&.pc-05 { // Apples
					bottom: -0.25em;
					left: calc(50% - 13.5em);
					svg {
						width: 15.15em;
						height: auto;
					}
					.ClockTasksOverview-taskBtn {
						top: 0.25em;
						left: 5em;
					}
				}
				&.pc-06 { // Potato
					bottom: 10.43em;
					left: calc(50% + 2em);
					svg {width: 1.5em; height: auto;}
					&.solved {display: none;}
					.ClockTasksOverview-taskBtn {
						top: -1.75em;
						left: -1.75em;
					}
				}
				&.pc-07 { // Peanuts
					bottom: 19.2em;
					left: calc(50% + 6.6em);
					svg {width: 3em; height: auto;}
					&.solved {display: none;}
					.ClockTasksOverview-taskBtn {
						top: -1.75em;
						left: -1.25em;
					}
				}
				&.pc-08 { // Refrigerated case
					bottom: 19.05em;
					left: calc(50% - 16em);
					svg {width: 12.87em; height: auto;}
					.ClockTasksOverview-taskBtn {
						top: 4em;
						left: 7em;
					}
				}
				&.pc-09 { // Refrigerated case with juice
					bottom: 27em;
					left: calc(50% + 0.5em);
					.ClockTasksOverview-taskBtn {
						top: -1.5em;
						left: -1.5em;
					}
				}
				&.pc-stall { // Orange stall with sign
					bottom: -0.5em;
					left: calc(50% + 4.05em);
					z-index: 3;
					svg {
						width: 15.15em;
						height: auto;
					}
					.ClockTasksOverview-taskBtn {
						display: none;
						pointer-events: none;
					}
				}
			}	
		}
	}
}