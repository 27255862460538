@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/mixins_backgrounds';
@import '../../../styles/animations';
@import '../../../styles/animations-intro';
.NavigationTop-navigation {
	position: fixed;
	top: 0em;
	left: 0;
	right: 0;
	height: 4.56em;
	z-index: 3;
	background-image: linear-gradient(rgba(#082436, 1), rgba(#082940, 0));
	.NavigationTop-navItem {
		position: absolute;
		top: 0.67em;
		@include flex('space-between', 'center', 'column');
		cursor: pointer;

		.NavigationTop-navItemIcon {
			@include no-select();
			text-align: center;
			overflow: hidden;
			img {
				width: 100%;
				height: 100%;
			}
		}

		.NavigationTop-navItemLabel {
			font-size: 0.67em;
			font-weight: bold;
			text-align: center;
			color: white;
			text-transform: uppercase;
		}

		&.back {
			left: 1em;
			.NavigationTop-navItemIcon {
				width: 2.33em;
				height: 2.33em;
			}
		}

		&.avatar {
			right: 1em;
			.NavigationTop-navItemIcon {
				width: 2.56em;
				height: 2.56em;
				border-radius: 100%;
				background: radial-gradient(#7ABCEA, #10779E);
				border: 0.17em solid #11466B;
			}
		}
		
	}
}