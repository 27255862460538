@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';
@import '../../../../styles/mixins_backgrounds';
@import '../../../../styles/animations';
@import '../../../../styles/animations-intro';
.FacilitatorMutedInsightsPopup {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(#7ABCEA, 0.81);
	@include flex('center', 'center');

	.FacilitatorMutedInsightsPopup-closeBtn {
		padding: 0em;
		width: 2em;
		height: 2em;
		background-image: url('../../../../assets/images/facilitator/icon-close.svg');
		background-size: 1.75em auto;
		cursor: pointer;
	}

	.FacilitatorMutedInsightsPopup-content {
		width: 30em;
		background-color: white;
		border-radius: 0.83em;

		.FacilitatorMutedInsightsPopup-header {
			position: relative;
			width: 100%;
			height: 2.8em;
			background-color: #11466B;
			border-top-left-radius: 0.83em;
			border-top-right-radius: 0.83em;
			border: 0.25em solid white;
			padding-left: 1em;
			@include flex('space-between', 'center');
			.FacilitatorMutedInsightsPopup-title {
				color: #ffffff;
				font-size: 1.25em;
				font-weight: 600;
			}
			.FacilitatorMutedInsightsPopup-closeBtn {
				width: 2.33em;
				height: 2.33em;
				background-image: url('../../../../assets/images/facilitator/icon-close.svg');
				background-size: 1.56em auto;
				cursor: pointer;
			}
		}

		.FacilitatorMutedInsightsPopup-body {
			position: relative;
			padding: 1em;
			.FacilitatorMutedInsightsPopup-text {
				margin-bottom: 1em;
				span {
					font-weight: bold;
				}
			}
			.FacilitatorMutedInsightsPopup-insights {
				max-height: 20em;
				overflow: auto;
				.FacilitatorMutedInsightsPopup-insight {
					padding-left: 1.5em;
					background-image: url('../../../../assets/images/facilitator/icon-checkbox-unchecked.svg');
					background-size: auto 80%;
					background-position: left center;
					cursor: pointer;
					span {
						@include no-select();
					}
					&.muted {
						background-image: url('../../../../assets/images/facilitator/icon-checkbox-checked.svg');
					}
					&.disabled {
						cursor: default;
					}
				}
			}
			.FacilitatorMutedInsightsPopup-buttons {
				margin-top: 1.5em;
				width: 100%;
				@include flex('space-between', 'center');
				div {
					margin: 0;
				}
			}
		}
	}
}