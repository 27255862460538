@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';
@import '../../../../styles/mixins_backgrounds';
@import '../../../../styles/animations';
@import '../../../../styles/animations-intro';
.OrganizeDndItem {
	position: relative;
	display: inline-block;

	.OrganizeDndItem-img {
		width: 100%;
		height: 100%;
		position: relative;
	}

	&.floral-2-bouquets {
		width: 4em;
		height: 6.308em;
		&::before {
			content: '';
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			background-size: 3.85em auto;
			background-position: 0.65em 0.725em;
			background-repeat: no-repeat;
		}
		.OrganizeDndItem-img {
			height: 6.308em;
			width: 5em;
			&.bouquet1 {
				background-size: 2.9em;
				background-position: bottom calc(50% - 0.25em) right calc(50%);
				background-image: url('../../../../assets/images/modules/tasks/organize/floral-2-bouquets/bouquet1.svg');
			}
			&.bouquet2 {
				background-size: 4.1em;
				background-position: bottom calc(50% + 0.25em) right calc(50%);
				background-image: url('../../../../assets/images/modules/tasks/organize/floral-2-bouquets/bouquet2.svg');
			}
			&.bouquet3 {
				background-size: 2.9em;
				background-position: bottom calc(50% - 0.15em) right calc(50% + 0.1em);
				background-image: url('../../../../assets/images/modules/tasks/organize/floral-2-bouquets/bouquet3.svg');
			}
			&.bouquet4 {
				background-size: 2.9em;
				background-position: bottom calc(50% - 0.25em) right calc(50% + 0.1em);
				background-image: url('../../../../assets/images/modules/tasks/organize/floral-2-bouquets/bouquet4.svg');
			}
			&.bouquet5 {
				background-size: 4.1em;
				background-position: bottom calc(50% + 0.25em) right calc(50%);
				background-image: url('../../../../assets/images/modules/tasks/organize/floral-2-bouquets/bouquet5.svg');
			}
			&.bouquet6 {
				background-size: 2.9em;
				background-position: bottom calc(50% - 0.15em) right calc(50% + 0.1em);
				background-image: url('../../../../assets/images/modules/tasks/organize/floral-2-bouquets/bouquet6.svg');
			}
			&.bouquet7 {
				background-size: 2.2em;
				background-position: bottom calc(50% - 0.25em) right calc(50%);
				background-image: url('../../../../assets/images/modules/tasks/organize/floral-2-bouquets/bouquet7.svg');
			}
			&.bouquet8 {
				background-size: 3.25em;
				background-position: bottom calc(50% - 0.1em) right calc(50%);
				background-image: url('../../../../assets/images/modules/tasks/organize/floral-2-bouquets/bouquet8.svg');
			}
			&.bouquet9 {
				background-size: 2.2em;
				background-position: bottom calc(50% - 0.35em) right calc(50%);
				background-image: url('../../../../assets/images/modules/tasks/organize/floral-2-bouquets/bouquet9.svg');
			}
		}
	}

	&.bakery-1-donuts {
		width: 100%;
		height: 100%;
		&::before {
			content: '';
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			background-size: 3.85em auto;
			background-position: 0.65em 0.725em;
			background-repeat: no-repeat;
		}
		.OrganizeDndItem-img {
			&.initial {
				height: 4.5em;
				width: 4.5em;
			}
			&.final {
				height: 3.5em;
				width: 5.5em;
			}
			&.remove {
				height: 5.5em;
				width: 3.5em;
				background-position: center bottom 0.3em;
			}
			&.donut-1 {
				&.initial {
					background-image: url('../../../../assets/images/modules/tasks/organize/bakery-1-donuts/donut-1.svg');
				}
				&.final {
					background-image: url('../../../../assets/images/modules/tasks/organize/bakery-1-donuts/donut-bunch-1.svg');
				}
				&.remove {
					background-image: url('../../../../assets/images/modules/tasks/organize/bakery-1-donuts/donut-1.svg');
				}
			}
			&.donut-2 {
				&.initial {
					background-image: url('../../../../assets/images/modules/tasks/organize/bakery-1-donuts/donut-2.svg');
				}
				&.final {
					background-image: url('../../../../assets/images/modules/tasks/organize/bakery-1-donuts/donut-bunch-2.svg');
				}
				&.remove {
					background-image: url('../../../../assets/images/modules/tasks/organize/bakery-1-donuts/donut-2.svg');
				}
			}
			&.donut-3 {
				&.initial {
					background-image: url('../../../../assets/images/modules/tasks/organize/bakery-1-donuts/donut-3.svg');
				}
				&.final {
					background-image: url('../../../../assets/images/modules/tasks/organize/bakery-1-donuts/donut-bunch-3.svg');
				}
				&.remove {
					background-image: url('../../../../assets/images/modules/tasks/organize/bakery-1-donuts/donut-3.svg');
				}
			}
			&.donut-4 {
				&.initial {
					background-image: url('../../../../assets/images/modules/tasks/organize/bakery-1-donuts/donut-4.svg');
				}
				&.final {
					background-image: url('../../../../assets/images/modules/tasks/organize/bakery-1-donuts/donut-bunch-4.svg');
				}
				&.remove {
					background-image: url('../../../../assets/images/modules/tasks/organize/bakery-1-donuts/donut-4.svg');
				}
			}
			&.donut-5 {
				&.initial {
					background-image: url('../../../../assets/images/modules/tasks/organize/bakery-1-donuts/donut-5.svg');
				}
				&.final {
					background-image: url('../../../../assets/images/modules/tasks/organize/bakery-1-donuts/donut-bunch-5.svg');
				}
				&.remove {
					background-image: url('../../../../assets/images/modules/tasks/organize/bakery-1-donuts/donut-5.svg');
				}
			}
			&.donut-6 {
				&.initial {
					background-image: url('../../../../assets/images/modules/tasks/organize/bakery-1-donuts/donut-6.svg');
				}
				&.final {
					background-image: url('../../../../assets/images/modules/tasks/organize/bakery-1-donuts/donut-bunch-6.svg');
				}
				&.remove {
					background-image: url('../../../../assets/images/modules/tasks/organize/bakery-1-donuts/donut-6.svg');
				}
			}
			&.donut-7 {
				&.initial {
					background-image: url('../../../../assets/images/modules/tasks/organize/bakery-1-donuts/donut-7.svg');
				}
				&.final {
					background-image: url('../../../../assets/images/modules/tasks/organize/bakery-1-donuts/donut-bunch-7.svg');
				}
				&.remove {
					background-image: url('../../../../assets/images/modules/tasks/organize/bakery-1-donuts/donut-7.svg');
				}
			}
			&.donut-8 {
				&.initial {
					background-image: url('../../../../assets/images/modules/tasks/organize/bakery-1-donuts/donut-8.svg');
				}
				&.final {
					background-image: url('../../../../assets/images/modules/tasks/organize/bakery-1-donuts/donut-bunch-8.svg');
				}
				&.remove {
					background-image: url('../../../../assets/images/modules/tasks/organize/bakery-1-donuts/donut-8.svg');
				}
			}
			&.donut-9 {
				&.initial {
					background-image: url('../../../../assets/images/modules/tasks/organize/bakery-1-donuts/donut-9.svg');
				}
				&.final {
					background-image: url('../../../../assets/images/modules/tasks/organize/bakery-1-donuts/donut-bunch-9.svg');
				}
				&.remove {
					background-image: url('../../../../assets/images/modules/tasks/organize/bakery-1-donuts/donut-9.svg');
				}
			}
			&.donut-10 {
				&.initial {
					background-image: url('../../../../assets/images/modules/tasks/organize/bakery-1-donuts/donut-10.svg');
				}
				&.final {
					background-image: url('../../../../assets/images/modules/tasks/organize/bakery-1-donuts/donut-bunch-10.svg');
				}
				&.remove {
					bottom: 0.3em;
					background-image: url('../../../../assets/images/modules/tasks/organize/bakery-1-donuts/donut-10.svg');
				}
			}
			&.donut-11 {
				&.initial {
					background-image: url('../../../../assets/images/modules/tasks/organize/bakery-1-donuts/donut-11.svg');
				}
				&.final {
					background-image: url('../../../../assets/images/modules/tasks/organize/bakery-1-donuts/donut-bunch-11.svg');
				}
				&.remove {
					bottom: 0.2em;
					background-image: url('../../../../assets/images/modules/tasks/organize/bakery-1-donuts/donut-11.svg');
				}
			}
			&.donut-12 {
				&.initial {
					background-image: url('../../../../assets/images/modules/tasks/organize/bakery-1-donuts/donut-12.svg');
				}
				&.final {
					background-image: url('../../../../assets/images/modules/tasks/organize/bakery-1-donuts/donut-bunch-12.svg');
				}
				&.remove {
					background-image: url('../../../../assets/images/modules/tasks/organize/bakery-1-donuts/donut-12.svg');
				}
			}
		}
	}

	&.bakery-1-cupcakes {
		width: 100%;
		height: 100%;
		.OrganizeDndItem-img {
			width: 100%;
			height: 100%;
			background-size: auto 3.5em;
			&.initial {
				background-position: center center;
			}
			&.final {
				background-position: bottom 1.22em center;
			}
			&.cupcake-1 {
				&.initial {background-image: url('../../../../assets/images/modules/tasks/organize/bakery-1-cupcakes/cupcake-1.svg');	}
				&.final {background-image: url('../../../../assets/images/modules/tasks/organize/bakery-1-cupcakes/cupcake-1-final.svg');	}
			}
			&.cupcake-2 {
				&.initial {background-image: url('../../../../assets/images/modules/tasks/organize/bakery-1-cupcakes/cupcake-2.svg');	}
				&.final {background-image: url('../../../../assets/images/modules/tasks/organize/bakery-1-cupcakes/cupcake-2-final.svg');	}
			}
			&.cupcake-3 {
				&.initial {background-image: url('../../../../assets/images/modules/tasks/organize/bakery-1-cupcakes/cupcake-3.svg');	}
				&.final {background-image: url('../../../../assets/images/modules/tasks/organize/bakery-1-cupcakes/cupcake-3-final.svg');	}
			}
			&.cupcake-4 {
				&.initial {background-image: url('../../../../assets/images/modules/tasks/organize/bakery-1-cupcakes/cupcake-4.svg');	}
				&.final {background-image: url('../../../../assets/images/modules/tasks/organize/bakery-1-cupcakes/cupcake-4-final.svg');	}
			}
		}
	}

	&.meat-1-match-with-animal {
		&::before {
			content: '';
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			background-size: 3.85em auto;
			background-position: 0.65em 0.725em;
			background-repeat: no-repeat;
		}
		.OrganizeDndItem-img {
			position: relative;
			&.initial {
				height: 4em;
				width: 5em;
				background-size: 4em;
			}
			&.final {
				height: 3.6em;
				width: 3.5em;
				background-size: 3.5em;
				background-position: bottom calc(50%) right calc(50%);
			}
			&.meat-1 {
				background-image: url('../../../../assets/images/modules/tasks/organize/meat-1-match-with-animal/meat-1.svg');
			}
			&.meat-2 {
				background-image: url('../../../../assets/images/modules/tasks/organize/meat-1-match-with-animal/meat-2.svg');
			}
			&.meat-3 {
				background-image: url('../../../../assets/images/modules/tasks/organize/meat-1-match-with-animal/meat-3.svg');
			}
			&.meat-4 {
				background-image: url('../../../../assets/images/modules/tasks/organize/meat-1-match-with-animal/meat-4.svg');
			}
			&.meat-5 {
				background-image: url('../../../../assets/images/modules/tasks/organize/meat-1-match-with-animal/meat-5.svg');
			}
			&.meat-6 {
				background-image: url('../../../../assets/images/modules/tasks/organize/meat-1-match-with-animal/meat-6.svg');
			}
			&.meat-7 {
				background-image: url('../../../../assets/images/modules/tasks/organize/meat-1-match-with-animal/meat-7.svg');
			}
		}
	}

	&.meat-2-meat-case {
		width: 5.22em;
		height: 3.43em;
		.OrganizeDndItem-img {
			width: 100%;
			height: 100%;
			background-size: 5em auto;
			background-position: center center;
			&.meat-1 {background-image: url('../../../../assets/images/modules/tasks/organize/meat-2-meat-case/meat-1.svg');}
			&.meat-2 {background-image: url('../../../../assets/images/modules/tasks/organize/meat-2-meat-case/meat-2.svg');}
			&.meat-3 {background-image: url('../../../../assets/images/modules/tasks/organize/meat-2-meat-case/meat-3.svg');}
			&.meat-4 {
				background-image: url('../../../../assets/images/modules/tasks/organize/meat-2-meat-case/meat-4.svg');
				background-size: 100% auto;
			}
			&.meat-5 {
				background-image: url('../../../../assets/images/modules/tasks/organize/meat-2-meat-case/meat-5.svg');
				background-size: 100% auto;
			}
			&.meat-6 {
				background-image: url('../../../../assets/images/modules/tasks/organize/meat-2-meat-case/meat-6.svg');
				background-size: 100% auto;
			}
			&.meat-7 {background-image: url('../../../../assets/images/modules/tasks/organize/meat-2-meat-case/meat-7.svg');}
			&.meat-8 {background-image: url('../../../../assets/images/modules/tasks/organize/meat-2-meat-case/meat-8.svg');}
			&.meat-9 {background-image: url('../../../../assets/images/modules/tasks/organize/meat-2-meat-case/meat-9.svg');}
		}
	}
	
	&.produce-1-cool-racks {
		width: 4.2em;
		height: 3.5em;
		&::before {
			content: '';
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			background-size: 3.85em auto;
			background-position: 0.65em 0.725em;
			background-repeat: no-repeat;
		}
		.OrganizeDndItem-img {
			height: 3em;
			width: 8.05em;

			&.dry-1 {
				&.final {
					height: 5em;
					background-size: 7.5em;
					background-image: url('../../../../assets/images/modules/tasks/organize/produce-1-produce-aisle/dry-bunch-1.svg');
					background-position: bottom calc(50% - 0.25em) right calc(50% + 0.1em);
				}
				&.initial {
					width: 4.5em;
					height: 3.4em;
					background-size: 4.5em;
					background-position: bottom calc(50%) right calc(50% + 0.1em);
					background-image: url('../../../../assets/images/modules/tasks/organize/produce-1-produce-aisle/dry-1.svg');
				}
			}
			&.dry-2 {
				&.final {
					height: 5em;
					background-size: 7.5em;
					background-position: bottom calc(50% - 0.45em) right calc(50% + 0.25em);
					background-image: url('../../../../assets/images/modules/tasks/organize/produce-1-produce-aisle/dry-bunch-2.svg');
				}
				&.initial {
					width: 4em;
					height: 3.4em;
					background-size: 4em;
					background-position: bottom calc(50%) right calc(50%);
					background-image: url('../../../../assets/images/modules/tasks/organize/produce-1-produce-aisle/dry-2.svg');
				}
			}
			&.dry-3 {
				&.final {
					height: 5em;
					background-size: 7.5em;
					background-position: bottom calc(50% - 0.55em) right calc(50% + 0.25em);
					background-image: url('../../../../assets/images/modules/tasks/organize/produce-1-produce-aisle/dry-bunch-3.svg');
				}
				&.initial {
					width: 4.1em;
					height: 3.4em;
					background-size: 4em;
					background-position: bottom calc(50%) right calc(50%);
					background-image: url('../../../../assets/images/modules/tasks/organize/produce-1-produce-aisle/dry-3.svg');
				}
			}
			&.dry-4 {
				&.final {
					height: 5em;
					background-size: 7.5em;
					background-position: bottom calc(50% - 0.25em) right calc(50% + 0.25em);
					background-image: url('../../../../assets/images/modules/tasks/organize/produce-1-produce-aisle/dry-bunch-4.svg');
				}

				&.initial {
					width: 4.1em;
					height: 3.4em;
					background-size: 4em;
					background-position: bottom calc(50% + 0.25em) right calc(50%);
					background-image: url('../../../../assets/images/modules/tasks/organize/produce-1-produce-aisle/dry-4.svg');
				}
			}
			&.dry-5 {
				&.final {
					height: 5em;
					background-size: 7.5em;
					background-position: bottom calc(50%) right calc(50% + 0.25em);
					background-image: url('../../../../assets/images/modules/tasks/organize/produce-1-produce-aisle/dry-bunch-5.svg');
				}

				&.initial {
					width: 4.1em;
					height: 4.25em;
					background-size: 4.4em;
					background-position: bottom calc(50%) right calc(50% - 0.5em);
					background-image: url('../../../../assets/images/modules/tasks/organize/produce-1-produce-aisle/dry-5.svg');
				}
			}
			&.dry-6 {
				&.final {
					height: 5em;
					background-size: 7.5em;
					background-position: bottom calc(50% - 0.5em) right calc(50% + 0.1em);
					background-image: url('../../../../assets/images/modules/tasks/organize/produce-1-produce-aisle/dry-bunch-6.svg');
				}

				&.initial {
					width: 4.4em;
					height: 3.4em;
					background-size: 4.4em;
					background-position: bottom calc(50% - 0.25em) right calc(50% - 0.25em);
					background-image: url('../../../../assets/images/modules/tasks/organize/produce-1-produce-aisle/dry-6.svg');
				}
			}
			&.dry-7 {
				&.final {
					height: 5em;
					background-size: 7.5em;
					background-position: bottom calc(50% - 0.15em) right calc(50%);
					background-image: url('../../../../assets/images/modules/tasks/organize/produce-1-produce-aisle/dry-bunch-7.svg');
				}

				&.initial {
					width: 4.75em;
					height: 4em;
					background-size: 4.4em;
					background-position: bottom calc(50%) right calc(50% - 0.15em);
					background-image: url('../../../../assets/images/modules/tasks/organize/produce-1-produce-aisle/dry-7.svg');
				}
			}
			&.dry-8 {
				&.final {
					height: 5em;
					background-size: 8em;
					background-position: bottom calc(50%) right calc(50%);
					background-image: url('../../../../assets/images/modules/tasks/organize/produce-1-produce-aisle/dry-bunch-8.svg');
				}

				&.initial {
					width: 5.1em;
					height: 3.4em;
					background-size: 5em;
					background-position: bottom calc(50%) right calc(50%);
					background-image: url('../../../../assets/images/modules/tasks/organize/produce-1-produce-aisle/dry-8.svg');
				}
			}
			&.cooled-1 {
				&.final {
					height: 5em;
					background-size: 7.5em;
					background-position: bottom calc(50% - 0.3em) right calc(50% + 0.1em);
					background-image: url('../../../../assets/images/modules/tasks/organize/produce-1-produce-aisle/cooled-bunch-1.svg');
				}

				&.initial {
					width: 5.1em;
					height: 3.4em;
					background-size: 5em;
					background-position: bottom calc(50%) right calc(50%);
					background-image: url('../../../../assets/images/modules/tasks/organize/produce-1-produce-aisle/cooled-1.svg');
				}
			}
			&.cooled-2 {
				&.final {
					height: 5em;
					background-size: 7.5em;
					background-position: bottom calc(50% - 0.4em) right calc(50% + 0.2em);
					background-image: url('../../../../assets/images/modules/tasks/organize/produce-1-produce-aisle/cooled-bunch-2.svg');
				}

				&.initial {
					width: 5.1em;
					height: 3.4em;
					background-size: 5em;
					background-position: bottom calc(50%) right calc(50%);
					background-image: url('../../../../assets/images/modules/tasks/organize/produce-1-produce-aisle/cooled-2.svg');
				}
			}
			&.cooled-3 {
				&.final {
					height: 5em;
					background-size: 7.5em;
					background-position: bottom calc(50% - 0.2em) right calc(50% + 0.2em);
					background-image: url('../../../../assets/images/modules/tasks/organize/produce-1-produce-aisle/cooled-bunch-3.svg');
				}

				&.initial {
					width: 4.5em;
					height: 3.4em;
					background-size: 4.4em;
					background-position: bottom calc(50%) right calc(50%);
					background-image: url('../../../../assets/images/modules/tasks/organize/produce-1-produce-aisle/cooled-3.svg');
				}
			}
			&.cooled-4 {
				&.final {
					height: 5em;
					background-size: 8em;
					background-position: bottom calc(50%) right calc(50% + 0.1em);
					background-image: url('../../../../assets/images/modules/tasks/organize/produce-1-produce-aisle/cooled-bunch-4.svg');
				}

				&.initial {
					width: 4.5em;
					height: 3.4em;
					background-size: 5em;
					background-position: bottom calc(50% - 0.1em) right calc(50%);
					background-image: url('../../../../assets/images/modules/tasks/organize/produce-1-produce-aisle/cooled-4.svg');
				}
			}
			&.cooled-5 {
				&.final {
					height: 5em;
					background-size: 7.5em;
					background-position: bottom calc(50%) right calc(50% + 0.15em);
					background-image: url('../../../../assets/images/modules/tasks/organize/produce-1-produce-aisle/cooled-bunch-5.svg');
				}

				&.initial {
					width: 4.2em;
					height: 4em;
					background-size: 3.5em;
					background-position: bottom calc(50%) right calc(50% - 0.35em);
					background-image: url('../../../../assets/images/modules/tasks/organize/produce-1-produce-aisle/cooled-5.svg');
				}
			}
			&.cooled-6 {
				&.final {
					height: 5em;
					background-size: 7.5em;
					background-position: bottom calc(50%) right calc(50% + 0.1em);
					background-image: url('../../../../assets/images/modules/tasks/organize/produce-1-produce-aisle/cooled-bunch-6.svg');
				}

				&.initial {
					width: 4.5em;
					height: 3.4em;
					background-size: 5em;
					background-position: bottom -0.5em right calc(50%);
					background-image: url('../../../../assets/images/modules/tasks/organize/produce-1-produce-aisle/cooled-6.svg');
				}
			}
		}
	}
	
	&.produce-1-dry-table {
		width: 4.2em;
		height: 3.5em;
		&::before {
			content: '';
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			background-size: 3.85em auto;
			background-position: 0.65em 0.725em;
			background-repeat: no-repeat;
		}
		.OrganizeDndItem-img {
			height: 3em;
			width: 8.05em;

			&.dry-1 {
				&.final {
					background-size: 8.5em;
					background-image: url('../../../../assets/images/modules/tasks/organize/produce-1-produce-aisle/dry-bunch-1.svg');
					background-position: top calc(50% + 0.45em) right calc(50%);
					height: 3.8em;
				}
				&.initial {
					width: 4.5em;
					height: 3.4em;
					background-size: 4.5em;
					background-position: bottom calc(50%) right calc(50% + 0.1em);
					background-image: url('../../../../assets/images/modules/tasks/organize/produce-1-produce-aisle/dry-1.svg');
				}
			}
			&.dry-2 {
				&.final {
					height: 3.782em;
					background-size: 8.5em;
					background-position: top calc(50% + 0.3em) right calc(50%);
					background-image: url('../../../../assets/images/modules/tasks/organize/produce-1-produce-aisle/dry-bunch-2.svg');
				}
				&.initial {
					width: 4em;
					height: 3.4em;
					background-size: 4em;
					background-position: bottom calc(50%) right calc(50%);
					background-image: url('../../../../assets/images/modules/tasks/organize/produce-1-produce-aisle/dry-2.svg');
				}
			}
			&.dry-3 {
				&.final {
					height: 3.782em;
					background-size: 8.5em;
					background-position: top 0.25em right calc(50% + 0.1em);
					background-image: url('../../../../assets/images/modules/tasks/organize/produce-1-produce-aisle/dry-bunch-3.svg');
				}
				&.initial {
					width: 4.1em;
					height: 3.4em;
					background-size: 4em;
					background-position: bottom calc(50%) right calc(50%);
					background-image: url('../../../../assets/images/modules/tasks/organize/produce-1-produce-aisle/dry-3.svg');
				}
			}
			&.dry-4 {
				&.final {
					height: 3.782em;
					background-size: 8em;
					background-position: top calc(50% + 0.25em) right calc(50%);
					background-image: url('../../../../assets/images/modules/tasks/organize/produce-1-produce-aisle/dry-bunch-4.svg');
				}

				&.initial {
					width: 4.1em;
					height: 3.4em;
					background-size: 4em;
					background-position: bottom calc(50% + 0.25em) right calc(50%);
					background-image: url('../../../../assets/images/modules/tasks/organize/produce-1-produce-aisle/dry-4.svg');
				}
			}
			&.dry-5 {
				&.final {
					height: 3.782em;
					background-size: 8.5em;
					background-image: url('../../../../assets/images/modules/tasks/organize/produce-1-produce-aisle/dry-bunch-5.svg');
					background-position: top calc(50% + 0.75em) right calc(50% + 0.1em);
				}

				&.initial {
					width: 4.1em;
					height: 4.25em;
					background-size: 4.4em;
					background-position: bottom calc(50%) right calc(50% - 0.5em);
					background-image: url('../../../../assets/images/modules/tasks/organize/produce-1-produce-aisle/dry-5.svg');
				}
			}
			&.dry-6 {
				&.final {
					height: 3.782em;
					background-size: 8.5em;
					background-position: top calc(50% + 0.25em) right calc(50% - 0.1em);
					background-image: url('../../../../assets/images/modules/tasks/organize/produce-1-produce-aisle/dry-bunch-6.svg');
				}

				&.initial {
					width: 4.4em;
					height: 3.4em;
					background-size: 4.4em;
					background-position: bottom calc(50% - 0.25em) right calc(50% - 0.25em);
					background-image: url('../../../../assets/images/modules/tasks/organize/produce-1-produce-aisle/dry-6.svg');
				}
			}
			&.dry-7 {
				&.final {
					height: 3.782em;
					background-size: 8.5em;
					background-position: top calc(50% + 0.55em) right calc(50% - 0.1em);
					background-image: url('../../../../assets/images/modules/tasks/organize/produce-1-produce-aisle/dry-bunch-7.svg');
				}

				&.initial {
					width: 4.75em;
					height: 4em;
					background-size: 4.4em;
					background-position: bottom calc(50%) right calc(50% - 0.15em);
					background-image: url('../../../../assets/images/modules/tasks/organize/produce-1-produce-aisle/dry-7.svg');
				}
			}
			&.dry-8 {
				&.final {
					height: 3.782em;
					background-size: 8.5em;
					background-position: top calc(50% + 0.8em) right calc(50% + 0.1em);
					background-image: url('../../../../assets/images/modules/tasks/organize/produce-1-produce-aisle/dry-bunch-8.svg');
				}

				&.initial {
					width: 5.1em;
					height: 3.4em;
					background-size: 5em;
					background-position: bottom calc(50%) right calc(50%);
					background-image: url('../../../../assets/images/modules/tasks/organize/produce-1-produce-aisle/dry-8.svg');
				}
			}
			&.cooled-1 {
				&.final {
					height: 3.782em;
					background-size: 8.5em;
					background-position: top calc(50% + 0.45em) right calc(50% + 0.2em);
					background-image: url('../../../../assets/images/modules/tasks/organize/produce-1-produce-aisle/cooled-bunch-1.svg');
				}

				&.initial {
					width: 5.1em;
					height: 3.4em;
					background-size: 5em;
					background-position: bottom calc(50%) right calc(50%);
					background-image: url('../../../../assets/images/modules/tasks/organize/produce-1-produce-aisle/cooled-1.svg');
				}
			}
			&.cooled-2 {
				&.final {
					height: 3.782em;
					background-size: 8.5em;
					background-position: top calc(50% + 0.35em) right calc(50% + 0.2em);
					background-image: url('../../../../assets/images/modules/tasks/organize/produce-1-produce-aisle/cooled-bunch-2.svg');
				}

				&.initial {
					width: 5.1em;
					height: 3.4em;
					background-size: 5em;
					background-position: bottom calc(50%) right calc(50%);
					background-image: url('../../../../assets/images/modules/tasks/organize/produce-1-produce-aisle/cooled-2.svg');
				}
			}
			&.cooled-3 {
				&.final {
					height: 3.782em;
					background-size: 8.5em;
					background-position: top calc(50% + 0.5em) right calc(50% + 0.1em);
					background-image: url('../../../../assets/images/modules/tasks/organize/produce-1-produce-aisle/cooled-bunch-3.svg');
				}

				&.initial {
					width: 4.5em;
					height: 3.4em;
					background-size: 4.4em;
					background-position: bottom calc(50%) right calc(50%);
					background-image: url('../../../../assets/images/modules/tasks/organize/produce-1-produce-aisle/cooled-3.svg');
				}
			}
			&.cooled-4 {
				&.final {
					height: 3.782em;
					background-size: 8.5em;
					background-position: top calc(50% + 0.8em) right calc(50% + 0.1em);
					background-image: url('../../../../assets/images/modules/tasks/organize/produce-1-produce-aisle/cooled-bunch-4.svg');
				}

				&.initial {
					width: 4.5em;
					height: 3.4em;
					background-size: 5em;
					background-position: bottom calc(50% - 0.1em) right calc(50%);
					background-image: url('../../../../assets/images/modules/tasks/organize/produce-1-produce-aisle/cooled-4.svg');
				}
			}
			&.cooled-5 {
				&.final {
					height: 3.782em;
					background-size: 8.5em;
					background-position: top calc(50% + 0.65em) right calc(50%);
					background-image: url('../../../../assets/images/modules/tasks/organize/produce-1-produce-aisle/cooled-bunch-5.svg');
				}

				&.initial {
					width: 4.2em;
					height: 4em;
					background-size: 3.5em;
					background-position: bottom calc(50%) right calc(50% - 0.35em);
					background-image: url('../../../../assets/images/modules/tasks/organize/produce-1-produce-aisle/cooled-5.svg');
				}
			}
			&.cooled-6 {
				&.final {
					height: 3.782em;
					background-size: 8.5em;
					background-position: top calc(50% + 0.75em) right calc(50% + 0.1em);
					background-image: url('../../../../assets/images/modules/tasks/organize/produce-1-produce-aisle/cooled-bunch-6.svg');
				}

				&.initial {
					width: 4.5em;
					height: 3.4em;
					background-size: 5em;
					background-position: bottom -0.5em right calc(50%);
					background-image: url('../../../../assets/images/modules/tasks/organize/produce-1-produce-aisle/cooled-6.svg');
				}
			}
		}
	}

	&.produce-2-cut-fruit {
		width: 5.9em;
		height: 3.4em;
		
		.OrganizeDndItem-img {
			height: 100%;
			width: 100%;
			background-image: url('../../../../assets/images/modules/tasks/organize/produce-2-cut-fruit/cut-fruit.svg');
		}
	}

	&.backroom-5-s-organize-shelves {
		width: 100%;
		height: 100%;
		.OrganizeDndItem-img {
			width: 100%;
			height: 100%;
			&.initial { 
				background-position: center center;
			}
			&.shelves {
				background-position: bottom 0.4em left;
			}
			
			
			&.spray-cleaner {
				background-size: auto 4.6em;
				&.shelves {
					background-image: url('../../../../assets/images/modules/tasks/organize/backroom-5-s-organize-shelves/spray-cleaner-shelved.svg');
				}
				&.initial {					
					background-image: url('../../../../assets/images/modules/tasks/organize/backroom-5-s-organize-shelves/spray-cleaner.svg');
				}
			}
			&.gloves {
				background-size: auto 3.6em;
				&.shelves {
					background-image: url('../../../../assets/images/modules/tasks/organize/backroom-5-s-organize-shelves/gloves-shelved.svg');					
				}
				&.initial {
					background-image: url('../../../../assets/images/modules/tasks/organize/backroom-5-s-organize-shelves/gloves.svg');
				}
			}
			&.buckets {
				background-size: auto 4.4em;
				&.shelves {
					background-image: url('../../../../assets/images/modules/tasks/organize/backroom-5-s-organize-shelves/bucket-shelved.svg');
				}
				&.initial {
					background-image: url('../../../../assets/images/modules/tasks/organize/backroom-5-s-organize-shelves/bucket.svg');
				}
			}
			&.paper-towels {
				background-size: auto 3.4em;
				&.shelves {
					background-image: url('../../../../assets/images/modules/tasks/organize/backroom-5-s-organize-shelves/paper-towels-shelved.svg');					
				}
				&.initial {
					background-image: url('../../../../assets/images/modules/tasks/organize/backroom-5-s-organize-shelves/paper-towels.svg');
				}
			}
			&.cleaner-jugs {
				background-size: auto 4.6em;
				&.shelves {
					background-image: url('../../../../assets/images/modules/tasks/organize/backroom-5-s-organize-shelves/cleaner-jug-shelved.svg');					
				}
				&.initial {
					background-image: url('../../../../assets/images/modules/tasks/organize/backroom-5-s-organize-shelves/cleaner-jug.svg');
				}
			}
		}
	}

	&.backroom-5-s-organize-files {
		width: 4.2em;
		height: 3.5em;
		&::before {
			content: '';
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			background-size: 3.85em auto;
			background-position: 0.65em 0.725em;
			background-repeat: no-repeat;
		}
		.OrganizeDndItem-img {
			height: 5.5em;
			width: 6.5em;
			&.order-history {
				&.folders {
					background-image: url('../../../../assets/images/modules/tasks/organize/backroom-5-s-organize-files/order-history-placed.svg');
					background-position: top 0.5em left 0.5em;
					background-size: 3.6em 1em;
				}
				&.initial {
					height: 3.7em;
					width: 5em;
					background-size: 2.9em 2.4em;
					background-position: bottom calc(50%) right calc(50%);
					background-image: url('../../../../assets/images/modules/tasks/organize/backroom-5-s-organize-files/order-history.svg');
				}
			}
			&.sanitation-schedules {
				&.folders {
					background-image: url('../../../../assets/images/modules/tasks/organize/backroom-5-s-organize-files/sanitation-schedules-placed.svg');
					background-position: top 0.5em left 0.5em;
					background-size: 5.6em 1em;
				}
				&.initial {
					height: 3.7em;
					width: 5em;
					background-size: 5em 2.4em;
					background-position: bottom calc(50%) right calc(50%);
					background-image: url('../../../../assets/images/modules/tasks/organize/backroom-5-s-organize-files/sanitation-schedules.svg');
				}
			}
			&.invoice {
				&.folders {
					background-image: url('../../../../assets/images/modules/tasks/organize/backroom-5-s-organize-files/invoice-placed.svg');
					background-position: top 0.5em left 0.5em;
					background-size: 3.6em 1em;
				}
				&.initial {
					height: 3.7em;
					width: 5em;
					background-size: 2.9em 2.4em;
					background-position: bottom calc(50%) right calc(50%);
					background-image: url('../../../../assets/images/modules/tasks/organize/backroom-5-s-organize-files/invoice.svg');
				}
			}
			&.holiday-ads {
				&.folders {
					background-image: url('../../../../assets/images/modules/tasks/organize/backroom-5-s-organize-files/holiday-ads-placed.svg');
					background-position: top 0.5em left 0.5em;
					background-size: 5.4em 1em;
				}
				&.initial {
					height: 3.7em;
					width: 5em;
					background-size: 4.8em 2.4em;
					background-position: bottom calc(50%) right calc(50%);
					background-image: url('../../../../assets/images/modules/tasks/organize/backroom-5-s-organize-files/holiday-ads.svg');
				}
			}
		}
	}

	&.center-store-2-shelf-tags {
		height: 1em;
		width: 6em;
		.OrganizeDndItem-img {
			background-size: auto 100%;
			background-position: left center;
			&.onion {background-image: url('../../../../assets/images/modules/tasks/organize/center-store-2-shelf-tags/onion.svg');}
			&.potatoes {background-image: url('../../../../assets/images/modules/tasks/organize/center-store-2-shelf-tags/potatoes.svg');}
			&.corn {background-image: url('../../../../assets/images/modules/tasks/organize/center-store-2-shelf-tags/corn.svg');}
			&.beets {background-image: url('../../../../assets/images/modules/tasks/organize/center-store-2-shelf-tags/beets.svg');}
			&.carrots {background-image: url('../../../../assets/images/modules/tasks/organize/center-store-2-shelf-tags/carrots.svg');}
			&.asparagus {background-image: url('../../../../assets/images/modules/tasks/organize/center-store-2-shelf-tags/asparagus.svg');}
		}
	}

	&.deli-1-salad-bar {
		width: 4.5em;
		height: 5.5em;
		.OrganizeDndItem-img {
			background-size: 92% auto;
			background-position: top left;
			&.tomato {background-image: url('../../../../assets/images/modules/tasks/organize/deli-1-salad-bar/tomato.svg');}
			&.cucumber {background-image: url('../../../../assets/images/modules/tasks/organize/deli-1-salad-bar/cucumber.svg');}
			&.green-salad {background-image: url('../../../../assets/images/modules/tasks/organize/deli-1-salad-bar/green-salad.svg');}
			&.carrot {background-image: url('../../../../assets/images/modules/tasks/organize/deli-1-salad-bar/carrot.svg');}
			&.onion {background-image: url('../../../../assets/images/modules/tasks/organize/deli-1-salad-bar/onion.svg');}
			&.champignon-mushroom {background-image: url('../../../../assets/images/modules/tasks/organize/deli-1-salad-bar/champignon-mushroom.svg');}
			&.red-bell-pepper {background-image: url('../../../../assets/images/modules/tasks/organize/deli-1-salad-bar/red-bell-pepper.svg');}
			&.corn {background-image: url('../../../../assets/images/modules/tasks/organize/deli-1-salad-bar/corn.svg');}
		}
	}

	&.safety-1-gloves {
		width: 100%;
		height: 100%;
		.OrganizeDndItem-img {
			width: 100%;
			height: 100%;
			background-size: auto 3.58em;
			&.initial {
				background-position: center center;
			}
			&.remove,
			&.final {
				background-position: bottom 0.4em left;
			}
			&.remove {
				left: calc(50% - 0.6em);
			}

			&.gloves-1 {
				&.final {
					background-image: url('../../../../assets/images/modules/tasks/organize/safety-1-gloves/gloves-heat-resistant-bunch.svg');
				}
				&.initial,
				&.remove {
					background-image: url('../../../../assets/images/modules/tasks/organize/safety-1-gloves/gloves-heat-resistant.svg');
				}
			}
			&.gloves-2 {
				&.final {
					background-image: url('../../../../assets/images/modules/tasks/organize/safety-1-gloves/gloves-cut-proof-bunch.svg');
				}
				&.initial,
				&.remove {
					background-image: url('../../../../assets/images/modules/tasks/organize/safety-1-gloves/gloves-cut-proof.svg');
				}
			}
			&.gloves-3 {
				background-size: auto 3.68em;
				&.final {
					background-image: url('../../../../assets/images/modules/tasks/organize/safety-1-gloves/gloves-washing-bunch.svg');
				}
				&.initial,
				&.remove {
					background-image: url('../../../../assets/images/modules/tasks/organize/safety-1-gloves/gloves-washing.svg');
				}
			}
			&.gloves-4 {
				background-size: auto 3.63em;
				&.final {
					background-image: url('../../../../assets/images/modules/tasks/organize/safety-1-gloves/gloves-food-prep-bunch.svg');
				}
				&.initial,
				&.remove {
					background-image: url('../../../../assets/images/modules/tasks/organize/safety-1-gloves/gloves-food-prep.svg');
				}
			}
			&.gloves-5 {
				background-size: auto 3.66em;
				&.final {
					background-image: url('../../../../assets/images/modules/tasks/organize/safety-1-gloves/baseball-mit-bunch.svg');
				}
				&.initial,
				&.remove {
					background-image: url('../../../../assets/images/modules/tasks/organize/safety-1-gloves/baseball-mit.svg');
				}
			}
			&.gloves-6 {
				background-size: auto 4.52em;
				&.final {
					background-image: url('../../../../assets/images/modules/tasks/organize/safety-1-gloves/finger-foam-bunch.svg');
				}
				&.initial,
				&.remove {
					width: 3em;
					background-image: url('../../../../assets/images/modules/tasks/organize/safety-1-gloves/finger-foam.svg');
				}
			}
			&.gloves-7 {
				background-size: auto 2.38em;
				&.final {
					background-image: url('../../../../assets/images/modules/tasks/organize/safety-1-gloves/biker-glove-bunch.svg');
				}
				&.initial,
				&.remove {
					background-image: url('../../../../assets/images/modules/tasks/organize/safety-1-gloves/biker-glove.svg');
				}
			}
		}
	}

	&.seafood-1-fish-labels {
		.OrganizeDndItem-img {
			&.label-1 {
				background-image: url('../../../../assets/images/modules/tasks/organize/seafood-1-fish-labels/label-1.svg');
				width: 6em;
				height: 3.6em;
			}
			&.label-2 {
				width: 3.5em;
				height: 3.5em;
				background-image: url('../../../../assets/images/modules/tasks/organize/seafood-1-fish-labels/label-2.svg');
			}
			&.label-3 {
				width: 3em;
				height: 3em;
				background-image: url('../../../../assets/images/modules/tasks/organize/seafood-1-fish-labels/label-3.svg');
			}
		}
	}
	
	&.seafood-1-seafood-pallet {
		&::before {
			content: '';
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			background-size: 3.85em auto;
			background-position: 0.65em 0.725em;
			background-repeat: no-repeat;
		}
		.OrganizeDndItem-img {
			z-index: 3;

			&.pallet-1 {
				&.final1 {
					height: 3em;
					width: 13em;
					margin-top: 0.2em;
					margin-left: 0.25em;
					background-size: 13em auto;
					background-image: url('../../../../assets/images/modules/tasks/organize/seafood-1-seafood-pallet/seafood-cooler-1.svg');
				}

				&.final2 {
					height: 3.8em;
					width: 11em;
					background-size: 11em auto;
					margin-top: 0.25em;
					background-image: url('../../../../assets/images/modules/tasks/organize/seafood-1-seafood-pallet/seafood-freezer-1.svg');
				}

				&.initial {
					height: 2.75em;
					width: 5em;
					background-size: 5em auto;
					background-image: url('../../../../assets/images/modules/tasks/organize/seafood-1-seafood-pallet/seafood-1.svg');
				}
			}
			&.pallet-2 {
				&.final1 {
					height: 2.5em;
					width: 13em;
					margin-top: 0.5em;
					margin-left: 0.25em;
					background-size: 13em;
					background-image: url('../../../../assets/images/modules/tasks/organize/seafood-1-seafood-pallet/seafood-cooler-2.svg');
				}

				&.final2 {
					height: 3em;
					width: 10em;
					margin-top: 0.65em;
					margin-left: 0.5em;
					background-size: 10em;
					background-image: url('../../../../assets/images/modules/tasks/organize/seafood-1-seafood-pallet/seafood-freezer-2.svg');
				}

				&.initial {
					height: 2.75em;
					width: 5em;
					background-size: 5em;
					background-image: url('../../../../assets/images/modules/tasks/organize/seafood-1-seafood-pallet/seafood-2.svg');
				}
			}
			&.pallet-3 {
				&.final1 {
					height: 3.25em;
					width: 10.5em;
					margin-left: 1.5em;
					background-size: 10.5em;
					background-image: url('../../../../assets/images/modules/tasks/organize/seafood-1-seafood-pallet/seafood-cooler-3.svg');
				}

				&.final2 {
					height: 3.75em;
					width: 9em;
					margin-left: 1em;
					background-size: 9em;
					background-position: bottom calc(50%) right calc(50%);
					background-image: url('../../../../assets/images/modules/tasks/organize/seafood-1-seafood-pallet/seafood-freezer-3.svg');
				}

				&.initial {
					width: 3.2em;
					height: 3em;
					background-size: 3em;
					background-position: bottom calc(50%) right calc(50%);
					background-image: url('../../../../assets/images/modules/tasks/organize/seafood-1-seafood-pallet/seafood-3.svg');
				}
			}
			&.pallet-4 {
				&.final1 {
					height: 3em;
					width: 11em;
					margin-top: 0.2em;
					margin-left: 1em;
					background-size: 11em;
					background-position: bottom calc(50%) right calc(50%);
					background-image: url('../../../../assets/images/modules/tasks/organize/seafood-1-seafood-pallet/seafood-bunch-4.svg');
				}

				&.final2 {
					height: 2.75em;
					width: 10em;
					margin-top: 0.75em;
					margin-left: 0.5em;
					background-size: 10em;
					background-position: bottom calc(50%) right calc(50%);
					background-image: url('../../../../assets/images/modules/tasks/organize/seafood-1-seafood-pallet/seafood-bunch-4.svg');
				}

				&.initial {
					height: 3em;
					width: 5em;
					background-size: 5em;
					background-position: bottom calc(50%) right calc(50%);
					background-image: url('../../../../assets/images/modules/tasks/organize/seafood-1-seafood-pallet/seafood-4.svg');
				}
			}
			&.pallet-5 {
				&.final1 {
					height: 3em;
					width: 11em;
					margin-top: 0.2em;
					margin-left: 1em;
					background-size: 11em;
					background-position: bottom calc(50%) right calc(50%);
					background-image: url('../../../../assets/images/modules/tasks/organize/seafood-1-seafood-pallet/seafood-bunch-5.svg');
				}

				&.final2 {
					height: 2.75em;
					width: 10em;
					margin-top: 0.75em;
					margin-left: 0.5em;
					background-size: 10em;
					background-position: bottom calc(50%) right calc(50%);
					background-image: url('../../../../assets/images/modules/tasks/organize/seafood-1-seafood-pallet/seafood-bunch-5.svg');
				}

				&.initial {
					height: 3em;
					width: 5em;
					background-size: 5em;
					background-position: bottom calc(50%) right calc(50%);
					background-image: url('../../../../assets/images/modules/tasks/organize/seafood-1-seafood-pallet/seafood-5.svg');
				}
			}
			&.pallet-6 {
				&.final1 {
					height: 3em;
					width: 11em;
					margin-top: 0.2em;
					margin-left: 1em;
					background-size: 11em;
					background-position: bottom calc(50%) right calc(50%);
					background-image: url('../../../../assets/images/modules/tasks/organize/seafood-1-seafood-pallet/seafood-bunch-6.svg');
				}

				&.final2 {
					height: 2.75em;
					width: 10em;
					margin-top: 0.75em;
					margin-left: 0.5em;
					background-size: 10em;
					background-position: bottom calc(50%) right calc(50%);
					background-image: url('../../../../assets/images/modules/tasks/organize/seafood-1-seafood-pallet/seafood-bunch-6.svg');
				}

				&.initial {
					height: 3em;
					width: 5em;
					background-size: 5em;
					background-position: bottom calc(50%) right calc(50%);
					background-image: url('../../../../assets/images/modules/tasks/organize/seafood-1-seafood-pallet/seafood-6.svg');
				}
			}
		}
	}

	&.deli-2-shelves {
		width: 100%;
		height: 100%;
		.OrganizeDndItem-img {
			background-position: bottom center;
			&.can-beans {
				background-size: 3.11em auto;
				background-image: url('../../../../assets/images/modules/tasks/organize/deli-2-shelves/can-beans.svg');
			}
			&.can-corn {
				background-size: 2.5em auto;
				background-image: url('../../../../assets/images/modules/tasks/organize/deli-2-shelves/can-corn.svg');
			}
			&.can-corn-2 {
				background-size: 2.5em auto;
				background-image: url('../../../../assets/images/modules/tasks/organize/deli-2-shelves/can-corn-2.svg');
			}
			&.can-mushrooms {
				background-size: 2.8em auto;
				background-image: url('../../../../assets/images/modules/tasks/organize/deli-2-shelves/can-mushrooms.svg');
			}
			&.can-onions {
				background-size: 2.5em auto;
				background-image: url('../../../../assets/images/modules/tasks/organize/deli-2-shelves/can-onions.svg');
			}
			&.can-tomatos {
				background-size: 2.1em auto;
				background-image: url('../../../../assets/images/modules/tasks/organize/deli-2-shelves/can-tomatos.svg');
			}
			&.can-peas {
				background-size: 2.62em auto;
				background-image: url('../../../../assets/images/modules/tasks/organize/deli-2-shelves/can-peas.svg');
			}
		}
	}

	&.deli-2-sandwiches {
		width: 100%;
		height: 100%;
		.OrganizeDndItem-img {
			background-position: bottom center;
			background-size: 100% auto;
			&.sandwich-1 {background-image: url('../../../../assets/images/modules/tasks/organize/deli-2-sandwiches/sandwich-1.svg');}
			&.sandwich-2 {background-image: url('../../../../assets/images/modules/tasks/organize/deli-2-sandwiches/sandwich-2.svg');}
			&.sandwich-3 {background-image: url('../../../../assets/images/modules/tasks/organize/deli-2-sandwiches/sandwich-3.svg');}
			&.sandwich-4 {background-image: url('../../../../assets/images/modules/tasks/organize/deli-2-sandwiches/sandwich-4.svg');}
			&.sandwich-5 {background-image: url('../../../../assets/images/modules/tasks/organize/deli-2-sandwiches/sandwich-5.svg');}
			&.sandwich-6 {background-image: url('../../../../assets/images/modules/tasks/organize/deli-2-sandwiches/sandwich-6.svg');}
		}
	}

	&.orientation-1-restock-bread {
		width: 100%;
		height: 100%;
		.OrganizeDndItem-img {
			background-position: bottom 0.1em center;
			background-size: 100% auto;
			&.bread-blue-1 {background-image: url('../../../../assets/images/modules/tasks/organize/orientation-1-restock-bread/bread-blue-1.svg');}
			&.bread-blue-2 {background-image: url('../../../../assets/images/modules/tasks/organize/orientation-1-restock-bread/bread-blue-2.svg');}
			&.bread-blue-3 {background-image: url('../../../../assets/images/modules/tasks/organize/orientation-1-restock-bread/bread-blue-3.svg');}
			&.bread-blue-1,
			&.bread-blue-2,
			&.bread-blue-3 {
				background-size: 3.55em auto;
			}
			&.bread-green-1 {background-image: url('../../../../assets/images/modules/tasks/organize/orientation-1-restock-bread/bread-green-1.svg');}
			&.bread-green-2 {background-image: url('../../../../assets/images/modules/tasks/organize/orientation-1-restock-bread/bread-green-2.svg');}
			&.bread-green-3 {background-image: url('../../../../assets/images/modules/tasks/organize/orientation-1-restock-bread/bread-green-3.svg');}
			&.bread-green-1,
			&.bread-green-2,
			&.bread-green-3 {
				background-size: 3.5em auto;
			}
			&.bread-red-1 {background-image: url('../../../../assets/images/modules/tasks/organize/orientation-1-restock-bread/bread-red-1.svg');}
			&.bread-red-2 {background-image: url('../../../../assets/images/modules/tasks/organize/orientation-1-restock-bread/bread-red-2.svg');}
			&.bread-red-1,
			&.bread-red-2 {
				background-size: 3.44em auto;
			}
		}
		&.c1, &.c2, &.c3, &.c4, &.c5, &.c6, &.c7, &.c8 {
			.OrganizeDndItem-img {
				background-position: center center;
			}
		}
	}
	
	&.seafood-1-bad-shellfish {
		width: 100%;
		height: 100%;

		.OrganizeDndItem-img {
			background-position: bottom 0.1em center;
			background-size: 100% auto;

			&.lobster {
				background-image: url('../../../../assets/images/modules/tasks/organize/seafood-1-bad-shellfish/lobster.svg');
			}
			&.clam {
				background-image: url('../../../../assets/images/modules/tasks/organize/seafood-1-bad-shellfish/clam.svg');
				background-size: 4.2em 4.6em;
			}
			&.shrimp {
				background-image: url('../../../../assets/images/modules/tasks/organize/seafood-1-bad-shellfish/shrimp.svg');
			}
			&.oyster {
				background-image: url('../../../../assets/images/modules/tasks/organize/seafood-1-bad-shellfish/oyster.svg');
				background-size: 4.8em 4em;
			}
			&.crab {
				background-image: url('../../../../assets/images/modules/tasks/organize/seafood-1-bad-shellfish/crab.svg');
			}
		}
		&.c1, &.c2, &.c3, &.c4, &.c5 {
			.OrganizeDndItem-img {
				background-position: center center;
			}
		}
	}
}