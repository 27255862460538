@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';
@import '../../../../styles/mixins_backgrounds';
@import '../../../../styles/animations';
@import '../../../../styles/animations-intro';
.FacilitatorNewCompetitionPopup {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(#7ABCEA, 0.81);
	@include flex('center', 'center');

	.FacilitatorNewCompetitionPopup-closeBtn {
		padding: 0em;
		width: 2em;
		height: 2em;
		background-image: url('../../../../assets/images/facilitator/icon-close.svg');
		background-size: 1.75em auto;
		cursor: pointer;
	}

	.FacilitatorNewCompetitionPopup-content {
		width: 47em;
		min-height: 14em;
		max-height: 100vh;
		background-color: white;
		border-radius: 0.83em;
		overflow: auto;
		.FacilitatorNewCompetitionPopup-header {
			position: relative;
			width: 100%;
			height: 2.8em;
			background-color: #11466B;
			border-top-left-radius: 0.83em;
			border-top-right-radius: 0.83em;
			border: 0.25em solid white;
			padding-left: 1em;
			@include flex('space-between', 'center');
			.FacilitatorNewCompetitionPopup-title {
				color: #ffffff;
				font-size: 1.25em;
				font-weight: 600;
			}
			.FacilitatorNewCompetitionPopup-closeBtn {
				width: 2.33em;
				height: 2.33em;
				background-image: url('../../../../assets/images/facilitator/icon-close.svg');
				background-size: 1.56em auto;
				cursor: pointer;
			}
		}

		.FacilitatorNewCompetitionPopup-body {
			position: relative;
			padding: 1.5em 1.5em 1em 1.5em;
			.FacilitatorNewCompetitionPopup-selectType {
				position: relative;
				width: 22em;
				padding-right: 2em;
				margin-bottom: 1em;
				.FacilitatorNewCompetitionPopup-typeTooltip {
					position: absolute;
					left: 20.5em;
					top: 0.1em;
					width: 17em;
					z-index: 5;
					cursor: pointer;
					@include flex('space-between', 'flex-start');
					.FacilitatorNewCompetitionPopup-typeTooltipIcon {
						width: 1.5em;
						height: 1.5em;
						background-image: url('../../../../assets/images/facilitator/icon-help-2.svg');
						background-size: contain;
					}
					.FacilitatorNewCompetitionPopup-typeTooltipText {
						display: none;
						width: 15em;
						background-color: #BFBFBF;
						color: #525252;
						border-radius: 0.55em;
						padding: 0.5em;
						p {
							margin-top: 0;
							&:last-of-type {
								margin-bottom: 0;
							}
						}
					}
					&:hover {
						.FacilitatorNewCompetitionPopup-typeTooltipText {
							display: block;
						}
					}
				}
			}
			.FacilitatorNewCompetitionPopup-selectModule {
				width: 20em;
				margin-bottom: 2em;
			}

			
			.FacilitatorNewCompetitionPopup-competitionSchedule {
				position: absolute;
				top: 1.5em;
				right: 1.5em;
				
				.FacilitatorNewCompetitionPopup-competitionScheduleLabel {
					background-image: url('../../../../assets/images/facilitator/icon-calendar-blue.svg');
					background-position: left center;
					padding-left: 2em;
					margin-bottom: 0.5em;
					span {
						color: #10779E;
						font-weight: 600;
						text-decoration: underline;
					}
				}
				.FacilitatorNewCompetitionPopup-competitionScheduleInput {
					position: relative;
					width: 20em;
					@include flex('space-between', 'center');
					.react-datepicker-wrapper {
						width: 9.5em;
						input {
							position: relative;
							width: 100%;
							height: 2.8em;
							border-radius: 0.4em;
							border: 0.1em solid #10779E;
							padding: 0.5em 1em;
							@include placeholderColor(#B4B4B4);
						}	
					}
					.react-datepicker-popper {
						.react-datepicker {
							font-size: 1em;
							width: 28em;
							.react-datepicker__month-container {
								width: 100%;
								.react-datepicker__header {
									.react-datepicker__current-month {
										font-size: 1.1em;
									}
									.react-datepicker__day-names {
										width: 100%;
										font-size: 1em;
										.react-datepicker__day-name {
											width: 3em;
											margin: 0.5em;
											line-height: 1.7em;
										}
									}
								}
								.react-datepicker__month {
									margin: 0;
									.react-datepicker__week {
										.react-datepicker__day {
											width: 3em;
											margin: 0.5em;
											line-height: 1.7em;
										}
									}
								}
							}
						}
					}
				}
			}

			.FacilitatorNewCompetitionPopup-competitionPlayers {
				margin-bottom: 2em;
				.FacilitatorNewCompetitionPopup-competitionPlayersLabel {
					background-image: url('../../../../assets/images/facilitator/icon-players-2-blue.svg');
					background-position: left center;
					padding-left: 2em;
					margin-bottom: 0.5em;
					span {
						color: #10779E;
						font-weight: 600;
						text-decoration: underline;
					}
				}
				.FacilitatorNewCompetitionPopup-competitionPlayersSelect {
					position: relative;
					padding-left: 1.5em;
					margin-bottom: 0.5em;
					cursor: pointer;
					> span {
						color: #10779E;
					}
					&::after {
						content: '';
						position: absolute;
						left: 0;
						top: calc(50% - 0.4em);
						width: 0.8em;
						height: 0.8em;
						border-radius: 100%;
						border: 0.1em solid #10779E;
					}
					&.selected {
						> span {
							font-weight: bold;
						}
						&::after {
							background-color: #10779E;
						}
					}
					.FacilitatorNewCompetitionPopup-competitionPlayersInput {
						position: absolute;
						width: 20em;
						right: 1.5em;
						top: -0.25em;
					}
				}
			}

			.FacilitatorNewCompetitionPopup-requiredStars,
			.FacilitatorNewCompetitionPopup-competitionDescription,
			.FacilitatorNewCompetitionPopup-competitionPrize {
				margin-bottom: 1em;
				.FacilitatorNewCompetitionPopup-requiredStarsLabel,
				.FacilitatorNewCompetitionPopup-competitionDescriptionLabel,
				.FacilitatorNewCompetitionPopup-competitionPrizeLabel {
					background-image: url('../../../../assets/images/facilitator/icon-megafon.svg');
					background-position: left center;
					padding-left: 2em;
					margin-bottom: 0.5em;
					span {
						color: #10779E;
						font-weight: 600;
						text-decoration: underline;
					}
				}
				.FacilitatorNewCompetitionPopup-competitionDescriptionInput,
				.FacilitatorNewCompetitionPopup-competitionPrizeInput {
					width: 100%;
					textarea {
						width: 100%;
						height: 8em;
						resize: none;
						border: 0.1em solid #10779E;
						border-radius: 0.4em;
						padding: 0.5em;
					}
				}
			}
			.FacilitatorNewCompetitionPopup-competitionPrize { 
				.FacilitatorNewCompetitionPopup-competitionPrizeLabel {
					background-image: url('../../../../assets/images/facilitator/icon-competitions-blue.svg');
				}
				.FacilitatorNewCompetitionPopup-competitionPrizeInput {
					textarea {
						height: 5em;
					}
				}
			}
			.FacilitatorNewCompetitionPopup-requiredStars {
				margin-bottom: 2em;
				.FacilitatorNewCompetitionPopup-requiredStarsLabel {
					background-image: url('../../../../assets/images/facilitator/star-empty-blue.svg');
				}
				.FacilitatorNewCompetitionPopup-requiredStarsOption {
					position: relative;
					padding-left: 1.5em;
					margin-bottom: 0.5em;
					cursor: pointer;
					> span {
						color: #10779E;
					}
					&::after {
						content: '';
						position: absolute;
						left: 0;
						top: calc(50% - 0.4em);
						width: 0.8em;
						height: 0.8em;
						border-radius: 100%;
						border: 0.1em solid #10779E;
					}
					&.selected {
						> span {
							font-weight: bold;
						}
						&::after {
							background-color: #10779E;
						}
					}
				}
			}

			.FacilitatorNewCompetitionPopup-errorMsg {
				height: 2em;
				margin-bottom: 1em;
				p {
					margin: 0;
					font-size: 1.2em;
					color: #C13B3B;
					font-weight: bold;
				}
			}

			.FacilitatorNewCompetitionPopup-confirmBtn {
				width: 8em;	
			}
		}
	}
}