.MultipleChoice {
	&.produce.landscape {@include produce-grey-landscape-background();}

	&.produce-1-leafy-greens {
		.MultipleChoice-options {
			margin-top: 1em;
			padding-bottom: 1em;
			.MultipleChoice-optionsWrap {
				height: 24.4em;			
				padding: 0 1.5em;	
				.MultipleChoice-option { 
					display: inline-block;
					width: 8em;
					height: 8em;
					span:nth-child(2) {
						bottom: 0.5em;
						right: 0.75em;
						left: auto;
						width: 1.39em;
						height: 1.39em;
					}
					&.position-1,
					&.position-3 {margin-right: calc(100% - 16em);}
					&.position-1,
					&.position-2 {margin-bottom: 1em;}
					&.option-1 {
						background-size: 6em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/produce-1-leafy-greens/option-1.svg');
					}
					&.option-2 {
						background-size: 8em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/produce-1-leafy-greens/option-2.svg');
					}
					&.option-3 {
						background-size: 8em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/produce-1-leafy-greens/option-3.svg');
					}
					&.option-4 {
						background-size: 3.75em auto;		
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/produce-1-leafy-greens/option-4.svg');
					}
				}
			}
		}
	}

	&.produce-1-jalapeno {
		.MultipleChoice-options {
			margin-top: 1em;
			padding-bottom: 1em;
			.MultipleChoice-optionsWrap {
				height: 24.4em;			
				padding: 0 1.5em;
				@include flex('flex-start', 'center', 'column');
				.MultipleChoice-option { 
					display: inline-block;
					width: 14.8em;
					height: 3.7em;
					background-size: contain;
					span:nth-child(2) {
						bottom: 0.5em;
						right: 0.75em;
						left: auto;
						width: 1.39em;
						height: 1.39em;
					}
					&.position-1 {margin-top: 2em;}
					&.position-2,
					&.position-3 {margin-top: 4em;}
					&.option-1 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/produce-1-jalapeno/option-1.svg');
					}
					&.option-2 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/produce-1-jalapeno/option-2.svg');
					}
					&.option-3 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/produce-1-jalapeno/option-3.svg');
					}
				}
			}
		}
	}

	&.produce-1-bananas-mark-down {
		.MultipleChoice-options {
			margin-top: 1em;
			padding-bottom: 1em;
			.MultipleChoice-optionsWrap {
				height: 24.4em;
				padding: 0 1.5em;
				padding-top: 3em;
				.MultipleChoice-option { 
					display: inline-block;
					width: 7.6em;
					height: 5.15em;
					span:nth-child(2) {
						bottom: 0.5em;
						right: 0.75em;
						left: auto;
						width: 1.39em;
						height: 1.39em;
					}
					&.position-1,
					&.position-3 {margin-right: calc(100% - 16em);}
					&.position-1,
					&.position-2 {margin-bottom: 1em;}
					&.option-1 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/produce-1-bananas-mark-down/option-1.svg');
					}
					&.option-2 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/produce-1-bananas-mark-down/option-2.svg');
					}
					&.option-3 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/produce-1-bananas-mark-down/option-3.svg');
					}
					&.option-4 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/produce-1-bananas-mark-down/option-4.svg');
					}
				}
			}
		}
	}

	&.produce-1-broccoli {
		.MultipleChoice-options {
			margin-top: 1em;
			padding-bottom: 1em;
			.MultipleChoice-optionsWrap {
				height: 24.4em;
				padding: 0 1.5em;
				padding-top: 3em;
				@include flex('center', 'center', 'column');
				.MultipleChoice-option { 
					display: inline-block;
					width: 11.8em;
					height: 6.12em;
					span:nth-child(2) {
						bottom: 0.5em;
						right: 0.75em;
						left: auto;
						width: 1.39em;
						height: 1.39em;
					}
					&.position-1,
					&.position-2,
					&.position-3 {margin-bottom: 0.5em;}
					&.option-1 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/produce-1-broccoli/option-1.svg');
					}
					&.option-2 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/produce-1-broccoli/option-2.svg');
					}
					&.option-3 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/produce-1-broccoli/option-3.svg');
					}
					&.option-4 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/produce-1-broccoli/option-4.svg');
					}
				}
			}
		}
	}

	&.produce-1-exotic-fruits {
		.MultipleChoice-options {
			margin-top: 1em;
			padding-bottom: 1em;
			.MultipleChoice-optionsWrap {
				height: 24.4em;
				padding: 0 1.5em;
				padding-top: 3em;
				.MultipleChoice-option { 
					display: inline-block;
					width: 7.6em;
					height: 5.15em;
					span:nth-child(2) {
						bottom: 0.5em;
						right: 0.75em;
						left: auto;
						width: 1.39em;
						height: 1.39em;
					}
					&.position-1,
					&.position-3 {margin-right: calc(100% - 16em);}
					&.position-1,
					&.position-2 {margin-bottom: 1em;}
					&.option-1 {
						width: 7.4em;
						height: 7.8em;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/produce-1-exotic-fruits/option-1.svg');
					}
					&.option-2 {
						width: 6em;
						height: 6.5em;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/produce-1-exotic-fruits/option-2.svg');
					}
					&.option-3 {
						width: 5.4em;
						height: 5em;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/produce-1-exotic-fruits/option-3.svg');
					}
					&.option-4 {
						width: 7em;
						height: 10.8em;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/produce-1-exotic-fruits/option-4.svg');
					}
				}
			}
		}
	}

	&.produce-1-flies {
		.MultipleChoice-options {
			position: relative;
			width: 100%;
			left: 0;
			margin-top: 1em;
			background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/produce-1-flies/background.svg');
			background-size: 18em 17em, 100%;
			background-position: top 3em left calc(50%);

			.MultipleChoice-optionsWrap {
				position: relative;
				width: 100%;
				height: 100%;
				min-height: 23.5em;
				
				.MultipleChoice-option {
					position: relative; 
					width: 100%;
					&.option-1 {
						overflow: visible;
						position: absolute;
						top: 10em;
						right: calc(50% - 17.65em / 2);
						height: 5.5em;
						width: 17.65em;
						z-index: 2;
						span {
							&:nth-child(2) {
								top: -10em;
								left: 4em;
								width: 9.60em;
								height: 2.74em;
								background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/produce-1-flies/flies.svg');
							}
						}
					}
					&.option-2 {
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						z-index: 1;
					}
					&.option-3 {
						position: absolute;
						top: 3.8em;
						right: calc(50% - 8.2em);
						width: 16.4em;
						height: 6.1em;
						z-index: 2;
					}
					&.selected,
					&.correct,
					&.animateCorrect,
					&.wrong,
					&.animateWrong {
						@include noAnimation();
						&.option-2,
						&.option-3 {
							span:nth-child(2) {display: none;}	
						}
					}
				}
			}
		}
	}

	&.produce-1-good-leafy {
		.MultipleChoice-options {
			margin-top: 1em;
			padding-bottom: 1em;
			.MultipleChoice-optionsWrap {
				height: 24.4em;			
				padding: 0 1.5em;
				@include flex('flex-start', 'center', 'column');	
				.MultipleChoice-option { 
					display: inline-block;
					width: 8em;
					height: 8em;
					span:nth-child(2) {
						bottom: 0.5em;
						right: 0.75em;
						left: auto;
						width: 1.39em;
						height: 1.39em;
					}
					&.position-1 {margin-top: 2em;}
					&.position-2 {margin-top: 4em;}
					&.option-1 {
						width: 8.7em;
						height: 7.75em;
						background-size: 8.7em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/produce-1-good-leafy/option-1.svg');
					}
					&.option-2 {
						width: 13.5em;
						height: 6em;
						background-size: 13.5em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/produce-1-good-leafy/option-2.svg');
					}
				}
			}
		}
	}

	&.produce-1-melons {
		.MultipleChoice-options {
			margin-top: 1em;
			padding-bottom: 1em;
			.MultipleChoice-optionsWrap {
				height: 24.4em;
				padding: 0 1.5em;
				.MultipleChoice-option { 
					display: inline-block;
					width: 8em;
					height: 11em;
					background-position: bottom center;
					span:nth-child(2) {
						bottom: 0.5em;
						right: 0.75em;
						left: auto;
						width: 1.39em;
						height: 1.39em;
					}
					&.position-1,
					&.position-3 {margin-right: calc(100% - 16em);}
					&.position-1,
					&.position-2 {margin-bottom: 1em;}
					&.option-1 {
						background-size: 7.1em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/produce-1-melons/option-1.svg');
					}
					&.option-2 {
						background-size: 6.6em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/produce-1-melons/option-2.svg');
					}
					&.option-3 {
						background-size: 5.8em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/produce-1-melons/option-3.svg');
					}
					&.option-4 {
						background-size: 6.9em auto;		
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/produce-1-melons/option-4.svg');
					}
				}
			}
		}
	}

	&.produce-1-produce-department {
		.MultipleChoice-options {
			left: 0;
			width: 100%;
			margin-top: 1em;
			.MultipleChoice-optionsWrap {
				height: 100%;
				min-height: 100%;
				overflow: hidden;
				background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/produce-1-produce-department/background.svg'), linear-gradient(rgba(#808285, 0), #808285);
				background-size: auto 28.65em, 100% calc(100% - 7.34em);
				background-position: bottom -2.1em left calc(50% - 1em), bottom 16em center;
				.MultipleChoice-option {
					position: absolute;
					span:nth-child(2) {
						bottom: auto;
						top: 2em;
						left: 50%;
					}
					&.option-1 {
						width: 18.5em;
						height: 11em;
						bottom: 0;
						left: calc(50% - 13.5em);
						z-index: 2;
					}
					&.option-2 {
						width: 13em;
						height: 8.75em;
						bottom: 5.5em;
						left: calc(50% + 1em);
						z-index: 1;
					}
					&.option-3 {
						width: 9.25em;
						height: 12em;
						bottom: 14.5em;
						left: calc(50% - 3.5em);
					}
					&.option-4 {
						width: 12.5em;
						height: 12em;
						bottom: 14.5em;
						left: calc(50% - 16em);
					}
				}
			}
		}
	}

	&.produce-1-onions-1 {
		.MultipleChoice-options {
			margin-top: 1em;
			padding-bottom: 1em;
			.MultipleChoice-optionsWrap {
				height: 24.4em;				
				padding: 0 1.5em;
				.MultipleChoice-option {
					display: inline-block;
					width: 8em;
					height: 8em;
					span:nth-child(2) {
						bottom: 1em;
						right: 1.75em;
						left: auto;
						width: 1.39em;
						height: 1.39em;
					}
					&.position-1,
					&.position-3 {margin-right: calc(100% - 16em);}
					&.position-1,
					&.position-2 {margin-bottom: 1em;}
					&.option-1 {
						background-size: 3.5em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/produce-1-onions-1/option-1.svg');
					}
					&.option-2 {
						background-size: 3.5em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/produce-1-onions-1/option-2.svg');
					}
					&.option-3 {
						background-size: auto 100%;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/produce-1-onions-1/option-3.svg');
					}
					&.option-4 {
						background-size: 3.5em auto;		
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/produce-1-onions-1/option-4.svg');
					}
					&.option-5 {
						background-size: 3em auto;		
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/produce-1-onions-1/option-5.svg');
					}
				}
			}
		}
	}

	&.produce-1-onions-2 {
		.MultipleChoice-options {
			margin-top: 1em;
			padding-bottom: 1em;
			.MultipleChoice-optionsWrap {
				height: 24.4em;			
				padding: 0 1.5em;	
				.MultipleChoice-option { 
					display: inline-block;
					width: 8em;
					height: 8em;
					span:nth-child(2) {
						bottom: 0.5em;
						right: 0.75em;
						left: auto;
						width: 1.39em;
						height: 1.39em;
					}
					&.position-1,
					&.position-4,
					&.position-3 {margin-right: calc(100% - 16em);}

					&.position-3 {margin-left: calc(25% + 1em); }

					&.position-1,
					&.position-2 {margin-bottom: -1em;}
					
					&.position-4,
					&.position-5 {margin-top: -2em;}
					&.option-1 {
						background-size: 6em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/produce-1-onions-2/option-1.svg');
					}
					&.option-2 {
						background-size: 5em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/produce-1-onions-2/option-2.svg');
					}
					&.option-3 {
						background-size: 4em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/produce-1-onions-2/option-3.svg');
					}
					&.option-4 {
						background-size: 6em auto;		
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/produce-1-onions-2/option-4.svg');
					}
					&.option-5 {
						background-size: 4.5em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/produce-1-onions-2/option-5.svg');
					}
				}
			}
		}
	}	

	&.produce-1-orange-type {
		.MultipleChoice-options {
			margin-top: 1em;
			padding-bottom: 1em;
			.MultipleChoice-optionsWrap {
				margin: auto;
				width: 15.34em;
				height: 22.5em;
				background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/produce-1-orange-type/background.svg');
				background-position: top center;
				.MultipleChoice-option { 
					position: absolute;
					span:nth-child(2) {
						bottom: 0em;
						right: 0em;
						left: auto;
						width: 1em;
						height: 1em;
					}
					&.option-1 {
						width: 4em;
						height: 0.9em;
						top: 15.1em;
						left: calc(50% - 1.8em);
					}
					&.option-2 {
						width: 5em;
						height: 4em;
						top: 0em;
						left: calc(50% - 1.5em);
					}
					&.option-3 {
						width: 4em;
						height: 1em;
						top: 16em;
						left: calc(50% - 1.8em);
					}
				}
			}
		}
	}

	&.produce-1-potatoes-1 {
		.MultipleChoice-options {
			margin-top: 1em;
			padding-bottom: 1em;
			.MultipleChoice-optionsWrap {
				height: 24.4em;				
				padding: 0 1.5em;
				.MultipleChoice-option { 
					display: inline-block;
					width: 8em;
					height: 9em;
					span:nth-child(2) {
						bottom: 0.5em;
						right: 0.75em;
						left: auto;
						width: 1.39em;
						height: 1.39em;
					}
					&.position-1,
					&.position-3 {margin-left: calc(25% + 3em); }
					&.position-2 {margin-top: -1em; margin-bottom: -1em; margin-right: calc(100%)}

					&.option-1 {
						height: 7em;
						background-size: 6em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/produce-1-potatoes-1/option-1.svg');
					}
					&.option-2 {
						background-size: 8em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/produce-1-potatoes-1/option-2.svg');
					}
					&.option-3 {
						height: 6em;
						background-size: 5em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/produce-1-potatoes-1/option-3.svg');
					}
				}
			}
		}
	}

	&.produce-1-potatoes-2 {
		.MultipleChoice-options {
			margin-top: 1em;
			padding-bottom: 1em;
			.MultipleChoice-optionsWrap {
				height: 24.4em;		
				padding: 0 1.5em;		
				.MultipleChoice-option { 
					display: inline-block;
					width: 8em;
					height: 9em;
					span:nth-child(2) {
						bottom: 0.5em;
						right: 0.75em;
						left: auto;
						width: 1.39em;
						height: 1.39em;
					}
					&.position-1 {margin-right: calc(100% - 16em);}
					&.position-3 {margin-right: calc(25% + 3em); }
					&.position-2 {margin-top: -1em; margin-bottom: -1em;}
					&.position-5 {margin-top: -2em;}

					&.option-1 {
						height: 7em;
						background-size: 6em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/produce-1-potatoes-2/option-1.svg');
					}
					&.option-2 {
						background-size: 6em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/produce-1-potatoes-2/option-2.svg');
					}
					&.option-3 {
						height: 9em;
						background-size: 8em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/produce-1-potatoes-2/option-3.svg');
					}
					&.option-4 {
						height: 6em;
						background-size: 5em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/produce-1-potatoes-2/option-4.svg');
					}
					&.option-5 {
						height: 6em;
						background-size: 5em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/produce-1-potatoes-2/option-5.svg');
					}
				}
			}
		}
	}

	&.produce-1-potatoes-3 {
		.MultipleChoice-options {
			margin-top: 1em;
			padding-bottom: 1em;
			.MultipleChoice-optionsWrap {
				height: 24.4em;			
				padding: 0 1.5em;	
				.MultipleChoice-option { 
					display: inline-block;
					width: 8em;
					height: 8em;
					span:nth-child(2) {
						bottom: 0.5em;
						right: 0.75em;
						left: auto;
						width: 1.39em;
						height: 1.39em;
					}
					&.position-1,
					&.position-3 {margin-right: calc(100% - 16em);}

					&.position-3 {margin-left: calc(25% + 1em);}

					&.position-1,
					&.position-2 {margin-bottom: -3em;}
					
					&.position-4,
					&.position-5 {margin-top: -2em;}
					&.option-1 {
						background-size: 6em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/produce-1-potatoes-3/option-1.svg');
					}
					&.option-2 {
						background-size: 5em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/produce-1-potatoes-3/option-2.svg');
					}
					&.option-3 {
						background-size: 6em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/produce-1-potatoes-3/option-3.svg');
					}
					&.option-4 {
						background-size: 6em auto;		
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/produce-1-potatoes-3/option-4.svg');
					}
					&.option-5 {
						background-size: 6em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/produce-1-potatoes-3/option-5.svg');
					}
				}
			}
		}
	}

	&.produce-2-banana-boxes {
		.MultipleChoice-options {
			margin-top: 1em;
			padding-bottom: 1em;
			.MultipleChoice-optionsWrap {
				height: 24.4em;
				.MultipleChoice-option { 
					margin: auto;
					width: 18em;
					height: 12em;
					span:nth-child(2) {
						bottom: 0.5em;
						right: 0.75em;
						left: auto;
						width: 1.39em;
						height: 1.39em;
					}
					&.position-1{margin-bottom: 1em;}
					&.option-1 {
						background-size: 18em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/produce-2-banana-boxes/option-1.svg');
					}
					&.option-2 {
						background-size: 18em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/produce-2-banana-boxes/option-2.svg');
					}
				}
			}
		}
	}

	&.produce-2-brown-bananas {
		.MultipleChoice-options {
			margin-top: 1em;
			padding-bottom: 1em;
			.MultipleChoice-optionsWrap {
				height: 24.4em;				
				.MultipleChoice-option { 
					display: inline-block;
					width: 7em;
					height: 7em;
					span:nth-child(2) {
						bottom: 0.5em;
						right: 0.75em;
						left: auto;
						width: 1.39em;
						height: 1.39em;
					}
					&.position-1,
					&.position-3,
					&.position-5 {margin-left: calc(100% - 17em);}
					&.option-1 {
						background-size: 6em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/produce-2-brown-bananas/option-1.svg');
					}
					&.option-2 {
						background-size: 6em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/produce-2-brown-bananas/option-2.svg');
					}
					&.option-3 {
						background-size: 6em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/produce-2-brown-bananas/option-3.svg');
					}
					&.option-4 {
						background-size: 6em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/produce-2-brown-bananas/option-4.svg');
					}
					&.option-5 {
						background-size: 6em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/produce-2-brown-bananas/option-5.svg');
					}
					&.option-6 {
						background-size: 6em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/produce-2-brown-bananas/option-6.svg');
					}
				}
			}
		}
	}

	&.produce-2-help-know-need-extra {
		.MultipleChoice-options {
			margin-top: 1em;
			padding-bottom: 1em;
			.MultipleChoice-optionsWrap {
				width: 18.14em;
				height: 18.74em;
				padding: 1em 0 0 2em;
				margin: 0 auto;
				text-align: left;
				background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/produce-2-help-know-need-extra/background.svg');
				.MultipleChoice-option { 
					display: inline-block;
					width: 5em;
					height: 6.75em;
					margin: 0 1em 1em 1em;
					span:nth-child(2) {
						bottom: calc(50% - 1.25em);
						left: calc(100% - 1em);
					}
					
					&.option-1 {background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/produce-2-help-know-need-extra/option-1.svg');}
					&.option-2 {background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/produce-2-help-know-need-extra/option-2.svg');}
					&.option-3 {background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/produce-2-help-know-need-extra/option-3.svg');}
				}
			}
		}
	}

	&.produce-2-how-put-organics {
		.MultipleChoice-options {
			margin-top: 1em;
			padding-bottom: 1em;
			.MultipleChoice-optionsWrap {
				width: 13em;
				margin: 0 auto;
				.MultipleChoice-option { 
					display: inline-block;
					width: 100%;
					height: 7.9em;
					margin-bottom: 1em;
					span:nth-child(2) {
						bottom: 1.75em;
						left: calc(100% - 1.25em);
					}
					&.option-1 {background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/produce-2-how-put-organics/option-1.svg');}
					&.option-2 {background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/produce-2-how-put-organics/option-2.svg');}
					&.option-3 {background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/produce-2-how-put-organics/option-3.svg');}
				}
			}
		}
	}
	&.produce-2-display-bagged-apples {
		.MultipleChoice-options {
			margin-top: 1.5em;
			padding-bottom: 1em;
			.MultipleChoice-optionsWrap {
				width: 17.25em;
				margin: 0 auto;
				text-align: center;
				.MultipleChoice-option { 
					display: inline-block;
					width: 7.66em;
					height: 8.96em;
					margin-bottom: 1em;
					&:first-of-type {
						margin-right: 1em;
					}
					span:nth-child(2) {
						bottom: calc(50% - 1.25em);
						left: calc(100% - 1.5em);
					}
					&.option-1 {background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/produce-2-display-bagged-apples/option-1.svg');}
					&.option-2 {background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/produce-2-display-bagged-apples/option-2.svg');}
					&.option-3 {background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/produce-2-display-bagged-apples/option-3.svg');}
				}
			}
		}
	}
	&.produce-2-display-peppers {
		.MultipleChoice-options {
			margin-top: 1em;
			padding-bottom: 1em;
			.MultipleChoice-optionsWrap {
				width: 11.55em;
				margin: 0 auto;
				.MultipleChoice-option { 
					display: inline-block;
					width: 100%;
					height: 5.55em;
					margin-bottom: 0.5em;
					span:nth-child(2) {
						bottom: 1.75em;
						left: calc(100% - 0.8em);
					}
					&.option-1 {background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/produce-2-display-peppers/option-1.svg');}
					&.option-2 {background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/produce-2-display-peppers/option-2.svg');}
					&.option-3 {background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/produce-2-display-peppers/option-3.svg');}
					&.option-4 {background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/produce-2-display-peppers/option-4.svg');}
				}
			}
		}
	}
	&.produce-2-bananas-ideal-number {
		.MultipleChoice-options {
			margin-top: 1.5em;
			padding-bottom: 1em;
			.MultipleChoice-optionsWrap {
				width: 19em;
				height: 8em;
				padding-top: 1em;
				margin: 0 auto;
				background-position: left top;
				background-size: auto 100%;
				background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/produce-2-bananas-ideal-number/background.svg');
				.MultipleChoice-option { 
					display: inline-block;
					width: 2.55em;
					height: 100%;
					span:nth-child(2) {
						bottom: 3.5em;
						left: calc(50% - 0.75em);
					}
				}
			}
		}
	}
	&.produce-2-how-high-pack-in-ice {
		.MultipleChoice-options {
			margin-top: 1.5em;
			padding-bottom: 1em;
			.MultipleChoice-optionsWrap {
				width: 14.6em;
				margin: 0 auto;
				text-align: center;
				.MultipleChoice-option { 
					display: inline-block;
					width: 6.21em;
					height: 8.76em;
					margin-bottom: 1em;
					&.position-1,
					&.position-3 {
						margin-right: 1.5em;
					}
					span:nth-child(2) {
						bottom: calc(50% - 1.25em);
						left: calc(100% - 1.5em);
					}
					&.option-1 {background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/produce-2-how-high-pack-in-ice/option-1.svg');}
					&.option-2 {background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/produce-2-how-high-pack-in-ice/option-2.svg');}
					&.option-3 {background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/produce-2-how-high-pack-in-ice/option-3.svg');}
					&.option-4 {background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/produce-2-how-high-pack-in-ice/option-4.svg');}
				}
			}
		}	
	}
	&.produce-2-not-in-cooler {
		.MultipleChoice-options {
			margin-top: 1.5em;
			padding-bottom: 1em;
			.MultipleChoice-optionsWrap {
				width: 14.6em;
				margin: 0 auto;
				text-align: center;
				.MultipleChoice-option { 
					display: inline-block;
					width: 6.5em;
					height: 9.34em;
					margin-bottom: 1em;
					&.position-1,
					&.position-3 {
						margin-right: 1.5em;
					}
					span:nth-child(2) {
						bottom: calc(50% - 1.25em);
						left: calc(100% - 1.5em);
					}
					&.option-1 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/produce-2-not-in-cooler/option-1.svg');
						background-size: auto 8.95em;
					}
					&.option-2 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/produce-2-not-in-cooler/option-2.svg');
						background-size: auto 5.44em;
					}
					&.option-3 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/produce-2-not-in-cooler/option-3.svg');
						background-size: auto 4.38em;
					}
					&.option-4 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/produce-2-not-in-cooler/option-4.svg');
						background-size: auto 100%;
					}
				}
			}
		}			
	}
	&.produce-2-holiday-produce {
		.MultipleChoice-options {
			margin-top: 1em;
			padding-bottom: 1em;
			.MultipleChoice-optionsWrap {
				height: 24.4em;				
				padding: 0 1.5em;
				.MultipleChoice-option { 
					display: inline-block;
					width: 8em;
					height: 8em;
					span:nth-child(2) {
						bottom: 0.5em;
						right: 0.75em;
						left: auto;
						width: 1.39em;
						height: 1.39em;
					}
					&.position-1,
					&.position-3 {margin-right: calc(100% - 16em);}

					&.position-1,
					&.position-2 {margin-bottom: -1em;}
					
					&.position-4,
					&.position-5 {margin-top: -2em;}
					&.option-1 {
						background-size: 7em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/produce-2-holiday-produce/option-1.svg');
					}
					&.option-2 {
						background-size: 5em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/produce-2-holiday-produce/option-2.svg');
					}
					&.option-3 {
						background-size: 6em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/produce-2-holiday-produce/option-3.svg');
					}
					&.option-4 {
						background-size: 5em auto;		
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/produce-2-holiday-produce/option-4.svg');
					}
					&.option-5 {
						background-size: 6em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/produce-2-holiday-produce/option-5.svg');
					}
					&.option-6 {
						background-size: 6em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/produce-2-holiday-produce/option-6.svg');
					}
					&.option-7 {
						background-size: 4.5em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/produce-2-holiday-produce/option-7.svg');
					}
					&.option-8 {
						background-size: 6.5em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/produce-2-holiday-produce/option-8.svg');
					}
				}
			}
		}
	}
}