@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';
@import '../../../../styles/mixins_backgrounds';
@import '../../../../styles/animations';
@import '../../../../styles/animations-intro';
.MultipleChoice {
	position: relative;
	height: 100%;
	padding-top: 2em;
	padding-bottom: 1em;
	overflow: hidden;
	@include grey-background();
	&.landscape {@include grey-landscape-background();}
		
	.MultipleChoice-intro {
		position: relative;
		width: 17.5em;
		margin: 2em auto auto auto;
		z-index: 2;
	}

	.MultipleChoice-options {
		position: absolute;
		width: 20.5em;
		left: calc(50% - 10.25em);
		margin: 0.75em auto auto auto;
		padding: 0 1.5em;
		overflow: auto;
		z-index: 1;
		@include hide-scrollbar();
		.MultipleChoice-optionsWrap {
			position: relative;			
			.MultipleChoice-option {
				position: relative;
				width: 100%;
				min-height: 2.25em;
				line-height: 1.25em;
				border-radius: 0.55em;
				font-family: 'Source Sans Pro', sans-serif;
				font-weight: bold;
				text-align: center;
				padding: 0.75em 1.25em 0.5em 1.25em;
				margin-bottom: 1.25em;
				cursor: pointer;
				@include blue-button();
				@include flex('center', 'center');
				@include no-select();
				&.animateCorrect {@include animateCorrect();}
				&.animateWrong {@include animateWrong();}
				&.disabled {cursor: not-allowed;}
			}
		}	
	}

	&:not(.images) {
		.MultipleChoice-options .MultipleChoice-optionsWrap {
			.MultipleChoice-option {
				&.selected {@include green-button();}
				&.correct,
				&.animateCorrect {@include green-button();}
				&.wrong,
				&.animateWrong {@include red-button();}
				&.completed {
					@include grey-button();
					cursor: not-allowed;
				}
			}
		}
	}

	&.images {
		.MultipleChoice-options {
			padding: 0;
			.MultipleChoice-optionsWrap {
				.MultipleChoice-option { 
					min-height: auto;
					background: none;
					background-size: contain;
					background-position: center center;
					background-repeat: no-repeat;
					border: none;
					margin: 0;
					padding: 0;
					border-radius: 0;
					@include box-shadow(0, 0, 0, 0, transparent);
					span {
						display: none;
						&:nth-child(2) {
							position: absolute;
							bottom: 0.25em;
							left: 0.25em;
							width: 1.5em;
							height: 1.5em;
						}
					}
					&.selected,
					&.correct,
					&.animateCorrect,
					&.wrong,
					&.animateWrong {
						border: none;	
						@include box-shadow(0, 0, 0, 0, transparent);
						span:nth-child(2) {display: block;}
					}
					&.correct,
					&.animateCorrect {
						span:nth-child(2) {
							background-image: url('../../../../assets/images/modules/icon-check2.svg');
						}
					}
					&.wrong,
					&.animateWrong {
						span:nth-child(2) {
							background-image: url('../../../../assets/images/modules/icon-error.svg');
						}
					}
				}
			}
		}
	}

	&.high-five {
		.MultipleChoice-options {
			position: absolute;
			width: 18em;
			left: calc(50% - 9em);
			margin-top: 1em;
			bottom: 0;
			.MultipleChoice-optionsWrap {
				position: relative;
				width: 100%;
				height: 100%;
				min-height: 23.5em;
				.MultipleChoice-manager {
					position: absolute;
					bottom: 0;
					margin: 0 auto;
				}
				.MultipleChoice-option {
					position: relative; 
					width: 100%;	
					&.selected,
					&.correct,
					&.animateCorrect,
					&.wrong,
					&.animateWrong {@include noAnimation();}
					&.animateCorrect {
						&::after {
							content: '';
							position: absolute;
							top: 0;
							left: 0;
							width: 100%;
							height: 100%;
							background-image: url('../../../../assets/images/modules/tasks/multiple-choice/high-five/icon-pow.svg');
							background-size: 3.5em auto;
							background-position: calc(50% + 0.2em) center;
							@include scale(0);
							@include animatePow();
						}
					}
					&.option-1 {
						overflow: visible;
						position: absolute;
						bottom: 13.25em;
						right: 1em;
						height: 5em;
						width: 5em;
						z-index: 2;
						span {
							&:nth-child(2) {
								left: 2em;
								top: 2.25em;
							}
						}
					}
					&.option-2 {
						z-index: 1;
						height: 100%;
						span {
							&:nth-child(2) {
								display: none;
							}
						}
					}
				}

				&.manager-e,
				&.manager-f,
				&.manager-g,
				&.manager-h {
					.MultipleChoice-option.option-1 {
						right: 1.5em;
						bottom: 12.5em;
					}
				}
			}
		}
	}
}

@import 'areas/5s.scss';
@import 'areas/bagger.scss';
@import 'areas/bakery.scss';
@import 'areas/cashier.scss';
@import 'areas/center-store.scss';
@import 'areas/cleaning.scss';
@import 'areas/cooler-freezer.scss';
@import 'areas/customer-service.scss';
@import 'areas/daily-guidelines.scss';
@import 'areas/deli.scss';
@import 'areas/floral.scss';
@import 'areas/food-safety.scss';
@import 'areas/meat.scss';
@import 'areas/orientation.scss';
@import 'areas/produce.scss';
@import 'areas/safety.scss';
@import 'areas/seafood.scss';
@import 'areas/shrink.scss';