@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/mixins_backgrounds';
@import '../../../styles/animations';
@import '../../../styles/animations-intro';
.FacilitatorMessages {
	position: relative;
	min-height: 100%;

	.FacilitatorMessages-header {
		@include flex('center', 'flex-end');

		.FacilitatorMessages-addMessageBtn {
			width: 15em;
		}
	}

	.FacilitatorMessages-messages {
		width: 72em;
		background-color: white;
		border-radius: 0.83em;
		margin-bottom: 2em;
		padding-top: 2em;
		.FacilitatorMessages-message {
			display: inline-block;
			vertical-align: top;
			width: 35em;
			margin-bottom: 2em;
			&:nth-child(odd) {margin-right: 2em;}
			.FacilitatorMessages-messageHeader {
				position: relative;
				height: 2.8em;
				background-color: #11466B;
				border-top-left-radius: 0.83em;
				border-top-right-radius: 0.83em;
				padding: 0 1em;
				@include flex('space-between', 'center');
				span {
					font-size: 1.1em;
					font-weight: 600;
					color: white;
				}
			}
			.FacilitatorMessages-messageBody {
				position: relative;
				padding: 1.25em 1em 4em 1em;
				border: 0.1em solid #11466B;
				border-bottom-left-radius: 0.83em;
				border-bottom-right-radius: 0.83em;
				.FacilitatorMessages-departments {
					background-image: url('../../../assets/images/facilitator/icon-players-2-blue.svg');
					background-position: left center;
					padding-left: 2.5em;
					margin-bottom: 1em;
					white-space: nowrap;
					overflow: hidden;
					span {
						color: #11466B;
						font-weight: 600;
					}
				}
			.FacilitatorMessages-recipients {
					background-image: url('../../../assets/images/facilitator/icon-eye.svg');
					background-position: left center;
					padding-left: 2.5em;
					margin-bottom: 1em;
					span {
						color: #11466B;
						font-weight: 600;
					}
				}
				.FacilitatorMessages-text {
					position: relative;
					max-height: 8.5em;
					text-align: left;
					overflow: auto;
					background-image: url('../../../assets/images/facilitator/icon-megafon.svg');
					background-position: left top;
					background-size: auto 1.5em;
					padding-left: 2.5em;
					@include custom-scrollbar(0.75em,#11466B, white);
					p {
						color: #11466B;
						font-weight: 600;
						&:first-of-type {
							margin-top: 0;
						}
						&:last-of-type {
							margin-bottom: 0;
						}
					}
				}
				.FacilitatorMessages-deleteBtn,
				.FacilitatorMessages-editBtn {
					position: absolute;
					left: 1em;
					bottom: 1em;
					display: inline-block;
					margin-top: 1.25em;
					cursor: pointer;
					span {
						color: #11466B;
						font-weight: 500;
						text-decoration: underline;
					}
				}
				.FacilitatorMessages-editBtn {
					margin-left: 4em;
				}
	
				
			}
		}
		
	}
}
