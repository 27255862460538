@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';
@import '../../../../styles/mixins_backgrounds';
@import '../../../../styles/animations';
@import '../../../../styles/animations-intro';
.OrganizeDndContainer {
	position: relative;
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	&.all {height: auto;}

	&.safety-1-gloves,
	&.backroom-5-s-organize-shelves,
	&.bakery-1-donuts,
	&.bakery-1-cupcakes,
	&.orientation-1-restock-bread,
	&.floral-2-bouquets,
	&.meat-1-match-with-animal,
	&.meat-2-meat-case,
	&.produce-1-cool-racks,
	&.produce-1-dry-table,
	&.produce-2-cut-fruit,
	&.seafood-1-bad-shellfish,
	&.seafood-1-seafood-pallet {
		&.initial.empty {
			margin: 0.5em 0;
			height: calc(100% - 1em);
			background-color: #EDEDED;
			border: 1px solid #D9D9D9;
			border-radius: 0.56em;
		}
	}
	&.safety-1-gloves,
	&.backroom-5-s-organize-shelves,
	&.bakery-1-donuts,
	&.bakery-1-cupcakes,
	&.floral-2-bouquets,
	&.meat-1-match-with-animal,
	&.produce-1-cool-racks,
	&.produce-1-dry-table,
	&.produce-2-cut-fruit,
	&.safety-1-gloves,
	&.seafood-1-bad-shellfish,
	&.seafood-1-seafood-pallet {
		&.initial.empty {
			margin: 0.5em 0.5em;
			width: calc(100% - 1em);
		}
	}

	&.deli-1-salad-bar {
		&.initial {
			background-image: url('../../../../assets/images/modules/tasks/organize/deli-1-salad-bar/item-empty.svg');
			background-size: 3.65em auto;
			background-position: 0.8em 0.9em;
		}
		&.final {
			background-image: url('../../../../assets/images/modules/tasks/organize/deli-1-salad-bar/container-bg.svg');
			background-size: contain;
			background-position: left top;
			.OrganizeDndItem {
				&::before {
					content: none;
				}
			}
		}
	}

	&.meat-2-meat-case {
		&.initial {
			@include flex('center', 'center');
		}
	}


	&.produce-2-cut-fruit.final {
		@include flex('center', 'center');
	}
	&.seafood-1-fish-labels {
		&.initial {
			@include flex('center', 'center');
		}
		&.final {
			&.a1 {
				padding: 0.75em 0 0 1em;
			}
			&.a2 {
				padding-top: 0.75em;
				@include flex('center', 'flex-start');
			}
			&.a3 {
				padding: 1em 1em 0 0;
				@include flex('flex-end', 'flex-start');
			}
			&.a4 {
				@include flex('flex-start', 'flex-end');
				padding: 0 0 0.5em 1em;
			}
			&.a5 {
				padding-bottom: 0.75em;
				@include flex('center', 'flex-end');
			}
			&.a6 {
				padding: 0 1em 1em 0;
				@include flex('flex-end', 'flex-end');
			}
		}
	}

	&.seafood-1-seafood-pallet {
		@include flex ('', '', 'row');
		flex-wrap: wrap;

		:nth-child(-n+2) {
			&.final1, &.final2{width: 50%;}
			&.shelves {
				width: 100%;
			}
		}
		&.initial {
			width: 100%;
			@include flex('center', 'center');
		}
	}

	&.backroom-5-s-organize-files.all {
		height: 24.6em;
		background-image: url('../../../../assets/images/modules/tasks/organize/backroom-5-s-organize-files/computer.svg');
		background-size: 29em auto;
		background-position: top center;
		padding: 0em 0.5em 0em 0.5em;

	}

	&.center-store-2-shelf-tags {
		text-align: left;
	}

}
