@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/mixins_backgrounds';
@import '../../../styles/animations';
@import '../../../styles/animations-intro';
.FacilitatorCompetitions {
	position: relative;
	min-height: 100%;

	.FacilitatorCompetitions-header {
		@include flex('center', 'flex-end');

		.FacilitatorCompetitions-addCompetitionBtn {
			width: 15em;
		}
	}

	.FacilitatorCompetitions-competitions {
		padding-top: 2em;
		.FacilitatorCompetitions-competition {
			width: 40em;
			background-color: white;
			border-radius: 0.83em;
			margin-bottom: 2em;
			.FacilitatorCompetitions-competitionHeader {
				position: relative;
				height: 2.8em;
				background-color: #11466B;
				border-top-left-radius: 0.83em;
				border-top-right-radius: 0.83em;
				padding: 0 1em;
				@include flex('space-between', 'center');
				.FacilitatorCompetitions-competitionModule {
					span {
						display: inline-block;
						vertical-align: bottom;
						color: #ffffff;
						font-size: 1.25em;
						font-weight: 600;
						&:nth-of-type(2) {
							margin-left: 0.5em;
							font-size: 1em;
							text-transform: lowercase;
						}
					}
					
				}
				.FacilitatorCompetitions-competitionStatus {
					span {
						color: #D29241;
						font-weight: 600;
					}
				}
			}
			.FacilitatorCompetitions-competitionBody {
				position: relative;
				padding: 1.25em 1em;
				border: 0.1em solid #11466B;
				border-bottom-left-radius: 0.83em;
				border-bottom-right-radius: 0.83em;
				@include flex('space-between', 'flex-start');
				.FacilitatorCompetitions-competitionInfo {
					position: relative;
					width: 50%;
					height: 8.5em;
					.FacilitatorCompetitions-competitionDates {
						background-image: url('../../../assets/images/facilitator/icon-calendar-blue.svg');
						background-position: left center;
						padding-left: 2em;
						margin-bottom: 1em;
						span {
							color: #11466B;
							font-weight: 600;
						}
					}
					.FacilitatorCompetitions-competitionPlayers {
						background-image: url('../../../assets/images/facilitator/icon-players-2-blue.svg');
						background-position: left center;
						padding-left: 2em;
						margin-bottom: 1em;
						span {
							color: #11466B;
							font-weight: 600;
						}
					}
					.FacilitatorCompetitions-viewTasksBtn {
						background-image: url('../../../assets/images/facilitator/icon-eye.svg');
						background-size: auto 90%;
						background-position: left center;
						padding-left: 2em;
						cursor: pointer;
						span {
							color: #11466B;
							font-weight: 600;
						}
					}
					.FacilitatorCompetitions-deleteBtn,
					.FacilitatorCompetitions-editBtn {
						position: absolute;
						left: 0em;
						bottom: 0;
						display: inline-block;
						margin-top: 1.25em;
						cursor: pointer;
						span {
							color: #11466B;
							font-weight: 500;
							text-decoration: underline;
						}
					}
					.FacilitatorCompetitions-editBtn {
						margin-left: 4em;
					}
					.FacilitatorCompetitions-competitionWinnerBtn {
						position: absolute;
						bottom: 0em;
						right: 1em;
						width: 8.25em;
						height: 2.25em;
						background: #EDEDED;
						color: #7C7C7C;
						border-radius: 0.39em;
						cursor: not-allowed;
						@include flex('center', 'center');
						span {
							font-size: 1.11em;
							font-weight: 700;
						}


						&.finished {
							color: white;
							background: #E8B341;	
							cursor: pointer;
							&:hover {
								background: darken(#E8B341, 3%);
							}
						}
					}
					.FacilitatorCompetitions-competitionWinner {
						position: absolute;
						bottom: 0em;
						right: 1em;
						text-align: right;
						span {
							display: block;
							color: #11466B;
							&:last-of-type {
								font-size: 1.11em;
								font-weight: 600;
							}
						}
					}

					.FacilitatorCompetitions-goalStatus {
						position: absolute;
						bottom: 0em;
						right: 1em;
						width: 8.25em;
						height: 2.25em;
						background-color: #EDEDED;
						border: 0.1em solid #E8B341;
						border-radius: 0.39em;
						&.success {
							border-color: #159F90;
							.FacilitatorCompetitions-goalStatusTrack {
								background-color: #159F90;
							}
						}
						&.fail {
							border-color: #B0292B;
							.FacilitatorCompetitions-goalStatusTrack {
								background-color: #B0292B;
							}
						}
		

						.FacilitatorCompetitions-goalStatusTrack {
							position: absolute;
							top: 0;
							left: 0;
							width: 0%;
							height: 100%;
							background: #E8B341;

						}
						.FacilitatorCompetitions-goalStatusLabel {
							position: absolute;
							top: 0;
							left: 0;
							width: 100%;
							height: 100%;
							@include flex('center', 'center');								
							span {
								font-size: 1.11em;
								font-weight: 700;
							}
						}
					}
				}
				.FacilitatorCompetitions-competitionDescriptionAndPrize {
					position: relative;
					width: 50%;
					height: 8.5em;
					border-left: 1px solid #11466B;
					text-align: left;
					padding: 0 1em;
					overflow: auto;
					@include custom-scrollbar(0.75em,#11466B, white);
					p {
						color: #11466B;
					}
					div:first-of-type p {
						margin-top: 0;
					}
					div:last-of-type p {
						margin-bottom: 0;
					}
				}
			}
		}
	}
}