@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/mixins_backgrounds';
@import '../../../styles/animations';
@import '../../../styles/animations-intro';
.FacilitatorSelectStore {
	position: relative;
	min-height: 100%;
	.FacilitatorSelectStore-title {
		margin-bottom: 1em;
		span {
			font-size: 1.5em;
			font-weight: bold;
			color: #10779E;
		}
	}

	.FacilitatorSelectStore-noStores {
		font-weight: bold;
	}

	.FacilitatorSelectStore-store {
		position: relative;
		width: 28em;
		height: 7.5em;
		margin-bottom: 2em;
		cursor: pointer;
		@include flex('flex-start', 'center');
		.FacilitatorSelectStore-storeInfo {
			width: calc(100% - 6em);
			margin-left: 6em;
			color: white;
			background-color: #10779E;
			border-radius: 0.44em;
			padding: 0.25em 0.75em 0.25em 2em;
			@include box-shadow(0, 0.167em, 0.33em, 0, rgba(black, 0.16));
			> div {
				white-space: nowrap;
				overflow: hidden;
				text-align: left;
				padding: 0.25em 0;
				span {
					display: inline-block;
					font-weight: 600;
					width: 4.5em;
				}
			}
		}
		.FacilitatorSelectStore-storeManager {
			position: absolute;
			top: 0;
			left: 0;
			width: 7.5em;
			height: 7.5em;
			background-color: white;
			border: calc(0.11em + 1px) solid #10779E;
			border-radius: 100%;
			overflow: hidden;
		}
	}
}