@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';
@import '../../../../styles/mixins_backgrounds';
@import '../../../../styles/animations';
@import '../../../../styles/animations-intro';
.BranchingStory {
	position: relative;
	height: 100%;
	padding-top: 4em;
	overflow: hidden;
	@include grey-background();

	.BranchingStory-story {
		height: 100%;
		overflow: scroll;
		scroll-behavior: smooth;
		-webkit-overflow-scrolling: touch;

		.BranchingStory-step {
			position: relative;
			&.fadeIn {@include fadeIn(0.5s, 'ease', 0.2s);}
			&.arrow {
				margin-bottom: 2.5em;
				&::after {
					content: '';
					position: absolute;
					top: calc(100% + 0.5em);
					left: calc(50% - 10em);
					width: 20em;
					height: 1.5em;
					background-image: url('../../../../assets/images/modules/tasks/branching-story/icon-arrow-down.svg');
				}
			}
			&.fullFadeOut {
				&.arrow::after {
					@include opacity(0.6);
				}
			}
			&.isFacilitator {
				pointer-events: none;
			}
		}
	}
	.BranchingStory-nextBtn {
		position: absolute;
		right: 0;
		bottom: 0;
	}

	&.landscape {
		.BranchingStory-nextBtn {right: calc(50% - 13.5em);}
		@include grey-landscape-background();
		&.bagger {@include bagger-grey-landscape-background();}
		&.bakery {@include bakery-grey-landscape-background();}
		&.cashier {@include cashier-grey-landscape-background();}
		&.center-store {@include center-store-grey-landscape-background();}
		&.customer-service {@include customer-service-grey-landscape-background();}
		&.deli {@include deli-grey-landscape-background();}
		&.orientation {@include orientation-grey-landscape-background();}
		&.floral {@include floral-grey-landscape-background();}
		&.meat {@include meat-grey-landscape-background();}
		&.produce {@include produce-grey-landscape-background();}
		&.seafood {@include seafood-grey-landscape-background();}
	}
}