@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/mixins_backgrounds';
@import '../../../styles/animations';
@import '../../../styles/animations-intro';
.PopupAddBadge {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
	background-color: rgba(#092335, 0.6);
	width: 100%;
	height: 100%;
	@include flex('center', 'center');
	.PopupAddBadge-content {
		padding: 1em;
		width: 17.22em;
		height: 26.83em;
		background-color: #F5F5F5;
		border-radius: 0.5em;
		

		.PopupAddBadge-contentTitle {
			width: 100%;
			height: 1em;
			position: relative;
			@include flex('center', 'center');
			margin-top: 0.5em;
			padding-bottom: 0.74em;
			margin-bottom: 0.9em;
			span {
				border-radius: 0.55em;
				font-weight: bold;
				text-align: center;
				text-decoration: none;
				font-family: "Roboto", sans-serif;
				color: $blue-dark2;
			}
		}
		.PopupAddBadge-badgesWarpper {
			@include flex('flex-start', 'center');
			flex-wrap: wrap;
			flex-direction: row;
			gap: 0.25em;
			width: 15.19em;
			height: 19.87em;
			background-color: white;
			border-radius: 0.55em;
			padding-left: 0.75em;
			.PopupAddBadge-Badge {
				width: 2.5em;
				height: 2.5em;
				position: relative;
				&.selected {
					::after {
						content: '';
						position: absolute;
						left: 1.5em;
						width: 1em;
						height: 1em;
						background-image: url('../../../assets/images/modules/icon-check2.svg');
					}
				}
			}
		}
		.PopupAddBadge-buttonsWrapper {
			@include flex('flex-end', 'space-between');
			height: 1.8em;
			width: 100%;
			padding: 0 2em;
			margin-top: 1em;
			.Button {
				height: 1.8em;
				width: 5.6em;
			}
		}
		.PopupAddBadge-buttonsWrapper .Button:last-child {
			margin-left: 0.555em;						
		}
	}
	
}