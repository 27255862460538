@import 'styles/variables';
@import 'styles/fonts';
@import 'styles/mixins';
@import 'styles/mixins_backgrounds';
@import 'styles/animations';
@import 'styles/animations-intro';
html {
  position: relative;
  font-size: calc(18 * ((100vw / 375)));
  height: 100%;
}

body {
  position: relative;
	height: 100%;
  width:100%;
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 1em;
  font-weight: 500;
  overflow: hidden;
}

#root {
  height: 100%;
  overflow: hidden;
}

*,
*::after,
*::before {
	box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  background-size: contain;
	background-repeat: no-repeat;
  background-position: center center;
}

button {
  background-image: none;
  -webkit-tap-highlight-color: transparent;
  @include no-select();
  &:focus {
    outline: 0;
  }
  &::-moz-focus-inner {
    border: 0;
  }
}

ul, ol {
	padding-inline-start: 0em;
	margin: 0;
}

input,
input:focus,
textarea,
textarea:focus {
  font-size: inherit;
  border-style: none;
  outline: none;
  box-shadow: none;
  @include appearance(none);
}

strong {
  font-weight: 900;
}


// Wide portrait, scale font size with respect to height
@media (orientation: portrait) and (min-aspect-ratio: 9/16) {
  body {
    font-size: calc(18 * ((100vh / 768)));
  }
}


// Landscape up to 16/9
@media (orientation: landscape) and (max-aspect-ratio: 16/9) {
  body {
    font-size: calc(18 * ((100vw / 1363))); //
  }
}

// Landscape wider than 16/9
@media (min-aspect-ratio: 16/9) {
  body {
    font-size: calc(18 * ((100vh / 768)));
  }
}