@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/mixins_backgrounds';
@import '../../styles/animations';
@import '../../styles/animations-intro';
.Facilitator {
	position: relative;
	min-height: 100%;
	font-size: calc(18 * ((100vw / 1920)));
	font-weight: 400;
	background-color: white;
	* {
		font-family: 'Source Sans Pro', sans-serif;
	}
	overflow: auto;

	.Facilitator-header {
		position: relative;
		height: 12.11em;
	}

	.Facilitator-content {
		position: absolute;
		width: 100%;
		top: 12.5em;
		bottom: 0;
		padding: 1.67em 2em;
		overflow: auto;
		@include hide-scrollbar();
	}
	.Facilitator-reportsNotification,
	.Facilitator-competitionNotification {
		position: absolute;
		bottom: 1em;
		right: 2em;
		width: 25em;
		height: 10em;
		border-radius: 0.83em;
		.Facilitator-reportsNotificationHeader,
		.Facilitator-competitionNotificationHeader {
			position: relative;
			width: 100%;
			height: 5em;
			background-color: #11466B;
			border-top-left-radius: 0.83em;
			border-top-right-radius: 0.83em;
			padding: 0 4em;
			@include flex('center', 'center');
			.Facilitator-reportsNotificationTitle,
			.Facilitator-competitionNotificationTitle {
				text-align: center;
				line-height: 1;
				span {
					font-size: 1.2em;
					font-weight: 600;
					color: #ffffff;
				}
			}
			.Facilitator-reportsNotificationCloseBtn,
			.Facilitator-competitionNotificationCloseBtn {
				position: absolute;
				top: 0;
				right: 0;
				width: 2.33em;
				height: 2.33em;
				background-image: url('../../assets/images/facilitator/icon-close.svg');
				background-size: 1.56em auto;
				cursor: pointer;
			}
		}
		.Facilitator-reportsNotificationBody ,
		.Facilitator-competitionNotificationBody {
			position: relative;
			height: calc(100% - 5em);
			background-color: white;
			border: 0.1em solid #11466B;
			border-bottom-left-radius: 0.83em;
			border-bottom-right-radius: 0.83em;
			@include flex('center', 'center');
			.Facilitator-reportsNotificationBtn,
			.Facilitator-competitionNotificationBtn {
				width: 18.25em;
			}
		}
	}

	.Facilitator-reportsNotification {
		position: absolute;
		bottom: 1em;
		right: 30em;
	}

	.Facilitator-popupOverlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(#7ABCEA, 0.81);
		@include flex('center', 'center');
	}
}

// @media (orientation: portrait) and (min-aspect-ratio: 375/667) {
//   .FacilitatorOverview {
//     min-height: calc(177.5vw);
// 		&.map,
// 		&.splash {
// 			min-height: 100%;
// 		}
//   }
// }