@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/mixins_backgrounds';
@import '../../styles/animations';
@import '../../styles/animations-intro';
.Login {
	position: relative;
	height: 100%;
	background-image: linear-gradient(#10779E, #ADE9FF);
	background-size: 100% 100%;
	@include login-background();
	&.landscape {
		.Login-logo {
			top: 3.83em;
		}
	}

	.Login-logo {
		position: absolute;
		width: 100%;
		left: 0;
		top: 1.72em;
		z-index: 1;
	}

	.Login-buttons {
		position: absolute;
		bottom: 1em;
		right: calc(50% - 3.77em);
		width: 7em;
		z-index: 3;
		.Login-signupBtn {margin-top: 0.75em;}
	}
}