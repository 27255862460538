/* No animation */
@mixin noAnimation() {
  -webkit-animation: none;
  animation: none;
}


@mixin animateCorrect() {
  -webkit-animation: bounce 0.5s cubic-bezier(0.280, 0.840, 0.420, 1) 0s 1 forwards;
  -webkit-animation-fill-mode: forwards;
  animation: bounce 0.5s cubic-bezier(0.280, 0.840, 0.420, 1) 0s 1 forwards;
  animation-fill-mode: forwards;
}

@mixin animateWrong() {
  -webkit-animation: wobble 0.5s cubic-bezier(0.280, 0.840, 0.420, 1) 0s 1 forwards;
  -webkit-animation-fill-mode: forwards;
  animation: wobble 0.5s cubic-bezier(0.280, 0.840, 0.420, 1) 0s 1 forwards;
  animation-fill-mode: forwards;
}

@mixin animatePow($seconds: '1s', $delay: '0s') {
  -webkit-animation: pow $seconds cubic-bezier(0.280, 0.840, 0.420, 1) $delay 1 forwards;
  animation: pow $seconds cubic-bezier(0.280, 0.840, 0.420, 1) $delay 1 forwards;
}

@mixin animateChop($seconds: '1s', $delay: '0s') {
  -webkit-animation: chop $seconds cubic-bezier(0.280, 0.840, 0.420, 1) $delay 1 forwards;
  animation: chop $seconds cubic-bezier(0.280, 0.840, 0.420, 1) $delay 1 forwards;
}

@mixin fadeIn($seconds: '0.5s', $timing: 'ease', $delay: '0s') {
  @include opacity(0);
  animation: fadeIn $seconds #{$timing} $delay forwards;
  -webkit-animation: fadeIn $seconds #{$timing} $delay forwards;
}

@mixin fadeOut($seconds: '0.5s', $timing: 'ease', $delay: '0s') {
  @include opacity(1);
  -webkit-animation: fadeOut $seconds #{$timing} $delay forwards;
  animation: fadeOut $seconds #{$timing} $delay forwards;
}

@mixin bounceIn($seconds: 0.5s, $timing: 'ease-out', $delay: 0s) {
  -webkit-animation: bounceIn $seconds #{$timing} $delay forwards;
  animation: bounceIn $seconds #{$timing} $delay forwards;
}

@mixin pulse($seconds: '2s') {
  @include opacity(0.75);
  animation: pulse $seconds infinite;
  -webkit-animation: pulse $seconds infinite;
}

/* Delay button */
@mixin delayButton($seconds: 0.5s, $timing: 'ease', $delay: 0.5s) {
  @include opacity(0);
  pointer-events: none;
  cursor: default;
  animation: delayButton $seconds #{$timing} $delay forwards;
  -webkit-animation: delayButton $seconds #{$timing} $delay forwards;
}
@keyframes delayButton {
	0% {
    @include opacity(0);
    pointer-events: none;
    cursor: default;
  }
  99% {
    @include opacity(1);
    pointer-events: none;
    cursor: default;
  }
	100% {
    @include opacity(1);
    pointer-events: all;
    cursor: pointer;
  }
}
@-webkit-keyframes delayButton {
	0% {
    @include opacity(0);
    pointer-events: none;
    cursor: default;
  }
  99% {
    @include opacity(1);
    pointer-events: none;
    cursor: default;
  }
	100% {
    @include opacity(1);
    pointer-events: all;
    cursor: pointer;
  }
}

/* Loot box */
@mixin animateLootBox1Of1() {
  -webkit-animation: animateLootBox1Of1 0.5s ease 0s forwards;
  animation: animateLootBox1Of1 0.5s ease 0s forwards;
}
@-webkit-keyframes animateLootBox1Of1 {
  0% {@include translate(0, 0);}
  100% {@include translate(0, -1.5em);}
}
@keyframes animateLootBox1Of1 {
  0% {@include translate(0, 0);}
  100% {@include translate(0, -1.5em);}
}
@mixin animateLootBox1Of2() {
  -webkit-animation: animateLootBox1Of2 0.5s ease 0s forwards;
  animation: animateLootBox1Of2 0.5s ease 0s forwards;
}
@-webkit-keyframes animateLootBox1Of2 {
  0% {@include translate(0, 0);}
  33% {@include translate(0, -1.5em);}
  100% {@include translate(-4em, -1.5em);}
}
@keyframes animateLootBox1Of2 {
  0% {@include translate(0, 0);}
  33% {@include translate(0, -1.5em);}
  100% {@include translate(-4em, -1.5em);}
}
@mixin animateLootBox2Of2() {
  -webkit-animation: animateLootBox2Of2 0.5s ease 0s forwards;
  animation: animateLootBox2Of2 0.5s ease 0s forwards;
}
@-webkit-keyframes animateLootBox2Of2 {
  0% {@include translate(0, 0);}
  33% {@include translate(0, -1.5em);}
  100% {@include translate(4em, -1.5em);}
}
@keyframes animateLootBox2Of2 {
  0% {@include translate(0, 0);}
  33% {@include translate(0, -1.5em);}
  100% {@include translate(4em, -1.5em);}
}


@mixin animateRotate($seconds: 1s) {
  animation: animateRotate $seconds linear 0s infinite;
}

@keyframes animateRotate {
  100% { 
    @include rotate(360deg);
  }
} 



@keyframes pulse {
	0% {@include opacity(1);}
  50% {@include opacity(0.1);}
	100% {@include opacity(1);}
}
@-webkit-keyframes pulse {
	0% {@include opacity(1);}
  50% {@include opacity(0.1);}
	100% {@include opacity(1);}
}

@-webkit-keyframes fadeOut {
	0% {@include opacity(1);}
	100% {@include opacity(0);}
}
@keyframes fadeOut {
	0% {@include opacity(1);}
	100% {@include opacity(0);}
}

@-webkit-keyframes fadeIn {
	0% {@include opacity(0);}
	100% {@include opacity(1);}
}
@keyframes fadeIn {
	0% {@include opacity(0);}
	100% {@include opacity(1);}
}

@-webkit-keyframes bounceIn {
  0% {opacity: 0; transform: scale(.3);}
  50% {opacity: 1; transform: scale(1.05);}
  70% {transform: scale(.9); }
  100% {transform: scale(1);}
}
@keyframes bounceIn {
  0% {opacity: 0; transform: scale(.3);}
  50% {opacity: 1; transform: scale(1.05);}
  70% {transform: scale(.9); }
  100% {transform: scale(1);}
}

@-webkit-keyframes pow {
	0% {@include opacity(1); @include scale(0);}
  20% {@include scale(1.1);}
	30% {@include scale(0.8);}
  40% {@include scale(1);}
  99% {@include scale(1); @include opacity(1);}
  100% {@include scale(1); @include opacity(1);}
  100% {@include opacity(0); @include scale(1);}
}

@keyframes pow {
	0% {@include opacity(1); @include scale(0);}
  20% {@include scale(1.1);}
	30% {@include scale(0.8);}
  40% {@include scale(1);}
  99% {@include scale(1); @include opacity(1);}
  100% {@include scale(1); @include opacity(1);}
  100% {@include opacity(0); @include scale(1);}
}

@-webkit-keyframes chop {
	0% {@include opacity(1); @include scale(0);}
  20% {@include scale(1.1);}
	30% {@include scale(0.8);}
  40% {@include scale(1);}
  99% {@include scale(1); @include opacity(1);}
  100% {@include scale(1); @include opacity(1);}
}

@keyframes chop {
	0% {@include opacity(1); @include scale(0);}
  20% {@include scale(1.1);}
	30% {@include scale(0.8);}
  40% {@include scale(1);}
  99% {@include scale(1); @include opacity(1);}
  100% {@include scale(1); @include opacity(1);}
}

@-webkit-keyframes bounce {
	from {
    @include scale(1); 
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
	10% {@include scale(0.9);}
	30% {@include scale(1.05);}
	60% {@include scale(0.95);}
	to {
    @include scale(1); 
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes bounce {
	from {
    @include scale(1); 
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
	10% {@include scale(0.9);}
	30% {@include scale(1.05);}
	60% {@include scale(0.95);}
	to {
    @include scale(1); 
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes wobble {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  15% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -3deg);
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -3deg);
  }

  30% {
    -webkit-transform: translate3d(4%, 0, 0) rotate3d(0, 0, 1, 2deg);
    transform: translate3d(4%, 0, 0) rotate3d(0, 0, 1, 2deg);
  }

  45% {
    -webkit-transform: translate3d(-3%, 0, 0) rotate3d(0, 0, 1, -2deg);
    transform: translate3d(-3%, 0, 0) rotate3d(0, 0, 1, -2deg);
  }

  60% {
    -webkit-transform: translate3d(3%, 0, 0) rotate3d(0, 0, 1, 1deg);
    transform: translate3d(3%, 0, 0) rotate3d(0, 0, 1, 1deg);
  }

  75% {
    -webkit-transform: translate3d(-2%, 0, 0) rotate3d(0, 0, 1, -1deg);
    transform: translate3d(-2%, 0, 0) rotate3d(0, 0, 1, -1deg);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes wobble {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  15% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -3deg);
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -3deg);
  }

  30% {
    -webkit-transform: translate3d(4%, 0, 0) rotate3d(0, 0, 1, 2deg);
    transform: translate3d(4%, 0, 0) rotate3d(0, 0, 1, 2deg);
  }

  45% {
    -webkit-transform: translate3d(-3%, 0, 0) rotate3d(0, 0, 1, -2deg);
    transform: translate3d(-3%, 0, 0) rotate3d(0, 0, 1, -2deg);
  }

  60% {
    -webkit-transform: translate3d(3%, 0, 0) rotate3d(0, 0, 1, 1deg);
    transform: translate3d(3%, 0, 0) rotate3d(0, 0, 1, 1deg);
  }

  75% {
    -webkit-transform: translate3d(-2%, 0, 0) rotate3d(0, 0, 1, -1deg);
    transform: translate3d(-2%, 0, 0) rotate3d(0, 0, 1, -1deg);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideOutToLeft {
  from {@include translate(0);}
  to {@include translate(-100vw);}
}
@-webkit-keyframes slideOutToLeft {
  from {@include translate(0);}
  to {@include translate(-100vw);}
}


@keyframes slideInFromLeft {
  from {@include translate(-100vw);}
  to {@include translate(0);}
}
@-webkit-keyframes slideInFromLeft {
  from {@include translate(-100vw);}
  to {@include translate(0);}
}

@keyframes slideInFromRight {
  from {@include translate(100vw);}
  to {@include translate(0);}
}
@-webkit-keyframes slideInFromRight {
  from {@include translate(100vw);}
  to {@include translate(0);}
}

@keyframes slideOutToRight {
  from {@include translate(0);}
  to {@include translate(100vw);}
}
@-webkit-keyframes slideOutToRight {
  from {@include translate(0);}
  to {@include translate(100vw);}
}


/**
 * ----------------------------------------
 * animation jello-horizontal
 * ----------------------------------------
 */
 @-webkit-keyframes jello-horizontal {
0% {
	-webkit-transform: scale3d(1, 1, 1);
	transform: scale3d(1, 1, 1);
}
30% {
	-webkit-transform: scale3d(1.25, 0.75, 1);
	transform: scale3d(1.25, 0.75, 1);
}
40% {
	-webkit-transform: scale3d(0.75, 1.25, 1);
	transform: scale3d(0.75, 1.25, 1);
}
50% {
	-webkit-transform: scale3d(1.15, 0.85, 1);
	transform: scale3d(1.15, 0.85, 1);
}
65% {
	-webkit-transform: scale3d(0.95, 1.05, 1);
	transform: scale3d(0.95, 1.05, 1);
}
75% {
	-webkit-transform: scale3d(1.05, 0.95, 1);
	transform: scale3d(1.05, 0.95, 1);
}
100% {
	-webkit-transform: scale3d(1, 1, 1);
	transform: scale3d(1, 1, 1);
}
}
@keyframes jello-horizontal {
0% {
	-webkit-transform: scale3d(1, 1, 1);
	transform: scale3d(1, 1, 1);
}
30% {
	-webkit-transform: scale3d(1.25, 0.75, 1);
	transform: scale3d(1.25, 0.75, 1);
}
40% {
	-webkit-transform: scale3d(0.75, 1.25, 1);
	transform: scale3d(0.75, 1.25, 1);
}
50% {
	-webkit-transform: scale3d(1.15, 0.85, 1);
	transform: scale3d(1.15, 0.85, 1);
}
65% {
	-webkit-transform: scale3d(0.95, 1.05, 1);
	transform: scale3d(0.95, 1.05, 1);
}
75% {
	-webkit-transform: scale3d(1.05, 0.95, 1);
	transform: scale3d(1.05, 0.95, 1);
}
100% {
	-webkit-transform: scale3d(1, 1, 1);
	transform: scale3d(1, 1, 1);
}
}

@mixin move-avatar-from-to($seconds: 1s, $delay: 0s, $fromX, $fromY, $toX, $toY) {
  $animation-name: unique-id() !global;

  @-webkit-keyframes #{$animation-name} {
    0% {@include opacity(1); @include translate($fromX, $fromY);}
    49% {@include opacity(0); @include translate($fromX, $fromY);}
    50% {@include opacity(0); @include translate($toX, $toY);}
    100% {@include opacity(1); @include translate($toX, $toY);}
  }
  
  @keyframes #{$animation-name} {
    0% {@include opacity(1); @include translate($fromX, $fromY);}
    49% {@include opacity(0); @include translate($fromX, $fromY);}
    50% {@include opacity(0); @include translate($toX, $toY);}
    100% {@include opacity(1); @include translate($toX, $toY);}
  }

  -webkit-animation: $animation-name $seconds ease-out $delay forwards;
  animation: $animation-name $seconds ease-out $delay forwards;
}