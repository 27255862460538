.MultipleChoice {
	&.floral.landscape {@include floral-grey-landscape-background();}
	
	&.floral-1-ready-time {
		.MultipleChoice-options {
			padding-bottom: 1em;
			.MultipleChoice-optionsWrap {
				position: relative;
				margin-top: 1em;
				text-align: center;
				.MultipleChoice-option {
					width: 7.64em;
					height: 7.23em;
					margin: 1em;
					display: inline-block;
					&.option-1 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/floral-1-ready-time/option-1.svg');
					}
					&.option-2 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/floral-1-ready-time/option-2.svg');
					}
					&.option-3 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/floral-1-ready-time/option-3.svg');
					}
					&.option-4 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/floral-1-ready-time/option-4.svg');
					}
				}
			}
		}


	}


	&.floral-2-clocks {
		.MultipleChoice-options {
			margin-top: 1em;
			padding-bottom: 1em;
			.MultipleChoice-optionsWrap {
				height: 24.4em;	
				padding: 0 1.5em;			
				.MultipleChoice-option { 
					display: inline-block;
					width: 8em;
					height: 8em;
					span:nth-child(2) {
						bottom: 0.5em;
						right: 0.75em;
						left: auto;
						width: 1.39em;
						height: 1.39em;
					}
					&.position-1,
					&.position-3 {margin-right: calc(100% - 16em);}
					&.position-1,
					&.position-2 {margin-bottom: 1em;}
					&.option-1 {
						background-size: 8em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/floral-2-clocks/option-1.svg');
					}
					&.option-2 {
						background-size: 8em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/floral-2-clocks/option-2.svg');
					}
					&.option-3 {
						background-size: 8em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/floral-2-clocks/option-3.svg');
					}
					&.option-4 {
						background-size: 8em auto;		
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/floral-2-clocks/option-4.svg');
					}
				}
			}
		}
	}

	&.floral-2-flowers {
		.MultipleChoice-options {
			margin-top: 1em;
			padding-bottom: 1em;
			.MultipleChoice-optionsWrap {
				width: 15em;			
				margin: 0 auto;	
				.MultipleChoice-option { 
					display: inline-block;
					margin-right: 1em;
					width: 5em;
					height: 10em;
					span:nth-child(2) {
						position: relative;
						bottom: 0em;
						right: 0.75em;
						left: auto;
						width: 1.39em;
						height: 1.39em;
					}
					&.position-3,
					&.position-6 {margin-right: calc(100% - 16em);}
					&.option-1 {
						background-size: 4em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/floral-2-flowers/option-1.svg');
					}
					&.option-2 {
						background-size: 2em auto;
						width: 3em;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/floral-2-flowers/option-2.svg');
					}
					&.option-3 {
						background-size: 5em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/floral-2-flowers/option-3.svg');
					}
					&.option-4 {
						background-size: 2em auto;	
						width: 3em;	
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/floral-2-flowers/option-4.svg');
					}
					&.option-5 {
						background-size: 5em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/floral-2-flowers/option-5.svg');
					}
					&.option-6 {
						background-size: 2em auto;	
						width: 3em;	
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/floral-2-flowers/option-6.svg');
					}
				}
			}
		}
	}

	&.floral-2-where-roses {
		.MultipleChoice-options {
			margin-top: 1em;
			padding-bottom: 1em;
			.MultipleChoice-optionsWrap {
				width: 15em;
				margin: 0 auto;
				.MultipleChoice-option {
					position: absolute;
					display: inline-block;
					width: 5em;
					height: 11.3em;
					span:nth-child(2) {
						position: relative;
						bottom: 0em;
						right: 0.75em;
						left: auto;
						width: 1.39em;
						height: 1.39em;
					}
					&.position-1,
					&.position-3 {left: 0;}

					&.position-2,
					&.position-4 {right: 0;}
					
					&.position-3,
					&.position-4 {top: 12em;}
					
					&.option-1 {
						background-size: 4.6em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/floral-2-where-roses/option-1.svg');
					}
					&.option-2 {
						background-size: 4.4em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/floral-2-where-roses/option-2.svg');
					}
					&.option-3 {
						background-size: 3.6em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/floral-2-where-roses/option-3.svg');
					}
					&.option-4 {
						background-size: 4em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/floral-2-where-roses/option-4.svg');
					}
				}
			}
		}
	}

	&.floral-1-starting-location {

		.MultipleChoice-options {
			margin-top: -3.2em;
			width: 100%;
			left: 0;
			.MultipleChoice-optionsWrap {
				height: 100%;
				min-height: 31.2em;
				background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/floral-1-starting-location/background.svg'), linear-gradient(rgba(#808285, 0), #808285);;
				background-size: auto 31.2em, 100% 10.5em;
				background-position: top center, top center;
				.MultipleChoice-option { 
					position: absolute;
					span:nth-child(2) {
						bottom: 0.5em;
						left: calc(50% - 0.75em);
						width: 1.39em;
						height: 1.39em;
					}
					&.option-1 {
						top: 18em;
						left: 0;
						width: 100%;
						height: calc(100% - 16em);
						&.selected span:nth-child(2) {
							bottom: auto;
							top: 6em;
							left: calc(50% + 6em);
						}
					}
					&.option-2 {
						top: 11em;
						left: 0;
						height: 7em;
						width: 100%;
						&.selected span:nth-child(2) {
							bottom: auto;
							top: 3.5em;
							left: calc(50% - 8em);
						}
					}
					&.option-3 {
						top: 0em;
						left: 0;
						height: 11em;
						width: 100%;
						&.selected span:nth-child(2) {
							bottom: 4em;
							left: 50%;
						}
					}
					&.selected,
					&.correct,
					&.animateCorrect,
					&.wrong,
					&.animateWrong {@include noAnimation();}
				}
			}
		}	
	}

	&.floral-2-stems {
		.MultipleChoice-options {
			margin-top: 1em;
			padding-bottom: 1em;
			.MultipleChoice-optionsWrap {
				height: 24.4em;
				padding: 0 1.5em;
				@include flex('space-between', 'flex-start');
				.MultipleChoice-option { 
					width: 4.3em;
					height: 22em;
					span:nth-child(2) {
						bottom: 0.5em;
						left: calc(50% - 0.75em);
						width: 1.39em;
						height: 1.39em;
					}
					&.position-1,
					&.position-3,
					&.position-1,
					&.position-2,
					&.option-1 {
						background-size: 4em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/floral-2-stems/option-1.svg');
					}
					&.option-2 {
						background-size: 4em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/floral-2-stems/option-2.svg');
					}
					&.option-3 {
						background-size: 4em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/floral-2-stems/option-3.svg');
					}
					&.option-4 {
						background-size: 4em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/floral-2-stems/option-4.svg');
					}
				}
			}
		}
	}

	&.floral-2-buckets {
		.MultipleChoice-options {
			margin-top: 1em;
			padding-bottom: 1em;
			.MultipleChoice-optionsWrap {
				height: 24.4em;
				@include flex('space-between', '', 'row');
				flex-wrap: wrap;
				padding-left: 3em;
				padding-right: 3em;
				padding-top: 1em;
				.MultipleChoice-option { 
					width: 5.8em;
					height: 6.2em;
					span:nth-child(2) {
						bottom: 0.5em;
						left: calc(50% - 0.75em);
						width: 1.39em;
						height: 1.39em;
					}
					&.position-5 {
						margin-left: 4.5em;
					}
					&.option-1 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/floral-2-buckets/option-1.svg');
					}
					&.option-2 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/floral-2-buckets/option-2.svg');
					}
					&.option-3 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/floral-2-buckets/option-3.svg');
					}
					&.option-4 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/floral-2-buckets/option-4.svg');
					}
					&.option-5 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/floral-2-buckets/option-5.svg');
					}
				}
			}
		}
	}
}