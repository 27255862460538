@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/mixins_backgrounds';
@import '../../styles/animations';
@import '../../styles/animations-intro';
.Game {
	position: relative;
	height: 100%;
	overflow: hidden;
}

// @media (orientation: portrait) and (min-aspect-ratio: 375/667) {
//   .Game {
//     min-height: calc(177.5vw);
// 		&.map,
// 		&.splash {
// 			min-height: 100%;
// 		}
//   }
// }