@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/mixins_backgrounds';
@import '../../styles/animations';
@import '../../styles/animations-intro';
.Loading {
	position: relative;
	height: 100%;
	background-image: linear-gradient(#10779E, #ADE9FF);
	background-size: 100% 100%;
	@include flex('center', 'center');
	.Loading-text {
		color: white;
	}
}
