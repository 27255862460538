@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/mixins_backgrounds';
@import '../../../styles/animations';
@import '../../../styles/animations-intro';
.ProfileProgress {
	.ProfileProgress-title {
		color: #11466B;
		font-weight: 900;
		font-size: 1.56em;	
		text-align: center;
	}
	.ProfileProgress-modules {
		position: absolute;
		top: 7em;
		bottom: 7em;
		left: calc(50% - 9.25em);
		width: 18.5em;	
		overflow: auto;
		@include hide-scrollbar();
		.ProfileProgress-module {
			width: 100%;
			margin-bottom: 1em;
			background-color: #F5F5F5;
			border-radius: 1em;
			@include opacity(0.5);
			.ProfileProgress-moduleHeader {
				width: 100%;
				height: 2em;
				border-top-left-radius: 1em;
				border-top-right-radius: 1em;
				padding-left: 1em;
				background-color: #10779E;
				@include flex('flex-start', 'center');
				span {
					color: white;
					font-weight: 700;
				}
			}
			.ProfileProgress-moduleBody {
				padding: 0 1em 0.24em 1em;
				.ProfileProgress-moduleRow {
					height: 1.5em;
					@include flex('space-between', 'center');
					&:first-of-type {border-bottom: 1px solid #D9D9D9;}
					span {
						font-size: 0.85em;
						font-weight: 500;
						color: #11466B;
						&:last-of-type {font-weight: 900;}
					}
				}
			}
			
			&.locked {
				.ProfileProgress-moduleHeader {
					background-image: url('../../../assets/images/profile/icon-lock.svg');
					background-size: auto 1em;
					background-position: right 1em center;
				}
			}
			&.started:not(.locked) {@include opacity(1);}
		}
	}
}
