@import '../../../../../styles/variables';
@import '../../../../../styles/fonts';
@import '../../../../../styles/mixins';
@import '../../../../../styles/mixins_backgrounds';
@import '../../../../../styles/animations';
@import '../../../../../styles/animations-intro';
.AdminGamePopupInfo {
	position: relative;
	padding: 1.5em 2.5em 0em 2.5em;
	height: 100%;
	.AdminGamePopupInfo-infoBlock {
		width: 25em;
		display: inline-block;
		vertical-align: top;
		
		.AdminGamePopupInfo-infoBlockTitle {
			span {
				color: #10779E;
				font-size: 1.44em;
				font-weight: 600;
			}
		}
		.AdminGamePopupInfo-infoBlockRow {
			span {
				color: #000000;
				font-size: 1.25em;
				font-weight: 600;
			}
			a {
				color: #000000;
			}
		}
		&.store {
			.AdminGamePopupInfo-infoBlockRow {
				width: 20em;
				input {
					width: 100%;
					color: #000000;
					font-size: 1.25em;
					font-weight: 600;
				}
			}	
		}

		.AdminGamePopupInfo-gameTags {
			margin-top: 1em;
			width: 23.5em;
			.AdminGamePopupInfo-gameTag {
				position: relative;
				height: 2em;
				@include flex('space-between', 'center');
				.AdminGamePopupInfo-gameTagValue {
					width: 17.5em;
					height: 1.5em;
					span {
						font-size: 1.25em;
						font-weight: 600;
					}
				}
				.AdminGamePopupInfo-gameTagRemoveBtn {
					width: 4em;
					height: 1.5em;
				}
			}
		}
	}



	.AdminGamePopupInfo-facilitators {
		position: absolute;
		left: 2.5em;
		bottom: 0;
		width: 20em;
		height: 22.5em;
		border-top-left-radius: 0.83em;
		border-top-right-radius: 0.83em;
		@include box-shadow(0.56em, 0, 0.26em, 0, rgba(#000000, 0.16));
		.AdminGamePopupInfo-facilitatorsHeader {
			height: 3.4em;
			background-color: #11466B;
			border-top-left-radius: 0.83em;
			border-top-right-radius: 0.83em;
			padding-left: 0.75em;
			@include flex('flex-start', 'center');
			.AdminGamePopupInfo-facilitatorsTitle {
				span {
					color: #ffffff;
					font-size: 1.44em;
					font-weight: 600;
				}
			}
			.AdminGamePopupInfo-addFacilitatorBtn {
				position: absolute;
				top: 0.5em;
				right: 0.67em;
				width: 8.44em;
				height: 2em;
			}
		}
		.AdminGamePopupInfo-facilitatorsBody {
			min-height: 16.25em;
			max-height: 18em;
			margin: 0.2em 0.2em 0.2em 0;
			overflow: auto;
			@include custom-scrollbar(0.75em,#11466B, white);
			.AdminGamePopupInfo-facilitatorRow {
				position: relative;
				height: 3.25em;
				&:nth-child(odd) {background-color: #EDEDED;}
				@include flex('center', 'flex-start', 'column');
				.AdminGamePopupInfo-facilitatorName,
				.AdminGamePopupInfo-facilitatorEmail {
					padding-left: 1em;
					width: 14em;
					overflow: hidden;
					white-space: nowrap;
					span {
						color: #000000;
						font-size: 1.11em;
						font-weight: 600;
					}
					&.invited span {
						font-weight: 400;
					}
				}
				.AdminGamePopupInfo-facilitatorDeleteBtn {
					position: absolute;
					top: 0.8em;
					right: 0.5em;
					width: 4em;
					height: 1.5em;
				}
			}
		}
	}

	.AdminGamePopup-deleteGameBtn {
		position: absolute;
		left: 52.5em;
		bottom: 6em;
		width: 9em;
		height: 2.75em;
	}
}