@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';
@import '../../../../styles/mixins_backgrounds';
@import '../../../../styles/animations';
@import '../../../../styles/animations-intro';
.FacilitatorPlayerPopup {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(#7ABCEA, 0.81);
	@include flex('center', 'center');

	.FacilitatorPlayerPopup-closeBtn {
		padding: 0em;
		width: 2em;
		height: 2em;
		background-image: url('../../../../assets/images/facilitator/icon-close.svg');
		background-size: 1.75em auto;
		cursor: pointer;
	}

	.FacilitatorPlayerPopup-content {
		width: 50em;
		min-height: 14em;
		background-color: white;
		border-radius: 0.83em;

		.FacilitatorPlayerPopup-header {
			position: relative;
			width: 100%;
			height: 7.5em;
			background-color: #11466B;
			border-top-left-radius: 0.83em;
			border-top-right-radius: 0.83em;
			border: 0.25em solid white;
			border-bottom-width: 0em;
			color: white;
			font-weight: bold;
			padding: 0.25em;
			.FacilitatorPlayerPopup-headerRow {
				position: relative;
				width: 100%;
				height: 100%;
				@include flex('space-between', 'space-between');
				.FacilitatorPlayerPopup-headerColumn {
					position: relative;
					width: 16.33em;
					height: 100%;
					&:nth-child(3) {
						@include flex('flex-start', 'flex-end', 'column');
					}
					.FacilitatorPlayerPopup-playerInfo {
						span {
							display: block;
							&:nth-child(2) {
								font-weight: normal;
							}
						}
					}
					.FacilitatorPlayerPopup-avatar {
						@include flex('center');
						margin: 0.25em 0em;
					}
					.FacilitatorPlayerPopup-lastLogin,
					.FacilitatorPlayerPopup-completion {
						position: absolute;
						bottom: 0em;
					}
					.FacilitatorPlayerPopup-completion {
						width: 100%;
						text-align: center;
					}
					.FacilitatorPlayerPopup-closeBtn {
						position: absolute;
						top: 0em;
						right: 0em;
					}
					.FacilitatorPlayerPopup-selectedModule {
						position: absolute;
						bottom: 0;
						.FacilitatorPlayerPopup-dropdownLabel {
							margin-bottom: 0.2em;
							text-align: right;
							span {
								font-weight: normal;
							}
						}
						.FacilitatorPlayerPopup-dropdown {
							width: 13.5em;
						}
					}
				}
			}
		}

		.FacilitatorPlayerPopup-generalModuleStats {
			@include flex('space-between','center');
			background-color: white;
			height: 4em;
			.FacilitatorPlayerPopup-moduleStars {
				@include flex('flex-start','center');
				width: 20em;
				.FacilitatorPlayerPopup-title {
					padding-right: 0.5em;
					padding-left: 0.75em;
					color: #10779E;
					font-weight: bold;
					span {
						font-size: 1.5em;
					}
				}
				.FacilitatorPlayerPopup-stars {
					width: 4.6em;
					@include flex('space-between', 'center')
				}
			}
			.FacilitatorPlayerPopup-textWrapper {
				width: 14.75em;
				height: 100%;
				font-weight: bold;
				@include flex('center','center');
				
				.FacilitatorPlayerPopup-title {
					color: #10779E;
					padding-right: 0.5em;
				}

				.FacilitatorPlayerPopup-text {
					color: #707070;
				}
				&.center {
					border: 1px #A2D0F0 solid;
					border-top: none;
					border-bottom: none;
				}
			}
		}

		.FacilitatorPlayerPopup-recentModuleStats {
			@include flex('space-between','center');
			background-color: white;
			height: 4em;
			border-top: 1px solid #A2D0F0;
			.FacilitatorPlayerPopup-recentModuleStatsColumn {
				width: 12.375em;
				height: 100%;;
				.FacilitatorPlayerPopup-title {
					text-align: center;
					color: #10779E;
					font-weight: bold;
					height: 1.2em;
					span {
						font-size: 0.9em;
					}
				}
				.FacilitatorPlayerPopup-session {
					width: 100%;
					height: 1.2em;
					text-align: center;
					.FacilitatorPlayerPopup-stars {
						width: 4.6em;
						margin: auto;
						@include flex('space-between', 'center');
						@include scale(0.7);
					}
					.FacilitatorPlayerPopup-text {
						width: 100%;
						span {
							font-size: 0.9em;
							color: #707070;
							text-align: center;
						}
						
					}
				}

				&.labels {
					padding-top: 1.2em;
					padding-left: 0.75em;
					.FacilitatorPlayerPopup-sessionLabel {
						height: 1.2em;
						span {
							color: #10779E;
							font-weight: bold;
							font-size: 0.9em;
						}
						
					}
				}
			}
		}

		.FacilitatorPlayerPopup-body {
			margin-top: 0.5em;
			.FacilitatorPlayerPopup-table {
				padding-bottom: 1em;
				.FacilitatorPlayerPopup-tableHeader {
					@include flex('space-between', 'center', 'row');
					background-color: #11466B;
					padding: 0.5em;
					span {
						display: inline-block;
						font-weight: bold;
						color: white;
						&:nth-of-type(2) {
							font-size: 0.8em;
							text-transform: lowercase;
							padding-left: 0.4em;
						}
					}
				}
				.FacilitatorPlayerPopup-tableContent {
					@include flex('', '', 'column');
					overflow: auto;
					height: 19em;
					@include hide-scrollbar();
					.FacilitatorPlayerPopup-row {
						padding: 0.25em 0;
						@include flex('space-between', '', 'row');
						&:nth-child(odd) {background-color: #EDEDED;}
						.FacilitatorPlayerPopup-tableItem {
							&.task {
								padding-left: 0.25em;
							}
							&.view {
								cursor: pointer;
								background-image: url('../../../../assets/images/facilitator/icon-eye.svg');
								background-position: bottom calc(50%) right calc(50%);
								background-size: auto 1.2em;
							}
						}
					}
				}
				.FacilitatorPlayerPopup-tableItem {
					@include flex('center', 'center');
					&.task {
						width: 5em;
						@include flex('flex-start', 'center');
					}
					&.view {
						width: 3em;
						@include flex('flex-end', 'center');
					}
					&.avgMistakes {
						width: 10em;
					}
					&.mistakes {
						width: calc(50% - 9em);
					}
					
				}
			}
		}
	}
}