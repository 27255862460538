/* Colors */
$skin1a: #F4AC88;
$skin1b: #E79165;
$skin2a: #E8AF8E;
$skin2b: #d38e65;
$skin3a: #D49B66;
$skin3b: #be7f44;
$skin4a: #C58E63;
$skin4b: #ac6e3f;
$skin5a: #B87C61;
$skin5b: #a15f40;
$skin6a: #A06C4E;
$skin6b: #885030;
$skin7a: #EAB671;
$skin7b: #d1974b;
$skin8a: #F2A399;
$skin8b: #da7c6f;
$skin9a: #A2B749;
$skin9b: #889d29;
$skin10a: #5BB2A5;
$skin10b: #3a9c8e;



$blue-dark: #11466B;
$blue-dark2: #10476C;