@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/mixins_backgrounds';
@import '../../../styles/animations';
@import '../../../styles/animations-intro';
.Logo {
	width: 100%;
	height: 7.25em;
	background-image: url('../../../assets/images/logo.svg');
	background-size: auto 100%;
	background-position: center center;
}
