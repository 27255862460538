@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';
@import '../../../../styles/mixins_backgrounds';
@import '../../../../styles/animations';
@import '../../../../styles/animations-intro';
.Sort {
	position: relative;
	height: 100%;
	padding-top: 2em;
	padding-bottom: 1em;
	overflow: auto;
	@include hide-scrollbar();
	@include grey-background();
	.Sort-intro {
		position: relative;
		width: 17.5em;
		margin: 2em auto 0.75em auto;
	}

	.Sort-categories {
		width: 17.5em;
		margin: 0 auto auto auto;

		&.shrink-1-math {
			background-image: url(../../../../assets/images/modules/tasks/sort/shrink-1-math/background.svg);
			@include flex('', 'flex-end', 'column');
			width: 10.35em;
			height: 6.11em;
			margin-top: 2.22em;
		}

		.Sort-category {
			margin-bottom: 0.5em;
			border-radius: 1em;
			background-color: rgba(#F5F5F5, 0.5);

			&.shrink-1-math {
				background-color: transparent;
				margin-bottom: 0em;
				.Sort-categoryTitle {
					display: none;
				}
			}

			.Sort-categoryTitle {
				@include flex('center', 'center');
				height: 2em;
				color: white;
				background-color: #10779E;
				border-top-left-radius: 1em;
				border-top-right-radius: 1em;
				span {
					font-size: 0.85em;
					font-weight: 700;
				}
			}
		}
	}
	.Sort-items {
		width: 17.5em;
		margin: 0 auto auto auto;

		&.shrink-1-math {
			margin: 2em auto 0 auto;
			padding-left: 1.8em;
			@include flex('', 'center', 'column');
		}
	}

	&.landscape {
		@include grey-landscape-background();
		&.bagger {@include bagger-grey-landscape-background();}
		&.bakery {@include bakery-grey-landscape-background();}
		&.cashier {@include cashier-grey-landscape-background();}
		&.center-store {@include center-store-grey-landscape-background();}
		&.customer-service {@include customer-service-grey-landscape-background();}
		&.deli {@include deli-grey-landscape-background();}
		&.orientation {@include orientation-grey-landscape-background();}
		&.floral {@include floral-grey-landscape-background();}
		&.meat {@include meat-grey-landscape-background();}
		&.produce {@include produce-grey-landscape-background();}
		&.seafood {@include seafood-grey-landscape-background();}
	}
}