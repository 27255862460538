.MultipleChoice {
	&.cashier.landscape {@include cashier-grey-landscape-background();}
		
	&.cashier-1-checkout-lane {
		.MultipleChoice-options {
			margin-top: 1em;
			padding-bottom: 1em;
			.MultipleChoice-optionsWrap {
				height: 24.4em;			
				padding: 0 0.5em;	
				.MultipleChoice-option { 
					display: inline-block;
					width: 8em;
					height: 8em;
					span:nth-child(2) {
						bottom: 0.5em;
						right: 0.75em;
						left: auto;
						width: 1.39em;
						height: 1.39em;
					}
					&.position-1,
					&.position-3 {margin-right: calc(100% - 18em); margin-left: 1em;}
					&.position-1,
					&.position-2 {margin-bottom: 1em;}
					&.option-1 {
						background-size: 8em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/cashier-1-checkout-lane/option-1.svg');
					}
					&.option-2 {
						background-size: 8em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/cashier-1-checkout-lane/option-2.svg');
					}
					&.option-3 {
						background-size: 8em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/cashier-1-checkout-lane/option-3.svg');
					}
					&.option-4 {
						background-size: 8em auto;		
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/cashier-1-checkout-lane/option-4.svg');
					}
				}
			}
		}
	}

	&.cashier-1-minor-purchase {
		.MultipleChoice-options {
			margin-top: 1em;
			padding-bottom: 1em;
			.MultipleChoice-optionsWrap {
				padding: 0 1.5em;
				height: 19em;				
				.MultipleChoice-option { 
					display: inline-block;
					width: 8em;
					height: 8em;
					span:nth-child(2) {
						bottom: 0.5em;
						right: 0.75em;
						left: auto;
						width: 1.39em;
						height: 1.39em;
					}
					&.position-1,
					&.position-5,
					&.position-3 {margin-right: calc(100% - 16em);}

					&.position-1,
					&.position-2,
					&.position-3,
					&.position-4,
					&.position-5,
					&.position-6 {margin-bottom: -1em; margin-top: -0.5em;}

					&.option-1 {
						background-size: 5em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/cashier-1-minor-purchase/option-1.svg');
					}
					&.option-2 {
						background-size: 5em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/cashier-1-minor-purchase/option-2.svg');
					}
					&.option-3 {
						background-size: 3em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/cashier-1-minor-purchase/option-3.svg');
					}
					&.option-4 {
						background-size: 5em auto;		
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/cashier-1-minor-purchase/option-4.svg');
					}
					&.option-5 {
						background-size: 5em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/cashier-1-minor-purchase/option-5.svg');
					}
					&.option-6 {
						background-size: 7em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/cashier-1-minor-purchase/option-6.svg');
					}
				}
			}
		}
	}
}