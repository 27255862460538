@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/mixins_backgrounds';
@import '../../../styles/animations';
@import '../../../styles/animations-intro';
.NotificationManager {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 2;
	@include fadeIn(0.5s, 'ease', 0.4s);
	
	.NotificationManager-content {
		position: absolute;
		bottom: 4.24em;
		width: 100%;
		left: 0;
		.NotificationManager-text {
			position: absolute;
			left: calc(50% - 8.75em);
			bottom: 1.25em;
			width: 17.5em;
			background-color: #F5F5F5;
			border-radius: 1.1em;
			padding: 1em 1em 1em 2em;
			p {
				margin: 0;
				color: #11466B;
				font-size: 0.89em;
			}
		}
		.NotificationManager-manager {
			position: absolute;
			bottom: 0em;
			left: calc(50% - 9.75em);
			width: 3em;
			height: 3em;
			border-radius: 100%;
			border: calc(0.06em + 1px) solid #10779E;
			background-color: #F5F5F5;
			overflow: hidden;
		}
	}
}
