@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';
@import '../../../../styles/mixins_backgrounds';
@import '../../../../styles/animations';
@import '../../../../styles/animations-intro';
.Results {
	position: relative;
	height: 100%;
	padding: 1em;
	overflow: auto;
	@include orientation-blue-background();

	.Results-background {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(#092335, 0.6);
	}

	.Results-title {
		position: relative;
		text-align: center;
		margin: 0 0 1em 0;
		span {
			display: block;
			font-weight: 900;
			color: #E8B341;
			&:nth-of-type(1) {
				font-size: 1em;
			}	
			&:nth-of-type(2) {
				font-size: 2em;
			}
		}
	}

	.Results-result {
		width: 100%;
		position: relative;
		margin-bottom: 1em;
		.Results-resultLabel {
			text-align: center;
			font-size: 0.889em;
			color: white;
			text-align: center;
		}
		.Results-resultValue {
			margin: auto;
			width: 9.5em;
			height: 2.25em;
			border-radius: 1em;
			color: white;
			background-color: #082940;
			margin-top: 0.25em;
			@include flex('center', 'center');
			span {
				font-size: 1.33em;
				font-weight: 900;
			}
		}
	}
	.Results-buttons {
		margin: 2em auto 0 auto;
		width: 10em;
		.Button {
			margin-top: 1em;
		}
	}
}