@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/mixins_backgrounds';
@import '../../../styles/animations';
@import '../../../styles/animations-intro';
.ProfileBadges {
	height: 100%;
	.ProfileBadges-title {
		color: #11466B;
		font-weight: 900;
		font-size: 1.56em;	
		text-align: center;
	}

	.ProfileBadges-badges {
		position: absolute;
		top: 7em;
		bottom: 7em;
		left: calc(50% - 9.25em);
		width: 18.5em;	
		background-color: #D9C1AB;
		border: 0.389em solid #9C705D;
		overflow: auto;
		@include hide-scrollbar();
		@include box-shadow-inset-outset(
			0.15em, 0.15em, 0.3em, 0, rgba(black, 0.2),
			0.15em, 0.15em, 0.3em, 0, rgba(black, 0.5)
		);


		.ProfileBadges-badgesWrap {
			position: relative;
			width: 100%;
			@include flex('flex-start', 'flex-start');
			flex-flow: row wrap;
			background-image: 
				linear-gradient(to bottom, rgba(#A56E59, 0.42) 50%, transparent 50%), 
				linear-gradient(to bottom, rgba(#A56E59, 0.42) 50%, transparent 50%),
				linear-gradient(to right, rgba(#A56E59, 0.42) 50%, transparent 50%),
				linear-gradient(to right, rgba(#A56E59, 0.42) 50%, transparent 50%),
				linear-gradient(to right, rgba(#A56E59, 0.42) 50%, transparent 50%),
				linear-gradient(to right, rgba(#A56E59, 0.42) 50%, transparent 50%),
				linear-gradient(to right, rgba(#A56E59, 0.42) 50%, transparent 50%),
				linear-gradient(to right, rgba(#A56E59, 0.42) 50%, transparent 50%),
			;
			background-position: 
				left 33.33% top, 
				right 33.33% top, 
				top 7.35em left 0%, 
				top 14.7em left 0%,  
				top 22.05em left 0%,
				top 29.4em left 0%,  
				top 36.75em left 0%,
				top 44.1em left 0%;
			background-repeat: repeat-y, repeat-y, repeat-x, repeat-x, repeat-x, repeat-x, repeat-x, repeat-x;
			background-size: 0.11em 1em, 0.11em 1em, 1em 0.11em, 1em 0.11em, 1em 0.11em, 1em 0.11em, 1em 0.11em, 1em 0.11em;
	
			.ProfileBadges-completionLevel {
				position: absolute;
				top: 0.4em;
				left: 0.6em;
				span {
					color: #8D8989;
					font-size: 0.5em;
					font-weight: 900;
				}
			}
			.ProfileBadges-badge {
				position: relative;
				display: inline-block;
				vertical-align: top;
				width: 33.33%;
				height: 7.35em;
				overflow: hidden;
				@include flex('center', 'center', 'column');
				&:nth-child(2):after {
					content: '';
					position: absolute;
					width: 1em;
					height: 1em;
					top: 0em;
					left: 1.6em;
					background-image: url('../../../assets/images/profile/pin-red.svg');
				}
				&:nth-child(3):after {
					content: '';
					position: absolute;
					width: 100%;
					height: 100%;
					top: 0;
					left: 0;
					background-image: url('../../../assets/images/profile/pin-blue.svg'), url('../../../assets/images/profile/pinned-paper.svg');
					background-size: 0.8em auto, 7em auto;
					background-position: top 0.3em right 0.3em, top 0.3em right -0.7em;
				}
				&:nth-child(9):after {
					content: '';
					position: absolute;
					width: 1em;
					height: 1em;
					top: 0.4em;
					right: 0.2em;
					background-image: url('../../../assets/images/profile/pin-yellow.svg');
				}
				&:nth-child(10):after {
					content: '';
					position: absolute;
					width: 1em;
					height: 1em;
					top: 0.4em;
					left: 0.2em;
					background-image: url('../../../assets/images/profile/pin-blue.svg');
				}
				.ProfileBadges-badgeIcon {
					width: 100%;
					height: 3.75em;
					margin: 0.6em 0 1em 0;
				}
				.ProfileBadges-badgeLabel {
					position: absolute;
					left: 0;
					bottom: 0;
					width: 100%;
					height: 1.825em;
					text-align: center;
					span {
						display: inline-block;
						font-size: 0.72em;
						line-height: 1em;
						font-weight: bold;
						color: #8D8989;
					}
				}
				&.unlocked {
					.ProfileBadges-badgeLabel span {color: #11466B;}
				}
			}
		}
	}
	.ProfileBadges-postits {
		position: absolute;
		top: 6.8em;
		left: calc(50% - 9.75em);
		width: 100%;
		height: 3.25em;
		background-image: url('../../../assets/images/profile/postit-yellow.svg'), url('../../../assets/images/profile/postit-pink.svg');
		background-size: 3.5em auto;
		background-position: top 0.1em left, top 0.05em left 10.75em;		
	}

	.ProfileBadges-popup {
		position: fixed;
		top: calc(50% - 2.75em);
		left: calc(50% - 6.125em);
		width: 13.25em;
		min-height: 5.5em;
		color: #11466B;
		background-color: #F5F5F5;
		border-radius: 1em;
		text-align: center;
		padding: 0.75em 1em;
		.ProfileBadges-popupClose {
			position: absolute;
			top: 0.25em;
			right: 0.5em;
			width: 1em;
			height: 1em;
			background-image: url('../../../assets/images/profile/icon-close.svg');
			cursor: pointer;
		}
		.ProfileBadges-popupTitle {
			font-weight: 700;
			font-size: 1.11em;
			margin-bottom: 0.25em;
		}
		.ProfileBadges-popupText {
			font-weight: 400;
		}
	}
}
