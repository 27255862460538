@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/mixins_backgrounds';
@import '../../../styles/animations';
@import '../../../styles/animations-intro';
.FacilitatorOverview {
	position: relative;
	min-height: 100%;

	.FacilitatorOverview-pages {
		width: 100%;
		min-height: 100%;
		@include flex('flex-start', 'flex-start', 'row');
		align-items: stretch;
		.FacilitatorOverview-page {
			width: 22.5em;
			padding-top: 2.67em;
			padding-left: 2.25em;
			&.training {
				width: 20.25em;
				padding-left: 0em;
			}
			&.retention {
				border-left: 0.11em solid #A2D0F0;
				border-right: 0.11em solid #A2D0F0;
			}
			&.analytics {
				border-right: 0.11em solid #A2D0F0;
			}

			.FacilitatorOverview-subpage {
				width: 18em;
				height: 7.17em;
				border-radius: 0.83em;
				color: white;
				background-color: #10779E;
				margin-bottom: 1.83em;
				overflow: hidden;
				cursor: pointer;
				.FacilitatorOverview-subpageHeader {
					height: 3em;
					padding: 1em;
					border-bottom: 1px solid #A2D0F0;
					font-family: 'Roboto', sans-serif;
					background-position: right 1.35em top 0.72em;
					background-size: auto 1.75em;
					@include flex('flex-start', 'center');
					span {
						font-size: 1.11em;
						font-weight: 700;
					}
				}
				.FacilitatorOverview-subpageBody {
					padding: 0.75em 0.75em 0.75em 1em ;
					p {
						margin: 0;
					}
				}

				&.players .FacilitatorOverview-subpageHeader {
					background-image: url('../../../assets/images/facilitator/icon-players.svg');
				}
				&.game-modules .FacilitatorOverview-subpageHeader {
					background-image: url('../../../assets/images/facilitator/icon-game-modules.svg');
				}
				&.resources .FacilitatorOverview-subpageHeader {
					background-image: url('../../../assets/images/facilitator/icon-resources.svg');
				}
				&.insights .FacilitatorOverview-subpageHeader {
					background-image: url('../../../assets/images/facilitator/icon-insights.svg');
				}
				&.competitions .FacilitatorOverview-subpageHeader {
					background-image: url('../../../assets/images/facilitator/icon-competitions.svg');
				}
				&.messages .FacilitatorOverview-subpageHeader {
					background-image: url('../../../assets/images/facilitator/icon-messages.svg');
				}
				&.statistics .FacilitatorOverview-subpageHeader {
					background-image: url('../../../assets/images/facilitator/icon-statistics.svg');
				}
				&.facilitators .FacilitatorOverview-subpageHeader {
					background-image: url('../../../assets/images/facilitator/icon-players-2.svg');
				}
			}
		}
	}
}