@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';
@import '../../../../styles/mixins_backgrounds';
@import '../../../../styles/animations';
@import '../../../../styles/animations-intro';
.TaskIntro {
	position: relative;
	width: 100%;		
	.TaskIntro-text {
		border-radius: 1.1em;
		color: #11466B;
		background-color: #F5F5F5;
		padding: 1em 1.5em;
		p {
			margin-top: 0;
			font-size: 0.85em;
			&:last-of-type {
				margin-bottom: 0;
			}
		}
		span {
			display: inline-block;
			margin-top: 1em;
			font-weight: 900;
			font-size: 0.85em;
		}
	}

	.TaskIntro-image {
		position: absolute;
		top: 0;
		right: 0;
		width: 100%;
		height: 100%;
	}

	&.apples {
		padding-bottom: 1.2em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/apples.svg');
			background-size: 6em auto;
			background-position: bottom 0em right 1em;
		}
		.TaskIntro-text {
			padding-bottom: 2em;
		}
	}

	&.avatar {
		padding-bottom: 0.75em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/avatar.svg');
			background-size: 4.5em auto;
			background-position: bottom 0em right 1em;
		}
		.TaskIntro-text {
			padding-bottom: 2em;
		}
	}

	&.balloons {
		padding-bottom: 1em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/balloons.svg');
			background-size: 5em auto;
			background-position: bottom 0em right 1em;
		}
		.TaskIntro-text {
			padding-bottom: 1.5em;
		}
	}

	&.bell-pepper {
		padding-bottom: 0.5em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/bell-pepper.svg');
			background-size: 3em auto;
			background-position: bottom 0em right 1em;
		}
		.TaskIntro-text {
			padding-bottom: 0.5em;
		}
	}

	&.berries {
		padding-bottom: 0.5em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/berries.svg');
			background-size: 7em auto;
			background-position: bottom 0em right 1em;
		}
		.TaskIntro-text {
			padding-bottom: 0.5em;
		}
	}

	&.bin-of-watermelons {
		padding-bottom: 1em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/bin-of-watermelons.svg');
			background-size: 5.5em auto;
			background-position: bottom 0.25em right 2em;
		}
		.TaskIntro-text {
			padding-bottom: 1em;
		}
	}

	&.boy-sad {
		padding-bottom: 1em;
		.TaskIntro-text {
			padding-right: 4em;
		}
		.TaskIntro-image {
			height: 4em;
			background-image: url('../../../../assets/images/modules/tasks/task-intro/boy-sad.svg');
			background-size: 3.75em auto;
			background-position: top 0.5em right;
		}
	}

	&.melon {
		padding-bottom: 2em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/melon.svg');
			background-size: 5.5em auto;
			background-position: bottom 0.25em right 1em;
		}
		.TaskIntro-text {
			padding-bottom: 1em;
		}
	}

	&.pear {
		padding-bottom: 2em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/pear.svg');
			background-size: 5.5em auto;
			background-position: bottom 3em center;
		}
		.TaskIntro-text {
			padding-bottom: 12em;
		}
	}
	
	&.bouqet-vase {
		padding-bottom: 1.25em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/bouqet-vase.svg');
			background-size: 4em auto;
			background-position: bottom 0em right 1em;
		}
		.TaskIntro-text {
			padding-bottom: 1em;
			padding-right: 5em;
		}
	}

	&.bucket {
		padding-bottom: 1em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/bucket.svg');
			background-size: 5em auto;
			background-position: bottom 0em right 1em;
		}	
	}

	&.bucket2 {
		padding-bottom: 1.25em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/bucket2.svg');
			background-size: 4em auto;
			background-position: bottom 0em right 1.5em;
		}
	}

	&.bucket3 {
		padding-bottom: 1.25em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/bucket2.svg');
			background-size: 3em auto;
			background-position: bottom 0em right 1.5em;
		}
	}

	&.cake-excessive-icing {
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/cake-excessive-icing.svg');
			background-size: 4em auto;
			background-position: bottom 0.5em right 1.5em;
		}
	}

	&.calendar {
		padding-bottom: 1em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/calendar.svg');
			background-size: 5em auto;
			background-position: bottom 0.25em right 0.75em;
		}
		.TaskIntro-text {
			padding-bottom: 1.5em;
		}		
	}

	&.calendar2,
	&.calendar2b {
		padding-bottom: 1.5em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/calendar2.svg');
			background-size: 5em auto;
			background-position: bottom right 1em;
			float:right;
		}
		.TaskIntro-text {
			padding-right: 1em;
			padding-bottom: 3em;
		}
	}
	&.calendar2b {
		.TaskIntro-image {
			background-position: top 1em right 1em;
		}
		.TaskIntro-text {
			padding-right: 6em;
			padding-bottom: 1em;
		}
	}


	&.calendar3 {
		padding-bottom: 1em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/calendar3.svg');
			background-size: 5em auto;
			background-position: bottom 0.25em right 1em;
		}
		.TaskIntro-text {
			padding-right: 6em;
			padding-bottom: 0.75em;
		}
	}

	&.calendar-april17 {
		padding-bottom: 1em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/calendar-april17.svg');
			background-size: 3.5em auto;
			background-position: bottom 1.2em right 0.5em;
		}
		.TaskIntro-text {
			padding-right: 4em;
			padding-bottom: 0.75em;
		}
	}

	&.calendar-august8 {
		padding-bottom: 1em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/calendar-august8.svg');
			background-size: 3.5em auto;
			background-position: bottom 1.2em right 0.5em;
		}
		.TaskIntro-text {
			padding-right: 4em;
			padding-bottom: 0.75em;
			min-height: 4.5em;
		}
	}
	&.cans {
		padding-bottom: 1em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/cans.svg');
			background-size: 3.5em auto;
			background-position: top 1.2em right 0.5em;
		}
		.TaskIntro-text {
			padding-right: 5em;
			padding-bottom: 0.75em;
			min-height: 4em;
		}
	}
	&.corn-cob {
		padding-bottom: 1em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/corn-cob.svg');
			background-size: 8em auto;
			background-position: bottom -0.25em right 0.5em;
		}
		.TaskIntro-text {
			padding-bottom: 0.75em;
		}
	}

	&.check {
		padding-bottom: 1.5em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/check.svg');
			background-size: 9em auto;
			background-position: bottom 0em right 0.75em;
		}
		.TaskIntro-text {
			padding-bottom: 1.75em;
		}
	}

	&.check2 {
		padding-bottom: 2em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/check2.svg');
			background-size: 9.5em auto;
			background-position: bottom 0em right 0.75em;
		}
		.TaskIntro-text {
			padding-bottom: 1.75em;
		}
	}

	&.checklist {
		padding-bottom: 1.5em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/checklist.svg');
			background-size: 3.5em auto;
			background-position: bottom right 0.75em;
		}
		.TaskIntro-text {
			padding-bottom: 1.75em;
		}
	}

	&.checklist2,
	&.checklist2b {
		padding-bottom: 1.75em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/checklist2.svg');
			background-size: 5em auto;
			background-position: bottom right 2.5em;
		}
		.TaskIntro-text {
			padding-bottom: 1.75em;
		}
	}
	&.checklist2b {
		.TaskIntro-image {
			background-size: 4em auto;
			background-position: bottom right 1.5em;
		}
		.TaskIntro-text {
			padding-bottom: 2em;
		}
	}

	&.checklist3 {
		padding-bottom: 1.75em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/checklist3.svg');
			background-size: 5em auto;
			background-position: bottom right 2.5em;
		}
		.TaskIntro-text {
			padding-bottom: 1.75em;
		}
	}

	&.checklist4 {
		padding-bottom: 2.5em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/checklist4.svg');
			background-size: 8em auto;
			background-position: bottom 0em right 0.5em;
		}
		.TaskIntro-text {
			padding-bottom: 2em;
		}
	}

	&.checklist5 {
		padding-bottom: 2.5em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/checklist5.svg');
			background-size: 4em auto;
			background-position: bottom 0em right 0.5em;
		}
		.TaskIntro-text {
			padding-bottom: 2em;
		}
	}

	&.checklist-weekly {
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/checklist-weekly.svg');
			background-size: 4em auto;
			background-position: top 0.2em right 2em;
		}
		.TaskIntro-text {
			padding-right: 6em;
		}
	}

	&.clam-oyster-mussel {
		padding-bottom: 1.75em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/clam-oyster-mussel.svg');
			background-size: 10em auto;
			background-position: bottom right;
		}
		.TaskIntro-text {
			padding-bottom: 0.5em;
		}
	}

	&.clean-schedule-1,
	&.clean-schedule-2 {
		padding-bottom: 2em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/clean-schedule-1.svg');
			background-size: 4.75em auto;
			background-position: bottom right 1.75em;
		}
		.TaskIntro-text {
			padding-bottom: 2.2em;
		}		
	}
	&.clean-schedule-2 {
		padding-bottom: 1.5em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/clean-schedule-2.svg');
		}
		.TaskIntro-text {
			padding-bottom: 1em;
		}	
	}

	&.clock {
		padding-bottom: 1.5em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/clock.svg');
			background-size: 4.75em auto;
			background-position: bottom right 1.75em;
		}
		.TaskIntro-text {
			padding-bottom: 0.5em;
		}		
	}


	&.cooler {
		padding-bottom: 1.75em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/cooler.svg');
			background-size: 4.25em auto;
			background-position: bottom 0em right 1.5em;
		}
	}

	&.crumbs {
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/crumbs.svg');
			background-size: 9em auto;
			background-position: bottom 0.5em right 1.5em;
		}
	}
	
	&.cup-water {
		padding-bottom: 1.75em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/cup-water.svg');
			background-size: 5em auto;
			background-position: bottom 0em right 1.5em;
		}
	}

	&.customer {
		padding-bottom: 1.75em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/customer.svg');
			background-size: 4em auto;
			background-position: bottom 0em right 2em;
		}
	}

	&.customer2-branching {
		padding-bottom: 2em;
		.TaskIntro-text {
			padding-right: 4.75em;
			padding-bottom: 1em;
		}
		.TaskIntro-image {
			background-size: 5em auto;
			background-image: url('../../../../assets/images/modules/tasks/task-intro/customer2.svg');
			background-position: bottom 0.5em right 0.5em;
		}
	}
	&.customer2 {
		padding-bottom: 1em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/customer2.svg');
			background-size: 4em auto;
			background-position: bottom 0.5em right 2em;
		}
	}
	&.customer-hungry {
		padding-bottom: 1.5em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/customer-hungry.svg');
			background-size: 2.75em auto;
			background-position: bottom 0.5em right 1.25em;
		}
	}
	&.customer-line {
		padding-bottom: 2em;
		.TaskIntro-text {
			padding-bottom: 1.75em;
		}
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/customer-line.svg');
			background-size: 14em auto;
			background-position: bottom 0em right 0em;
		}
	}
	&.chart {
		padding-bottom: 3.5em;
		.TaskIntro-text {
			padding-bottom: 1.25em;
			padding-right: 4em;
		}
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/chart.svg');
			background-size: 7.25em auto;
			background-position: bottom 0.5em right 0.75em;
		}
	}


	&.deli-desk {
		padding-bottom: 1.75em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/deli-desk.svg');
			background-size: 7.5em auto;
			background-position: bottom right 0.75em;
		}
	}

	&.deli-shelves {
		padding-bottom: 0em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/deli-shelves.svg');
			background-size: 9em auto;
			background-position: bottom 0.75em right 1.5em;
		}
		.TaskIntro-text {
			padding-bottom: 2em;
		}
	}

	&.endcap {
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/endcap.svg');
			background-size: 8.5em auto;
			background-position: bottom 0.75em center;
		}
		.TaskIntro-text {
			padding-bottom: 11em;
		}
	}

	&.fake-id {
		padding-bottom: 2em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/fake-id.svg');
			background-size: 8em auto;
			background-position: bottom 1em right 0.5em;
		}
		.TaskIntro-text {
			padding-bottom: 3em;
		}
	}

	&.fish {
		padding-bottom: 0.5em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/fish.svg');
			background-size: 10.75em auto;
			background-position: bottom 1em right 0.5em;
		}
		.TaskIntro-text {
			padding-bottom: 2em;
		}
	}

	&.fire-extinguisher {
		padding-bottom: 0.5em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/fire-extinguisher.svg');
			background-size: 5em auto;
			background-position: bottom 0.6em right 1em;
		}
		.TaskIntro-text {
			padding-bottom: 2em;
		}	
	}

	&.first {
		padding-bottom: 0.5em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/first.svg');
			background-size: 5em auto;
			background-position: bottom 1em right 1em;
		}
		.TaskIntro-text {
			padding-bottom: 2em;
		}
	}
	
	&.flower1 {
		padding-bottom: 0.5em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/flower1.svg');
			background-size: 4em auto;
			background-position: bottom 1em right calc(50%);
		}
		.TaskIntro-text {
			padding-bottom: 8em;
		}
	}

	&.flower2 {
		padding-bottom: 0.5em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/flower2.svg');
			background-size: 5em auto;
			background-position: bottom 1em right calc(50%);
		}
		.TaskIntro-text {
			padding-bottom: 8.5em;
		}
	}

	&.flower3 {
		padding-bottom: 0.5em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/flower3.svg');
			background-size: 6em auto;
			background-position: bottom 1em right calc(50%);
		}
		.TaskIntro-text {
			padding-bottom: 7em;
		}
	}

	&.flower4 {
		padding-bottom: 1em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/flower4.svg');
			background-size: 7em auto;
			background-position: bottom 0.25em right 1em;
		}
		.TaskIntro-text {
			padding-bottom: 0.5em;
		}
	}

	&.frosted-meat {
		padding-bottom: 2.5em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/frosted-meat.svg');
			background-size: 8em auto;
			background-position: bottom 0.25em right calc(50% - 4em);
		}
		.TaskIntro-text {
			padding-bottom: 0.25em;
		}
	}

	&.frying-pan-and-veg-oil {
		padding-bottom: 0.75em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/frying-pan-and-veg-oil.svg');
			background-size: 8em auto;
			background-position: bottom 0.25em right calc(50% - 4.5em);
		}
		.TaskIntro-text {
			padding-right: 2em;
			
		}
	}

	&.haricot-steamed {
		padding-bottom: 1em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/haricot-steamed.svg');
			background-size: 8em auto;
			background-position: bottom -0.25em right 0.5em;
		}
		.TaskIntro-text {
			padding-bottom: 0.75em;
		}
	}

	&.hearts {
		padding-bottom: 1em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/hearts.svg');
			background-size: 6em auto;
			background-position: bottom 0em right 1em;
		}
		.TaskIntro-text {
			padding-bottom: 1.5em;
		}
	}

	&.heavy-box {
		padding-bottom: 0em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/heavy-box.svg');
			background-size: 6em auto;
			background-position: bottom 0.5em right 4em;
		}
		.TaskIntro-text {
			padding-bottom: 4em;
		}
	}

	&.issue,
	&.issue-small {
		padding-bottom: 1em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/issue.svg');
			background-size: 5em auto;
			background-position: bottom 0em right 2em;
		}
	}
	&.issue-small {
		.TaskIntro-image {
			background-size: 3.5em auto;
			background-position: bottom 0.25em right 2em;
		}
	}

	&.lettuce {
		padding-bottom: 1.5em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/lettuce.svg');
			background-size: 5em auto;
			background-position: bottom 2em right 0.5em;
		}
		.TaskIntro-text {
			padding-right: 6em;
		}
	}

	&.bag-lunch {
		padding-bottom: 0.5em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/bag-lunch.svg');
			background-size: 5.2em auto;
			background-position: bottom 0em right 0.6em;
		}
		.TaskIntro-text {
			padding-right: 5em;
		}
	}

	&.chef {
		padding-bottom: 1em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/chef.svg');
			background-size: 4.55em auto;
			background-position: bottom 0em right 0em;
		}
		.TaskIntro-text {
			padding-bottom: 1em;
		}
	}

	&.customer-service-manager {
		.TaskIntro-text {
			padding-bottom: 4em;
		}
	}

	&.donut-1 {
		padding-bottom: 1em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/donut-1.svg');
			background-size: 4.55em auto;
			background-position: bottom 0em right 2em;
		}
		.TaskIntro-text {
			padding-bottom: 1em;
		}
	}

	&.lisa {
		padding-bottom: 1em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/lisa.svg');
			background-size: 4.5em auto;
			background-position: bottom 0.25em right 1em;
		}	
	}

	&.linda {
		padding-bottom: 1em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/linda.svg');
			background-size: 4.5em auto;
			background-position: bottom 0.25em right 1em;
		}	
	}

	&.bob {
		padding-bottom: 1em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/bob.svg');
			background-size: 4.5em auto;
			background-position: bottom 0.25em right 1em;
		}	
	}

	&.manager {
		.TaskIntro-text {
			padding-bottom: 2em;
		}
	}
	&.manager-top-right {
		padding-bottom: 0.5em;
		.TaskIntro-text {
			padding-right: 5em;
			min-height: 4.5em;
			
		}
		.Manager {
			top: -1.8em;
			right: 1em;
		}
	}
	&.manager-center-right {
		.TaskIntro-text {
			padding-right: 5em;
		}
		.Manager {
			top: 0em;
			right: 1em;
		}
	}

	&.magnifying-glass {
		padding-bottom: 1em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/magnifying-glass.svg');
			background-size: 4em auto;
			background-position: bottom 0.25em right 2.5em;
		}		
	}

	&.marshmallows {
		padding-bottom: 1em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/marshmallows.svg');
			background-size: 7em auto;
			background-position: bottom 0em right 0.5em;
		}
	}

	&.meat-cooler {
		padding-bottom: 0em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/meat-cooler.svg');
			background-size: 10.75em auto;
			background-position: bottom 1em right 3em;
		}
		.TaskIntro-text {
			padding-bottom: 9em;
		}
	}

	&.case-no-date {
		padding-bottom: 1em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/case-no-date.svg');
			background-size: 7em auto;
			background-position: bottom 2em right 5em;
		}
		.TaskIntro-text {
			padding-bottom: 5em;
		}
	}

	&.milk-and-bleach {
		padding-bottom: 1em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/milk-and-bleach.svg');
			background-size: 7em auto;
			background-position: bottom 0em right 1.5em;
		}
		.TaskIntro-text {
			padding-bottom: 2em;
		}
	}

	&.mom-unhappy-boy-upset {
		padding-bottom: 1em;
		.TaskIntro-text {
			padding-bottom: 3em;
			padding-right: 4em;
		}
		.TaskIntro-image {
			height: 6.5em;
			background-image: url('../../../../assets/images/modules/tasks/task-intro/mom-unhappy-boy-upset.svg');
			background-size: 3em auto;
			background-position: right 1em top 1em;
		}	
	}

	&.mushroom {
		padding-bottom: 1.5em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/mushroom.svg');
			background-size: 5em auto;
			background-position: bottom 0em right 1.5em;
		}
		.TaskIntro-text {
			padding-bottom: 1em;
		}
	}

	&.mystery-fruit {
		padding-bottom: 1em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/mystery-fruit.svg');
			background-size: 6em auto;
			background-position: bottom 0em right 0.5em;
		}
		.TaskIntro-text {
			padding-bottom: 3em;
		}
	}

	&.mystery-fruit-revealed {
		padding-bottom: 1em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/mystery-fruit-revealed.svg');
			background-size: 5em auto;
			background-position: bottom 0em right 0.5em;
		}
		.TaskIntro-text {
			padding-bottom: 3em;
		}
	}

	&.new-items {
		padding-bottom: 1.75em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/new-items.svg');
			background-size: 3.5em auto;
			background-position: bottom 0em right 1.5em;
		}
	}

	&.number-one {
		padding-bottom: 1em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/first.svg');
			background-size: 5em auto;
			background-position: bottom 0em right 2em;
		}
	}

	&.orange1 {
		padding-bottom: 0em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/orange1.svg');
			background-size: 7em auto;
			background-position: bottom 1em right calc(50%);
		}
		.TaskIntro-text {
			padding-bottom: 9em;
		}
	}

	&.orange2 {
		padding-bottom: 0em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/orange2.svg');
			background-size: 11em auto;
			background-position: bottom 1em right calc(50%);
		}
		.TaskIntro-text {
			padding-bottom: 9em;
		}
	}

	&.orange3 {
		padding-bottom: 0em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/orange3.svg');
			background-size: 16em auto;
			background-position: bottom 1em right calc(50%);
		}
		.TaskIntro-text {
			padding-bottom: 17em;
		}
	}

	&.order-history {
		padding-bottom: 2em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/order-history.svg');
			background-size: 5.7em auto;
			background-position: bottom 1em right 1em;
		}
		.TaskIntro-text {
			padding-bottom: 1em;
			padding-right: 5.7em;
		}
	}

	&.paragraph {
		padding-bottom: 0.5em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/paragraph.svg');
			background-size: 2em auto;
			background-position: bottom 0em right 2.5em;
		}
	}
	
	&.peppers {
		padding-bottom: 0em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/peppers.svg');
			background-size: 8em auto;
			background-position: bottom 0em right 2em;
		}
	}

	&.percent {
		padding-bottom: 0.5em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/percent.svg');
			background-size: 2.5em auto;
			background-position: bottom 0em right 2em;
		}
	}

	&.percent2 {
		padding-bottom: 1em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/percent2.svg');
			background-size: 5em auto;
			background-position: bottom 0em right 2em;
		}
		.TaskIntro-text {
			padding-bottom: 2em;
		}
	}

	&.phone {
		padding-bottom: 1em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/phone.svg');
			background-size: 4em auto;
			background-position: bottom right 2em;
		}
		.TaskIntro-text {
			padding-bottom: 1.5em;
			padding-right: 5em;
		}
	}

	&.pinchy-ones {
		padding-bottom: 1em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/pinchy-ones.svg');
			background-size: 15em auto;
			background-position: bottom right;
		}
		.TaskIntro-text {
			padding-bottom: 2.5em;
		}
	}

	&.plant1 {
		padding-bottom: 0.75em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/plant1.svg');
			background-size: 7em auto;
			background-position: bottom 1.25em right calc(50%);
		}
		.TaskIntro-text {
			padding-bottom: 8.5em;
		}
	}

	&.plant2 {
		padding-bottom: 0.5em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/plant2.svg');
			background-size: 7em auto;
			background-position: bottom 1.25em right calc(50%);
		}
		.TaskIntro-text {
			padding-bottom: 9em;
		}
	}

	&.plant3 {
		padding-bottom: 0.5em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/plant3.svg');
			background-size: 7em auto;
			background-position: bottom 1.5em right calc(50%);
		}
		.TaskIntro-text {
			padding-bottom: 7em;
		}
	}

	&.plant4 {
		padding-bottom: 0.5em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/plant4.svg');
			background-size: 5.5em auto;
			background-position: bottom 1.25em right calc(50%);
		}
		.TaskIntro-text {
			padding-bottom: 8.5em;
		}
	}

	&.plant5 {
		padding-bottom: 0.5em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/plant5.svg');
			background-size: 5em auto;
			background-position: bottom 1.25em right calc(50%);
		}
		.TaskIntro-text {
			padding-bottom: 8.5em;
		}
	}

	&.potato-green {
		padding-bottom: 0.5em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/potato-green.svg');
			background-size: 5em auto;
			background-position: bottom -0.25em right 1em;
		}
		.TaskIntro-text {
			padding-bottom: 0.5em;
		}
	}

	&.potato {
		padding-bottom: 0.5em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/potato.svg');
			background-size: 5em auto;
			background-position: bottom -0.25em right 1em;
		}
		.TaskIntro-text {
			padding-bottom: 0.5em;
		}
	}

	&.prep-area {
		padding-bottom: 2em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/prep-area.svg');
			background-size: 9em auto;
			background-position: bottom 0.75em right 1em;
		}
		.TaskIntro-text {
			padding-bottom: 2em;
		}
	}

	&.produce-table {
		padding-bottom: 3em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/produce-table.svg');
			background-size: 9em auto;
			background-position: bottom 0.75em right 1em;
		}
		.TaskIntro-text {
			padding-bottom: 2em;
		}
	}

	&.products {
		padding-bottom: 2em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/products.svg');
			background-size: 9em auto;
			background-position: bottom 0.75em right 1em;
		}
		.TaskIntro-text {
			padding-bottom: 2em;
		}
	}

	&.products2 {
		padding-bottom: 2em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/products2.svg');
			background-size: 9em auto;
			background-position: bottom 0.75em right 1em;
		}
		.TaskIntro-text {
			padding-bottom: 2em;
		}
	}

	&.puddle {
		padding-bottom: 3em;
		margin-bottom: 2em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/puddle.svg');
			background-size: 8em auto;
			background-position: bottom 0em right 1em;
		}
	}

	&.register {
		padding-bottom: 2.25em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/register.svg');
			background-size: 9em auto;
			background-position: bottom right 1.5em;
		}
		.TaskIntro-text {
			padding-bottom: 1.5em;
		}
	}

	&.roses-bad {
		padding-bottom: 2.25em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/roses-bad.svg');
			background-size: 3.5em auto;
			background-position: bottom right 1.5em;
		}
		.TaskIntro-text {
			padding-bottom: 1.5em;
		}
	}

	&.rotten-chicken {
		padding-bottom: 0.5em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/rotten-chicken.svg');
			background-size: 5.5em auto;
			background-position: bottom right 1.5em;
		}
		.TaskIntro-text {
			padding-bottom: 1.5em;
		}
	}

	&.ruler {
		padding-bottom: 2em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/ruler.svg');
			background-size: 4.5em auto;
			background-position: bottom 0.5em right 0.75em;
		}
		.TaskIntro-text {
			padding-bottom: 1em;
		}
	}

	&.salad {
		padding-bottom: 1em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/salad.svg');
			background-size: 6em auto;
			background-position: bottom right 2em;
		}
		.TaskIntro-text {
			padding-bottom: 2em;
		}
	}

	&.salad-bar {
		padding-bottom: 1.3em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/salad-bar.svg');
			background-size: 8.5em auto;
			background-position: bottom right 0.75em;
		}
		.TaskIntro-text {
			padding-bottom: 1.5em;
		}
	}

	&.salad-spill {
		padding-bottom: 1em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/salad-spill.svg');
			background-size: 9.25em auto;
			background-position: bottom 0em right 1em;
		}
	}

	&.sale {
		padding-bottom: 1em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/sale.svg');
			background-size: 3.75em auto;
			background-position: bottom right 0.75em;
		}
		.TaskIntro-text {
			padding-bottom: 1em;
		}
	}
	&.sale2 {
		padding-bottom: 1em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/sale2.svg');
			background-size: 6em auto;
			background-position: bottom right 1.5em;
		}
		.TaskIntro-text {
			padding-bottom: 2em;
		}
	}


	&.sandwich {
		padding-bottom: 1em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/sandwich.svg');
			background-size: 10em auto;
			background-position: bottom right 0.75em;
		}
	}

	&.sandwich-expired {
		padding-bottom: 0.5em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/sandwich-expired.svg');
			background-size: 6em auto;
			background-position: bottom 0em right 1.5em;
		}
	}

	&.sandwich-old {
		padding-bottom: 1.75em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/sandwich-old.svg');
			background-size: 6em auto;
			background-position: bottom 0em right 1.5em;
		}
	}

	&.shelf {
		padding-bottom: 0em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/shelf.svg');
			background-size: 9em auto;
			background-position: bottom 1em right 4em;
		}
		.TaskIntro-text {
			padding-bottom: 9em;
		}
	}

	&.shelves {
		padding-bottom: 1.5em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/shelves.svg');
			background-size: 9em auto;
			background-position: bottom 0em right 1.5em;
		}
		.TaskIntro-text {
			padding-bottom: 0.75em;
		}
	}

	&.shelves2 {
		padding-bottom: 1.25em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/shelves2.svg');
			background-size: 3.5em auto;
			background-position: bottom right 1.25em;
		}
		.TaskIntro-text {
			padding-bottom: 1.25em;
		}
	}

	&.service-cake-case {
		padding-bottom: 1.75em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/service-cake-case.svg');
			background-size: 7em auto;
			background-position: bottom right 0.5em;
		}
		.TaskIntro-text {
			padding-bottom: 1.5em;
		}
	}

	&.sink {
		padding-bottom: 1.25em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/sink.svg');
			background-size: 10em auto;
			background-position: bottom right 1em;
		}
		.TaskIntro-text {
			padding-bottom: 1.25em;
		}
	}

	&.skipper {
		padding-bottom: 1em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/skipper.svg');
			background-size: 4.55em auto;
			background-position: bottom 0em right 0.5em;
		}
		.TaskIntro-text {
			padding-bottom: 1em;
		}
	}

	&.soup {
		padding-bottom: 2em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/soup.svg');
			background-size: 4.6em auto;
			background-position: bottom 1em right 1em;
		}
	}

	&.spilled-cookies {
		padding-bottom: 1em;
		.TaskIntro-image {
			background-size: auto 4.5em;
			background-image: url('../../../../assets/images/modules/tasks/task-intro/spilled-cookies.svg');
			background-position: center right 2em;

		}
	}	

	&.supply-closet {
		padding-bottom: 2em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/supply-closet.svg');
			background-size: 5em auto;
			background-position: bottom 0em right 2em;
		}	
	}

	&.steak {
		padding-bottom: 1em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/steak.svg');
			background-size: 7.1em auto;
			background-position: bottom 0em right 1em;
		}
	}

	&.table-cookies-full {
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/table-cookies-full.svg');
			background-size: auto 7.44em;
			background-position: bottom 1em center;
		}
		.TaskIntro-text {
			padding-bottom: 9em;
		}
	}

	&.tags-broken {
		.TaskIntro-image {
			margin-top: 0.5em;
			width: 4.2em;
			height: 3.2em;
			right: 1em;
			background-image: url('../../../../assets/images/characters/items/tags-broken.svg');
		}
		.TaskIntro-text {
			padding-right: 5em;
			min-height: 4em;
		}
	}

	&.thermometer {
		padding-bottom: 0.25em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/thermometer.svg');
			background-size: 5em auto;
			background-position: bottom right 0.25em;
		}
		.TaskIntro-text {
			padding-right: 4em;
		}
	}

	&.thermometer2 {
		padding-bottom: 3.5em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/thermometer2.svg');
			background-size: 6.5em auto;
			background-position: bottom 1.5em right 1em;
		}
		.TaskIntro-text {
			padding-bottom: 1em;
		}
	}

	&.three-bay-sink {
		padding-bottom: 2.5em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/three-bay-sink.svg');
			background-size: 9em auto;
			background-position: bottom 1.5em right 1em;
		}
		.TaskIntro-text {
			padding-bottom: 3em;
		}
	}

	&.u-boat-boxes {
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/u-boat-boxes.svg');
			background-size: 6em auto;
			background-position: top 0.5em right 1em;
		}
		.TaskIntro-text {
			min-height: 5em;
			padding-right: 7em;
		}
	}

	&.vegetables {
		padding-bottom: 2em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/vegetables.svg');
			background-size: 8em auto;
			background-position: bottom right 0.25em;
		}
		.TaskIntro-text {
			padding-right: 4em;
			padding-bottom: 2em;
		}
	}

	&.wet-floor-sign {
		padding-bottom: 1em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/wet-floor-sign.svg');
			background-size: 4em auto;
			background-position: right 1em bottom 0;
		}
	}	

	&.wic-tag {
		padding-bottom: 1em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/wic-tag.svg');
			background-size: 5em auto;
			background-position: bottom right 1em;
		}
	}

	&.wing-bar {
		padding-bottom: 1.5em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/wing-bar.svg');
			background-size: 8.5em auto;
			background-position: bottom right 0.75em;
		}
		.TaskIntro-text {
			padding-bottom: 1.5em;
		}
	}

	&.wizard-child {
		padding-bottom: 2em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/wizard-child.svg');
			background-size: 5em auto;
			background-position: bottom -9em right 0.75em;
		}
		.TaskIntro-text {
			padding-bottom: 1.5em;
		}
	}

	&.wizard,
	&.wizard3 {
		padding-bottom: 3em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/wizard.svg');
			background-size: 6.5em auto;
			background-position: right 0.75em bottom 0;
		}
		.TaskIntro-text {
			padding-bottom: 3em;
		}
	}
	&.wizard3 {
		padding-bottom: 1em;
		.TaskIntro-image {
			background-position: bottom 0em right 0em;
		}
		.TaskIntro-text {
			padding-right: 5em;
			padding-bottom: 1em;
		}
	}
	&.wizard2 {
		padding-bottom: 1em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/wizard.svg');
			background-size: 5em auto;
			background-position: right 0.75em bottom 0;
		}
		.TaskIntro-text {
			padding-bottom: 2em;
			min-height: 4.5em;
		}
	}
	&.wizard2 {
		padding-bottom: 1em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/modules/tasks/task-intro/wizard.svg');
			background-size: 5em auto;
			background-position: right 0.75em bottom 0;
		}
		.TaskIntro-text {
			padding-bottom: 1em;
			padding-right: 5em;
		}
	}
}
