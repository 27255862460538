@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';
@import '../../../../styles/mixins_backgrounds';
@import '../../../../styles/animations';
@import '../../../../styles/animations-intro';
.Order {
	position: relative;
	height: 100%;
	padding-top: 2em;
	padding-bottom: 1em;
	overflow: hidden;
	@include grey-background();
	.Order-intro {
		position: relative;
		width: 17.5em;
		margin: 2em auto 0.75em auto;
	}

	.Order-items {
		position: absolute;
		width: 17.5em;
		left: calc(50% - 8.75em);
		bottom: 3em;
		margin: 0.75em auto auto auto;
		overflow: auto;
		@include hide-scrollbar();
		.Order-item {
			width: 100%;
			margin-bottom: 1.25em;
			@include flex('space-between', 'center');

			.Order-itemNumber {
				width: 2em;
				text-align: left;
				span {
					font-size: 1.33em;
					font-weight: bold;
					color: #11466B;
				}
			}
			.Order-itemContainer {
				width: calc(100% - 2em);
			}
		}
	}

	.Order-doneBtn {
		position: fixed;
		right: calc(50% - 8.5em);
		bottom: 0.5em;
		z-index: 2;
		width: 7em;
	}


	&.landscape {
		@include grey-landscape-background();
		&.bagger {@include bagger-grey-landscape-background();}
		&.bakery {@include bakery-grey-landscape-background();}
		&.cashier {@include cashier-grey-landscape-background();}
		&.center-store {@include center-store-grey-landscape-background();}
		&.customer-service {@include customer-service-grey-landscape-background();}
		&.deli {@include deli-grey-landscape-background();}
		&.floral {@include floral-grey-landscape-background();}
		&.meat {@include meat-grey-landscape-background();}
		&.orientation {@include orientation-grey-landscape-background();}
		&.produce {@include produce-grey-landscape-background();}
		&.seafood {@include seafood-grey-landscape-background();}
		.Order-doneBtn {
			right: calc(50% - 16.5em);
		}
	}
}