@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/mixins_backgrounds';
@import '../../../styles/animations';
@import '../../../styles/animations-intro';
.CharacterItem {
	position: absolute;

	&.balloon-yay-me {
		width: 7.24em;
		height: 15.92em;
		background-image: url('../../../assets/images/avatar-maker/items/balloon-yay-me.svg');

		&.manager-a,
		&.manager-b,
		&.manager-c,
		&.manager-d,
		&.manager-e,
		&.manager-f,
		&.manager-g,
		&.manager-h {
			bottom: 5em;
			left: calc(50% + 2.8em);
			z-index: 9;
		}
		&.manager-e,
		&.manager-f,
		&.manager-g,
		&.manager-h {
			bottom: 3.5em;	
		}
	}

	&.beard-11 {
		width: 5.41em;
		height: 3.88em;
		background-image: url('../../../assets/images/avatar-maker/beard/beard-11.svg');
		&.manager-a,
		&.manager-b,
		&.manager-c,
		&.manager-d,
		&.manager-e,
		&.manager-f,
		&.manager-g,
		&.manager-h {
			bottom: 11.2em;	
			left: calc(50% + 3.9em);
			z-index: 9;
		}
		&.manager-e,
		&.manager-f,
		&.manager-g,
		&.manager-h {
			bottom: 9.5em;
			left: calc(50% + 3em);
		}
	}

	&.box-1 {
		bottom: -1.18em;
		left: calc(50% - 1em);
		width: 12em;
		height: 11.25em;
		background-image: url('../../../assets/images/characters/items/box-1.svg');
	}

	&.bread {
		width: 6.4em;
		height: 2.58em;
		background-image: url('../../../assets/images/characters/items/bread.svg');
		&.wizard-arm-out-1 {
			bottom: 7.8em;
			left: calc(50% + 3.5em);
		}
	}

	&.bread-wrapped {
		width: 12.63em;
		height: 3.1em;
		background-image: url('../../../assets/images/characters/items/bread-wrapped.svg');
		&.wizard-arm-out-1 {
			bottom: 7.3em;
			left: calc(50% + 0.5em);
		}
	}

	&.bread-wrapped-correct {
		width: 12.63em;
		height: 3.1em;
		background-image: url('../../../assets/images/characters/items/bread-wrapped-correct.svg');
		&.manager-a,
		&.manager-b,
		&.manager-c,
		&.manager-d,
		&.manager-e,
		&.manager-f,
		&.manager-g,
		&.manager-h {
			bottom: 11.3em;	
			left: 50%;
			z-index: 9;
		}
		&.manager-e,
		&.manager-f,
		&.manager-g,
		&.manager-h {
			bottom: 9.6em;
		}
	}

	&.cake-excessive-icing {
		width: 7.75em;
		height: 7.83em;
		background-image: url('../../../assets/images/characters/items/cake-excessive-icing.svg');
		&.wizard-arm-out-1 {
			bottom: 7.5em;
			left: calc(50% + 2.75em);
			z-index: 9;
		}
	}
	


	&.corny-1 {
		width: 7.46em;
		height: 6.99em;
		background-image: url('../../../assets/images/characters/items/corny-1.svg');
		&.wizard-arm-out-1 {
			bottom: 4.7em;
			left: calc(50% + 1em);
			z-index: 9;
		}
	}

	&.cupcake {
		width: 4.82em;
		height: 3.36em;
		background-image: url('../../../assets/images/characters/items/cupcake.svg');
		&.manager-a,
		&.manager-b,
		&.manager-c,
		&.manager-d,
		&.manager-e,
		&.manager-f,
		&.manager-g,
		&.manager-h {
			bottom: 11.4em;	
			left: calc(50% + 4em);
			z-index: 9;
		}
		&.manager-e,
		&.manager-f,
		&.manager-g,
		&.manager-h {
			bottom: 9.7em;
			left: calc(50% + 3.3em);
		}
	}
	&.disco-chicken {
		width: 4.28em;
		height: 9.88em;
		background-image: url('../../../assets/images/avatar-maker/animals/disco-chicken.svg');
		&.manager-a,
		&.manager-b,
		&.manager-c,
		&.manager-d,
		&.manager-e,
		&.manager-f,
		&.manager-g,
		&.manager-h {
			bottom: 11em;	
			left: calc(50% + 4em);
			z-index: 9;
		}
		&.manager-e,
		&.manager-f,
		&.manager-g,
		&.manager-h {
			bottom: 9.3em;
			left: calc(50% + 3.3em);
		}
	}
	&.dog-white {
		width: 11.14em;
		height: 11.5em;
		background-image: url('../../../assets/images/characters/items/dog-white.svg');
		&.wizard,
		&.wizard-1 {
			bottom: -5.72em;
			left: calc(50% - 7.5em);
			z-index: 3;
		}
	}
	&.donut-hat {
		width: 9.78em;
		height: 9.78em;
		background-image: url('../../../assets/images/avatar-maker/hats/donut-hat.svg');
		&.manager-a,
		&.manager-b,
		&.manager-c,
		&.manager-d,
		&.manager-e,
		&.manager-f,
		&.manager-g,
		&.manager-h {
			bottom: 4em;	
			left: calc(50% + 3em);
			z-index: 9;
		}
		&.manager-e,
		&.manager-f,
		&.manager-g,
		&.manager-h {
			bottom: 2em;
			left: calc(50% + 2.7em);
		}
	}
	&.fireball {
		width: 3.25em;
		height: 5.82em;
		background-image: url('../../../assets/images/characters/items/fireball.svg');
		&.wizard-arm-out,
		&.wizard-arm-out-1 {
			bottom: 8em;
			left: calc(50% + 4.5em);
			z-index: 3;
		}
	}
	&.flower-1 {
		width: 6em;
		height: 1.5em;
		background-image: url('../../../assets/images/characters/items/flower-1.svg');
		&.wizard,
		&.wizard-1 {
			bottom: 12.6em;
			left: calc(50% - 2.9em);
		}
	}
	&.hot-deal-1 {
		width: 9.1em;
		height: 6.66em;
		background-image: url('../../../assets/images/characters/items/hot-deal-1.svg');
		&.wizard-1 {
			bottom: 4.3em;
			left: calc(50% - 3.5em);
		}
	}
	&.karate-headband {
		width: 6.17em;
		height: 2.28em;
		background-image: url('../../../assets/images/avatar-maker/hats/karate-headband.svg');
		z-index: 9;
		&.wizard-arm-out-1 {
			bottom: 7em;
			left: calc(50% + 4em);
		}		
	}
	&.large-sign {
		width: 14em;
		height: 17em;
		background-image: url('../../../assets/images/characters/items/large-sign.svg');
		z-index: 9;
		&.wizard-arm-out.flipped {
			bottom: 6.5em;
			left: calc(50% - 14.5em);
		}
	}
	&.life-jacket {
		width: 7.24em;
		height: 8.15em;
		background-image: url('../../../assets/images/avatar-maker/work-clothes/lifejacket.svg');
		&.manager-a,
		&.manager-b,
		&.manager-c,
		&.manager-d,
		&.manager-e,
		&.manager-f,
		&.manager-g,
		&.manager-h {
			bottom: 4em;	
			left: calc(50% + 4.8em);
			z-index: 9;
		}
		&.manager-e,
		&.manager-f,
		&.manager-g,
		&.manager-h {
			bottom: 2.1em;
			left: calc(50% + 4em);
		}
	}
	&.lobster-hat {
		width: 7.41em;
		height: 4.05em;
		background-image: url('../../../assets/images/avatar-maker/hats/lobster-hat.svg');
		&.manager-a,
		&.manager-b,
		&.manager-c,
		&.manager-d,
		&.manager-e,
		&.manager-f,
		&.manager-g,
		&.manager-h {
			bottom: 9.8em;	
			left: calc(50% + 2.8em);
			z-index: 9;
		}
		&.manager-e,
		&.manager-f,
		&.manager-g,
		&.manager-h {
			bottom: 8.1em;
			left: calc(50% + 2.1em);
		}
	}
	&.log-1 {
		width: 10.25em;
		height: 5.53em;
		background-image: url('../../../assets/images/characters/items/log-1.svg');
		&.wizard-arm-out-1 {
			bottom: 7.4em;
			left: calc(50% + 0.7em);
		}
	}
	&.checklist-meat-2 {
		width: 9.6em;
		height: 13.2em;
		background-image: url('../../../assets/images/characters/items/checklist-meat-2.svg');
		&.butcher-arm-out,
		&.butcher-arm-out-1 {
			bottom: 3em;
			left: calc(50% + 2.2em);
			z-index: 9;
		}
	}
	&.mop {
		width: 5.34em;
		height: 22.35em;
		background-image: url('../../../assets/images/characters/items/mop.svg');
		&.manager-a,
		&.manager-b,
		&.manager-c,
		&.manager-d,
		&.manager-e,
		&.manager-f,
		&.manager-g,
		&.manager-h {
			bottom: 9em;	
			left: calc(50% + 3.1em);
			z-index: 9;
		}
		&.manager-e,
		&.manager-f,
		&.manager-g,
		&.manager-h {
			bottom: 7.5em;
		}
	}
	&.mounted-singing-fish {
		width: 7em;
		height: 4.6em;
		background-image: url('../../../assets/images/avatar-maker/decorations/mounted-singing-fish.svg');
		&.manager-a,
		&.manager-b,
		&.manager-c,
		&.manager-d,
		&.manager-e,
		&.manager-f,
		&.manager-g,
		&.manager-h {
			bottom: 11em;	
			left: calc(50% + 3.7em);
			z-index: 9;
		}
		&.manager-e,
		&.manager-f,
		&.manager-g,
		&.manager-h {
			bottom: 9.5em;
		}
	}

	&.parrot-shoulder-behind,
	&.parrot-shoulder-front {
		width: 5.95em;
		height: 10.1em;
		background-image: url('../../../assets/images/characters/items/parrot.svg');
		z-index: 1;
		&.pirate,
		&.pirate-1, 
		&.pirate-arm-out,
		&.pirate-arm-out-1 {
			top: 3em;
			left: 6.5em;
		}
	}
	&.parrot-shoulder-front {
		z-index: 3;
	}
	&.shirt-cowprint {
		width: 9em;
		height: 9.5em;
		background-image: url('../../../assets/images/avatar-maker/clothes/shirt-m-cowprint.svg');
		&.butcher-arm-out,
		&.butcher-arm-out-1 {
			bottom: 1.7em;
			left: calc(50% + 1.25em);
			z-index: 9;
		}
	}
	&.shirt-milk-sandwich {
		width: 9em;
		height: 9.5em;
		background-image: url('../../../assets/images/avatar-maker/clothes/shirt-m-milk-sandwich.svg');
		&.manager-a,
		&.manager-b,
		&.manager-c,
		&.manager-d,
		&.manager-e,
		&.manager-f,
		&.manager-g,
		&.manager-h {
			bottom: 2.75em;
			left: calc(50% + 1.5em);
			z-index: 9;
		}
		&.manager-e,
		&.manager-f,
		&.manager-g,
		&.manager-h {
			bottom: 1.25em;	
		}
	}
	&.spoon-1 {
		width: 3.2em;
		height: 8.2em;
		background-image: url('../../../assets/images/characters/items/spoon-1.svg');
		&.wizard-arm-out-1 {
			bottom: 5.8em;
			left: calc(50% + 4.3em);
		}
	}
	&.stack-of-cakes {
		width: 7.94em;
		height: 13.5em;
		background-image: url('../../../assets/images/characters/items/stack-of-cakes.svg');
		&.wizard-arm-out-1 {
			bottom: 7em;
			left: calc(50% + 2em);
		}
	}
	&.tags-broken {
		width: 4.2em;
		height: 3.2em;
		background-image: url('../../../assets/images/characters/items/tags-broken.svg');
		&.manager-a,
		&.manager-b,
		&.manager-c,
		&.manager-d,
		&.manager-e,
		&.manager-f,
		&.manager-g,
		&.manager-h {
			bottom: 10.9em;
			left: calc(50% + 4em);
			z-index: 9;
		}
		&.manager-e,
		&.manager-f,
		&.manager-g,
		&.manager-h {
			bottom: 9.1em;	
			left: calc(50% + 3.5em);
		}
	}
	&.sunglasses-1 {
		background-image: url('../../../assets/images/characters/items/sunglasses-1.svg');
		width: 4.25em;
		height: 1.44em;
		&.wizard-1 {
			bottom: 14.5em;
			left: calc(50% - 2.2em);
		}
	}
	&.wand-1 {
		width: 1.19em;
		height: 6.39em;
		background-image: url('../../../assets/images/characters/items/wand-1.svg');
		&.wizard-arm-out-1 { 
			bottom: 6em;
			left: calc(50% + 6em);
		}
	}
}