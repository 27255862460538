@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/mixins_backgrounds';
@import '../../styles/animations';
@import '../../styles/animations-intro';
.Admin {
	position: relative;
	min-height: 100%;
	font-size: calc(18 * ((100vw / 1920)));
	font-weight: 400;
	background-color: white;
	* {font-family: 'Source Sans Pro', sans-serif;}
	overflow: auto;

	.Admin-header {
		position: relative;
		height: 12.11em;
	}

	.Admin-content {
		position: absolute;
		width: 100%;
		top: 12.5em;
		bottom: 0;
		padding: 0.5em 2em;
		overflow: auto;
		@include hide-scrollbar();
	}

	.Admin-popupOverlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(#7ABCEA, 0.81);
		@include flex('center', 'center');
	}
}

// @media (orientation: portrait) and (min-aspect-ratio: 375/667) {
//   .FacilitatorOverview {
//     min-height: calc(177.5vw);
// 		&.map,
// 		&.splash {
// 			min-height: 100%;
// 		}
//   }
// }