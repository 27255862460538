.MultipleChoice {
	&.meat.landscape {@include meat-grey-landscape-background();}

	&.meat-1-where-to-look {
		.MultipleChoice-options {
			margin-top: 1em;
			padding-bottom: 1em;
			.MultipleChoice-optionsWrap {
				height: 24.4em;
				.MultipleChoice-option { 
					display: inline-block;
					width: 8em;
					height: 6em;
					span:nth-child(2) {
						bottom: 0.5em;
						right: 0.75em;
						left: auto;
						width: 1.39em;
						height: 1.39em;
					}

					&.position-1,
					&.position-4 {margin-left: 2em;}
					
					&.position-3 {margin-right: calc(100% - 14em); margin-left: calc(100% - 14em);}

					&.position-1,
					&.position-2,
					&.position-3 {
						margin-bottom: 1em;
					}

					&.option-1 {
						height: 6.5em;
						background-size: 6.3em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/meat-1-where-to-look/option-1.svg');
					}
					&.option-2 {
						width: 10em;
						height: 6.3em;
						background-size: 10em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/meat-1-where-to-look/option-2.svg');
					}
					&.option-3 {
						width: 8.5em;
						height: 8.25em;
						background-size: 8em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/meat-1-where-to-look/option-3.svg');
					}
					&.option-4 {
						height: 6.5em;
						background-size: 4.4em auto;		
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/meat-1-where-to-look/option-4.svg');
					}
					&.option-5 {
						height: 7.6em;
						background-size: 6.2em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/meat-1-where-to-look/option-5.svg');
					}
				}
			}
		}
	}

	&.meat-1-where-to-look-2 {
		.MultipleChoice-options {
			margin-top: 1em;
			padding-bottom: 1em;
			.MultipleChoice-optionsWrap {
				height: 24.4em;
				.MultipleChoice-option { 
					display: inline-block;
					width: 8em;
					height: 6em;
					span:nth-child(2) {
						bottom: 0.5em;
						right: 0.75em;
						left: auto;
						width: 1.39em;
						height: 1.39em;
					}
					&.position-1,
					&.position-3,
					&.position-5 {margin-left: 2em;}
					&.option-1 {
						background-size: 6em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/meat-1-meat-department/option-1.svg');
					}
					&.option-2 {
						background-size: 8em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/meat-1-meat-department/option-2.svg');
					}
					&.option-3 {
						height: 8em;
						background-size: 8em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/meat-1-meat-department/option-3.svg');
					}
					&.option-4 {
						height: 8em;
						background-size: 5em auto;		
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/meat-1-meat-department/option-4.svg');
					}
					&.option-5 {
						height: 9em;
						background-size: 7em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/meat-1-meat-department/option-5.svg');
					}
					&.option-6 {
						height: 9em;
						background-size: 9em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/meat-1-meat-department/option-6.svg');
					}
				}
			}
		}
	}

	&.meat-1-chuck-eye {
		.MultipleChoice-options {
			margin-top: 1em;
			padding-bottom: 1em;
			.MultipleChoice-optionsWrap {
				background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/meat-1-chuck-eye/background.svg');
				background-position: top center;
				background-size: 18em 100%;
				height: 24.4em;
				.MultipleChoice-option { 
					position: absolute; 
					&.option-1 {
						width: 4em;
						height: 2.5em;
						top: 5.5em; left: calc(50% + 1em);
					}
					&.option-2 {
						width: 8.8em;
						height: 3.75em;
						top: 7.7em; left: calc(50% - 7.55em);
					}
					&.option-3 {
						width: 5.75em;
						height: 1.75em;
						top: 4em; left: calc(50% - 7.8em);
					}
					&.option-4 {
						width: 10em;
						height: 1.25em;
						top: 18.5em; left: calc(50% - 7.1em);
					}
					&.option-2
					&.selected,
					&.correct,
					&.animateCorrect,
					&.wrong,
					&.animateWrong {@include noAnimation();}
				}
			}
		}
	}

	&.meat-1-measure {
		.MultipleChoice-options {
			margin-top: 1em;
			padding-bottom: 1em;
			.MultipleChoice-optionsWrap {
				height: 24.4em;
				@include flex('', 'center', 'column');
				.MultipleChoice-option { 
					display: inline-block;
					width: 10.5em;
					height: 5.5em;
					margin-bottom: 1em;
					span:nth-child(2) {
						bottom: 0.5em;
						right: 0.75em;
						left: auto;
						width: 1.39em;
						height: 1.39em;
					}
					&.option-1 {
						background-size: 10.3em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/meat-1-measure/option-1.svg');
					}
					&.option-2 {
						background-size: 10.3em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/meat-1-measure/option-2.svg');
					}
					&.option-3 {
						background-size: 10.5em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/meat-1-measure/option-3.svg');
					}
					&.option-4 {
						background-size: 6em auto;		
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/meat-1-measure/option-4.svg');
					}
				}
			}
		}
	}

	&.meat-1-steak-labels {
		.MultipleChoice-options {
			margin-top: 1em;
			padding-bottom: 1em;
			.MultipleChoice-optionsWrap {
				height: 24.4em;
				.MultipleChoice-option { 
					display: inline-block;
					width: 9.5em;
					height: 6em;
					span:nth-child(2) {
						bottom: 0.5em;
						right: 0.75em;
						left: auto;
						width: 1.39em;
						height: 1.39em;
					}
					&.position-1,
					&.position-3 {
						margin-left: 0.75em;
					}

					&.position-1,
					&.position-2 {
						margin-top: 1em;
						margin-bottom: 1em;
					}
					
					&.option-1 {
						background-size: 9.5em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/meat-1-steak-labels/option-1.svg');
					}
					&.option-2 {
						background-size: 9.5em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/meat-1-steak-labels/option-2.svg');
					}
					&.option-3 {
						background-size: 9.5em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/meat-1-steak-labels/option-3.svg');
					}
					&.option-4 {
						background-size: 9.5em auto;	
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/meat-1-steak-labels/option-4.svg');
					}
				}
			}
		}
	}

	&.meat-2-hamburger {
		.MultipleChoice-options {
			margin-top: 2em;
			.MultipleChoice-optionsWrap {
				margin: 0 auto;
				width: 11.9em;
				.MultipleChoice-option { 
					display: inline-block;
					width: 100%;
					height: 6em;
					margin-bottom: 1.5em;
					span:nth-child(2) {
						bottom: 0.5em;
						right: 0.75em;
						left: auto;
						width: 1.39em;
						height: 1.39em;
					}
					&.option-1 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/meat-2-hamburger/option-1.svg');
					}
					&.option-2 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/meat-2-hamburger/option-2.svg');
					}
					&.option-3 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/meat-2-hamburger/option-3.svg');
					}
				}
			}
		}
	}

	&.meat-2-help-plan-production {
		.MultipleChoice-options {
			margin-top: 2em;
			.MultipleChoice-optionsWrap {
				margin: 0 auto;
				width: 18.14em;
				height: 19em;
				background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/meat-2-help-plan-production/background.svg');
				background-size: 100% auto;
				background-position: top center;
				.MultipleChoice-option { 
					position: absolute;
					width: 6em;
					height: 6.75em;
					span:nth-child(2) {
						bottom: 0.5em;
						right: 0.75em;
						left: auto;
						width: 1.39em;
						height: 1.39em;
					}
					&.option-1 {
						top: 1.1em; 
						left: 2.6em;
					}
					&.option-2 {
						bottom: 3.8em;
						right: 2.6em;
					}
					&.option-3 {
						bottom: 3.8em;
						left: 2.6em;
					}
					&.option-4 {
						top: 1.1em;
						right: 2.6em;
					}
				}
			}
		}
	}
}