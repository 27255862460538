@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/mixins_backgrounds';
@import '../../styles/animations';
@import '../../styles/animations-intro';
.SelectSection {
	position: relative;
	min-height: 100%;
	font-size: calc(18 * ((100vw / 1920)));
	font-weight: 400;
	background-color: white;
	* {font-family: 'Source Sans Pro', sans-serif;}
	overflow: auto;
	&.portrait {
		font-size: calc(18 * ((100vw / 667)));
		.SelectSection-header {
			.SelectSection-logo {
				top: -0.5em;
				left:-0.5em;
				width: 6em;
			}
			.SelectSection-user {
				width: 23em;
				height: 2.5em;
				.SelectSection-userInfo {
					padding: 0 1em;
					width: 100%;
					@include flex('space-between', 'center');
					> div {
						width: 8em;
						text-align: center;
						span {
							font-size: 0.75em;
							color: white;
						}
						&:first-of-type {
							width: 5em;
							text-align: left;
						}
					}
				}
			}
		}
		.SelectSection-content {
			padding: 2em 0;
			@include flex('flex-start', 'center', 'column');
		}
		.SelectSection-or {
			margin: 2em auto 2em auto;
		}
	}

	.SelectSection-header {
		position: relative;
		width: 100%;
		height: 12.11em;
		background-image: url('../../assets/images/facilitator/background-header.jpg');
		background-size: cover;
		@include flex('center', 'center');
		.SelectSection-logo {
			position: absolute;
			top: 0em;
			left: 2em;
			width: 8em;
			cursor: pointer;
			img {width: 100%;}
		}
	
		.SelectSection-buttons {
			position: absolute;
			top: 0.75em;
			right: 1.5em;
			width: 2.5em;
			text-align: right;
		}

		.SelectSection-user {
			position: absolute;
			top: 0em;
			right: 6em;
			width: 12.5em;
			height: 5.25em;
			background-color: #10779E;
			border-bottom-left-radius: 0.44em;
			border-bottom-right-radius: 0.44em;
			@include flex('flex-start', 'center');
			@include box-shadow(0, 0.167em, 0.33em, 0, rgba(black, 0.16));
			.SelectSection-userInfo {
				padding-left: 1.33em;
				> div {
					width: 100%;
					white-space: nowrap;
					overflow: hidden;
					span {
						font-size: 0.75em;
						color: white;
					}
					&:first-of-type span,
					&:last-of-type span {
						font-weight: 600;
					}
				}
			}
		}

		.SelectSection-title {
			span {
				font-size: 4.25em;
				font-weight: 600;
				color: white;
			}
		}
	}

	.SelectSection-content {
		position: absolute;
		width: 100%;
		top: 12.11em;
		bottom: 0em;
		padding: 6em 10em;
		overflow: auto;
		@include flex('space-between', 'center');
		.SelectSection-section {
			display: inline-block;
			vertical-align: middle;
			.SelectSection-sectionText {
				span {
					font-size: 2em;
					font-weight: 600;
					color: #11466B;
				}	
			}
			.SelectSection-sectionBtn {
				width: 9em;
				height: 2.75em;
				margin: 2em auto 0 auto;
			}
		}
		.SelectSection-or {
			display: inline-block;
			vertical-align: middle;
			span {
				font-size: 2em;
				font-weight: 600;
				color: #11466B;
			}	
		}
	}
}