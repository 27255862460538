@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';
@import '../../../../styles/mixins_backgrounds';
@import '../../../../styles/animations';
@import '../../../../styles/animations-intro';
.Intro {
	position: relative;
	height: 100%;
	overflow: hidden;

	.Intro-logo {
		position: absolute;
		width: 100%;
		left: 0;
		top: calc(((100% - 19.11em) / 2) - (8.85em / 2)); // centered between screen top and building top
	}

	.Intro-building {
		position: absolute;
		bottom: 0;
		height: 19.11em;
		width: 100%;
		background-image: url('../../../../assets/images/backgrounds/intro.svg'), linear-gradient(#797c82, #48484d);
		background-position: bottom center, bottom center;
		background-size: auto 100%, 100% 45%;
		@include origin(50%, 100%);
		&.zoom-2 {@include translateAndScale(5.5em, 11.25em, 2.31);}
		&.zoom-3 {@include translateAndScale(13em, 22.5em, 3.49);}
		&.zoom-1-to-2 {@include building-zoom-1-to-2();}
		&.zoom-2-to-3 {@include building-zoom-2-to-3();}
		&.zoom-4 {
			@include transition(0.5s, 'all', 'linear', 0.2s);
			@include translateAndScale(0.25em, 3.75em, 1.82);
		}
	}

	.Intro-wizard {
		position: absolute;
		left: calc(50% - 8.1em);
		bottom: 3.5em;
		width: 3.44em;
		@include opacity(0);
		svg {
			width: 100%;
			height: auto;
		}
		&.show {
			@include opacity(1);
		}
		&.zoom-2 {
			@include translateAndScale(1em, 1.75em, 2.32);
		}
		&.zoom-3 {
			@include translateAndScale(3em, 4em, 3.49);
		}
		&.zoom-1-to-2 {@include wizard-zoom-1-to-2();}
		&.zoom-2-to-3 {@include wizard-zoom-2-to-3();}
	}

		
	.Intro-manager {
		position: absolute;
		left: calc(50% - 12.1em);
		bottom: 0;
		@include opacity(0);
		&.show {
			@include opacity(1);
		}
		&.fadeIn {
			@include translate(-100vw);
			animation: slideInFromLeft 0.5s ease-in-out 0.75s forwards;
			-webkit-animation: slideInFromLeft 0.5s ease-in-out 0.75s forwards;
		}
	}

	.Intro-box {
		.Intro-title {font-weight: bold;}
		.Intro-text {p {margin: 0;}}
		&.info {
			position: absolute;
			width: 15em;
			top: calc(((100% - 19.11em) / 2) + (8.85em / 2) + 0.5em); // 1em below logo
			left: calc(50% - 7.5em);
			color: white;
			text-align: center;
			.Intro-text {
				font-size: 1.33em;
				font-weight: 500;
			}
			.Intro-title {font-weight: 900;}
		}
		&.speech {
			position: absolute;
			left: calc(50% - 8.75em);
			bottom: 22em;
			width: 17.5em;
			color: #11466B;
			background-color: #F5F5F5;
			border-radius: 1.1em;
			padding: 1em 1.25em 1.25em 1.25em;
			&::after {
				content: '';
				position: absolute;
				top: calc(100% - 0.5em);
				left: 4.25em;
				border-left: 2em solid #F5F5F5;
				border-bottom: 2.4em solid transparent;
			}
			.Intro-title {
				font-weight: bold;
				font-size: 1.5em;
				margin-bottom: 0.75em;
			}
			&.zoom-3 {
				&::after {
					left: 5.5em;
				}
			}
			&.manager {bottom: 18.5em;}
		}

		&.fadeIn {
			@include fadeIn(0.25s, 'ease', 1.25s);
		}
	}
	
	.Intro-avatar {
		position: absolute;
		right: calc(50% - 15.2em);
		bottom: -1em;
		overflow: hidden;
		@include transition(0.5s, 'all', 'linear');
		&.zoom-3 {
			@include translateAndScale(7.5em, 1em, 1.5);
		}
	}

	.Intro-buttons {
		position: absolute;
		bottom: 1.75em;
		left: calc(50% - 10.4165em);
		width: 20.833em;
		padding: 0 1em;
		@include flex('center', 'center'); 
		&.two {
			@include flex('space-between', 'center'); 
		}
	}

	.Intro-nextBtn {
		position: absolute;
		right: 0;
		bottom: 0;
		cursor: pointer;
	}


	&.landscape {
		.Intro-nextBtn {right: calc(50% - 13.5em);}
	}
}