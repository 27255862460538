
@mixin building-zoom-1-to-2() {
  -webkit-animation: building-zoom-1-to-2 0.5s linear 0s forwards;
  animation: building-zoom-1-to-2 0.5s linear 0s forwards;
}

@-webkit-keyframes building-zoom-1-to-2 {
  0% {
    @include translateAndScale(0, 0, 1);
  }
  100% {
    @include translateAndScale(5.5em, 11.25em, 2.31);
  }
}
@keyframes building-zoom-1-to-2 {
  0% {
    @include translateAndScale(0, 0, 1);
  }
  100% {
    @include translateAndScale(5.5em, 11.25em, 2.31);
  }
}


@mixin building-zoom-2-to-3() {
  -webkit-animation: building-zoom-2-to-3 0.5s linear 0s forwards;
  animation: building-zoom-2-to-3 0.5s linear 0s forwards;
}

@-webkit-keyframes building-zoom-2-to-3 {
  0% {
    @include translateAndScale(5.5em, 11.25em, 2.31);
  }
  100% {
    @include translateAndScale(13em, 22.5em, 3.49);
  }
}
@keyframes building-zoom-2-to-3 {
  0% {
    @include translateAndScale(5.5em, 11.25em, 2.31);
  }
  100% {
    @include translateAndScale(13em, 22.5em, 3.49);
  }
}



@mixin wizard-zoom-1-to-2() {
  -webkit-animation: wizard-zoom-1-to-2 0.5s linear 0s forwards;
  animation: wizard-zoom-1-to-2 0.5s linear 0s forwards;
}

@-webkit-keyframes wizard-zoom-1-to-2 {
  0% {
    @include translateAndScale(0, 0, 1);
  }
  100% {
    @include translateAndScale(1em, 1.75em, 2.32);
  }
}
@keyframes wizard-zoom-1-to-2 {
  0% {
    @include translateAndScale(0, 0, 1);
  }
  100% {
    @include translateAndScale(1em, 1.75em, 2.32);
  }
}

@mixin wizard-zoom-2-to-3() {
  -webkit-animation: wizard-zoom-2-to-3 0.5s linear 0s forwards;
  animation: wizard-zoom-2-to-3 0.5s linear 0s forwards;
}

@-webkit-keyframes wizard-zoom-2-to-3 {
  0% {
    @include translateAndScale(1em, 1.75em, 2.32);
  }
  100% {
    @include translateAndScale(3em, 4em, 3.49);
  }
}
@keyframes wizard-zoom-2-to-3 {
  0% {
    @include translateAndScale(1em, 1.75em, 2.32);
  }
  100% {
    @include translateAndScale(3em, 4em, 3.49);
  }
}