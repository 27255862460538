@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/mixins_backgrounds';
@import '../../../styles/animations';
@import '../../../styles/animations-intro';
.NotificationsGame {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	height: 4.56em;
	background-image: linear-gradient(rgba(#082940, 0), #08263B, #082436);
	z-index: 3;
	@include flex('center', 'center');

	.NotificationsGame-button {
		position: relative;
		height: 3.56em;
		@include flex('space-between', 'center', 'column');
		cursor: pointer;

		.NotificationsGame-buttonIcon {
			position: relative;
			height: 2.56em;
			text-align: center;
			overflow: hidden;
			@include no-select();
			img {
				width: auto;
				height: 100%;
			}
		}

		.NotificationsGame-buttonLabel {
			font-size: 0.5em;
			font-weight: bold;
			text-align: center;
			color: white;
			text-transform: uppercase;
		}

		.NotificationsGame-buttonNumber {
			position: absolute;
			left: calc(50% - 1.5em);
			bottom: 1em;
			width: 1em;
			height: 1em;
			border-radius: 100%;
			font-weight: 900;
			color: white;
			background-color: #E8B341;
			@include flex('center', 'center');
			span {
				font-size: 0.5em;
			}
		}

		&.message {
			margin-left: 2em;
		}
	}

	.NotificationsGame-popup {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 3;
		&.competition {
			.NotificationsGame-popupContent {
				.NotificationsGame-popupMessage {
					.NotificationsGame-popupText {
						padding-right: 5em;
					}
				}
			}
		}
		&.message {
			.NotificationsGame-popupContent {
				.NotificationsGame-popupMessage {
					padding-bottom: 2.5em;
				}
			}

		}
		.NotificationsGame-popupContent {
			position: absolute;
			bottom: 6em;
			left: calc(50% - 9em);
			width: 18em;
			white-space: nowrap;
			overflow: hidden;			
			
			.NotificationsGame-popupMessage {
				position: relative;
				display: inline-block;
				vertical-align: bottom;
				width: 100%;
				max-height: 15em;
				padding: 1em 1em 0.5em 1em;
				white-space: normal;
				color: #11466B;
				background-color: #F5F5F5;
				border-radius: 1em;
				@include transition(0.1s, 'all', 'ease-in-out');
				&.simple {
					padding: 1em;
					p {
						margin: 0;
						text-align: center;
					}
				}
				

				.NotificationsGame-popupSeagull {
					position: absolute;
					right: 0.5em;
					top: 1em;
					width: 5em;
					height: 5em;
					background-image: url('../../../assets/images/map/seagull-2.svg')
				}
				.NotificationsGame-popupMessageInfo {
					position: relative;
					width: 100%;
					margin-bottom: 0.5em;
					@include flex('space-between', 'flex-start');
					span {
						font-size: 0.78em;
						font-weight: 600;
						&:last-of-type {
							font-size: 0.72em;
							font-weight: 400;
						}
					}					
				}
				.NotificationsGame-popupText {
					padding-right: 0.5em;
					max-height: 8em;
					overflow: auto;
					@include custom-scrollbar(0.22em, transparent, #B5B5B5);
					p {
						font-size: 0.72em;
						font-weight: 400;
						span {
							font-style: italic;
						}
						&:last-of-type {
							margin-bottom: 0;
						}
					}
					div:first-of-type p {
						margin-top: 0;
					}
				}
				.NotificationsGame-popupBtn {
					margin: 0.75em auto 0 auto;
					width: 4.44em;
					height: 1.67em;
					background-color: #11466B;
					border-radius: 0.83em;
					cursor: pointer;
					@include flex('center', 'center');
					span {
						font-size: 0.89em;
						font-weight: 700;
						color: white;
					}
				}
				.NotificationsGame-deleteBtn {
					position: absolute;	
					bottom: 0.5em;
					left: calc(50% - 2.5em);
					width: 3.89em;
					height: 1.22em;
					border-radius: 1em;
					border: 1px solid #B0292B;
					text-align: center;
					cursor: pointer;
					@include flex('center', 'center');
					span {
						font-size: 0.72em;
						font-weight: 500;
						color: #B0292B;
					}
				}
			}
			
			.NotificationsGame-popupNavPrev,
			.NotificationsGame-popupNavNext {
				position: absolute;
				width: 2em;
				height: 2em;
				bottom: 0.25em;
				background-image: url('../../../assets/images/modules/icon-next.svg');
				cursor: pointer;
				@include opacity(1);
				@include transition(0.5s, 'opacity', 'ease-in-out');
				&.hidden {
					cursor: default;
					pointer-events: none;
					@include opacity(0);
					@include transition(0.2s, 'opacity', 'ease-in-out');
				}
			}			
			.NotificationsGame-popupNavPrev {
				left: 0.5em;
				@include flip(-1);
			}
			.NotificationsGame-popupNavNext {
				right: 0.5em;
				
			}
		}
		.NotificationsGame-popupLocation {
			position: absolute;
			bottom: 5em;
			left: calc(50% - 9em);
			width: 18em;
			height: 1em;
			@include flex('center', 'flex-end');
			.NotificationsGame-popupLocationDot {
				width: 0.67em;
				height: 0.67em;
				border-radius: 1em;
				background-color: white;
				margin: 0 0.25em;
				@include opacity(0.5);
				&.current {
					@include opacity(1);
				}
			}
		}
	}
}