@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/mixins_backgrounds';
@import '../../../styles/animations';
@import '../../../styles/animations-intro';
.AdminGames {
	position: relative;
	min-height: 100%;

	.AdminGames-addGameBtn {
		float: right;
		width: 10.83em;
		height: 2.67em;
		margin-bottom: 0.5em;
	}

	.AdminGames-cell {
		width: 14%;
		height: 100%;
		padding: 0 1.5em;
		overflow: hidden;
		white-space: nowrap;
		@include flex('flex-start', 'center');
		&.avrCompletedModules {
			width: 16%;
		}
	}
	.AdminGames-header {
		width: 100%;
		height: 2.33em;
		color: white;
		background-color: #11466B;
		@include flex('space-between', 'center');
		.AdminGames-cell {
			span {
				display: inline-block;
				vertical-align: middle;
				position: relative;
				font-size: 1.39em;
				font-weight: 600;

			}
			&.client,
			&.created {
				cursor: pointer;
			}
			
			&.sortedBy {
				span::after {
					content: '';
					display: inline-block;
					vertical-align: middle;
					width: 1em;
					height: 1em;
					background-size: 0.6em auto;
					background-image: url('../../../assets/images/facilitator/icon-sort-desc.svg');
				}
				&.ASC span::after {background-image: url('../../../assets/images/facilitator/icon-sort-asc.svg');}
			}
		}
	}
	.AdminGames-body {
		width: 100%;
		.AdminGames-row {
			width: 100%;
			height: 2.5em;
			color: #525252;
			cursor: pointer;
			@include flex('space-between', 'center');
			&:hover {background-color: darken(#ffffff, 5%);}
			&:nth-child(odd) {
				background-color: #EDEDED;
				&:hover {background-color: darken(#EDEDED, 3%);}
			}
			.AdminGames-cell {
				&.client {
					span {
						font-weight: 600;
						text-decoration: underline;
					}
				} 
			}
		}
	}
}