@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';
@import '../../../../styles/mixins_backgrounds';
@import '../../../../styles/animations';
@import '../../../../styles/animations-intro';
.FacilitatorEditCompetitionPopup {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(#7ABCEA, 0.81);
	@include flex('center', 'center');

	.FacilitatorEditCompetitionPopup-closeBtn {
		padding: 0em;
		width: 2em;
		height: 2em;
		background-image: url('../../../../assets/images/facilitator/icon-close.svg');
		background-size: 1.75em auto;
		cursor: pointer;
	}

	.FacilitatorEditCompetitionPopup-content {
		width: 47em;
		min-height: 14em;
		max-height: 100vh;
		background-color: white;
		border-radius: 0.83em;
		overflow: auto;

		.FacilitatorEditCompetitionPopup-header {
			position: relative;
			width: 100%;
			height: 2.8em;
			background-color: #11466B;
			border-top-left-radius: 0.83em;
			border-top-right-radius: 0.83em;
			border: 0.25em solid white;
			padding-left: 1em;
			@include flex('space-between', 'center');
			.FacilitatorEditCompetitionPopup-title {
				color: #ffffff;
				font-size: 1.25em;
				font-weight: 600;
			}
			.FacilitatorEditCompetitionPopup-closeBtn {
				width: 2.33em;
				height: 2.33em;
				background-image: url('../../../../assets/images/facilitator/icon-close.svg');
				background-size: 1.56em auto;
				cursor: pointer;
			}
		}

		.FacilitatorEditCompetitionPopup-body {
			position: relative;
			padding: 1.5em 1.5em 1em 1.5em;
			.FacilitatorEditCompetitionPopup-selectModule {
				width: 20em;
				margin-bottom: 3em;
				span {
					display: block;
					font-weight: 600;
					&:first-of-type {
						color: #10779E;
						text-decoration: underline;
						margin-right: 2em;
					}
				}
			}

			
			.FacilitatorEditCompetitionPopup-competitionSchedule {
				position: absolute;
				top: 1.5em;
				right: 1.5em;
				.FacilitatorEditCompetitionPopup-competitionScheduleLabel {
					background-image: url('../../../../assets/images/facilitator/icon-calendar-blue.svg');
					background-position: left center;
					padding-left: 2em;
					margin-bottom: 0.5em;
					span {
						color: #10779E;
						font-weight: 600;
						text-decoration: underline;
					}
				}
				.FacilitatorEditCompetitionPopup-competitionScheduleInput {
					position: relative;
					width: 20em;
					@include flex('space-between', 'center');
					.react-datepicker-wrapper {
						width: 9.5em;
						input {
							position: relative;
							width: 100%;
							height: 2.8em;
							border-radius: 0.4em;
							border: 0.1em solid #10779E;
							padding: 0.5em 1em;
							@include placeholderColor(#B4B4B4);
						}	
					}
					.react-datepicker-popper {
						.react-datepicker {
							font-size: 1em;
							width: 28em;
							.react-datepicker__month-container {
								width: 100%;
								.react-datepicker__header {
									.react-datepicker__current-month {
										font-size: 1.1em;
									}
									.react-datepicker__day-names {
										width: 100%;
										font-size: 1em;
										.react-datepicker__day-name {
											width: 3em;
											margin: 0.5em;
											line-height: 1.7em;
										}
									}
								}
								.react-datepicker__month {
									margin: 0;
									.react-datepicker__week {
										.react-datepicker__day {
											width: 3em;
											margin: 0.5em;
											line-height: 1.7em;
										}
									}
								}
							}
						}
					}
				}
			}

			.FacilitatorEditCompetitionPopup-competitionPlayers {
				width: 20em;
				margin-bottom: 2em;
				> span {
					display: block;
					font-weight: 600;
					&:nth-of-type(1) {
						background-image: url('../../../../assets/images/facilitator/icon-players-2-blue.svg');
						background-position: left center;
						padding-left: 2em;
						margin-bottom: 0.5em;
						color: #10779E;
						text-decoration: underline;
						
					}
				}
				.FacilitatorEditCompetitionPopup-competitionAreas {
					width: 20em;
				}
			}

			.FacilitatorEditCompetitionPopup-competitionDescription,
			.FacilitatorEditCompetitionPopup-competitionPrize {
				margin-bottom: 1em;
				.FacilitatorEditCompetitionPopup-competitionDescriptionLabel,
				.FacilitatorEditCompetitionPopup-competitionPrizeLabel {
					background-image: url('../../../../assets/images/facilitator/icon-megafon.svg');
					background-position: left center;
					padding-left: 2em;
					margin-bottom: 0.5em;
					span {
						color: #10779E;
						font-weight: 600;
						text-decoration: underline;
					}
				}
				.FacilitatorEditCompetitionPopup-competitionDescriptionInput,
				.FacilitatorEditCompetitionPopup-competitionPrizeInput {
					width: 100%;
					textarea {
						width: 100%;
						height: 8em;
						resize: none;
						border: 0.1em solid #10779E;
						border-radius: 0.4em;
						padding: 0.5em;
					}
				}
			}
			.FacilitatorEditCompetitionPopup-competitionPrize { 
				.FacilitatorEditCompetitionPopup-competitionPrizeLabel {
					background-image: url('../../../../assets/images/facilitator/icon-competitions-blue.svg');
				}
				.FacilitatorEditCompetitionPopup-competitionPrizeInput {
					textarea {
						height: 5em;
					}
				}
			}

			.FacilitatorEditCompetitionPopup-errorMsg {
				height: 2em;
				margin-bottom: 1em;
				p {
					margin: 0;
					font-size: 1.2em;
					color: #C13B3B;
					font-weight: bold;
				}
			}

			.FacilitatorEditCompetitionPopup-confirmBtn {
				width: 8em;	
			}
		}
	}
}