@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/mixins_backgrounds';
@import '../../../styles/animations';
@import '../../../styles/animations-intro';
.Star {
	width: 1.5em;
	height: 1.5em;
	background-image: url('../../../assets/images/modules/star-empty.svg');
	background-size: contain;
	background-position: center center;

	&.facilitator {
		background-image: url('../../../assets/images/facilitator/star-empty.svg');
	}

	&.map {
		width: 1.25em;
		height: 1.25em;
	}

	&.results {
		width: 2em;
		height: 2em;
	}

	&.filled {
		background-image: url('../../../assets/images/modules/star-full.svg');

		&.facilitator {
			background-image: url('../../../assets/images/facilitator/star-full.svg');
		}
	}
	&.animate {
		-webkit-animation: jello-horizontal 0.9s both;
		animation: jello-horizontal 0.9s both;
					
	}
}
