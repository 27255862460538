@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/mixins_backgrounds';
@import '../../../styles/animations';
@import '../../../styles/animations-intro';
.PopupYearbook {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
	background-color: rgba(#092335, 0.6);
	width: 100%;
	height: 100%;
	@include flex('center', 'center');

	.PopupYearbook-dialogue {
		position: relative;
		background-color: #F5F5F5;
		width: 17.2em;
		height: 32.4em;
		border-radius: 1.1em;
		padding: 1.67em;
		padding-top: 1.3em;
		padding-bottom: 1.3em;
		@include flex('', 'center', 'column');

		.PopupYeartbook-closeBtn {
			position: absolute;
			top: 0em;
			right: 0em;
			width: 3em;
			height: 2em;
			cursor: pointer;
			background-image: url('../../../assets/images/profile/icon-close.svg');
			background-size: 1.25em auto;
		}

		.PopupYearbook-avatarFrame {
			position: relative;
			background-color: #FFFFFF;
			width: 8.94em;
			height: 10.576em;
			padding: 0.83em;
			@include rotate(2deg);
			@include box-shadow(0.17em, 0.17em, 0.55em, 0, rgba(#000000, 0.3));

			.PopupYearbook-avatar {
				background-color: #10779E;
				background-image: radial-gradient(#7ABCEA, #10779E 70%);
				position: relative;
				height: 100%;
				width: 100%;
				overflow: hidden;
			}
		}

		.PopupYearbook-form {
			@include flex('', 'center', 'column');
			margin-top: 1em;
			width: 14.67em;
			.PopupYearbook-title {
				@include flex('center');
				width: 100%;
				padding-bottom: 0.74em;
				
				span {
					color: $blue-dark2;
					font-weight: bold;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					margin-right: 0.44em;
					margin-left: 0.44em;
				}
			}
			.PopupYearbook-yearbookEntry {
				@include flex('', 'flex-start');
				height: 2.3em;
				width: 100%;
				padding-left: 0.6em;
				flex-grow: 1;
				flex-direction: column;
				margin-top: 0.5em;
				.PopupYearbook-yearbookEntryTitle {
					font-size: 0.66em;
					font-weight: 700;
					color: $blue-dark2;
					margin-right: 0.44em;
					white-space: nowrap;
					@include opacity(0.5);
				}
				.PopupYearbook-yearbookEntryValue,
				input {
					height: 1.8em;
					width: 100%;
					background-color: #FFFFFF;
					font-size: 0.88em;
					font-weight: 700;
					color: $blue-dark2;
					white-space: nowrap;
					padding-left: 0.3em;
					border-radius: 0.33em;
					
				}
				input {
					border: 1px solid #D7D7D8;
				}
				.PopupYearbook-yearbookEntryValue {
					@include flex('flex-start', 'center');
				}
			}
			.PopupYearbook-addBadgeWrapper {
				width: 2.27em;
				height: 2.27em;
				@include flex('flex-start', 'center');
	
				.PopupYearbook-addBadge {
					position: relative;
					width: 2em;
					height: 2em;
					background-size: contain;
					background-image: url('../../../assets/images/badges/unknown.svg');
					&::after {
						content: " ";
						position: absolute;
						background-color: #fff;
						top: 50%;
						height: 0.2em;
						margin-top: -0.1em;
						left: 0.5em;
						right: 0.5em;
						border-radius: 0.1em;
					}
					&::before {
						content: " ";
						position: absolute;
						background-color: #fff;
						left: 50%;
						width: 0.2em;
						margin-left: -0.1em;
						top: 0.5em;
						bottom: 0.5em;
						border-radius: 0.1em;
					}
				}
			}
		}
		.PopupYearbook-feedback {
			span {
				color: #FD5D44;
				font-size: 0.5em;
			}
		}
		.PopupYearbook-saveButton {
			position: absolute;
			right: 1.1em;
			bottom: 0.8em;
			height: 1.8em;
			width: 5.6em;
		}
		.PopupYearbook-editQuestions {
			position: absolute;
			right: 7.5em;
			bottom: 0.8em;
			height: 1.8em;
			width: 8em;
		}

		.PopupYearbook-badges {
			position: relative;
			height: 2.32em;
			width: 7.472em;
			@include flex('flex-start', 'center');
		}

		
		.PopupYearbook-reportButton {
			position: absolute;
			top: 0.5em;
			left: 15.5em;
		}
	}
	
}