@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/mixins_backgrounds';
@import '../../../styles/animations';
@import '../../../styles/animations-intro';
.Avatar {
	position: relative;
	text-align: center;

	.Avatar-image {
		display: inline-block;
		width: 22.5em;
		height: auto;
		svg {
			width: 100%;
			height: auto;
			overflow: visible;

			/* Layers that scales / move with body width */
			#Hands,
			#Items_Right_Hand_M,
			#Items_Right_Hand_F,
			#Items_Left_Hand_M,
			#Items_Left_Hand_F,
			#Skin_F > g > g:nth-child(1),
			#Skin_M > g > g:nth-child(1),
			#Clothes_F_clip_group,
			#Clothes_M_clip_group,
			#Work_clothes_M,
			#White_gloves_M,
			#Plastic_gloves_M,
			#Rubber_gloves_M
			#Pants_M_01,
			#Work_clothes_F,
			#White_gloves_F,
			#Plastic_gloves_F,
			#Rubber_gloves_F,
			#Pants_F_01 {
				@include transition(.25s, 'transform', 'ease-in-out');
				transform-origin: 50% 50%;
			}

			/* Hide all layers */
			#Hands > g,
			#Items_Right_Hand_M > g,
			#Items_Right_Hand_F > g,
			#Items_Left_Hand_M > g,
			#Items_Left_Hand_F > g,
			#Hats > g,
			#Glasses > g,
			#Nose_F > g,
			#Nose_M > g,
			#Mouth_F > g,
			#Mouth_M > g,
			#Eyes_F > g,
			#Eyes_M > g,
			#Hair_F > g,
			#Hair_F > path,
			#Hair_M > g,
			#Hair_M > path,
			#Beard > g,
			#Accessories2 > g,
			#Work_clothes_F > g,
			#Work_clothes_M > g,
			#Accessories > g,
			#Clothes_F > g,
			#Clothes_F > path,
			#Clothes_M > g,
			#Clothes_M > path,
			#Skin_F > g,			
			#Skin_M > g,
			#Accessories3 > g,
			#Hair_Back_F > g,
			#Hair_Back_F > path,
			#Paperdoll_items > g {
				display: none;
			}
		}

		/* Show selected items */
		&.Hand_left_F svg #Hand_left_F,
		&.Hand_right_F svg #Hand_right_F,
		&.Hand_left_M svg #Hand_left_M,
		&.Hand_right_M svg #Hand_right_M,

		&.Bunny_M svg #Bunny_M,
		&.Bunny_F svg #Bunny_F,
		&.Canary_M svg #Canary_M,
		&.Canary_F svg #Canary_F,		
		&.Disco_Chicken_M svg #Disco_Chicken_M,
		&.Disco_Chicken_F svg #Disco_Chicken_F,
		&.Fish_M svg #Fish_M,
		&.Fish_F svg #Fish_F,
		&.Hamster_M svg #Hamster_M,
		&.Hamster_F svg #Hamster_F,
		&.Hedgehog_M svg #Hedgehog_M,
		&.Hedgehog_F svg #Hedgehog_F,		
		&.Parrot_M svg #Parrot_M,
		&.Parrot_F svg #Parrot_F,
		&.Plank_M svg #Plank_M,
		&.Plank_F svg #Plank_F,
		&.Lizard_M svg #Lizard_M,
		&.Lizard_F svg #Lizard_F,
		&.Snake_M svg #Snake_M,
		&.Snake_F svg #Snake_F,
		&.Toad_M svg #Toad_M,
		&.Toad_F svg #Toad_F,
		&.Turtle_M svg #Turtle_M,
		&.Turtle_F svg #Turtle_F,
		&.Jarzard_M svg #Jarzard_M,
		&.Jarzard_F svg #Jarzard_F,

		&.Lightsaber_Broom_M svg #Lightsaber_Broom_M,
		&.Wand_Toy_M svg #Wand_Toy_M,
		&.Balloon_M svg #Balloon_M,
		&.Lightsaber_Broom_F svg #Lightsaber_Broom_F,
		&.Wand_Toy_F svg #Wand_Toy_F,
		&.Balloon_F svg #Balloon_F,

		&.body-f svg #Skin_01-2,
		&.body-f2 svg #Skin_01-2,
		&.body-m svg #Skin_01,
		&.body-m2 svg #Skin_01,

		&.Nose_01 svg #Nose_01,
		&.Nose_02 svg #Nose_02,
		&.Nose_03 svg #Nose_03,
		&.Nose_04 svg #Nose_04,
		&.Nose_05 svg #Nose_05,
		&.Nose_06 svg #Nose_06,
		&.Nose_01-2 svg #Nose_01-2,
		&.Nose_02-2 svg #Nose_02-2,
		&.Nose_03-2 svg #Nose_03-2,
		&.Nose_04-2 svg #Nose_04-2,
		&.Nose_05-2 svg #Nose_05-2,
		&.Nose_06-2 svg #Nose_06-2,

		&.Mouth_01a svg #Mouth_01a,
		&.Mouth_01b svg #Mouth_01b,
		&.Mouth_02a svg #Mouth_02a,
		&.Mouth_02b svg #Mouth_02b,
		&.Mouth_03a svg #Mouth_03a,
		&.Mouth_03b svg #Mouth_03b,
		&.Mouth_04a svg #Mouth_04a,
		&.Mouth_04b svg #Mouth_04b,
		&.Mouth_05a svg #Mouth_05a,
		&.Mouth_05b svg #Mouth_05b,
		&.Mouth_01a-2 svg #Mouth_01a-2,
		&.Mouth_01b-2 svg #Mouth_01b-2,
		&.Mouth_02a-2 svg #Mouth_02a-2,
		&.Mouth_02b-2 svg #Mouth_02b-2,
		&.Mouth_03a-2 svg #Mouth_03a-2,
		&.Mouth_03b-2 svg #Mouth_03b-2,
		&.Mouth_04a-2 svg #Mouth_04a-2,
		&.Mouth_04b-2 svg #Mouth_04b-2,
		&.Mouth_05a-2 svg #Mouth_05a-2,
		&.Mouth_05b-2 svg #Mouth_05b-2,

		&.Eyes_01 svg #Eyes_01,
		&.Eyes_02 svg #Eyes_02,
		&.Eyes_03 svg #Eyes_03,
		&.Eyes_04 svg #Eyes_04,
		&.Eyes_05 svg #Eyes_05,
		&.Eyes_06 svg #Eyes_06,
		&.Eyes_07 svg #Eyes_07,
		&.Eyes_08 svg #Eyes_08,
		&.Eyes_09 svg #Eyes_09,
		&.Eyes_10 svg #Eyes_10,
		&.Eyes_01-2 svg #Eyes_01-2,
		&.Eyes_02-2 svg #Eyes_02-2,
		&.Eyes_03-2 svg #Eyes_03-2,
		&.Eyes_04-2 svg #Eyes_04-2,
		&.Eyes_05-2 svg #Eyes_05-2,
		&.Eyes_06-2 svg #Eyes_06-2,
		&.Eyes_07-2 svg #Eyes_07-2,
		&.Eyes_08-2 svg #Eyes_08-2,
		&.Eyes_09-2 svg #Eyes_09-2,
		&.Eyes_10-2 svg #Eyes_10-2,

		&.Pants_01 svg #Pants_01,
		&.Pants_01-2 svg #Pants_01-2,

		&.Tshirt_01 svg #Tshirt_01,
		&.Tshirt_02 svg #Tshirt_02,
		&.Tshirt_03 svg #Tshirt_03,
		&.Tshirt_04 svg #Tshirt_04,
		&.Tshirt_05 svg #Tshirt_05,
		&.Tshirt_06 svg #Tshirt_06,
		&.Tshirt_07 svg #Tshirt_07,
		&.Tshirt_08 svg #Tshirt_08,
		&.Tshirt_09 svg #Tshirt_09,
		&.Tshirt_10 svg #Tshirt_10,
		&.Tshirt_11 svg #Tshirt_11,
		&.Tshirt_12 svg #Tshirt_12,
		&.Tshirt_13 svg #Tshirt_13,
		&.Jacket_01 svg #Jacket_01,
		&.Jacket_02 svg #Jacket_02,
		&.Shirt_01 svg #Shirt_01,
		&.Shirt_02 svg #Shirt_02,
		&.Sweatshirt_01 svg #Sweatshirt_01,
		&.Sweatshirt_02 svg #Sweatshirt_02,
		&.Tshirt_01-2 svg #Tshirt_01-2,
		&.Tshirt_02-2 svg #Tshirt_02-2,
		&.Tshirt_03-2 svg #Tshirt_03-2,
		&.Tshirt_04-2 svg #Tshirt_04-2,
		&.Tshirt_05-2 svg #Tshirt_05-2,
		&.Tshirt_06-2 svg #Tshirt_06-2,
		&.Tshirt_07-2 svg #Tshirt_07-2,
		&.Tshirt_08-2 svg #Tshirt_08-2,
		&.Tshirt_09-2 svg #Tshirt_09-2,
		&.Tshirt_10-2 svg #Tshirt_10-2,
		&.Tshirt_11-2 svg #Tshirt_11-2,
		&.Tshirt_12-2 svg #Tshirt_12-2,
		&.Tshirt_13-2 svg #Tshirt_13-2,
		&.Jacket_01-2 svg #Jacket_01-2,
		&.Jacket_02-2 svg #Jacket_02-2,
		&.Shirt_01-2 svg #Shirt_01-2,
		&.Shirt_02-2 svg #Shirt_02-2,
		&.Sweatshirt_01-2 svg #Sweatshirt_01-2,
		&.Sweatshirt_02-2 svg #Sweatshirt_02-2,
		&.Shirt_Tropical_M svg #Shirt_Tropical_M,
		&.Shirt_Tropical_F svg #Shirt_Tropical_F,
		&.Shirt_Cowprint_M svg #Shirt_Cowprint_M,
		&.Shirt_Cowprint_F svg #Shirt_Cowprint_F,
		&.Leather-jacket_M svg #Leather-jacket_M,
		&.Leather-jacket_F svg #Leather-jacket_F,
		&.Shirt_F_Milk_Sandwich svg #Shirt_F_Milk_Sandwich,
		&.Shirt_M_Milk_Sandwich svg #Shirt_M_Milk_Sandwich,

		&.Apron_01 svg #Apron_01,
		&.Apron_02 svg #Apron_02,
		&.Apron_03 svg #Apron_03,
		&.Apron_01-2 svg #Apron_01-2,
		&.Apron_02-2 svg #Apron_02-2,
		&.Apron_03-2 svg #Apron_03-2,
		&.Lifejacket_M svg #Lifejacket_M,
		&.Lifejacket_F svg #Lifejacket_F,
		&.Sandwich_Costume_M svg #Sandwich_Costume_M,
		&.Sandwich_Costume_F svg #Sandwich_Costume_F,
		&.Penguin_Suit_F svg #Penguin_Suit_F,
		&.Penguin_Suit_M svg #Penguin_Suit_M,

		&.Clover svg #Clover,
		&.Green_Thumb_badge svg #Green_Thumb_badge,
		&.Name_Tag svg #Name_Tag,
		&.Lobster_Bib svg #Lobster_Bib,
		&.Cloud_Stink svg #Cloud_Stink,
		&.Chicken_Wings svg #Chicken_Wings,

		&.Hat_03 svg #Hat_03,
		&.Hat_04 svg #Hat_04,
		&.Hat_08 svg #Hat_08,
		&.Hat_12 svg #Hat_12,
		&.Hat_17 svg #Hat_17,
		&.Hat_18 svg #Hat_18,
		&.Hat_20 svg #Hat_20,
		&.Hat_21 svg #Hat_21,
		&.Hat_22 svg #Hat_22,
		&.Hat_23 svg #Hat_23,
		&.Hat_24 svg #Hat_24,
		&.Hat_25 svg #Hat_25,
		&.Hat_26 svg #Hat_26,
		&.Hat_27 svg #Hat_27,
		&.Hat_28 svg #Hat_28,
		&.Hat_29 svg #Hat_29,
		&.Hat_30 svg #Hat_30,
		&.Hat_31 svg #Hat_31,
		&.Hat_32 svg #Hat_32,
		&.Hat_33 svg #Hat_33,
		
		&.Hair_01 svg #Hair_01,
		&.Hair_02 svg #Hair_02,
		&.Hair_03 svg #Hair_03,
		&.Hair_04 svg #Hair_04,
		&.Hair_05 svg #Hair_05,
		&.Hair_06 svg #Hair_06,
		&.Hair_07 svg #Hair_07,
		&.Hair_08 svg #Hair_08,
		&.Hair_09 svg #Hair_09,
		&.Hair_01-2 svg #Hair_01-2,
		&.Hair_Back_01 svg #Hair_Back_01,
		&.Hair_02-2 svg #Hair_02-2,
		&.Hair_back_02 svg #Hair_back_02,
		&.Hair_03-2 svg #Hair_03-2,
		&.Hair_Back_03 svg #Hair_Back_03,
		&.Hair_04-2 svg #Hair_04-2,
		&.Hair_Back_04 svg #Hair_Back_04,
		&.Hair_05-2 svg #Hair_05-2,
		&.Hair_06-2 svg #Hair_06-2,
		&.Hair_Back_06 svg #Hair_Back_06, 
		&.Hair_07-2 svg #Hair_07-2,
		&.Hair_Back_07 svg #Hair_Back_07,
		&.Hair_08-2 svg #Hair_08-2,
		&.Hair_Back_08 svg #Hair_Back_08,
		&.Hair_09-2 svg #Hair_09-2,
		&.Hair_Back_09 svg #Hair_Back_09,

		&.Beard_01 svg #Beard_01,
		&.Beard_02 svg #Beard_02,
		&.Beard_03 svg #Beard_03,
		&.Beard_04 svg #Beard_04,
		&.Beard_05 svg #Beard_05,
		&.Beard_06 svg #Beard_06,
		&.Beard_07 svg #Beard_07,
		&.Beard_08 svg #Beard_08,
		&.Beard_09 svg #Beard_09,
		&.Beard_10 svg #Beard_10,
		&.Beard_11 svg #Beard_11,

		&.Glasses_01 svg #Glasses_01,
		&.Glasses_02 svg #Glasses_02,
		&.Glasses_03 svg #Glasses_03,
		&.Glasses_04 svg #Glasses_04,
		&.Glasses_05 svg #Glasses_05,
		&.Glasses_06 svg #Glasses_06,
		&.Glasses_07 svg #Glasses_07,
		&.Glasses_08 svg #Glasses_08,
		&.Glasses_09 svg #Glasses_09,
		&.Glasses_10 svg #Glasses_10 {
			display: block;
		}

		&.Hat_20 {
			svg #Hair_M_clip_group,
			svg #Hair_F_clip_group,
			svg #Hair_F_Back_clip_group {
				clip-path: url(#hairnet);
			}
		}

		&.Hat_12 {
			svg #Hair_M_clip_group,
			svg #Hair_F_clip_group,
			svg #Hair_F_Back_clip_group {
				clip-path: url(#hair-1);
			}
		}
		&.Hat_01,
		&.Hat_02,
		&.Hat_03,
		&.Hat_04,
		&.Hat_05,
		&.Hat_07,
		&.Hat_08,
		&.Hat_09,
		&.Hat_10,
		&.Hat_11,
		&.Hat_13,
		&.Hat_14,
		&.Hat_15,
		&.Hat_17,
		&.Hat_19,
		&.Hat_21,
		&.Hat_22,
		&.Hat_23,
		&.Hat_24,
		&.Hat_33 {
			svg #Hair_M_clip_group,
			svg #Hair_F_clip_group,
			svg #Hair_F_Back_clip_group {
				clip-path: url(#hair-2);
			}
		}
		
		&.arm-left {
			&.body-f, &.body-f2 {
				svg #Clothes_F_clip_group,
				svg #Skin_F > g > g:nth-child(1) {
					clip-path: url(#arm-left-f);
				}
			}
			&.body-m, &.body-m2 {
				svg #Clothes_M_clip_group,
				svg #Skin_M > g > g:nth-child(1) {
					clip-path: url(#arm-left-m);
				}
			}
		}
		&.arm-right {
			&.body-f, &.body-f2 {
				svg #Clothes_F_clip_group,
				svg #Skin_F > g > g:nth-child(1) {
					clip-path: url(#arm-right-f);
				}
			}
			&.body-m, &.body-m2 {
				svg #Clothes_M_clip_group,
				svg #Skin_M > g > g:nth-child(1) {
					clip-path: url(#arm-right-m);
				}
			}
		}
		&.arm-both {
			&.body-f, &.body-f2 {
				svg #Clothes_F_clip_group,
				svg #Skin_F > g > g:nth-child(1) {
					clip-path: url(#arm-both-f);
				}
			}
			&.body-m, &.body-m2 {
				svg #Clothes_M_clip_group,
				svg #Skin_M > g > g:nth-child(1) {
					clip-path: url(#arm-both-m);
				}
			}
		}

		&.arm-left,
		&.arm-both {
			&.body-f2 {
				svg #Items_Left_Hand_F {
					@include translate(-0.7em);
				}
			}
			&.body-m2 {
				svg #Items_Left_Hand_M {
					@include translate(-0.7em);
				}
			}
		}
		&.arm-right,
		&.arm-both {
			&.body-f2 {
				svg #Items_Right_Hand_F {
					@include translate(0.7em);
				}
			}
			&.body-m2 {
				svg #Items_Right_Hand_M {
					@include translate(0.7em);
				}
			}
		}


		/* Skin color */
		&.color-1 svg {
			#Skin_01 #Rectangle_2208, #Skin_01 #Path_8824, #Skin_01 #Path_52448, #Skin_01 #Path_52449, #Skin_01 #Ellipse_166, #Skin_01 #Ellipse_167, #Skin_01 #Ellipse_168,
			#Skin_01-2 #Path_8861, #Skin_01-2 #Rectangle_2209, #Skin_01-2 #Path_8857, #Skin_01-2 #Path_8858, #Skin_01-2 #Path_8863 {
				fill: $skin1a;
			}
			#Skin_01 #Path_8825, #Skin_01 #Path_8829, #Skin_01 #Path_8830, 
			#Skin_01-2 #Path_8862, #Skin_01-2 #Path_8859, #Skin_01-2 #Path_8860 {
				fill: $skin1b;
			}
			#Hand_left_M path, #Hand_right_M path, #Hand_left_F path, #Hand_right_F path {
				fill: $skin1a;
			}
		}
		&.color-2 svg {
			#Skin_01 #Rectangle_2208, #Skin_01 #Path_8824, #Skin_01 #Path_52448, #Skin_01 #Path_52449, #Skin_01 #Ellipse_166, #Skin_01 #Ellipse_167, #Skin_01 #Ellipse_168,
			#Skin_01-2 #Path_8861, #Skin_01-2 #Rectangle_2209, #Skin_01-2 #Path_8857, #Skin_01-2 #Path_8858, #Skin_01-2 #Path_8863 {
				fill: $skin2a;
			}
			#Skin_01 #Path_8825, #Skin_01 #Path_8829, #Skin_01 #Path_8830, 
			#Skin_01-2 #Path_8862, #Skin_01-2 #Path_8859, #Skin_01-2 #Path_8860 {
				fill: $skin2b;
			}
			#Hand_left_M path, #Hand_right_M path, #Hand_left_F path, #Hand_right_F path {
				fill: $skin2a;
			}
		}
		&.color-3 {
			#Skin_01 #Rectangle_2208, #Skin_01 #Path_8824, #Skin_01 #Path_52448, #Skin_01 #Path_52449, #Skin_01 #Ellipse_166, #Skin_01 #Ellipse_167, #Skin_01 #Ellipse_168,
			#Skin_01-2 #Path_8861, #Skin_01-2 #Rectangle_2209, #Skin_01-2 #Path_8857, #Skin_01-2 #Path_8858, #Skin_01-2 #Path_8863 {
				fill: $skin3a;
			}
			#Skin_01 #Path_8825, #Skin_01 #Path_8829, #Skin_01 #Path_8830, 
			#Skin_01-2 #Path_8862, #Skin_01-2 #Path_8859, #Skin_01-2 #Path_8860 {
				fill: $skin3b;
			}
			#Hand_left_M path, #Hand_right_M path, #Hand_left_F path, #Hand_right_F path {
				fill: $skin3a;
			}
		}
		&.color-4 {
			#Skin_01 #Rectangle_2208, #Skin_01 #Path_8824, #Skin_01 #Path_52448, #Skin_01 #Path_52449, #Skin_01 #Ellipse_166, #Skin_01 #Ellipse_167, #Skin_01 #Ellipse_168,
			#Skin_01-2 #Path_8861, #Skin_01-2 #Rectangle_2209, #Skin_01-2 #Path_8857, #Skin_01-2 #Path_8858, #Skin_01-2 #Path_8863 {
				fill: $skin4a;
			}
			#Skin_01 #Path_8825, #Skin_01 #Path_8829, #Skin_01 #Path_8830, 
			#Skin_01-2 #Path_8862, #Skin_01-2 #Path_8859, #Skin_01-2 #Path_8860 {
				fill: $skin4b;
			}
			#Hand_left_M path, #Hand_right_M path, #Hand_left_F path, #Hand_right_F path {
				fill: $skin4a;
			}
		}
		&.color-5 {
			#Skin_01 #Rectangle_2208, #Skin_01 #Path_8824, #Skin_01 #Path_52448, #Skin_01 #Path_52449, #Skin_01 #Ellipse_166, #Skin_01 #Ellipse_167, #Skin_01 #Ellipse_168,
			#Skin_01-2 #Path_8861, #Skin_01-2 #Rectangle_2209, #Skin_01-2 #Path_8857, #Skin_01-2 #Path_8858, #Skin_01-2 #Path_8863 {
				fill: $skin5a;
			}
			#Skin_01 #Path_8825, #Skin_01 #Path_8829, #Skin_01 #Path_8830, 
			#Skin_01-2 #Path_8862, #Skin_01-2 #Path_8859, #Skin_01-2 #Path_8860 {
				fill: $skin5b;
			}
			#Hand_left_M path, #Hand_right_M path, #Hand_left_F path, #Hand_right_F path {
				fill: $skin5a;
			}
		}
		&.color-6 {
			#Skin_01 #Rectangle_2208, #Skin_01 #Path_8824, #Skin_01 #Path_52448, #Skin_01 #Path_52449, #Skin_01 #Ellipse_166, #Skin_01 #Ellipse_167, #Skin_01 #Ellipse_168,
			#Skin_01-2 #Path_8861, #Skin_01-2 #Rectangle_2209, #Skin_01-2 #Path_8857, #Skin_01-2 #Path_8858, #Skin_01-2 #Path_8863 {
				fill: $skin6a;
			}
			#Skin_01 #Path_8825, #Skin_01 #Path_8829, #Skin_01 #Path_8830, 
			#Skin_01-2 #Path_8862, #Skin_01-2 #Path_8859, #Skin_01-2 #Path_8860 {
				fill: $skin6b;
			}
			#Hand_left_M path, #Hand_right_M path, #Hand_left_F path, #Hand_right_F path {
				fill: $skin6a;
			}
		}
		&.color-7 {
			#Skin_01 #Rectangle_2208, #Skin_01 #Path_8824, #Skin_01 #Path_52448, #Skin_01 #Path_52449, #Skin_01 #Ellipse_166, #Skin_01 #Ellipse_167, #Skin_01 #Ellipse_168,
			#Skin_01-2 #Path_8861, #Skin_01-2 #Rectangle_2209, #Skin_01-2 #Path_8857, #Skin_01-2 #Path_8858, #Skin_01-2 #Path_8863 {
				fill: $skin7a;
			}
			#Skin_01 #Path_8825, #Skin_01 #Path_8829, #Skin_01 #Path_8830, 
			#Skin_01-2 #Path_8862, #Skin_01-2 #Path_8859, #Skin_01-2 #Path_8860 {
				fill: $skin7b;
			}
			#Hand_left_M path, #Hand_right_M path, #Hand_left_F path, #Hand_right_F path {
				fill: $skin7a;
			}
		}
		&.color-8 {
			#Skin_01 #Rectangle_2208, #Skin_01 #Path_8824, #Skin_01 #Path_52448, #Skin_01 #Path_52449, #Skin_01 #Ellipse_166, #Skin_01 #Ellipse_167, #Skin_01 #Ellipse_168,
			#Skin_01-2 #Path_8861, #Skin_01-2 #Rectangle_2209, #Skin_01-2 #Path_8857, #Skin_01-2 #Path_8858, #Skin_01-2 #Path_8863 {
				fill: $skin8a;
			}
			#Skin_01 #Path_8825, #Skin_01 #Path_8829, #Skin_01 #Path_8830, 
			#Skin_01-2 #Path_8862, #Skin_01-2 #Path_8859, #Skin_01-2 #Path_8860 {
				fill: $skin8b;
			}
			#Hand_left_M path, #Hand_right_M path, #Hand_left_F path, #Hand_right_F path {
				fill: $skin8a;
			}
		}
		&.color-9 {
			#Skin_01 #Rectangle_2208, #Skin_01 #Path_8824, #Skin_01 #Path_52448, #Skin_01 #Path_52449, #Skin_01 #Ellipse_166, #Skin_01 #Ellipse_167, #Skin_01 #Ellipse_168,
			#Skin_01-2 #Path_8861, #Skin_01-2 #Rectangle_2209, #Skin_01-2 #Path_8857, #Skin_01-2 #Path_8858, #Skin_01-2 #Path_8863 {
				fill: $skin9a;
			}
			#Skin_01 #Path_8825, #Skin_01 #Path_8829, #Skin_01 #Path_8830, 
			#Skin_01-2 #Path_8862, #Skin_01-2 #Path_8859, #Skin_01-2 #Path_8860 {
				fill: $skin9b;
			}
			#Hand_left_M path, #Hand_right_M path, #Hand_left_F path, #Hand_right_F path {
				fill: $skin9a;
			}
		}
		&.color-10 {
			#Skin_01 #Rectangle_2208, #Skin_01 #Path_8824, #Skin_01 #Path_52448, #Skin_01 #Path_52449, #Skin_01 #Ellipse_166, #Skin_01 #Ellipse_167, #Skin_01 #Ellipse_168,
			#Skin_01-2 #Path_8861, #Skin_01-2 #Rectangle_2209, #Skin_01-2 #Path_8857, #Skin_01-2 #Path_8858, #Skin_01-2 #Path_8863 {
				fill: $skin10a;
			}
			#Skin_01 #Path_8825, #Skin_01 #Path_8829, #Skin_01 #Path_8830, 
			#Skin_01-2 #Path_8862, #Skin_01-2 #Path_8859, #Skin_01-2 #Path_8860 {
				fill: $skin10b;
			}
			#Hand_left_M path, #Hand_right_M path, #Hand_left_F path, #Hand_right_F path {
				fill: $skin10a;
			}
		}
	}

	.Avatar-decorations {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;		
		.Avatar-decoration {
			position: absolute;
			&.mounted-singing-fish {
				bottom: 11.5em;
				right: calc(50% - 11.5em);
				width: 7em;
				height: 4.6em;
				background-image: url('../../../assets/images/avatar-maker/decorations/mounted-singing-fish.svg');
			}

		}
	}

	.Avatar-taskItems {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;	
		.Avatar-taskItem {
			position: absolute;
			&.box-1 {
				width: 12em;
				height: 11.25em;
				background-image: url('../../../assets/images/characters/items/box-1.svg');
				left: calc(50% - 6em);
				&.body-f, &.body-f2 {
					bottom: -3.2em;
				}
				&.body-m, &.body-m2 {
					bottom: -2.1em;
				}
			}
		}
	}


	.Avatar-animals {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		.Avatar-animal {
			position: absolute;
			&.cat {
				bottom: 0;
				right: calc(50% + 0.5em);
				width: 4.5em;
				height: 5.9em;
				background-image: url('../../../assets/images/avatar-maker/animals/cat.svg');
			}
			&.dog {
				bottom: -4em;
				right: calc(50% - 3.5em);
				width: 9em;
				height: 10em;
				background-image: url('../../../assets/images/avatar-maker/animals/dog.svg');
			}
		}
	}


	&.body-m2 .Avatar-image svg {
		#Skin_M > g > g:nth-child(1),
		#Clothes_M_clip_group,
		#Work_clothes_M,
		#White_gloves_M,
		#Plastic_gloves_M,
		#Rubber_gloves_M,
		#Hands {
			@include scaleX(1.2);
		}
	}

	&.body-f2 .Avatar-image svg {
		#Skin_F > g > g:nth-child(1),
		#Clothes_F_clip_group,
		#Work_clothes_F,
		#Work_clothes_F,
		#White_gloves_F,
		#Plastic_gloves_F,
		#Rubber_gloves_F,
		#Hands {
			@include scaleX(1.2);	
		}
	}

	&.avatar-maker .Avatar-image {
		@include transition(.25s, 'transform', 'ease-in-out');
		&.body-f,
		&.body-f2 {
			@include translate(0, 1.5em);
		}
		svg {
			#Skin_F > g > g:nth-child(1),
			#Skin_M > g > g:nth-child(1),
			#Clothes_F_clip_group,
			#Clothes_M_clip_group,
			#Work_clothes_M,
			#Work_clothes_F {
				@include transition(.25s, 'transform', 'ease-in-out');
			}
		}
	}

	&.map-full-body {
		.Avatar-image {
			width: 100%;
			@include flex('top', 'center', 'column');
			svg:first-of-type {
				position: relative;
				z-index: 1;
				width: 4em;
				#Pants_01,
				#Pants_01-2 {
					display: none;
				}
			}
			svg:last-of-type {
				position: relative;
				z-index: 2;
				width: 1.1em;
				height: auto;
				margin-top: -0.83em;
				margin-left: -0.025em;
				#Top_M_01,
				#Pants_M_01,
				#Top_F_01,
				#Pants_F_01 {
					display: none;
			}

			}
		}
		&.body-m .Avatar-image svg:last-of-type,
		&.body-m2 .Avatar-image svg:last-of-type {
			margin-top: -0.9em;
			margin-left: -0.015em;
			#Top_M_01,
			#Pants_M_01 {
				display: block;
			}
		}
		&.body-f .Avatar-image svg:last-of-type,
		&.body-f2 .Avatar-image svg:last-of-type {
			#Top_F_01,
			#Pants_F_01 {
				display: block;
			}
		}
		&.body-m2 .Avatar-image svg:last-of-type,
		&.body-f2 .Avatar-image svg:last-of-type {
			width: 1.32em;
			margin-left: -0.05em;

		}
		&.body-m2 .Avatar-image svg:last-of-type {
			margin-top: -1em;
		}
	}

	&.mapNav {
		.Avatar-image {
			width: 5.1em;
			margin-top: -1.5em;
			margin-left: -1.4em;	
		}
	}

	&.yearbook {
		text-align: center;
		.Avatar-image {
			margin-top: -4em;
			margin-left: -2.8em;
			width: 12.9em;
		}
		&.body-f .Avatar-image,
		&.body-f2 .Avatar-image {
			@include translate(0, 0.75em);
		}

		.Avatar-animals {
			.Avatar-animal {
				left: auto;
				&.bunny {
					bottom: 1em;
					right: calc(50% - 6em);
					width: 5em;
					height: 6em;
					background-image: url('../../../assets/images/avatar-maker/animals/bunny.svg');
				}
				&.canary {
					bottom: 5.4em;
					right: calc(50% - 3.2em);
					width: 2.5em;
					height: 4em;
					background-image: url('../../../assets/images/avatar-maker/animals/canary.svg');
					@include scaleX(-1);
				}
				&.cat {
					right: calc(50% - 5em);
					bottom: 1em;
				}
				&.dog {
					bottom: -1.1em;
					width: 8em;
					height: 9em;
					right: calc(50% - 8em);
				}
				&.disco-chicken {
					bottom: 0.5em;
					right: calc(50% - 4em);
					width: 3em;
					height: 7em;
					background-image: url('../../../assets/images/avatar-maker/animals/disco-chicken.svg');
				}
				&.fish {
					bottom: 2.4em;
					right: calc(50% - 3.5em);
					width: 2.5em;
					height: 4.33em;
					background-image: url('../../../assets/images/avatar-maker/animals/fish.svg');
				}
				&.hamster {
					bottom: 2.4em;
					right: calc(50% - 3.5em);
					width: 2em;
					height: 4em;
					background-image: url('../../../assets/images/avatar-maker/animals/hamster.svg');
				}
				&.hedgehog {
					bottom: 2.4em;
					right: calc(50% - 4.5em);
					width: 3em;
					height: 4em;
					background-image: url('../../../assets/images/avatar-maker/animals/hedgehog.svg');
				}
				&.jarzard {
					bottom: 0em;
					right: calc(50% - 3.25em);
					width: 1.5em;
					height: 8.7em;
					background-image: url('../../../assets/images/avatar-maker/animals/jarzard.svg');
				}
				&.lizard {
					bottom: 4em;
					right: calc(50% - 3.8em);
					width: 2.5em;
					height: 4em;
					background-image: url('../../../assets/images/avatar-maker/animals/lizard.svg');
				}
				&.parrot {
					bottom: -0.35em;
					right: calc(50% - 3.8em);
					width: 2.5em;
					height: 10em;
					background-image: url('../../../assets/images/avatar-maker/animals/parrot.svg');
				}
				&.plank-of-wood {
					bottom: 0.8em;
					right: calc(50% - 3.8em);
					width: 2.25em;
					height: 10.32em;
					background-image: url('../../../assets/images/avatar-maker/animals/plank-of-wood.svg');
				}
				&.snake {
					bottom: 3.4em;
					right: calc(50% - 4.2em);
					width: 3.1em;
					height: 3em;
					background-image: url('../../../assets/images/avatar-maker/animals/snake.svg');
				}
				&.toad {
					bottom: 2.2em;
					right: calc(50% - 3.2em);
					width: 2.75em;
					height: 3.68em;
					background-image: url('../../../assets/images/avatar-maker/animals/toad.svg');
				}
				&.turtle {
					bottom: 2.6em;
					right: calc(50% - 3.5em);
					width: 3.2em;
					height: 3.68em;
					background-image: url('../../../assets/images/avatar-maker/animals/turtle.svg');
					@include scaleX(-1);
				}
			}
		}
	}

	&.profile {
		.Avatar-image {
			@include transition(.25s, 'transform', 'ease-in-out');
			&.body-f,
			&.body-f2 {
				@include translate(0, 1.5em);
			}
			width: 24.84em;
		}
		.Avatar-animals {
			@include translate(0, -1.7em);
		}
	}

	&.profileNav {
		margin-top: 0.5em;
		height: 3.2em;
		overflow: hidden;
		.Avatar-image {
			width: 6.45em;
			margin-top: -2em;
			margin-left: -0.6em;
		}
	}

	&.intro {
		text-align: center;
		.Avatar-image {
			width: 19.68em;
		}
		&.body-f .Avatar-image,
		&.body-f2 .Avatar-image {
			@include translate(0, 0.75em);
		}
	}

	&.instructions,
	&.dialogue-intro,
	&.dialogue-result {
		text-align: right;
		.Avatar-image {
			width: 21.5em;
		}
		&.body-f .Avatar-image,
		&.body-f2 .Avatar-image {
			@include translate(0, 1.5em);
		}
		&.body-m .Avatar-image,
		&.body-m2 .Avatar-image {
			@include translate(0, 0.25em);
		}
	}

	&.ocean {
		text-align: center;
		.Avatar-image {
			width: 15em;
		}
		&.instructions {
			&.body-f,
			&.body-f2 {
				overflow: hidden;
			}
		}
		&.body-m .Avatar-image,
		&.body-m2 .Avatar-image {
			@include translate(0, 0.25em);
		}
	}

	&.paper-doll {
		.Avatar-image {
			width: 20.3em;
		}
		&.body-f .Avatar-image,
		&.body-f2 .Avatar-image {
			@include translate(0, 1.2em);
		}
		.Avatar-image svg {
			#Skin_F > g > g:nth-child(1),
			#Skin_M > g > g:nth-child(1),
			#Clothes_F_clip_group,
			#Clothes_M_clip_group {
				transform-origin: 50% 50%;
			}
			#Hands > g,
			#Items_Right_Hand_M > g,
			#Items_Right_Hand_F > g,
			#Items_Left_Hand_M > g,
			#Items_Left_Hand_F > g,

			#Accessories > g,
			#Accessories2 > g,
			#Accessories3 > g,
			#Hats > g,
			#Work_clothes_F > g,
			#Work_clothes_M > g {
				display: none;
			}
			#Clothes_F_clip_group,
			#Skin_F > g > g:nth-child(1),
			#Clothes_M_clip_group,
			#Skin_M > g > g:nth-child(1),
			#Hair_M_clip_group,
			#Hair_F_clip_group,
			#Hair_F_Back_clip_group {
				clip-path: none !important;
			}
		}
	}

	&.dialogue { 
		.Avatar-image {
			width: 5.9em;
			margin-top: -1.5em;
			margin-left: 0.1em;
		}
	}

	&.dialogue,
	&.dialogue-intro,
	&.dialogue-result {
		/* Remove all selected work clothes, hats and accessories */
		.Avatar-image svg {
			#Accessories > g,
			#Accessories2 > g,
			#Accessories3 > g,
			#Hats > g,
			#Work_clothes_F > g,
			#Work_clothes_M > g {
				display: none;
			}
			#Hair_M_clip_group,
			#Hair_F_clip_group,
			#Hair_F_Back_clip_group {
				clip-path: none;
			}
		}
		/* Always show name tag */
		&.body-f, &.body-f2 {#Nametag_F {display: block;}}
		&.body-m, &.body-m2 {#Nametag_M {display: block;}}

		/* Bagger */
		&.bagger {
			.Avatar-image {
				&.body-f,
				&.body-f2 {
					svg #Apron_01-2 {
						display: block;
					}
				}
				&.body-m,
				&.body-m2 {
					svg #Apron_01 {
						display: block;
					}
				}
			}
		}

		/* Bakery */
		&.bakery {
			.Avatar-image {
				&.Beard_01,
				&.Beard_02,
				&.Beard_03,
				&.Beard_04,
				&.Beard_05,
				&.Beard_06,
				&.Beard_07,
				&.Beard_08,
				&.Beard_09,
				&.Beard_10,
				&.Beard_11 {
					svg #Beardnet {display: block}
				}
				&.body-f,
				&.body-f2 {
					svg #Apron_01-2,
					svg #White_gloves_F {
						display: block;
					}
				}
				&.body-m,
				&.body-m2 {
					svg #Apron_01,
					svg #White_gloves_M {
						display: block;
					}
				}
			}
		}

		/* Deli */
		&.deli {
			.Avatar-image {
				svg #Hairnet {display: block;}
				svg #Hair_M_clip_group,
				svg #Hair_F_clip_group,
				svg #Hair_F_Back_clip_group {
					clip-path: url(#hairnet);
				}
				&.body-f,
				&.body-f2 {
					svg #Apron_01-2,
					svg #Plastic_gloves_F {
						display: block;
					}
				}
				&.body-m,
				&.body-m2 {
					svg #Apron_01,
					svg #Plastic_gloves_M {
						display: block;
					}
				}
			}
		}

		/* Floral */
		&.floral {
			.Avatar-image {
				#Green_Thumb_badge {display: block;}
				&.body-f,
				&.body-f2 {
					svg #Plastic_gloves_F {
						display: block;
					}
				}
				&.body-m,
				&.body-m2 {
					svg #Plastic_gloves_M {
						display: block;
					}
				}
			}
		}

		/* Meat & seafood */
		&.meat,
		&.seafood {
			.Avatar-image {
				svg #Hat_21 {
					display: block;
				}
				svg #Hair_M_clip_group,
				svg #Hair_F_clip_group,
				svg #Hair_F_Back_clip_group {
					clip-path: url(#hair-2);
				}
				&.body-f,
				&.body-f2 {
					svg #Apron_01-2,
					svg #Plastic_gloves_F {
						display: block;
					}
				}
				&.body-m,
				&.body-m2 {
					svg #Apron_01,
					svg #Plastic_gloves_M {
						display: block;
					}
				}
				&.Beard_01,
				&.Beard_02,
				&.Beard_03,
				&.Beard_04,
				&.Beard_05,
				&.Beard_06,
				&.Beard_07,
				&.Beard_08,
				&.Beard_09,
				&.Beard_10,
				&.Beard_11 {
					svg #Beardnet {display: block}
				}
			}
		}

		/* Produce */
		&.produce {
			.Avatar-image {
				svg #Hairnet {display: block;}
				svg #Hair_M_clip_group,
				svg #Hair_F_clip_group,
				svg #Hair_F_Back_clip_group {
					clip-path: url(#hairnet);
				}
				&.body-f,
				&.body-f2 {
					svg #Apron_01-2,
					svg #Plastic_gloves_F {
						display: block;
					}
				}
				&.body-m,
				&.body-m2 {
					svg #Apron_01,
					svg #Plastic_gloves_M {
						display: block;
					}
				}
				&.Beard_01,
				&.Beard_02,
				&.Beard_03,
				&.Beard_04,
				&.Beard_05,
				&.Beard_06,
				&.Beard_07,
				&.Beard_08,
				&.Beard_09,
				&.Beard_10,
				&.Beard_11 {
					svg #Beardnet {display: block}
				}
			}
		}
	}

	&.button {
		width: 2.55em;
		height: 2.55em;
		border-radius: 2.55em;
		border: calc(0.11em + 1px) solid #11466B;
		background-image: radial-gradient(#7ABCEA, #10779E);
		cursor: pointer;
		overflow: hidden;
		.Avatar-image {
			width: 4.83em;
			margin-left: -1.3em;
			margin-top: -1.4em;			
		}	
	}

	&.largeButton {
		width: 5em;
		height: 5em;
		border-radius: 2.55em;
		border: calc(0.11em + 1px) solid #11466B;
		background-image: radial-gradient(#7ABCEA, #10779E);
		overflow: hidden;
		.Avatar-image {
			width: 10.2em;
			margin-left: -2.8em;
			margin-top: -3.1em;
		}	
	}
}