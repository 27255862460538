@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';
@import '../../../../styles/mixins_backgrounds';
@import '../../../../styles/animations';
@import '../../../../styles/animations-intro';
.AdminUserPopup {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(#7ABCEA, 0.81);
	@include flex('center', 'center');

	.AdminUserPopup-content {
		width: 31.5em;
		background-color: white;
		border-radius: 0.83em;

		.AdminUserPopup-header {
			position: relative;
			width: 100%;
			height: 4.25em;
			background-color: #11466B;
			border-top-left-radius: 0.83em;
			border-top-right-radius: 0.83em;
			border-top: 0.25em solid white;
			border-right: 0.25em solid white;
			border-left: 0.25em solid white;
			padding-left: 2em;
			@include flex('flex-start', 'center');
			.AdminUserPopup-title {
				color: #ffffff;
				font-size: 1.4em;
				font-weight: 600;
			}
			.AdminUserPopup-closeBtn {
				position: absolute;
				top: 0;
				right: 0;
				width: 4.25em;
				height: 3.75em;
				background-image: url('../../../../assets/images/facilitator/icon-close.svg');
				background-size: 1.56em auto;
				cursor: pointer;
			}
		}

		.AdminUserPopup-body {
			padding: 1em 2em;
			.AdminUserPopup-section {
				margin-bottom: 1em;
				.AdminUserPopup-sectionTitle {
					color: #10779E;
					font-size: 1.44em;
					font-weight: 600;		
				}
				.AdminUserPopup-sectionText {
					p {
						margin: 0;
						color: #000000;
						font-size: 1.25em;
						font-weight: 600;
					}
				}
			}
		}
	}
}