@import '../../../../../styles/variables';
@import '../../../../../styles/fonts';
@import '../../../../../styles/mixins';
@import '../../../../../styles/mixins_backgrounds';
@import '../../../../../styles/animations';
@import '../../../../../styles/animations-intro';
.AdminGamePopupPlayers {
	position: relative;
	padding: 1.5em .75em 0em .75em;
	height: 100%;
	.AdminGamePopupPlayers-cell {
		width: 20%;
		height: 100%;
		padding: 0 1.5em;
		@include flex('flex-start', 'center');
	}
	.AdminGamePopupPlayers-header {
		width: 100%;
		height: 2.33em;
		color: white;
		background-color: #11466B;
		@include flex('space-between', 'center');
		.AdminGamePopupPlayers-cell {
			span {
				display: inline-block;
				vertical-align: middle;
				position: relative;
				font-size: 1.39em;
				font-weight: 600;
				@include no-select();
			}
			&.name,
			&.email,
			&.completion,
			&.lastlogin {
				cursor: pointer;

			}
			&.sortedBy {
				span::after {
					content: '';
					display: inline-block;
					vertical-align: middle;
					width: 1em;
					height: 1em;
					background-size: 0.6em auto;
					background-image: url('../../../../../assets/images/facilitator/icon-sort-desc.svg');
				}
				&.ASC span::after {background-image: url('../../../../../assets/images/facilitator/icon-sort-asc.svg');}
			}
		}
	}
	.AdminGamePopupPlayers-body {
		width: 100%;
		height: calc(100% - 2.33em);
		overflow: auto;
		.AdminGamePopupPlayers-row {
			width: 100%;
			height: 2.5em;
			color: #525252;
			&:nth-child(odd) {background-color: #EDEDED;}
			@include flex('space-between', 'center');
			.AdminGamePopupPlayers-cell {
				&.name {
					span {
						font-weight: 600;
						text-decoration: underline;
					}
				} 
			}
		}
	}
}