.MultipleChoice {
	&.bakery.landscape {@include bakery-grey-landscape-background();}

	&.bakery-1-food-below-standards {
		.MultipleChoice-options {
			margin-top: 1em;
			padding-bottom: 1em;
			.MultipleChoice-optionsWrap {
				margin: auto;
				width: 14.43em;
				height: 18.16em;
				.MultipleChoice-option { 
					position: absolute;
					// width: 14em;
					// height: 8.2em;
					// margin: 0 auto;
					// span:nth-child(2) {
					// 	bottom: 0.5em;
					// 	right: 0.75em;
					// 	left: auto;
					// 	width: 1.39em;
					// 	height: 1.39em;
					// }

					&.option-1 {
						width: 4.9em;
						height: 7.25em;
						top: 0;
						left: 0;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/bakery-1-food-below-standards/option-1.svg');
					}
					&.option-2 {
						width: 5.8em;
						height: 9.6em;
						bottom: 0;
						left: 0;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/bakery-1-food-below-standards/option-2.svg');
					}
					&.option-3 {
						width: 5.32em;
						height: 12.54em;
						top: 0;
						right: 0;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/bakery-1-food-below-standards/option-3.svg');
					}
				}
			}
		}
	}

	&.bakery-1-cupcake-label {
		.MultipleChoice-options {
			padding-bottom: 1em;
			.MultipleChoice-optionsWrap {
				background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/bakery-1-cupcake-label/background.svg');
				background-position: top center;
				background-size: 17em 100%;
				height: 24.4em;				
				.MultipleChoice-option { 
					position: absolute;
					span:nth-child(2) {
						bottom: calc(50% - 0.5em);
						left: calc(50% - 0.5em);
						width: 1em;
						height: 1em;
					}
					&.option-1 {
						width: 4em;
						height: 2.5em;
						top: 3em; left: calc(50% - 7em);
					}
					&.option-2 {
						width: 3em;
						height: 1.25em;
						top: 6.5em; left: calc(50% - 6.75em);
					}
					&.option-3 {
						width: 10.5em;
						height: 1.25em;
						top: 7.75em; left: calc(50% - 6.75em);
					}
					&.option-4 {
						width: 7em;
						height: 3.5em;
						top: 16em; left: calc(50% - 6.8em);
					}
					&.option-5 {
						width: 11.5em;
						height: 3em;
						top: 9.5em; left: calc(50% - 6.6em);
					}
					&.option-6 {
						width: 6em;
						height: 1em;
						top: 12.75em; left: calc(50% - 7em);
					}
					&.option-2
					&.selected,
					&.correct,
					&.animateCorrect,
					&.wrong,
					&.animateWrong {@include noAnimation();}
				}
			}
		}
	}

	&.bakery-1-cupcakes {
		.MultipleChoice-options {
			margin-top: 1em;
			padding-bottom: 1em;
			.MultipleChoice-optionsWrap {
				height: 5em;
				width: 100%;			
				.MultipleChoice-option { 
					position: relative;
					display: inline-block;
					margin-right: 1em;
					width: 100%;
					height: 5.34em;
					background-size: auto 5.34em;
					background-position: center center;
					margin-bottom: 0.8em;
					span:nth-child(2) {
						bottom: 1.5em;
						left: calc(50% + 4em);
						width: 1.39em;
						height: 1.39em;
					}
					&.option-1 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/bakery-1-cupcakes/option-1.svg');
					}
					&.option-2 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/bakery-1-cupcakes/option-2.svg');
					}
					&.option-3 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/bakery-1-cupcakes/option-3.svg');
					}
					&.option-4 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/bakery-1-cupcakes/option-4.svg');
					}
				}
			}
		}
	}

	&.bakery-1-donuts {
		.MultipleChoice-options {
			margin-top: 1em;
			padding-bottom: 1em;
			.MultipleChoice-optionsWrap {
				height: 24.4em;				
				padding: 0 1.5em;
				.MultipleChoice-option { 
					display: inline-block;
					width: 8em;
					height: 8em;
					span:nth-child(2) {
						bottom: 0.5em;
						right: 0.75em;
						left: auto;
						width: 1.39em;
						height: 1.39em;
					}
					&.position-1,
					&.position-3 {margin-right: calc(100% - 16em);}
					&.position-1,
					&.position-2 {margin-bottom: 1em;}
					&.option-1 {
						background-size: 8em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/bakery-1-donuts/option-1.svg');
					}
					&.option-2 {
						background-size: 8em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/bakery-1-donuts/option-2.svg');
					}
					&.option-3 {
						background-size: 8em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/bakery-1-donuts/option-3.svg');
					}
					&.option-4 {
						background-size: 8em auto;	
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/bakery-1-donuts/option-4.svg');
					}
				}
			}
		}
	}

	&.bakery-1-handwashing-signs {
		.MultipleChoice-options {
			margin-top: 1em;
			padding-bottom: 1em;
			.MultipleChoice-optionsWrap {
				height: 24.4em;		
				padding: 0 0.5em;		
				.MultipleChoice-option { 
					display: inline-block;
					width: 8em;
					height: 8em;
					span:nth-child(2) {
						bottom: 0.5em;
						right: 0.75em;
						left: auto;
						width: 1.39em;
						height: 1.39em;
					}
					&.position-1,
					&.position-3 {
						margin-right: calc(100% - 18em);
						margin-left: 1em;
					}
					&.position-1,
					&.position-2 {margin-bottom: 1em;}
					&.option-1 {
						background-size: 8em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/bakery-1-handwashing-signs/option-1.svg');
					}
					&.option-2 {
						background-size: 8em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/bakery-1-handwashing-signs/option-2.svg');
					}
					&.option-3 {
						background-size: 8em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/bakery-1-handwashing-signs/option-3.svg');
					}
					&.option-4 {
						background-size: 8em auto;		
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/bakery-1-handwashing-signs/option-4.svg');
					}
				}
			}
		}
	}

	&.bakery-1-knife-angle {
		.MultipleChoice-options {
			margin-top: 1em;
			padding-bottom: 1em;
			.MultipleChoice-optionsWrap {
				height: 24.4em;				
				.MultipleChoice-option { 
					width: 14em;
					height: 8.2em;
					margin: 0 auto;
					span:nth-child(2) {
						bottom: 0.5em;
						right: 0.75em;
						left: auto;
						width: 1.39em;
						height: 1.39em;
					}

					&.option-1 {
						background-size: 14em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/bakery-1-knife-angle/option-1.svg');
					}
					&.option-2 {
						background-size: 14em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/bakery-1-knife-angle/option-2.svg');
					}
					&.option-3 {
						background-size: 14em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/bakery-1-knife-angle/option-3.svg');
					}
				}
			}
		}
	}

	&.bakery-2-when-graduation-cakes {
		.MultipleChoice-options {
			margin-top: 1em;
			padding-bottom: 1em;
			.MultipleChoice-optionsWrap {
				width: 15em;	
				margin: 0 auto;
				text-align: center;
				.MultipleChoice-option { 
					display: inline-block;
					width: 6.5em;
					height: 6.7em;
					background-size: contain;
					margin: 0.4em;
					span:nth-child(2) {
						bottom: calc(50% - 0.75em);
						left: calc(50% - 0.75em);
					}
					&.option-1 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/bakery-2-when-graduation-cakes/option-1.svg');
					}
					&.option-2 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/bakery-2-when-graduation-cakes/option-2.svg');
					}
					&.option-3 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/bakery-2-when-graduation-cakes/option-3.svg');
					}
					&.option-4 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/bakery-2-when-graduation-cakes/option-4.svg');
					}
					&.option-5 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/bakery-2-when-graduation-cakes/option-5.svg');
					}
				}
			}
		}
	}
	&.bakery-2-tables-with-cookies {
		.MultipleChoice-options {
			margin-top: 1em;
			padding-bottom: 1em;
			.MultipleChoice-optionsWrap {
				width: 14em;	
				margin: 0 auto;
				text-align: center;
				.MultipleChoice-option { 
					display: inline-block;
					width: 13.33em;
					height: 10.55em;
					background-size: contain;
					span:nth-child(2) {
						bottom: calc(50% - 0.75em);
						left: calc(100% - 0.75em);
					}
					&.position-1 {
						margin-bottom: 1em;
					}
					&.option-1 {

						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/bakery-2-tables-with-cookies/option-1.svg');
					}
					&.option-2 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/bakery-2-tables-with-cookies/option-2.svg');
					}
				}
			}
		}
	}
	&.bakery-2-when-set-displays {
		.MultipleChoice-options {
			margin-top: 1em;
			padding-bottom: 1em;
			.MultipleChoice-optionsWrap {
				width: 17em;	
				margin: 0 auto;
				text-align: center;
				.MultipleChoice-option { 
					display: inline-block;
					width: 7.6em;
					height: 7.6em;
					background-size: contain;
					margin: 0.4em 0.4em;
					span:nth-child(2) {
						bottom: 0.5em;
						left: 0.5em
					}
					&.option-1 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/bakery-2-when-set-displays/option-1.svg');
					}
					&.option-2 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/bakery-2-when-set-displays/option-2.svg');
					}
					&.option-3 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/bakery-2-when-set-displays/option-3.svg');
					}
					&.option-4 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/bakery-2-when-set-displays/option-4.svg');
					}
				}
			}
		}
	}

	&.bakery-2-not-prepped-for-next-day {
		.MultipleChoice-options {
			margin-top: 1em;
			padding-bottom: 1em;
			.MultipleChoice-optionsWrap {
				width: 17em;	
				height: 11.4em;
				margin: 0 auto;
				.MultipleChoice-option { 
					position: absolute;
					background-size: contain;
					span:nth-child(2) {
						bottom: 0.5em;
						left: 0.5em
					}
					&.option-1 {
						width: 9.85em;
						height: 4.13em;
						bottom: 0;
						right: 0;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/bakery-2-not-prepped-for-next-day/option-1.svg');
					}
					&.option-2 {
						width: 9.33em;
						height: 3.76em;
						top: 0;
						left: 0;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/bakery-2-not-prepped-for-next-day/option-2.svg');
					}
					&.option-3 {
						width: 4.72em;
						height: 4.22em;
						top: 0.25;
						right: 1em;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/bakery-2-not-prepped-for-next-day/option-3.svg');
					}
					&.option-4 {
						width: 4.91em;
						height: 3.88em;
						bottom: 0;
						left: 0;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/bakery-2-not-prepped-for-next-day/option-4.svg');
					}
				}
			}
		}
	}
}