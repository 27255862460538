@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';
@import '../../../../styles/mixins_backgrounds';
@import '../../../../styles/animations';
@import '../../../../styles/animations-intro';
.SortDndItem {
	position: relative;
	display: inline-block;
	border-radius: 1em;
	color: white;
	background-color: #10779E;
	padding: 0.5em 1.75em 0.5em 0.5em;
	margin-bottom: 0.25em;
	margin-right: 0.25em;
	line-height: 1em;
	white-space: nowrap;
	font-family: 'Source Sans Pro', sans-serif;
	span {font-size: 0.75em;}
	&.completed {
		background-color: #159F90;
		padding: 0.5em 1.125em;
	}
	&.animateCorrect {
		padding: 0.5em 1.125em;
		background-color: #159F90;
		@include animateCorrect();
	}
	&.animateWrong {
		background-color: #B0292B;
		@include animateWrong();
	}
	&.shrink-1-math {
		width: 9em;
	}
	.SortDndItem-handle {
		position: absolute;
		height: 100%;
		width: 2em;
		right: 0;
		top: 0;
		background-image: url('../../../../assets/images/modules/icon-move.svg');
		background-position: center right 0.5em;
		background-size: auto 1em;
		cursor: move;
		&.disabled {
			cursor: not-allowed;
			background-image: none;
		}
	}
}