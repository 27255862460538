@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';
@import '../../../../styles/mixins_backgrounds';
@import '../../../../styles/animations';
@import '../../../../styles/animations-intro';
.SortDndContainer {
	position: relative;
	min-height: 2.85em;
	padding: 0.5em;
	border: 0.16em solid #39769A;
	border-top-style: none;
	border-bottom-left-radius: 1em;
	border-bottom-right-radius: 1em;
	&.hover {
		background-color: white;
	}

	&.bagger-1-sort-correct-bags-beans,
	&.bagger-1-sort-correct-bags-cat-food {
		min-height: 4em;
		background-position: top 0.5em right 0.5em;
		background-size: 3.5em auto;
		&.empty-bag {
			background-image: url('../../../../assets/images/modules/tasks/sort/bagger-1-sort-correct-bags-beans/empty-bag.svg');
		}
		&.bag-with-beans {
			background-image: url('../../../../assets/images/modules/tasks/sort/bagger-1-sort-correct-bags-beans/bag-with-beans.svg');
		}
	}
	&.bagger-1-sort-correct-bags-cat-food {
		&.empty-bag {
			background-image: url('../../../../assets/images/modules/tasks/sort/bagger-1-sort-correct-bags-cat-food/empty-bag.svg');
		}
		&.bag-with-cat-food {
			background-image: url('../../../../assets/images/modules/tasks/sort/bagger-1-sort-correct-bags-cat-food/bag-with-cat-food.svg');
		}
	}

	&.meat-1-animals {
		border: 0.16em solid #39769A;
		border-top-left-radius: 1em;
		border-top-right-radius: 1em;
		background-position: top calc(50%) right 0.5em;

		&.cow {
			background-size: 3em auto;
			background-image:  url('../../../../assets/images/modules/tasks/sort/meat-1-animals/cow.svg');
		}
		&.chicken {
			background-size: 1.75em auto;
			background-image:  url('../../../../assets/images/modules/tasks/sort/meat-1-animals/chicken.svg');
		}
		&.lamb {
			background-size: 2em auto;
			background-image:  url('../../../../assets/images/modules/tasks/sort/meat-1-animals/lamb.svg');
		}
		&.pig {
			background-size: 3em auto;
			background-image:  url('../../../../assets/images/modules/tasks/sort/meat-1-animals/pig.svg');
		}
	}

	&.shrink-1-math {
		border: none;
		border-radius: 1em;

		&.loss {
			margin-bottom: 1.67em;
		}

		&.loss,
		&.sales {
			padding: 0;
			min-height: 2.22em;
			height: 2.22em;
			width: 9em;
		}
	}
}