.MultipleChoice {	
	&.backroom-5-s-clipboards {
		.MultipleChoice-options {
			margin-top: 1em;
			padding-bottom: 1em;
			background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/backroom-5-s-clipboards/background.svg');
			background-position: top center;
			padding-top: 1.5em;
			padding-bottom: 2em;
			.MultipleChoice-optionsWrap {
				height: 22.5em;	
				padding: 0 1.5em;
				@include flex('space-between');
				flex-wrap: wrap;
				.MultipleChoice-option {
					display: inline-block;
					width: 5em;
					height: 6.75em;
					span:nth-child(2) {
						bottom: 0.5em;
						right: 0.75em;
						left: auto;
						width: 1.39em;
						height: 1.39em;
					}

					&.option-1 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/backroom-5-s-clipboards/option-1.svg');
					}
					&.option-2 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/backroom-5-s-clipboards/option-2.svg');
					}
					&.option-3 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/backroom-5-s-clipboards/option-3.svg');
					}
					&.option-4 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/backroom-5-s-clipboards/option-4.svg');
					}
					&.option-5 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/backroom-5-s-clipboards/option-5.svg');
					}
					&.option-6 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/backroom-5-s-clipboards/option-6.svg');
					}
					&.option-7 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/backroom-5-s-clipboards/option-7.svg');
					}
					&.option-8 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/backroom-5-s-clipboards/option-8.svg');
					}
					&.option-9 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/backroom-5-s-clipboards/option-9.svg');
					}
				}
			}
		}
	}
}