.MultipleChoice {
	&.deli.landscape {@include deli-grey-landscape-background();}

	&.deli-1-stack-high-limit {
		.MultipleChoice-options {
			padding-bottom: 1em;
			.MultipleChoice-optionsWrap {
				position: relative;
				margin-top: 1em;
				text-align: center;
				.MultipleChoice-option {
					width: 6.56em;
					height: 8.14em;
					margin: 1em;
					display: inline-block;
					&.option-1 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/deli-1-stack-high-limit/option-1.svg');
					}
					&.option-2 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/deli-1-stack-high-limit/option-2.svg');
					}
					&.option-3 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/deli-1-stack-high-limit/option-3.svg');
					}
					&.option-4 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/deli-1-stack-high-limit/option-4.svg');
					}
				}
			}
		}
	}

	&.deli-1-stack-low-limit {
		.MultipleChoice-options {
			padding-bottom: 1em;
			.MultipleChoice-optionsWrap {
				position: relative;
				margin-top: 1em;
				text-align: center;
				.MultipleChoice-option {
					width: 6.56em;
					height: 8.14em;
					margin: 1em;
					display: inline-block;
					&.option-1 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/deli-1-stack-low-limit/option-1.svg');
					}
					&.option-2 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/deli-1-stack-low-limit/option-2.svg');
					}
					&.option-3 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/deli-1-stack-low-limit/option-3.svg');
					}
					&.option-4 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/deli-1-stack-low-limit/option-4.svg');
					}
				}
			}
		}
	}

	&.deli-1-temperatures-cold {
		.MultipleChoice-options {
			padding-bottom: 1em;
			.MultipleChoice-optionsWrap {
				position: relative;
				margin-top: 0.5em;
				text-align: center;
				.MultipleChoice-option {
					width: 8.43em;
					height: 8.43em;
					margin: 0.5em;
					display: inline-block;
					span:nth-child(2) {
						left: auto;
						right: 0.25em;
					}
					&.option-1 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/deli-1-temperatures-cold/option-1.svg');
					}
					&.option-2 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/deli-1-temperatures-cold/option-2.svg');
					}
					&.option-3 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/deli-1-temperatures-cold/option-3.svg');
					}
					&.option-4 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/deli-1-temperatures-cold/option-4.svg');
					}
				}
			}
		}
	}
	&.deli-1-temperatures-hot {
		.MultipleChoice-options {
			padding-bottom: 1em;
			.MultipleChoice-optionsWrap {
				position: relative;
				margin-top: 0.5em;
				text-align: center;
				.MultipleChoice-option {
					width: 8.43em;
					height: 8.43em;
					margin: 0.5em;
					display: inline-block;
					span:nth-child(2) {
						left: auto;
						right: 0.25em;
					}
					&.option-1 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/deli-1-temperatures-hot/option-1.svg');
					}
					&.option-2 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/deli-1-temperatures-hot/option-2.svg');
					}
					&.option-3 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/deli-1-temperatures-hot/option-3.svg');
					}
					&.option-4 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/deli-1-temperatures-hot/option-4.svg');
					}
				}
			}
		}
	}

	/*************************************************************/


	&.deli-2-department-areas, &.deli-2-department-areas.landscape {
		background-image: linear-gradient(rgba(#808285, 0), rgba(#808285, 1)), url('../../../../../assets/images/modules/tasks/multiple-choice/deli-2-department-areas/floor.svg');
		background-size: 100% 18em, auto 18em;
		background-position: top center, top 18.75em center; 
		background-repeat: no-repeat, repeat-x;

		.MultipleChoice-options {
			margin: 0;
			width: 100%;
			left: 0;
			.MultipleChoice-optionsWrap {
				.MultipleChoice-option {
					position: absolute;
					z-index: 1;
					span:nth-child(2) {
						left: calc(50% - 1em);
					}
					&.option-1 {
						top: 4em;
						left: calc(50% + 3em);
						width: 9.78em;
						height: 4em;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/deli-2-department-areas/option-1.svg');
					}
					&.option-2 {
						top: 8.5em;
						left: calc(50% - 0.25em);
						width: 10.5em;
						height: 7.44em;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/deli-2-department-areas/option-2.svg');
					}
					&.option-3 {
						top: 3.75em;
						left: calc(50% - 12.5em);
						width: 12.45em;
						height: 4.1em;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/deli-2-department-areas/option-3.svg');
					}
					&.option-4 {
						top: 0.75em;
						left: calc(50% - 3.8em);
						width: 7.64em;
						height: 2.62em;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/deli-2-department-areas/option-4.svg');
					}
					&.option-5 {
						top: 12.5em;
						left: calc(50% - 10em);
						width: 12.2em;
						height: 10.92em;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/deli-2-department-areas/option-5.svg');
						z-index: 2;
					}
					&.option-6 {
						top: 0.5em;
						left: calc(50% + 7.5em);
						width: 2em;
						height: 3em;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/deli-2-department-areas/option-6.svg');
					}
				}
			}
		}
	}

	&.deli-2-clamshell-label {
		.MultipleChoice-options {
			padding-bottom: 1em;
			.MultipleChoice-optionsWrap {
				position: relative;
				margin-top: 1em;
				text-align: center;
				.MultipleChoice-option {
					width: 8.39em;
					height: 7.25em;
					margin: 0.5em;
					display: inline-block;
					background-size: contain;
					background-position: top center;
					span:nth-child(2) {
						left: auto;
						right: 0.5em;
						bottom: 2.5em;
					}
					&.option-1 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/deli-2-clamshell-label/option-1.svg');
					}
					&.option-2 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/deli-2-clamshell-label/option-2.svg');
					}
					&.option-3 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/deli-2-clamshell-label/option-3.svg');
					}
					&.option-4 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/deli-2-clamshell-label/option-4.svg');
					}
				}
			}
		}
	}

	&.deli-2-hot-bar-items {
		.MultipleChoice-options {
			padding-bottom: 1em;
			.MultipleChoice-optionsWrap {
				position: relative;
				width: 17.5em;
				height: 18em;
				margin: 1em auto;
				.MultipleChoice-option {
					position: absolute;
					width: 7.8em;
					height: 5.4em;
					background-size: contain;
					background-position: top center;
					span:nth-child(2) {
						left: auto;
						right: 0.5em;
						bottom: 2em;
					}
					&.option-1 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/deli-2-hot-bar-items/option-1.svg');
					}
					&.option-2 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/deli-2-hot-bar-items/option-2.svg');
						background-size: auto 5.29em;
					}
					&.option-3 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/deli-2-hot-bar-items/option-3.svg');
						background-size: auto 3.61em;
					}
					&.option-4 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/deli-2-hot-bar-items/option-4.svg');
					}
					&.option-5 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/deli-2-hot-bar-items/option-5.svg');
					}
					&.position-1 {top: 0; left: 0;}
					&.position-2 {top: 0; right: 0;}
					&.position-3{top: calc(50% - 2.7em); left: calc(50% - 3.9em);}
					&.position-4 {bottom: 0; left: 0;}
					&.position-5 {bottom: 0; right: 0;}

				}
			}
		}
	}
}