@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/mixins_backgrounds';
@import '../../../styles/animations';
@import '../../../styles/animations-intro';
.Feedback {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	text-align: center;
	background-image: linear-gradient(rgba(#092335, 0) 20%, rgba(#092335, 0.6) 80%);
	background-size: 100% 8em;
	background-position: bottom center;
	z-index: 12;
	.Feedback-content {
		position: absolute;
		left: calc(50% - 8.75em);
		bottom: 1.25em;
		width: 17.5em;
		border-radius: 1.1em;
		background-color: #F5F5F5;
		padding: 1em 1em 1em 2em;
		text-align: left;
		p {
			margin: 0;
			font-size: 0.85em;
			color: #11466B;
		}
	}

	.Feedback-manager {
		position: absolute;
		bottom: -1em;
		left: -1em;
		width: 3em;
		height: 3em;
		border-radius: 100%;
		border: calc(0.06em + 1px) solid #10779E;
		background-color: #F5F5F5;
		overflow: hidden;
	}

	

	&.butcher {
		.Feedback-content::after {
			content: '';
			position: absolute;
			bottom: -1em;
			left: -1em;
			width: 3em;
			height: 3em;
			border-radius: 100%;
			border: calc(0.06em + 1px) solid #10779E;
			background-color: #aaaaaa;
			background-image: url('../../../assets/images/characters/butcher.svg');
			background-size: 2.1em auto;
			background-position: top 0.3em left 0.35em;
		}
	}

	&.chef {
		.Feedback-content::after {
			content: '';
			position: absolute;
			bottom: -1em;
			left: -1em;
			width: 3em;
			height: 3em;
			border-radius: 100%;
			border: calc(0.06em + 1px) solid #10779E;
			background-color: #aaaaaa;
			background-image: url('../../../assets/images/characters/chef.svg');
			background-size: 2.1em auto;
			background-position: top 0.3em left 0.35em;
		}
	}
	&.pirate {
		.Feedback-content::after {
			content: '';
			position: absolute;
			bottom: -1em;
			left: -1em;
			width: 3em;
			height: 3em;
			border-radius: 100%;
			border: calc(0.06em + 1px) solid #10779E;
			background-color: #f5f5f5;
			background-image: url('../../../assets/images/characters/pirate.svg');
			background-size: 2.1em auto;
			background-position: top 0.3em left 0.35em;
		}
	}

	&.skipper,
	&.skipper-winter-gear {
		.Feedback-content::after {
			content: '';
			position: absolute;
			bottom: -1em;
			left: -1em;
			width: 3em;
			height: 3em;
			border-radius: 100%;
			border: calc(0.06em + 1px) solid #10779E;
			background-color: #F5F5F5;
			background-image: url('../../../assets/images/characters/skipper.svg');
			background-size: 2.1em auto;
			background-position: top 0.3em left 0.35em;
		}
	}
	&.skipper-winter-gear {
		.Feedback-content::after {
			background-image: url('../../../assets/images/characters/skipper-winter-gear.svg');
		}
	}

	&.wizard,
	&.wizard-dragon {
		.Feedback-content::after {
			content: '';
			position: absolute;
			bottom: -1em;
			left: -1em;
			width: 3em;
			height: 3em;
			border-radius: 100%;
			border: calc(0.06em + 1px) solid #10779E;
			background-color: #F5F5F5;
			background-image: url('../../../assets/images/characters/wizard-1.svg');
			background-size: 2.1em auto;
			background-position: top 0.3em left 0.15em;
		}
	}
	&.wizard-dragon {
		.Feedback-content::after {
			background-image: url('../../../assets/images/characters/wizard-dragon.svg');
		}
	}

	&.new-badge,
	&.new-badge-tier {
		.Feedback-content {
			padding-left: 4em;
			.Feedback-badge {
				position: absolute;
				left: 0.25em;
				top: 0.25em;
				height: calc(100% - 0.5em);				
				width: 3em;
				@include flex('center', 'center');
			}
		}
	}
	&.new-badge {
		.Feedback-content .Feedback-bdage {
			width: 2.5em;
		}
	}

	&.avatar-item {
		.Feedback-content {
			padding-left: 4em;
			.Feedback-item {
				position: absolute;
				left: 0.5em;
				top: 0.5em;
				height: calc(100% - 1em);				
				width: 3em;
				@include flex('center', 'center');
			}
		}
	}
}
