@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';
@import '../../../../styles/mixins_backgrounds';
@import '../../../../styles/animations';
@import '../../../../styles/animations-intro';
.Instructions {
	position: relative;
	height: 100%;
	overflow: hidden;
	.Instructions-speechBubble {
		position: absolute;
		left: calc(50% - 8.75em);
		bottom: 20em;
		width: 17.5em;
		color: #11466B;
		background-color: #F5F5F5;
		border-radius: 1.1em;
		padding: 1em 1.25em 1.25em 1.25em;
		z-index: 11;

		.Instructions-bubbleTriangle {
			position: absolute;
			top: calc(100% - 0.5em);
			left: 6.25em;
			border-left: 2em solid #F5F5F5;
			border-bottom: 2.4em solid transparent;
			z-index: 4;

			&.middle {
				left: 9.5em;
			}

			&.right {
				@include scaleX(-1);
				left: 9.5em;
			}
		}

		.Instructions-title {
			font-weight: bold;
			font-size: 1.5em;
			margin-bottom: 0.75em;
		}

		.Instructions-text {
			p {
				margin-bottom: 0;
				&:first-of-type {margin-top: 0;}
			}
			ul {
				margin: 0;
				list-style: none;
				li {
					min-height: 1.3em;
					line-height: 1.2em;
					font-weight: 900;
					padding-left: 1.75em;
					margin-bottom: 0.2em;
					background-image: url('../../../../assets/images/modules/icon-check.svg');
					background-size: 1.2em auto;
					background-position: left center;
					background-repeat: no-repeat;
					&:nth-last-child(1) {margin-bottom: 0;}
				}
			}
			ul + p {
				margin: 0;
			}
		}
	}

	.Instructions-countdown {
		position: absolute;
		top: 3.5em;
		left: calc(50% - 4em);
	}

	.Instructions-extraCharacter {
		position: absolute;
		left: calc(50% - 11.75em);
		bottom: 0;
		z-index: 2;	
		
		&.hiding {
			left: calc(50% - 13em);
		}

		&.right {
			left: calc(50% + 0.25em);
		}
			
		&.middle {
			left: calc(50% - 11.75em / 2);
		}

		&.poof {
			left: calc(50% - 24.7em / 2);
			z-index: 10;
		}
		&.poof-2 {
			left: calc(50% - 18em);
			z-index: 10;
		}
		&.animate-poof {
			&.right {
				.Character {
					animation: slideOutToRight 0.25s ease-in-out 1.5s forwards;
					-webkit-animation: slideOutToRight 0.25s ease-in-out 1.5s forwards;
				}
			}
			&::after {
				content: '';
				position: absolute;
				top: 0;
				left: -6.4em;
				display: block;
				width: 24.7em;
				height: 21.9em;
				background-image: url('../../../../assets/images/characters/items/poof.svg');
				@include opacity(0);
				@include animatePow(1s, 1s);
	
			}

		}
		&.animate-chop {
			&::after {
				content: '';
				display: block;
				position: absolute;
				bottom: -5.8em;
				left: -14em;
				width: 24.7em;
				height: 21.9em;
				z-index: 9;
				background-image: url('../../../../assets/images/characters/items/chop.svg');
				@include opacity(0);
				@include animateChop(1s, 1s);
	
			}
		}
		&.ocean {
			left: calc(50% - 8em / 2);
			bottom: 2.6em;
			z-index: 2;

			&::before {
				content: '';
				display: block;
				position: absolute;
				width: 6.5em;
				height: 2em;
				bottom: -1em;
				left: 0.75em;
				z-index: 0;
				background-image: url('../../../../assets/images/characters/items/ocean-ring.svg');
				background-position: bottom center;
				background-size: contain;
			}
		}

		&.ocean-rock {
			left: calc(50% + 6.7em);
			bottom: 7.4em;
		}

		&.onTop {
			z-index: 10;
		}

		&.wizard-arm-out.flipped.right {
			left: calc(50% - 5em);
		}
		&.right.animate-enter {
			@include translate(1000vw);
			animation: slideInFromRight 0.5s ease-in-out 0.25s forwards;
			-webkit-animation: slideInFromRight 0.5s ease-in-out 0.25s forwards;
		}
	}
	
	.Instructions-character {
		position: absolute;
		left: calc(50% - 11.75em);
		bottom: 0;
		z-index: 3;
		&.skipper,
		&.skipper-parrot {
			left: calc(50% - 11em);
		}
		&.skipper-winter-gear,
		&.skipper-winter-gear-pick-axe {
			left: calc(50% - 11em);
		}
	}

	.Instructions-avatar {
		position: absolute;
		left: calc(50% - 5.8em);
		bottom: -0.8em;
		z-index: 2;
		overflow: hidden;

		&.ocean {
			overflow: visible;
			bottom: 2em;
			left: calc(50% - 1em);
			&::before {
				content: '';
				display: block;
				position: absolute;
				width: 7.5em;
				height: 3em;
				bottom: -0.75em;
				left: 3.75em;
				background-image: url('../../../../assets/images/characters/items/ocean-ring.svg');
				background-position: bottom center;
				background-size: contain;
			}
		}
	}

	&.fadeoutSpeachBubble {
		.Instructions-speechBubble {
			@include fadeOut(0.1s, 'ease', 1.25s);
		}
	}

	&.animateCharacter-bubble {
		.Instructions-speechBubble {
			@include fadeIn(0.25s, 'ease', 0.75s);
		}
	}


	&.animateCharacter-1 {
		.Instructions-speechBubble {
			@include fadeIn(0.25s, 'ease', 0.75s);

		}
		.Instructions-character {
			@include translate(-1000vw);
			animation: slideInFromLeft 0.5s ease-in-out 0.25s forwards;
			-webkit-animation: slideInFromLeft 0.5s ease-in-out 0.25s forwards;
		}
	}

	&.animateCharacter-2 {
		.Instructions-speechBubble {
			@include fadeOut(0.25s, 'ease', 1.5s);
		}
		.Instructions-character {
			animation: slideOutToLeft 0.5s ease-in-out 1.5s forwards;
			-webkit-animation: slideOutToLeft 0.5s ease-in-out 1.5s forwards;
		}
	}

	&.animateCharacter-poof {
		.Instructions-speechBubble {
			@include fadeOut(0.1s, 'ease', 1.25s);
		}
		.Instructions-character::after {
			content: '';
			position: absolute;
			top: 0;
			left: -6.4em;
			display: block;
			width: 24.7em;
			height: 21.9em;
			background-image: url('../../../../assets/images/characters/items/poof.svg');
			@include opacity(0);
			@include animatePow(1s, 1s);

		}
		.Instructions-character .Character {
			animation: slideOutToLeft 0.25s ease-in-out 1.5s forwards;
			-webkit-animation: slideOutToLeft 0.25s ease-in-out 1.5s forwards;
		}
	}

	.Instructions-item {
		position: absolute;
		z-index: 4;
		&.big-apple {
			bottom: 6em;
			left: calc(50% - 1.5em);
			width: 11.5em;
			height: 12.14em;
			background-image: url('../../../../assets/images/modules/tasks/instructions/big-apple.svg');
		}
		&.bin-of-tomatoes {
			z-index: 0;
			bottom: 0em;
			left: calc(50% - 1.5em);
			width: 13.56em;
			height: 8.65em;
			background-position: top center;
			background-image: url('../../../../assets/images/modules/tasks/instructions/bin-of-tomatoes.svg');
		}
		&.box-items {
			bottom: 0;
			left: calc(50% - 5.1em);
			width: 10.2em;
			height: 6.7em;
			background-image: url('../../../../assets/images/modules/tasks/instructions/box-items.svg');
		}
		&.boxes {
			bottom: -2em;
			left: calc(50%);
			width: 21em;
			height: 12.8em;
			background-image: url('../../../../assets/images/modules/tasks/instructions/boxes.svg');
		}
		&.bread-on-table {
			bottom: 0em;
			left: calc(50% - 13em);
			width: 24.26em;
			height: 6.82em;
			background-image: url('../../../../assets/images/modules/tasks/instructions/bread-on-table.svg');
			background-position: top center;
			background-size: 100% auto;
		}
		&.clipboard-wall {
			bottom: 6em;
			left: calc(50% - 2.5em);
			width: 12em;
			height: 11em;
			background-image: url('../../../../assets/images/modules/tasks/instructions/clipboard-wall.svg');
			background-position: bottom center;
		}
		&.frying-pan-and-veg-oil {
			bottom: 0;
			left: calc(50% - 2em);
			width: 11.96em;
			height: 8.81em;
			background-image: url('../../../../assets/images/modules/tasks/instructions/frying-pan-and-veg-oil.svg');
			background-position: bottom center;
		}
		&.penguin-1 {
			bottom: 10.26em;
			left: calc(50% - 1em);
			width: 3.2em;
			height: 4.2em;
			background-image: url('../../../../assets/images/modules/tasks/instructions/penguin.svg');
		}
		&.penguin-2 {
			bottom: 0em;
			left: calc(50% - 3em);
			width: 5.33em;
			height: 5.84em;
			background-position: top center;
			background-size: 100% auto;
			background-image: url('../../../../assets/images/modules/tasks/instructions/penguin.svg');
			z-index: 1;
		}
		&.penguin-3 {
			bottom: 0em;
			left: calc(50% + 0.5em);
			width: 6.34em;
			height: 5.5em;
			background-position: top center;
			background-size: 100% auto;
			background-image: url('../../../../assets/images/modules/tasks/instructions/penguin.svg');
			z-index: 4;
		}
		&.shelf-with-cans {
			bottom: -2.72em;
			left: calc(50% - 10.8em);
			width: 13.2em;
			height: 17.1em;
			background-image: url('../../../../assets/images/modules/tasks/instructions/shelf-with-cans.svg');
			z-index: 1;
		}
		&.shelf-with-cans-tags-on-floor {
			bottom: 0.5em;
			left: calc(50% - 10.8em);
			width: 13.2em;
			height: 19.3em;
			background-image: url('../../../../assets/images/modules/tasks/instructions/shelf-with-cans-tags-on-floor.svg');
			z-index: 1;
		}
		&.u-boat-boxes {
			bottom: -2em;
			left: calc(50% - 2em);
			width: 21em;
			height: 12.8em;
			background-image: url('../../../../assets/images/modules/tasks/instructions/u-boat-boxes.svg');
		}
	}

	&.landscape {
		.Instructions-speechBubble { 
			left: calc(50% - 13.5em);
		}
		.Instructions-character {
			left: calc(50% - 16.5em);
		}
		.Instructions-extraCharacter {
			&.hiding {
				left: calc(50% - 17.75em);
			}
		}
	}
}