@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/mixins_backgrounds';
@import '../../../styles/animations';
@import '../../../styles/animations-intro';
.PopupReportPrompt {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
	background-color: rgba(#092335, 0.6);
	width: 100%;
	height: 100%;
	@include flex('center', 'center');
	.PopupReportPrompt-content {
		padding: 1.5em 1em 0em 1em;
		width: 17.2em;
		height: 7.5em;
		background-color: white;
		border-radius: 0.5em;
		@include flex('flex-start', 'center');
		flex-wrap: wrap;
		flex-direction: column;
		gap: 1em;
		.PopupReportPrompt-title{
			width: 100%;
			height: 1em;
			position: relative;
			@include flex('center', 'center');
			margin-top: 1em;
			padding-bottom: 0.74em;
			span {
				border-radius: 0.55em;
				font-weight: bold;
				text-align: center;
				text-decoration: none;
				font-family: "Roboto", sans-serif;
				color: $blue-dark2;
			}
		}
		.PopupReportPrompt-buttonsWrapper {
			@include flex('center', 'space-between');
			height: 1.8em;
			width: 100%;
			padding: 0 2em;
			gap: 2em;
			margin-top: 0.5em;
			.Button {
				height: 1.8em;
				width: 5.6em;
			}
			
		}
	}
	
}