@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/mixins_backgrounds';
@import '../../../styles/animations';
@import '../../../styles/animations-intro';
.Character {
	position: relative;
	background-size: auto 100%;
	background-position: bottom right;

	.Character-items {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	.Character-character {
		position: relative;
		z-index: 2;
		
		/* Bearded man */
		&.bearded-man-confused {
			width: 8.7em;
			height: 14.8em;
			background-image: url('../../../assets/images/characters/bearded-man-confused.svg');
			background-size: 100% auto;
			background-position: bottom center;
		}
		&.bearded-man-happy {
			width: 6.48em;
			height: 14.8em;
			background-image: url('../../../assets/images/characters/bearded-man-happy.svg');
			background-size: 100% auto;
			background-position: bottom center;
		}

		/* BOB */
		&.bob-1 {
			width: 10em;
			height: 17.89em;
			background-image: url('../../../assets/images/characters/bob-1.svg');
			background-size: 10em auto;
			background-position: top center;
		}

		/* Butcher */
		&.butcher,
		&.butcher-1 {
			width: 9.7em;
			height: 19.4em;
			background-image: url('../../../assets/images/characters/butcher.svg');
			background-position: bottom center;
		}
		&.butcher-arm-out,
		&.butcher-arm-out-1 {
			width: 14.48em;
			height: 19.4em;
			background-image: url('../../../assets/images/characters/butcher-arm-out.svg');
		}

		/* Chef */
		&.chef,
		&.chef-1 {
			width: 9.7em;
			height: 19.4em;
			background-image: url('../../../assets/images/characters/chef.svg');
			background-position: bottom center;
		}

		/* Elderly lady */
		&.elderly-lady-with-cart {
			z-index: 1;
			margin: auto;
			width: 31em;
			height: 30.25em;
			background-size: 100% auto;
			background-position: bottom center;
			background-image: url('../../../assets/images/characters/elderly-lady-with-cart.svg');
		}

		/* Giant sea creature */
		&.giant-sea-creature {
			bottom: 8.5em;
			left: calc(50% - 1.5em);
			width: 11.17em;
			height: 6.43em;
			background-image: url('../../../assets/images/characters/giant-sea-creature.svg');
		}

		/* Man with box(es) */
		&.man-with-box,
		&.man-with-box-angry,
		&.man-with-box-annoyed {
			margin: auto;
			width: 10em;
			height: 19em;
			background-image: url('../../../assets/images/characters/man-with-box.svg');
			background-size: 100% auto;
			background-position: bottom -1.75em center;
		}
		&.man-with-box-angry {background-image: url('../../../assets/images/characters/man-with-box-angry.svg');}
		&.man-with-box-annoyed {background-image: url('../../../assets/images/characters/man-with-box-annoyed.svg');}

		&.man-with-boxes,
		&.man-with-boxes-angry,
		&.man-with-boxes-annoyed {
			margin: auto;
			width: 10em;
			height: 19em;
			background-image: url('../../../assets/images/characters/man-with-boxes.svg');
			background-size: 100% auto;
			background-position: bottom -1.75em center;
		}
		&.man-with-boxes-angry {background-image: url('../../../assets/images/characters/man-with-boxes-angry.svg');}
		&.man-with-boxes-annoyed {background-image: url('../../../assets/images/characters/man-with-boxes-annoyed.svg');}

		/* Middle-aged man */
		&.middle-aged-man-angry {
			width: 7em;
			height: 18.89em;
			background-image: url('../../../assets/images/characters/middle-aged-man-angry.svg');
		}
		&.middle-aged-man-happy {
			width: 7em;
			height: 18.89em;
			background-image: url('../../../assets/images/characters/middle-aged-man-happy.svg');
		}
		&.middle-aged-man-with-bag {
			width: 9em;
			height: 18.89em;
			background-image: url('../../../assets/images/characters/middle-aged-man-with-bag.svg');
			background-position: bottom -0.75em right 0em;
		}
		&.middle-aged-man-with-watch,
		&.middle-aged-man-with-watch-happy {
			width: 8em;
			height: 18.89em;
			bottom: -0.5em;
		}
		&.middle-aged-man-with-watch {
			background-image: url('../../../assets/images/characters/middle-aged-man-with-watch.svg');
		}
		&.middle-aged-man-with-watch-happy {
			background-image: url('../../../assets/images/characters/middle-aged-man-with-watch-happy.svg');
		}

		/* Old lady */
		&.old-lady-1 {
			width: 7.6em;
			height: 17.5em;
			background-image: url('../../../assets/images/characters/old-lady.svg');
		}
		&.old-lady,
		&.old-lady-angry,
		&.old-lady-happy {
			width: 7em;
			height: 15em;
			background-image: url('../../../assets/images/characters/old-lady.svg');
		}
		&.old-lady-angry {background-image: url('../../../assets/images/characters/old-lady-angry.svg');}
		&.old-lady-happy {background-image: url('../../../assets/images/characters/old-lady-happy.svg');}

		/* Old man */
		&.old-man-with-cat-food,
		&.old-man-with-cat-food-annoyed,
		&.old-man-with-cat-food-angry {
			width: 14.35em;
			height: 18.765em;
			background-image: url('../../../assets/images/characters/old-man-with-cat-food.svg');
		}
		&.old-man-with-cat-food-annoyed {background-image: url('../../../assets/images/characters/old-man-with-cat-food-annoyed.svg');}
		&.old-man-with-cat-food-angry {background-image: url('../../../assets/images/characters/old-man-with-cat-food-angry.svg');}

		&.old-man {
			width: 14.35em;
			height: 18.765em;
			background-image: url('../../../assets/images/characters/old-man.svg');
		}
		&.old-man-annoyed {background-image: url('../../../assets/images/characters/old-man-with-cat-food-annoyed.svg');}
		&.old-man-angry {background-image: url('../../../assets/images/characters/old-man-with-cat-food-angry.svg');}

		/* Parrot */
		&.parrot {
			width: 2.7em;
			height: 4.5em;
			background-image: url('../../../assets/images/characters/parrot.svg');
			background-position: bottom center;
		}

		/* Pirate */
		&.pirate,
		&.pirate-1 {
			width: 15.71em;
			height: 19.96em;
			background-image: url('../../../assets/images/characters/pirate.svg');
			background-position: bottom -0.9em left 1em;
		}
		&.pirate-arm-out,
		&.pirate-arm-out-1 {
			width: 15.71em;
			height: 19.96em;
			background-image: url('../../../assets/images/characters/pirate-arm-out.svg');
			background-position: bottom -0.9em left 1em;
		}

		/* Poof */
		&.poof {
			width: 24.7em;
			height: 21.9em;
			background-image: url('../../../assets/images/characters/items/poof.svg');
			background-position: bottom 1em center;
		}

		/* Shopping cart lady */
		&.shopping-cart-lady-neutral,
		&.shopping-cart-lady-angry,
		&.shopping-cart-lady-happy {
			width: 15.8em;
			height: 19.25em;
			background-position: bottom -2em right 0;
		}
		&.shopping-cart-lady-neutral {
			background-image: url('../../../assets/images/characters/shopping-cart-lady-neutral.svg');
		}
		&.shopping-cart-lady-angry {
			background-image: url('../../../assets/images/characters/shopping-cart-lady-angry.svg');
		}
		&.shopping-cart-lady-happy {
			background-image: url('../../../assets/images/characters/shopping-cart-lady-happy.svg');
		}

		/* Skipper w/o parrot */
		&.skipper-1 {
			width: 10em;
			height: 19.4em;
			background-image: url('../../../assets/images/characters/skipper.svg');
			background-position: bottom center;
		}
		&.skipper-2 {
			width: 10em;
			height: 19.4em;
			background-image: url('../../../assets/images/characters/skipper-2.svg');
			background-position: bottom center;
		}
		&.skipper-3 {
			width: 10em;
			height: 12.6em;
			background-image: url('../../../assets/images/characters/skipper-3.svg');
			background-position: bottom -5.6em center;
		}
		&.skipper-parrot-1 {
			width: 11.43em;
			height: 19.4em;
			background-image: url('../../../assets/images/characters/skipper-parrot.svg');
			background-position: bottom center;
		}
		&.skipper-parrot-2 {
			width: 11.43em;
			height: 19.4em;
			background-image: url('../../../assets/images/characters/skipper-parrot-2.svg');
			background-position: bottom center;
		}
		&.skipper-parrot-3 {
			width: 10em;
			height: 19.5em;
			background-image: url('../../../assets/images/characters/skipper-parrot-3.svg');
			background-position: bottom -5.6em center;
		}
		&.skipper-parrot-4 {
			width: 15em;
			height: 18.8em;
			background-image: url('../../../assets/images/characters/skipper-parrot-4.svg');
			background-position: bottom center;
		}
		&.skipper-winter-gear,
		&.skipper-winter-gear-1 {
			width: 10em;
			height: 19.4em;
			background-image: url('../../../assets/images/characters/skipper-winter-gear.svg');
			background-position: bottom center;
		}
		&.skipper-winter-gear-pick-axe,
		&.skipper-winter-gear-pick-axe-1 {
			width: 11.78em;
			height: 19.4em;
			background-image: url('../../../assets/images/characters/skipper-winter-gear-pick-axe.svg');
			background-position: bottom center;
		}

		/* Wizard */
		&.wizard,
		&.wizard-1 {
			width: 12em;
			height: 25.25em;
			background-image: url('../../../assets/images/characters/wizard-1.svg');
			background-position: bottom -6.25em center;
			&.ocean {
				bottom: 0;
				width: 8em;
				height: 13.11em;
				background-position: top 0em center;
				background-size: 8em 17.4em;
			}
			&.small {
				width: 7.8em;
				height: 12.8em;
				background-position: bottom -3.25em right;
			}
		}
		&.wizard-side,
		&.wizard-side-1 {
			width: 10em;
			height: 23em;
			background-image: url('../../../assets/images/characters/wizard-side.svg');
			background-position: bottom -6.25em center;
			&.ocean {
				position: absolute;
				bottom: 0;
				width: 8em;
				height: 13.11em;
				background-image: url('../../../assets/images/characters/wizard-side.svg');
				background-position: top 0em center;
				background-size: 8em 17.4em;
			}
		}
		&.wizard-arm-out,
		&.wizard-arm-out-1 {
			width: 15em;
			height: 18.8em;
			background-image: url('../../../assets/images/characters/wizard-arm-out.svg');
		}
		&.wizard-black-eye,
		&.wizard-black-eye-1 {
			width: 11.48em;
			height: 24.93em;
			background-image: url('../../../assets/images/characters/wizard-black-eye.svg');
			&.instructions {
				bottom: -6.22em;
				z-index: 3;
			}
		}
		&.wizard-child {
			width: 12em;
			height: 25.25em;
			background-image: url('../../../assets/images/characters/wizard-child.svg');
		}
		&.wizard-customer,
		&.wizard-customer-1 {
			width: 11em;
			height: 19.2em;
			background-image: url('../../../assets/images/characters/wizard-customer.svg');
			background-position: bottom 0em center;
		}
		&.wizard-dragon,
		&.wizard-dragon-1 {
			width: 11.48em;
			height: 18.81em;
			background-image: url('../../../assets/images/characters/wizard-dragon.svg');
		}
		&.wizard-karate,
		&.wizard-karate-1 {
			width: 11.48em;
			height: 24.93em;
			background-image: url('../../../assets/images/characters/wizard-karate.svg');
			&.instructions {
				bottom: -6.22em;
				z-index: 3;
			}
			&.branching-story {
				margin: auto;
				width: 5.84em;
				height: 12.5em;
			}
		}
		&.wizard-flower,
		&.wizard-flower-1 {
			width: 10em;
			height: 25em;
			background-image: url('../../../assets/images/characters/wizard-flower.svg');
			background-position: bottom -6em center;
		}
		&.wizard-stink,
		&.wizard-stink-1 {
			width: 17.6em;
			height: 19.6em;
			background-image: url('../../../assets/images/characters/wizard-stink.svg');
			background-position: bottom -0.8em center;
		}
		&.wizard-skates,
		&.wizard-skates-1 {
			width: 6.44em;
			height: 16.94em;
			background-image: url('../../../assets/images/characters/wizard-skates.svg');
		}


		/* Woman with baby */
		&.woman-with-baby {
			width: 15.76em;
			height: 16.29em;
			background-image: url('../../../assets/images/characters/woman-with-baby.svg');	
			background-size: 100% auto;
			background-position: bottom -3em center;
		}

		/* Woman with food / handbag */
		&.woman-with-sandwich-happy,
		&.woman-with-pizza-angry {
			margin: auto;
			width: 11.32em;
			height: 17.22em;
			background-size: 100% auto;
			background-position: top center;
		}
		&.woman-with-sandwich-happy {background-image: url('../../../assets/images/characters/woman-with-sandwich-happy.svg');}
		&.woman-with-pizza-angry {background-image: url('../../../assets/images/characters/woman-with-pizza-angry.svg');}
		&.woman-with-handbag,
		&.woman-with-handbag-angry,
		&.woman-with-handbag-very-angry {
			width: 10em;
			height: 16.67em;
			background-image: url('../../../assets/images/characters/woman-with-handbag.svg');	
			background-size: 100% auto;
			background-position: top center;
		}
		&.woman-with-handbag-angry {background-image: url('../../../assets/images/characters/woman-with-handbag-angry.svg');}
		&.woman-with-handbag-very-angry {background-image: url('../../../assets/images/characters/woman-with-handbag-very-angry.svg');}

		/* General */
		&.flipped {
			@include scaleX(-1);
		}
	}
}