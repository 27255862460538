@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/mixins_backgrounds';
@import '../../../styles/animations';
@import '../../../styles/animations-intro';
.Item {
	width: 100%;
	height: 100%;
	background-size: contain;;
	background-image: url('../../../assets/images/badges/unknown.svg');
	&.apron-f-1 {background-image: url('../../../assets/images/avatar-maker/work-clothes/apron-f-1.svg')}
	&.balloon-yay-me {background-image: url('../../../assets/images/avatar-maker/items/balloon-yay-me.svg');}
	&.banana-headdress {background-image: url('../../../assets/images/avatar-maker/hats/banana-headdress.svg');}
	&.beard-11 {background-image: url('../../../assets/images/avatar-maker/beard/beard-11.svg');}
	&.bucket-hat {background-image: url('../../../assets/images/avatar-maker/hats/bucket-hat.svg');}
	&.bunny-ears {background-image: url('../../../assets/images/avatar-maker/hats/bunny-ears.svg');}
	&.chefs-hat {background-image: url('../../../assets/images/avatar-maker/hats/chefs-hat.svg');}
	&.chicken-wings {background-image: url('../../../assets/images/avatar-maker/accessories/chicken-wings.svg');}
	&.cupcake-hat {background-image: url('../../../assets/images/avatar-maker/hats/cupcake-hat.svg');}
	&.disco-chicken {background-image: url('../../../assets/images/avatar-maker/animals/disco-chicken.svg')}
	&.donut-hat {background-image: url('../../../assets/images/avatar-maker/hats/donut-hat.svg');}
	&.fish {background-image: url('../../../assets/images/avatar-maker/animals/fish.svg')}
	&.flower-pot {background-image: url('../../../assets/images/avatar-maker/hats/flower-pot.svg');}
	&.four-leaf-clover {background-image: url('../../../assets/images/avatar-maker/accessories/four-leaf-clover.svg');}
	&.giant-log {background-image: url('../../../assets/images/avatar-maker/hats/giant-log.svg')}
	&.green-thumb-badge {background-image: url('../../../assets/images/avatar-maker/accessories/green-thumb-badge.svg');}
	&.hairnet {background-image: url('../../../assets/images/avatar-maker/hats/hairnet.svg');}
	&.jarzard {background-image: url('../../../assets/images/avatar-maker/animals/jarzard.svg')}
	&.karate-headband {background-image: url('../../../assets/images/avatar-maker/hats/karate-headband.svg')}
	&.leather-jacket-m {background-image: url('../../../assets/images/avatar-maker/clothes/leather-jacket.svg')}
	&.lifejacket-m {background-image: url('../../../assets/images/avatar-maker/work-clothes/lifejacket.svg')}
	&.lightsaber-broom {background-image: url('../../../assets/images/avatar-maker/items/lightsaber-broom.svg');}
	&.lobster-bib {background-image: url('../../../assets/images/avatar-maker/accessories/lobster-bib.svg');}
	&.lobster-hat {background-image: url('../../../assets/images/avatar-maker/hats/lobster-hat.svg');}
	&.mop-hat {background-image: url('../../../assets/images/avatar-maker/hats/mop-hat.svg');}
	&.mounted-singing-fish {background-image: url('../../../assets/images/avatar-maker/decorations/mounted-singing-fish.svg');}
	&.parrot {background-image: url('../../../assets/images/avatar-maker/animals/parrot.svg')}
	&.penguin-suit {background-image: url('../../../assets/images/avatar-maker/work-clothes/penguin-suit.svg')}
	&.plank-of-wood {background-image: url('../../../assets/images/avatar-maker/animals/plank-of-wood.svg')}
	&.sandwich-costume-m {background-image: url('../../../assets/images/avatar-maker/work-clothes/sandwich-costume.svg')}
	&.shark-hat {background-image: url('../../../assets/images/avatar-maker/hats/shark-hat.svg')}
	&.shirt-m-13 {background-image: url('../../../assets/images/avatar-maker/clothes/shirt-m-13.svg')}
	&.shirt-m-cowprint {background-image: url('../../../assets/images/avatar-maker/clothes/shirt-m-cowprint.svg')}
	&.shirt-m-milk-sandwich {background-image: url('../../../assets/images/avatar-maker/clothes/shirt-m-milk-sandwich.svg')}
	&.shirt-tropical-m {background-image: url('../../../assets/images/avatar-maker/clothes/shirt-tropical.svg')}
	&.smelly-cheese-crown {background-image: url('../../../assets/images/avatar-maker/hats/smelly-cheese-crown.svg');}
	&.cloud-stink {background-image: url('../../../assets/images/avatar-maker/accessories/cloud-stink.svg');}
	&.toad {background-image: url('../../../assets/images/avatar-maker/animals/toad.svg');}
	&.wand-toy {background-image: url('../../../assets/images/avatar-maker/items/wand-toy.svg');}
	&.watermelon-helmet {background-image: url('../../../assets/images/avatar-maker/hats/watermelon-helmet.svg')}
	&.wet-floor-sign-hat {background-image: url('../../../assets/images/avatar-maker/hats/wet-floor-sign-hat.svg');}
}
