@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/mixins_backgrounds';
@import '../../../styles/animations';
@import '../../../styles/animations-intro';
.FacilitatorHeaderSubpage {
	position: relative;
	width: 100%;
	
	.FacilitatorHeaderSubpage-menu {
		position: relative;
		width: 72em;
		height: 2.25em;
		@include flex('flex-start', 'flex-end');
		&::after {
			content: '';
			position: absolute;
			left: 0;
			top: 2.25em;
			width: 100%;
			height: 0.1em;
			background-color: white;
			@include box-shadow(0.1em, 0.1em, 0.25em, 0, rgba(black, 0.16));
		}
		.FacilitatorHeaderSubpage-menuItem {
			display: inline-block;
			width: 9.11em;
			height: 100%;
			margin-right: 1.33em;
			border-top-left-radius: 0.83em;
			border-top-right-radius: 0.83em;
			background-color: #EDEDED;
			color: #7C7C7C;
			cursor: pointer;
			@include flex('center', 'center');
			span {
				font-family: 'Roboto', sans-serif;
				font-size: 1.11em;
				font-weight: bold;
			}
			&.selected {
				background-color: #BFBFBF;
				color: #525252;
				@include box-shadow(0.5em, 0, 0.25em, 0, rgba(black, 0.16));
			}
		}
	}

	.FacilitatorHeaderSubpage-description {
		margin-top: 1.5em;
		.FacilitatorHeaderSubpage-descriptionIcon {
			display: inline-block;
			vertical-align: middle;
			width: 3em;
			height: 1.75em;
			background-size: contain;
			background-position: left center;
			&.players {background-image: url('../../../assets/images/facilitator/icon-players-blue.svg');}
			&.game-modules {background-image: url('../../../assets/images/facilitator/icon-game-modules-blue.svg');}
			&.resources {background-image: url('../../../assets/images/facilitator/icon-resources-blue.svg');}
			&.insights {background-image: url('../../../assets/images/facilitator/icon-insights-blue.svg');}
			&.competitions {background-image: url('../../../assets/images/facilitator/icon-competitions-blue.svg');}
			&.messages {background-image: url('../../../assets/images/facilitator/icon-messages-blue.svg');}
			&.statistics, &.tasks {background-image: url('../../../assets/images/facilitator/icon-statistics-blue.svg');}
			&.facilitators {background-image: url('../../../assets/images/facilitator/icon-players-2-blue.svg');}
		}
		.FacilitatorHeaderSubpage-descriptionText {
			display: inline-block;
			vertical-align: middle;
			p {margin: 0;}
		}
	}
}