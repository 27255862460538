@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/mixins_backgrounds';
@import '../../../styles/animations';
@import '../../../styles/animations-intro';
.ProfileAvatar {
	.ProfileAvatar-title {
		color: #11466B;
		font-weight: 900;
		font-size: 1.56em;	
		text-align: center;
	}
	.ProfileAvatar-avatar {
		position: absolute;
		left: calc(50% - 12.42em);
		bottom: 2.7em;
	}
	.ProfileAvatar-yearbookBtn {
		position: absolute;
		left: calc(50% + 0.1em - 5em*2);
		top: 6.16em;
		width: 5em;
		height: 1.8em;
		border-radius: 1em;
		background-color: #F5F5F5;
		color: white;
		cursor: pointer;
		@include flex('center', 'center');
		span {
			color: #11466B;
			font-size: 0.8em;
			font-weight: bold;
		}
		&:hover {
			background-color: darken(#F5F5F5, 3%);
		}
	}
	.ProfileAvatar-avatarBtn {
		position: absolute;
		left: calc(50% - 4.425em);
		top: 5.94em;
		width: 8.85em;
		height: 2.25em;
		border-radius: 1em;
		background-color: #10779E;
		color: white;
		cursor: pointer;
		@include flex('center', 'center');
		span {
			font-family: 'Source Sans Pro', sans-serif;
			font-size: 0.85em;
			font-weight: bold;
		}
		&:hover {
			background-color: darken(#10779E, 3%);
		}
		&.highlight {
			border-radius: 3em;
			border: 0.2em solid white;
			@include box-shadow(0, 0, 0.56em, 0, white);
		}
	}
}
