@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/mixins_backgrounds';
@import '../../../styles/animations';
@import '../../../styles/animations-intro';
.Profile {
	position: relative;
	height: 100%;
	background-image: radial-gradient(ellipse closest-side, #D7D7D7, #B5B5B5);

	.Profile-content {
		height: calc(100% - 5.2em);
		padding: 3.5em 1em 1.5em 1em;
	}

	.Profile-backBtn {
		position: absolute;
		top: 0.25em;
		left: 0.25em;
	}

	.Profile-userName {
		position: absolute;
		pointer-events: none;
		top: 0.5em;
		right: 0.5em;
		left: 3.5em;
		text-align: right;
		overflow: hidden;
		span {
			font-size: 0.85em;
			color: #10779E;
		}		
	}

	.Profile-logoutBtn {
		position: absolute;
		top: 2em;
		width: 5.6em;
		right: 0.5em;
	}
	.Profile-profileBtn {
		position: absolute;
		top: 0.25em;
		right: 0.25em;
		width: 3em;
		height: 3em;
		@include flex('center', 'center');
	}

	.Profile-navigation {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 5.2em;
		background-color: #7DBCE8;
		@include flex('space-between', 'center');
		.Profile-navItem {
			position: relative;
			height: 100%;
			width: calc((100% - 0.22em) * 0.333);
			box-sizing: content-box;
			background-image: linear-gradient(#10779E, #15476A);
			background-size: 100% 100%;
			cursor: pointer;
			border-right: 0.11em solid #7DBCE8;
			border-top: 0.11em solid #7DBCE8;
			&:last-child {
				border-right: 0em solid #7DBCE8;
			}
			&.selected {
				background-image: linear-gradient(#15476A, #113955);
			}

			.Profile-navItemIcon {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				text-align: center;
				overflow: hidden;
				filter: drop-shadow(0 0 0.66em #A4D0EF);
				img {
					margin-top: 0.5em;
					width: auto;
					height: 3.2em;
				}
				
			}

			.Profile-navItemLabel {
				position: absolute;
				left: 0;
				bottom: 1em;
				width: 100%;
				font-size: 0.56em;
				font-weight: bold;
				text-align: center;
				color: white;
				text-transform: uppercase;
			}
		}
	}

	&.landscape {
		.Profile-backBtn {
			left: calc(50% - 13.5em);
		}
		.Profile-userName,
		.Profile-logoutBtn,
		.Profile-profileBtn {
			right: calc(50% - 13.5em);
		}
	}
}