@import '../../../../../styles/variables';
@import '../../../../../styles/fonts';
@import '../../../../../styles/mixins';
@import '../../../../../styles/mixins_backgrounds';
@import '../../../../../styles/animations';
@import '../../../../../styles/animations-intro';
.AdminGamePopupStats {
	position: relative;
	padding: 0em 0.75em;
	margin-top: 1.5em;
	height: calc(100% - 1.5em);
	overflow: auto;
}