@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';
@import '../../../../styles/mixins_backgrounds';
@import '../../../../styles/animations';
@import '../../../../styles/animations-intro';
.FacilitatorPlayerReportsPopup {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(#7ABCEA, 0.81);
	@include flex('center', 'center');

	.FacilitatorPlayerReportsPopup-content {
		width: 50.16em;
		height: 34.83em;
		background-color: white;
		border-radius: 0.83em;

		.FacilitatorPlayerReportsPopup-header {
			position: relative;
			width: 100%;
			min-height: 3.61em;
			background-color: #11466B;
			border-top-left-radius: 0.83em;
			border-top-right-radius: 0.83em;
			border: 0.25em solid white;
			padding-left: 1em;
			@include flex('center', 'center');
			.FacilitatorPlayerReportsPopup-closeBtn {
				position: absolute;
				top: 0;
				right: 0;
				width: 2.33em;
				height: 2.33em;
				background-image: url('../../../../assets/images/facilitator/icon-close.svg');
				background-size: 1.56em auto;
				cursor: pointer;
			}
			.FacilitatorPlayerReportsPopup-title {
				color: #ffffff;
				font-size: 1.25em;
				font-weight: 600;
			}
		}

		.FacilitatorPlayerReportsPopup-body {
			@include flex('center', 'center');
			flex-direction: column;
			.FacilitatorPlayerReportsPopup-text {
				height: 4.166em;
				padding-left: 1em;
				margin-top: 0.944em;
				span {
					color: #11466B;
					font-size: 1.11em;
					font-weight: 600;
					p {
						margin: 0;
					}

				}
			}
			
			.FacilitatorPlayerReportsPopup-table {
				width: 100%;
				overflow: auto;
				&::-webkit-scrollbar {
					width: 0.5em;
					background-color: #707070;
				}

				height: 25em;
				border-radius: 0.83em;
				
				.FacilitatorPlayerReportsPopup-row:nth-child(2) {
					margin-top: 1.5em;
				}

				.FacilitatorPlayerReportsPopup-cell {
					width: 50%;
					height: 100%;
					padding: 0 0.5em;
					@include flex('flex-start', 'center');
				}
				.FacilitatorPlayerReportsPopup-tableheader {
					width: 50.2em;
					height: 1.55em;
					color: white;
					background-color: #11466B;
					@include flex('space-between', 'center');
					position: fixed;

					.FacilitatorPlayerReportsPopup-cell {
						span {
							display: inline-block;
							vertical-align: middle;
							position: relative;
							font-weight: 600;
						}
						&.name {
							width: 25%;
						
						}
						&.reportTime  {
							width: 20%;
						}
						&.yearbook {
							width: 45%;
						}
					}
					

				}

				.FacilitatorPlayerReportsPopup-row {
					cursor: pointer;
					width: 100%;
					height: 6.11em;
					color: #707070;
					&:nth-child(odd) {
						background-color: #EDEDED;
					}
					
					
					@include flex('space-between', 'center');
					.FacilitatorPlayerReportsPopup-cell {
						&.name {
							width: 25%;
							span {
								font-weight: 600;
							}
							overflow: hidden;

						}
						&.reportTime {
							width: 20%;
		
						}
						&.yearbook {
							width: 45%;
							@include flex('center', 'flex-start');
							flex-direction: column;
			
						}
					}
					.FacilitatorPlayerReportsPopup-delete {
						display: none;
					}
					&.seen {
						position: relative;
						background-color: rgba(0, 128, 0, 0.253);
						cursor: default;
						.FacilitatorPlayerReportsPopup-delete {
							display: flex;
							position: absolute;
							top: 2em;
							right: 1em;
							width: 6em;
							height: 2em;
							border-radius: 50%;
						}
					}
				}
			}
		}
	}

	.FacilitatorPlayerReportsPopup-deletePopup {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 2;
		background-color: rgba(#092335, 0.6);
		width: 100%;
		height: 100%;
		@include flex('center', 'center');
	.FacilitatorPlayerReportsPopup-deleteContent {
		padding: 1.5em 1em 0em 1em;
		width: 20em;
		height: 7em;
		background-color: white;
		border-radius: 0.5em;
		.FacilitatorPlayerReportsPopup-deleteTitle{
			width: 100%;
			height: 1em;
			position: relative;
			@include flex('center', 'center');
			margin-top: 0.5em;
			padding-bottom: 1.74em;
			span {
				border-radius: 0.55em;
				font-weight: bold;
				text-align: center;
				text-decoration: none;
				font-family: "Roboto", sans-serif;
				color: $blue-dark2;
			}
		}
		.FacilitatorPlayerReportsPopup-deleteButtonsWrapper {
			@include flex('center', 'space-between');
			height: 1.8em;
			width: 100%;
			padding: 0 2em;
			gap: 2em;
			.Button {
				height: 1.8em;
				width: 5.6em;
			}
			
		}
	}
	}
}