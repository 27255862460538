@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/mixins_backgrounds';
@import '../../../styles/animations';
@import '../../../styles/animations-intro';
.Countdown { 
	position: relative;
	width: 8em;
	height: 8em;

	.Countdown-graphic {
		position: absolute;
		width: 8em;
		height: 8em;
		border-radius: 100%;
		@include scaleX(-1);

		mask-image: url('../../../assets/images/mask-shape.svg');
		mask-size: 100%;
		mask-composite: subtract;
		-webkit-mask-image: url('../../../assets/images/mask-shape.svg');
		-webkit-mask-composite: subtract;
		-webkit-mask-size: 100%;
	}
		
	.Countdown-numbering {
		position: absolute;
		width: 4em;
		height: 4em;
		left: calc(50% - 4em / 2);
		top: calc(50% - 4em / 2);
		text-align: center;
		@include flex(center, center);
		span {
			font-size: 2.9em;
			color: white;
		}
	}
}