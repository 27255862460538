.MultipleChoice {
	&.center-store.landscape {@include center-store-grey-landscape-background();}
	
	&.center-store-1-attention-areas {
		.MultipleChoice-options {
			margin-top: 1em;
			padding-bottom: 1em;
			.MultipleChoice-optionsWrap {
				width: 16em;
				height: 20.5em;
				margin: 0 auto;
				.MultipleChoice-option { 
					position: absolute;
					width: 4.75em;
					height: 6.5em;
					background-position: bottom center;
					span:nth-child(2) {
						bottom: 0;
						right: 0;
						left: auto;
					}
					&.position-1 {top: 0; left: 2em;}
					&.position-2 {top: 0; right: 2em;}
					&.position-3 {top: calc(50% - 3.25em); left: 0;}
					&.position-4 {top: calc(50% - 3.25em); left: calc(50% - 2.375em);}
					&.position-5 {top: calc(50% - 3.25em); right: 0;}
					&.position-6 {bottom: 0; left: 2em;}
					&.position-7 {bottom: 0; right: 2em;}
					&.option-1 {
						background-size: 4.36em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-1-attention-areas/option-1.svg');
					}
					&.option-2 {
						background-size: 4.36em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-1-attention-areas/option-2.svg');
					}
					&.option-3 {
						background-size: 2.89em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-1-attention-areas/option-3.svg');
					}
					&.option-4 {
						background-size: 100% auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-1-attention-areas/option-4.svg');
					}
					&.option-5 {
						background-size: 3.28em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-1-attention-areas/option-5.svg');
					}
					&.option-6 {
						background-size: 4.88em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-1-attention-areas/option-6.svg');
					}
					&.option-7 {
						background-size: 3.13em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-1-attention-areas/option-7.svg');
					}
				}
			}
		}
	}

	&.center-store-1-baby-food {
		.MultipleChoice-options {
			width: 18em;
			left: calc(50% - 9em);
			margin-top: 1em;
			padding-bottom: 1em;
			.MultipleChoice-optionsWrap {
				background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-1-baby-food/background.svg');
				background-position: top center;
				background-size: 18em auto;
				.MultipleChoice-option {
					position: relative; 
					width: 100%;
					height: 9em;		
					&.selected,
					&.correct,
					&.animateCorrect,
					&.wrong,
					&.animateWrong {@include noAnimation();}
					&.option-1 {height: 5.75em;}
					&.option-2 {height: 5.45em;}
					&.option-3 {height: 5.45em;}
					&.option-4 {height: 5.45em;}
				}
			}		
		}
	}

	&.center-store-1-cardboard-tray-pack {
		.MultipleChoice-options {
			margin-top: 2em;
			padding: 0 1.5em;
			.MultipleChoice-option { 
				position: relative;
				display: inline-block;
				width: 7.67em;
				height: 7.25em;
				span {
					bottom: 0.5em;
					left: auto;
					right: 0.25em;
					width: 1.39em;
					height: 1.39em;
				}
				&.position-1,
				&.position-3 {margin-right: calc(100% - 15.35em);}
				&.position-1,
				&.position-2 {margin-bottom: 2em;}
				&.option-1 {background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-1-cardboard-tray-pack/option-1.svg');}
				&.option-2 {background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-1-cardboard-tray-pack/option-2.svg');}
				&.option-3 {background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-1-cardboard-tray-pack/option-3.svg');}
				&.option-4 {
					background-size: 6.33em auto;
					background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-1-cardboard-tray-pack/option-4.svg');
				}
			}
		}
	}

	&.center-store-1-fill-shelf {
		.MultipleChoice-options {
			margin-top: 2em;
			.MultipleChoice-optionsWrap {
				margin: 0 auto;
				width: 17.75em;
				height: 8.25em;
				background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-1-fill-shelf/background.svg');
				background-size: 100% auto;
				background-position: bottom center;
	
				.MultipleChoice-option { 
					position: absolute;
					width: 3em;
					height: 6.3em;
					&.option-1 {
						top: 0;
						left: 2.52em;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-1-fill-shelf/option-1.svg');
					}
					&.option-2 {
						top: 0;
						left: 6.2em;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-1-fill-shelf/option-2.svg');	
						span:nth-child(2) {
							bottom: 3.9em;
							right: 0.5em;						
							left: auto;
						}
					}
					&.option-3 {
						top: 0;
						left: 13.56em;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-1-fill-shelf/option-3.svg');	
						span:nth-child(2) {
							bottom: 3.9em;
							right: 0em;						
							left: auto;
						}
					}
					&.option-4 {
						top: 0.92em;
						left: 4.89em;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-1-fill-shelf/option-4.svg');	
					}
					&.option-5 {
						top: 0.92em;
						left: 8.61em;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-1-fill-shelf/option-5.svg');	
					}
					&.option-6 {
						top: 0.92em;
						left: 12.28em;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-1-fill-shelf/option-6.svg');	
					}
				}
			}
		}
	}

	&.center-store-1-missing-tags {
		.MultipleChoice-options {
			margin-top: 1em;
			padding-bottom: 1em;
			.MultipleChoice-optionsWrap {
				padding-bottom: 2em;
				text-align: center;
				.MultipleChoice-option { 
					display: inline-block;
					width: 16.6em;
					height: 6.51em;
					background-size: 100% auto;
					background-position: bottom center;
					span:nth-child(2) {
						bottom: 0.5em;
						right: 0.75em;
						left: auto;
						width: 1.39em;
						height: 1.39em;
					}
					&.option-1 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-1-missing-tags/option-1.svg');
					}
					&.option-2 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-1-missing-tags/option-2.svg');
					}
					&.option-3 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-1-missing-tags/option-3.svg');
					}
					&.option-4 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-1-missing-tags/option-4.svg');
					}
				}
			}
		}	
	}

	&.center-store-1-fall {
		.MultipleChoice-options {
			margin-top: 1em;
			padding-bottom: 1em;
			.MultipleChoice-optionsWrap {
				width: 17.5em;
				margin: 0 auto;
				.MultipleChoice-option { 
					display: inline-block;
					vertical-align: middle;
					width: 33.33%;
					height: 8.5em;
					span:nth-child(2) {
						bottom: calc(50% - 0.75em);
						left: calc(50% - 0.75em);
					}		
					&.option-1 {
						background-size: 3em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-1-fall/option-1.svg');
					}
					&.option-2 {
						background-size: 4.16em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-1-fall/option-2.svg');
					}
					&.option-3 {
						background-size: 3.43em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-1-fall/option-3.svg');
					}
					&.option-4 {
						background-size: 3em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-1-fall/option-4.svg');
					}
					&.option-5 {
						background-size: 3em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-1-fall/option-5.svg');
					}
					&.option-6 {
						background-size: 4.35em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-1-fall/option-6.svg');
					}
					&.option-7 {
						background-size: 3.88em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-1-fall/option-7.svg');
					}
					&.option-8 {
						background-size: 3.28em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-1-fall/option-8.svg');
					}
					&.option-9 {
						background-size: 2.86em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-1-fall/option-9.svg');
					}
				}
			}
		}		
	}

	&.center-store-1-grilling {
		.MultipleChoice-options {
			margin-top: 1em;
			padding-bottom: 1em;
			.MultipleChoice-optionsWrap {
				margin: 0 auto;
				height: 24.5em;
				width: 17.5em;
				.MultipleChoice-option { 
					position: absolute;
					span:nth-child(2) {
						bottom: 0;
						left: auto;
						right: 0;
					}					
					&.option-1 {
						top: 9.44em;
						right: 0;
						width: 5.75em;
						height: 8em;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-1-grilling/option-1.svg');
						background-size: 5.25em auto;
					}
					&.option-2 {
						bottom: 0;
						left: 5.4em;
						width: 2.5em;
						height: 5.31em;
						background-size: 2em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-1-grilling/option-2.svg');
					}
					&.option-3 {
						top: 0;
						left: 6.11em;
						width: 5.5em;
						height: 7.64em;
						background-size: 5em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-1-grilling/option-3.svg');
					}
					&.option-4 {
						top: 1.43em;
						right: 1em;
						width: 3.5em;
						height: 5.52em;
						background-size: 3em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-1-grilling/option-4.svg');
					}
					&.option-5 {
						top: 9.89em;
						left: 6.67em;
						width: 4em;
						height: 6.69em;
						background-size: 3.44em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-1-grilling/option-5.svg');
					}
					&.option-6 {
						bottom: 0;
						left: 0;
						width: 3.5em;
						height: 4.5em;
						background-size: 3em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-1-grilling/option-6.svg');
					}
					&.option-7 {
						top: 9.17em;
						left: 0;
						width: 4.85em;
						height: 7.83em;
						background-size: 4.35em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-1-grilling/option-7.svg');
					}
					&.option-8 {
						bottom: 0;
						left: 8.9em;
						width: 4.4em;
						height: 3.26em;
						background-size: 3.9em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-1-grilling/option-8.svg');
					}
					&.option-9 {
						top: 1em;
						left: 0.25em;
						width: 3.8em;
						height: 6.4em;
						background-size: 3.3em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-1-grilling/option-9.svg');
					}
					&.option-10 {
						bottom: 0;
						right: 0;
						width: 3.4em;
						height: 4.24em;
						background-size: 2.9em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-1-grilling/option-10.svg');
					}
				}
			}
		}
	}

	&.center-store-1-ready-shelves {
		.MultipleChoice-options {
			margin-top: 1em;
			padding-bottom: 1em;
			.MultipleChoice-optionsWrap {
				margin: 0 auto;
				height: 24.5em;
				width: 17.5em;
				.MultipleChoice-option { 
					position: relative;
					display: inline-block;
					width: 8em;
					span:nth-child(2) {
						bottom: 0;
						left: auto;
						right: 0;
					}
					&.position-1 {
						position: absolute;
						top: 0;
						left: 0;
					}
					&.position-2 {
						position: absolute;
						top: 0;
						right: 0;
					}
					&.position-3 {
						position: absolute;
						top: 8em;
						left: 0;
					}
					&.position-4 {
						position: absolute;
						top: 8em;
						right: 0;
					}
					&.position-5 {
						position: absolute;
						top: 16em;
						left: 0;
					}
					&.position-6 {
						position: absolute;
						top: 16em;
						right: 0;
					}
					&.position-7 {
						position: absolute;
						top: 24em;
						left: 0;
					}
					&.position-8 {
						position: absolute;
						top: 24em;
						right: 0;
					}

					&.option-1 {
						height: 7em;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-1-ready-shelves/option-1.svg');
						background-size: 3em auto;
					}
					&.option-2 {
						height: 2.5em;
						background-size: 5.8em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-1-ready-shelves/option-2.svg');
					}
					&.option-3 {
						height: 7.64em;
						background-size: 5em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-1-ready-shelves/option-3.svg');
					}
					&.option-4 {
						height: 4.3em;
						background-size: 6.3em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-1-ready-shelves/option-4.svg');
					}
					&.option-5 {
						height: 3em;
						background-size: 7.3em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-1-ready-shelves/option-5.svg');
					}
					&.option-6 {
						width: 8.8em;
						height: 1.9em;
						background-size: 8.8em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-1-ready-shelves/option-6.svg');
					}
					&.option-7 {
						height: 3.7em;
						background-size: 6em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-1-ready-shelves/option-7.svg');
					}
					&.option-8 {
						height: 5.5em;
						background-size: 2.5em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-1-ready-shelves/option-8.svg');
					}
				}
			}
		}
	}

	&.center-store-1-shelves-1,
	&.center-store-1-shelves-2 {
		.MultipleChoice-options {
			width: 18em;
			left: calc(50% - 9em);
			margin-top: 1em;
			padding-bottom: 1em;
			.MultipleChoice-optionsWrap {
				background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-1-shelves-1/background.svg');
				background-position: top center;
				background-size: 18em auto;
				.MultipleChoice-option {
					position: relative; 
					width: 100%;
					height: 7.1em;
					&.selected,
					&.correct,
					&.animateCorrect,
					&.wrong,
					&.animateWrong {@include noAnimation();}
					&.option-1 {height: 7.5em;}
				}
			}		
		}
	}
	&.center-store-1-shelves-2 {
		.MultipleChoice-options .MultipleChoice-optionsWrap {
			background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-1-shelves-2/background.svg');
		}
	}

	&.center-store-1-spaghetti-1 {
		.MultipleChoice-options {
			.MultipleChoice-optionsWrap {
				height: 25em;
				background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-1-spaghetti-1/background.svg');
				background-position: top center;
				.MultipleChoice-option {
					position: absolute; 
					width: 3.1em;
					height: 5.2em;
					&.option-1 {top: 0em; left: calc(50% - 8.5em);}
					&.option-2 {top: 2.5em; left: calc(50% - 4em);}
					&.option-3 {top: 5.2em; left: calc(50% + 0.5em);}
					&.option-4 {top: 7.75em; left: calc(50% + 5.25em);}
					&.option-2
					&.selected,
					&.correct,
					&.animateCorrect,
					&.wrong,
					&.animateWrong {@include noAnimation();}
				}
			}
		}
	}
	&.center-store-1-spaghetti-2 {
		.MultipleChoice-options {
			width: 18.1em;
			left: calc(50% - 9.05em);
			margin-top: 1em;
			padding-bottom: 1em;
			.MultipleChoice-optionsWrap {
				padding-top: 0.25em;
				background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-1-spaghetti-2/background.svg');
				background-position: top center;
				.MultipleChoice-option {
					position: relative; 
					width: 100%;
					height: 5.48em;
					&.selected,
					&.correct,
					&.animateCorrect,
					&.wrong,
					&.animateWrong {@include noAnimation();}
				}
			}	
		}
	}
	&.center-store-1-spaghetti-3 {
		.MultipleChoice-options {
			width: 18.43em;
			left: calc(50% - 9.215em);
			margin-top: 1em;
			padding-bottom: 1em;
			.MultipleChoice-optionsWrap {
				padding-top: 0.25em;
				background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-1-spaghetti-3/background.svg');
				background-position: top center;
				.MultipleChoice-option {
					position: relative; 
					width: 100%;
					height: 5.48em;
					&.selected,
					&.correct,
					&.animateCorrect,
					&.wrong,
					&.animateWrong {@include noAnimation();}
				}
			}
		}
	}

	&.center-store-1-spaghetti-4 {
		.MultipleChoice-options {
			margin-top: 2em;
			.MultipleChoice-optionsWrap {
				.MultipleChoice-option { 
					position: relative;
					width: 14em;
					margin: 0 auto 1.5em auto;
					span:nth-child(2) {
						bottom: 0.5em;
						left: auto;
						right: 0.75em;
						width: 1.39em;
						height: 1.39em;
					}
					&.option-1 {
						height: 3.75em;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-1-spaghetti-4/option-1.svg');
					}
					&.option-2 {
						height: 2em;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-1-spaghetti-4/option-2.svg');
					}
					&.option-3 {
						height: 3.5em;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-1-spaghetti-4/option-3.svg');
					}
					&.option-4 {
						height: 1.75em;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-1-spaghetti-4/option-4.svg');
					}
					&.option-5 {
						height: 4.25em;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-1-spaghetti-4/option-5.svg');
					}
				}
			}
		}
	}


	&.center-store-1-spring-cleaning {
		.MultipleChoice-options {
			margin-top: 1em;
			padding-bottom: 1em;
			.MultipleChoice-optionsWrap {
				width: 20em;
				margin: 0 auto;
				.MultipleChoice-option { 
					display: inline-block;
					vertical-align: middle;
					width: 33.33%;
					height: 8.5em;
					span:nth-child(2) {
						bottom: calc(50% - 0.75em);
						left: calc(50% - 0.75em);
					}		
					&.option-1 {
						background-size: 3em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-1-spring-cleaning/option-1.svg');
					}
					&.option-2 {
						background-size: 6em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-1-spring-cleaning/option-2.svg');
					}
					&.option-3 {
						background-size: 3.38em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-1-spring-cleaning/option-3.svg');
					}
					&.option-4 {
						background-size: 4.4em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-1-spring-cleaning/option-4.svg');
					}
					&.option-5 {
						background-size: 3.28em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-1-spring-cleaning/option-5.svg');
					}
					&.option-6 {
						background-size: 2.86em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-1-spring-cleaning/option-6.svg');
					}
					&.option-7 {
						background-size: 5em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-1-spring-cleaning/option-7.svg');
					}
					&.option-8 {
						background-size: 3.88em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-1-spring-cleaning/option-8.svg');
					}
					&.option-9 {
						background-size: 3.44em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-1-spring-cleaning/option-9.svg');
					}
				}
			}
		}
	}

	&.center-store-1-tailgating {
		.MultipleChoice-options {
			margin-top: 1em;
			padding-bottom: 1em;
			.MultipleChoice-optionsWrap {
				width: 17.5em;
				margin: 0 auto;
				.MultipleChoice-option { 
					display: inline-block;
					vertical-align: middle;
					width: 33.33%;
					height: 8.5em;
					span:nth-child(2) {
						bottom: calc(50% - 0.75em);
						left: calc(50% - 0.75em);
					}		
					&.option-1 {
						background-size: 6em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-1-tailgating/option-1.svg');
					}
					&.option-2 {
						background-size: 2.5em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-1-tailgating/option-2.svg');
					}
					&.option-3 {
						background-size: 1.7em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-1-tailgating/option-3.svg');
					}
					&.option-4 {
						background-size: 2.5em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-1-tailgating/option-4.svg');
					}
					&.option-5 {
						background-size: 5em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-1-tailgating/option-5.svg');
					}
					&.option-6 {
						background-size: 3.38em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-1-tailgating/option-6.svg');
					}
					&.option-7 {
						background-size: 3.44em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-1-tailgating/option-7.svg');
					}
					&.option-8 {
						background-size: 3.88em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-1-tailgating/option-8.svg');
					}
					&.option-9 {
						background-size: 4.4em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-1-tailgating/option-9.svg');
					}
				}
			}
		}
	}

	&.center-store-1-prep-deadline {
		.MultipleChoice-options {
			margin-top: 2em;
			.MultipleChoice-optionsWrap {
				text-align: center;
				.MultipleChoice-option { 
					position: relative;
					width: 7.5em;
					height: 7.5em;
					margin: 0.5em 1em;
					display: inline-block;
					span:nth-child(2) {
						bottom: 0.5em;
						left: auto;
						right: 0.75em;
						width: 1.39em;
						height: 1.39em;
					}
					&.option-1 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-1-prep-deadline/option-1.svg');
					}
					&.option-2 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-1-prep-deadline/option-2.svg');
					}
					&.option-3 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-1-prep-deadline/option-3.svg');
					}
					&.option-4 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-1-prep-deadline/option-4.svg');
					}
				}
			}
		}
	}

	&.center-store-2-learned-about-checklist {
		.MultipleChoice-options {
			margin-top: 1em;
			padding-bottom: 1em;
			.MultipleChoice-optionsWrap {
				width: 16.1em;
				height: 22em;
				margin: 0 auto;
				background-position: top left;
				background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-2-learned-about-checklist/background.svg');
				.MultipleChoice-option { 
					position: absolute;
					left: 12em;
					width: 1.7em;
					height: 1.5em;
					span:nth-child(2) {
						bottom: calc(50% - 0.75em);
						left: calc(50% - 0.75em);
					}		
					&.option-1 {
						top: 5em;
					}
					&.option-2 {
						top: 7em;
					}
					&.option-3 {
						top: 9.05em;
					}
					&.option-4 {
						top: 11.1em;
					}
					&.option-5 {
						top: 13.7em;
					}

				}
			}
		}		
	}

	&.center-store-2-tool-belt-items {
		.MultipleChoice-options {
			margin-top: 1em;
			padding-bottom: 1em;
			.MultipleChoice-optionsWrap {
				width: 17.5em;
				margin: 0 auto;
				.MultipleChoice-option { 
					display: inline-block;
					vertical-align: middle;
					width: 33.33%;
					height: 8em;
					span:nth-child(2) {
						bottom: calc(50% - 0.75em);
						left: calc(50% - 0.75em);
					}		
					&.option-1 {
						background-size: 5.16em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-2-tool-belt-items/option-1.svg');
					}
					&.option-2 {
						background-size: 4.76em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-2-tool-belt-items/option-2.svg');
					}
					&.option-3 {
						background-size: 2.21em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-2-tool-belt-items/option-3.svg');
					}
					&.option-4 {
						background-size: 5.3em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-2-tool-belt-items/option-4.svg');
					}
					&.option-5 {
						background-size: 2.32em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-2-tool-belt-items/option-5.svg');
					}
					&.option-6 {
						background-size: 5.07em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-2-tool-belt-items/option-6.svg');
					}
					&.option-7 {
						background-size: 4.57em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-2-tool-belt-items/option-7.svg');
					}
					&.option-8 {
						background-size: auto 100%;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-2-tool-belt-items/option-8.svg');
					}
					&.option-9 {
						background-size: 2.91em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-2-tool-belt-items/option-9.svg');
					}
				}
			}
		}	
	}
	&.center-store-2-bag-cereals {
		.MultipleChoice-options {
			margin-top: 1em;
			padding-bottom: 1em;
			.MultipleChoice-optionsWrap {
				width: 16.5em;
				margin: 0 auto;
				.MultipleChoice-option { 
					width: 100%;
					height: 7.6em;
					margin-bottom: 0.25em;
					background-size: 100% auto;
					span:nth-child(2) {
						bottom: calc(50% - 0.75em);
						left: calc(100% - 2.5em);
					}		
					&.option-1 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-2-bag-cereals/option-1.svg');
					}
					&.option-2 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-2-bag-cereals/option-2.svg');
					}
					&.option-3 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-2-bag-cereals/option-3.svg');
					}
				}
			}
		}
	}

	&.center-store-2-endcap-product-position {
		.MultipleChoice-options {
			margin-top: 1em;
			padding-bottom: 1em;
			.MultipleChoice-optionsWrap {
				width: 9em;
				margin: 0 auto;
				.MultipleChoice-option { 
					width: 100%;
					height: 11em;
					margin-bottom: 1em;
					span:nth-child(2) {
						bottom: calc(50% - 0.75em);
						left: calc(100% - 2.5em);
					}		
					&.option-1 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-2-endcap-product-position/option-1.svg');
					}
					&.option-2 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-2-endcap-product-position/option-2.svg');
					}

				}
			}
		}
		&.landscape {
			.MultipleChoice-options { 
				margin-top: 3em;
				width: 40em;
				left: calc(50% - 20em);
				.MultipleChoice-optionsWrap {
					width: 100%;
					@include flex('space-between', 'flex-start');
					.MultipleChoice-option { 
						width: 50%;
						height: 22em;
					}
				}
			}
			
		}
	}
	&.center-store-2-pallet-max-height {
		.MultipleChoice-options {
			margin-top: 1em;
			padding-bottom: 1em;
			.MultipleChoice-optionsWrap {
				width: 17.65em;
				margin: 0 auto;
				@include flex('space-between');
				flex-wrap: wrap;
				.MultipleChoice-option { 
					width: 8.27em;
					height: 7.75em;
					background-position: bottom center;
					margin-bottom: 2em;
					span:nth-child(2) {
						bottom: 1em;
						left: calc(100% - 2.5em);
					}		
					&.option-1 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-2-pallet-max-height/option-1.svg');
					}
					&.option-2 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-2-pallet-max-height/option-2.svg');
					}
					&.option-3 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-2-pallet-max-height/option-3.svg');
					}
					&.option-4 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-2-pallet-max-height/option-4.svg');
					}

				}
			}
		}
	}
	&.center-store-2-pallet-min-height {
		.MultipleChoice-options {
			margin-top: 3em;
			.MultipleChoice-optionsWrap {
				width: 12.25em;
				margin: 0 auto;
				
				.MultipleChoice-option { 
					width: 100%;
					height: 4.66em;
					background-position: bottom center;
					margin-bottom: 2em;
					span:nth-child(2) {
						bottom: 1em;
						left: calc(100% - 2.5em);
					}		
					&.option-1 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-2-pallet-min-height/option-1.svg');
					}
					&.option-2 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-2-pallet-min-height/option-2.svg');
					}
				}
			}
		}
	}
	&.center-store-2-customer-pallet-items {
		.MultipleChoice-options {
			margin-top: 1em;
			.MultipleChoice-optionsWrap {
				width: 10em;
				margin: 0 auto;
				.MultipleChoice-option { 
					background-position: bottom center;
					margin-bottom: 1em;
					span:nth-child(2) {
						bottom: 1em;
						left: calc(100% - 2.5em);
					}		
					&.option-1 {
						width: 9.5em;
						height: 7.5em;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-2-customer-pallet-items/option-1.svg');
					}
					&.option-2 {
						width: 9.8em;
						height: 5.7em;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-2-customer-pallet-items/option-2.svg');
					}
					&.option-3 {
						width: 9.7em;
						height: 8.4em;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/center-store-2-customer-pallet-items/option-3.svg');
					}
				}
			}
		}
	}
}