@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/mixins_backgrounds';
@import '../../../styles/animations';
@import '../../../styles/animations-intro';
.SelectMultiple {
	position: relative;
	width: 100%;
	height: 2.5em;
	border-radius: 0.4em;
	color: black;
	background-color: white;
	text-align: left;
	cursor: pointer;
	&.searchable {
		cursor: default;
	}
	&.open {
		border-radius: 0.4em 0.4em 0 0;
		.SelectMultiple-dropdown {
			height: auto;
			border-radius: 0 0 0.4em 0.25em;
			// border-top: 1px solid #213640;
		}
	}
	&.disabled {
		cursor: default;
	}
	
	.SelectMultiple-panel {
		height: 100%;
		form,
		.SelectMultiple-input {
			width: 100%;
			height: 100%;
			@include placeholderColor(#d1d1d1);
		}
		
		.SelectMultiple-selected {
			span {
				font-size: 1.11em;
				line-height: 1.62;
			}
		}
	}

	.SelectMultiple-dropdown {
		position: absolute;
		left: 0;
		top: 2.5em;
		width: 100%;
		height: 0;
		background-color: white;
		overflow: hidden;
		z-index: 2;
		@include transition('height', 0.3s, 'ease');
		
		.SelectMultiple-option {
			cursor: pointer;
			position: relative;			
			padding: 0 0.5em;
			height: 2.5em;
			span {
				font-size: 1.11em;
				line-height: 1.62;
			}
			&:hover {
				background-color: rgba(#213640, 0.1);
			}
			&.disabled {
				cursor: not-allowed;
				opacity: 0.5;
				&:hover {
					background-color: white;
				}
			}
			&.selected {
				background-image: url('../../../assets/images/modules/icon-check2.svg');
				background-size: auto 50%;
				background-position: right 0.5em center;
			}
		}
	}


	&.competition {
		height: 1.75em;
		color: #10779E;
		border: 0.1em solid #10779E;
		box-sizing: content-box;
		.SelectMultiple-panel .SelectMultiple-selected {
			padding: 0 0.5em;
			background-image: url('../../../assets/images/facilitator/icon-arrow-down.svg');
			background-size: 0.9em auto;
			background-position: right 0.25em center;
			span {
				font-weight: 600;
				line-height: 1.5;
			}
		}
		.SelectMultiple-dropdown {
			top: 1.75em;
			
			.SelectMultiple-option {
				height: 1.75em;
				white-space: nowrap;
				span {
					font-weight: 600;
					line-height: 1.5;
				}
			}
		}
		&.open {
			.SelectMultiple-panel .SelectMultiple-selected {
				background-image: url('../../../assets/images/facilitator/icon-arrow-up.svg');
			}
			.SelectMultiple-dropdown {
				border: 0.1em solid #10779E;
				box-sizing: content-box;
				border-top: 0.1em dashed #11466B;
				margin-left: -0.1em;
			}
		}
	}
}