@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';
@import '../../../../styles/mixins_backgrounds';
@import '../../../../styles/animations';
@import '../../../../styles/animations-intro';
.PaperDoll {
	position: relative;
	height: 100%;
	overflow: auto;
	@include grey-background();
	
	.PaperDoll-avatar {
		position: absolute;
		left: 0;
		bottom: 13.4em;
		width: 100%;
	}
	.PaperDoll-selectedItems,
	.PaperDoll-availableItems {
		.PaperDoll-item {
			position: absolute;
			text-align: left;
			svg {width: 100%; height: auto;}
			&.selected {@include opacity(0.25);}
			&.apron-full {width: 3.28em;}
			&.apron-half {width: 5.15em;}
			&.badge {width: 2.63em;}
			&.bag {width: 4.5em;}
			&.bag-of-bird-seeds {width: 2.87em;}
			&.beard-net {width: 4.5em;}
			&.beef-slab {width: 5em;}
			&.book {width: 3.5em;}
			&.box-cutter {width: 5.2em;}
			&.bucket {width: 4em;}
			&.christmas-sweater {width: 6.28em;}
			&.clown-hair {width: 4em;}
			&.baseball-bat {width: 11em;}
			&.cloth {width: 4em;}
			&.ears {width: 3.25em;}
			&.fish {width: 5.5em;}
			&.flower {width: 2.63em;}
			&.flour {width: 4em;}
			&.goggles {width: 3.6em;}
			&.gloves-plastic {width: 4.83em;}
			&.gloves-rubber {width: 4.83em;}
			&.gloves-white {width: 4.83em;}
			&.hair-net {width: 4.26em;}
			&.hair-net2 {width: 5.4em;}
			&.hammer {width: 5em;}
			&.hand-sanitizer {width: 2.19em;}
			&.hard-hat {width: 4em;}
			&.hat-green {width: 5.54em;}
			&.hat-white {width: 5em;}
			&.hat-white-2 {width: 4.8em;}
			&.ice-bucket {width: 4em;}
			&.keys {width: 4em;}
			&.knife {width: 6.25em;}
			&.knife2 {width: 5.75em;}
			&.knife3 {width: 5em;}
			&.knife-sharpener {width: 7em;}
			&.life-jacket {width: 4.3em;}
			&.lucky-clover {width: 1.8em;}
			&.map {width: 3.75em;}
			&.meat-cleaver {width: 7em;}
			&.meat-grinder {width: 5.5em;}
			&.nametag {width: 3.67em;}
			&.pencil-and-pad {width: 3.31em;}
			&.oven-timer {width: 5em;}
			&.peeler {width: 2em;} 
			&.phone {width: 3.67em;}
			&.plant-mister {width: 2.63em;}
			&.rag-wet-clean {width: 5.75em;}
			&.rag-wet-dirty {width: 5.75em;}
			&.rolling-pin {width: 10em;}
			&.scales {width: 10em;}
			&.scissor {width: 5em;}
			&.scotch-tape {width: 4.76em;}
			&.smile {width: 2.44em;}
			&.safety-vest {width: 4.3em;}
			&.spray-bottle {width: 2.25em;}
			&.thermometer {width: 1.75em;}
			&.umbrella {width: 4.28em;}
			&.vegetables {width: 5.4em;}
			&.wet-floor-sign {width: 4.8em;}
			&.whistle {width: 2.63em;}
			&.paper {width: 5.3em;}
			&.paper-bag {width: 4.5em;}
			&.pencil {width: 4.5em;}
		}
	}

	.PaperDoll-selectedItems {
		position: absolute;
		left: 0;
		top: 0;
		bottom: 14em;
		width: 100%;
		z-index: 1;
		.PaperDoll-item {
			&.apron-full {width: 6.15em; bottom: -0.55em; left: calc(50% - 3.14em);}
			&.apron-half {width: 6.4em; bottom: -2.25em; left: calc(50% - 3.2em);}
			&.baseball-bat {bottom: 0em; left: calc(50% + 1.75em);}
			&.badge {width: 2.63em; bottom: 7em; left: calc(50% - 2.5em);}
			&.bag {width: 5.75em;}
			&.bag-of-bird-seeds {bottom: 0; left: calc(50% - 3.15em);}
			&.beard-net {width: 4.5em; bottom: 11.25em; left: calc(50% - 2.25em);}
			&.beef-slab {bottom: 0em; left: calc(50% + 1.75em);}
			&.book {bottom: 0em; left: calc(50% + 1.75em);}
			&.box-cutter {bottom: 0; left: calc(50% + 4.35em);}
			&.christmas-sweater {width: 9.6em; bottom: 1.75em; left: calc(50% - 4.9em);}
			&.cloth {bottom: 0em; left: calc(50% + 1.75em);}
			&.clown-hair {width: 7em; left: calc(50% - 3.5em);bottom: 15em;}
			&.ears {bottom: 13.25em; left: calc(50% - 3.15em); width: 6.3em;}
			&.fish {bottom: 0em; left: calc(50% + 1.75em);}
			&.flower {width: 2.63em; bottom: -0.2em; left: calc(50% - 9.5em);}
			&.flour {bottom: 0em; left: calc(50% + 1.75em);}
			&.gloves-plastic {width: 10.8em; bottom: -0.5em; left: calc(50% - 5.4em)}
			&.gloves-rubber {width: 10.8em; bottom: -0.5em; left: calc(50% - 5.4em)}
			&.gloves-white {width: 10.8em; bottom: -0.5em; left: calc(50% - 5.4em)}
			&.goggles {width: 5em; left: calc(50% - 2.5em); bottom: 12.25em;}
			&.hair-net, &.hair-net2 {width: 6em; bottom: 14.7em; left: calc(50% - 3em);}
			&.hammer {bottom: 0; left: calc(50% + 3em); width: 6em;}
			&.hand-sanitizer {width: 2.67em; bottom: 0;}
			&.hard-hat {width: 5em; bottom: 0em; left: calc(50% + 1.75em);}
			&.hat-green {width: 10.5em; bottom: 14.75em; left: calc(50% - 5.45em);}
			&.hat-white {width: 8em; bottom: 15.75em; left: calc(50% - 4.1em);}
			&.hat-white-2 {bottom: 15.75em; left: calc(50% - 2.4em);}
			&.ice-bucket {bottom: 0em; left: calc(50% + 1.75em);}
			&.keys {bottom: 1.5em; left: calc(50% + 4em);}
			&.knife {bottom: 0em; left: calc(50% + 1.75em);}
			&.knife2 {bottom: 0em; left: calc(50% + 1.75em);}
			&.knife3 {bottom: 1.5em; left: calc(50% + 2.75em);}
			&.knife-sharpener {bottom: 0em; left: calc(50% + 1.75em);}
			&.life-jacket {width: 7em; bottom: 0em; left: calc(50% + 1.75em);}
			&.lucky-clover {bottom: 0em; left: calc(50% + 1.75em);}
			&.meat-cleaver {bottom: 0em; left: calc(50% + 2em);}
			&.meat-grinder {width: 6em; bottom: 0em; left: calc(50% - 11em);}
			&.nametag {width: 1.81em; bottom: 7em; left: calc(50% + 0em);}
			&.oven-timer {bottom: 0em; left: calc(50% + 1.75em);}
			&.peeler {bottom: 0em; left: calc(50% + 1.75em);}
			&.plant-mister {width: 2.63em; bottom: -0.2em;}
			&.rag-wet-clean {bottom: 1.8em; left: calc(50% + 2.75em);}
			&.rag-wet-dirty {bottom: 1.32em; left: calc(50% + 2.75em);}
			&.rolling-pin {bottom: 0em; left: calc(50% + 1.75em);}
			&.safety-vest {width: 6.5em; bottom: 0em; left: calc(50% + 1.75em);}
			&.scales {width: 7em; bottom: -0.2em; left: calc(50% - 10em);}
			&.scissor {width: 5em; bottom: -0.2em;}
			&.scotch-tape {bottom: 0; left: calc(50% - 10em);}
			&.smile {bottom: 12.2em; left: calc(50% - 1.55em); width: 3.1em;}
			&.spray-bottle {bottom: 0; left: calc(50% - 2em);}
			&.thermometer {width: 2.02em; bottom: -0.2em; left: calc(50% - 7em);}
			&.umbrella {width: 6.83em; bottom: -0.2em;}
			&.vegetables {width: 10em; bottom: -0.3em; left: calc(50% - 10.25em);}
			&.wet-floor-sign {width: 9.6em; bottom: 0em; left: calc(50% + 1.75em);}
			&.whistle {bottom: 0em; left: calc(50% + 1.75em);}
			&.paper {bottom: 0em; left: calc(50% + 1.75em);}
			&.paper-bag {bottom: 0em; left: calc(50% + 1.75em);}
			&.pencil {bottom: 0em; left: calc(50% + 1.75em);}

			&.f, &.f2 {
				&.height {@include translate(0, 1.2em);}
				&.apron-full {width: 4.9em; bottom: 1.25em; left: calc(50% - 2.55em);}	
				&.apron-half {width: 5.55em; bottom: -0.3em; left: calc(50% - 2.85em);}
				&.badge {width: 2.63em; bottom: 7.6em; left: calc(50% + 0.1em);}
				&.christmas-sweater {width: 8em; bottom: 3.3em; left: calc(50% - 4.15em);}
				&.gloves-plastic {width: 8.5em; bottom: 0.75em; left: calc(50% - 4.3em)}
				&.gloves-rubber {width: 8.5em; bottom: 0.75em; left: calc(50% - 4.3em)}
				&.gloves-white {width: 8.5em; bottom: 0.75em; left: calc(50% - 4.3em);}
				&.nametag {width: 1.5em; bottom: 7.6em; left: calc(50% + 0.1em);}
				&.life-jacket {width: 5.5em; bottom: 0em; left: calc(50% + 1.75em);}
				&.safety-vest {width: 5.25em; bottom: 0em; left: calc(50% + 1.75em);}
			}
			&.m2, &.f2 {
				&.width svg {@include scaleX(1.2);}
			}
		}
	}

	.PaperDoll-tools {
		position: absolute;
		bottom: 14.25em;
		right: calc(50% - 10.25em);
		width: 9.1em;
		height: 5.13em;
		background-image: url('../../../../assets/images/modules/tasks/paper-doll/tools.svg');
		background-position: bottom right;
		pointer-events: none;
		z-index: 2;
	}

	.PaperDoll-panel {
		position: absolute;
		width: 100%;
		height: 14.25em;
		left: 0;
		bottom: 0;
		background-color: #F5F5F5;
		z-index: 2;
		.PaperDoll-panelHeader {
			position: relative;
			width: 100%;
			height: 2.25em;
			background-color: #10779E;
			background-image: linear-gradient(#10779E, #15476A);
			background-size: 100% 100%;
			border-top: calc(0.11em + 1px) solid #7DBDE8;
			box-sizing: content-box;
			@include flex('flex-start', 'flex-start');
			@include hide-scrollbar();
		}
		.PaperDoll-availableItems {
			position: relative;
			width: 100%;
			height: calc(100% - (2.25em + 0.11em + 1px));
		}	
	}

	.PaperDoll-confirmBtn {
		position: absolute;
		right: calc(50% - 9.75em);
		bottom: 24em;
		z-index: 20;
	}

	&.completed {
		background: none;
		overflow: hidden;
		.PaperDoll-avatar,
		.PaperDoll-selectedItems {
			@include translate(0, 14em);
		}
		.PaperDoll-panel {
			display: none;
			.PaperDoll-availableItems {
				display: none;
			}
		}
		.PaperDoll-tools {
			bottom: 0em;
		}
	}

	&.inventory-management-1-paper-doll {
		.PaperDoll-panel .PaperDoll-availableItems .PaperDoll-item {
			&.nametag {left: calc(50% + 6em); top: 1em}
			&.apron-full {left: calc(50% + 6em); top: 5em}
			&.pencil {left: calc(50% - 8em); top: 6.5em}
			&.paper {left: calc(50% - 5em); top: 9em}
			&.phone {left: calc(50% - 9em); top: 1em}
			&.paper-bag {left: calc(50%); top: 2em}
		}
		.PaperDoll-selectedItems .PaperDoll-item {
			&.pencil {left: calc(50% + 5em); bottom: 0em;}
			&.paper {left: calc(50% + 2.5em); bottom: 0em;}
			&.phone {left: calc(50% + 2.5em); bottom: 0em;}
			&.paper-bag {left: calc(50% - 10em); bottom: 0em;}
		}
	}

	&.safety-1-paper-doll {
		.PaperDoll-panel .PaperDoll-availableItems .PaperDoll-item {
			&.gloves-plastic {left: calc(50% - 10.25em); top: 6.5em;}
			&.goggles {left: calc(50% - 9em); top: 0.5em;}
			&.hard-hat {left: calc(50% + 0.5em); top: 7em;}
			&.life-jacket {left: calc(50% - 4.5em); top: 6em;}
			&.lucky-clover {left: calc(50% - 4.5em); top: 1em;}
			&.safety-vest {left: calc(50% + 3em); top: 0.5em;}
			&.wet-floor-sign {left: calc(50% + 5em); top: 6em;}
			&.whistle {left: calc(50% - 2.25em); top: 3em;}
		}
		.PaperDoll-selectedItems .PaperDoll-item {
			&.lucky-clover {left: calc(50% + 3em); bottom: 0em;}
			&.wet-floor-sign {left: calc(50% - 14.75em); bottom: 0em;}
			&.hard-hat {left: calc(50% - 2.5em); bottom: 15.5em;}
			&.goggles {left: calc(50% - 2.5em); bottom: 12.25em;}
			&.life-jacket {left: calc(50% - 3.5em); bottom: 4em;}
			&.safety-vest {left: calc(50% - 3.25em); bottom: 4.25em;}
			&.whistle {left: calc(50% + 5em); bottom: 0em;}

			&.f, &.f2 {
				&.life-jacket {left: calc(50% - 2.8em); bottom: 5.5em;}
				&.safety-vest {left: calc(50% - 2.7em); bottom: 5.6em;}
			}
		}
	}

	&.bagger-1-paper-doll-suit-up {
		.PaperDoll-panel .PaperDoll-availableItems .PaperDoll-item { 
			&.apron-full {left: calc(50% + 6.25em); top: 5.4em;}
			&.bag {left: calc(50% - 1.75em); top: 0.5em;}
			&.box-cutter {left: calc(50% + 4em); top: 3.05em;}
			&.gloves-plastic {left: calc(50% - 10.25em); top: 7em;}
			&.hair-net2 {left: calc(50% - 0.5em); top: 6.74em;}
			&.knife {left: calc(50% + 3.25em); top: 0.83em; width: 6.3em;}
			&.nametag {left: calc(50% - 5.6em); top: 2.27em; width: 2.7em;}
			&.pencil-and-pad {left: calc(50% - 5em); top: 6.12em;}
			&.phone {left: calc(50% - 10.1em); top: 1em;}
			&.smile {left: calc(50% - 5.6em); top: 4.6em;}
		}
		.PaperDoll-selectedItems .PaperDoll-item {
			&.bag {left: calc(50% - 10em); bottom: 0em;}
			&.box-cutter {left: calc(50% + 2.5em); bottom: 0;}
			&.knife {left: calc(50% + 2em); bottom: 1.6em; width: 6.3em;}
			&.pencil-and-pad {left: calc(50% + 1.75em); bottom: 0em;}
			&.phone {left: calc(50% + 5.8em); bottom: 0.1em;}
		}
	}	

	&.center-store-1-paper-doll-stocking-shelves {
		.PaperDoll-selectedItems .PaperDoll-item {
			&.umbrella {width: 4.39em; left: calc(50% + 6em);}
		}

		.PaperDoll-panel .PaperDoll-availableItems .PaperDoll-item {
			&.christmas-sweater {left: calc(50% - 9.5em); top: 0.64em;}
			&.box-cutter {left: calc(50% - 2.5em); top: 1em;}
			&.rag-wet-dirty {left: calc(50% + 3.5em); top: 1.08em;}
			&.whistle {left: calc(50% - 2.25em); top: 3em;}
			&.nametag {left: calc(50% + 1.25em); top: 3.69em;}
			&.gloves-rubber {left: calc(50% - 9.5em); top: 7.25em;}
			&.spray-bottle {left: calc(50% - 2.5em); top: 5.68em;}
			&.scotch-tape {left: calc(50% + 1.75em); top: 5.53em;}
			&.rag-wet-clean {left: calc(50% + 0.5em); top: 8.72em;}
			&.umbrella {left: calc(50% + 6.3em); top: 1em;}
		}
	}

	&.customer-service-1-paper-doll-get-dressed {
		.PaperDoll-panel .PaperDoll-availableItems .PaperDoll-item {
			&.smile {left: calc(50% - 9em); top: 2em; width: 3em;}
			&.nametag {left: calc(50% + 5.5em); top: 3em;}
			&.ears {left: calc(50% + 1em); top: 2.5em;}
			&.knife {left: calc(50% + 3em); top: 0.6em; width: 6.3em;}
			&.gloves-plastic {left: calc(50% - 10em); top: 6em; width: 5.25em;}
			&.phone {left: calc(50% - 3.5em); bottom: 0.5em;}
			&.pencil-and-pad {left: calc(50% + 1.75em); bottom: 0.75em;}
			&.apron-full {left: calc(50% + 6.25em); top: 5.4em;}
			&.map {left: calc(50% - 4.5em); top: 0.5em;}
		}

		.PaperDoll-selectedItems .PaperDoll-item {
			&.knife {left: calc(50% + 2em); bottom: 0.1em; width: 6.3em;}
			&.pencil-and-pad {left: calc(50% + 1.75em); bottom: 0em;}
			&.phone {left: calc(50% - 9.5em); bottom: 0em;}
			&.map {left: calc(50% + 5.8em); bottom: 0.1em;}
		}
	}

	&.deli-1-paper-doll-items {
		.PaperDoll-selectedItems .PaperDoll-item {
			&.thermometer {left: calc(50% - 6.5em);}
			&.umbrella {left: calc(50% - 0.5em);}
			&.bag-of-bird-seeds {left: calc(50% - 10em); bottom: 0em;}
		}
		.PaperDoll-panel .PaperDoll-availableItems .PaperDoll-item {
			&.apron-full {left: calc(50% - 9.5em); top: 0.9em;}
			&.apron-half {left: calc(50% - 4.75em); top: 7.93em;}
			&.bag-of-bird-seeds {left: calc(50% - 5.5em); top: 0.5em;}
			&.beard-net {width: 3.5em; left: calc(50% + 1.1em); top: 7.5em;}
			&.gloves-white {left: calc(50% - 10em); top: 7em;}
			&.hair-net {left: calc(50% + 0.7em); top: 4em;}
			&.hat-green {left: calc(50% - 5.25em); top: 4em;}
			&.meat-cleaver {left: calc(50% - 1em); top: 0.5em;}
			&.nametag {left: calc(50% + 0.5em); top: 9.9em;}
			&.thermometer {left: calc(50% + 8.15em); top: 7em;}
			&.umbrella {left: calc(50% + 5.25em); top: 0.19em;}
		}
	}

	&.floral-1-paper-doll-items {
		.PaperDoll-selectedItems .PaperDoll-item {
			&.umbrella {width: 4.39em; left: calc(50% + 6em);}
			&.plant-mister {width: 3em; left: calc(50% + 6.5em);}
			&.scissor {width: 6em; left: calc(50% + 2.5em); @include rotate(13deg); bottom: -0.5em}
		}

		.PaperDoll-panel .PaperDoll-availableItems .PaperDoll-item {
			&.gloves-plastic {left: calc(50% - 10em); top: 7em;}
			&.hat-green {left: calc(50% + 1.25em); top: 4.46em;}
			&.thermometer {left: calc(50% - 9.25em); top: 1.59em;}
			&.umbrella {left: calc(50% + 6em); top: 1em;}
			&.badge {left: calc(50% - 1em); top: 1em;}
			&.flower {left: calc(50% - 5em); top: 1em;}
			&.plant-mister {left: calc(50% - 2.3em); top: 6em;}
			&.scissor {left: calc(50% + 3.3em); top: 1em;}
		}
	}

	&.meat-1-paper-doll-dress {
		.PaperDoll-selectedItems .PaperDoll-item {
			&.umbrella {width: 4.39em; left: calc(50% + 6em);}
		}

		.PaperDoll-panel .PaperDoll-availableItems .PaperDoll-item {
			&.meat-cleaver {left: calc(50% + 0.5em); top: 5.75em; z-index: 3;}
			&.beard-net {left: calc(50% + 0.25em); top: 8.75em;}
			&.apron-full {width: 2.75em; left: calc(50% - 10em); top: 0.89em;}
			&.gloves-plastic {left: calc(50% - 2.25em); top: 0.5em;}
			&.flower {width: 2.25em; left: calc(50% - 2em); top: 6em;}
			&.hair-net {width: 4.5em; left: calc(50% + 3em); top: 1em;}
			&.beef-slab {width: 4em; left: calc(50% - 10em); top: 6.5em;}
			&.goggles {left: calc(50% - 6.25em); bottom: 6.5em;}
			&.umbrella {left: calc(50% + 6em); top: 1em;}
			&.scales {width: 5em; left: calc(50% - 10em); top: 9.5em;}
			&.clown-hair {left: calc(50% - 5.75em); top: 6em;}
		}
	}

	&.produce-1-paper-doll-dress {
		.PaperDoll-selectedItems .PaperDoll-item
		{
			&.peeler {width: 2.25em; left: calc(50% + 2.5em);}
			&.knife {width: 6.25em; left: calc(50% + 2.5em);}
			&.book {width: 4.25em; left: calc(50% + 5em);}
			&.baseball-bat { 
				left: calc(50% - 0.75em); 
				@include rotate(32deg); 
				bottom: 2.75em
			}
		}

		.PaperDoll-panel .PaperDoll-availableItems .PaperDoll-item
		{
			&.apron-half {left: calc(50% - 6em); top: 8.75em;}
			&.apron-full {left: calc(50% - 10em); top: 0.89em;}
			&.baseball-bat {left: calc(50% - 6.5em); top: 8.5em;}
			&.beard-net {left: calc(50% + 5em); top: 9em;}
			&.book {left: calc(50% - 5em); top: 1em;}
			&.gloves-plastic {left: calc(50% - 1em); top: 1em;}
			&.fish {left: calc(50% - 10em); top: 9.5em;}
			&.hair-net {left: calc(50% - 1em); top: 5em;}
			&.hat-white {left: calc(50% + 4.75em); top: 0em;}
			&.keys {left: calc(50% + 6.5em); top: 3.75em;}
			&.knife {left: calc(50% - 10em); top: 7em;}
			&.knife3 {left: calc(50% - 4em); top: 10em;}
			&.peeler {left: calc(50% + 3.5em); top: 1em;}
			&.vegetables {left: calc(50% + 5em); top: 6em;}
		}
	}

	&.seafood-1-paper-doll-dress {
		.PaperDoll-selectedItems .PaperDoll-item {
			&.bucket {left: calc(50% - 7.25em); bottom: 0em}
			&.ice-bucket {left: calc(50% - 11em); bottom: 0em}
			&.fish {left: calc(50% - 10em);}
			&.knife-sharpener {width: 7em; left: calc(50% + 2.5em);}
			&.knife2 {width: 6.25em; left: calc(50% + 2.5em);}
			&.scissor {width: 6em; left: calc(50% + 2.5em); @include rotate(13deg); bottom: -0.5em}
		}

		.PaperDoll-panel .PaperDoll-availableItems .PaperDoll-item {
			&.gloves-plastic {left: calc(50% - 1em); top: 1em;}
			&.apron-full {left: calc(50% - 10em); top: 0.89em;}
			&.beard-net {left: calc(50% + 0.5em); top: 9em;}
			&.ice-bucket {left: calc(50% + 6em); top: 6.5em;}
			&.knife2 {left: calc(50% + 4.5em); top: 1em;}
			&.fish {left: calc(50% - 6.5em); top: 1em;}
			&.knife-sharpener {left: calc(50% - 10em); top: 7em;}
			&.hair-net2 {left: calc(50% + 5.5em); top: 3em; width: 4.5em;}
			&.box-cutter {width: 4em; left: calc(50% - 4.5em); top: 8.75em;}
			&.bucket {left: calc(50% + 1em); top: 5.5em;}
			&.scissor {left: calc(50% - 5.3em); top: 4em;}
			&.hat-white-2 {width: 4.25em; left: calc(50% - 9.5em); top: 8.5em;}
		}
	}

	&.bakery-1-paper-doll-1 {
		.PaperDoll-selectedItems .PaperDoll-item
		{
			&.ice-bucket {left: calc(50% - 6.5em); bottom: 0em}
			&.oven-timer {left: calc(50% + 5.75em); width: 3em;}
			&.rolling-pin {left: calc(50% + 0.25em); @include rotate(34deg); bottom: 2em}
			&.flour {left: calc(50% - 10.5em); bottom: 0em}
			&.scotch-tape {width: 4em;}
		}

		.PaperDoll-panel .PaperDoll-availableItems .PaperDoll-item
		{
			&.gloves-white {left: calc(50% - 1em); top: 1em;}
			&.apron-full {left: calc(50% - 10em); top: 0.89em;}
			&.beard-net {left: calc(50% + 0.5em); top: 9em;}
			&.ice-bucket {left: calc(50% + 6em); top: 7em; width: 4em;}
			&.hat-white {left: calc(50% + 5em); top: 3em; width: 5em;}
			&.hammer {left: calc(50% + 4.5em); top: 0.25em;}
			&.scotch-tape {left: calc(50%); top: 5.53em;}
			&.oven-timer {left: calc(50% - 5.5em); top: 0.5em; width: 3em;}
			&.rolling-pin {left: calc(50% - 9em); top: 9em; width: 8em;}
			&.flour {left: calc(50% - 5em); top: 4.25em; width: 3.5em;}
			&.cloth {left: calc(50% - 10em); top: 7em; width: 4em;}
		}
	}

	&.landscape {
		@include avatar-maker-landscape-background();
	}

	&.completed {
		background: none;
	}
}