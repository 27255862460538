@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/mixins_backgrounds';
@import '../../../styles/animations';
@import '../../../styles/animations-intro';
.ProfileHighscore {
	height: 100%;
	.ProfileHighscore-title {
		color: #11466B;
		font-weight: 900;
		font-size: 1.56em;	
		text-align: center;
	}
	.ProfileHighscore-content {
		height: 32.4em;
		border-radius: 1.1em;
		padding: 1.3em 0.8em;
		@include flex('', 'center', 'column');
		.ProfileHighscore-buttons {
			@include flex('space-between', 'center');
			width: 17.6em;
			height: 1.8em;
			.ProfileHighscore-toggleBtn {
				width: 5.61em;
				height: 100%;
				border-radius: 0.89em;
				background-color: white;
				@include opacity(0.5);
				@include flex('center', 'center');
				span {
					color: #10476C;
					font-size: 0.78em;
					font-weight: 700;
				}
				&.active {
					@include opacity(1);
				}
			}
		}


	

		.ProfileHighscore-list {
			background: linear-gradient(to bottom, #F5F5F5 50%, #F5F5F5 85.5%, #D7D7D8 85.5%);
			@include flex('', 'center', 'column');
			margin-top: 1em;
			width: 17.23em;
			height: 21.77em;
			border-radius: 1.12em;
			padding: 0.66em 0.77em 0.38em 0.38em;
			gap: 0.45em;
			position: relative;
			&.small {
				background: #F5F5F5;
				height: 19.22em;
			}
			.ProfileHighscore-highscoreEntry {
				@include flex('flex-start', 'center');
				border-bottom-style: solid;
				border-bottom-color: #D7D7D8;
				border-bottom-width: 0.15em;
				height: 2.55em;
				width: 100%;
				padding-left: 0.72em;
				border-radius: 1.28em;
				span {
					font-size: 0.88em;
					font-weight: 700;
					color: $blue-dark2;
					white-space: nowrap;
				}
				.ProfileHighscore-position {
					@include flex('flex-start', '');
					span:first-of-type {
						margin-right: 0.77em;
					}
					span:last-of-type {

						text-overflow:ellipsis;
						overflow: hidden;
					}
					width: 7.5em;
				
				}
				.ProfileHighscore-avatarContainer {
					position: absolute;
					left: 14.16em;
				}
				
				
				.ProfileHighscore-star,
				.ProfileHighscore-badge {
					position: absolute;
					left: 9.11em;
					width: 3.16em;
					background-image: url('../../../assets/images/profile/icon-highscore-star.svg');
					@include flex('flex-end', 'center');
					background-position: left;
					background-size: 1.44em 1.36em;
					height: 1.44em;
					
					span {
						font-size: 1.11em;
						font-weight: 500;
						margin-right: 0.44em;
					}
				}
				.ProfileHighscore-badge {
					background-image: url('../../../assets/images/profile/icon-profile-badges.svg');

				}

				.ProfileHighscore-avatarContainer {
					margin-top: 0.15em;
				}
				
				&.position-1, &.position-2, &.position-3 {
					.ProfileHighscore-star span,
					.ProfileHighscore-badge span {
						font-weight: 900;
					}
				}
				&.position-1 {
					background-color: #E8B341;
					border-bottom-color: #E8B341;
				}
				&.position-2 {
					background-color: rgba(#E8B341, 0.8);
					border-bottom-color: rgba(#E8B341, 0.8);
				}
				&.position-3 {
					background-color: rgba(#E8B341, 0.6);
					border-bottom-color: rgba(#E8B341, 0.6);
				}
				&.last {
					background-color: #10476C;
					border-bottom-color: #10476C;
					span {
						color: white;
					}
					margin-top: auto;
				}
			
			}
		}
	}
}