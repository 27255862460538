@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';
@import '../../../../styles/mixins_backgrounds';
@import '../../../../styles/animations';
@import '../../../../styles/animations-intro';
.FacilitatorRemovePlayerPopup {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(#7ABCEA, 0.81);
	@include flex('center', 'center');

	.FacilitatorRemovePlayerPopup-content {
		width: 35em;
		min-height: 14em;
		background-color: white;
		border-radius: 0.83em;

		.FacilitatorRemovePlayerPopup-header {
			position: relative;
			width: 100%;
			min-height: 2.8em;
			background-color: #11466B;
			border-top-left-radius: 0.83em;
			border-top-right-radius: 0.83em;
			border: 0.25em solid white;
			padding-left: 1em;
			@include flex('flex-start', 'center');
			.FacilitatorRemovePlayerPopup-closeBtn {
				position: absolute;
				top: 0;
				right: 0;
				width: 2.33em;
				height: 2.33em;
				background-image: url('../../../../assets/images/facilitator/icon-close.svg');
				background-size: 1.56em auto;
				cursor: pointer;
			}
			.FacilitatorRemovePlayerPopup-title {
				color: #ffffff;
				font-size: 1.25em;
				font-weight: 600;
			}
		}

		.FacilitatorRemovePlayerPopup-body {
			padding: 1em;
			.FacilitatorRemovePlayerPopup-text {
				color: #525252;
			}
			.FacilitatorRemovePlayerPopup-buttons {
				margin-top: 2em;
				text-align: center;
			}
		}
	}
}