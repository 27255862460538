.MultipleChoice {
	&.daily-guidelines-1-label {
		.MultipleChoice-options {
			width: 17.6em;
			left: calc(50% - 8.8em);
			.MultipleChoice-optionsWrap {
				height: 10em;
				background-color: #F5F5F5;
				@include box-shadow(0.5em, 0.5em, 0, 0, rgba(black, 0.25));
				.MultipleChoice-option {
					position: absolute;
					span:nth-child(2) {
						width: 1.25em;
						height: 1.25em;
						left: 50%;
						bottom: 0.1em;
					}
					&.option-1 {
						left: 0;
						top: 0;
						width: 7.14em;
						height: 2.14em;
						background-size: 5.97em auto;
						background-position: bottom right;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/daily-guidelines-1-label/option-1.svg');
					}
					&.option-2 {
						left: 0;
						top: 2.5em;
						width: 7.14em;
						height: 1.3em;
						background-size: 5.72em auto;
						background-position: bottom 0.25em right 0.1em;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/daily-guidelines-1-label/option-2.svg');
					}
					&.option-3 {
						left: 0;
						top: 3.8em;
						width: 3.33em;
						height: 1.5em;
						background-size: auto 0.69em;
						background-position: top 0.5em right;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/daily-guidelines-1-label/option-3.svg');
					}	
					&.option-4 {
						left: 3.75em;
						top: 3.8em;
						width: 3.65em;
						height: 1.5em;
						background-size: auto 0.69em;
						background-position: top 0.5em right;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/daily-guidelines-1-label/option-4.svg');
						span:nth-child(2) {left: calc(50% - 0.35em);}
					}	
					&.option-5 {
						top: 1.26em;
						right: 1em;
						width: 7.54em;
						height: 6.89em;
						background-size: contain;
						background-position: top right;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/daily-guidelines-1-label/option-5.svg');
						span:nth-child(2) {left: 0.25em;}
					}
					&.option-6 {
						left: 0.73em;
						bottom: 0.72em;
						width: 3.79em;
						height: 2.44em;
						background-size: contain;
						background-position: bottom left;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/daily-guidelines-1-label/option-6.svg');
						span:nth-child(2) {left: 0em;}
					}
				}
			}	
		}
	}
	&.daily-guidelines-1-table-with-cookies {
		.MultipleChoice-options {
			width: 14em;
			left: calc(50% - 7em);
			margin-top: 2em;
			.MultipleChoice-optionsWrap {
				height: 21.4em;
				.MultipleChoice-option {
					&:first-of-type {
						margin-bottom: 1em;
					}
					span:nth-child(2) {
						left: auto;
						right: 0;
					}
					&.option-1,
					&.option-2 {
						width: 100%;
						height: 10.22em;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/daily-guidelines-1-table-with-cookies/option-1.svg');
					}
					&.option-2 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/daily-guidelines-1-table-with-cookies/option-2.svg');
					}
				}
			}	
		}
	}
	&.daily-guidelines-1-wic-what-not {
		.MultipleChoice-options {
			width: 16em;
			left: calc(50% - 8em);
			margin-top: 2em;
			.MultipleChoice-optionsWrap {
				height: 15.75em;
				.MultipleChoice-option {
					position: absolute;
					span:nth-child(2) {
						width: 1.25em;
						height: 1.25em;
						left: 50%;
						bottom: 0.1em;
					}
					&.option-1 {
						top: 2em;
						right: 1em;
						width: 7.22em;
						height: 3.95em;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/daily-guidelines-1-wic-what-not/option-1.svg');
					}
					&.option-2 {
						left: 5.5em;
						bottom: 1.83em;
						width: 6.24em;
						height: 3.35em;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/daily-guidelines-1-wic-what-not/option-2.svg');
					}
					&.option-3 {
						right: 0;
						bottom: 0;
						width: 2.93em;
						height: 7em;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/daily-guidelines-1-wic-what-not/option-3.svg');
					}	
					&.option-4 {
						left: 0;
						bottom: 0;
						width: 3.56em;
						height: 6.92em;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/daily-guidelines-1-wic-what-not/option-4.svg');
						span:nth-child(2) {left: calc(50% - 0.35em);}
					}	
					&.option-5 {
						left: 1em;
						top: 0;
						width: 3.73em;
						height: 7.25em;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/daily-guidelines-1-wic-what-not/option-5.svg');
					}
				}
			}	
		}
	}
	&.daily-guidelines-1-signs {
		.MultipleChoice-options {
			width: 18em;
			left: calc(50% - 9em);
			margin-top: 2em;
			.MultipleChoice-optionsWrap {
				height: 16em;
				width: 100%;
				background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/daily-guidelines-1-signs/background.svg');
				background-size: 18em 16em;

				.MultipleChoice-option {
					position: absolute;
					span:nth-child(2) {
						width: 1.25em;
						height: 1.25em;
						left: 50%;
						bottom: 0.1em;
					}
					&.option-1 {
						top: 1.5em;
						right: 2.3em;
						width: 4.3em;
						height: 5em;
					}
					&.option-2 {
						left: 3.65em;
						bottom: 2.5em;
						width: 1.7em;
						height: 2em;
					}
					&.option-3 {
						left: 0.75em;
						top: 0.25em;
						width: 2.80em;
						height: 3.3em;
					}
					&.option-4 {
						left: 3.1em;
						bottom: 6.3em;
						width: 2.8em;
						height: 3.3em;
					}
				}
			}	
		}
	}
}