@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/mixins_backgrounds';
@import '../../../styles/animations';
@import '../../../styles/animations-intro';
.PointsTrack {
	position: relative;
	width: 100%;
	margin-top: 0.05em;
	padding-top: 0.85em;
	.PointsTrack-track {
		width: 100%;
		height: 1.11em;
		background-color: #082940;
		border-radius: 0.67em;
		margin: auto;
		border: 0.15em solid #11466B;
		overflow: hidden;
		.PointsTrack-score {
			width: 0;
			height: 100%;
			border-top-left-radius: 0.67em;
			border-bottom-left-radius: 0.67em;
			background-color: #E8B341;
			background-image: linear-gradient(#F0C241, #E8B341, #CE9135);
			background-size: 100% 100%;
			@include box-inner-shadow(0, 0.15em, 0, 0, #F9D043);
			@include transition(0.5s, width, ease-in-out);
		}
	}

	.PointsTrack-stars {
		position: absolute;
		top: 0.55em;
		width: 100%;
		.PointsTrack-star {
			position: absolute;
			top: 0;
			left: 0;
			@include translate(-0.75em);
		}
	}
}