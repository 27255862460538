@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';
@import '../../../../styles/mixins_backgrounds';
@import '../../../../styles/animations';
@import '../../../../styles/animations-intro';
.Image {
	position: relative;
	height: 100%;
	padding-top: 4em;
	@include grey-background();

	.Image-intro {
		position: absolute;
		top: 4em;
		left: calc(50% - 8.75em);
		width: 17.5em;	
	}

	.Image-countdown {
		position: relative;
		left: calc(50% - 5em);
	}

	.Image-image {
		position: relative;
		height: 100%;
	}

	&.bagger-1-employee-perspective {
		min-height: 29em;
		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			bottom: 20.25em;
			width: 100%;
			background-image: linear-gradient(rgba(#808285, 0), #808285);
			background-size: 100% 100%;
		}
		.Image-image {
			height: 100%;
			background-image: url('../../../../assets/images/modules/tasks/image/bagger-1-employee-perspective.svg');
			background-size: auto 17.93em;
			background-position: bottom 7.1em left 50%;
		}
	}

	&.bakery-1-department {
		min-height: 36.5em;
		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 16.2em;
			background-image: linear-gradient(rgba(#808285, 0), #808285);
		}
		.Image-image {
			height: 100%;
			background-image: url('../../../../assets/images/modules/tasks/image/bakery-1-department.svg');
			background-size: auto 31.48em;
			background-position: top 1em left calc(50% + 0.5em);
		}
	}

	&.bakery-1-donuts-on-shelves {
		min-height: calc(17.7em + 6em);
		.Image-image {
			height: 100%;
			background-image: url('../../../../assets/images/modules/tasks/image/bakery-1-donuts-on-shelves.svg');
			background-size: auto 17.7em;
			background-position: top 6em center;
		}
	}

	&.bakery-1-cakes-stacked {
		min-height: calc(23.3em + 4.78em);
		.Image-image {
			height: 100%;
			background-image: url('../../../../assets/images/modules/tasks/image/bakery-1-cakes-stacked.svg'), linear-gradient(rgba(#808285, 0), #808285);
			background-size: auto 23.3em, 100% 24em;
			background-position: top 4.78em center, top center;
		}
	}
	&.bakery-1-cakes-fallen-over {
		min-height: calc(23.3em + 11em);
		.Image-image {
			height: 100%;
			background-image: url('../../../../assets/images/modules/tasks/image/bakery-1-cakes-fallen-over.svg'), linear-gradient(rgba(#808285, 0), #808285);
			background-size: auto 21.08em, 100% 24em;
			background-position: top 11em left calc(50% + 1.6em), top center;
		}
	}

	&.center-store-1-endcap {
		min-height: 36.5em;
		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			bottom: calc(50% - 2em);
			width: 100%;
			background-image: linear-gradient(rgba(#808285, 0), #808285);
			background-size: 100% 100%;
		}
		.Image-image {
			height: 100%;
			background-image: url('../../../../assets/images/modules/tasks/image/center-store-1-endcap.svg');
			background-size: auto 20em;
			background-position: bottom calc(50% + 2.5em) left calc(50%);
		}
	}

	&.center-store-1-shelf {
		min-height: 36.5em;
		.Image-image {
			height: 100%;
			background-image: url('../../../../assets/images/modules/tasks/image/center-store-1-shelf.svg');
			background-size: auto 15em;
			background-position: bottom calc(50% + 4.5em) left calc(50%);
		}
	}

	&.center-store-1-fixed-shelf {
		min-height: calc(14.33em + 7em);
		.Image-image {
			height: 100%;
			background-image: url('../../../../assets/images/modules/tasks/image/center-store-1-fixed-shelf.svg');
			background-size: auto 14.33em;
			background-position: top 7em center;
		}
	}

	&.center-store-1-shelf-juice {
		.Image-image {
			background-image: url('../../../../assets/images/modules/tasks/image/center-store-1-shelf-juice.svg');
			background-size: auto 8.25em;
			background-position: top 5em center;
		}
	}

	&.center-store-2-checklist {
		@include center-store-blue-background();
		&.landscape {@include center-store-blue-landscape-background();}
		.Image-image {
			min-height: calc(20.4em + 5em);
			background-image: url('../../../../assets/images/modules/tasks/image/center-store-2-checklist.svg');
			background-size: auto 20.4em;
			background-position: top 5em center;
		}
	}

	&.cleaning-1-3-well-sink {
		.Image-image {
			min-height: calc(7.12em + 9.5em);
			background-image: url('../../../../assets/images/modules/tasks/image/cleaning-1-3-well-sink.svg'), linear-gradient(rgba(#808285, 0), #808285);
			background-size: auto 7.12em, 100% 20.11em;
			background-position: top 9.5em center, top center;	
		}
	}

	&.cleaning-1-meat-production {
		.Image-image {
			min-height: calc(7.12em + 9.5em);
			background-image: url('../../../../assets/images/modules/tasks/image/cleaning-1-meat-production.svg'), linear-gradient(rgba(#808285, 0), #808285);
			background-size: auto 18.2em, 100% 13.6em;
			background-position: top 6em left calc(50% + 0.25em), top center;	
		}
	}
	
	&.cooler-freezer-1-shelf-with-lettuce {
		.Image-image {
			min-height: calc(15.62em + 5em);
			background-image: url('../../../../assets/images/modules/tasks/image/cooler-freezer-1-shelf-with-lettuce.svg');
			background-size: auto 15.62em;
			background-position: top 5em center;
		}
	}
	&.cooler-freezer-1-possible-errors {
		.Image-image {
			min-height: calc(20.4em + 5em);
			background-image: url('../../../../assets/images/modules/tasks/image/cooler-freezer-1-possible-errors.svg');
			background-size: auto 20.4em;
			background-position: top 5em center;
		}
	}

	&.customer-service-1-daily-information-board {
		min-height: 35em;
		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			height: 29em;
			width: 100%;
			background-image: linear-gradient(rgba(#808285, 0), #808285);
			background-size: 100% 29em;
		}
		.Image-image {
			height: 100%;
			background-image: url('../../../../assets/images/modules/tasks/image/customer-service-1-daily-information-board.svg');
			background-size: 18.11em auto;
			background-position: top 5.44em center;
		}
	}

	&.customer-service-1-clip-board {
		min-height: 35em;
		.Image-image {
			height: 100%;
			background-image: url('../../../../assets/images/modules/tasks/image/customer-service-1-clip-board.svg');
			background-size: 16em auto;
			background-position: top 5.44em center;
		}
	}

	&.customer-service-1-desk {
		min-height: 35em;
		&::before {
			content: '';
			position: absolute;
			bottom: 18.05em;
			left: 0;
			height: 30em;
			width: 100%;
			background-image: linear-gradient(rgba(#808285, 0), #808285);
			background-size: 100% 29em;
		}
		.Image-image {
			height: 100%;
			background-image: url('../../../../assets/images/modules/tasks/image/customer-service-1-desk.svg');
			background-size: 28em auto;
			background-position: bottom 14em left calc(50% + 3.75em);
		}
	}
	&.customer-service-1-desk-front {
		min-height: 35em;
		&::before {
			content: '';
			position: absolute;
			bottom: 18.05em;
			left: 0;
			height: 30em;
			width: 100%;
			background-image: linear-gradient(rgba(#808285, 0), #808285);
			background-size: 100% 29em;
		}
		.Image-image {
			height: 100%;
			background-image: url('../../../../assets/images/modules/tasks/image/customer-service-1-desk-front.svg');
			background-size: 28em auto;
			background-position: bottom 9em left calc(50% + 3.75em);
		}
	}

	&.deli-2-case-types {
		@include deli-blue-background();
		&.landscape {@include deli-blue-landscape-background();}
		min-height: calc(20.4em + 2em);
		.Image-image {
			height: 100%;
			background-image: url('../../../../assets/images/modules/tasks/image/deli-2-case-types.svg');
			background-size: auto 20.4em;
			background-position: top 2em center;
		}
	}

	&.entry-1-ice-cream {
		min-height: 36.5em;
		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			bottom: 9.25em;
			width: 100%;
			background-image: linear-gradient(rgba(#808285, 0), #808285);
			background-size: 100% 100%;
		}
		.Image-image {
			height: 100%;
			background-image: url('../../../../assets/images/modules/tasks/image/entry-1-ice-cream.svg');
			background-size: auto 21.5em;
			background-position: bottom 4.83em center;
		}
	}

	&.food-safety-1-sink {
		min-height: 35em;
		width: 100%;
		.Image-image {
			position: relative;
			top: 10em;
			left: calc(50% - 17em / 2);
			height: 10em;
			width: 17em;
			background-image: url('../../../../assets/images/modules/tasks/image/food-safety-1-sink.svg');
			background-size: 16em auto;
			background-position: top calc(50%) center;
		}

		.Image-countdown {
			top: 6em;
			width: 10em;
		}
	}
	
	&.produce-1-scales {
		min-height: 36.5em;
		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			bottom: 20.75em;
			width: 100%;
			background-image: linear-gradient(rgba(#808285, 0), #808285);
			background-size: 100% 100%;
		}
		.Image-image {
			height: 100%;
			background-image: url('../../../../assets/images/modules/tasks/image/produce-1-scales.svg');
			background-size: auto 15em;
			background-position: bottom 17em left calc(50% + 5em);
		}
	}

	&.meat-1-department {
		min-height: 36.5em;
		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			bottom: 20.75em;
			width: 100%;
			background-image: linear-gradient(rgba(#808285, 0), #808285);
			background-size: 100% 100%;
		}
		.Image-image {
			height: 100%;
			background-image: url('../../../../assets/images/modules/tasks/image/meat-1-department.svg');
			background-size: auto 32em;
			background-position: bottom -1em center;
		}
	}

	&.seafood-1-department {
		min-height: 36.5em;
		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			bottom: 15.25em;
			width: 100%;
			background-image: linear-gradient(rgba(#808285, 0), #808285);
			background-size: 100% 100%;
		}
		.Image-image {
			height: 100%;
			background-image: url('../../../../assets/images/modules/tasks/image/seafood-1-department.svg');
			background-size: auto 28.5em;
			background-position: bottom -2em left calc(50%);
		}
	}
	&.seafood-1-calendar-first {
		min-height: calc(16.38em + 5em);
		.Image-image {
			height: 100%;
			background-image: url('../../../../assets/images/modules/tasks/image/seafood-1-calendar-first.svg');
			background-size: auto 16.38em;
			background-position: top 5em left 50%;
		}
	}

	&.shrink-1-people {
		min-height: 36.5em;
		.Image-image {
			height: 100%;
			background-image: url('../../../../assets/images/modules/tasks/image/shrink-1-people.svg');
			background-size: auto 20em;
			background-position: top 5em left calc(50%);
		}
	}
	
	&.meat-1-hot-deal {
		min-height: 36.5em;
		.Image-image {
			height: 100%;
			background-image: url('../../../../assets/images/modules/tasks/image/meat-1-hot-deal.svg');
			background-size: auto 12.5em;
			background-position: top 6em center;
		}
	}

	&.meat-1-sales-floor {
		min-height: 36.5em;
		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			bottom: 21em;
			width: 100%;
			background-image: linear-gradient(rgba(#808285, 0), #808285);
			background-size: 100% 100%;
		}
		.Image-image {
			height: 100%;
			background-image: url('../../../../assets/images/modules/tasks/image/meat-1-sales-floor.svg');
			background-size: auto 34em;
			background-position: bottom -2em left calc(50% + 1.5em);
		}
	}

	&.landscape {
		@include grey-landscape-background();
	}
}