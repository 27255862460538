@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/mixins_backgrounds';
@import '../../../styles/animations';
@import '../../../styles/animations-intro';
.FacilitatorTaskPopup {
	margin: 0 1.5em;
	background-color: #11466B;
	border-radius: 1em;
	border-style: solid;
	border-width: 0.37em;
	border-color: white;
	padding: 0.75em;

	.FacilitatorTaskPopup-header {
		@include flex('space-between','','row');
		@include no-select();
		padding-bottom: 0.37em;
		.FacilitatorTaskPopup-title {
			color: white;
			span {
				font-size: 2.25em;
				font-weight: bold;
			}
		}
		
		.FacilitatorTaskPopup-closeBtn {
			padding: 0em;
			width: 3em;
			height: 3em;
			background-image: url('../../../assets/images/facilitator/icon-close.svg');
			background-size: 2.62em auto;
			cursor: pointer;
		}
	}
	.FacilitatorTaskPopup-body {
		@include flex('', '', 'row');
		padding-bottom: 0.37em;
		height: calc(1.78 * 20em);
		
		&.competition {
			height: calc(1.78 * 30.833em + 3em);
			font-size: 1.1em; // size of tasks popup
		}
		.FacilitatorTaskPopup-task,
		.FacilitatorTaskPopup-taskSolved {
			overflow: hidden;
			border-radius: 3em;
			
			width: 20em;
			height: 100%;
		}
		.FacilitatorTaskPopup-taskSolved {
			margin-left: 1.5em;
			.FacilitatorTaskPopup-taskSolvedInfo {
				width: 100%;
				padding: 3em;
				color: white;
				span {
					font-size: 1.8em;
				}
			}
		}

		

		.FacilitatorTaskPopup-statistics {
			@include flex('space-between', 'center', 'column');
			width: 16.5em;
			height: 100%;
			color: white;
			padding-left: 0.75em;
			padding-right: 0.75em;

			.FacilitatorTaskPopup-statisticsHeader
			{
				@include flex('', 'center', 'column');
				.FacilitatorTaskPopup-statisticsResult
				{
					font-weight: bold;
					font-size: 3em;
				}
			}
		}
	}

	.FacilitatorTaskPopup-taskLabels {
		width: 100%;
		height: 3em;
		@include flex('space-between', 'center');
		.FacilitatorTaskPopup-taskLabel {
			width: 75%;
			text-align: center;
			span {
				color: white;
				font-weight: bold;
			}
		}
	}


	.FacilitatorTaskPopup-footer
	{
		@include flex('space-between','center','row');
		bottom: 0;
		width: 100%;
		.FacilitatorTaskPopup-previous
		{
			background-image: url('../../../assets/images/facilitator/icon-arrow-previous.svg');
			width: 3em;
			height: 3em;
			background-size: 2.62em auto;
			cursor: pointer;
		}
		.FacilitatorTaskPopup-taskText
		{
			@include no-select();
			color: white;
			font-weight: bold;
		}
		.FacilitatorTaskPopup-next
		{
			background-image: url('../../../assets/images/facilitator/icon-arrow-next.svg');
			width: 3em;
			height: 3em;
			background-size: 2.62em auto;
			cursor: pointer;
		}
	}
}