@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/mixins_backgrounds';
@import '../../../styles/animations';
@import '../../../styles/animations-intro';
.FacilitatorInsights {
	position: relative;
	min-height: 100%;

	.FacilitatorInsights-notifications {
		padding-top: 2em;

		.FacilitatorInsights-notification {
			width: 40em;
			background-color: white;
			border-radius: 0.83em;
			margin-bottom: 2em;
			.FacilitatorInsights-notificationHeader {
				position: relative;
				height: 3.5em;
				color: white;
				background-color: #11466B;
				border-top-left-radius: 0.83em;
				border-top-right-radius: 0.83em;
				padding: 0 1em;
				@include flex('center', 'flex-start', 'column');
				.FacilitatorInsights-notificationTitle {
					span {
						font-size: 1.11em;
						font-weight: bold;
					}
				}
				.FacilitatorInsights-notificationCreated {
					span {
						font-size: 1em;
					}
				}
				.FacilitatorInsights-deleteNotificationBtn {
					position: absolute;
					top: 0.25em;
					right: 0.5em;
					width: 2em;
					height: 2em;
					background-image: url('../../../assets/images/facilitator/icon-close.svg');
					cursor: pointer;
				}
			}
			.FacilitatorInsights-notificationBody {
				position: relative;
				padding: 1.25em 1em;
				border: 0.1em solid #11466B;
				border-bottom-left-radius: 0.83em;
				border-bottom-right-radius: 0.83em;
				color: #11466B;
				.FacilitatorInsights-notificationText {
					p {
						margin-top: 0;
						&:last-of-type {
							margin-bottom: 0;
						}
					}
				}
				.FacilitatorInsights-notificationTaskInfo {
					text-align: center;
					margin-top: 1.5em;
					cursor: pointer;

					span {
						display: inline-block;
						font-size: 1.5em;
						padding-right: 1.5em;
						font-weight: bold;
						background-image: url('../../../assets/images/facilitator/icon-eye.svg');
						background-size: 1em auto;
						background-position: center right;
					}
				}
				.FacilitatorInsights-notificationPlayers {
					margin-top: 1.5em;
					span {
						font-weight: bold;
					}
				}
				.FacilitatorInsights-notificationAction {
					background-color: #ECECEC;
					padding: 0.5em 1em;
					margin-top: 1.5em;
					.FacilitatorInsights-notificationActionTitle {
						padding-left: 1.5em;
						background-image: url('../../../assets/images/facilitator/icon-megafon.svg');
						background-size: 1em auto;
						background-position: left center;
						margin-bottom: 0.5em;
						span {
							font-weight: 700;
						}
					}
					.FacilitatorInsights-notificationActionText {
						p {
							margin-top: 0;
							&:last-of-type {
								margin-bottom: 0;
							}
						}
					}
				}
				.FacilitatorInsights-mute {
					position: absolute;
					bottom: 0.15em;
					right: 0.5em;
					padding-left: 1em;
					background-image: url('../../../assets/images/facilitator/icon-checkbox-unchecked.svg');
					background-size: auto 0.7em;
					background-position: left center;
					line-height: 1;
					cursor: pointer;
					span {
						@include no-select();
						font-size: 0.7em;
					}
					&.muted {
						background-image: url('../../../assets/images/facilitator/icon-checkbox-checked.svg');
					}
				}
			}
		}
	}

	.FacilitatorInsights-viewMutedBtn {
		position: absolute;
		top: 3em;
		right: 1em;
		width: 10em;
	}

	.FacilitatorInsights-devTools {
		position: absolute;
		top: 6em;
		right: 1em;
		width: 20em;
		> div {
			margin-bottom: 1em;
		}
	}
}