@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/mixins_backgrounds';
@import '../../../styles/animations';
@import '../../../styles/animations-intro';
.FacilitatorManagerPopup {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(#7ABCEA, 0.81);
	@include flex('center', 'center');

	.FacilitatorManagerPopup-content {
		width: 50em;
		min-height: 10em;
		background-color: white;
		border-radius: 0.83em;

		.FacilitatorManagerPopup-header {
			position: relative;
			width: 100%;
			height: 4.25em;
			background-color: #11466B;
			border-top-left-radius: 0.83em;
			border-top-right-radius: 0.83em;
			border-top: 0.25em solid white;
			border-right: 0.25em solid white;
			border-left: 0.25em solid white;
			.FacilitatorManagerPopup-title {
				position: absolute;
				left: 2.56em;
				top: 0;
				bottom: 0;
				@include flex('flex-start', 'center');
				span {
					color: #ffffff;
					font-size: 1.4em;
					font-weight: 600;
				}
			}
			.FacilitatorManagerPopup-closeBtn {
				position: absolute;
				top: 0;
				right: 0;
				width: 4.25em;
				height: 3.75em;
				background-image: url('../../../assets/images/facilitator/icon-close.svg');
				background-size: 1.56em auto;
				cursor: pointer;
			}
		}

		.FacilitatorManagerPopup-body {
			height: calc(100% - 4.25em);
			padding: 1em;
			.FacilitatorManagerPopup-text {
				margin-bottom: 1em;
			}
			.FacilitatorManagerPopup-managers {
				.FacilitatorManagerPopup-manager {
					display: inline-block;
					width: 8em;
					height: 8em;
					border-radius: 100%;
					border: 0.15em solid #EDEDED;
					margin: 1em;
					cursor: pointer;
					&.selected {
						border-color: #10779E;
					}
				}
			}
			.FacilitatorManagerpopup-saveBtn {
				margin-top: 1em;
				width: 7em;
			}
		}
	}
}