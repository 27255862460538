@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';
@import '../../../../styles/mixins_backgrounds';
@import '../../../../styles/animations';
@import '../../../../styles/animations-intro';
.FacilitatorNewMessagePopup {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(#7ABCEA, 0.81);
	@include flex('center', 'center');

	.FacilitatorNewMessagePopup-closeBtn {
		padding: 0em;
		width: 2em;
		height: 2em;
		background-image: url('../../../../assets/images/facilitator/icon-close.svg');
		background-size: 1.75em auto;
		cursor: pointer;
	}

	.FacilitatorNewMessagePopup-content {
		width: 47em;
		min-height: 14em;
		max-height: 100vh;
		background-color: white;
		border-radius: 0.83em;
		overflow: auto;
		.FacilitatorNewMessagePopup-header {
			position: relative;
			width: 100%;
			height: 2.8em;
			background-color: #11466B;
			border-top-left-radius: 0.83em;
			border-top-right-radius: 0.83em;
			border: 0.25em solid white;
			padding-left: 1em;
			@include flex('space-between', 'center');
			.FacilitatorNewMessagePopup-title {
				color: #ffffff;
				font-size: 1.25em;
				font-weight: 600;
			}
			.FacilitatorNewMessagePopup-closeBtn {
				width: 2.33em;
				height: 2.33em;
				background-image: url('../../../../assets/images/facilitator/icon-close.svg');
				background-size: 1.56em auto;
				cursor: pointer;
			}
		}

		.FacilitatorNewMessagePopup-body {
			position: relative;
			padding: 1.5em 1.5em 1em 1.5em;
			

			.FacilitatorNewMessagePopup-text {
				margin-bottom: 1em;
				.FacilitatorNewMessagePopup-textLabel {
					background-image: url('../../../../assets/images/facilitator/icon-megafon.svg');
					background-position: left center;
					padding-left: 2em;
					margin-bottom: 0.5em;
					span {
						color: #10779E;
						font-weight: 600;
						text-decoration: underline;
					}
				}
				.FacilitatorNewMessagePopup-textInput {
					width: 100%;
					textarea {
						width: 100%;
						height: 8em;
						resize: none;
						border: 0.1em solid #10779E;
						border-radius: 0.4em;
						padding: 0.5em;
					}
				}
			}

			.FacilitatorNewMessagePopup-recipients {
				margin-bottom: 2em;
				.FacilitatorNewMessagePopup-recipientsLabel {
					background-image: url('../../../../assets/images/facilitator/icon-players-2-blue.svg');
					background-position: left center;
					padding-left: 2em;
					margin-bottom: 0.5em;
					span {
						color: #10779E;
						font-weight: 600;
						text-decoration: underline;
					}
				}
				.FacilitatorNewMessagePopup-recipientsOption {
					position: relative;
					padding-left: 1.5em;
					margin-bottom: 0.5em;
					cursor: pointer;
					> span {
						color: #10779E;
					}
					&::after {
						content: '';
						position: absolute;
						left: 0;
						top: calc(50% - 0.4em);
						width: 0.8em;
						height: 0.8em;
						border-radius: 100%;
						border: 0.1em solid #10779E;
					}
					&.selected {
						> span {
							font-weight: bold;
						}
						&::after {
							background-color: #10779E;
						}
					}
					.FacilitatorNewMessagePopup-departmentsSelect {
						position: absolute;
						width: 20em;
						right: 1.5em;
						top: -0.25em;
					}
				}
			}

			
			

			.FacilitatorNewMessagePopup-errorMsg {
				height: 2em;
				margin-bottom: 1em;
				p {
					margin: 0;
					font-size: 1.2em;
					color: #C13B3B;
					font-weight: bold;
				}
			}

			.FacilitatorNewMessagePopup-confirmBtn {
				width: 8em;	
			}
		}
	}
}