@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';
@import '../../../../styles/mixins_backgrounds';
@import '../../../../styles/animations';
@import '../../../../styles/animations-intro';
.Organize {
	position: relative;
	height: 100%;
	padding-top: 2em;
	padding-bottom: 1em;
	overflow: auto;
	@include grey-background();
	&.landscape {
		@include grey-landscape-background();
	}
	.Organize-intro {
		position: relative;
		width: 17.5em;
		margin: 1.25em auto 0.8em auto;
	}
	.Organize-mainContainer {
		.Organize-containers {
			position: relative;
			@include flex('flex-start', 'flex-start', 'row');
			flex-wrap: wrap;
		}
	}

	.Organize-doneBtn {
		position: fixed;
		right: calc(50% - 8.5em);
		bottom: 0.5em;
		z-index: 2;
		width: 7em;
	}

	&.backroom-5-s-organize-files {
		.Organize-mainContainer {
			&.initial {
				position: absolute;
				display: block;
				top: 1em;
				left: calc(50% - 10em);
				width: 5em;
				height: 15em;
				.Organize-containers {
					width: 100%;
					height: 100%;
					@include flex('space-between','','column');
					.Organize-container {
						display: inline-block;
						vertical-align: top;
						width: 5em;
						height: 3.7em;
					}
				}
			}
			&.folders {
				position: absolute;
				top: 2.75em;
				left: calc(50% - 3.75em);
				width: 13.5em;
				height: 11.7em;
				.Organize-containers {
					width: 100%;
					height: 100%;
					@include flex('space-between');
					.Organize-container {
						width: 6.5em;
						height: 5.1em;
						margin-top: 0.15em;

						&:nth-of-type(1),
						&:nth-of-type(2) {
							margin-bottom: 1.3em;
						}
					}
				}
			}
		}		
	}
	&.backroom-5-s-organize-shelves {
		.Organize-mainContainer {
			&.shelves {
				.Organize-containers {
					margin: auto;
					width: 20.8em;
					height: 18.3em;
					background-image: url('../../../../assets/images/modules/tasks/organize/backroom-5-s-organize-shelves/shelves.svg');
					background-size: 100% auto;
					background-position: top center;
					@include flex('space-between');
					.Organize-container {
						width: 10.4em;
						height: 5.26em;
						overflow: hidden;
						margin-bottom: 0.84em;
						&:nth-of-type(1),
						&:nth-of-type(3),
						&:nth-of-type(5) {
							padding-left: 1.5em;
						}
						&:nth-of-type(5) {
							width: 100%;
						}
					}
				}
			}
			&.initial {
				margin-top: 1.5em;
				.Organize-containers {
					display: block;
					width: 100%;
					height: 5.5em;
					background-color: white;
					padding: 0.25em 0.5em;
					overflow: auto;
					white-space: nowrap;
					.Organize-container {
						display: inline-block;
						vertical-align: middle;
						width: 4.75em;
						height: 4.75em;
						margin: 0 0.1em;
					}
				}
			}
		}
		&.landscape {
			.Organize-mainContainer { 
				&.initial {
					margin: 2em auto 0 auto;
					width: 45em;
					max-width: 100%;
					padding: 0 4em;
					background-image: linear-gradient(to right, rgba(white, 0) 0%, rgba(white, 1) 10%, rgba(white, 1) 90%, rgba(white, 0) 100%);
					.Organize-containers {
						@include flex('center', 'center');
						background-color: transparent;
					}
				}
			}
		}
	}

	&.bakery-1-donuts {
		.Organize-mainContainer {
			&.initial {
				margin-top: 0.8em;
				.Organize-containers {
					display: block;
					width: 100%;
					height: 5em;
					background-color: white;
					padding: 0.1em 0.5em;
					overflow: auto;
					white-space: nowrap;
					.Organize-container {
						display: inline-block;
						vertical-align: top;
						width: 4.75em;
						height: 4.5em;
						margin-right: 0;
					}
				}
			}
			&.remove {
				width: 12em;
				margin-left: calc(50% - 7em);
				text-align: right;
				overflow: visible;
				.Organize-containers {
					@include flex ('center', 'flex-end', 'row');
					position: relative;
					left: calc(50% - 1.5em);
					width: 12em;
					height: 5.5em;
					&::after {
						content: '';
						position: absolute;
						width: 100%;
						height: 100%;
						background-image: url('../../../../assets/images/modules/tasks/organize/bakery-1-donuts/remove.svg');
						background-position: center bottom;
						pointer-events: none;
					}
					.Organize-container {
						display: inline-block;
						width: 30%;
						margin: 0 1.5%;
						height: 5.5em;
					}
				}
			}
			&.final {
				.Organize-containers {
					width: 18.33em;
					height: 15.5em;
					margin: 0 auto;
					background-image: url('../../../../assets/images/modules/tasks/organize/bakery-1-donuts/shelves.svg');
					background-size: 18.33em auto;
					background-position: top center;
					padding: 0.5em 0em 0.7em 0.2em;
					.Organize-container {
						width: 5.4em;
						height: 3.5em;
						background-size: 2.5em auto;
						background-position: bottom left calc(50%);
						&:nth-of-type(2),
						&:nth-of-type(5),
						&:nth-of-type(8) {
							margin: 0 0.9em;
						}
					}
				}
			}
		}
		
		&.landscape {
			.Organize-mainContainer { 
				&.initial {
					
					margin: 2em auto 0 auto;
					width: 45em;
					max-width: 100%;
					padding: 0 4em;
					background-image: linear-gradient(to right, rgba(white, 0) 0%, rgba(white, 1) 10%, rgba(white, 1) 90%, rgba(white, 0) 100%);
					.Organize-containers {
						background-color: transparent;
						height: 5.6em;
					}
				}
			}
		}
	}

	&.bakery-1-cupcakes {
		.Organize-mainContainer {
			&.final {
				.Organize-containers {
					margin: auto;
					width: 17.39em;
					height: 20.36em;
					background-image: url('../../../../assets/images/modules/tasks/organize/bakery-1-cupcakes/shelves.svg');
					background-size: 100% auto;
					background-position: bottom center;
					padding-bottom: 0.27em;
					@include flex('space-between');
					.Organize-container {
						width: 100%;
						height: 5em;
					}
				}
			}
			&.initial {
				margin-top: 1.5em;
				.Organize-containers {
					display: block;
					width: 100%;
					height: 5em;
					background-color: white;
					padding: 0.25em 0.5em;
					overflow: auto;
					white-space: nowrap;
					.Organize-container {
						display: inline-block;
						vertical-align: middle;
						width: 4.25em;
						height: 4.25em;
						margin: 0em 0.1em;
					}
				}
			}
		}

		&.landscape {
			.Organize-mainContainer { 
				&.initial {
					margin: 2em auto 0 auto;
					width: 45em;
					max-width: 100%;
					padding: 0 4em;
					background-image: linear-gradient(to right, rgba(white, 0) 0%, rgba(white, 1) 10%, rgba(white, 1) 90%, rgba(white, 0) 100%);
					.Organize-containers {
						@include flex('center', 'center');
						background-color: transparent;
					}
				}
			}
		}
	}

	&.center-store-2-shelf-tags {
		.Organize-mainContainer {
			&.final {
				.Organize-containers {
					position: relative;
					margin: auto;
					width: 18em;
					height: 14.3em;
					background-image: url('../../../../assets/images/modules/tasks/organize/center-store-2-shelf-tags/background.svg');
					background-size: 100% auto;
					background-position: bottom center;
					padding-top: 6.2em;
					.Organize-container {
						position: inline-block;
						width: 6em;
						height: 1em;
						padding-left: 0.5em;
						&:nth-of-type(1), &:nth-of-type(2), &:nth-of-type(3) {
							margin-bottom: 6.11em;
						}
					}
				}
			}
			&.initial {
				margin-top: 1.5em;
				.Organize-containers {
					display: block;
					width: 100%;
					height: 5em;
					background-color: white;
					padding: 0.25em 0.5em;
					@include flex('center', 'center');
					flex: wrap;
					.Organize-container {
						width: 6em;
						height: 1em;
						margin: 0em 0.1em;
					}
				}
			}
		}

		&.landscape {
			.Organize-mainContainer { 
				&.initial {
					margin: 2em auto 0 auto;
					max-width: 100%;
					background-image: linear-gradient(to right, rgba(white, 0) 0%, rgba(white, 1) 10%, rgba(white, 1) 90%, rgba(white, 0) 100%);
					.Organize-containers {
						@include flex('center', 'center');
						background-color: transparent;
					}
				}
			}
		}
	}

	&.deli-1-salad-bar {
		.Organize-mainContainer { 
			&.initial {
				.Organize-containers {
					width: 19.5em;
					height: 12em;
					overflow-x: hidden;
					margin: -0.25em auto 0em auto;
					.Organize-container { 
						display: inline-block;
						width: 4.5em;
						height: 5.5em;
						margin-right: 0.35em;
					}
				}
			}
			&.final {
				.Organize-containers { 
					width: 19.26em;
					height: 13em;
					margin: 0 auto auto auto;
					background-image: url('../../../../assets/images/modules/tasks/organize/deli-1-salad-bar/containers-bg.svg');
					background-size: contain;
					background-position: left top;
					padding: 1em;
					padding-top: 0.8em;
					padding-right: 0em;
					padding-bottom: 0.5em;
					.Organize-container {
						width: 3.9em;
						height: 4.9em;
						margin-right: 0.6em;
						&:nth-of-type(4),
						&:nth-of-type(8) {
							margin-right: 0;
						}
						&:nth-of-type(5),
						&:nth-of-type(6),
						&:nth-of-type(7),
						&:nth-of-type(8) {
							margin-top: -0.5em;
						}
					}
				}
			}
		}
	}


	&.deli-2-shelves {
		.Organize-mainContainer { 
			&.shelves {
				.Organize-containers {
					margin: 0 auto;
					width: 18em;
					height: 21.87em;
					background-image: url('../../../../assets/images/modules/tasks/organize/deli-2-shelves/background.svg');
					padding: 0.3em 0.3em 0 0.3em;
					.Organize-container {
						width: 2.9em;
						height: 5.75em;
					}
				}
			}
			
			&.remove {
				width: 9.89em;
				margin-left: calc(50% - 9.89em);
				margin-top: 0.5em;
				text-align: right;
				overflow: visible;
				.Organize-containers {
					position: relative;
					display: inline-block;
					text-align: center;
					width: 100%;
					height: 6em;
					&::after {
						content: '';
						position: absolute;
						width: 100%;
						height: 100%;
						top: 0;
						right: 0;
						background-image: url('../../../../assets/images/modules/tasks/organize/deli-2-shelves/remove.svg');
						background-position: center bottom;
						pointer-events: none;
					}
					.Organize-container {
						display: inline-block;
						width: 31%;
						height: 5.75em;
					}
				}
			}
		}
	}

	&.deli-2-sandwiches {
		.Organize-mainContainer { 
			&.shelves {
				.Organize-containers {
					margin: 0 auto;
					width: 19em;
					height: 9.8em;
					padding-top: 1.2em;
					background-image: url('../../../../assets/images/modules/tasks/organize/deli-2-sandwiches/background.svg');
					.Organize-container {
						width: 6.9em;
						height: 3em;
						margin-bottom: 0.8em;
						margin-left: -0.3em;
						margin-right: -0.3em;
						&:nth-last-child(1),
						&:nth-last-child(2),
						&:nth-last-child(3) {margin-bottom: 0;} 
					}
				}
			}
			&.remove,
			&.reduce {
				display: inline-block;
				vertical-align: top;
				width: 8.5em;
				margin-top: 0.5em;
				border-radius: 1em;
				background-color: #10779E;
				border: 0.17em solid #39769A;
				overflow: hidden;
				box-sizing: content-box;

				&:nth-child(2) {
					margin-left: calc(50% - 9em);
					margin-right: 0.5em;
				}

				.Organize-mainContainerTitle {
					width: 100%;
					height: 1.25em;
					color: white;
					@include flex('center', 'center');
					span {
						font-size: 0.85em;
						font-weight: bold;
					}
				}
				.Organize-containers {
					width: 100%;
					padding: 0.5em 1.1em;
					background-color: #D9D9D9;
					border-bottom-left-radius: 1em;
					.Organize-container {
						width: 6.9em;
						height: 3em;
						margin-bottom: 1em;
						margin-left: -0.3em;
						margin-right: -0.3em;
						&:nth-last-child(1) {margin-bottom: 0;} 
					}
				}
			}
		}	
	}

	&.orientation-1-restock-bread {
		padding-bottom: 0;
		.Organize-mainContainer { 
			&.shelves {
				.Organize-containers {
					margin: 0 auto;
					width: 18em;
					height: 14.4em;
					padding-bottom: 0.4em;
					background-image: url('../../../../assets/images/modules/tasks/organize/orientation-1-restock-bread/background-shelves.svg');
					.Organize-container {
						width: 3.6em;
						height: 6.4em;
						margin-bottom: 0.6em;
					}
				}
			}
			&.remove {
				width: 20em;
				margin-left: calc(50% - 11em);
				text-align: right;
				overflow: visible;
				.Organize-containers {
					position: relative;
					display: inline-block;
					width: 10.55em;
					height: 6.4em;
					&::after {
						content: '';
						position: absolute;
						width: 100%;
						height: 100%;
						top: 0;
						right: 0;
						background-image: url('../../../../assets/images/modules/tasks/organize/orientation-1-restock-bread/background-remove.svg');
						background-position: center bottom;
						pointer-events: none;
					}
					.Organize-container {
						display: inline-block;
						vertical-align: top;
						width: 30%;
						margin: 0 1.5%;
						height: 6.4em;
					}
				}
			}
			&.initial {
				.Organize-containers {
					display: block;
					width: 100%;
					height: 6.8em;
					background-color: white;
					margin-top: 1em;
					padding: 0.1em 0.5em;
					overflow: auto;
					white-space: nowrap;
					.Organize-container {
						display: inline-block;
						vertical-align: middle;
						width: 3.6em;
						height: 6.4em;
						margin-right: 0.75em;
					}
				}
			}
		}	

		&.landscape {
			.Organize-mainContainer { 
				&.initial {
					margin: 0 auto;
					width: 45em;
					max-width: 100%;
					padding: 0 4em;
					background-image: linear-gradient(to right, rgba(white, 0) 0%, rgba(white, 1) 10%, rgba(white, 1) 90%, rgba(white, 0) 100%);
					.Organize-containers {
						background-color: transparent;
					}
				}
			}
		}
	}

	&.floral-2-bouquets {
		.Organize-mainContainer {
			&.initial {
				.Organize-containers {
					display: block;
					width: 100%;
					height: 7em;
					background-color: white;
					margin-top: 1em;
					padding: 0.1em 0.5em;
					overflow: auto;
					white-space: nowrap;
					.Organize-container {
						display: inline-block;
						vertical-align: top;
						width: 4em;
						height: 6em;
						margin-right: 0em;
					}
				}
			}
			&.final {
				.Organize-containers {
					width: 19.26em;
					height: 20em;
					margin: 0 auto -1em auto;
					background-image: url('../../../../assets/images/modules/tasks/organize/floral-2-bouquets/shelves.svg');
					background-size: 16em auto;
					background-position: top calc(50%) right calc(50%);
					padding-top: 1.4em;
					padding-left: 2.1em;
					padding-bottom: 0.8em;
					.Organize-container {
						background-image: url('../../../../assets/images/modules/tasks/organize/floral-2-bouquets/pot.svg');
						background-size: 2.25em auto;
						background-position: bottom center;
						width: 5em;
						height: 5.9em;
						margin-right: 0em;
						margin-bottom: 0em;
					}
				}
			}
		}
		&.landscape {
			.Organize-mainContainer {
				&.initial {
					margin: 0 auto;
					width: 45em;
					max-width: 100%;
					padding: 0 4em;
					background-image: linear-gradient(to right, rgba(white, 0) 0%, rgba(white, 1) 10%, rgba(white, 1) 90%, rgba(white, 0) 100%);
					.Organize-containers {
						background-color: transparent;
					}
				}
			}
		}
	}

	&.meat-1-match-with-animal {
		.Organize-mainContainer {
			overflow-x: hidden;
			&.initial {
				.Organize-containers {
					display: block;
					width: 100%;
					// margin-top: 0.5em;
					height: 4.35em;
					background-color: white;
					padding: 0.1em 0.5em;
					overflow: auto;
					white-space: nowrap;

					.Organize-container {
						display: inline-block;
						vertical-align: top;
						width: 5em;
						height: 4em;
						margin-right: 0em;
					}
				}
			}
			&.final {
				.Organize-containers { 
					width: 18em;
					height: 17em;
					margin: 0 auto auto auto;
					background-image: url('../../../../assets/images/modules/tasks/organize/meat-1-match-with-animal/categories.svg');
					background-size: contain;
					background-position: left top;
					padding: 1em;
					padding-top: 0.2em;
					padding-right: 4.5em;
					margin-bottom: 2em;
					.Organize-container {
						width: 3.7em;
						height: 3.6em;
						margin-right: 0.2em;
						margin-bottom: 0.7em;
						&:nth-of-type(3),
						&:nth-of-type(6),
						&:nth-of-type(9),
						&:nth-of-type(12) {
							margin-right: 0em;
						}
						&:nth-of-type(10),
						&:nth-of-type(11),
						&:nth-of-type(12) {
							margin-bottom: 0;
						}
					}
				}
			}
		}
		&.landscape {
			.Organize-mainContainer {
				&.initial {
					margin: 0 auto;
					width: 45em;
					max-width: 100%;
					padding: 0 4em;
					background-image: linear-gradient(to right, rgba(white, 0) 0%, rgba(white, 1) 10%, rgba(white, 1) 90%, rgba(white, 0) 100%);
					.Organize-containers {
						background-color: transparent;
					}
				}
			}
		}
	}

	&.meat-2-meat-case {
		padding-bottom: 0;
		.Organize-mainContainer { 
			&.final {
				.Organize-containers {
					margin: 0 auto;
					width: 18.2em;
					height: 21em;
					padding: 1.4em 0.3em;
					background-image: url('../../../../assets/images/modules/tasks/organize/meat-2-meat-case/background.svg');
					@include flex('flex-start', 'center', 'column');
					.Organize-container {
						width: 5em;
						height: calc(10.5em * 0.33);
						&:nth-of-type(3),
						&:nth-of-type(6) {
							margin-bottom: 7em;
						}
					}
				}
			}
			&.initial {
				.Organize-containers {
					display: block;
					width: 100%;
					height: 4.9em;
					background-color: white;
					margin-top: 1em;
					padding: 0.1em 0.5em;
					overflow: auto;
					white-space: nowrap;
					.Organize-container {
						display: inline-block;
						vertical-align: middle;
						width: 5.22em;
						height: 4.43em;
						margin-right: 0.75em;
					}
				}
			}
		}	

		&.landscape {
			.Organize-mainContainer { 
				&.initial {
					margin: 0 auto;
					width: 45em;
					max-width: 100%;
					padding: 0 4em;
					background-image: linear-gradient(to right, rgba(white, 0) 0%, rgba(white, 1) 10%, rgba(white, 1) 90%, rgba(white, 0) 100%);
					.Organize-containers {
						background-color: transparent;
					}
				}
			}
		}
	}

	&.produce-1-cool-racks {
		.Organize-mainContainer {
			&.initial {
				.Organize-containers {
					display: block;
					width: 100%;
					height: 4.5em;
					background-color: white;
					margin-top: 1em;
					padding: 0.1em 0.5em;
					overflow: auto;
					white-space: nowrap;
					.Organize-container {
						display: inline-block;
						vertical-align: top;
						width: 5em;
						height: 4em;
						margin-right: 0em;
					}
				}
			}
			&.final {
				.Organize-containers {
					width: 19.26em;
					height: 20em;
					margin: 0 auto -1em auto;
					background-image: url('../../../../assets/images/modules/tasks/organize/produce-1-produce-aisle/background-cooled.svg');
					background-size: 17em auto;
					background-position: top right calc(50%);
					padding-top: 1.15em;
					padding-left: 1.8em;
					padding-bottom: 1.1em;
					.Organize-container {
						background-size: 2.5em auto;
						background-position: bottom left calc(50%);
						width: 7.72em;
						height: 5.25em;
						margin-right: 0.1em;
						margin-bottom: 0em;
					}
				}
			}
		}

		&.landscape {
			.Organize-mainContainer { 
				&.initial {
					margin: 0 auto;
					width: 45em;
					max-width: 100%;
					padding: 0 4em;
					background-image: linear-gradient(to right, rgba(white, 0) 0%, rgba(white, 1) 10%, rgba(white, 1) 90%, rgba(white, 0) 100%);
					.Organize-containers {
						background-color: transparent;
					}
				}
			}
		}
	}

	&.produce-1-dry-table {
		.Organize-mainContainer {
			&.initial {
				.Organize-containers {
					display: block;
					width: 100%;
					height: 5em;
					background-color: white;
					margin-top: 1em;
					padding: 0.1em 0.5em;
					overflow: auto;
					white-space: nowrap;
					.Organize-container {
						display: inline-block;
						vertical-align: top;
						width: 5em;
						height: 4em;
						margin-right: 0em;
					}
				}
			}
			&.final {
				.Organize-containers {
					width: 19.26em;
					height: 20em;
					margin: 0 auto -1em auto;
					background-image: url('../../../../assets/images/modules/tasks/organize/produce-1-produce-aisle/background-dry.svg');
					background-size: 17em auto;
					background-position: top 0.5em right calc(50%);
					padding-top: 0em;
					padding-left: 1.45em;
					padding-bottom: 1.78em;
					.Organize-container {
						background-size: 2.5em auto;
						background-position: bottom left calc(50%);
						width: 8em;
						height: 3.8em;
						margin-right: 0.355em;
						margin-bottom: 0em;
					}
				}
			}
		}

		&.landscape {
			.Organize-mainContainer { 
				&.initial {
					margin: 0 auto;
					width: 45em;
					max-width: 100%;
					padding: 0 4em;
					background-image: linear-gradient(to right, rgba(white, 0) 0%, rgba(white, 1) 10%, rgba(white, 1) 90%, rgba(white, 0) 100%);
					.Organize-containers {
						background-color: transparent;
					}
				}
			}
		}
	}

	&.produce-2-cut-fruit {
		.Organize-mainContainer {
			&.initial {
				.Organize-containers {
					display: block;
					width: 100%;
					height: 5em;
					background-color: white;
					margin-top: 1em;
					padding: 0.8em 0.5em;
					overflow: auto;
					white-space: nowrap;
					
					.Organize-container {
						display: inline-block;
						vertical-align: top;
						width: 5.8em;
						height: 3.4em;
						margin-right: 0.5em;
					}
				}
			}
			&.final {
				.Organize-containers {
					width: 19.2em;
					height: 20em;
					margin: 1em auto;
					background-image: url('../../../../assets/images/modules/tasks/organize/produce-2-cut-fruit/background.svg');
					background-size: 100% auto;
					background-position: top center;
					padding-top: 2.4em;
					
					.Organize-container {
						position: absolute;
						width: 6.3em;
						height: 3.4em;
						&:nth-of-type(1) {top: 0.2em; left: 6.45em;}
						&:nth-of-type(2) {top: 0.2em; left: 12.6em;}
						&:nth-of-type(3) {top: 7em; left: 0.3em;}
						&:nth-of-type(4) {top: 7em; left: 6.45em;}
						&:nth-of-type(5) {top: 7em; left: 12.6em;}
						&:nth-of-type(6) {top: 13.8em; left: 6.45em;}
						&:nth-of-type(7) {top: 13.8em; left: 12.6em;}
					}
				}
			}
		}

		&.landscape {
			.Organize-mainContainer { 
				&.initial {
					margin: 0 auto;
					width: 33em;
					max-width: 100%;
					padding: 0 4em;
					background-image: linear-gradient(to right, rgba(white, 0) 0%, rgba(white, 1) 10%, rgba(white, 1) 90%, rgba(white, 0) 100%);
					.Organize-containers {
						background-color: transparent;
					}
				}
			}
		}
	}

	&.safety-1-gloves {
		.Organize-mainContainer {
			&.final {
				.Organize-containers {
					margin: auto;
					width: 20.8em;
					height: 17.2em;
					background-image: url('../../../../assets/images/modules/tasks/organize/safety-1-gloves/background.svg');
					background-size: 100% auto;
					background-position: top center;
					@include flex('space-between');
					.Organize-container {
						width: 10.4em;
						height: 5.28em;
						padding-left: 1em;
						margin-bottom: 0.83em;
						&:nth-of-type(3),
						&:nth-of-type(4) {
							margin-bottom: calc(17.2em - (2 * 5.28em + 0.83em));
						}
					}
				}
			}
			&.remove {
				.Organize-containers {
					width: 10.5em;
					height: 5em;
					margin-top: -3em;
					left: calc(50% - 1em);
					padding: 0 0.25em;
					// background-image: url('../../../../assets/images/modules/tasks/organize/safety-1-gloves/remove.svg');
					// background-position: bottom center;
					// @include flex('space-between');
					&::after {
						content: '';
						position: absolute;
						width: 100%;
						height: 100%;
						background-image: url('../../../../assets/images/modules/tasks/organize/safety-1-gloves/remove.svg');
						background-position: center bottom;
						pointer-events: none;
					}
					.Organize-container {
						width: calc(0.33 * 10em);
						height: 5em;
						margin-bottom: 0em;
					}
				}
			}
			&.initial {
				margin-top: 1.5em;
				.Organize-containers {
					display: block;
					width: 100%;
					height: 5.5em;
					background-color: white;
					padding: 0.25em 0.5em;
					overflow: auto;
					white-space: nowrap;
					.Organize-container {
						display: inline-block;
						vertical-align: middle;
						width: 4.75em;
						height: 4.75em;
						margin: 0em 0.1em;
					}
				}
			}
		}

		&.landscape {
			.Organize-mainContainer { 
				&.initial {
					margin: 2em auto 0 auto;
					width: 45em;
					max-width: 100%;
					padding: 0 4em;
					background-image: linear-gradient(to right, rgba(white, 0) 0%, rgba(white, 1) 10%, rgba(white, 1) 90%, rgba(white, 0) 100%);
					.Organize-containers {
						@include flex('center', 'center');
						background-color: transparent;
					}
				}
			}
		}
	}

	&.seafood-1-bad-shellfish {
		padding-bottom: 0;
		.Organize-mainContainer { 
			&.bucket {
				.Organize-containers {
					margin: 0 auto;
					padding-top: 2em;
					padding-bottom: 4em;
					width: 20em;
					height: 20.2em;
					background-image: url('../../../../assets/images/modules/tasks/organize/seafood-1-bad-shellfish/background.svg');
					.Organize-container {
						width: 7.7em;
						height: 4.7em;
						&:nth-of-type(1) {
							margin-left: calc(100% - (20em / 2 + 7.7em / 2)); 
						}
						&:nth-of-type(2),
						&:nth-of-type(4) {
							margin-left: 2.5em;
						}
					}
				}
			}
			&.initial {
				.Organize-containers {
					display: block;
					width: 100%;
					height: 5.7em;
					background-color: white;
					margin-top: 1em;
					padding: 0.1em 0.5em;
					overflow: auto;
					white-space: nowrap;
					.Organize-container {
						display: inline-block;
						vertical-align: middle;
						width: 7.7em;
						height: 4.7em;
						margin-right: 0.75em;
					}
				}
			}
		}	

		&.landscape {
			.Organize-mainContainer { 
				&.initial {
					margin: 0 auto;
					width: 45em;
					max-width: 100%;
					padding: 0 4em;
					background-image: linear-gradient(to right, rgba(white, 0) 0%, rgba(white, 1) 10%, rgba(white, 1) 90%, rgba(white, 0) 100%);
					.Organize-containers {
						background-color: transparent;
					}
				}
			}
		}
	}

	&.seafood-1-fish-labels {
		.Organize-mainContainer {
			&.initial {
				.Organize-containers {
					display: block;
					width: 100%;
					height: 6.17em;
					background-color: #E5E5E5;
					margin-top: 1em;
					padding: 0.1em 0.5em;
					overflow: auto;
					white-space: nowrap;
					@include flex('center', 'center');
					.Organize-container {
						display: inline-block;
						vertical-align: top;
						width: 6em;
						height: 3.75em;
						margin: 0em 0.25em;
					}
				}
			}
			&.final {
				.Organize-containers {
					width: 18em;
					height: 9.5em;
					margin: 3em auto 3em auto;
					background-image: url('../../../../assets/images/modules/tasks/organize/seafood-1-fish-labels/background.svg');
					background-size: 100% auto;
					background-position: top center;
					padding-top: 0em;
					.Organize-container {
						width: 6em;
						height: 4.75em;
					}
				}
			}
		}

		&.landscape {
			.Organize-mainContainer { 
				&.initial {
					margin: 0 auto;
					width: 45em;
					max-width: 100%;
					padding: 0 4em;
					background-image: linear-gradient(to right, rgba(white, 0) 0%, rgba(white, 1) 10%, rgba(white, 1) 90%, rgba(white, 0) 100%);
					.Organize-containers {
						background-color: transparent;
					}
				}
			}
		}
	}

	&.seafood-1-seafood-pallet {
		.Organize-mainContainer {
			overflow-x: hidden;
			&.initial {
				.Organize-containers {
					display: block;
					width: 100%;
					height: 4.5em;
					background-color: white;
					margin-top: 1em;
					padding: 0.1em 0.5em;
					overflow: auto;
					white-space: nowrap;

					.Organize-container {
						display: inline-block;
						vertical-align: top;
						width: 5em;
						height: 4em;
						margin-right: 0em;
					}
				}
			}
			&.final1 {
				z-index: 2;
				.Organize-containers {
					width: 14em;
					height: 20em;
					float: right;
					margin-right: 1em;
					background-image: url('../../../../assets/images/modules/tasks/organize/seafood-1-seafood-pallet/background1.svg');
					background-size: 14em auto;
					background-position: top 0.5em right calc(50%);
					padding-top: 1.3em;
					padding-left: 0.25em;
					padding-bottom: 4.6em;

					.Organize-container {
						width: 13.45em;
						height: 3.2em;
					}
				}
			}
			&.final2 {
				z-index: 2;
				.Organize-containers {
					width: 12.5em;
					height: 20em;
					background-image: url('../../../../assets/images/modules/tasks/organize/seafood-1-seafood-pallet/background2.svg');
					background-size: 12.5em auto;
					background-position: top 0.65em right calc(50%);
					padding-top: 2.8em;
					padding-left: 0.55em;
					padding-bottom: 4.75em;
					z-index: 2;

					.Organize-container {
						width: 11.45em;
						height: 3.8em;
					}
				}
			}
			&.foreground {
				position: absolute;
				z-index: 4;
				left: calc(50% + 0.25em);
				bottom: calc(50% - 2.9em);
				width: 12em;
				height: 13em;
				background-image: url('../../../../assets/images/modules/tasks/organize/seafood-1-seafood-pallet/foreground.svg');
				background-size: 11.4em auto;
				pointer-events: none;
			}
		}

		&.landscape {
			.Organize-mainContainer { 
				&.initial {
					margin: 0 auto;
					width: 45em;
					max-width: 100%;
					padding: 0 4em;
					background-image: linear-gradient(to right, rgba(white, 0) 0%, rgba(white, 1) 10%, rgba(white, 1) 90%, rgba(white, 0) 100%);
					.Organize-containers {
						background-color: transparent;
					}
				}
			}
		}
	}
}