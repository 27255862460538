

.MultipleChoice {
	&.orientation-1-information-board {
		.MultipleChoice-options {
			margin-top: 1em;
			padding-bottom: 1em;
			.MultipleChoice-optionsWrap {
				background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/orientation-1-information-board/background.svg');
				background-position: top center;
				background-size: 18em auto;
				height: 18em;				
				.MultipleChoice-option { 
					position: absolute; 
					&.option-1 {
						width: 4.6em;
						height: 6.5em;
						@include rotate(2.5deg);
						top: 1em; 
						left: calc(50% + 1.5em);
					}
					&.option-2 {
						width: 4.6em;
						height: 6em;
						top: 1.55em; 
						left: calc(50% - 6.1em);
					}
					&.option-3 {
						width: 4.6em;
						@include rotate(-2.5deg);
						height: 6em;
						top: 8em; 
						left: calc(50% - 4.2em);
					}
					&.selected,
					&.correct,
					&.animateCorrect,
					&.wrong,
					&.animateWrong {@include noAnimation();}
				}
			}
		}
	}

	&.orientation-1-leaking-pallet-tools {
		.MultipleChoice-options {
			margin-top: 1em;
			padding-bottom: 1em;
			.MultipleChoice-optionsWrap {
				height: 24.4em;				
				.MultipleChoice-option { 
					display: inline-block;
					width: 8em;
					height: 6em;
					span:nth-child(2) {
						bottom: 0.5em;
						right: 0.75em;
						left: auto;
						width: 1.39em;
						height: 1.39em;
					}
					&.position-1,
					&.position-3,
					&.position-5 {margin-left: 2em; margin-right: 2em;}
					&.option-1 {
						width: 6.2em;
						height: 7.1em;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/orientation-1-leaking-pallet-tools/option-1.svg');
					}
					&.option-2 {
						width: 4.1em;
						height: 14em;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/orientation-1-leaking-pallet-tools/option-2.svg');
					}
					&.option-3 {
						width: 5.75em;
						height: 6.21em;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/orientation-1-leaking-pallet-tools/option-3.svg');
					}
					&.option-4 {
						width: 2.44em;
						height: 5.7em;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/orientation-1-leaking-pallet-tools/option-4.svg');
					}
					&.option-5 {
						width: 6em;
						height: 2.5em;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/orientation-1-leaking-pallet-tools/option-5.svg');
					}
					&.selected,
					&.correct,
					&.animateCorrect,
					&.wrong,
					&.animateWrong {@include noAnimation();}
				}
			}
		}
	}

	&.orientation-1-apron {
		.MultipleChoice-options {
			margin-top: 0em;
			padding-bottom: 1em;
			.MultipleChoice-optionsWrap {
				height: 24.4em;				
				.MultipleChoice-option { 
					display: inline-block;
					span:nth-child(2) {
						bottom: 0.5em;
						right: 0.75em;
						left: auto;
						width: 1.39em;
						height: 1.39em;
					}
					&.option-1 {
						width: 11em;
						height: 18em;
						left: calc(50% - 11em / 2);
						top: calc(50% - 21em / 2);
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/orientation-1-apron/option-1.svg');
					}
					&.selected,
					&.correct,
					&.animateCorrect,
					&.wrong,
					&.animateWrong {@include noAnimation();}
				}
			}
		}
	}
}