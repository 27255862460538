.MultipleChoice {
	&.produce.landscape {@include produce-grey-landscape-background();}

	&.shrink-1-buckets {
		.MultipleChoice-options {
			margin-top: 1em;
			padding-bottom: 1em;
			.MultipleChoice-optionsWrap {
				height: 24.4em;			
				padding: 0 1.5em;	
				.MultipleChoice-option { 
					display: inline-block;
					width: 5.5em;
					height: 5em;
					span:nth-child(2) {
						bottom: 0.5em;
						right: 0.75em;
						left: auto;
						width: 1.39em;
						height: 1.39em;
					}
					&.position-3 {margin-left: calc(100% - 11em)}
					&.position-1,
					&.position-4 {margin-right: calc(100% - 17em); margin-left: calc(100% - 16em); margin-right: 4em}
					&.position-1,
					&.position-2,
					&.position-3 {margin-bottom: 2em;}
					&.option-1 {
						background-size: 4.8em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/shrink-1-buckets/option-1.svg');
					}
					&.option-2 {
						background-size: 4.4em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/shrink-1-buckets/option-2.svg');
					}
					&.option-3 {
						background-size: 4.4em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/shrink-1-buckets/option-3.svg');
					}
					&.option-4 {
						background-size: 5.5em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/shrink-1-buckets/option-4.svg');
					}
					&.option-5 {
						background-size: 4.6em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/shrink-1-buckets/option-5.svg');
					}
				}
			}
		}
	}

	&.shrink-1-bags {
		.MultipleChoice-options {
			margin-top: 1em;
			padding-bottom: 1em;
			.MultipleChoice-optionsWrap {
				height: 24.4em;			
				padding: 0 1.5em;	
				.MultipleChoice-option { 
					display: inline-block;
					width: 6.89em;
					height: 8.8em;
					span:nth-child(2) {
						bottom: 0.5em;
						right: 0.75em;
						left: auto;
						width: 1.39em;
						height: 1.39em;
					}
					
					&.position-1,
					&.position-3 {margin-right: calc(100% - 14em)}

					&.position-1,
					&.position-2 {margin-bottom: 2em;}
					&.option-1 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/shrink-1-bags/option-1.svg');
					}
					&.option-2 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/shrink-1-bags/option-2.svg');
					}
					&.option-3 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/shrink-1-bags/option-3.svg');
					}
					&.option-4 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/shrink-1-bags/option-4.svg');
					}
				}
			}
		}
	}
}