.MultipleChoice {
	&.safety-1-pick-up-box {
		.MultipleChoice-options {
			margin-top: 1em;
			padding-bottom: 1em;
			.MultipleChoice-optionsWrap {
				height: 24.4em;	
				padding: 0 1.5em;			
				.MultipleChoice-option { 
					position: absolute;
					background-size: contain;
					background-position: center center;

					span:nth-child(2) {
						bottom: 0.5em;
						right: 0.75em;
						left: auto;
						width: 1.39em;
						height: 1.39em;
					}
					&.position-1 {top: 0; left: 1em;}
					&.position-2 {top: 0; right: 1em;}
					&.position-3 {top: 15em; left: 1em;}
					&.position-4 {top: 15em; right: 1em;}

					&.option-1 {
						width: 7.25em;
						height: 10.7em;
						background-size: contain;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/safety-1-pick-up-box/option-1.svg');
					}
					&.option-2 {
						width: 7.7em;
						height: 13.8em;
						background-size: contain;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/safety-1-pick-up-box/option-2.svg');
					}
					&.option-3 {
						width: 6.3em;
						height: 7.3em;
						background-size: contain;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/safety-1-pick-up-box/option-3.svg');
					}
					&.option-4 {
						width: 7.25em;
						height: 9em;
						background-size: contain;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/safety-1-pick-up-box/option-4.svg');
					}
				}
			}
		}
	}

	&.safety-1-move-box {
		.MultipleChoice-options {
			margin-top: 1em;
			padding-bottom: 1em;
			.MultipleChoice-optionsWrap {
				height: 24.4em;	
				padding: 0 1.5em;			
				.MultipleChoice-option { 
					position: absolute;
					background-size: contain;
					background-position: center center;

					span:nth-child(2) {
						bottom: 0.5em;
						right: 0.75em;
						left: auto;
						width: 1.39em;
						height: 1.39em;
					}
					&.position-1 {top: 0; left: 2em;}
					&.position-2 {top: 0; right: 2em;}
					&.position-3 {top: 14em; left: 2em;}
					&.position-4 {top: 14em; right: 2em;}

					&.option-1 {
						width: 4em;
						height: 10.7em;
						background-size: contain;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/safety-1-move-box/option-1.svg');
					}
					&.option-2 {
						width: 4.5em;
						height: 10.8em;
						background-size: contain;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/safety-1-move-box/option-2.svg');
					}
					&.option-3 {
						width: 3.8em;
						height: 13.2em;
						background-size: contain;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/safety-1-move-box/option-3.svg');
					}
					&.option-4 {
						width: 7.3em;
						height: 8.7em;
						background-size: contain;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/safety-1-move-box/option-4.svg');
					}
				}
			}
		}
	}

	&.safety-1-how-to-turn {
		.MultipleChoice-options {
			margin-top: 1em;
			padding-bottom: 1em;
			.MultipleChoice-optionsWrap {
				height: 24.4em;	
				padding: 0 1.5em;			
				.MultipleChoice-option { 
					position: absolute;
					background-size: contain;
					background-position: center center;

					span:nth-child(2) {
						bottom: 0.5em;
						right: 0.75em;
						left: auto;
						width: 1.39em;
						height: 1.39em;
					}
					&.position-1 {top: 0; left: 2em;}
					&.position-2 {top: 0; right: 2em;}

					&.option-1 {
						width: 7em;
						height: 16.6em;
						background-size: contain;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/safety-1-how-to-turn/option-1.svg');
					}
					&.option-2 {
						width: 7.7em;
						height: 16.8em;
						background-size: contain;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/safety-1-how-to-turn/option-2.svg');
					}
				}
			}
		}
	}
}