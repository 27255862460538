.MultipleChoice {	
	&.cooler-freezer-1-freezer-boxes {
		.MultipleChoice-options {
			margin-top: 2em;
			padding-bottom: 1em;
			.MultipleChoice-optionsWrap {
				width: 10em;
				margin: 0 auto;
				.MultipleChoice-option { 
					margin-bottom: 1em;
					width: 10em;
					height: 6em;
					span:nth-child(2) {
						bottom: 1em;
						left: calc(100% - 2.5em);
					}		
					&.option-1 {background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/cooler-freezer-1-freezer-boxes/option-1.svg');}
					// &.option-2 {background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/cooler-freezer-1-freezer-boxes/option-2.svg');}
					&.option-3 {background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/cooler-freezer-1-freezer-boxes/option-3.svg');}
					&.option-4 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/cooler-freezer-1-freezer-boxes/option-4.svg')}	
				}
			}
		}
	}
	&.cooler-freezer-1-lettuces-stocking {
		.MultipleChoice-options {
			margin-top: 2em;
			padding-bottom: 1em;
			.MultipleChoice-optionsWrap {
				width: 18.1em;
				margin: 0 auto;
				text-align: center;
				.MultipleChoice-option { 
					display: inline-block;
					vertical-align: top;
					width: 8.4em;
					height: 7.26em;
					&:nth-of-type(1), &:nth-of-type(2) {
						margin-bottom: 1em;
					}
					&:nth-of-type(1), &:nth-of-type(3) {
						margin-right: 1em;
					}
					span:nth-child(2) {
						bottom: 1em;
						left: calc(100% - 2.5em);
					}		
					&.option-1 {background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/cooler-freezer-1-lettuces-stocking/option-1.svg');}
					&.option-2 {background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/cooler-freezer-1-lettuces-stocking/option-2.svg');}
					&.option-3 {background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/cooler-freezer-1-lettuces-stocking/option-3.svg');}
					&.option-4 {background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/cooler-freezer-1-lettuces-stocking/option-4.svg');}

				}
			}
		}
	}
}