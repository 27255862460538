@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';
@import '../../../../styles/mixins_backgrounds';
@import '../../../../styles/animations';
@import '../../../../styles/animations-intro';
.FacilitatorAddCoFacilitatorPopup {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(#7ABCEA, 0.81);
	@include flex('center', 'center');

	.FacilitatorAddCoFacilitatorPopup-content {
		width: 42em;
		background-color: white;
		border-radius: 0.83em;

		.FacilitatorAddCoFacilitatorPopup-header {
			position: relative;
			width: 100%;
			height: 4.25em;
			background-color: #11466B;
			border-top-left-radius: 0.83em;
			border-top-right-radius: 0.83em;
			border-top: 0.25em solid white;
			border-right: 0.25em solid white;
			border-left: 0.25em solid white;
			padding-left: 2em;
			@include flex('flex-start', 'center');
			.FacilitatorAddCoFacilitatorPopup-title {
				color: #ffffff;
				font-size: 1.25em;
				font-weight: 600;
			}
			.FacilitatorAddCoFacilitatorPopup-closeBtn {
				position: absolute;
				top: 0;
				right: 0;
				width: 4.25em;
				height: 3.75em;
				background-image: url('../../../../assets/images/facilitator/icon-close.svg');
				background-size: 1.56em auto;
				cursor: pointer;
			}
		}

		.FacilitatorAddCoFacilitatorPopup-body {
			padding: 1.5em 10em;
			.FacilitatorAddCoFacilitatorPopup-input {
				width: 100%;
				input {
					width: 100%;
					padding: 1em;
					font-size: 0.8em;
					border: 1px solid #11466B;
					border-radius: 0.5em;
				}
			}
			.FacilitatorAddCoFacilitatorPopup-feedback {
				width: 100%;
				min-height: 2em;
				line-height: 0.8em;
				span {
					font-size: 0.8em;
					color: black;
				}
				&.error span {color: #C13B3B;}
			}
			.FacilitatorAddCoFacilitatorPopup-confirmBtn {
				margin-top: 1em;
				@include flex('center', 'center');
			}
		}
	}
}