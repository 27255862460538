@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/mixins_backgrounds';
@import '../../../styles/animations';
@import '../../../styles/animations-intro';
.Splash {
	position: relative;
	height: 100%;
	@include login-background();
	&.landscape {
		.Splash-logo {top: 3.83em;}
		.Splash-manager {left: calc(50% - 17em);}
		.Splash-startBtn {left: calc(50% - 4.22em);}
	}
	.Splash-loginInfo {
		position: absolute;
		top: 0.75em;
		left: 1em;
		right: 7em;
		overflow: hidden;
		span {
			color: #53A3CE;
			font-size: 0.85em;
			font-weight: 400;
		}
	}
	.Splash-logoutBtn,
	.Splash-resetBtn {
		position: absolute;
		top: 1em;
		right: 1em;
		width: 4.75em;
		z-index: 1;
		cursor: pointer;
	}
	.Splash-resetBtn {
		left: auto;
		top: 3.25em;
		right: 1em;
		width: 4em;
	}
	
	.Splash-logo {
		position: absolute;
		width: 100%;
		left: 0;
		top: 1.72em;
		z-index: 1;
	}

	.Splash-manager {
		position: absolute;
		left: calc(50% - 13em);
		bottom: 0;
		z-index: 2;
	}

	.Splash-startBtn {
		position: absolute;
		width: 8.88em;
		left: calc(50% - 1em);
		bottom: 2em;
		z-index: 3;
	}
}
