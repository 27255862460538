@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/mixins_backgrounds';
@import '../../../styles/animations';
@import '../../../styles/animations-intro';
.Area {
	position: relative;
	width: 100%;
	height: 100%;
	background-image: url('../../../assets/images/areas/island.svg');
	
	&.area {
		background-size: 9.35em auto;
		background-position: bottom 1.2em center;
		cursor: pointer;
		&.locked {cursor: not-allowed;}
	}
	&.main-island {
		.Area-character,
		.Area-items {
			display: none;
		}
		.Area-title {
			height: 4.44em;
			bottom: 0.3em;
			padding-top: 0.85em;
			span {
				font-size: 1.17em;
			}
		}
	}

	.Area-character {
		position: absolute;
		width: 3.4em;
		left: calc(50% - 1.6em);
		bottom: 2.5em;
	}
	.Area-items {
		position: absolute;
		width: 100%;
		height: 100%;
		pointer-events: none;
	}
	.Area-title {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 3em;
		background-image: url('../../../assets/images/areas/island-title.svg');
		text-align: center;
		padding-top: 0.4em;
		span {
			font-size: 0.78em;
			font-weight: 900;
			color: white;
			text-transform: uppercase;
		}
	}
	.Area-progress {
		position: absolute;
		bottom: 0;
		left: calc(50% - (0.5 * 5.38em));
		width: 5.38em;
		height: 0.69em;
		background-image: linear-gradient(#11466B, #082940);
		border-radius: 0.3em;
		overflow: hidden;
		.Area-progressShadow {
			position: absolute;
			top: 0.05em;
			left: calc(50% - (0.5 * 5.05em));
			width: 5.05em;
			height: 0.24em;
			border-radius: 0.12em;
			background-color: rgba(white, 0.2);
		}
		.Area-progressBar {
			position: relative;
			width: 0;
			height: 100%;
			border-top-left-radius: 0.3em;
			border-bottom-left-radius: 0.3em;
			background-color: #E8B341;
			background-image: linear-gradient(#E8B341, #AC7022);
			background-size: 100% 100%;
			@include transition(0.5s, width, ease-in-out);
		}
		.Area-progressLabel {
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			height: 100%;
			@include flex('center', 'center');
			span {
				font-size: 0.49em;
				font-weight: 700;
				color: white;
			}
		}
	}

	&.bagger {
		background-image: url('../../../assets/images/areas/island-bagger.svg');
		&.main-island {
			background-image: url('../../../assets/images/map/module-bagger.svg');
		}
	}
	&.bakery {
		background-image: url('../../../assets/images/areas/island-bakery.svg');
		&.main-island {
			background-image: url('../../../assets/images/map/module-bakery.svg');
		}
	}
	&.cashier {
		background-image: url('../../../assets/images/areas/island-cashier.svg');
		&.main-island {
			background-image: url('../../../assets/images/map/module-cashier.svg');
		}
	}
	&.center-store {
		background-image: url('../../../assets/images/areas/island-center-store.svg');
		.Area-character {
			width: 2.75em;
			left: calc(50% - 2em);
			bottom: 3.35em;
		}
		.Area-items {
			background-image: url('../../../assets/images/areas/island-center-store-item-1.svg');
			background-size: 4.68em auto;
			background-position: bottom 2em center;
		}
		&.main-island {
			background-image: url('../../../assets/images/map/module-center-store.svg');
		}
	}
	&.customer-service {
		background-image: url('../../../assets/images/areas/island-customer-service.svg');
		.Area-character {
			width: 3.75em;
			left: calc(50% - 2.8em);
			bottom: 2.5em;
		}
		&.area .Area-title {
			span {
				font-size: 0.67em;
			}
		}
		&.main-island {
			background-image: url('../../../assets/images/map/module-customer-service.svg');
			.Area-title {
				padding-top: 1em;
				span {
					font-size: 0.9em;
				}
			}
		}
	}
	&.deli {
		background-image: url('../../../assets/images/areas/island-deli.svg');
		&.main-island {
			background-image: url('../../../assets/images/map/module-deli.svg');
		}
	}
	&.orientation {
		background-image: url('../../../assets/images/areas/island-orientation.svg');
		&.main-island {
			background-image: url('../../../assets/images/map/module-orientation.svg');
		}
	}
	&.floral {
		background-image: url('../../../assets/images/areas/island-floral.svg');
		&.main-island {
			background-image: url('../../../assets/images/map/module-floral.svg');
		}
	}
	&.meat {
		background-image: url('../../../assets/images/areas/island-meat.svg');
		&.main-island {
			background-image: url('../../../assets/images/map/module-meat.svg');
		}
	}
	&.produce {
		background-image: url('../../../assets/images/areas/island-produce.svg');
		&.main-island {
			background-image: url('../../../assets/images/map/module-produce.svg');
		}
	}
	&.seafood {
		background-image: url('../../../assets/images/areas/island-seafood.svg');
		&.main-island {
			background-image: url('../../../assets/images/map/module-seafood.svg');
		}
	}	
}