.MultipleChoice {
	&.customer-service.landscape {@include customer-service-grey-landscape-background();}

	&.customer-service-1-cleaning {
		.MultipleChoice-options {
			padding: 1em 0;
			.MultipleChoice-optionsWrap {
				margin: auto;
				width: 18.25em;
				height: 24.25em;
				.MultipleChoice-option { 
					position: absolute;
					background-size: contain;
					background-position: center center;
					span:nth-child(2) {
						bottom: -0.5em;
						right: -0.5em;
						left: auto;
						width: 1.39em;
						height: 1.39em;
					}
					&.option-1 {
						left: 0;
						bottom: 0;
						width: 5.76em;
						height: 16.41em;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/customer-service-1-cleaning/option-1.svg');
					}
					&.option-2 {
						left: calc(50% - 2.72em);
						bottom: 7.28em;
						width: 5.44em;
						height: 5.57em;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/customer-service-1-cleaning/option-2.svg');
					}
					&.option-3 {
						bottom: 13.89em;
						left: calc(50% - 2.955em);
						width: 5.91em;
						height: 2.52em;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/customer-service-1-cleaning/option-3.svg');
					}
					&.option-4 {
						right: 1.2em;
						bottom: 0;
						width: 5.15em;
						height: 16.41em;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/customer-service-1-cleaning/option-4.svg');
					}
					&.option-5 {
						top: 0.67em;
						left: 0;
						width: 4.56em;
						height: 5.16em;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/customer-service-1-cleaning/option-5.svg');
					}
					&.option-6 {
						top: 0;
						left: calc(50% - 2.88em);
						width: 5.76em;
						height: 6.21em;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/customer-service-1-cleaning/option-6.svg');
					}
					&.option-7 {
						top: 0.67em;
						right: 0em;
						width: 5.78em;
						height: 4.52em;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/customer-service-1-cleaning/option-7.svg');
					}
					&.option-8 {
						bottom: 0;
						left: calc(50% - 1.22em);
						width: 2.44em;
						height: 5.68em;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/customer-service-1-cleaning/option-8.svg');
					}
				}
			}
		}	
	}

	&.customer-service-1-clipboards {
		.MultipleChoice-options {
			padding: 1em 0;
			.MultipleChoice-optionsWrap {
				margin: auto;
				width: 18.13em;
				height: 17.01em;
				background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/customer-service-1-clipboards/background.svg');
				background-size: 100% auto;
				background-position: top left;
				.MultipleChoice-option { 
					position: absolute;
					width: 5em;
					height: 6.75em;
					span:nth-child(2) {
						right: 0.5em;
						left: auto;
					}
					&.option-1 {top: 0.9em; left: 1.4em;}
					&.option-2 {top: 1.2em; left: 6.6em;}
					&.option-3 {top: 0.7em; right: 1.35em;}
					&.option-4 {top: 7.95em; left: 1.55em;}
					&.option-5 {top: 8.25em; left: 6.6em;}
					&.option-6 {top: 7.55em; right: 1.15em;}
				}
			}
		}
	}

	&.customer-service-1-customer-perspective {
		.MultipleChoice-options {
			padding: 1em 0;
			.MultipleChoice-optionsWrap {
				margin: auto;
				width: 18.13em;
				height: 17.01em;
				background-size: 100% auto;
				background-position: top left;
				.MultipleChoice-option { 
					position: absolute;
					width: 5em;
					height: 6.75em;
					span:nth-child(2) {
						right: 0.5em;
						left: auto;
					}
					&.option-1 {
						left: 0;
						top: 0;
						width: 10.14em;
						height: 7.11em;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/customer-service-1-customer-perspective/option-1.svg');
					}
					&.option-2 {
						left: calc(50% + 2.8em);
						top: 0em;
						width: 4.9em;
						height: 8.72em;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/customer-service-1-customer-perspective/option-2.svg');
					}
					&.option-3 {
						top: 11.5em;
						left: 0;
						width: 8em;
						height: 4.6em;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/customer-service-1-customer-perspective/option-3.svg');
					}
					&.option-4 {
						top: 13.7em;
						right: 0;
						width: 5.3em;
						height: 7.9em;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/customer-service-1-customer-perspective/option-4.svg');
					}
					&.option-5 {
						top: 18.8em;
						left: 3.9em;
						width: 5.8em;
						height: 3.42em;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/customer-service-1-customer-perspective/option-5.svg');
					}
				}
			}
		}
	}

	&.customer-service-1-greeting-customers {
		.MultipleChoice-options {
			padding: 1em 0;
			.MultipleChoice-optionsWrap {
				margin: auto;
				width: 16em;
				height: 24em;
				.MultipleChoice-option { 
					position: absolute;
					background-size: contain;
					background-position: center center;
					span:nth-child(2) {
						bottom: -0.5em;
						right: -0.5em;
						left: auto;
						width: 1.39em;
						height: 1.39em;
					}
					&.position-1 {top: 0; left: 0;}
					&.position-2 {top: 0; right: 0;}
					&.position-3 {top: calc(50% - 2.25em); left: 0;}
					&.position-4 {top: calc(50% - 2.25em); right: 0;}
					&.option-1 {
						width: 7.67em;
						height: 9.17em;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/customer-service-1-greeting-customers/option-1.svg');
					}
					&.option-2 {
						width: 7.67em;
						height: 9.17em;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/customer-service-1-greeting-customers/option-2.svg');
					}
					&.option-3 {
						width: 7.67em;
						height: 9.17em;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/customer-service-1-greeting-customers/option-3.svg');
					}
					&.option-4 {
						width: 7.67em;
						height: 9.17em;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/customer-service-1-greeting-customers/option-4.svg');
					}
				}
			}
		}
	}

	&.customer-service-1-head-out {
		.MultipleChoice-options {
			padding: 1em 0;
			.MultipleChoice-optionsWrap {
				margin: auto;
				width: 16em;
				height: 24em;
				.MultipleChoice-option { 
					position: absolute;
					background-size: contain;
					background-position: center center;
					span:nth-child(2) {
						bottom: -0.5em;
						right: -0.5em;
						left: auto;
						width: 1.39em;
						height: 1.39em;
					}
					&.option-1 {
						right: 0;
						top: 0;
						width: 6em;
						height: 12.25em;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/customer-service-1-head-out/option-1.svg');
					}
					&.option-2 {
						left: calc(50% - 2.8em);
						bottom: 0em;
						width: 5.6em;
						height: 8.9em;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/customer-service-1-head-out/option-2.svg');
					}
					&.option-3 {
						top: 4em;
						left: 0;
						width: 5.9em;
						height: 8.1em;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/customer-service-1-head-out/option-3.svg');
					}
				}
			}
		}
	}

	&.customer-service-1-pick-up-phone {
		// &.landscape {background-image: none;}
		background-image: linear-gradient(#797c82, #48484d);
		background-size: 100% 25em;
		background-position: top 25em center;
		&.landscape {
			background-image: linear-gradient(#797c82, #48484d);
			background-size: 100% 25em;
			background-position: top 25em center;
		}
		.MultipleChoice-options {
			width: 100%;
			left: 0;
			.MultipleChoice-optionsWrap {
				position: relative;
				width: 100%;
				height: 26.25em;
				margin: 0 auto;
				background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/customer-service-1-pick-up-phone/background.svg');
				background-size: auto 25.62em;
				background-position: bottom center;
				.MultipleChoice-option { 
					position: absolute;
					span:nth-child(2) {
						left: calc(50% - 0.75em);
						bottom: calc(50% - 0.75em);
						
					}
					&.option-1 {
						width: 9.5em;
						height: 3.75em;
						bottom: 13.75em; 
						left: calc(50% - 6em);
					}
					&.option-2 {
						width: 5em;
						height: 2.5em;
						bottom: 0;
						left: calc(50% + 3.25em);
					}
					&.option-3 {
						width: 5em;
						height: 6.5em;
						bottom: 17.5em;
						left: calc(50% + 6.75em);
					}
					&.option-4 {
						width: 8em;
						height: 9em;
						bottom: 2.25em;
						left: calc(50% - 11.5em);
					}
					&.option-5 {
						width: 6.75em;
						height: 7.75em;
						bottom: 17.75em;
						left: calc(50% - 7em);
					}
				}
			}
		}
	}

	&.customer-service-1-rain-items {
		.MultipleChoice-options {
			padding: 1em 0;
			.MultipleChoice-optionsWrap {
				margin: auto;
				width: 18em;
				height: 24em;
				.MultipleChoice-option { 
					position: absolute;
					background-size: contain;
					background-position: center center;
					span:nth-child(2) {
						bottom: -0.5em;
						right: -0.5em;
						left: auto;
						width: 1.39em;
						height: 1.39em;
					}
					&.option-1 {
						right: 0;
						bottom: 0;
						width: 7.25em;
						height: 8.15em;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/customer-service-1-rain-items/option-1.svg');
					}
					&.option-2 {
						left: 0;
						bottom: 0;
						width: 4.65em;
						height: 9.44em;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/customer-service-1-rain-items/option-2.svg');
					}
					&.option-3 {
						top: 1em;
						right: 2em;
						width: 3.67em;
						height: 2.43em;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/customer-service-1-rain-items/option-3.svg');
					}
					&.option-4 {
						left: 6.5em;
						bottom: 0;
						width: 2.08em;
						height: 11.22em;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/customer-service-1-rain-items/option-4.svg');
					}
					&.option-5 {
						top: 6em;
						right: 2em;
						width: 2.81em;
						height: 5.72em;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/customer-service-1-rain-items/option-5.svg');
					}
					&.option-6 {
						top: 0;
						left: 0;
						width: 11.61em;
						height: 11.78em;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/customer-service-1-rain-items/option-6.svg');
						span:nth-child(2) {
							right: 3em;
						}
					}
				}
			}
		}		
	}

	&.customer-service-1-shelf-before-walk-away {
		.MultipleChoice-options {
			padding: 1em 0;
			.MultipleChoice-optionsWrap {
				margin: auto;
				width: 10em;
				.MultipleChoice-option { 
					display: inline-block;
					width: 10em;
					height: 11.67em;
					background-size: contain;
					background-position: center center;
					&:nth-child(1) {margin-bottom: 1em;}
					span:nth-child(2) {
						bottom: 0.5em;
						right: 0.5em;
						left: auto;
					}
					&.option-1 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/customer-service-1-shelf-before-walk-away/option-1.svg');
					}
					&.option-2 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/customer-service-1-shelf-before-walk-away/option-2.svg');
					}
				}
			}
		}
	}

	&.customer-service-1-shrink-spell {
		.MultipleChoice-options {
			padding: 1em 0;
			.MultipleChoice-optionsWrap {
				margin: auto;
				width: 18em;
				.MultipleChoice-option { 
					display: inline-block;
					width: 9em;
					height: 9em;
					background-size: 4.5em auto;
					background-position: center center;
					span:nth-child(2) {
						bottom: 2em;
						left: 1em;
					}
					&.option-1 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/customer-service-1-shrink-spell/option-1.svg');
					}
					&.option-2 {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/customer-service-1-shrink-spell/option-2.svg');
					}
					&.option-3 {
						background-size: 6em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/customer-service-1-shrink-spell/option-3.svg');
					}
					&.option-4 {
						background-size: 5.4em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/customer-service-1-shrink-spell/option-4.svg');
					}
				}
			}
		}
	}

	&.customer-service-1-information-board {
		.MultipleChoice-options {
			margin-top: 1em;
			padding-bottom: 1em;
			.MultipleChoice-optionsWrap {
				background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/orientation-1-information-board/background.svg');
				background-position: top center;
				background-size: 18em 100%;
				height: 24.4em;				
				.MultipleChoice-option { 
					position: absolute; 
					&.option-1 {
						width: 4.6em;
						@include rotate(2.5deg);
						height: 5.75em;
						top: 5.45em; left: calc(50% + 1.5em);
					}
					&.option-2 {
						width: 4.6em;
						height: 5.6em;
						top: 5.55em; left: calc(50% - 6.1em);
					}
					&.option-3 {
						width: 4.6em;
						@include rotate(-2.5deg);
						height: 5.75em;
						top: 12em; left: calc(50% - 4.2em);
					}
					&.selected,
					&.correct,
					&.animateCorrect,
					&.wrong,
					&.animateWrong {@include noAnimation();}
				}
			}
		}
	}

	&.customer-service-2-collect-carts {
		.MultipleChoice-options {
			padding: 1em 0;
			.MultipleChoice-optionsWrap {
				margin: auto;
				width: 18em;
				.MultipleChoice-option { 
					display: inline-block;
					width: 9em;
					height: 9em;
					background-size: 4.5em auto;
					background-position: center center;
					span:nth-child(2) {
						bottom: 2em;
						left: 1em;
					}
					&.option-1 {
						background-size: 7.23em 8.15em;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/customer-service-2-collect-carts/option-1.svg');
					}
					&.option-2 {
						background-size: 3.8em 9em;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/customer-service-2-collect-carts/option-2.svg');
					}
					&.option-3 {
						background-size: 7.6em 5.2em;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/customer-service-2-collect-carts/option-3.svg');
					}
					&.option-4 {
						background-size: 3.7em 2.33em;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/customer-service-2-collect-carts/option-4.svg');
					}
				}
			}
		}
	}
}