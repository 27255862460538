@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/mixins_backgrounds';
@import '../../../styles/animations';
@import '../../../styles/animations-intro';
.FacilitatorGameModules {
	position: relative;
	height: 100%;

	.FacilitatorGameModules-flowchart {
		width: 77em;
		padding-left: 2em;
		margin-top: 2em;
		background-image: url('../../../assets/images/facilitator/background-flowchart-path.svg');
		background-size: 62.02em auto;
		background-position: left 12.81em top 3.85em;
		.FacilitatorGameModules-step {
			display: inline-block;
			vertical-align: top;
			width: 23.33em;
			height: 8.44em;
			padding: 0.78em 3em 0em 1.83em;
			margin-right: 0.61em;
			margin-bottom: 3.9em;

			background-image: url('../../../assets/images/facilitator/background-flowchart-step.svg');
			&:nth-child(4), &:nth-child(5), &:nth-child(6) {
				margin-bottom: 0;
			}
			.FacilitatorGameModules-stepTitle {
				span {
					font-size: 1.11em;
					font-weight: bold;
					text-transform: uppercase;
					color: white;
				}
			}
			.FacilitatorGameModules-stepDescription {
				line-height: 1.22;
				span {
					font-weight: 600;
					color: white;
				}
			}
		}
	}
}