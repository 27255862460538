@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/mixins_backgrounds';
@import '../../../styles/animations';
@import '../../../styles/animations-intro';
.AdminGameContent {
	position: relative;
	min-height: 100%;
	padding-top: 2.17em;
}