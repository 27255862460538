@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/mixins_backgrounds';
@import '../../../styles/animations';
@import '../../../styles/animations-intro';
.Badge {
	width: 100%;
	height: 100%;
	background-size: contain;
	background-image: url('../../../assets/images/badges/unknown.svg');
	&.always-prepared{background-image: url('../../../assets/images/badges/always-prepared.svg');}
	&.blazing-sun {background-image: url('../../../assets/images/badges/blazing-sun.svg');}
	&.deli-superstar {background-image: url('../../../assets/images/badges/deli-superstar.svg');}
	&.food-safety-specialist {background-image: url('../../../assets/images/badges/food-safety-specialist.svg');}
	&.helping-hand {background-image: url('../../../assets/images/badges/helping-hand.svg');}
	&.lucky-charm {background-image: url('../../../assets/images/badges/lucky-charm.svg');}
	&.neat-freak {background-image: url('../../../assets/images/badges/neat-freak.svg');}
	&.perfectionist {background-image: url('../../../assets/images/badges/perfectionist.svg');}
	&.problem-solver {background-image: url('../../../assets/images/badges/problem-solver.svg');}
	&.produce-machine {background-image: url('../../../assets/images/badges/produce-machine.svg');}
	&.produce-superstar {background-image: url('../../../assets/images/badges/produce-superstar.svg');}
	&.released-the-kraken {background-image: url('../../../assets/images/badges/released-the-kraken.svg');}
	&.shooting-star {background-image: url('../../../assets/images/badges/shooting-star.svg');}
	&.smooth-talker {background-image: url('../../../assets/images/badges/smooth-talker.svg');}
	&.stardust {background-image: url('../../../assets/images/badges/stardust.svg');}
	&.stickler-for-details {background-image: url('../../../assets/images/badges/stickler-for-details.svg');}
	&.undercover-hero {background-image: url('../../../assets/images/badges/undercover-hero.svg');}
	&.veteran-deli {background-image: url('../../../assets/images/badges/veteran-deli.svg');}
	&.veteran-produce {background-image: url('../../../assets/images/badges/veteran-produce.svg');}
	&.locked {
		@include opacity(0.25);
		mix-blend-mode: luminosity;
		&.always-prepared {background-image: url('../../../assets/images/badges/always-prepared-shadow.svg');}
		&.blazing-sun {background-image: url('../../../assets/images/badges/blazing-sun-shadow.svg');}
		&.deli-superstar {background-image: url('../../../assets/images/badges/deli-superstar-shadow.svg');}
		&.food-safety-specialist {background-image: url('../../../assets/images/badges/food-safety-specialist-shadow.svg');}
		&.helping-hand {background-image: url('../../../assets/images/badges/helping-hand-shadow.svg');}
		&.lucky-charm {background-image: url('../../../assets/images/badges/lucky-charm-shadow.svg');}
		&.neat-freak {background-image: url('../../../assets/images/badges/neat-freak-shadow.svg');}
		&.perfectionist {background-image: url('../../../assets/images/badges/perfectionist-shadow.svg');}
		&.problem-solver {background-image: url('../../../assets/images/badges/problem-solver-shadow.svg');}
		&.produce-machine {background-image: url('../../../assets/images/badges/produce-machine-shadow.svg');}
		&.produce-superstar {background-image: url('../../../assets/images/badges/produce-superstar-shadow.svg');}
		&.released-the-kraken {background-image: url('../../../assets/images/badges/released-the-kraken-shadow.svg');}
		&.shooting-star {background-image: url('../../../assets/images/badges/shooting-star-shadow.svg');}
		&.smooth-talker {background-image: url('../../../assets/images/badges/smooth-talker-shadow.svg');}
		&.stardust {background-image: url('../../../assets/images/badges/stardust-shadow.svg');}
		&.stickler-for-details {background-image: url('../../../assets/images/badges/stickler-for-details-shadow.svg');}
		&.undercover-hero {background-image: url('../../../assets/images/badges/undercover-hero-shadow.svg');}
		&.veteran-deli {background-image: url('../../../assets/images/badges/veteran-deli-shadow.svg');}
		&.veteran-produce {background-image: url('../../../assets/images/badges/veteran-produce-shadow.svg');}
	}
}
