@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/mixins_backgrounds';
@import '../../../styles/animations';
@import '../../../styles/animations-intro';
.Module {
	position: relative;
	height: 100%;
	background-color: #10779E;
	background-image: radial-gradient(circle at left center, #7ABCEA 10%, #10779E 90%);
	overflow: auto;

	&.intro {
		@include blue-background();
	}
	
	&.bagger {
		@include bagger-blue-background();
		&.landscape {@include bagger-blue-landscape-background();}
	}
	
	&.bakery {
		@include bakery-blue-background();
		&.landscape {@include bakery-blue-landscape-background();}
	}
	
	&.cashier {
		@include cashier-blue-background();
		&.landscape {@include cashier-blue-landscape-background();}
	}

	&.center-store {
		@include center-store-blue-background();
		&.offset-floor {
			background-position: left calc(50% - 7.25em) bottom 3em, center;	
		}
		&.landscape {
			@include center-store-blue-landscape-background();
			&.offset-floor {
				background-position: bottom 0.5em center, center;
			}
		}

	}

	&.customer-line {
		@include customer-line();
		&.landscape {
			@include customer-line-landscape();
		}
	}

	&.customer-service {
		@include customer-service-blue-background();
		&.landscape {@include customer-service-blue-landscape-background();}
	}
	
	&.deli {
		@include deli-blue-background();
		&.landscape {@include deli-blue-landscape-background();}
	}

	&.floral {
		@include floral-blue-background();
		&.landscape {@include floral-blue-landscape-background();}
	}

	&.intro {
		@include blue-background();
	}
	
	&.meat {
		@include meat-blue-background();
		&.landscape {@include meat-blue-landscape-background();}
	}

	&.orientation {
		@include orientation-blue-background();
		&.landscape {@include orientation-blue-landscape-background();}
	}

	&.produce {
		@include produce-blue-background();
		&.landscape {@include produce-blue-landscape-background();}
	}

	&.salad-bar {
		@include salad-bar-blue-background();
	}

	&.seafood {
		@include seafood-blue-background();
		&.landscape {@include seafood-blue-landscape-background();}
	}

	&.outside {
		@include login-background();
	}

	&.ocean {
		@include ocean();
	}

	&.ocean-island {
		@include ocean-island();
	}

	&.ocean-raft {
		@include ocean-raft();
	}
	&.ocean-raft-ice {
		@include ocean-raft-ice();
	}
	&.ocean-ice-rink {
		@include ocean-ice-rink();
	}

	.Module-header {
		position: absolute;
		top: 0;
		width: 100%;
		padding-top: 0.25em;
		z-index: 11;
		@include flex('center', 'flex-start');
	
		.Module-areaMapBtn {
			width: 3em;
			height: 3em;
		}
		.Module-pointsTrack {
			width: 14em;
		}

		.Module-profileBtn {
			width: 3em;
			height: 3em;
			@include flex('center', 'center');
			&.highlight {
				border-radius: 3em;
				border: 0.2em solid white;
				@include box-shadow(0, 0, 0.56em, 0, white);
			}
		}
	}


	.Module-nextBtn {
		position: absolute;
		right: 0em;
		bottom: 0.2em;
		z-index: 11;

		&.done,
		&.finish {
			right: 1.5em;
			bottom: 1.5em;
		}
	}


	&.landscape {
		.Module-areaMapBtn {margin-right: 0.75em;}
		.Module-pointsTrack {width: 18em;}
		.Module-profileBtn {margin-left: 0.75em;}
		.Module-nextBtn {right: calc(50% - 13.5em);}
	}
}