@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/mixins_backgrounds';
@import '../../../styles/animations';
@import '../../../styles/animations-intro';
.FacilitatorHeader {
	position: relative;
	width: 100%;
	height: 12.11em;
	background-image: url('../../../assets/images/facilitator/background-header.jpg');
	background-size: cover;

	.FacilitatorHeader-logo {
		position: absolute;
		top: 0em;
		left: 2em;
		width: 8em;
		text-align: center;
		cursor: pointer;
		img {
			display: block;
			&:nth-of-type(1) {width: 100%;}
			&:nth-of-type(2) {
				width: 7.8em;
				margin-left: calc(50% - 3.9em);
				margin-top: -0.4em;
			}
		}
	}

	.FacilitatorHeader-buttons {
		position: absolute;
		top: 0.75em;
		right: 1.5em;
		width: 2.5em;
		text-align: right;
		> div {
			margin-bottom: 1em;
		}
	}

	.FacilitatorHeader-user {
		position: absolute;
		top: 2.25em;
		right: 6.45em;
		width: 28em;
		height: 7.56em;
		@include flex('flex-start', 'center');
		.FacilitatorHeader-userInfo {
			width: calc(100% - 6em);
			color: white;
			background-color: #10779E;
			border-radius: 0.44em;
			padding: 0.25em 0.75em;
			@include box-shadow(0, 0.167em, 0.33em, 0, rgba(black, 0.16));
			> div {
				white-space: nowrap;
				overflow: hidden;
				span, a {
					display: inline-block;
					font-size: 0.75em;
					color: white;
					&:first-of-type {
						font-weight: 600;
						width: 7em;
					}
				}
			}
		}
		.FacilitatorHeader-userProfile {
			position: absolute;
			top: 0;
			right: 0;
			width: 7.56em;
			height: 7.56em;
			background-color: white;
			border: calc(0.11em + 1px) solid #10779E;
			border-radius: 100%;
			overflow: hidden;
		}
		.FacilitatorHeader-userProfileEditBtn {
			position: absolute;
			bottom: -0.8em;
			right: -0.5em;
			width: 8.56em;
			height: 1.67em;
			background-color: white;
			border: calc(0.11em + 1px) solid #10779E;
			border-radius: 0.833em;
			cursor: pointer;
			@include flex('center', 'center');
			span {
				font-size: 0.85em;
				font-weight: 600;
				color: #10779E;
			}
		}
	}

	.FacilitatorHeader-menu {
		position: absolute;
		bottom: 0;
		left: 2em;
		width: 85.5em;
		height: 2.44em;
		@include flex('space-between', 'flex-end');
		.FacilitatorHeader-menuItem {
			width: 18em;
			height: 100%;
			color: #10779E;
			background-color: #A2D0F0;
			border-top-left-radius: 0.83em;
			border-top-right-radius: 0.83em;
			padding-bottom: 0.15em;
			cursor: pointer;
			@include flex('center', 'flex-end');
			span {
				font-size: 1.39em;
				font-weight: bold;
			}
			&.selected {
				color: #10779E;
				background-color: white;
			}
		}
	}
}

// @media (orientation: portrait) and (min-aspect-ratio: 375/667) {
//   .FacilitatorOverview {
//     min-height: calc(177.5vw);
// 		&.map,
// 		&.splash {
// 			min-height: 100%;
// 		}
//   }
// }