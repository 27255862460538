@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/mixins_backgrounds';
@import '../../../styles/animations';
@import '../../../styles/animations-intro';
.Manager {
	width: auto;
	svg {
		display: block;
		width: 16.5em;
		height: auto;
		overflow: visible;
		#Manager_M_01 > g,
		#Manager_M_02 > g,
		#Manager_M_03 > g,
		#Manager_M_04 > g,
		#Manager_F_01 > g,
		#Manager_F_02 > g,
		#Manager_F_03 > g,
		#Manager_F_04 > g {
			display: none;
		}
	}

	&.intro svg {
		width: 15.35em;
	}

	&.area svg {
		width: 100%;
	}

	&.instructions {
		position: absolute;
		left: -2.5em;
		bottom: 0;
		z-index: 9;
	}

	&.branching-story svg {
		width: 4em;
		margin-left: -0.8em;
		@include scaleX(-1);
	}

	&.ocean {
		bottom: 2.6em;
		left: -1em;

		&::before {
			content: '';
			display: block;
			position: absolute;
			width: 6.5em;
			height: 2em;
			bottom: -1em;
			left: 2.5em;
			z-index: 1;
			background-image: url('../../../assets/images/characters/items/ocean-ring.svg');
			background-position: bottom center;
			background-size: contain;
		}

		svg {
			position: absolute;
			width: 12em;
			z-index: 2;
			bottom: 0;
		}
	}

	&.taskIntro {
		position: absolute;
		// top: 4em;
		// right: 1em;
		width: 6em;
		height: 5em;
		overflow: hidden;
		svg {
			margin-top: 0.1em;
			margin-left: -0.1em;
			width: 9em;
		}
		&.manager-e,
		&.manager-f,
		&.manager-g,
		&.manager-h {
			height: 6em;
			// top: 3em;
			@include translate(0, 1em);
			svg {
				margin-top: 0;
			}
		}
	}
	
	&.area-notification,
	&.feedback {
		width: 100%;
		height: 100%;
		overflow: hidden;
		border-radius: 100%;		
		svg {
			margin-top: 0.1em;
			margin-left: -0.1em;
			width: 3.2em;
		}
		&.manager-e,
		&.manager-f,
		&.manager-g,
		&.manager-h {
			svg {
				margin-top: 0;
			}
		}
	}

	&.dialogue {
		width: 100%;
		height: 100%;
		overflow: hidden;
		border-radius: 100%;		
		svg {
			margin-top: 0.2em;
			margin-left: -0.8em;
			width: 5em;
		}
		&.manager-e,
		&.manager-f,
		&.manager-g,
		&.manager-h {
			svg {
				margin-top: -0.2em;
			}
		}
	}

	&.high-five svg {
		width: 18em;
	}

	&.facilitator-header {
		width: 100%;
		height: 100%;
		overflow: hidden;
		border-radius: 100%;	
		svg {
			width: 10em;
			margin-left: -2em;
			margin-top: 0.1em;
			@include scaleX(-1);
		}
		&.manager-e,
		&.manager-f,
		&.manager-g,
		&.manager-h {
			svg {
				margin-top: -0.5em;
			}
		}	
	}

	&.facilitator-popup {
		width: 100%;
		height: 100%;
		overflow: hidden;
		border-radius: 100%;
		svg {
			margin-top: 0.2em;
			margin-left: -0.5em;
			width: 10em;

		}
		&.manager-e,
		&.manager-f,
		&.manager-g,
		&.manager-h {
			svg {
				margin-top: -0.4em;
			}
		}	
	}

	// Male 1
	&.manager-a {
		#Manager_M_01 > #Body {display: block;}
		&.manager-1 { // happy, left hand "presenting"
			#Manager_M_01 > #Head_01,
			#Manager_M_01 > #Arm_02 {
				display: block;
			}
		}
		&.manager-2 { // happy, hands at sides
			#Manager_M_01 > #Head_01,
			#Manager_M_01 > #Arm_01,
			#Manager_M_01 > #Hand_01 {
				display: block;
			}
		}
		&.manager-3 { // angry, hands at sides
			#Manager_M_01 > #Head_02,
			#Manager_M_01 > #Arm_01,
			#Manager_M_01 > #Hand_01 {
				display: block;
			}
		}
		&.manager-4 { // happy with helmet, hands at sides
			#Manager_M_01 > #HelmetHead_01,
			#Manager_M_01 > #Arm_01,
			#Manager_M_01 > #Hand_01 {
				display: block;
			}
		}
		&.manager-5 { // happy with helmet, left hand high five
			#Manager_M_01 > #HelmetHead_01,
			#Manager_M_01 > #Arm_03 {
				display: block;
			}
		}
		&.manager-6 { // angry with helmet, hands at sides
			#Manager_M_01 > #HelmetHead_02,
			#Manager_M_01 > #Arm_01,
			#Manager_M_01 > #Hand_01 {
				display: block;
			}
		}
		&.manager-7 { // happy, left hand high five
			#Manager_M_01 > #Head_01,
			#Manager_M_01 > #Arm_03 {
				display: block;
			}
		}
	}

	// Male 2
	&.manager-b {
		#Manager_M_02 > #Body-2 {display: block;}
		&.manager-1 { // happy, left hand "presenting"
			#Manager_M_02 > #Head_01-2,
			#Manager_M_02 > #Arm_02-2 {
				display: block;
			}
		}
		&.manager-2 { // happy, hands at sides
			#Manager_M_02 > #Head_01-2,
			#Manager_M_02 > #Arm_01-2,
			#Manager_M_02 > #Hand_01-2 {
				display: block;
			}
		}
		&.manager-3 { // angry, hands at sides
			#Manager_M_02 > #Head_02-2,
			#Manager_M_02 > #Arm_01-2,
			#Manager_M_02 > #Hand_01-2 {
				display: block;
			}
		}
		&.manager-4 { // happy with helmet, hands at sides
			#Manager_M_02 > #HelmetHead_01-2,
			#Manager_M_02 > #Arm_01-2,
			#Manager_M_02 > #Hand_01-2 {
				display: block;
			}
		}
		&.manager-5 { // happy with helmet, left hand high five
			#Manager_M_02 > #HelmetHead_01-2,
			#Manager_M_02 > #Arm_03-2 {
				display: block;
			}
		}
		&.manager-6 { // angry with helmet, hands at sides
			#Manager_M_02 > #HelmetHead_02-2,
			#Manager_M_02 > #Arm_01-2,
			#Manager_M_02 > #Hand_01-2 {
				display: block;
			}
		}
		&.manager-7 { // happy, left hand high five
			#Manager_M_02 > #Head_01-2,
			#Manager_M_02 > #Arm_03-2 {
				display: block;
			}
		}
	}

	// Male 3
	&.manager-c {
		#Manager_M_03 > #Body-3 {
			display: block;}
		&.manager-1 { // happy, left hand "presenting"
			#Manager_M_03 > #Head_01-3,
			#Manager_M_03 > #Arm_02-3 {
				display: block;
			}
		}
		&.manager-2 { // happy, hands at sides
			#Manager_M_03 > #Head_01-3,
			#Manager_M_03 > #Arm_01-3,
			#Manager_M_03 > #Hand_01-3 {
				display: block;
			}
		}
		&.manager-3 { // angry, hands at sides
			#Manager_M_03 > #Head_02-3,
			#Manager_M_03 > #Arm_01-3,
			#Manager_M_03 > #Hand_01-3 {
				display: block;
			}
		}
		&.manager-4 { // happy with helmet, hands at sides
			#Manager_M_03 > #HelmetHead_01-3,
			#Manager_M_03 > #Arm_01-3,
			#Manager_M_03 > #Hand_01-3 {
				display: block;
			}
		}
		&.manager-5 { // happy with helmet, left hand high five
			#Manager_M_03 > #HelmetHead_01-3,
			#Manager_M_03 > #Arm_03-3 {
				display: block;
			}
		}
		&.manager-6 { // angry with helmet, hands at sides
			#Manager_M_03 > #HelmetHead_02-3,
			#Manager_M_03 > #Arm_01-3,
			#Manager_M_03 > #Hand_01-3 {
				display: block;
			}
		}
		&.manager-7 { // happy, left hand high five
			#Manager_M_03 > #Head_01-3,
			#Manager_M_03 > #Arm_03-3 {
				display: block;
			}
		}
	}

	// Male 4
	&.manager-d {
		#Manager_M_04 > #Body-4 {display: block;}
		&.manager-1 { // happy, left hand "presenting"
			#Manager_M_04 > #Head_01-4,
			#Manager_M_04 > #Arm_02-4 {
				display: block;
			}
		}
		&.manager-2 { // happy, hands at sides
			#Manager_M_04 > #Head_01-4,
			#Manager_M_04 > #Arm_01-4,
			#Manager_M_04 > #Hand_01-4 {
				display: block;
			}
		}
		&.manager-3 { // angry, hands at sides
			#Manager_M_04 > #Head_02-4,
			#Manager_M_04 > #Arm_01-4,
			#Manager_M_04 > #Hand_01-4 {
				display: block;
			}
		}
		&.manager-4 { // happy with helmet, hands at sides
			#Manager_M_04 > #HelmetHead_01-4,
			#Manager_M_04 > #Arm_01-4,
			#Manager_M_04 > #Hand_01-4 {
				display: block;
			}
		}
		&.manager-5 { // happy with helmet, left hand high five
			#Manager_M_04 > #HelmetHead_01-4,
			#Manager_M_04 > #Arm_03-4 {
				display: block;
			}
		}
		&.manager-6 { // angry with helmet, hands at sides
			#Manager_M_04 > #HelmetHead_02-4,
			#Manager_M_04 > #Arm_01-4,
			#Manager_M_04 > #Hand_01-4 {
				display: block;
			}
		}
		&.manager-7 { // happy, left hand high five
			#Manager_M_04 > #Head_01-4,
			#Manager_M_04 > #Arm_03-4 {
				display: block;
			}
		}
	}

	// Female 1
	&.manager-e {
		#Manager_F_01 > #Body-5,
		#Manager_F_01 > #Hair-2 {
			display: block;
		}
		&.manager-1 { // happy, left hand "presenting"
			#Manager_F_01 > #Head_01-5,
			#Manager_F_01 > #Arm_02-5 {
				display: block;
			}
		}
		&.manager-2 { // happy, hands at sides
			#Manager_F_01 > #Head_01-5,
			#Manager_F_01 > #Arm_01-5,
			#Manager_F_01 > #Hand_01-5 {
				display: block;
			}
		}
		&.manager-3 { // angry, hands at sides
			#Manager_F_01 > #Head_02-5,
			#Manager_F_01 > #Arm_01-5,
			#Manager_F_01 > #Hand_01-5 {
				display: block;
			}
		}
		&.manager-4 { // happy with helmet, hands at sides
			#Manager_F_01 > #HelmetHead_01-5,
			#Manager_F_01 > #Arm_01-5,
			#Manager_F_01 > #Hand_01-5 {
				display: block;
			}
		}
		&.manager-5 { // happy with helmet, left hand high five
			#Manager_F_01 > #HelmetHead_01-5,
			#Manager_F_01 > #Arm_03-5 {
				display: block;
			}
		}
		&.manager-6 { // angry with helmet, hands at sides
			#Manager_F_01 > #HelmetHead_02-5,
			#Manager_F_01 > #Arm_01-5,
			#Manager_F_01 > #Hand_01-5 {
				display: block;
			}
		}
		&.manager-7 { // happy, left hand high five
			#Manager_F_01 > #Head_01-5,
			#Manager_F_01 > #Arm_03-5 {
				display: block;
			}
		}
	}

	// Female 2
	&.manager-f {
		#Manager_F_02 > #Body-6,
		#Manager_F_02 > #Hair-2-2 {display: block;}
		&.manager-1 { // happy, left hand "presenting"
			#Manager_F_02 > #Head_01-6,
			#Manager_F_02 > #Arm_02-6 {
				display: block;
			}
		}
		&.manager-2 { // happy, hands at sides
			#Manager_F_02 > #Head_01-6,
			#Manager_F_02 > #Arm_01-6,
			#Manager_F_02 > #Hand_01-6 {
				display: block;
			}
		}
		&.manager-3 { // angry, hands at sides
			#Manager_F_02 > #Head_02-6,
			#Manager_F_02 > #Arm_01-6,
			#Manager_F_02 > #Hand_01-6 {
				display: block;
			}
		}
		&.manager-4 { // happy with helmet, hands at sides
			#Manager_F_02 > #HelmetHead_01-6,
			#Manager_F_02 > #Arm_01-6,
			#Manager_F_02 > #Hand_01-6 {
				display: block;
			}
		}
		&.manager-5 { // happy with helmet, left hand high five
			#Manager_F_02 > #HelmetHead_01-6,
			#Manager_F_02 > #Arm_03-6 {
				display: block;
			}
		}
		&.manager-6 { // angry with helmet, hands at sides
			#Manager_F_02 > #HelmetHead_02-6,
			#Manager_F_02 > #Arm_01-6,
			#Manager_F_02 > #Hand_01-6 {
				display: block;
			}
		}
		&.manager-7 { // happy, left hand high five
			#Manager_F_02 > #Head_01-6,
			#Manager_F_02 > #Arm_03-6 {
				display: block;
			}
		}
	}

	// Female 3
	&.manager-g {
		#Manager_F_03 > #Body-7,
		#Manager_F_03 > #Hair-3 {display: block;}
		&.manager-1 { // happy, left hand "presenting"
			#Manager_F_03 > #Head_01-7,
			#Manager_F_03 > #Arm_02-7 {
				display: block;
			}
		}
		&.manager-2 { // happy, hands at sides
			#Manager_F_03 > #Head_01-7,
			#Manager_F_03 > #Arm_01-7,
			#Manager_F_03 > #Hand_01-7 {
				display: block;
			}
		}
		&.manager-3 { // angry, hands at sides
			#Manager_F_03 > #Head_02-7,
			#Manager_F_03 > #Arm_01-7,
			#Manager_F_03 > #Hand_01-7 {
				display: block;
			}
		}
		&.manager-4 { // happy with helmet, hands at sides
			#Manager_F_03 > #HelmetHead_01-7,
			#Manager_F_03 > #Arm_01-7,
			#Manager_F_03 > #Hand_01-7 {
				display: block;
			}
		}
		&.manager-5 { // happy with helmet, left hand high five
			#Manager_F_03 > #HelmetHead_01-7,
			#Manager_F_03 > #Arm_03-7 {
				display: block;
			}
		}
		&.manager-6 { // angry with helmet, hands at sides
			#Manager_F_03 > #HelmetHead_02-7,
			#Manager_F_03 > #Arm_01-7,
			#Manager_F_03 > #Hand_01-7 {
				display: block;
			}
		}
		&.manager-7 { // happy, left hand high five
			#Manager_F_03 > #Head_01-7,
			#Manager_F_03 > #Arm_03-7 {
				display: block;
			}
		}
	}


	// Female 4
	&.manager-h {
		#Manager_F_04 > #Body-8,
		#Manager_F_04 > #Hair-4 {display: block;}
		&.manager-1 { // happy, left hand "presenting"
			#Manager_F_04 > #Head_01-8,
			#Manager_F_04 > #Arm_02-8 {
				display: block;
			}
		}
		&.manager-2 { // happy, hands at sides
			#Manager_F_04 > #Head_01-8,
			#Manager_F_04 > #Arm_01-8,
			#Manager_F_04 > #Hand_01-8 {
				display: block;
			}
		}
		&.manager-3 { // angry, hands at sides
			#Manager_F_04 > #Head_02-8,
			#Manager_F_04 > #Arm_01-8,
			#Manager_F_04 > #Hand_01-8 {
				display: block;
			}
		}
		&.manager-4 { // happy with helmet, hands at sides
			#Manager_F_04 > #HelmetHead_01-8,
			#Manager_F_04 > #Arm_01-8,
			#Manager_F_04 > #Hand_01-8 {
				display: block;
			}
		}
		&.manager-5 { // happy with helmet, left hand high five
			#Manager_F_04 > #HelmetHead_01-8,
			#Manager_F_04 > #Arm_03-8 {
				display: block;
			}
		}
		&.manager-6 { // angry with helmet, hands at sides
			#Manager_F_04 > #HelmetHead_02-8,
			#Manager_F_04 > #Arm_01-8,
			#Manager_F_04 > #Hand_01-8 {
				display: block;
			}
		}
		&.manager-7 { // happy, left hand high five
			#Manager_F_04 > #Head_01-8,
			#Manager_F_04 > #Arm_03-8 {
				display: block;
			}
		}
	}


	&.flipped {
		@include scaleX(-1);
	}
}