@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';
@import '../../../../styles/mixins_backgrounds';
@import '../../../../styles/animations';
@import '../../../../styles/animations-intro';
.OrderDndItem {
	position: relative;
	width: 100%;
	height: 2.25em;
	line-height: 1.1em;
	border-radius: 0.55em;
	font-family: 'Source Sans Pro', sans-serif;
	font-weight: bold;
	text-align: center;
	padding: 0.75em 2em 0.5em 2em;
	cursor: pointer;
	@include blue-button();
	@include flex('center', 'center');
	@include no-select();
	span {
		color: white;
		font-size: 0.85em;
		font-weight: bold;
	}

	&.correct {@include green-button();}

	.OrderDndItem-handle {
		position: absolute;
		height: 100%;
		width: 6em;
		right: 0;
		top: 0;
		background-image: url('../../../../assets/images/modules/icon-move2.svg');
		background-position: left 4.5em center;
		background-size: auto 1.25em;
		cursor: move;
		&.disabled {
			cursor: not-allowed;
			background-image: none;
		}
	}

	&.orientation-1-order-of-important,
	&.deli-2-order-deep-clean-display-case,
	&.floral-2-multiple-choice-flower-care-water-buckets,
	&.meat-1-order-fix-hot-case,
	&.produce-2-order-receiving-bananas,
	&.produce-2-order-vegetables-crisping,
	&.produce-2-order-lettuce-crisping,
	&.seafood-2-order-reset-service-case,
	&.seafood-2-order-purging-lobsters,
	&.daily-guidelines-1-order-culling {
		height: 3em;
	}
}