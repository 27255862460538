@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/mixins_backgrounds';
@import '../../../styles/animations';
@import '../../../styles/animations-intro';
.FacilitatorStatistics {
	position: relative;
	min-height: 100%;

	.FacilitatorStatistics-header 
	{
		@include flex('','flex-end','row');
	}

	.FacilitatorStatistics-table
	{
		margin-top: 1em;
		height: 100%;
		width: 100%;

		.FacilitatorStatistics-tableHeader
		{
			@include flex('', 'center', 'row');
			background-color: #11466B;
			padding: 1em;

			.FacilitatorStatistics-tableHeaderTitle {
				@include flex('center', '', '');
				width: 100%;
				display: flex;
				cursor: pointer;
				@include no-select();
				span {
					font-weight: bold;
					color: white;
				}

				&:last-child {
					cursor: default;
					@include flex('flex-end', '', '');
				}

				&:first-child {
					@include flex('flex-start', '', '');
				}
				&.sortedBy {
					position: relative;
					span::after {
						content: '';
						position: absolute;
						top: 0.2em;
						display: inline-block;
						vertical-align: middle;
						width: 1em;
						height: 1em;
						background-size: 0.6em auto;
						background-image: url('../../../assets/images/facilitator/icon-sort-desc.svg');
					}
					&.ASC span::after {background-image: url('../../../assets/images/facilitator/icon-sort-asc.svg');}
				}
			}
		}

		.FacilitatorStatistics-tableRow
		{
			@include flex('space-between', 'center', 'row');
			padding: 0.5em;
			padding-left: 1em;

			&:nth-child(odd) {
				background-color: #EDEDED;
			}

			.FacilitatorStatistics-moduleItem
			{
				@include flex('center', '', '');
				width: 100%;

				&:first-child {
					@include flex('flex-start', '', '');
				}

				&.bold
				{
					font-weight: bold;
				}
				
				&.iconEye
				{
					cursor: pointer;
					background-image: url('../../../assets/images/facilitator/icon-eye.svg');
					background-size: 1em 1em;
					height: 1.5em;
					background-position: top calc(50%) right 1.5em;
				}
			}
		}
	}
}