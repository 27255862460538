@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';
@import '../../../../styles/mixins_backgrounds';
@import '../../../../styles/animations';
@import '../../../../styles/animations-intro';
.FacilitatorFindWinnerPopup {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(#7ABCEA, 0.81);
	@include flex('center', 'center');

	.FacilitatorFindWinnerPopup-closeBtn {
		padding: 0em;
		width: 2em;
		height: 2em;
		background-image: url('../../../../assets/images/facilitator/icon-close.svg');
		background-size: 1.75em auto;
		cursor: pointer;
	}

	.FacilitatorFindWinnerPopup-content {
		width: 40em;
		height: 20em;
		background-color: white;
		border-radius: 0.83em;

		.FacilitatorFindWinnerPopup-header {
			position: relative;
			width: 100%;
			height: 2.8em;
			background-color: #11466B;
			border-top-left-radius: 0.83em;
			border-top-right-radius: 0.83em;
			border: 0.25em solid white;
			padding-left: 1em;
			@include flex('space-between', 'center');
			.FacilitatorFindWinnerPopup-title {
				color: #ffffff;
				font-size: 1.25em;
				font-weight: 600;
			}
			.FacilitatorFindWinnerPopup-closeBtn {
				width: 2.33em;
				height: 2.33em;
				background-image: url('../../../../assets/images/facilitator/icon-close.svg');
				background-size: 1.56em auto;
				cursor: pointer;
			}
		}

		.FacilitatorFindWinnerPopup-body {
			position: relative;
			padding: 1.5em 1.5em 1em 1.5em;
			height: calc(100% - 2.8em);
			.FacilitatorFindWinnerPopup-text {
				margin-bottom: 1em;
				p {
					margin: 0;
					color: #10779E;
				}
			}
			.FacilitatorFindWinnerPopup-info {
				span {
					display: inline-block;
					color: #10779E;
					&:first-of-type {
						font-weight: 600;
						width: 12em;
					}
				}
			}
			.FacilitatorFindWinnerPopup-successMsg,
			.FacilitatorFindWinnerPopup-errMsg {
				margin-top: 1em;
				p {
					margin: 0;

					color: #10779E;
				}
			}
			.FacilitatorFindWinnerPopup-successMsg {
				p span {
					font-weight: bold;
				}
			}
			.FacilitatorFindWinnerPopup-errMsg p {
				font-size: 0.8em;
				color: #C13B3B;
			}

			.FacilitatorFindWinnerPopup-findWinnerBtn {
				position: absolute;
				bottom: 1.5em;
				left: 1.5em;
				> div {margin: 0;}
			}
		}
	}
}