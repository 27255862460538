.MultipleChoice {
	&.food-safety-1-cooler-temps {
		.MultipleChoice-options {
			margin-top: 1em;
			padding-bottom: 1em;
			.MultipleChoice-optionsWrap {
				height: 24.4em;	
				padding: 0 1.5em;			
				.MultipleChoice-option { 
					position: absolute;
					background-size: contain;
					background-position: center center;
					width: 8.43em;
					height: 8.43em;
					span:nth-child(2) {
						bottom: 0.5em;
						right: 0.75em;
						left: auto;
						width: 1.39em;
						height: 1.39em;
					}
					&.position-1 {top: 0; left: 1em;}
					&.position-2 {top: 0; right: 1em;}
					&.position-3 {top: 9.43em; left: 1em;}
					&.position-4 {top: 9.43em; right: 1em;}
					&.position-5 {top: 18.86em; right: calc(50% - 8.43em / 2);}

					&.option-1 {
						background-size: contain;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/food-safety-1-cooler-temps/option-1.svg');
					}
					&.option-2 {
						background-size: contain;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/food-safety-1-cooler-temps/option-2.svg');
					}
					&.option-3 {
						background-size: contain;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/food-safety-1-cooler-temps/option-3.svg');
					}
					&.option-4 {
						background-size: contain;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/food-safety-1-cooler-temps/option-4.svg');
					}
					&.option-5 {
						background-size: contain;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/food-safety-1-cooler-temps/option-5.svg');
					}
				}
			}
		}
	}

	&.food-safety-1-cooler-temps-2 {
		.MultipleChoice-options {
			margin-top: 1em;
			padding-bottom: 1em;
			.MultipleChoice-optionsWrap {
				height: 24.4em;	
				padding: 0 1.5em;			
				.MultipleChoice-option { 
					position: absolute;
					background-size: contain;
					background-position: center center;
					width: 8.43em;
					height: 8.43em;
					span:nth-child(2) {
						bottom: 0.5em;
						right: 0.75em;
						left: auto;
						width: 1.39em;
						height: 1.39em;
					}
					&.position-1 {top: 0; left: 1em;}
					&.position-2 {top: 0; right: 1em;}
					&.position-3 {top: 9.43em; left: 1em;}
					&.position-4 {top: 9.43em; right: 1em;}
					&.position-5 {top: 18.86em; right: calc(50% - 8.43em / 2);}

					&.option-1 {
						background-size: contain;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/food-safety-1-cooler-temps-2/option-1.svg');
					}
					&.option-2 {
						background-size: contain;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/food-safety-1-cooler-temps-2/option-2.svg');
					}
					&.option-3 {
						background-size: contain;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/food-safety-1-cooler-temps-2/option-3.svg');
					}
					&.option-4 {
						background-size: contain;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/food-safety-1-cooler-temps-2/option-4.svg');
					}
					&.option-5 {
						background-size: contain;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/food-safety-1-cooler-temps-2/option-5.svg');
					}
				}
			}
		}
	}

	&.food-safety-1-freezer-temps {
		.MultipleChoice-options {
			margin-top: 1em;
			padding-bottom: 1em;
			.MultipleChoice-optionsWrap {
				height: 24.4em;	
				padding: 0 1.5em;
				.MultipleChoice-option { 
					position: absolute;
					background-size: contain;
					background-position: center center;
					width: 8.43em;
					height: 8.43em;
					span:nth-child(2) {
						bottom: 0.5em;
						right: 0.75em;
						left: auto;
						width: 1.39em;
						height: 1.39em;
					}
					&.position-1 {top: 0; left: 1em;}
					&.position-2 {top: 0; right: 1em;}
					&.position-3 {top: 9.43em; left: 1em;}
					&.position-4 {top: 9.43em; right: 1em;}
					&.position-5 {top: 18.86em; right: calc(50% - 8.43em / 2);}

					&.option-1 {
						background-size: contain;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/food-safety-1-freezer-temps/option-1.svg');
					}
					&.option-2 {
						background-size: contain;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/food-safety-1-freezer-temps/option-2.svg');
					}
					&.option-3 {
						background-size: contain;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/food-safety-1-freezer-temps/option-3.svg');
					}
					&.option-4 {
						background-size: contain;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/food-safety-1-freezer-temps/option-4.svg');
					}
					&.option-5 {
						background-size: contain;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/food-safety-1-freezer-temps/option-5.svg');
					}
				}
			}
		}
	}

	&.food-safety-1-some-more {
		.MultipleChoice-options {
			margin-top: 1em;
			.MultipleChoice-optionsWrap {
				height: 24.4em;	
				.MultipleChoice-option { 
					position: absolute;
					background-size: contain;
					background-position: center center;
					width: 9em;
					height: 9em;
					span:nth-child(2) {
						bottom: 0.5em;
						right: 0.75em;
						left: auto;
						width: 1.39em;
						height: 1.39em;
					}
					&.position-1 {top: 0; left: 0em;}
					&.position-2 {top: 0; right: 0em;}
					&.position-3 {top: 9.43em; left: 0em;}
					&.position-4 {top: 9.43em; right: 0em;}

					&.option-1 {
						background-size: contain;
						width: 11.2em;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/food-safety-1-some-more/option-1.svg');
					}
					&.option-2 {
						background-size: 5.4em 4em;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/food-safety-1-some-more/option-2.svg');
					}
					&.option-3 {
						background-size: contain;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/food-safety-1-some-more/option-3.svg');
					}
					&.option-4 {
						background-size: 3em 7.2em;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/food-safety-1-some-more/option-4.svg');
					}
				}
			}
		}
	}

	&.food-safety-1-some-more-2 {
		.MultipleChoice-options {
			margin-top: 1em;
			.MultipleChoice-optionsWrap {
				height: 24.4em;	
				.MultipleChoice-option { 
					position: absolute;
					background-size: contain;
					background-position: center center;
					width: 9em;
					height: 9em;
					span:nth-child(2) {
						bottom: 0.5em;
						right: 0.75em;
						left: auto;
						width: 1.39em;
						height: 1.39em;
					}
					&.position-1 {top: 0; left: 0em;}
					&.position-2 {top: 0; right: 0em;}
					&.position-3 {top: 9.43em; left: 0em;}
					&.position-4 {top: 9.43em; right: 0em;}

					&.option-1 {
						background-size: 4.8em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/food-safety-1-some-more-2/option-1.svg');
					}
					&.option-2 {
						background-size: 5.74em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/food-safety-1-some-more-2/option-2.svg');
					}
					&.option-3 {
						background-size: 8.6em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/food-safety-1-some-more-2/option-3.svg');
					}
					&.option-4 {
						background-size: 5.8em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/food-safety-1-some-more-2/option-4.svg');
					}
				}
			}
		}
	}

	&.food-safety-1-some-of-them {
		.MultipleChoice-options {
			margin-top: 1em;
			padding-bottom: 1em;
			.MultipleChoice-optionsWrap {
				height: 24.4em;	
				padding: 0 1.5em;			
				.MultipleChoice-option { 
					position: absolute;
					background-size: contain;
					background-position: center center;
					width: 8.43em;
					height: 8.43em;
					span:nth-child(2) {
						bottom: 0.5em;
						right: 0.75em;
						left: auto;
						width: 1.39em;
						height: 1.39em;
					}
					&.position-1 {top: 0; left: 1em;}
					&.position-2 {top: 0; right: 1em;}
					&.position-3 {top: 9.43em; left: 1em;}
					&.position-4 {top: 9.43em; right: 1em;}

					&.option-1 {
						background-size: contain;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/food-safety-1-some-of-them/option-1.svg');
					}
					&.option-2 {
						background-size: contain;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/food-safety-1-some-of-them/option-2.svg');
					}
					&.option-3 {
						background-size: contain;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/food-safety-1-some-of-them/option-3.svg');
					}
					&.option-4 {
						background-size: contain;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/food-safety-1-some-of-them/option-4.svg');
					}
				}
			}
		}
	}

	&.food-safety-1-sinks {
		.MultipleChoice-options {
			margin-top: 1em;
			padding-bottom: 1em;
			.MultipleChoice-optionsWrap {
				height: 24.4em;	
				padding: 0 1.5em;			
				.MultipleChoice-option { 
					display: inline-block;
					width: 18em;
					height: 8em;
					span:nth-child(2) {
						bottom: 0.5em;
						right: 0.75em;
						left: auto;
						width: 1.39em;
						height: 1.39em;
					}
					&.position-1 {margin-right: calc(100% - 16em);}
					&.option-1 {
						background-size: 14.4em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/food-safety-1-sinks/option-1.svg');
					}
					&.option-2 {
						background-size: 18em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/food-safety-1-sinks/option-2.svg');
					}
				}
			}
		}
	}

	&.food-safety-1-washing-hands {
		.MultipleChoice-options {
			margin-top: 1em;
			padding-bottom: 1em;
			.MultipleChoice-optionsWrap {
				height: 24.4em;	
				padding: 0 1.5em;			
				.MultipleChoice-option {
					position: absolute;
					background-size: contain;
					background-position: center center;
					span:nth-child(2) {
						bottom: 0.5em;
						right: 0.75em;
						left: auto;
						width: 1.39em;
						height: 1.39em;
					}
					&.position-1 {top: 0; left: 1em;}
					&.position-2 {top: 0; right: 1em;}
					&.position-3 {top: calc(50% - 5em); left: calc(50% - 3em);}
					&.position-4 {top: calc(50% + 3em); left: 1em;}
					&.position-5 {top: calc(50% + 3em); right: 1em;}

					&.option-1 { // Restroom signs
						width: 5em;
						height: 3.4em;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/food-safety-1-washing-hands/option-1.svg');
					}
					&.option-2 { // garbage bags
						width: 5.8em;
						height: 7.6em;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/food-safety-1-washing-hands/option-2.svg');
					}
					&.option-3 { // bandaid/bandage
						width: 5.8em;
						height: 3.2em;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/food-safety-1-washing-hands/option-3.svg');
					}
					&.option-4 { // cutting carrots
						width: 8em;
						height: 4.3em;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/food-safety-1-washing-hands/option-4.svg');
					}
					&.option-5 { // washing dishes *pile of dishes with soap*
						width: 7.8em;
						height: 4.8em;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/food-safety-1-washing-hands/option-5.svg');
					}
				}
			}
		}
	}

	&.food-safety-1-whos-ready {
		.MultipleChoice-options {
			margin-top: 1em;
			padding-bottom: 1em;
			.MultipleChoice-optionsWrap {
				height: 24.4em;	
				padding: 0 1.5em;			
				.MultipleChoice-option {
					position: absolute;
					background-size: contain;
					background-position: center center;
					width: 9.7em;
					height: 10em;
					span:nth-child(2) {
						bottom: 0.5em;
						right: 0.75em;
						left: auto;
						width: 1.39em;
						height: 1.39em;
					}
					&.position-1 {top: 0; left: 0;}
					&.position-2 {top: 0; right: 0;}
					&.position-3 {top: calc(50% - 1em); left: 0;}
					&.position-4 {top: calc(50% - 1em); right: 0;}
					&.option-1 {
						background-size: 10em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/food-safety-1-whos-ready/option-1.svg');
					}
					&.option-2 {
						background-size: 10em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/food-safety-1-whos-ready/option-2.svg');
					}
					&.option-3 {
						background-size: 10em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/food-safety-1-whos-ready/option-3.svg');
					}
					&.option-4 {
						background-size: 10em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/food-safety-1-whos-ready/option-4.svg');
					}
				}
			}
		}
	}
}