@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';
@import '../../../../styles/mixins_backgrounds';
@import '../../../../styles/animations';
@import '../../../../styles/animations-intro';
.OrderDndItems {
	width: 17.5em;
	left: calc(50% - 8.75em);
	bottom: 3em;
	margin: auto 0;
	overflow: auto;
	@include hide-scrollbar();
	@include flex('space-between', 'flex-start');

	.OrderDndItems-itemNumbers {
		width: 2em;
		padding-top: 0.3em;
		.OrderDndItems-itemNumber {
			width: 100%;
			height: 2.25em;
			margin-bottom: 1.35em;
			@include flex('flex-start', 'center');
			span {
				font-size: 1.33em;
				font-weight: bold;
				color: #11466B;
			}
		}
	}
	.OrderDndItems-items {
		position: relative;
		width: calc(100% - 2em);
		.OrderDndItems-itemContainer {
			position: absolute;
			width: 100%;
			left: 0em;
			@include transition(0.2s, 'top');
			&.index-0 {top: 0.3em;}
			&.index-1 {top: 3.5em + 0.4em;}
			&.index-2 {top: calc(2 * 3.5em + 0.4em);}
			&.index-3 {top: calc(3 * 3.5em + 0.4em);}
			&.index-4 {top: calc(4 * 3.5em + 0.4em);}
			&.index-5 {top: calc(5 * 3.5em + 0.4em);}
			&.index-6 {top: calc(6 * 3.5em + 0.4em);}
			&.index-7 {top: calc(7 * 3.5em + 0.4em);}
			&.index-8 {top: calc(8 * 3.5em + 0.4em);}
		}
	}

	&.orientation-1-order-of-important,
	&.deli-2-order-deep-clean-display-case,
	&.floral-2-multiple-choice-flower-care-water-buckets,
	&.meat-1-order-fix-hot-case,
	&.produce-2-order-receiving-bananas,
	&.produce-2-order-vegetables-crisping,
	&.seafood-2-order-reset-service-case,
	&.seafood-2-order-purging-lobsters,
	&.daily-guidelines-1-order-culling {
		.OrderDndItems-itemNumbers {
			.OrderDndItems-itemNumber {
				height: 3em;
			}
		}
		.OrderDndItems-items {
			.OrderDndItems-itemContainer {
				&.index-0 {top: 0.3em;}
				&.index-1 {top: 4.25em + 0.4em;}
				&.index-2 {top: calc(2 * 4.25em + 0.4em);}
				&.index-3 {top: calc(3 * 4.25em + 0.4em);}
				&.index-4 {top: calc(4 * 4.25em + 0.4em);}
				&.index-5 {top: calc(5 * 4.25em + 0.4em);}
				&.index-6 {top: calc(6 * 4.25em + 0.4em);}
				&.index-7 {top: calc(7 * 4.25em + 0.4em);}
				&.index-8 {top: calc(8 * 4.25em + 0.4em);}
			}

		}
	}
}